
/*
<Button icon="" caption="hello" />
  <Button icon="fas fa-user-circle" caption="hello" />
  className -> btn-primary, btn-secondary, btn-danger, btn-warning, btn-info
*/
import styled from "styled-components";


export const MainContainer = styled.div.attrs(props => ({

}))`
background-color:red;
width:100px;
&:hover .icon{
  color:#6DAEC2;
  cursor:pointer;
}

`;
export const BtnContainer = styled.button`
background-color:#027BFF;
border-radius: 5px;
padding: 8px;
display:flex;
min-width:80px;
align-self: center;
flex-direction:row;
justify-content:center;
cursor:pointer;
text-align:center;
align-items: center;
border:none;
outline:none;
box-shadow:none;

&.btn-primary{
    background-color:#202020;
    color:#FAD230;
    outline:none;
    box-shadow:none;
    border:1px solid #202020;
    &:hover{
        background:transparent;
        outline:none;
    }
    &:hover label{
        color:#202020;
    }
    &:focus{
        outline:none;
        box-shadow:none;
    }
    &:active{
        outline:none;
        box-shadow:none;
    }    
}
&.btn-secondary{
    background-color:#FAD230;
    color:#202020;
    outline:none;
    box-shadow:none;
    border:1px solid #FAD230;
    font-weight:500;    
    &:focus{
        outline:none;
        box-shadow:none;
    }
    &:active{
        outline:none;
        box-shadow:none;
    }
}
&.btn-success{
    background-color:#28A745;
    color:#ffffff;
}
&.add-url{
    margin-left:15px;
}
&.btn-gray{
    background-color:#D4DCE5;
    color:#4A3121;
}
&.btn-danger{
    background-color:#cc0202;
    color:#ffffff;
    outline:none;
    box-shadow:none;
    border:1px solid #cc0202;
    font-weight:500;
    &:hover{
        background:transparent;
        outline:none;
    }
    &:hover label{
        color:#cc0202;
    }
    &:hover i{
        color:#cc0202;
    }
    &:focus{
        outline:none;
        box-shadow:none;
    }
    &:active{
        outline:none;
        box-shadow:none;
    }
}
&.btn-warning{
    background-color:#FFC008;
    color:#000000;
}
&.btn-info{
    background-color:#0000FF;
    color:#ffffff;
    border:1px solid #0000ff;
    font-weight:500;
    &:hover{
        background:transparent;
        outline:none;
    }
    &:hover label{
        color:#0000ff;
    }
    &:hover i{
        color:#0000ff;
    }
    &:focus{
        outline:none;
        box-shadow:none;
    }
    &:active{
        outline:none;
        box-shadow:none;
    }
}
&.btn-light{
    background-color:#ffffff;
    color:#000000;
}

`;
export const Itab = styled.i`
background-color:transparent;
border:0px;
font-size:${props => props.fontSize ? props.fontSize : "12px"};
color:#ffffff;
padding-right:10px;
&:hover{
    background:transparent;
}
`;
export const Label = styled.label`
background-color:transparent;
border:0px;
font-size:${props => props.fontSize ? props.fontSize : "12px"};
// color:#FAD230;
color:#ffffff;
padding-right:10px;
cursor:pointer;
&:hover{
    // color:#FAD230;
    background:transparent;
}
`;

export function Button({
    ...props
}) {
    return (
        <div>
            <BtnContainer {...props} onClick={props.btnFun} >
                <Itab {...props} className={props.icon + " icon"} /> <Label {...props} >{props.caption}</Label>
            </BtnContainer>
        </div>
    );
}