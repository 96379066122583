import React from 'react'
import UserFavouriteTeamListController from './UserFavouriteTeamListController';
import UserFavouriteTeamCreate from './../create/UserFavouriteTeamCreate';

import UserFavouriteTeamAction from '../../../redux/action/user_favourite_team_action.js'
import UserAction from './../../../redux/action/user_action';
import TeamAction from './../../../redux/action/team_action';
import { Alert, connect, PageLoader, Pagination, Button, Search, MainContainer, Header, Body } from '../../../components/ImportsFile'
class UserFavouriteTeamList extends UserFavouriteTeamListController {
    render() {
        return (
            <MainContainer>
                <Header>
                    <h4>User Favourite Teams</h4>
                    <form className="search user-fav-team" >
                        <div className="input-group">

                            {/**
                        <Search
                        items={this.props.users}
                        searchItem={(userId) => this.handleSelectUserId(userId)}
                        text={"user"}
                        searching={this.props.searchUser}
                        getItems={this.props.getAllUsers}
                        rowCount={this.state.rowCount}
                        currentPage={this.props.currentUserPage}
                        stopSearching={() => this.props.getUserFavouriteTeams(this.state.initialPage, this.state.rowCount)}
                        LoadMore={this.loadMore1}
                        
                        />
                    */}

                            <Search
                                items={this.props.teams}
                                searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                                searchTeam={this.props.searchTeam}
                                rowCount={this.state.rowCount}
                                getItems={this.props.getAllTeams}
                                currentPage={this.props.currentTeamPage}
                                text={'Search with Team'}
                                search={this.state.teamId ? true : false}
                                stopSearching={this.stopSearching}
                                LoadMore={this.loadMore2}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>

                    <Button className="btn-primary add-button" caption="Add New" btnFun={this.showModal} style={{ display: 'none' }} />


                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Team</th>
                                <th>Active</th>
                                {/**
                                <th>Actions</th>
                            */}
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.props.user_favourite_teams?.map((favTeam, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{favTeam.User?.user_name}</td>
                                            <td>{favTeam.Team?.name}</td>
                                            <td>{favTeam.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                {/**
                                            <EditModal editItem={() => this.handleEdit(favTeam)} />
                                        */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.userId && !this.props.teamId) && this.props.getUserFavouriteTeams(pageNumber, this.props.row_count);
                        (this.props.userId || this.props.teamId) && this.props.searchWithUserOrTeam(this.state.userId, this.state.teamId, pageNumber);

                    }} />
                    {
                        // this.state.show &&
                        // <UserFavouriteTeamCreate
                        //     isEdit={this.state.isEdit}
                        //     hideModal={this.hideModal}
                        //     user_favourite_team={this.state.user_favourite_team}
                        //     createOrUpdate={this.createOrUpdate}
                        //     users={this.props.users}
                        //     teams={this.props.teams}
                        //     loadMore1={this.loadMore1}
                        //     loadMore2={this.loadMore2}
                        //     active={this.props.active}
                        //     // searchUser={this.props.searchUser}
                        //     searchTeam={this.props.searchTeam}
                        //     userText={this.props.userText}
                        //     teamText={this.props.teamText}
                        //     currentPage={this.props.currentPage}
                        //     getAllUsers={this.props.getAllUsers}
                        //     getAllTeams={this.props.getAllTeams}
                        //     userRowCount={this.state.userRowCount}
                        //     teamRowCount={this.state.teamRowCount}
                        //     requiredError={this.state.requiredError}

                        // />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user_favourite_teams: state.user_favourite_team.user_favourite_teams,
        totalCount: state.user_favourite_team.totalCount,
        totalCountPerPage: state.user_favourite_team.totalCountPerPage,
        userTotalCount: state.user.totalCount,
        teamTotalCount: state.team.totalCount,
        currentPage: state.user_favourite_team.currentPage,
        currentUserPage: state.user.currentPage,
        currentTeamPage: state.team.currentPage,
        error: state.user_favourite_team.error,
        success: state.user_favourite_team.success,
        row_count: state.user_favourite_team.row_count,
        loading: state.user_favourite_team.loading,
        teamId: state.user_favourite_team.teamId,
        userId: state.user_favourite_team.userId,
        userText: state.user.text,
        teamText: state.team.text,
        users: state.user.users,
        active: state.user.active,
        teams: state.team.teams,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserFavouriteTeams: (pageNumber, row_count) => dispatch(UserFavouriteTeamAction.getUserFavouriteTeams(pageNumber, row_count)),
        createUserFavouriteTeam: (user_favourite_team) => dispatch(UserFavouriteTeamAction.createUserFavouriteTeam(user_favourite_team)),
        updateUserFavouriteTeam: (user_favourite_team) => dispatch(UserFavouriteTeamAction.updateUserFavouriteTeam(user_favourite_team)),
        deleteUserFavouriteTeam: (id) => dispatch(UserFavouriteTeamAction.deleteUserFavouriteTeam(id)),

        searchWithUserOrTeam: (userId, teamId, pageNumber) => dispatch(UserFavouriteTeamAction.searchWithUserOrTeam(userId, teamId, pageNumber)),
        // searchUser: (text, active, pageNumber) => dispatch(UserAction.searchItem(text, active, pageNumber)),
        searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchItem(text, stadiumId, countryId, pageNumber)),
        getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
        getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserFavouriteTeamList)