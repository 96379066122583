
import CONSTANTS from '../constants';
import axios from 'axios'
import QuestionType from './../type/question_type';

export const Error = (data) => {
    return {
        type: QuestionType.QUESTION_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: QuestionType.QUESTION_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: QuestionType.LOADING, payload: status });
    }
}
const getQuestions = (type, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}questions/list?quiz_type=${type}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {

            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.GET_QUESTIONS,
                    payload: response.data.data
                })
                dispatch({
                    type: QuestionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: QuestionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: QuestionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: QuestionType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: QuestionType.QUESTION_TYPE, payload: type
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: QuestionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuestionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllQuestions = (type, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}questions/list?quiz_type=${type}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {

            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.GET_QUESTIONS,
                    payload: response.data.data
                })
                dispatch({
                    type: QuestionType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: QuestionType.QUESTION_TYPE, payload: type
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: QuestionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuestionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createQuestion = (question) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}questions/create`, question).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.ADD_QUESTION,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateQuestion = (question) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}questions/update`, question).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.UPDATE_QUESTION,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const deleteQuestion = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));

    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}questions/delete`, {
            data: { id: id, deleted_by: operator.id }
        }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.DELETE_QUESTION,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: QuestionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuestionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchQuestion = (matchId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}questions/list?match_id=${matchId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuestionType.GET_QUESTIONS, payload: response.data.data
                })
                dispatch({
                    type: QuestionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: QuestionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: QuestionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: QuestionType.SEARCH_WITH_MATCH,
                    payload: matchId
                })
            }

        })
    }
}

const QuestionAction = {
    getQuestions,
    getAllQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    searchQuestion,
    loading
}
export default QuestionAction