/* 
====================== USAGE
input text
<Input type="text" color="red" label="Name in English" />

input radio
<Input type="radio" label="hello" />

======================
*/

import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:${props => props.width ? props.width : "100%"};
    color:${props => props.color ? props.color : "#14A2B8"};
`;

export const Caption = styled.label`
    padding-bottom:10px;
    color:${props => props.color ? props.color : "#14A2B8"};
`

export const Select = styled.select`
    width:100%;
    border:1px solid #14A2B8;
    padding-top:7px;
    padding-bottom:8px;
    border-radius:5px;
    padding-left:10px;
    color:${props => props.color ? props.color : "#14A2B8"};
`


export function SelectBox({
    ...props
}) {
    return (
        <Container {...props}>
            <Caption {...props}>{props.label}</Caption>
            <Select>
                {
                    props.items.map((element, index) => {
                        return <option key={index} value={element.name}>{element.name}</option>
                    })
                }
            </Select>
        </Container>
    );
}