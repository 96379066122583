const PermissionType = {
    GET_PERMISSIONS : "GET_PERMISSIONS",
    ADD_PERMISSION : "ADD_PERMISSION",
    UPDATE_PERMISSION : "UPDATE_PERMISSION",
    DELETE_PERMISSION : "DELETE_PERMISSION",
    PERMISSION_ERROR: "PERMISSION_ERROR",
    PERMISSION_SUCCESS: "PERMISSION_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default PermissionType