
import CONSTANTS from './../constants';
import axios from 'axios'
import TeamLeagueType from './../type/team_league_type';

export const Error = (data) => {
    return {
        type: TeamLeagueType.TEAM_LEAGUE_ERROR,
        payload: data
    }
}

export const Success = (data) => {
    return {
        type: TeamLeagueType.TEAM_LEAGUE_SUCCESS,
        payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({
            type: TeamLeagueType.LOADING,
            payload: status
        })
    }
}
const getTeamLeagues = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.GET_TEAM_LEAGUES,
                    payload: response.data.data,
                })
                dispatch({
                    type: TeamLeagueType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count,
                })
                dispatch({
                    type: TeamLeagueType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at,
                })
                dispatch({
                    type: TeamLeagueType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: TeamLeagueType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: TeamLeagueType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TeamLeagueType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createTeamLeague = (team_league) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}team-leagues/create`, team_league).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.ADD_TEAM_LEAGUE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateTeamLeague = (team_league) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}team-leagues/update`, team_league).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.UPDATE_TEAM_LEAGUE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteTeamLeague = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}team-leagues/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.DELETE_TEAM_LEAGUE, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail to delete"
                dispatch({
                    type: TeamLeagueType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TeamLeagueType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchWithLeagueAndTeamName = (leagueId, teamName, row_count, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?league_id=${leagueId}&teamName=${teamName}&row_count=${row_count}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.GET_TEAM_LEAGUES, payload: response.data.data
                })
                dispatch({
                    type: TeamLeagueType.SEARCH_WITH_LEAGUE,
                    payload: leagueId
                })
                dispatch({
                    type: TeamLeagueType.SEARCH_WITH_TEAM,
                    payload: teamName
                })
                if (!teamName) {
                    dispatch({
                        type: TeamLeagueType.GET_TOTAL_COUNT,
                        payload: response.data.pagination.total_records_count,
                    })
                    dispatch({
                        type: TeamLeagueType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at,
                    })
                    dispatch({
                        type: TeamLeagueType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                    })
                }
                else if (teamName) {
                    dispatch({
                        type: TeamLeagueType.GET_TOTAL_COUNT, payload: response.data?.data?.length,
                    })
                }

                dispatch({
                    type: TeamLeagueType.ROW_COUNT, payload: row_count
                })

            }
        })
    }
}
const searchWithLeague = (leagueId, teamName, row_count, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?league_id=${leagueId}&teamName=${teamName}&row_count=${row_count}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamLeagueType.GET_TEAM_LEAGUES, payload: response.data.data
                })
                dispatch({
                    type: TeamLeagueType.SEARCH_WITH_LEAGUE,
                    payload: leagueId
                })
                dispatch({
                    type: TeamLeagueType.SEARCH_WITH_TEAM,
                    payload: teamName
                })
                dispatch({
                    type: TeamLeagueType.ROW_COUNT, payload: row_count
                })

            }
        })
    }
}

const TeamLeagueAction = {
    getTeamLeagues,
    updateTeamLeague,
    deleteTeamLeague,
    createTeamLeague,
    searchWithLeagueAndTeamName,
    searchWithLeague
}
export default TeamLeagueAction