
import styled from "styled-components";
import { Button } from '../buttons/Button'

export const TableContainer = styled.table.attrs(props => ({

}))`
  font-size:14px;
  font-family:Fjord;
  cursor:pointer;
  color:#565656;
  width:100%;
  display:flex;
  flex-direction:column;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom:0px;

  & tbody tr{
    transition: padding 0.3s;
  }
  & tr{
    display:flex;
  }
  
  & td{
    height:50px;
    width: 100%;
    line-height: 50px;
    justify-content:space-between;
    text-align:center;
  }
  
  & thead{
    display:flex;
    flex-direction:row;
    width:100%;
    height:10%;
  }
  & tbody{
    overflow: auto;
    height: 90%;
    width:100%;
  }
  & th{
    // background-color: #3A3365;
    background-color: #202020;
    color:#FAD230;
    justify-content:center;
    align-items:center;
    color:#ffffff;
    line-height: 300%;
    height:40px;
    text-align:center;
    border-bottom:1px solid #ECEEF9;
    
  }
  
  & tr:nth-child(even){background-color: #F0F6F8;}
  & tbody tr:hover {background-color: #E5F6FB;
    padding-top:5px;
    padding-bottom:5px;
  }



  & .actionButton{
   // display:none;
   display:block;
  }
  & tbody tr:hover .actionButton{
   // display:block;
  }
  ${props => (
    props.columnSize ? props.columnSize.map((element, index) =>
      `
        & td:nth-child(`+ (index + 1) + `){
          width:`+ element + `;
        }
        & th:nth-child(`+ (index + 1) + `){
          width:`+ element + `;
        }
        `
    ) : ""

  )
  }

  ${props => (
    props.columnCss ? props.columnCss.map((element, index) =>
      `
        & td:nth-child(`+ (index + 1) + `){
          `+ element + `;
        }
        & th:nth-child(`+ (index + 1) + `){
         `+ element + `;
        }
        `
    ) : ""


  )
  }  
`;

export function Table({
  ...props
}) {

  return (
    <TableContainer {...props}>
      <thead>

        <tr style={{ width: "100%" }}>
          {
            props.headers.map(element => <th key={"th" + element} style={{ width: "100%" }}>{element}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {
          props.rows.map((element, index) => {

            // return (<tr key={"tr"+index} style={{ textAlign:'center' }}>
            return (<tr key={"tr" + index}>
              {
                Object.keys(element).map(function (x, y) {
                  return <td key={"td" + y}>{element[x]}</td>

                  //return typeof element[x]=== "string"?<td key={"td"+y}>{element[x]}</td>:<td>{element[x]}</td>
                })
              }
              <td>
                <Button caption="Edit" icon="fas fa-edit" className="btn-info" btnFun={() => props.handleEdit(index)} />
              </td>
              <td>
                <Button caption="Delete" className="btn-danger" icon="fas fa-trash" btnFun={() => props.handleDelete(index)} />
              </td>

            </tr>)
          })
        }
      </tbody>
    </TableContainer>
  );
}