import React from 'react'
import ScoreDetailController from './ScoreDetailController';
import { MainContainer, ScoreNav } from './ScoreDetailElement'
import { withRouter } from '../../../components/routers/withRouter'
import ScoreStats from './stats/ScoreStats';
import Lineup from './lineup/Lineup';
import ScoreTable from './table/ScoreTable';
import { Link } from 'react-router-dom';

import BackArr from '../../../images/back.png'
class ScoreDetail extends ScoreDetailController {
  render(props) {

    return (
      <MainContainer>


        <ScoreNav>
          <ul>
            <Link to="/livescore" style={{ textDecoration: 'none' }}>
              <img src={BackArr} alt="" width="25px" style={{ marginRight:"10px" }} />
            </Link>

            <li className={this.state.stats ? 'active' : ''} onClick={this.showStats}>
              <p>Stats</p>
            </li>
            <li className={this.state.lineup ? 'active' : ''} onClick={this.showLineUp}>
              <p>Line Up</p>
            </li>
            <li className={this.state.table ? 'active' : ''} onClick={this.showTable}>
              <p>Table</p>
            </li>
          </ul>
        </ScoreNav>
        {
          this.state.stats === true && <ScoreStats scoreDetail={this.state.scoreDetail} />

        }
        {
          this.state.lineup && <Lineup />
        }
        {
          this.state.table && <ScoreTable />
        }
      </MainContainer>
    )
  }
}

export default withRouter(ScoreDetail)