import { Component } from 'react';
class CountryCreateController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            country: props.isEdit === false ? {
                flag: '',
                name: '',
                country_code: '',
                active: 1,
            } : props.country.country
        }
    }

    handleInputChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            country: {
                ...prevState.country,
                name: e.target.value
            }
        }))
    }
    handleInputCountyCode = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            country: {
                ...prevState.country,
                country_code: e.target.value
            }
        }))
    }

    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            country: {
                ...prevState.country,
                active: e.target.value
            }
        }))
    }
    onChangeImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState((prevState) => ({
                ...prevState,
                country: {
                    ...prevState.country,
                    flag: e.target.files[0]
                }
            }))
        }
    }
}

export {
    CountryCreateController
}