import { Component } from 'react'
import { axios, CONSTANTS } from '../../../components/ImportsFile'
class SpecialQuizDetailCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {

      // teams: props.teams,
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      checked: false,
      quizInfo: '',
      submit_type: [
        { id: 1, name: 'Numbering Scroll' },
        { id: 2, name: 'Radio Button' },
        { id: 4, name: 'Click Select' },
      ],
      selectedQuestion: '',
      // questionType: this.props.quizInfo.match_id_from_fotmob === null ? 2 : 1,
      quiz_details: props.isEdit === false ? {
        special_quiz_id: '',
        question_id: '',
        // type: 1,
        type: this.props.quizInfo.quiz_type === 1 ? 4 : 1,
        team_id: '',
        player_id: '',
        manager_id: '',
        match_id: '',
        submit_type: '',
        quiz_type: '',
        answer_count: '',
        redeem_point: '',
        multiply_no: '',
        win_reward: '',
        remark: '',
        active: 1,
      } : props.quiz_details.quiz_details


    }
    this.handleSwitch = this.handleSwitch.bind(this)
  }
  componentDidMount = () => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        special_quiz_id: this.props.quizInfo?.id,
        quiz_type: this.props.quizInfo?.quiz_type,
      }
    }))
  }

  AnswerCount = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        answer_count: e.target.value,
      }
    }))

  }
  handleSelectQuestion = (questionId) => {
    axios.post(`${CONSTANTS.BASE_URL}questions/detail`, { id: questionId }).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        quiz_details: {
          ...prevState.quiz_details,
          win_reward: !this.state.checked ? response.data.data.win_reward : '',
          question_id: questionId,
          redeem_point: response.data.data.redeem_points,
        },
        selectedQuestion: response.data.data.question,
      }), () => {
        if (this.state.selectedQuestion == "ဗိုလ်စွဲမည့်အသင်း/ချန်ပီယံအသင်း" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက် သွင်းဂိုးအများဆုံး အသင်း" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက် ပေးဂိုးအနည်းဆုံး အသင်း" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး ဂိုးသမား (လက်ဗ်ယာရှင်ဆု)" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး နောက်တန်းကစားသမား" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး အလယ်တန်းကစားသမား" || this.state.selectedQuestion == "ပြိုင်ပွဲတစ်လျှောက်အကောင်းဆုံး ရှေ့တန်းကစားသမား" || this.state.selectedQuestion == "ပြိုင်ပွဲတလျှောက် အကောင်းဆုံး ကစားသမား" || this.state.selectedQuestion == "ပြိုင်ပွဲတလျှောက် ဂိုးသွင်းအများဆုံး ကစားသမား" || this.state.selectedQuestion == "ပြိုင်ပွဲတလျှောက် ဂိုးဖန်တီးမှုအများဆုံး ကစားသမား") {
          this.setState((prevState) => ({
            ...prevState,
            quiz_details: {
              ...prevState.quiz_details,
              submit_type: 4,
              answer_count: 1,
            }
          }))
        }
        else if (this.state.selectedQuestion == "ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ" || this.state.selectedQuestion == "ဘယ်အသင်းအရင် လူစားလဲမှာလဲ") {
          this.setState((prevState) => ({
            ...prevState,
            quiz_details: {
              ...prevState.quiz_details,
              submit_type: 2,
              answer_count: 1,
            }
          }))
        }

      })
    })

    if (questionId === 19) {
      this.setState((prevState) => ({
        ...prevState,
        quiz_details: {
          ...prevState.quiz_details,
          submit_type: 2,
          answer_count: 1,
        }
      }))
    }
    else if (questionId === 4) {
      this.setState((prevState) => ({
        ...prevState,
        quiz_details: {
          ...prevState.quiz_details,
          submit_type: 2,
          answer_count: 1,
        }
      }))
    }
  }
  handleSelectSubmitType = (typeId) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        submit_type: typeId
      }
    }))
    if (typeId === 2 || typeId === 1 || typeId === 4) {
      this.setState((prevState) => ({
        ...prevState,
        quiz_details: {
          ...prevState.quiz_details,
          answer_count: 1
        }
      }))
    }

  }
  handleSelectType = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        type: e.target.value
      }
    }))
  }
  // handleSelectType = (typeId) => {
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     quiz_details: {
  //       ...prevState.quiz_details,
  //       type: typeId
  //     }
  //   }))
  // }

  RedeemPoints = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        redeem_point: e.target.value
      }
    }), () => {
      if (this.state.quiz_details.redeem_point && this.state.quiz_details.multiply_no) {
        let reward = this.state.quiz_details.redeem_point * this.state.quiz_details.multiply_no
        this.setState((prevState) => ({
          ...prevState,
          quiz_details: {
            ...prevState.quiz_details,
            win_reward: reward
          }
        }))
      }
    })
  }
  handleSwitch(checked) {
    this.setState({ checked })
  }
  MultiplyNo = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        multiply_no: e.target.value
      }
    }), () => {
      if (this.state.quiz_details.redeem_point && this.state.quiz_details.multiply_no) {
        let reward = this.state.quiz_details.redeem_point * this.state.quiz_details.multiply_no
        this.setState((prevState) => ({
          ...prevState,
          quiz_details: {
            ...prevState.quiz_details,
            win_reward: reward
          }
        }))
      }
    })
  }
  WinReward = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        win_reward: e.target.value
      }
    }))
  }
  Remark = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        remark: e.target.value
      }
    }))
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_details: {
        ...prevState.quiz_details,
        active: e.target.value
      }
    }))
  }
}

export default SpecialQuizDetailCreateController