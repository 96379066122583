import React from 'react'
import { TransactionsListController } from './TransactionsListController'
import { TransactionsCreate } from '../create/TransactionsCreate'
import TransactionAction from './../../../redux/action/transaction_action'; import WalletAction from './../../../redux/action/wallet_action';
import UserAction from './../../../redux/action/user_action';
import PaymentTypeAction from './../../../redux/action/payment_type_action';
import { connect, PageLoader, Modal, Pagination, Button, Search, MainContainer, Header, Body } from '../../../components/ImportsFile'
class TransactionsList extends TransactionsListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Transaction' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Transaction' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Transaction' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Transactions</h4>
                    <form className="search search-transaction">
                        <div className="input-group">

                            <Search
                                items={this.props.users}
                                searching={this.props.searchUser}
                                currentPage={this.props.currentPage}
                                searchItem={(userId) => this.handleSelectUser(userId)}
                                text={'Search with User'}
                                search={this.state.userId ? true : false}
                                LoadMore={this.loadMore1}
                                getItems={this.props.getAllUsers}
                                rowCount={this.props.userRowCount}
                                stopSearching={() => this.stopSearching()}
                            />

                            <Search
                                items={this.props.payment_types}
                                searching={this.props.searchPayType}
                                currentPage={this.props.currentPage}
                                searchItem={(payment_type_id) => this.handleSelectPayType(payment_type_id)}
                                text={'Search with Payment Type'}
                                search={this.state.payment_type_id ? true : false}
                                LoadMore={this.loadMore2}
                                getItems={this.props.getAllPaymentTypes}
                                rowCount={this.props.paymentTypeRowCount}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Transactions" className="btn-primary add-button" btnFun={this.showModal} />
                    }

                </Header>
                <Body>
                    {
                        (this.props.transactionError || this.props.walletError) && <div className="alert alert-danger alert-dismissible fade show" role="alert">

                            {
                                this.props.transactionError.message
                            }
                            {
                                // this.props.walletError.message
                            }
                            <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    }
                    {
                        ((this.props.transactionSuccess && this.props.walletSuccess) || (this.props.transactionSuccess && this.state.isEdit)) &&
                        // (this.props.transactionSuccess && this.props.walletSuccess) &&
                        <div className="alert alert-success alert-dismissible fade show" role="alert">{this.props.transactionSuccess.message}
                            <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    }

                    {
                        this.props.loading && <PageLoader />
                    }

                    <table>
                        <thead>
                            <tr>
                                <th>Payment Type</th>
                                <th>Username</th>
                                <th>Income</th>
                                <th>Outcome</th>
                                <th>Add Points</th>
                                <th>Remove Points</th>
                                <th>Description</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.transactions.map((transaction, index) => {
                                    return (
                                        <tr key={index}>
                                            {/**
                                            <td>{transaction.user_id}</td>
                                            <td>
                                                <img src={transaction.payment_screenshot} alt=""/>
                                            </td>
                                        */}
                                            <td>
                                                <img src={transaction?.PaymentType?.logo_url} alt="" />
                                            </td>
                                            <td>{transaction?.User?.user_name}</td>
                                            <td style={{ color: transaction.amount_in !== 0 && "green" }}>
                                                {transaction.amount_in.toLocaleString()}
                                            </td>
                                            <td style={{ color: transaction.amount_out !== 0 && "red" }}>
                                                {transaction.amount_out.toLocaleString()}
                                            </td>
                                            <td style={{ color: transaction.add_points !== 0 && "green" }}>{transaction.add_points}</td>
                                            <td style={{ color: transaction.remove_points !== 0 && "red" }}>{transaction.remove_points}</td>
                                            <td>{transaction.description}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteTransaction(transaction.id)} deletePermission={deletePermission} updatePermission={deletePermission} editItem={() => this.handleEdit(transaction)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.userId && !this.props.payTypeId) && this.props.getTransactions(pageNumber, this.props.row_count);
                        (this.props.userId || this.props.payment_type_id) && this.props.searchItem(this.state.userId, this.state.payment_type_id, pageNumber);
                    }} />
                    {
                        this.state.show &&
                        <TransactionsCreate
                            isEdit={this.state.isEdit}
                            transaction={this.state.transaction}
                            hideModal={this.hideModal}
                            createOrUpdate={this.createOrUpdate}
                            checked={this.state.checked}
                            handleSwitch={this.handleSwitch}
                            users={this.props.users}
                            payment_types={this.props.payment_types}
                            loadMore1={this.loadMore1}
                            loadMore2={this.loadMore2}
                            searchUser={this.props.searchUser}
                            searchPayType={this.props.searchPayType}
                            userText={this.props.userText}
                            paytypeText={this.props.paytypeText}
                            userRowCount={this.props.userRowCount}
                            paymentTypeRowCount={this.props.paymentTypeRowCount}
                            getAllUsers={this.props.getAllUsers}
                            getAllPaymentTypes={this.props.getAllPaymentTypes}
                            currentPage={this.props.currentPage}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        transactions: state.transaction.transactions,
        transactionError: state.transaction.error,
        transactionSuccess: state.transaction.success,
        currentPage: state.transaction.currentPage,
        totalCount: state.transaction.totalCount,
        userId: state.transaction.userId,
        payTypeId: state.transaction.payTypeId,
        totalCountPerPage: state.transaction.totalCountPerPage,
        row_count: state.transaction.row_count,
        loading: state.transaction.loading,
        payment_types: state.payment_type.payment_types,
        users: state.user.users,
        wallets: state.wallet.wallets,
        walletError: state.wallet.error,
        walletSuccess: state.wallet.success,
        notification: state.wallet.notification,
        userText: state.user.text,
        paytypeText: state.payment_type.text,
        userRowCount: state.user.row_count,
        paymentTypeRowCount: state.payment_type.row_count,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTransactions: (pageNumber, row_count) => dispatch(TransactionAction.getTransactions(pageNumber, row_count)),
        getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
        getAllPaymentTypes: (pageNumber, row_count) => dispatch(PaymentTypeAction.getAllPaymentTypes(pageNumber, row_count)),
        amountInTransaction: (transaction) => dispatch(TransactionAction.amountInTransaction(transaction)),
        amountOutTransaction: (transaction) => dispatch(TransactionAction.amountOutTransaction(transaction)),
        addPointsTransaction: (transaction) => dispatch(TransactionAction.addPointsTransaction(transaction)),
        removePointsTransaction: (transaction) => dispatch(TransactionAction.removePointsTransaction(transaction)),
        updateTransaction: (transaction) => dispatch(TransactionAction.updateTransaction(transaction)),
        deleteTransaction: (id) => dispatch(TransactionAction.deleteTransaction(id)),
        updateWallet: (wallet) => dispatch(WalletAction.updateWallet(wallet)),
        searchItem: (userId, payTypeId, pageNumber) => dispatch(TransactionAction.searchItem(userId, payTypeId, pageNumber)),

        searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),
        searchPayType: (text, pageNumber) => dispatch(PaymentTypeAction.searchPayType(text, pageNumber)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList)