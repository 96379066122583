

function NotFoundPage (){
   
        return (
           
           <div>404 not found page</div>
        );
}



export {NotFoundPage};