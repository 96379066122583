import React from 'react'
import SpecialQuizDetailListController from './SpecialQuizDetailListController';
import { withRouter, connect, MainContainer, Header, Body, Back, Link, Button, Alert, PageLoader, Modal, Pagination } from '../../../components/ImportsFile';
import SpecialQuizDetailsAction from './../../../redux/action/special_quiz_detail_action';
import SpecialQuizDetailCreate from './../special_quiz_detail_create/SpecialQuizDetailCreate';
import SpecialQuizAction from './../../../redux/action/special_quiz_action';
import QuestionAction from './../../../redux/action/question_action';

class SpecialQuizDetailList extends SpecialQuizDetailListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Quizzes' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>
            <Link to="/quizzes" style={{ textDecoration: 'none' }}>
              <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
            </Link>
            Quiz Details Lists
          </h4>
          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Quiz Details" className="btn-primary add-button" />
          }
        </Header>
        <Body>
          <div className="quiz_info">

            <p> <b>League: </b> {this.state.quizInfo?.League?.name}</p>
            {
              (this.state.quizInfo?.Match && this.state.quizInfo.quiz_type === 1) &&
              <p> <b>Match: </b>
                {
                  this.state.quizInfo?.Match?.HomeTeam?.name + " Vs " + this.state.quizInfo?.Match?.AwayTeam?.name
                }
              </p>
            }
            <p> <b>Country: </b> {this.state.quizInfo?.Country?.name}</p>
          </div>

          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />


          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Submit Type</th>
                <th>Answer Counts</th>
                <th>Redeem Point</th>
                <th>Win Reward</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.special_quiz_details.map((quiz_details, index) => {
                  return (
                    <tr key={index} style={{ background: (quiz_details?.QuizAnswers && quiz_details?.QuizAnswers[0]?.answer) && '#77E87A' }}>

                      <td>
                        {
                          // quiz_details.question.length > 50 ? quiz_details.question.substring(0, 50) + "..." : quiz_details.question
                          quiz_details.Question?.question
                        }
                      </td>
                      <td>
                        {
                          quiz_details.submit_type === 1 && "Numbering Scroll"}
                        {

                          quiz_details.submit_type === 2 && "Radio Button"
                        }
                        {
                          // quiz_details.submit_type === 3 && "Check Box"
                        }
                        {
                          quiz_details.submit_type === 4 && "Click Select"
                        }
                      </td>
                      <td>{quiz_details.answer_count || "-"}</td>
                      <td>{quiz_details.redeem_point || "-"}</td>
                      <td>{quiz_details.win_reward || "-"}</td>


                      <td>
                        <Link to={`/quiz_detail/${quiz_details.special_quiz_id}/answers/${quiz_details.id}`} className="quiz_detail">
                          <Button caption="Answers" className="btn-success" icon="fa fa-info-circle"
                          />
                        </Link>


                        {
                          quiz_details.quiz_type === 2 &&
                          <Link to={`/quiz_detail/${quiz_details.special_quiz_id}/season_details/${quiz_details.id}`} className="quiz_detail">
                            <Button caption='Details' className="btn-primary" icon="fa fa-info-circle"
                            />
                          </Link>
                        }
                        {/**
                        <Link to={`/quiz_detail/${quiz_details.special_quiz_id}/${quiz_details.quiz_type === 4 ? "future_details" : "season_details"}/${quiz_details.id}`} className="quiz_detail">
                        <Button caption='Details' className="btn-primary" icon="fa fa-info-circle"
                        />
                        </Link>
                      */}


                        <Modal deleteItem={() => this.props.deleteSpecialQuizDetails(quiz_details.id)}
                          editItem={() => { this.handleEdit(quiz_details) }}
                          updatePermission={updatePermission} deletePermission={deletePermission}
                        />
                      </td>
                    </tr>
                  )
                })

              }
            </tbody>
          </table>


          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => {
              (!this.props.matchId) && this.props.getSpecialQuizDetailsLists(this.state.specialQuizId, pageNumber, this.props.rowCount);
              (this.props.matchId) && this.props.searchQuiz(this.props.userId, this.props.matchId, pageNumber)
            }} />

          {
            this.state.show &&
            <SpecialQuizDetailCreate
              quiz_details={this.state.quiz_details}
              special_quizzes={this.props.special_quizzes}
              hideModal={this.hideModal}
              isEdit={this.state.isEdit}
              quizInfo={this.state.quizInfo}
              createOrUpdate={this.createOrUpdate}
              searchQuiz={this.props.searchQuiz}
              currentPage={this.props.currentPage}
              matchRowCount={this.props.matchRowCount}
              getAllMatches={this.props.getAllMatches}
              matches={this.props.matches}
              loadMore={this.loadMore}
              requiredError={this.state.requiredError}
              questions={this.props.questions}
              questionRowCount={this.props.questionRowCount}
              questionTotalCount={this.props.questionTotalCount}
              getQuestions={this.props.getQuestions}
            // teams={this.props.teams}
            // getTeams={(pageNumber, row_count) => this.props.getAllTeams(pageNumber, row_count)}
            // teamRowCount={this.state.teamRowCount}
            // teamCurrentPage={this.props.teamCurrentPage}
            // searchTeam={this.props.searchTeam}
            />

          }

        </Body>
      </MainContainer>


    )
  }
}
const mapStateToProps = (state) => {
  return {
    special_quiz_details: state.special_quiz_detail.special_quiz_details,
    currentPage: state.special_quiz_detail.currentPage,
    totalCount: state.special_quiz_detail.totalCount,
    error: state.special_quiz_detail.error,
    success: state.special_quiz_detail.success,
    totalCountPerPage: state.special_quiz_detail.totalCountPerPage,
    rowCount: state.special_quiz_detail.row_count,
    loading: state.special_quiz_detail.loading,
    matchId: state.special_quiz_detail.matchId,

    special_quizzes: state.special_quiz.special_quizzes,
    matchRowCount: state.match.row_count,

    questions: state.question.questions,
    questionRowCount: state.question.row_count,
    questionTotalCount: state.question.totalCount,
    // teams: state.team.teams,
    // teamCurrentPage: state.team.currentPage,
    // teamRowCount: state.team.teamRowCount,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    // getSpecialQuizzes: (pageNumber, row_count) => dispatch(SpecialQuizAction.getSpecialQuizzes(pageNumber, row_count)),
    // searchQuizDetails: (quizId, pageNumber) => dispatch(QuizDetailsAction.searchQuizDetails(quizId, pageNumber)),
    getQuestions: (type, pageNumber, row_count) => dispatch(QuestionAction.getQuestions(type, pageNumber, row_count)),

    getSpecialQuizDetailsLists: (specialQuizId, pageNumber, row_count) => dispatch(SpecialQuizDetailsAction.getSpecialQuizDetailsLists(specialQuizId, pageNumber, row_count)),
    createSpecialQuizDetails: (special_quiz_detail) => dispatch(SpecialQuizDetailsAction.createSpecialQuizDetails(special_quiz_detail)),
    updateSpecialQuizDetails: (special_quiz_detail) => dispatch(SpecialQuizDetailsAction.updateSpecialQuizDetails(special_quiz_detail)),
    deleteSpecialQuizDetails: (id) => dispatch(SpecialQuizDetailsAction.deleteSpecialQuizDetails(id)),
    // searchQuizDetails: (quizId, pageNumber) => dispatch(SpecialQuizDetailsAction.searchQuizDetails(quizId, pageNumber)),
    // getSpecialQuizzes: (pageNumber, row_count) => dispatch(SpecialQuizAction.getSpecialQuizzes(pageNumber, row_count)),
    // getQuestions: (type, pageNumber, row_count) => dispatch(QuestionAction.getQuestions(type, pageNumber, row_count)),
    // getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
    // searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, countryId, pageNumber))



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecialQuizDetailList))