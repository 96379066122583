
const RoleType = {
    GET_ROLES: "GET_ROLES",
    ADD_ROLE: "ADD_ROLE",
    UPDATE_ROLE: "UPDATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    ROLE_ERROR: "ROLE_ERROR",
    ROLE_SUCCESS: "ROLE_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"
}

export default RoleType