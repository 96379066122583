import TeamLeagueType from './../type/team_league_type';

const initialState = {
    team_leagues: [],
    loading: false,
    error: null,
    success: null,
    totalCount: null,
    currentPage: 1,
    teamName: null,
    leagueId: null,
    totalCountPerPage: null,
    row_count: null,
}

const teamLeagueReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case TeamLeagueType.GET_TEAM_LEAGUES:
            return {
                ...state,
                loading: false,
                team_leagues: actions.payload,
            }
        case TeamLeagueType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload,
            }
        
        case TeamLeagueType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload,
            }
        
        case TeamLeagueType.SEARCH_WITH_TEAM:
            return {
                ...state,
                teamName: actions.payload,
            }
        
        case TeamLeagueType.SEARCH_WITH_LEAGUE:
            return {
                ...state,
                leagueId: actions.payload,
            }
        
        case TeamLeagueType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload,
            }
        
        case TeamLeagueType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload,
            }
        
        case TeamLeagueType.ADD_TEAM_LEAGUE:
            return{
                ...state,
                loading:false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                team_leagues: [
                    actions.payload,
                    ...state.team_leagues,
                ]
            }
            case TeamLeagueType.UPDATE_TEAM_LEAGUE:
                const updateTeamLeague = state.team_leagues.filter(team_league => team_league.id !== actions.payload.id)
                return{
                    ...state,
                    loading: false,
                    team_leagues:[
                        actions.payload,
                        ...updateTeamLeague,
                    ]
                }
            case TeamLeagueType.DELETE_TEAM_LEAGUE:
                
                return{
                    ...state,
                    totalCount: state.totalCount - 1,
                    totalCountPerPage: state.totalCountPerPage - 1,
                    team_leagues: state.team_leagues.filter(team_league => team_league.id !== actions.payload)
                }
        case TeamLeagueType.LOADING: 
            return {
                ...state,
                loading: actions.payload,
            }
        
        case TeamLeagueType.TEAM_LEAGUE_ERROR: 
            return {
                ...state,
                loading: false,
                error: actions.payload
            }
        
        case TeamLeagueType.TEAM_LEAGUE_SUCCESS: 
            return {
                ...state,
                loading: false,
                success: actions.payload
            }
        
        default: return state
    }
}
export default teamLeagueReducer