import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
class LeaderBoardController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showFavTeamList: true,
      showTopFanList: false,
      showQuizzer: false,
      searching: false,
      initialPage: 1,
      usersForFavTeamList: [],
      usersForFavTeam: [],
      topFanUsers: [],
      levelName: '',
      requiredError: '',
      loading: true,
      text: '',
      team_id: '',
      topFanRowCount: CONSTANTS.ROW_COUNT,
      rowCount: CONSTANTS.ROW_COUNT,
      teamRowCount: CONSTANTS.ROW_COUNT,
      initialPageForTopFan: 0,
      initialPageForFavTeam: 0,
      limit: 10,
    }
  }

  showFavTeamList = () => {
    this.setState({
      showTopFanList: false,
      showQuizzer: false,
      showFavTeamList: true,
    })
  }
  showTopFanList = () => {
    this.setState({
      showFavTeamList: false,
      showQuizzer: false,
      showTopFanList: true,
    })
  }
  showQuizzer = () => {
    this.setState({
      showTopFanList: false,
      showFavTeamList: false,
      showQuizzer: true,
    })
  }

  // NextTopFan = () => {
  //   this.setState({
  //     initialPageForTopFan: this.state.initialPageForTopFan + 10,
  //     initialPageForFavTeam: this.state.initialPageForFavTeam + 10,
  //     limit: this.state.showTopFanList ? this.state.limit : (this.state.limit + 10),
  //   }, () => {
  //     this.getUsers();
  //   })
  // }
  // PrevTopFan = () => {
  //   this.setState({
  //     initialPageForTopFan: this.state.initialPageForTopFan - 10,
  //     initialPageForFavTeam: this.state.initialPageForFavTeam - 10,
  //     limit: this.state.showTopFanList ? this.state.limit : (this.state.limit - 10)
  //   }, () => {
  //     this.getUsers();
  //   })
  // }

}

export default LeaderBoardController
