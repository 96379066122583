import { Component } from 'react'

class UserFavouriteTeamCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user_favourite_team: props.isEdit === false ? {
                user_id: "",
                team_id: '',
                active: 1
            } : props.user_favourite_team.user_favourite_team
        }
    }
    userIdProcess = (user_id) => {
        this.setState((prevState) => ({
            ...prevState,
            user_favourite_team: {
                ...prevState.user_favourite_team,
                user_id: user_id
            }
        }))
    }
    teamIdProcess = (team_id) => {
        this.setState((prevState) => ({
            ...prevState,
            user_favourite_team: {
                ...prevState.user_favourite_team,
                team_id: team_id
            }
        }))
    }

    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user_favourite_team: {
                ...prevState.user_favourite_team,
                active: e.target.value
            }
        }))
    }
}

export default UserFavouriteTeamCreateController