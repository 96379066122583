import axios from 'axios';
import CONSTANTS from './../constants';
import LeagueType from './../type/league_type';

export const Error = (data) => {
    return {
        type: LeagueType.LEAGUE_ERROR,
        payload: data
    }
}
export const Success = (data) => {
    return {
        type: LeagueType.LEAGUE_SUCCESS,
        payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: LeagueType.LOADING, payload: status });
    }
}

const getLeagues = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}leagues/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.GET_LEAGUES,
                    payload: response.data.data
                })
                dispatch({
                    type: LeagueType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: LeagueType.GET_CURRENT_PAGE,
                    payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LeagueType.TOTAL_COUNT_PER_PAGE,
                    payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: LeagueType.ROW_COUNT,
                    payload: row_count
                })
            } else {
                let message = "Failed";
                dispatch({
                    type: LeagueType.ERROR,
                    payload: message,
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LeagueType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllLeagues = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}leagues/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.GET_LEAGUES,
                    payload: response.data.data
                })

                dispatch({
                    type: LeagueType.ROW_COUNT,
                    payload: row_count
                })
            } else {
                let message = "Failed";
                dispatch({
                    type: LeagueType.ERROR,
                    payload: message,
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LeagueType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createLeague = (league) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}leagues/create`, league).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.ADD_LEAGUE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateLeague = (league) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}leagues/update`, league).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.UPDATE_LEAGUE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
        })
    }
}

const deleteLeague = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}leagues/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.DELETE_LEAGUE, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: LeagueType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LeagueType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchItem = (text, countryId, pageNumber) => {
    return (dispatch) => {
        // let path;
        // if (countryId && text) {
        //     path = `keyword=${text}&country_id=${countryId}`
        // }
        // else if (text) {
        //     path = `keyword=${text}`
        // }
        // else if (countryId) {
        //     path = `country_id=${countryId}`
        // }
        // return axios.post(`${CONSTANTS.BASE_URL}leagues/list?page_at=${pageNumber}&${path}`).then(response => {
        return axios.post(`${CONSTANTS.BASE_URL}leagues/list?page_at=${pageNumber}&keyword=${text}&country_id=${countryId}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.GET_LEAGUES, payload: response.data.data
                })
                dispatch({
                    type: LeagueType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: LeagueType.TOTAL_COUNT_PER_PAGE,
                    payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: LeagueType.GET_CURRENT_PAGE,
                    payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LeagueType.SEARCH_ITEM,
                    payload: text
                })
                dispatch({
                    type: LeagueType.SEARCH_WITH_COUNTRY,
                    payload: countryId
                })
            }
        })
    }
}
const searchLeague = (text, countryId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}leagues/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeagueType.GET_LEAGUES, payload: response.data.data
                })
                dispatch({
                    type: LeagueType.SEARCH_ITEM,
                    payload: text
                })
                dispatch({
                    type: LeagueType.SEARCH_WITH_COUNTRY,
                    payload: countryId
                })
            }
        })
    }
}

const LeagueAction = {
    getLeagues,
    getAllLeagues,
    createLeague,
    updateLeague,
    deleteLeague,
    searchItem,
    searchLeague
}
export default LeagueAction;