import React from 'react'
import FutureQuizAnswerCreateController from './FutureQuizAnswerCreateController';
import { Input, Button, SelectModal } from '../../../../components/ImportsFile'
import SelectModalForAnswer from './../../SelectModalForAnswer';

class FutureQuizAnswerCreate extends FutureQuizAnswerCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Correct Answer' : 'Add Correct Answer'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ width: '50%', margin: 'auto' }}>
                <Input type="text" name="quiz_id" value={this.props.quizInfo && this.props.quizInfo?.Question?.question} placeholder="Enter Quiz..." disabled />

                {
                  (this.props.quizInfo.type === 1 || this.props.quizInfo.type === 3) &&
                  <SelectModalForAnswer
                    routeChangeName={"Player"}
                    answerForPlayers={this.props.players}
                    teams={this.props.teams}
                    text={this.props.teamText}
                    leagueId={this.props.league_id}
                    teamRowCount={this.props.teamRowCount}
                    teamCurrentPage={this.props.teamCurrentPage}
                    searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                    type={this.props.quizInfo.type}
                    quizType={this.props.quizInfo.quiz_type}
                    LoadMore={this.props.loadMorePlayers}
                    searchPlayer={this.props.searchPlayer}
                    stopPlayerSearching={this.props.searchPlayer}
                    playerCurrentPage={this.props.playerCurrentPage}
                    getPlayers={this.props.getPlayers}
                    playerRowCount={this.props.playerRowCount}
                    playerTotalCount={this.props.playerTotalCount}
                    playerTotalCountPerPage={this.props.playerTotalCountPerPage}
                    playerRowCounts={this.props.playerRowCount}
                    handleProcess={(id) => this.handleSelectId(id)}
                    // handleSelectAnswerId={(id) => this.handleSelectId(id)}
                    loadMoreTeams={this.props.loadMoreTeams}
                    handleProcessName={(answer) => this.handleSelectName(answer)
                    }
                    // handleSelectAnswerName={(answer) => this.handleSelectName(answer)
                    // }
                    itemName={this.props.isEdit ? this.props.quiz_answer.quiz_answer?.win_case : `*** Choose Player ***`}
                  />
                }
                {
                  this.props.quizInfo.type === 2 &&
                  <SelectModal
                    routeChangeName="team"
                    team_leagues={this.props.teams}
                    LoadMore={this.props.loadMoreTeams}
                    league_id={this.props.league_id}
                    searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                    currentPage={this.props.teamCurrentPage}
                    getItems={this.props.getTeams}
                    rowCount={this.props.teamRowCount}
                    handleSelectAnswerId={(id) => this.handleSelectId(id)}
                    handleSelectAnswerName={(answer) => this.handleSelectName(answer)}
                    itemName={this.props.isEdit ? this.props.quiz_answer.quiz_answer?.win_case : "*** Choose Teams ***"}
                    stopSearching={this.props.searchWithLeagueAndTeamName}
                  />
                }
                <br />
                <br />
                <br />

                <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.quiz_answer)} />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default FutureQuizAnswerCreate
