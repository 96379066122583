import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'
class BarChart extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        let data = {
            labels: this.props.BarLabels,
            datasets: [
                {
                    label: 'Total Quiz Counts',
                    data: this.props.quizCount,
                    borderColor: ['#FAD230'],
                    backgroundColor: ['#FAD230'],
                    // backgroundColor: ['#ebf2b8'],
                    pointBackgorundColor: ['#FAD230'],
                    pointBorderColor: ['#FAD230'],
                    borderWidth: 1

                }
            ],
        }
        return <Bar data={data} />

    }
}

export default BarChart