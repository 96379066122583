import { Component } from 'react'
import CONSTANTS from '../../../redux/constants';
import axios from 'axios';
import moment from 'moment'
class SpecialQuizListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            date: '',
            leagueId: '',
            isCurrent: "true",
            quiz_type: '',
            userId: '',
            special_quiz: {},
            matchRowCount: CONSTANTS.ROW_COUNT,
            leagueRowCount: CONSTANTS.ROW_COUNT,
            countryRowCount: CONSTANTS.ROW_COUNT,
            rowCount: CONSTANTS.ROW_COUNT,
            requiredError: '',
            // leagues: [
            //     {
            //         name: 'Premier League',
            //         id: 47,
            //         ccode: "ENG"
            //     },
            //     {
            //         name: 'Champion League',
            //         id: 42,
            //         ccode: "INT"
            //     },
            //     {
            //         name: 'Europa League',
            //         id: 73,
            //         ccode: "INT"
            //     },
            //     {
            //         name: 'Spain La Liga',
            //         id: 87,
            //         ccode: "INT"
            //     },
            //     {
            //         name: 'Italy Serie A',
            //         id: 55,
            //         ccode: "INT"
            //     },
            //     {
            //         name: 'France Ligue 1',
            //         id: 53,
            //         ccode: "INT"
            //     },
            // ],
        }
    }
    componentDidMount = () => {
        this.loadMoreLeagues();
        this.loadMoreCountries();
        this.loadMoreMatches();
        this.getDateRange();
        // this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount);
        // this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount);
    }
    handleSelectQuizType = (e) => {
        this.setState({
            quiz_type: e.target.value == 0 ? '' : e.target.value,
            // leagueId: '',
        }, () => {
            this.getDateRange();
            this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount)
        })
    }
    handleSelect = (e) => {
        this.setState({
            isCurrent: e.target.value,
        })
        this.getDateRange();
    }
    stopSearching = () => {
        this.setState({
            leagueId: '',
            quiz_type: '',
            isCurrent: true,
        }, () => {
            this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount)
        })
    }
    getDateRange = () => {

        let oneYear = 7 * 60 * 60 * 1000 * 24 * 4 * 13;

        let oneWeek = 6 * 60 * 60 * 1000 * 24
        let subTime = (this.state.quiz_type == 2 || this.state.quiz_type == 4) ? oneYear : oneWeek
        let endday = new Date().getTime() - subTime
        let endDate = moment(endday).format('yyyy-MM-DD')
        this.setState({
            date: endDate,
        }, () => {
            this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount);
        })

    }
    handleSelectLeague = (leagueId) => {
        this.setState({
            leagueId: leagueId,
            // quiz_type: ''

        }, () => {
            this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount)
        })

    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount)
    }
    loadMoreMatches = () => {
        this.setState({
            matchRowCount: this.state.matchRowCount + 6
        })
        this.props.getAllMatches(this.state.initialPage, this.state.matchRowCount);
    }
    loadMoreLeagues = () => {
        this.setState({
            leagueRowCount: this.state.leagueRowCount + 6
        })
        this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
    }
    loadMoreCountries = () => {
        this.setState({
            countryRowCount: this.state.countryRowCount + 6
        })
        this.props.getAllCountries(this.state.initialPage, this.state.countryRowCount);
    }

    handleEdit = (special_quiz) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            special_quiz: {
                ...prevState.special_quiz,
                special_quiz
            }
        }))
    }

    createOrUpdate = async (data) => {

        let oneYear = 7 * 60 * 60 * 1000 * 24 * 4 * 13;
        let endday = new Date().getTime() - oneYear
        let endDate = moment(endday).format('yyyy-MM-DD')


        let operator = JSON.parse(localStorage.getItem('operator'));
        let timeStamps;
        if (data.deadline_time) {
            timeStamps = new Date(
                data.deadline_date.split("-")[0],
                parseInt(data.deadline_date.split("-")[1]) - 1,
                data.deadline_date.split("-")[2],
                data.deadline_time.split(":")[0],
                data.deadline_time.split(":")[1],
                0).getTime();
        }
        else {
            timeStamps = new Date(
                data.deadline_date.split("-")[0],
                parseInt(data.deadline_date.split("-")[1]) - 1,
                data.deadline_date.split("-")[2],
                0).getTime();
        }
        let special_quiz = {
            league_id: data.league_id,
            quiz_type: parseInt(data.quiz_type),
            country_id: data.country_id,
            deadline: timeStamps,
            active: data.active,
            deadline_str: data.deadline_str
        }

        if (data.match_id) {
            special_quiz = {
                ...special_quiz, match_id: data.match_id
            }
        }
        if (data.country_id) {
            special_quiz = {
                ...special_quiz, country_id: data.country_id
            }
        }
        if (this.state.isEdit) {
            // let player = this.state.allPlayers.find(player => player.id == id)
            special_quiz = { ...special_quiz, id: data.id, updated_by: operator.id }
            if (special_quiz.league_id === '' || special_quiz.quiz_type === "") {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (this.state.prevSeasonQuiz?.length > 0) {
                this.setState({
                    requiredError: "Already Exist"
                })
            }
            else {
                this.props.updateSpecialQuiz(special_quiz).then(() => {
                    this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.props.currentPage, this.state.rowCount);
                })
                this.hideModal();
            }
        }
        else {
            special_quiz = { ...special_quiz, created_by: operator.id, updated_by: operator.id }

            if (special_quiz.league_id === '' || special_quiz.quiz_type === "") {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                axios.post(`${CONSTANTS.BASE_URL}special_quizzes/list?leagueId=${special_quiz.league_id}&date=${endDate}&quiz_type=${special_quiz.quiz_type}&is_current=true`).then((res) => {
                    if (special_quiz.quiz_type == 2 || special_quiz.quiz_type == 4) {

                        if (res.data.data?.length > 0) {
                            this.setState({
                                requiredError: "Already Exist"
                            })
                        }
                        else {
                            this.props.createSpecialQuiz(special_quiz).then(() => {
                                this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount);
                            })
                            this.setState({
                                requiredError: ''
                            })
                            this.hideModal()
                        }
                    } else {
                        this.props.createSpecialQuiz(special_quiz).then(() => {
                            this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount);
                        })
                        this.setState({
                            requiredError: ''
                        })
                        this.hideModal()
                    }
                })
            }

        }
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    tempCalculate = (id) => {
        axios.post(`${CONSTANTS.BASE_URL}user/wallets/calculate`, { special_quiz_id: id }).then((response) => {
            console.log(response);
        })
    }
}

export default SpecialQuizListController
