import { Component } from 'react'

class RoleCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            role: props.isEdit === false ? {
                name: '',
                description: '',
                active: 1
            } : props.role.role
        }
    }
    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            role: {
                ...prevState.role,
                name: e.target.value,
            }
        }))
    }
    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            role: {
                ...prevState.role,
                description: e.target.value,
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            role: {
                ...prevState.role,
                active: e.target.value
            }
        }))
    }

}
export {
    RoleCreateController
}