import React from 'react'
import { StadiumCreateController } from './StadiumCreateController'
import { Input,Button } from '../../../components/ImportsFile';
class StadiumCreate extends StadiumCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Stadium' : 'Add Stadium'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">

                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div>
                                <Input type="text" name="name" placeholder="Enter Stadium Name..." value={this.state.stadium.name} onChange={this.handleInputName} required />

                                <Input type="number" name="total_seats" placeholder="Enter Total Seats..." value={this.state.stadium.total_seats} onChange={this.handleInputSeat} required />

                                <select name="active" className="select" value={this.state.stadium.active} onChange={this.processActive} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />
                            <Button type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.stadium)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default StadiumCreate
