import React from 'react'
import ActivityListController from './ActivityListController';
import { connect } from 'react-redux';
import { MainContainer, Header, Body } from '../../PagesElement';
import Pagination from './../../../components/pagination/Pagination';
import Search from './../../../components/search/Search';
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import moment from 'moment'
import ActivityLogsAction from './../../../redux/action/activity_logs_action';
import UserAction from './../../../redux/action/user_action';
import Alert from './../../../components/alert/Alert';
class ActivityList extends ActivityListController {
  render() {

    // let activity_types = [
    //   { id: 1, type: "OVERALL" },
    //   { id: 2, type: "LIVE_STREAM" },
    //   { id: 3, type: "HIGHLIGHT" },
    //   { id: 4, type: "LIVE_SCORE" },
    //   { id: 5, type: "QUIZ" },
    //   { id: 6, type: "SPECILA_QUIZ" },
    //   { id: 7, type: "ADS" },
    // ]
    // let activity_actions = [
    //   { id: 1, action: "SURFING" },
    //   { id: 2, action: "REGISTER" },
    //   { id: 3, action: "LOGIN" },
    //   { id: 4, action: "PUBLIC_WATCH" },
    //   { id: 5, action: "GROUP_WATCH" },
    //   { id: 6, action: "SELF_WATCH" },
    //   { id: 7, action: "FAVOURITE_TEAM_WATCH" },
    //   { id: 8, action: "PLAY" },
    // ]
    return (
      <MainContainer>
        <Header>
          <h4>Activity Logs</h4>
          <form className="search activity_search" >
            <div className="input-group">
              <Search
                activity_types={this.state.activity_types}
                searchItem={(type) => this.handleSelectType(type)}
                text={"Activity Type"}
                searchActivityLogs={this.props.searchActivityLogs}
                getItems={this.props.getAllActivityLogs}
                currentPage={this.props.currentPage}
                rowCount={this.state.typeRowCount}
                search={this.state.type ? true : false}
                stopSearching={() => this.props.getAllActivityLogs(this.state.initialPage, this.state.rowCount)}
              />
              <Search
                activity_actions={this.state.activity_actions}
                searchItem={(action) => this.handleSelectAction(action)}
                text={"Activity Action"}
                type={this.state.activity_logs.type}
                searchActivityLogs={this.props.searchActivityLogs}
                getItems={this.props.getAllActivityLogs}
                currentPage={this.props.currentPage}
                rowCount={this.state.typeRowCount}
                search={this.state.action ? true : false}
                stopSearching={() => this.props.getAllActivityLogs(this.state.initialPage, this.state.rowCount)}
              />
              <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
              <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
            </div>
          </form>
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Action</th>
                <th>Last Active</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.activity_logs.map((log, index) => {
                  return (
                    <tr key={index}>
                      <td>{log.User?.user_name}</td>
                      <td>{log.type}</td>
                      <td>{log.action}</td>
                      <td>

                        {
                          log.last_active ? moment(log.last_active).format('ddd,D MMM, YYYY, h:mm A')
                            : "-"
                        }
                      </td>

                    </tr>
                  )
                })
              }
            </tbody>
          </table>



          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
            (!this.props.action && !this.props.type) && this.props.getAllActivityLogs(pageNumber, this.props.row_count);
            (this.props.action || this.props.type || this.props.startDate || this.props.endDate) && this.props.searchActivityLogs(this.state.type, this.state.action, this.props.startDate, this.props.endDate, pageNumber)
          }} />




          <br />
          <br />
        </Body>
      </MainContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    activity_logs: state.activity_log.activity_logs,
    currentPage: state.activity_log.currentPage,
    totalCount: state.activity_log.totalCount,
    error: state.activity_log.error,
    success: state.activity_log.success,
    action: state.activity_log.action,
    type: state.activity_log.type,
    startDate: state.activity_log.startDate,
    endDate: state.activity_log.endDate,
    totalCountPerPage: state.activity_log.totalCountPerPage,
    row_count: state.activity_log.row_count,
    loading: state.activity_log.loading,
    userRowCount: state.user.row_count,
    users: state.user.users,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getAllActivityLogs: (pageNumber, row_count) => dispatch(ActivityLogsAction.getAllActivityLogs(pageNumber, row_count)),
    getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
    searchActivityLogs: (type, action, startDate, endDate, pageNumber) => dispatch(ActivityLogsAction.searchActivityLogs(type, action, startDate, endDate, pageNumber)),



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList)
