import React from 'react'
import { AccessToMatchCreateController } from './AccessToMatchCreateController'
import '../../modal/modal.css'
import { Button } from './../../../components/buttons/Button';
import SelectModal from './../../../components/selectModal/SelectModal';

class AccessToMatchCreate extends AccessToMatchCreateController {

    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Access' : 'Add Access'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div>
                                <SelectModal
                                    routeChangeName="user"
                                    items={this.props.users}
                                    searching={this.props.searchUser}
                                    currentPage={this.props.currentPage}
                                    getItems={this.props.getAllUsers}
                                    rowCount={this.props.userRowCount}
                                    handleProcess={(user_id) => this.handleSelectUser(user_id)}
                                    itemName={this.props.isEdit ? this.props.access_to_match.access_to_match.User.user_name : "*** Choose User ***"}
                                    LoadMore={this.props.loadMore1}
                                />

                                <SelectModal
                                    items={this.props.matches}
                                    routeChangeName="match"
                                    handleProcess={(match_id) => this.handleSelectMatch(match_id)}
                                    getItems={this.props.getAllMatches}
                                    rowCount={this.props.matchRowCount}
                                    itemName={this.props.isEdit ? this.props.access_to_match.access_to_match.Match.HomeTeam.name + " Vs " + this.props.access_to_match.access_to_match.Match.AwayTeam.name : "*** Choose Match ***"}
                                    LoadMore={this.props.loadMore2}
                                />

                                <select name="active" className="select" value={this.state.access_to_match.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />
                            <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.access_to_match)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    AccessToMatchCreate
}
