
const OperatorType = {
    GET_OPERATORS: "GET_OPERATORS",
    ADD_OPERATOR: "ADD_OPERATOR",
    UPDATE_OPERATOR: "UPDATE_OPERATOR",
    DELETE_OPERATOR: "DELETE_OPERATOR",
    OPERATOR_ERROR: "OPERATOR_ERROR",
    OPERATOR_SUCCESS: "OPERATOR_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING:"LOADING",
    ERROR: "ERROR"
}
export default OperatorType