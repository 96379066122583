
import LiveStreamingType from './../type/live_streaming_type';
const initialState = {
    livestreams: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    matchId: null,
    totalCountPerPage: null,
    row_count: 1,
    loading:false,
}
const liveStreamReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case LiveStreamingType.GET_LIVESTREAMS:
            return {
                ...state,
                loading:false,
                livestreams: actions.payload
            }
        case LiveStreamingType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case LiveStreamingType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case LiveStreamingType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case LiveStreamingType.SEARCH_DATA:
            return {
                ...state,
                matchId: actions.payload
            }
        case LiveStreamingType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case LiveStreamingType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case LiveStreamingType.ADD_LIVESTREAM:
            return {
                ...state,
                loading:false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                livestreams: [
                    actions.payload,
                    ...state.livestreams,
                ]
                // livestreams: state.livestreams.concat(actions.payload)
            }
        case LiveStreamingType.UPDATE_LIVESTREAM:
            const updateLivestream = state.livestreams.filter(livestream => livestream.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                livestreams: [
                    actions.payload,
                    ...updateLivestream,
                ]
            }
        case LiveStreamingType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case LiveStreamingType.DELETE_LIVESTREAM:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                livestreams: state.livestreams.filter(livestream => livestream.id !== actions.payload)
            }
        case LiveStreamingType.LIVESTREAM_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case LiveStreamingType.LIVESTREAM_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default liveStreamReducer