import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class CityListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            city: {},
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            countryRowCount: CONSTANTS.ROW_COUNT,
            text: '',
            selected: '',
            requiredError: '',

        }
    }

    componentDidMount = () => {
        this.loadMore();
        this.props.getCities(this.state.initialPage, this.state.rowCount);
    }

    stopSearching = () => {
        this.props.getCities(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
            selected: ''
        })
    }
    loadMore = () => {
        this.props.getAllCountries(this.state.initialPage, this.state.rowCount);
        this.setState({
            rowCount: this.state.rowCount + 6
        })
    }
    handleInputText = e => {
        this.setState({
            text: e.target.value,
            // selected: ''
        })

    }
    handleSelectId = (selected) => {
        this.setState({
            selected,
        })
    }
    testingSearch = (e) => {
        e.preventDefault();
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.selected, this.state.initialPage)
    }

    handleEdit = async (city) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            city: {
                ...prevState.city,
                city
            }
        }))
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,

        })
    }
    createOrUpdate = async (data) => {

        let operator = JSON.parse(localStorage.getItem('operator'));
        let city;
        if (this.state.isEdit) {
            city = {
                id: data.id,
                name: data.name,
                country_id: data.country_id,
                active: data.active,
                updated_by: operator.id
            }
            if (city.name === '' || city.country_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateCity(city).then(() => {
                    this.props.getCities(this.props.currentPage, this.state.rowCount);
                })
                this.hideModal();
            }
        } else {
            city = { ...data, created_by: operator.id, updated_by: operator.id }
            if (city.name === '' || city.country_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                await this.props.createCity(city).then(() => {
                    this.props.getCities(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
}
export {
    CityListController
} 