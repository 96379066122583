const ModuleList = [
  {
    name: "League",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "leagues/list",
    createURL: "leagues/create",
    updateURL: "leagues/update",
    deleteURL: "leagues/delete"
  },
  {
    name: "Team",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "teams/list",
    createURL: "teams/create",
    updateURL: "teams/update",
    deleteURL: "teams/delete"
  },
  {
    name: "Player",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "players/list",
    createURL: "players/create",
    updateURL: "players/update",
    deleteURL: "players/delete"
  },
  {
    name: "Stadium",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "stadiums/list",
    createURL: "stadiums/create",
    updateURL: "stadiums/update",
    deleteURL: "stadiums/delete"
  },
  {
    name: "Match",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "matches/list",
    createURL: "matches/create",
    updateURL: "matches/update",
    deleteURL: "matches/delete"
  },
  {
    name: "Highlight",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "highlights/list",
    createURL: "highlights/create",
    updateURL: "highlights/update",
    deleteURL: "highlights/delete"
  },
  {
    name: "Live Streaming",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "live-streamings/list",
    createURL: "live-streamings/create",
    updateURL: "live-streamings/update",
    deleteURL: "live-streamings/delete"
  },
  {
    name: "Prize History",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "prize_histories/list",
    createURL: "prize_histories/create",
    updateURL: "prize_histories/update",
    deleteURL: "prize_histories/delete"
  },
  {
    name: "Quizzes",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "quizzes/list",
    createURL: "quizzes/create",
    updateURL: "quizzes/update",
    deleteURL: "quizzes/delete"
  },
  {
    name: "Global Level Rule",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "global-level-rules/list",
    createURL: "global-level-rules/create",
    updateURL: "global-level-rules/update",
    deleteURL: "global-level-rules/delete"
  },
  {
    name: "Favourite Team Level Rule",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "favourite-team-level-rules/list",
    createURL: "favourite-team-level-rules/create",
    updateURL: "favourite-team-level-rules/update",
    deleteURL: "favourite-team-level-rules/delete"
  },
  {
    name: "User",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "users/list",
    createURL: "users/create",
    updateURL: "users/update",
    deleteURL: "users/delete"
  },

  {
    name: "Transaction",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "user/transactions//list",
    createURL: "user/transactions/amount-in",
    updateURL: "user/transactions//update",
    deleteURL: "user/transactions//delete"
  },
  {
    name: "Operator",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "operators/list",
    createURL: "operators/create",
    updateURL: "operators/update",
    deleteURL: "operators/delete"
  },
  {
    name: "Role",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "roles/list",
    createURL: "roles/create",
    updateURL: "roles/update",
    deleteURL: "roles/delete"
  },
  {
    name: "Permission",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "permissions/list",
    createURL: "permissions/create",
    updateURL: "permissions/update",
    deleteURL: "permissions/delete"
  },
  {
    name: "Role Permission",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "role_permission/list",
    createURL: "role_permission/create",
    updateURL: "role_permission/update",
    deleteURL: "role_permission/delete"
  },
  {
    name: "Country",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "country/list",
    createURL: "country/create",
    updateURL: "country/update",
    deleteURL: "country/delete"
  },
  {
    name: "City",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "city/list",
    createURL: "city/create",
    updateURL: "city/update",
    deleteURL: "city/delete"
  },
  {
    name: "Township",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "townships/list",
    createURL: "townships/create",
    updateURL: "townships/update",
    deleteURL: "townships/delete"
  },
  {
    name: "Payment Type",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "payment_types/list",
    createURL: "payment_types/create",
    updateURL: "payment_types/update",
    deleteURL: "payment_types/delete"
  },
  {
    name: "Question",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "questions/list",
    createURL: "questions/create",
    updateURL: "questions/update",
    deleteURL: "questions/delete"
  },
  {
    name: "Sponsor",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "sponsor/list",
    createURL: "sponsor/create",
    updateURL: "sponsor/update",
    deleteURL: "sponsor/delete"
  },
  {
    name: "Coin Policy",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "coin_policies/list",
    createURL: "coin_policies/create",
    updateURL: "coin_policies/update",
    deleteURL: "coin_policies/delete"
  },
  {
    name: "Prize",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "prizes/list",
    createURL: "prizes/create",
    updateURL: "prizes/update",
    deleteURL: "prizes/delete"
  },
  {
    name: "Announcement",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "announcements/list",
    createURL: "announcements/create",
    updateURL: "announcements/update",
    deleteURL: "announcements/delete"
  },
  {
    name: "Site Config",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "site-config/list",
    createURL: "site-config/create",
    updateURL: "site-config/update",
    deleteURL: "site-config/delete"
  },
  {
    name: "Version Check",
    list: "List",
    create: "Create",
    update: "Update",
    delete: "Delete",
    listURL: "version_check/list",
    createURL: "version_check/create",
    updateURL: "version_check/update",
    deleteURL: "version_check/delete"
  },
  {
    name: "Wallet",
    list: "List",
    update: "Update",
    listURL: "wallets/list",
    updateURL: "wallets/update",
  },
  {
    name: "User Favourite Team",
    list: "List",
    listURL: "user-fav-team/list",
  },
  {
    name: "User Favourite National Team",
    list: "List",
    listURL: "user-fav-national-team/list",
  },
  {
    name: "User Team Activity",
    list: "List",
    listURL: "user-team-activities/list",
  },
  {
    name: "Leaderboard",
    list: "List",
    listURL: "leaderboard/fav_team_users",
  },
  {
    name: "LiveScore",
    list: "List",
    listURL: "livescore/list",
  },
  {
    name: "Notification",
    list: "List",
    listURL: "notification/list",
  },
  {
    name: "Activity Logs",
    list: "List",
    listURL: "activity_logs/list",
  },
]



const data = [
  { feature: 'League', functions: 'list' },
  { feature: 'League', functions: 'create' },
  { feature: 'League', functions: 'update' },
  { feature: 'League', functions: 'delete' },

  { feature: 'Team', functions: 'list' },
  { feature: 'Team', functions: 'create' },
  { feature: 'Team', functions: 'update' },
  { feature: 'Team', functions: 'delete' },

  { feature: 'Player', functions: 'list' },
  { feature: 'Player', functions: 'create' },
  { feature: 'Player', functions: 'update' },
  { feature: 'Player', functions: 'delete' },

  { feature: 'Stadium', functions: 'list' },
  { feature: 'Stadium', functions: 'create' },
  { feature: 'Stadium', functions: 'update' },
  { feature: 'Stadium', functions: 'delete' },

  { feature: 'Match', functions: 'list' },
  { feature: 'Match', functions: 'create' },
  { feature: 'Match', functions: 'update' },
  { feature: 'Match', functions: 'delete' },

  { feature: 'Highlight', functions: 'list' },
  { feature: 'Highlight', functions: 'create' },
  { feature: 'Highlight', functions: 'update' },
  { feature: 'Highlight', functions: 'delete' },

  { feature: 'Live Streaming', functions: 'list' },
  { feature: 'Live Streaming', functions: 'create' },
  { feature: 'Live Streaming', functions: 'update' },
  { feature: 'Live Streaming', functions: 'delete' },

  { feature: 'Prize History', functions: 'list' },
  { feature: 'Prize History', functions: 'create' },
  { feature: 'Prize History', functions: 'update' },
  { feature: 'Prize History', functions: 'delete' },

  { feature: 'Quizzes', functions: 'list' },
  { feature: 'Quizzes', functions: 'create' },
  { feature: 'Quizzes', functions: 'update' },
  { feature: 'Quizzes', functions: 'delete' },

  { feature: 'Global Level Rule', functions: 'list' },
  { feature: 'Global Level Rule', functions: 'create' },
  { feature: 'Global Level Rule', functions: 'update' },
  { feature: 'Global Level Rule', functions: 'delete' },

  { feature: 'Favourite Team Level Rule', functions: 'list' },
  { feature: 'Favourite Team Level Rule', functions: 'create' },
  { feature: 'Favourite Team Level Rule', functions: 'update' },
  { feature: 'Favourite Team Level Rule', functions: 'delete' },

  { feature: 'User', functions: 'list' },
  { feature: 'User', functions: 'create' },
  { feature: 'User', functions: 'update' },
  { feature: 'User', functions: 'delete' },

  { feature: 'Transaction', functions: 'list' },
  { feature: 'Transaction', functions: 'create' },
  { feature: 'Transaction', functions: 'update' },
  { feature: 'Transaction', functions: 'delete' },

  { feature: 'Operator', functions: 'list' },
  { feature: 'Operator', functions: 'create' },
  { feature: 'Operator', functions: 'update' },
  { feature: 'Operator', functions: 'delete' },

  { feature: 'Role', functions: 'list' },
  { feature: 'Role', functions: 'create' },
  { feature: 'Role', functions: 'update' },
  { feature: 'Role', functions: 'delete' },

  { feature: 'Permission', functions: 'list' },
  { feature: 'Permission', functions: 'create' },
  { feature: 'Permission', functions: 'update' },
  { feature: 'Permission', functions: 'delete' },

  { feature: 'Role Permission', functions: 'list' },
  { feature: 'Role Permission', functions: 'create' },
  { feature: 'Role Permission', functions: 'update' },
  { feature: 'Role Permission', functions: 'delete' },

  { feature: 'Country', functions: 'list' },
  { feature: 'Country', functions: 'create' },
  { feature: 'Country', functions: 'update' },
  { feature: 'Country', functions: 'delete' },

  { feature: 'City', functions: 'list' },
  { feature: 'City', functions: 'create' },
  { feature: 'City', functions: 'update' },
  { feature: 'City', functions: 'delete' },

  { feature: 'Township', functions: 'list' },
  { feature: 'Township', functions: 'create' },
  { feature: 'Township', functions: 'update' },
  { feature: 'Township', functions: 'delete' },

  { feature: 'Payment Type', functions: 'list' },
  { feature: 'Payment Type', functions: 'create' },
  { feature: 'Payment Type', functions: 'update' },
  { feature: 'Payment Type', functions: 'delete' },

  { feature: 'Question', functions: 'list' },
  { feature: 'Question', functions: 'create' },
  { feature: 'Question', functions: 'update' },
  { feature: 'Question', functions: 'delete' },

  { feature: 'Sponsor', functions: 'list' },
  { feature: 'Sponsor', functions: 'create' },
  { feature: 'Sponsor', functions: 'update' },
  { feature: 'Sponsor', functions: 'delete' },

  { feature: 'Coin Policy', functions: 'list' },
  { feature: 'Coin Policy', functions: 'create' },
  { feature: 'Coin Policy', functions: 'update' },
  { feature: 'Coin Policy', functions: 'delete' },

  { feature: 'Prize', functions: 'list' },
  { feature: 'Prize', functions: 'create' },
  { feature: 'Prize', functions: 'update' },
  { feature: 'Prize', functions: 'delete' },

  { feature: 'Announcement', functions: 'list' },
  { feature: 'Announcement', functions: 'create' },
  { feature: 'Announcement', functions: 'update' },
  { feature: 'Announcement', functions: 'delete' },

  { feature: 'Site Config', functions: 'list' },
  { feature: 'Site Config', functions: 'create' },
  { feature: 'Site Config', functions: 'update' },
  { feature: 'Site Config', functions: 'delete' },

  { feature: 'Version Check', functions: 'list' },
  { feature: 'Version Check', functions: 'create' },
  { feature: 'Version Check', functions: 'update' },
  { feature: 'Version Check', functions: 'delete' },

  { feature: 'Wallet', functions: 'list' },
  { feature: 'Wallet', functions: 'update' },

  { feature: 'User Favourite Team', functions: 'list' },
  { feature: 'User Favourite National Team', functions: 'list' },
  { feature: 'User Team Activity', functions: 'list' },
  { feature: 'Leaderboard', functions: 'list' },
  { feature: 'LiveScore', functions: 'list' },
  { feature: 'Notification', functions: 'list' },
  { feature: 'Activity Logs', functions: 'list' },

]
export { ModuleList, data }