import axios from "axios"
import CONSTANTS from './../constants';
import OddsRateType from './../type/odds_reate_type';

export const Error = (data) => {
  return {
    type: OddsRateType.ODDS_RATE_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: OddsRateType.ODDS_RATE_SUCCESS, payload: data
  }
}

const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: OddsRateType.LOADING, payload: status })
  }
}

const toggleEdit = (item) => {
  return (dispatch) => {
    return dispatch({
      type: OddsRateType.TOGGLE_EDIT, payload: item
    })
  }
}
const getOddsRates = (specialQuizDetailId, pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}odds_rates/list?special_quiz_detail_id=${specialQuizDetailId}&page_at=${pageNumber}&row_count=${row_count}`).then(response => {
      if (response.data.status === 'Success') {
        let data = response.data.data.map(item => {
          item.isEdit = false;
          return item
        })
        dispatch({
          type: OddsRateType.GET_ODDS_RATES, payload: data
        })
        dispatch({
          type: OddsRateType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: OddsRateType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: OddsRateType.TOTAL_PAGE, payload: response.data.pagination.total_page
        })
        dispatch({
          type: OddsRateType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: OddsRateType.ROW_COUNT,
          payload: row_count
        })
        dispatch({
          type: OddsRateType.SEARCH_WITH_SPECIAL_QUIZ_DETAIL_ID,
          payload: specialQuizDetailId
        })
      } else {
        let message = "custom error message"
        dispatch({
          type: OddsRateType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: OddsRateType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const getAllOddsRates = (pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}odds_rates/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {

      if (response.data.status === 'Success') {
        dispatch({
          type: OddsRateType.GET_ODDS_RATES, payload: response.data.data
        })
        dispatch({
          type: OddsRateType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: OddsRateType.ROW_COUNT,
          payload: row_count
        })
      } else {
        let message = "custom error message"
        dispatch({
          type: OddsRateType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: OddsRateType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const addOddsRate = (odds_rate) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}odds_rates/create`, odds_rate).then(response => {
      if (response.data.status === 'Success' || response.data.message === 'Success') {
        dispatch({
          type: OddsRateType.ADD_ODDS_RATE,
          payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }

}
const updateOddsRate = (odds_rate) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}odds_rates/update`, odds_rate).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: OddsRateType.UPDATE_ODDS_RATE, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)

    })
  }
}
const deleteOddsRate = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'));
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}odds_rates/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: OddsRateType.DELETE_ODDS_RATE, payload: id,
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "custom error message"
        dispatch({
          type: OddsRateType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: OddsRateType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}


const OddsRateAction = {
  getOddsRates,
  toggleEdit,
  getAllOddsRates,
  addOddsRate,
  updateOddsRate,
  deleteOddsRate,
  loading,
}

export default OddsRateAction;