
import QuestionType from './../type/question_type';
const initialState = {
    questions: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
    type: null,
}
const questionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case QuestionType.GET_QUESTIONS:
            return {
                ...state,
                loading: false,
                questions: actions.payload

            }
        case QuestionType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case QuestionType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case QuestionType.QUESTION_TYPE:
            return {
                ...state,
                type: actions.payload
            }
        case QuestionType.SEARCH_WITH_MATCH:
            return {
                ...state,
                matchId: actions.payload
            }
        case QuestionType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case QuestionType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case QuestionType.ADD_QUESTION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                questions: [
                    actions.payload,
                    ...state.questions,
                ]
            }
        case QuestionType.UPDATE_QUESTION:
            const updatequestions = state.questions.filter(question => question.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                questions: [
                    actions.payload,
                    ...updatequestions,
                ]
            }
        case QuestionType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case QuestionType.DELETE_QUESTION:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                questions: state.questions.filter(question => question.id !== actions.payload),
            }
        case QuestionType.QUESTION_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case QuestionType.QUESTION_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default questionReducer