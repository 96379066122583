import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class QuestionlistController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      question: {},
      rowCount: CONSTANTS.ROW_COUNT,
      requiredError: '',
      quiz_type: '',
    }
  }
  componentDidMount = () => {
    this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.rowCount);
  }
  handleSelectType = (quiz_type) => {
    this.setState({
      quiz_type: quiz_type,
    }, () => {
      this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.rowCount)
    })

  }
  stopSearching = () => {
    this.setState({
      quiz_type: ''
    })
    this.props.getQuestions('', this.state.initialPage, this.state.rowCount)
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.rowCount)
  }
  handleEdit = (question) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      question: {
        ...prevState.question,
        question
      }
    }))
  }
  createOrUpdate = (data) => {

    let operator = JSON.parse(localStorage.getItem('operator'));
    let questions = {
      question: data.question,
      quiz_type: data.quiz_type,
      win_reward: data.win_reward ? data.win_reward : '0',
      redeem_points: data.redeem_points,
      active: data.active,
      updated_by: operator.id,
    }
    if (data.question_type) {
      questions = { ...questions, question_type: data.question_type }
    }
    if (data.future_type) {
      questions = { ...questions, future_type: data.future_type }
    }
    if (this.state.isEdit) {
      questions = { ...questions, id: data.id }
      if (questions.question === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateQuestion(questions).then(() => {
          this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.rowCount);
        })
        this.hideModal()
      }
    }
    else {
      questions = { ...questions, created_by: operator.id, }
      if (questions.question === "" || questions.quiz_type === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createQuestion(questions).then(() => {
          this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal()
      }
    }
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default QuestionlistController