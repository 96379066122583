import React, { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class AnnouncementListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      show: false,
      isEdit: false,
      initialPage: 1,
      announcement: {},
      requiredError: '',
      rowCount: CONSTANTS.ROW_COUNT
    }
  }
  componentDidMount = () => {
    this.props.getAnnouncements(this.state.initialPage, this.state.rowCount)
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchAnnouncement(this.state.text, this.state.initialPage)
  }
  stopSearching = () => {
    this.setState({
      text: ''
    }, () => {
      this.props.getAnnouncements(this.state.initialPage, this.state.rowCount)
    })
  }
  handleEdit = async (announcement) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      announcement: {
        ...prevState.announcement,
        announcement
      }
    }))
  }
  showModal = () => {
    this.setState({
      show: true
    })
  }
  hideModal = () => {
    this.setState({
      show: false,
      isEdit: false
    })
  }
  createOrUpdate = (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    let announcement = {
      description: data.description,
      image_url: data.image_url,
      go_to_screen: parseInt(data.go_to_screen),
      active: data.active,
    }
    if (data.title) {
      announcement = { ...announcement, title: data.title }
    }
    if (data.start_date) {
      announcement = { ...announcement, start_date: new Date(data.start_date).getTime() }
    }
    if (data.end_date) {
      announcement = { ...announcement, end_date: new Date(data.end_date).getTime() }
    }
    if (data.button_text) {
      announcement = { ...announcement, button_text: data.button_text }
    }
    if (data.button_icon) {
      // button_icon_${ original_image_name }
      announcement = { ...announcement, button_icon: data.button_icon }
    }
    if (data.destination) {
      announcement = { ...announcement, destination: parseInt(data.destination) }
    }
    if (data.destinatio_team_id) {
      announcement = { ...announcement, destinatio_team_id: parseInt(data.destinatio_team_id) }
    }
    if (this.state.isEdit) {
      announcement = { ...announcement, id: data.id }
      let formData = new FormData();
      formData.append("id", announcement.id)
      formData.append("description", announcement.description)
      formData.append("go_to_screen", announcement.go_to_screen)
      formData.append("active", announcement.active)
      formData.append("updated_by", parseInt(operator.id))
      if (announcement.title) {
        formData.append('title', announcement.title)
      }
      if (announcement.start_date) {
        formData.append('start_date', announcement.start_date)
      }
      if (announcement.end_date) {
        formData.append('end_date', announcement.end_date)
      }
      if (announcement.button_text) {
        formData.append('button_text', announcement.button_text)
      }
      if (announcement.destination) {
        formData.append('destination', announcement.destination)
      }
      if (announcement.destination_team_id) {
        formData.append('destination_team_id', announcement.destination_team_id)
      }
      if (typeof announcement.image_url !== "string") {
        formData.append("image_url", announcement.image_url)
      }
      if (typeof announcement.button_icon !== "string") {
        formData.append("button_icon", announcement.button_icon)
      }
      if (announcement.go_to_screen === "" || announcement.active === "" || announcement.button_text === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (announcement.image_url === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.updateAnnouncement(formData).then(() => {
          this.props.getAnnouncements(this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }

    }
    else {
      announcement = { ...announcement, created_by: operator.id, updated_by: operator.id }
      let formData = new FormData();
      formData.append("description", announcement.description)
      formData.append("image_url", announcement.image_url)
      formData.append("go_to_screen", announcement.go_to_screen)
      formData.append("active", announcement.active)
      formData.append("created_by", parseInt(announcement.created_by))
      formData.append("updated_by", parseInt(announcement.updated_by))
      if (announcement.title) {
        formData.append('title', announcement.title)
      }
      if (announcement.start_date) {
        formData.append('start_date', announcement.start_date)
      }
      if (announcement.end_date) {
        formData.append('end_date', announcement.end_date)
      }
      if (announcement.button_text) {
        formData.append('button_text', announcement.button_text)
      }
      if (announcement.button_icon) {
        formData.append('button_icon', announcement.button_icon)
      }
      if (announcement.destination) {
        formData.append('destination', announcement.destination)
      }
      if (announcement.destination_team_id) {
        formData.append('destination_team_id', announcement.destination_team_id)
      }


      if (announcement.button_icon === "" || announcement.go_to_screen === "" || announcement.button_text === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (announcement.image_url === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.createAnnouncement(formData).then(() => {
          this.props.getAnnouncements(this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }

}

export default AnnouncementListController
