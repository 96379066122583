import { Component } from 'react'
class TransactionsCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            zoom: false,
            transaction: props.isEdit === false ? {
                // id: '',
                payment_type_id: '',
                user_id: '',
                amount_in: '',
                amount_out: '',
                add_points: '',
                remove_points: '',
                description: '',
                payment_screenshot: '',
                active: 1,
                // amount:'',
            } : props.transaction.transaction
        }

    }
    paymentTypeChange = (payment_type_id) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                payment_type_id: payment_type_id
            }
        }))
    }
    userIdChange = (user_id) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                user_id: user_id
            }
        }))
    }
    AddPointsProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                add_points: e.target.value
            }
        }))
    }
    RemovePointsProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                remove_points: e.target.value
            }
        }))
    }

    AmountInProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                amount_in: e.target.value
            }
        }))
    }
    AmountOutProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                amount_out: e.target.value
            }
        }))
    }

    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                description: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            transaction: {
                ...prevState.transaction,
                active: e.target.value
            }
        }))
    }
    onChangeImage = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            this.setState((prevState) => ({
                ...prevState,
                transaction: {
                    ...prevState.transaction,
                    payment_screenshot: e.target.files[0]
                }
            }))
        }
    }
    zoomToggle = () => {
        this.setState({
            zoom: !this.state.zoom,
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createOrUpdate(this.state.transaction)
    }
}
export {
    TransactionsCreateController
}