import { Component } from 'react';
class AccessToMatchCreateController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_to_match: props.isEdit === false ? {
                user_id: '',
                match_id: '',
                active: 1
            } : props.access_to_match.access_to_match
        }
    }
    handleSelectUser = (user_id) => {
        this.setState((prevState) => ({
            ...prevState,
            access_to_match: {
                ...prevState.access_to_match,
                user_id: user_id
            }
        }))
    }
    handleSelectMatch = (match_id) => {
        this.setState((prevState) => ({
            ...prevState,
            access_to_match: {
                ...prevState.access_to_match,
                match_id: match_id
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            access_to_match: {
                ...prevState.access_to_match,
                active: e.target.value
            }
        }))
    }
}

export {
    AccessToMatchCreateController
}