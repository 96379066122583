
import axios from 'axios'
import CONSTANTS from './../constants';
import PermissionType from './../type/permission_type';

export const Error = (data) => {
    return {
        type: PermissionType.PERMISSION_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: PermissionType.PERMISSION_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: PermissionType.LOADING, payload: status })
    }
}
const getPermissions = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}permissions/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=updated_at&order_by=DESC`).then(response => {

            if (response.data.status === "Success") {
                dispatch({
                    type: PermissionType.GET_PERMISSIONS,
                    payload: response.data.data
                })
                dispatch({
                    type: PermissionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: PermissionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: PermissionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: PermissionType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: PermissionType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PermissionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllPermissions = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}permissions/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PermissionType.GET_PERMISSIONS,
                    payload: response.data.data
                })
                dispatch({
                    type: PermissionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: PermissionType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: PermissionType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PermissionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createPermission = (permission) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}permissions/create`, permission).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PermissionType.ADD_PERMISSION, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updatePermission = (permission) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}permissions/update`, permission).then((response) => {
            if (response.data.code === 200) {
                dispatch({
                    type: PermissionType.UPDATE_PERMISSION, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deletePermission = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}permissions/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: PermissionType.DELETE_PERMISSION, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed to delete"
                dispatch({
                    type: PermissionType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PermissionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}permissions/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PermissionType.GET_PERMISSIONS, payload: response.data.data
                })
                dispatch({
                    type: PermissionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: PermissionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: PermissionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: PermissionType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}
const searchPermission = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}permissions/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PermissionType.GET_PERMISSIONS, payload: response.data.data
                })
            }
            return dispatch({
                type: PermissionType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}


const PermissionAction = {
    getPermissions,
    getAllPermissions,
    createPermission,
    updatePermission,
    deletePermission,
    searchItem,
    searchPermission
}

export default PermissionAction;