import React from 'react'
import QuizDetailFutureCreateController from './QuizDetailFutureCreateController';
import { Button } from '../../../../components/buttons/Button';
import SelectModal from '../../../../components/selectModal/SelectModal';
class QuizDetailFutureCreate extends QuizDetailFutureCreateController {
  render() {
    const styles = {
      match_name: { color: "#ffffff", alignItems: 'center', fontSize: '15px', fontWeight: 'normal' },
      form: { width: '50%', margin: 'auto' }
    };

    const type = [
      { id: 1, name: 'Player' },
      { id: 2, name: 'Team' },
      { id: 3, name: 'Manager' },
    ]

    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Quiz Details' : 'Add Quiz Details'}</h3>

          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
          <form autoComplete="off" style={styles.form}>
          {
            this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
          }
              <div>
                <SelectModal
                  items={type}
                  // routeChangeName="match_quiz"
                  handleProcess={(typeId) => this.handleSelectType(typeId)}
                  itemName={
                    this.props.isEdit ?
                      (
                        this.props.future_quiz_details.future_quiz_details.type == 1 && "Player" ||
                        this.props.future_quiz_details.future_quiz_details.type == 2 && "Team" ||
                        this.props.future_quiz_details.future_quiz_details.type == 3 && "Manager"
                      )
                      :
                      "*** Choose Type ***"}
                />
                <SelectModal
                  questions={this.props.questions}

                  // noQuestion={this.props.questions.length < 1 ? "noQuestion" : ""}
                  handleSelectQuestion={(questionId) => this.handleSelectQuestion(questionId)}
                  itemName={
                    this.props.isEdit ?
                      this.props.future_quiz_details.future_quiz_details?.Question?.question
                      :
                      "*** Choose Question ***"}
                />


                <select name="active" className="select" value={this.state.future_quiz_details.active} onChange={this.handleSelect} required>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
              <br />
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => {
                this.props.createOrUpdate(this.state.future_quiz_details)
              }
              } />

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default QuizDetailFutureCreate
