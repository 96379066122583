import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
class MenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menu: this.props.menu,
      isOpen: false,
    }
  }
  openChildMenu = () => this.setState((currentState) => ({
    isOpen: !currentState.isOpen,
  }))

  // openChildMenu = () => {
  //   console.log('openChildMenu')
  //   this.setState({
  //     isOpen: !this.state.isOpen
  //   })
  // }
  render() {
    return (
      <div>

        {
          this.state.menu.route ?
            <Link to={`${this.state.menu.route}`} >
              <div className="nav-item">
                {this.state.menu.icon} &nbsp;
                <span>
                  {
                    this.props.showMenuItem && this.state.menu.name
                  }
                </span>
              </div>
            </Link>
            :
            <div>
              {
                // (this.state.menu?.child) &&
                <div>
                  <div className="nav-item" onClick={this.openChildMenu} >
                    {this.state.menu.icon} &nbsp;
                    <span>
                      {
                        this.props.showMenuItem && this.state.menu.name
                      }
                    </span>
                    {
                      this.state.menu?.child?.length > 0 && (this.state.isOpen ?
                        <FontAwesomeIcon icon={faAngleDown} /> :
                        <FontAwesomeIcon icon={faAngleRight} />
                      )
                    }
                  </div>
                  {
                    this.state.isOpen && this.state.menu?.child?.map((childMenu, idx) => {
                      return <ChildMenu key={idx} menu={childMenu} showMenuItem={this.props.showMenuItem} />
                    })
                  }
                </div>
              }
            </div>
        }
      </div>
    )
  }
}

const ChildMenu = ({ menu, showMenuItem }) => {
  return (
    <div className="nav-item-child">
      <Link to={`${menu.route}`} >
        <div className="nav-item">
          {menu.icon} &nbsp;
          <span>
            {
              showMenuItem && menu.name
            }
          </span>
        </div>
      </Link>
    </div>
  )
}
export default MenuItem
