import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class CountryListController extends Component {
    constructor(props) {
        super(props)


        this.state = {
            show: false,
            searchTerm: '',
            text: '',
            isEdit: false,
            initialPage: 1,
            country: {},
            requiredError: '',
            rowCount: CONSTANTS.ROW_COUNT,
        }
    }

    componentDidMount = () => {
        this.props.getCountries(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.setState({
            text: ''
        }, () => {
            this.props.getCountries(this.state.initialPage, this.state.rowCount);
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }
    handleEdit = async (country) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            country: {
                ...prevState.country,
                country
            }
        }))
    }

    hideModal = () => {
        this.setState({
            show: false
        })
    }

    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }

    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let country = {
            flag: data.flag,
            name: data.name,
            country_code: data.country_code,
            active: data.active,
            updated_by: operator.id,
        }

        if (this.state.isEdit) {
            country = { ...country, id: data.id }
            let formData = new FormData();
            formData.append("id", country.id)
            formData.append("name", country.name)
            formData.append("active", country.active)
            formData.append("updated_by", parseInt(operator.id))
            if (data.country_code) {
                formData.append("country_code", data.country_code)
            }
            if (typeof country.flag !== "string") {
                formData.append("flag", country.flag)
            }
            if (country.name === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (country.flag === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.updateCountry(formData).then(()=>{
                    this.props.getCountries(this.state.initialPage, this.state.rowCount)
                })
                this.hideModal();
            }

        } else {
            country = { ...country, created_by: operator.id, updated_by: operator.id }
            let formData = new FormData();
            formData.append("name", country.name)
            formData.append("flag", country.flag)
            formData.append("active", country.active)
            formData.append("created_by", parseInt(operator.id))
            formData.append("updated_by", parseInt(operator.id))
            if (data.country_code) {
                formData.append("country_code", data.country_code)
            }
            if (country.name === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (country.flag === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.createCountry(formData).then(()=>{
                    this.props.getCountries(this.state.initialPage, this.state.rowCount)
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
}

export { CountryListController }
