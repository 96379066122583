const TeamType = {
    GET_TEAMS: "GET_TEAMS",
    ADD_TEAM: "ADD_TEAM",
    UPDATE_TEAM: "UPDATE_TEAM",
    DELETE_TEAM: "DELETE_TEAM",
    TEAM_ERROR: "TEAM_ERROR",
    TEAM_SUCCESS: "TEAM_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    LOADING: "LOADING",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_WITH_STADIUMID: "SEARCH_WITH_STADIUMID",
    SEARCH_WITH_LEAGUEID: "SEARCH_WITH_LEAGUEID",
    SEARCH_WITH_COUNTRYID: "SEARCH_WITH_COUNTRYID",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    ERROR: "ERROR"
}
export default TeamType