import { Component } from 'react'

class PaymentTypeCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            payment_type: props.isEdit === false ? {
                name: '',
                description: '',
                logo_url: '',
                active: 1
            } : props.payment_type.payment_type
        }
    }
    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            payment_type: {
                ...prevState.payment_type,
                name: e.target.value
            }
        }))
    }
    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            payment_type: {
                ...prevState.payment_type,
                description: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            payment_type: {
                ...prevState.payment_type,
                active: e.target.value
            }
        }))
    }
    onChangeImage = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            this.setState((prevState) => ({
                ...prevState,
                payment_type: {
                    ...prevState.payment_type,
                    logo_url: e.target.files[0]
                }
            }))
        }
    }
}
export {
    PaymentTypeCreateController
}
