import { Component } from 'react'
import { CONSTANTS, axios } from '../../../components/ImportsFile';
import moment from 'moment'
class SpecialQuizDetailListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      specialQuizId: '',
      quizInfo: {},
      quiz_type: '',
      previousQuestions: [],
      special_quiz_details: {},
      rowCount: CONSTANTS.ROW_COUNT,
      questionRowCount: CONSTANTS.ROW_COUNT,
      requiredError: '',

    }
  }
  componentDidMount = async () => {
    let id = this.props.match.params.id && this.props.match.params.id
    // this.loadMore();
    await axios.post(`${CONSTANTS.BASE_URL}special_quizzes/detail`, { id: id }).then(response => {
      let value = response.data.data
      if (id == value.id) {
        this.setState({
          quizInfo: value,
          quiz_type: value.quiz_type,
          specialQuizId: id
        })
      }
    })



    this.loadMoreQuestion();
    this.getPreviousQuestion();
    this.props.getSpecialQuizDetailsLists(id, this.state.initialPage, this.state.rowCount);
  }
  getPreviousQuestion = () => {
    let previousQuestions = [];
    axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?special_quiz_id=${this.state.specialQuizId}&page_at=${this.state.initialPage}&row_count=${this.state.rowCount}`).then((response) => {
      let data = response.data.data
      data.map((detail) => {
        previousQuestions.push(detail.question_id)
      })
      this.setState({
        previousQuestions: previousQuestions
      })
    })
  }
  loadMoreQuestion = () => {
    this.setState({
      questionRowCount: this.state.questionRowCount + 6
    })
    this.props.getQuestions(this.state.quiz_type, this.state.initialPage, this.state.questionRowCount)
  }
  handleSelectQuiz = (specialQuizId) => {
    this.setState({
      specialQuizId,
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchQuizDetails(this.state.specialQuizId, this.state.initialPage)
  }
  // loadMore = () => {
  //     this.setState({
  //         QuizRowCount: this.state.QuizRowCount + 6
  //     })
  //     this.props.getQuizzes(this.state.initialPage, this.state.QuizRowCount);
  // }

  handleEdit = (quiz_details) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      quiz_details: {
        ...prevState.quiz_details,
        quiz_details
      }
    }))
  }

  createOrUpdate = async (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'));

    let quiz_details = {
      special_quiz_id: data.special_quiz_id,
      question_id: data.question_id,
      // quiz_type: this.state.quizInfo?.quiz_type,
      quiz_type: data.quiz_type,
      type: data.type,
      submit_type: data.submit_type,
      redeem_point: data.redeem_point,
      answer_count: data.answer_count,
      active: data.active,
      win_reward: data.win_reward,
      updated_by: operator.id,
    }

    if (data.remark) {
      quiz_details = { ...quiz_details, remark: data.remark }
    }


    if (this.state.isEdit) {

      quiz_details = { ...quiz_details, id: data.id }
      if (quiz_details.question === "" || quiz_details.answer_count === '' || quiz_details.win_reward === '' || quiz_details.redeem_point === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }

      else {
        this.props.updateSpecialQuizDetails(quiz_details).then(() => {
          this.props.getSpecialQuizDetailsLists(this.state.specialQuizId, this.props.currentPage, this.state.rowCount);
        })
        this.hideModal()
      }
    }
    else {
      quiz_details = { ...quiz_details, created_by: operator.id }
      if (quiz_details.question_id === "" || quiz_details.answer_count === '' || quiz_details.win_reward === '' || quiz_details.redeem_point === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (this.state.previousQuestions.includes(quiz_details.question_id)) {
        this.setState({
          requiredError: "This question is already exist"
        })
      }
      else {
        this.props.createSpecialQuizDetails(quiz_details).then(() => {
          this.props.getSpecialQuizDetailsLists(this.state.specialQuizId, this.state.initialPage, this.state.rowCount);
          this.getPreviousQuestion();
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal()
      }
    }
  }
  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default SpecialQuizDetailListController