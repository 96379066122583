import React from 'react'
import LineupController from './LineupController';
import './Lineup.css'
import { withRouter } from '../../../../components/routers/withRouter'
import fallBackTeam from '../../../../images/icons/fallBackLeague.png'
import fallBackPlayer from '../../../../images/icons/player.png'
import Bench from './Bench';


class Lineup extends LineupController {
  render() {

    return (
      <div>
        <div className="lineup">
          {
            this.state.homeTeam &&
            <div className="teamInfo">
              <img src={`https://images.fotmob.com/image_resources/logo/teamlogo/${this.state.homeTeamLineup?.teamId}.png`} alt="" width="40px" onError={(e) => (e.currentTarget.src = fallBackTeam)} style={{ marginRight: '24px' }} />
              <span className="rating badge"
                style={{
                  backgroundColor: this.state.lineups?.teamRatings?.home?.num > this.state.lineups?.teamRatings?.away?.num ? 'red' : '#202020', marginLeft: '10px',
                  color: this.state.lineups?.teamRatings?.home?.num > this.state.lineups?.teamRatings?.away?.num ? 'white' : '#fad230'
                }}
              >
                {this.state.lineups?.teamRatings?.home?.num && this.state.lineups?.teamRatings?.home?.num.toFixed(1)}

              </span>
              <span className="teamName">{this.state.homeTeamLineup?.teamName}</span>
              <span>{this.state.homeTeamLineup?.lineup}</span>
            </div>
          }
          <div className="pitch">
            <div className="home">

              {
                this.state.homeTeam && Object.values(this.state.homeTeam).map((team, index) => {
                  return (
                    <div className="lineup_row keeper" key={index}>
                      {
                        Object.values(team).map((player, index) => {
                          return (
                            player.positionRow === 0 ?
                              <div className="box1" key={index}>
                                <div className="pen1">
                                  <div className="player">
                                    {
                                      player.timeSubbedOff &&
                                      <span className="arrow-badge">
                                        <small className="timeSubbedOff">{player.timeSubbedOff}</small><br />
                                        <span className="arrow">
                                          <span className="">&#11013;</span>
                                        </span>
                                      </span>
                                    }
                                    <div className="info">
                                      <img src={player.imageUrl} alt="" width="50px" onError={(e) => (e.currentTarget.src = fallBackPlayer)} style={{ marginLeft: '30%' }} />
                                      <p style={{ marginLeft: '15%' }}> {player?.shirt} {player?.name?.firstName} {player?.name?.lastName}</p>
                                    </div>
                                    <span className="rating badge rounded-pill" style={{ backgroundColor: player.rating?.num >= 7.0 ? 'red' : '#202020', color: player.rating?.num >= 7.0 ? 'white' : '#fad230' }}>{player.rating?.num}</span>
                                  </div>
                                </div>
                              </div>
                              :

                              <div className="player" key={index}>

                                {
                                  player.timeSubbedOff &&
                                  <span className="arrow-badge">
                                    <small className="timeSubbedOff">{player.timeSubbedOff}</small><br />
                                    <span className="arrow">
                                      <span className="">&#11013;</span>
                                    </span>
                                  </span>
                                }
                                <div className="info">
                                  <img src={player.imageUrl} alt="" onError={(e) => (e.currentTarget.src = fallBackPlayer)} />
                                  <p> {player?.shirt} {player?.name?.firstName} {player?.name?.lastName}</p>
                                </div>
                                <span className="rating badge rounded-pill" style={{ backgroundColor: player.rating?.num >= 7.0 ? 'red' : '#202020', color: player.rating?.num >= 7.0 ? 'white' : '#fad230' }}>{player.rating?.num}</span>
                              </div>
                          )
                        }
                        )
                      }
                    </div>
                  )
                })
              }

            </div>

            <div className="half"></div>

            <div className="away">

              {
                this.state.awayTeam && Object.values(this.state.awayTeam).map((team, index) => {
                  return (
                    <div className="lineup_row keeper" key={index} style={{ width: team.length === 4 ? '90%' : '70%' }}>
                      {
                        Object.values(team).map((player, index) => {

                          return (
                            player.positionRow === 0 ?
                              <div className="box2" key={index}>
                                <div className="pen2">
                                  <div className="player">
                                    {
                                      player.timeSubbedOff &&
                                      <span className="arrow-badge" style={{ marginLeft: '30%', marginTop: '-30%' }}>
                                        <small className="timeSubbedOff">{player.timeSubbedOff}</small><br />
                                        <span className="arrow">
                                          <span className="">&#11013;</span>
                                        </span>
                                      </span>
                                    }
                                    <div className="info"  >

                                      <img src={player.imageUrl} alt="" width="50px" onError={(e) => (e.currentTarget.src = fallBackPlayer)} style={{ marginLeft: '30%', marginTop: '-30%' }} />
                                      <p style={{ marginLeft: '20%' }}> {player?.shirt} {player?.name?.firstName} {player?.name?.lastName}</p>
                                    </div>
                                    <span className="rating badge rounded-pill" style={{ backgroundColor: player.rating?.num >= 7.0 ? 'red' : '#202020', color: player.rating?.num >= 7.0 ? 'white' : '#fad230', top: '-80%' }} >{player.rating?.num}</span>
                                  </div>
                                </div>
                              </div>
                              :

                              <div className="player" key={index}>
                                {
                                  player.timeSubbedOff &&
                                  <span className="arrow-badge">
                                    <small className="timeSubbedOff">{player.timeSubbedOff}</small><br />
                                    <span className="arrow">
                                      <span className="">&#11013;</span>
                                    </span>
                                  </span>
                                }
                                <div className="info">
                                  <img src={player.imageUrl} alt="" onError={(e) => (e.currentTarget.src = fallBackPlayer)} />
                                  <p> {player?.shirt} {player?.name?.firstName} {player?.name?.lastName}</p>
                                </div>
                                <span className="rating badge" style={{ backgroundColor: player.rating?.num >= 7.0 ? 'red' : '#202020', color: player.rating?.num >= 7.0 ? 'white' : '#fad230' }}>{player.rating?.num}</span>
                              </div>
                          )
                        }
                        )
                      }
                    </div>
                  )
                })
              }

            </div>

          </div>

          {
            this.state.awayTeam &&
            <div className="teamInfo">
              <img src={`https://images.fotmob.com/image_resources/logo/teamlogo/${this.state.awayTeamLineup?.teamId}.png`} alt="" width="40px" onError={(e) => (e.currentTarget.src = fallBackTeam)} style={{ marginRight: '24px' }} />
              <span className="rating badge"
                style={{
                  backgroundColor: this.state.lineups?.teamRatings?.away?.num > this.state.lineups?.teamRatings?.home?.num ? 'red' : '#202020', marginLeft: '10px',
                  color: this.state.lineups?.teamRatings?.away?.num > this.state.lineups?.teamRatings?.home?.num ? 'white' : '#fad230'
                }}
              >
                {this.state.lineups?.teamRatings?.away?.num && this.state.lineups?.teamRatings?.away?.num.toFixed(1)}
              </span>
                <span className="teamName">{this.state.awayTeamLineup?.teamName}</span>
              <span>{this.state.awayTeamLineup?.lineup}</span>
            </div>
          }
        </div>

        <br />
        <Bench lineups={this.state.lineups} />
        <br />

      </div>
    )
  }
}

export default withRouter(Lineup)