import React, { Component } from 'react'
import SiteConfigsCreateController from './SiteConfigsCreateController';
import { Switch, Input, Button } from '../../../components/ImportsFile'
const styles = {
  textSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingRight: 2,
    color: "#FFFFFFA0",
    fontSize: 12,
    fontWeight: "bold",
    zIndex: 0,
  }
}
class SiteConfigsCreate extends SiteConfigsCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Site Config' : 'Add Site Config'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <form autoComplete="off" style={{ width: '65%', margin: 'auto' }}>
              {
                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
              }
              <div >
                <Input type="number" name="registration_reward" placeholder="Enter Registration Reward..." value={this.state.config.registration_reward} onChange={this.RegistrationReward} required />

                <select name="active" className="select" value={this.state.config.active} onChange={this.processActive} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>


                {
                  this.props.checked &&
                  <>
                    <Input type="date" name="start_date" placeholder="Enter Start Date..." value={this.state.config.start_date} onChange={this.StartDate} />

                    <Input type="date" name="end_date" placeholder="Enter Start Date..." value={this.state.config.end_date} onChange={this.EndDate} />

                    <Input type="number" name="duration_reward" placeholder="Enter Additinal Reward..." value={this.state.config.duration_reward} onChange={this.DurationReward} />
                  </>
                }
                <div style={{ paddingTop: '22px', display: 'flex', alignItem: 'center' }}>

                  <Switch onChange={this.props.handleSwitch} checked={this.props.checked}
                    offColor="#ff0000"
                    onColor="#02ba02"
                    offHandleColor="#eaeaea"
                    onHandleColor="#eaeaea"
                    checkedIcon={
                      <div style={styles.textSwitch}> </div>
                    } uncheckedIcon={
                      <div style={styles.textSwitch}> </div>
                    }
                  />
                  <span style={{ color: "#FAD230", fontWeight: "bold", paddingLeft: '10px' }}>
                    {
                      this.props.checked ? "Duration" : ""
                    }
                  </span>
                </div>


                <br />
                <br />
              </div>
              <Button type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.config)} />
              <br />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SiteConfigsCreate
