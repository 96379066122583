import React from 'react'
import './NotificationElement.jsx'
import { NotificationListController } from './NotificationListController'
import TransactionAction from './../../redux/action/transaction_action';
import { MainContainer, Header, Body, Card } from './NotificationElement'

import { Switch, connect, Pagination, moment, Operator, In, Out } from '../../components/ImportsFile'
class NotificationList extends NotificationListController {

    render() {


        return (
            <MainContainer className="notifications">
                <Header>
                    <h4>Notifications</h4>
                    <div className="switch">
                        <span style={{ color: '#fad230' }}> User</span>
                        <Switch onChange={this.handleSwitch} className="switch-toggle" checked={this.state.checked}
                            offColor="#202020"
                            onColor="#fad230"
                            offHandleColor="#fad230"
                            onHandleColor="#202020"
                            checkedIcon={
                                <div style={{ display: 'none' }}> Operator </div>
                            } uncheckedIcon={
                                <div style={{ display: 'none' }}>User </div>
                            }
                        />
                        <span style={{ color: '#202020' }}> Operator</span>
                    </div>
                    {/**
                    <form className="search_noti" >
                    <input type="text" placeholder="Search.." name="search" />
                    <button type="submit"><i className="fa fa-search"></i></button>
                    </form>
                */}
                </Header>
                <Body>

                    <Card>
                        {
                            this.props.transactions.length > 0 ?
                                this.props.transactions.map((transaction, index) => {
                                    return (

                                        <div key={index} className="noti-list">
                                            {
                                                this.state.checked ?


                                                    <div className="noti-item">
                                                        <div className="profile">
                                                            <img src={Operator} alt="operator" className="operatorImg" />
                                                        </div>
                                                        <div className="transaction">
                                                            <span className="operator">
                                                                {transaction.createdBy?.full_name} &nbsp;
                                                            </span>
                                                            made Transaction

                                                            <span className={transaction.amount_in !== 0 ? "in" : "out"}>
                                                                &nbsp;
                                                                {
                                                                    transaction.amount_in !== 0 ? "IN" : "OUT"
                                                                }
                                                            </span>

                                                            <p>
                                                                <small className="date_time">{moment(transaction.created_at).format(" kk:mm:ss ddd, D MMM , YYYY")}</small>
                                                            </p>
                                                        </div>

                                                        <div className="center">
                                                            {
                                                                transaction.amount_in !== 0 ? <img src={In} alt="img" /> : <img src={Out} alt="img" />
                                                            }

                                                        </div>
                                                        <div className="amount">
                                                            {
                                                                transaction.amount_in !== 0 ? transaction.amount_in.toLocaleString() :
                                                                    transaction.amount_out.toLocaleString()
                                                            }
                                                            <small style={{ color: '#808080' }}> MMK </small>
                                                            for
                                                            <span className="user">
                                                                &nbsp; {transaction.User?.user_name}
                                                            </span>
                                                        </div>
                                                        <div className="pay_type">
                                                            <span className="pay-type">
                                                                &nbsp; {transaction.PaymentType?.name}  &nbsp;
                                                            </span>
                                                            <img src={transaction.PaymentType?.logo_url} alt="logo" className="payment_type_logo" />
                                                        </div>
                                                    </div>

                                                    :

                                                    <div className="noti-item">
                                                        <div className="profile">
                                                            <img src={transaction?.User?.image_url || Operator} alt="operator" className="operatorImg" />
                                                        </div>
                                                        <div className="transaction">
                                                            <span className="user">
                                                                {transaction?.User?.user_name}  &nbsp;
                                                            </span>
                                                            made Transaction
                                                            <span className={transaction.amount_in !== 0 ? "in" : "out"}>
                                                                &nbsp; {
                                                                    transaction.amount_in !== 0 ? "IN" : "OUT"
                                                                }
                                                            </span>
                                                            <p>
                                                                <small className="date_time">{moment(transaction.created_at).format(" kk:mm:ss ddd, D MMM , YYYY")}</small>
                                                            </p>
                                                        </div>
                                                        <div className="center">
                                                            {
                                                                transaction.amount_in !== 0 ? <img src={In} alt="in" /> : <img src={Out} alt="out" />
                                                            }
                                                        </div>
                                                        <div className="amount">
                                                            {
                                                                transaction.amount_in !== 0 ? transaction.amount_in.toLocaleString()
                                                                    + " MMK " : transaction.amount_out.toLocaleString() + " MMK "
                                                            }
                                                            via
                                                        </div>
                                                        <div className="pay_type">
                                                            <span className="pay-type">
                                                                &nbsp; {transaction.PaymentType?.name}  &nbsp;
                                                            </span>
                                                            <img src={transaction.PaymentType?.logo_url} alt="logo" className="payment_type_logo" />
                                                        </div>
                                                    </div>
                                            }
                                            <hr />
                                        </div>
                                    )
                                })

                                :
                                <div className="no-noti">
                                    <p>No Notifications!</p>
                                </div>
                        }

                        {
                            this.props.transactions.length > 0 &&
                            <div style={{ padding: "5px 20px 0px 50px" }}>
                                <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                                    this.props.getTransactions(pageNumber, this.props.row_count)
                                }} />
                            </div>
                        }

                    </Card>

                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = state => {
    return {
        row_count: state.transaction.row_count,
        totalCount: state.transaction.totalCount,
        totalCountPerPage: state.transaction.totalCountPerPage,
        currentPage: state.transaction.currentPage,
        transactions: state.transaction.transactions,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTransactions: (pageNumber, row_count) => dispatch(TransactionAction.getTransactions(pageNumber, row_count))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)