import PaymentTypeType from './../type/payment_type_type';
const initialState = {
    payment_types: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}

const paymentTypeReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case PaymentTypeType.GET_PAYMENT_TYPES:
            return {
                ...state,
                loading:false,
                payment_types: actions.payload
            }
        case PaymentTypeType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case PaymentTypeType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case PaymentTypeType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case PaymentTypeType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case PaymentTypeType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case PaymentTypeType.ADD_PAYMENT_TYPE:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // payment_types: state.payment_types.concat(actions.payload)
                payment_types: [
                    actions.payload,
                    ...state.payment_types,
                ]
            }
        case PaymentTypeType.UPDATE_PAYMENT_TYPE:
            const updatePaymentTypes = state.payment_types.filter(payment_type => payment_type.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                payment_types: [
                    actions.payload,
                    ...updatePaymentTypes,
                ]
            }
        case PaymentTypeType.DELETE_PAYMENT_TYPE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                payment_types: state.payment_types.filter(payment_type => payment_type.id !== actions.payload)
            }
        case PaymentTypeType.LOADING: {
            return {
                ...state,
                loading: actions.payload
            }
        }
        case PaymentTypeType.PAYMENT_TYPE_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case PaymentTypeType.PAYMENT_TYPE_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}

export default paymentTypeReducer;