import React from 'react'
import { LiveStreamingListController } from './LiveStreamingListController'
import { LiveStreamingCreate } from '../create/LiveStreamingCreate'
import LiveStreamingAction from './../../../redux/action/live_streaming_action';
import MatchAction from './../../../redux/action/match_action';
import { Modal, Alert, PageLoader, Search, Pagination, connect, Button, MainContainer, Header, Body } from '../../../components/ImportsFile'
class LiveStreamingList extends LiveStreamingListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Live Streaming' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Live Streaming' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Live Streaming' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        const IDs = []
        const MatchIDs = [];
        this.props.livestreams.map((data) => {
            if (!MatchIDs.includes(data.match_id)) {
                MatchIDs.push(data.match_id)
                IDs.push(data.id)
            }
        })
        return (
            <MainContainer >
                <Header>
                    <h4>Live Streaming</h4>
                    <form className="search search-livestream" autoComplete="off">
                        <div className="input-group">
                            <Search
                                items={this.props.matches}
                                searchItem={(matchId) => this.handleSelectMatch(matchId)}
                                text={'Search with Match'}
                                search={this.state.matchId ? true : false}
                                LoadMore={this.loadMore}
                                getItems={this.props.getAllMatches}
                                rowCount={this.state.matchRowCount}
                                stopSearching={() => this.props.getLiveStreams(this.state.initialPage, this.state.rowCount)}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>

                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Live Streaming" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    {
                        !this.state.isEdit &&
                        <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    }
                    {
                        (!this.state.isEdit && this.props.loading) && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Match</th>
                                <th>LiveStreaming URL</th>
                                {/**
                                <th>Resolution</th>
                            */}
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                this.props.livestreams.map((livestream, index) => {


                                    return (
                                        IDs.includes(livestream.id) &&
                                        <tr key={index}>
                                            <td>{livestream.Match?.HomeTeam?.name} Vs {livestream.Match?.AwayTeam?.name}</td>
                                            <td>

                                                {
                                                    (livestream.live_stream_url && livestream.live_stream_url.length > 30) ? livestream.live_stream_url.substring(0, 30) + '...' : livestream.live_stream_url
                                                }
                                            </td>
                                            <td>{livestream.active === 1 ? "Active" : "Inactive"}</td>
                                            {/**
                                            <td>{livestream.resolution}</td>
                                        */}
                                            <td>
                                                <Modal deleteItem={() => this.deleteLiveStream(livestream)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(livestream)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination
                        activePage={this.props.currentPage}
                        totalItemsCount={this.props.matchId ? IDs.length : this.props.totalCount}
                        totalCountPerPage={this.props.matchId ? IDs.length : this.props.totalCountPerPage}
                        paginate={(pageNumber) => {
                            (!this.props.text && !this.props.matchId) && this.props.getLiveStreams(pageNumber, this.props.row_count);
                            (this.props.text || this.props.matchId) && this.props.searchItem(this.state.text, this.state.matchId, pageNumber);
                        }} />
                    {
                        this.state.show &&
                        <LiveStreamingCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            createOrUpdate={this.createOrUpdate}
                            createLiveStream={this.props.createLiveStream}
                            livestream={this.state.livestream}
                            matches={this.props.matches}
                            getAllMatches={this.props.getAllMatches}
                            matchRowCount={this.props.matchRowCount}
                            matchTime={this.props.matchTime}
                            loadMore={this.loadMore}
                            requiredError={this.state.requiredError}
                            updateLiveStream={this.props.updateLiveStream}
                            deleteLiveStream={this.props.deleteLiveStream}
                            success={this.props.success}
                            getLiveStreams={this.props.getLiveStreams}
                            error={this.props.error}
                            loading={this.props.loading}
                            totalCount={this.props.totalCount}
                        />
                    }
                </Body>
            </MainContainer >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        totalCount: state.livestream.totalCount,
        totalCountPerPage: state.livestream.totalCountPerPage,
        currentPage: state.livestream.currentPage,
        livestreams: state.livestream.livestreams,
        matches: state.match.matches,
        error: state.livestream.error,
        success: state.livestream.success,
        text: state.livestream.text,
        matchId: state.livestream.matchId,
        row_count: state.livestream.row_count,
        loading: state.livestream.loading,
        matchRowCount: state.match.row_count,
        matchTime: state.match.time
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getLiveStreams: (pageNumber, row_count) => dispatch(LiveStreamingAction.getLiveStreams(pageNumber, row_count)),
        createLiveStream: (livestream) => dispatch(LiveStreamingAction.createLiveStream(livestream)),
        updateLiveStream: (livestream) => dispatch(LiveStreamingAction.updateLiveStream(livestream)),
        deleteLiveStream: (id) => dispatch(LiveStreamingAction.deleteLiveStream(id)),
        getAllMatches: (pageNumber, row_count) => dispatch(MatchAction.getAllMatches(pageNumber, row_count)),
        searchItem: (text, matchId, pageNumber) => dispatch(LiveStreamingAction.searchItem(text, matchId, pageNumber)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamingList)