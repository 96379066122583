import CONSTANTS from './../constants';
import axios from 'axios'
import MatchType from './../type/match_type';

export const Error = (data) => {
    return {
        type: MatchType.MATCH_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: MatchType.MATCH_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: MatchType.LOADING, payload: status })
    }
}
const getMatches = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}matches/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.GET_MATCHES,
                    payload: response.data.data
                })
                dispatch({
                    type: MatchType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: MatchType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: MatchType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: MatchType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Fail"
                dispatch({
                    type: MatchType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: MatchType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};

            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;

            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllMatches = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}matches/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.GET_MATCHES,
                    payload: response.data.data
                })
                dispatch({
                    type: MatchType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: MatchType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Fail"
                dispatch({
                    type: MatchType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: MatchType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};

            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createMatch = (match) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}matches/create`, match).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.ADD_MATCH,
                    payload: response.data.data
                    // payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};

            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateMatch = (match) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}matches/update`, match).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.UPDATE_MATCH,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};

            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;

            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteMatch = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}matches/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.DELETE_MATCH,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail"
                dispatch({
                    type: MatchType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: MatchType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};

            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;

            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchItem = (time, leagueId, teamId, startDate, endDate, pageNumber) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}matches/list?time=${time}&league_id=${leagueId}&team_id=${teamId}&startDate=${startDate}&endDate=${endDate}&date=${startDate || endDate}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: MatchType.GET_MATCHES, payload: response.data.data
                })
                dispatch({
                    type: MatchType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: MatchType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: MatchType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: MatchType.SEARCH_WITH_LEAGUE,
                    payload: leagueId
                })
                dispatch({
                    type: MatchType.SEARCH_DATA,
                    payload: teamId
                })
                dispatch({
                    type: MatchType.SEARCH_ITEM_S,
                    payload: startDate,
                })
                dispatch({
                    type: MatchType.SEARCH_ITEM_E,
                    payload: endDate,
                })
                dispatch({
                    type: MatchType.SEARCH_ITEM,
                    payload: time
                })

            }

        })
    }
}

const MatchAction = {
    getMatches,
    getAllMatches,
    createMatch,
    updateMatch,
    deleteMatch,
    searchItem,
}
export default MatchAction