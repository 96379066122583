
import LeaderboardType from './../type/leaderboard_type';

const initialState = {
    top_fans: [],
    fav_team_users: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    text: '',
    teamId: '',
    loading: false,
}
const leaderboardReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case LeaderboardType.GET_TOP_FANS:
            return {
                ...state,
                loading: false,
                top_fans: actions.payload
            }
        case LeaderboardType.GET_FAV_TEAM_USERS:
            return {
                ...state,
                loading: false,
                fav_team_users: actions.payload
            }
        case LeaderboardType.SEARCH_WITH_TEAM:
            return {
                ...state,
                teamId: actions.payload
            }
        case LeaderboardType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }

        case LeaderboardType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case LeaderboardType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case LeaderboardType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case LeaderboardType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case LeaderboardType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case LeaderboardType.LEADERBOARD_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case LeaderboardType.LEADERBOARD_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default leaderboardReducer