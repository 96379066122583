
import UserTeamActivityType from './../type/user_team_activity_type';
const initialState = {
    user_team_activities: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    text: '',
    teamId: '',
    loading: false,
}
const userTeamActivityReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case UserTeamActivityType.GET_USER_TEAM_ACTIVITIES:
            return {
                ...state,
                loading: false,
                user_team_activities: actions.payload
            }
        case UserTeamActivityType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case UserTeamActivityType.SEARCH_WITH_TEAM:
            return {
                ...state,
                teamId: actions.payload
            }
        case UserTeamActivityType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case UserTeamActivityType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case UserTeamActivityType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case UserTeamActivityType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case UserTeamActivityType.ADD_USER_TEAM_ACTIVITY:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                user_team_activities: [
                    actions.payload,
                    ...state.user_team_activities,
                ]

            }
        case UserTeamActivityType.UPDATE_USER_TEAM_ACTIVITY:
            const updateActivity = state.user_team_activities.filter(activity => activity.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                user_team_activities: [
                    actions.payload,
                    ...updateActivity,
                ]
            }
        case UserTeamActivityType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case UserTeamActivityType.DELETE_USER_TEAM_ACTIVITY:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                user_team_activities: state.user_team_activities.filter(activity => activity.id !== actions.payload)
            }
        case UserTeamActivityType.USER_TEAM_ACTIVITY_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case UserTeamActivityType.USER_TEAM_ACTIVITY_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default userTeamActivityReducer