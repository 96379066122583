import styled from "styled-components";
import './MainLayoutCss.css';

export const shadowbox = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 16.00,

  elevation: 25,
  // backgroundColor:"red"

}
export const MainContainer = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  min-height:100%;
`;
export const MainHeader = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:60px;
    position:fixed;
    top:0;
    z-index:99;
  `;
export const MainHeaderLeft = styled.div`
      display:flex;
      flex-direction:row;
      width:${props => props.isOpenNavBar ? "24%" : "6%"};
      transition: width 1s;
      height:100%;
      background-color:#202020;
      justify-content:space-between;
      align-items:center;
      color:#FAD230;
      border-right: 0.2px solid #BBB9CA;
      & .admin_dashboard{
        padding-left:20px;
        h5{
          margin-bottom:0px!important;
        }
      }
      

      
    `;
export const MainHeaderRight = styled.div`
      display:flex;
      flex-direction:column;
      width:${props => props.isOpenNavBar ? "80%" : "95%"};
      transition: width 1s;
      height:100%;
      background-color:#202020;
      justify-content:center;

    `;
export const MainHeaderEnd = styled.div`
      display:flex;
      flex-direction:row;
      align-items:center;
      width:15%;
      transition: width 1s;
      height:100%;
      padding: 0 20px 0 20px;
      cursor:pointer;
      background-color:#202020;
      justify-content:space-around;
      .notification{
        position:relative;
      }
      .notification .counter{
        color:white;
        position:absolute;
        top:4px;
        right:-2px;
        width:6px;
        height:6px;
        border-radius:50%;
        margin-bottom:0px;
        background:red;
      }
      

    `;
export const MainBody = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    min-height:85%;
    // height:100%;
    // padding-top:4%;
    justify-content:space-between;
  `;
export const MainBodyLeft = styled.div`
      display:flex;
      flex-direction:column;
      width:${props => props.isOpenNavBar ? "20%" : "5%"};
      // height:100%;
      background-color:#3A3365;
      background-color:#ffffff;
      // background-color:#202020;
      box-shadow: -5px 0px 20px #3A3365;
      box-shadow: 2px 0px 9px -1px #3A3365;
      -webkit-box-shadow: 2px 0px 9px -1px #3A3365;
      -moz-box-shadow: 2px 0px 9px -1px #3A3365;
      padding-top:4%;
      padding-bottom:4%;

      transition: width 1s;
      justify-content:flex-start;
      font-size:14px;
      margin-bottom: 0px;
      height: 100vh;
      // height:100%;
      overflow-y: scroll;
      
      overflow-y: scroll;
      ::-webkit-scrollbar{
        width:3px;
      }
      
      // ::-webkit-scrollbar-track {
      //   background: #202020;
      // }
      :hover::-webkit-scrollbar-thumb{
        background-image: linear-gradient(#202020, #fad230);
        border-radius: 3px;
      }

      
      
    `;
export const Nav = styled.div`
      display:flex;
      flex-direction:column;
      font-family: 'Roboto', sans-serif;
      min-height:100vh;
      // height:100vh;
      // width:90%;
      // background-color:red;
      // min-height:20px;
      // padding-left:30px;
      // padding-top:20px;
      // justify-content:center;
      // align-items:center;
      & .nav-item{
        padding:10px;
        padding-left:20px;
        cursor:pointer;
        color: #202020;
        font-weight: 500;
        &.sideBarNotification{
        position:relative;
      }
      &.sideBarNotification .sideBarCounter{
        color:white;
        position:absolute;
        top:10px;
        left:5%;
        // left:38%;
        // left:30px;
        width:6px;
        height:6px;
        border-radius:50%;
        margin-bottom:0px;
        background:red;
      }
        
      }
      .stadiumIcon{
        color:#202020;
        fill:#202020;
      }
      & .nav-list .nav-item{
        color: #202020;
      }

      & a{
        text-decoration:none;
        color: #202020;
      }
      
      & .nav-item:hover{
        // background-color:#3A3365;
        // color:white;
        background-color:#202020;
        color:#FAD230;
      }

      & .nav-item:hover a{
        color:#FAD230;
      }
      & .nav-item:hover .stadiumIcon{
        color:#FAD230;
        fill:#FAD230;
      }
      & .nav-item:hover .leaderboardIcon{
        color:#FAD230;
        fill:#FAD230;
      }
      & .nav-item-child{
        padding-left:20px;
        // background-color:#202020;
      }
      
      

    `;
// export const NavBody = styled.div`
//   display:flex;
//   width:100%;
//   // min-height:100vh;
//   display:flex;
//   flex-direction:row;
//   align-items:center;
//   padding-top:20px;

// `;
export const NavLeft = styled.div`
        display:flex;
        width:10%;
      `;
export const NavMid = styled.div`
        display:flex;
        width:80%;
      `;
export const NavRight = styled.div`
        display:flex;
        width:10%;
      `;
// export const NavChild = styled.div`
//   display:flex;
//   width:100%;
//   flex-direction:column; 
// `;

export const MainBodyRight = styled.div`
      display:flex;
      flex-direction:column;
      width:${props => props.isOpenNavBar ? "79%" : "94%"};
      height:100%;
      transition: width 1s;
      background-color:#ffffff;
      // background-color:#202020;
      padding-bottom:9%;
      padding-top:6%;
      // margin-bottom:100px; 

      height: 100vh;
      overflow-y: scroll;

      ::-webkit-scrollbar{
        width:3px;
      }
      ::-webkit-scrollbar-thumb{
        background-image: linear-gradient(#202020, #fad230);
        border-radius: 3px;
      }
     
    `;
export const MainFooter = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:5%;
    position:fixed;
    bottom: 0;
    // background-color:#3A3365;
      background-color:#202020;

  `;
export const MainFooterLeft = styled.div`
      display:flex;
      flex-direction:column;
      width:${props => props.isOpenNavBar ? "20%" : "5%"};
      transition: width 1s;
      height:100%;
      // background-color:#3A3365;
      background-color:#202020;
      border-right: 1px solid #BBB9CA;
    `;
export const MainFooterRight = styled.div`
    display:flex;
    flex-direction:column;
    width:${props => props.isOpenNavBar ? "80%" : "95%"};
    transition: width 1s;
    height:100%;
    background-color:#202020;
    justify-content:center;
    align-items:center;
    color:#FAD230;
    // color:#ffffff;
    font-size:12px;
  `;