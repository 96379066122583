
import OperatorType from './../type/operator_type';
const initialState = {
    operators: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    loading:false,
}
const operatorReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case OperatorType.GET_OPERATORS:
            return {
                ...state,
                loading:false,
                operators: actions.payload
            }
        case OperatorType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case OperatorType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case OperatorType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case OperatorType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case OperatorType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case OperatorType.ADD_OPERATOR:
            return {
                ...state,
                loading:false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // operators: state.operators.concat(actions.payload)
                operators: [
                    actions.payload,
                    ...state.operators,
                ]
            }
        case OperatorType.UPDATE_OPERATOR:
            const updateOperators = state.operators.filter(operator => operator.id !== actions.payload.id)
            return {
                ...state,
                loading:false,
                operators: [
                    actions.payload,
                    ...updateOperators,
                ]
            }
        case OperatorType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case OperatorType.DELETE_OPERATOR:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                operators: state.operators.filter(operator => operator.id !== actions.payload)
            }
        case OperatorType.OPERATOR_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case OperatorType.OPERATOR_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default operatorReducer