import React from 'react'
import { PermissionCreate } from '../create/PermissionCreate'
import { PermissionListController } from './PermissionListController';
import PermissionAction from './../../../redux/action/permission_action';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, connect } from '../../../components/ImportsFile'
class PermissionList extends PermissionListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Permission' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Permission' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Permission' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Permissions</h4>
                    <form className="search" onSubmit={this.handleSearch} autoComplete="off">
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Permission" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.permissions.map((permission, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{permission.name}</td>
                                            <td>{permission.description}</td>
                                            <td>{permission.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deletePermission(permission.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(permission)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getPermissions(pageNumber, this.props.row_count) : this.props.searchItem(this.props.text, pageNumber) }} />
                    {
                        this.state.show && <PermissionCreate isEdit={this.state.isEdit} hideModal={this.hideModal} createOrUpdate={this.createOrUpdate} permission={this.state.permission} requiredError={this.state.requiredError} />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        permissions: state.permission.permissions,
        error: state.permission.error,
        success: state.permission.success,
        currentPage: state.permission.currentPage,
        totalCount: state.permission.totalCount,
        totalCountPerPage: state.permission.totalCountPerPage,
        text: state.permission.text,
        row_count: state.permission.row_count,
        loading: state.permission.loading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPermissions: (pageNumber, row_count) => dispatch(PermissionAction.getPermissions(pageNumber, row_count)),
        createPermission: (permission) => dispatch(PermissionAction.createPermission(permission)),
        updatePermission: (permission) => dispatch(PermissionAction.updatePermission(permission)),
        deletePermission: (id) => dispatch(PermissionAction.deletePermission(id)),
        searchItem: (text, pageNumber) => dispatch(PermissionAction.searchItem(text, pageNumber))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionList)