import React from 'react'
import { MatchContainer, ScoreSubNav, ScoreBody } from '../ScoreDetailElement'
import { withRouter } from '../../../../components/routers/withRouter'
import ScoreStatsController from './ScoreStatsController';
import SubStats from './SubStats';
import TopPlayer from './TopPlayer';
import H2H from './H2H';
import MainStats from './MainStats';
import { PageLoader } from './../../../../components/pageLoader/PageLoader';
import MatchFacts from './MatchFacts';
class ScoreStats extends ScoreStatsController {
    render(props) {
        return (
            <div className="scoreStats">

                {
                    this.state.loading ?
                        <>
                            <br />
                            <PageLoader />
                        </>
                        :
                        <>
                            <MatchContainer >
                                <MainStats teams={this.state.teams} status={this.state.status} events={this.state.events} />
                            </MatchContainer>

                            <ScoreSubNav>
                                <ul>

                                    <li className={this.state.showSubStats ? 'active' : ''} onClick={this.showSubStats}><p>Stats</p></li>
                                    <li className={this.state.showMatchFact ? 'active' : ""} onClick={this.showMatchFact}><p>Match Facts</p></li>
                                    <li className={this.state.showH2H ? 'active' : ""} onClick={this.showH2H}><p>H2H</p></li>
                                </ul>
                            </ScoreSubNav>

                            <ScoreBody>
                                {
                                    this.state.showSubStats && <SubStats showTeamData={this.state.showTeamData} getTeamData={this.getTeamData} topStats={this.state.topStats} getPlayerData={this.getPlayerData} showPlayerData={this.state.showPlayerData} />
                                }
                                {
                                    this.state.showPlayerData &&
                                    <TopPlayer
                                        topPlayers={this.state.topPlayers}
                                        teams={this.state.teams}
                                        events={this.state.events}
                                        lineups={this.state.lineups}
                                    />
                                }
                                {
                                    this.state.showMatchFact && <MatchFacts matchFacts={this.state.matchFacts} />

                                }
                                {
                                    this.state.showH2H && <H2H h2h={this.state.h2h} teams={this.state.teams} />

                                }
                            </ScoreBody>
                        </>
                }


            </div>
        )
    }
}

export default withRouter(ScoreStats)