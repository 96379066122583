import React from 'react';
import PieChart from './PieChart';
import styled from "styled-components";
// import './HomePage.css'
import LineChart from './LineChart';
import BarChart from './BarChart';
import HomePageController from './HomePageController';
import fallBackUser from '../../images/Black_Goat.png'
import { ReactComponent as PlayerIcon } from '../../images/player.svg'
import { ReactComponent as ShieldIcon } from '../../images/shield.svg'

import { Button } from './../../components/buttons/Button';
import { Link } from 'react-router-dom';
import { PageLoader } from './../../components/pageLoader/PageLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import ReactLoading from 'react-loading';
class HomePage extends HomePageController {


    render() {
        let date = new Date();
        let prev = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        return (
            <MainContainer>
                {
                    (!this.state.watchCountForCurrentMonth || !this.state.watchCountForPrevMonth || !this.state.quizCount || !this.state.BarLabels || !this.state.userCount || !this.state.topFanUsers) ?
                        <div className="full_page_loader">
                            <ReactLoading type={'bubbles'} color={"#fad230"} height={'20%'} width={'20%'} />
                        </div>
                        :
                        <Body>
                            <CountsContainer>
                                <div className="line-chart">
                                    <h4>Watching Counts</h4>
                                    <LineChart
                                        previousMonth={moment(prev).format("MMMM")}
                                        currentMonth={moment(date).format("MMMM")}
                                        watchCountForCurrentMonth={this.state.watchCountForCurrentMonth}
                                        watchCountForPrevMonth={this.state.watchCountForPrevMonth}
                                    />
                                </div>
                                <div className="bar-chart">
                                    <h4>Quiz Counts for {moment(date).format("MMMM")} </h4>
                                    <BarChart
                                        quizCount={this.state.quizCount}
                                        BarLabels={this.state.BarLabels}
                                    />
                                </div>
                            </CountsContainer>

                            <CountDataContainer>
                                <div className="no-user">
                                    <Link to="/user">
                                        <FontAwesomeIcon icon={faUsers} style={{ fontSize: '30px' }} />
                                        <span> {this.state.userCount || 0}</span>
                                        <br /><br />
                                        <small>Number of Users</small>
                                    </Link>
                                </div>
                                <div className="no-player">
                                    <Link to="/player">
                                        <PlayerIcon width="40px" height="40px" fill="#202020" />
                                        <span style={{ fontSize: this.state.playerCount > 9999 && '14px' }}>{this.state.playerCount.toLocaleString() || 0}</span>
                                        <br /><br />
                                        <small>Number of Players</small>
                                    </Link>
                                </div>
                                <div className="no-team">
                                    <Link to="/team">
                                        <ShieldIcon width="40px" height="40px" fill="#fad230" />

                                        <span>{this.state.teamCount || 0}</span>
                                        <br /><br />
                                        <small>Number of Teams</small>
                                    </Link>
                                </div>
                            </CountDataContainer>

                            <TopFanContainer>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="toFansTitle"> Top Fans </h4>
                                        <Link to="/leaderboard">
                                            <Button caption="View All" className="btn-primary" style={{ color: "#fad230" }} />
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.loading ? <PageLoader /> :
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Top Fan Level</th>
                                                            <th>Watch Count</th>
                                                            <th>Username</th>
                                                            <th>Phone</th>
                                                            <th>Image</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.topFanUsers.slice(0, 10).map((user, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>Level {user.global_level_id}</td>
                                                                    <td>{user.global_watch_count}</td>
                                                                    <td>{user.user_name}</td>
                                                                    <td>{user.phone_no}</td>
                                                                    <td>
                                                                        <img src={user.image_url} alt="" onError={(e) => (e.currentTarget.src = fallBackUser)} />
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>

                                                </table>
                                        }
                                    </div>
                                </div>
                            </TopFanContainer>

                            <PieChartContainer>
                                <h4>Comparison of Favorite Team Count</h4>
                                <PieChart
                                    teamName={this.state.teamName}
                                    favCount={this.state.favCount}
                                />
                            </PieChartContainer>

                        </Body>
                }
            </MainContainer>
        );
    }
}



export default HomePage
export const MainContainer = styled.div`
    width:90%;
    height:100%;
    // background-color:red;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
export const Body = styled.div`
    width:100%;
    height:80%;
    
`

export const CountsContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    div{
        width:50%;
        h4{
            padding-bottom: 10px;
            background: -webkit-linear-gradient(#202020, #fad230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }   
`
export const CountDataContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:30px;   
    margin-bottom:30px;   
    .no-team,.no-user{
        display:flex;
        align-item:center;
        justify-content:space-between;
        align-items:center;
        width:30%;
        min-height:100px;
        background:#202020;
        margin: 10px;
        border: 1px solid #fad230;
        background-color: #202020;
        color: #fad230;
        padding: 20px;
        cursor: pointer;
        span {
            display: flex;
            float: right;
            min-width: 50px;
            min-height: 50px;
            max-width: 100px;
            max-height: 100px;
            background: #ffffff;
            align-items: center;
            text-align: right;
            justify-content: center;
            border-radius: 50%;
            font-weight: bold;
            font-size: 18px;
        }
        a{
            width:100%;
            color:#fad230;
            text-decoration:none;
        }
        i{
            color:#fad230;
        }
    }
    .no-player{
        display:flex;
        align-item:center;
        justify-content:space-between;
        align-items:center;
        width:30%;
        min-height:100px;
        background:#fad230;
        margin: 10px;
        border: 1px solid #202020;
        color: #202020;
        padding: 20px;
        cursor: pointer;
        span {
            display: flex;
            float: right;
            min-width: 50px;
            min-height: 50px;
            max-width: 100px;
            max-height: 100px;
            background: #ffffff;
            align-items: center;
            text-align: right;
            justify-content: center;
            border-radius: 50%;
            font-weight: bold;
            font-size: 18px;
        }
        a{
            width:100%;
            color:#202020;
            text-decoration:none;
        }
        i{
            color:#202020;
        }
    }
    
`

export const TopFanContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    .card{
        width:100%;
        .card-header{
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:center;
            a{
                text-decoration:none;
            }
            h4{
                align-items:center;
                padding-bottom: 0px;
                margin-bottom:0px;
                background: -webkit-linear-gradient(#202020, #fad230);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }   
`

export const PieChartContainer = styled.div`
    width:40%;
    display:flex;
    flex-direction:column;
    // justify-content:space-between;
    // margin:30px auto;
    margin-top:30px;
    padding-bottom:10%;
    h4{
        text-align:center;
        padding-bottom: 10px;
            background: -webkit-linear-gradient(#202020, #fad230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
    }
`