import { Component } from 'react'

class FutureQuizAnswerCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {

      quiz_answer: this.props.isEdit === false ? {
        special_quiz_detail_id: "",
        answer: "",
        match_id: '',
        win_case: '',
        quiz_type: '',
        active: 1
      } : props.quiz_answer.quiz_answer
    }

  }
  componentDidMount = () => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        special_quiz_detail_id: this.props.quizInfo && this.props.quizInfo?.id,
        quiz_type: this.props.quizInfo && this.props.quizInfo?.quiz_type,
      }
    }))
  }
  handleSelectId = (answer) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        answer: answer
      }
    }))
  }
  handleSelectName = (win_case) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        win_case: win_case
      }
    }))
  }

}

export default FutureQuizAnswerCreateController
