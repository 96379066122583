import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
class LogoutLayout extends Component {
    constructor(props) {
        super(props)
        localStorage.removeItem('operator')
        localStorage.removeItem('permissions')
    }

    render() {
        return (
            <Navigate to="/" />
            // <div className="loginForm">
            //     <Link to="/">Login Again</Link>
            // </div>
        )
    }

}
export {
    LogoutLayout
}