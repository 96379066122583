
import OddsRateType from './../type/odds_reate_type';
const initialState = {
  odds_rates: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  totalPage: 1,
  text: null,
  row_count: 1,
  loading: false,
  specialQuizDetailId: null,
}
const oddsRateReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case OddsRateType.GET_ODDS_RATES:
      return {
        ...state,
        loading: false,
        odds_rates: actions.payload,
      }
    case OddsRateType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case OddsRateType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case OddsRateType.TOTAL_PAGE:
      return {
        ...state,
        totalPage: actions.payload
      }
    case OddsRateType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case OddsRateType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case OddsRateType.ADD_ODDS_RATE:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        // odds_rates: state.odds_rates.push(actions.payload)
        odds_rates: [
          ...state.odds_rates,
          actions.payload,
        ],

      }

    case OddsRateType.TOGGLE_EDIT:
      let updatedArray = state.odds_rates.map(item => {
        if (item.id == actions.payload.id) {
          return {
            ...item,
            isEdit: !item.isEdit
          }
        }
        return item
      });
      return {
        ...state,
        odds_rates: [...updatedArray]
      }
    case OddsRateType.UPDATE_ODDS_RATE:
      const updateOddsRate = state.odds_rates.filter(odds_rate => odds_rate.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        odds_rates: [
          actions.payload,
          ...updateOddsRate,
          // ...state.odds_rates
        ]
      }
    case OddsRateType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case OddsRateType.DELETE_ODDS_RATE:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        odds_rates: state.odds_rates.filter(odds_rate => odds_rate.id !== actions.payload)
      }
    case OddsRateType.SEARCH_WITH_SPECIAL_QUIZ_DETAIL_ID:
      return {
        ...state,
        specialQuizDetailId: actions.payload
      }
    case OddsRateType.ODDS_RATE_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case OddsRateType.ODDS_RATE_SUCCESS:
      return { ...state, success: actions.payload, loading: false }
    default: return state
  }
}
export default oddsRateReducer