
import HighlightType from './../type/highlight_type';
const initialState = {
    highlights: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    leagueId: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const highlightReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case HighlightType.GET_HIGHLIGHT:
            return {
                ...state,
                loading: false,
                highlights: actions.payload
            }
        case HighlightType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case HighlightType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case HighlightType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case HighlightType.SEARCH_DATA:
            return {
                ...state,
                leagueId: actions.payload
            }
        case HighlightType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case HighlightType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case HighlightType.ADD_HIGHLIGHT:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                highlights: [
                    actions.payload,
                    ...state.highlights,
                ]
            }
        case HighlightType.UPDATE_HIGHLIGHT:
            const updateHighlight = state.highlights.filter(highlight => highlight.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                highlights: [
                    actions.payload,
                    ...updateHighlight
                ]
            }
        case HighlightType.DELETE_HIGHLIGHT:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                highlights: state.highlights.filter(highlight => highlight.id !== actions.payload)
            }
        case HighlightType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }

        case HighlightType.HIGHLIGHT_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case HighlightType.HIGHLIGHT_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default highlightReducer