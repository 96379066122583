import React from 'react'
import { UserImg, PageLoader, Pagination, connect, Search } from '../../../components/ImportsFile'
import UserAction from './../../../redux/action/user_action';
import WalletAction from './../../../redux/action/wallet_action.js';
import TheQuizzerListController from './TheQuizzerListController';

class TheQuizzer extends TheQuizzerListController {
  render() {
    return (

      this.props.loading ? <PageLoader /> : <div>
        <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
          <div className="input-group">
            <Search
              items={this.props.users}
              searchItem={(userId) => this.handleSelectUser(userId)}
              searching={this.props.searchUser}
              getItems={this.props.getAllUsers}
              currentPage={this.props.userCurrentPage}
              text={'Search with User'}
              search={this.state.userId ? true : false}
              stopSearching={() => this.stopSearching()}
              LoadMore={this.loadMore}
              rowCount={this.props.userRowCount}
            />
            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
            <button type="submit"><i className="fa fa-search"></i></button>
          </div>
        </form>
        <div className="leaderboardCard">

          <div>
            {
              this.props.wallets && this.props.wallets.map((wallet, index) => {
                return (
                  <div className="topFanLists" key={index}>
                    <div style={{ fontSize: '14px' }}>
                      {((Number(this.props.currentPage) - 1) * Number(this.state.rowCount)) + index + 1 + "."}
                    </div>
                    <div className="profile">
                      <img src={wallet?.User?.image_url} alt="" onError={(e) => (e.currentTarget.src = UserImg)} />
                    </div>
                    <div className="info">
                      <h6>{wallet?.User?.user_name}</h6>
                    </div>
                    <div className="level">
                      <span>
                        {wallet?.quiz_points}
                      </span>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
        {
          !this.props.loading && <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => { !this.props.text ? this.props.getWallets(pageNumber, this.state.rowCount) : this.props.searchItem(this.props.text, pageNumber) }}
          />
        }
      </div>
    )
  }
}
const mapStateTopProp = state => {
  return {
    wallets: state.wallet.wallets,
    users: state.user.users,
    error: state.wallet.error,
    success: state.wallet.success,
    sort_by: state.wallet.sort_by,
    currentPage: state.wallet.currentPage,
    userCurrentPage: state.user.currentPage,
    totalCount: state.wallet.totalCount,
    totalCountPerPage: state.wallet.totalCountPerPage,
    text: state.wallet.text,
    row_count: state.wallet.row_count,
    loading: state.wallet.loading,
    userText: state.user.text,
    userRowCount: state.user.row_count,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getWallets: (sortBy, pageNumber, row_count) => dispatch(WalletAction.getWallets(sortBy, pageNumber, row_count)),
    getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
    searchItem: (text, pageNumber) => dispatch(WalletAction.searchItem(text, pageNumber)),
    searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),

  }
}
export default connect(mapStateTopProp, mapDispatchToProps)(TheQuizzer)
