import React from 'react'
import { UserCreateController } from './UserCreateController'
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
class UserCreate extends UserCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit User' : 'Add User'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" encType="multipart/form-data" >
                            {
                                this.props.requiredError && <p className="requiredError">*{
                                    this.props.requiredError}</p>
                            }
                            <div style={{ columnCount: '2' }}>
                                <Input type="text" placeholder="Enter Fullname" name="full_name" value={this.state.user.full_name} onChange={this.handleInputFullname} required />

                                <Input type="text" placeholder="Enter Username..." name="user_name" value={this.state.user.user_name} onChange={this.handleInputUsername} required />

                                <Input type="text" placeholder="Enter Email..." name="email" value={this.state.user.email} onChange={this.handleInputEmail} required />

                                <Input type="text" placeholder="Enter Phone..." name="phone_no" value={this.state.user.phone_no} onChange={this.handleInputPhone} required />

                                <Input type="text" placeholder="Enter Address..." name="address" value={this.state.user.address} onChange={this.handleInputAddress} required />

                                <Input type="text" placeholder="Enter Password..." name="password" value={this.state.user.password} onChange={this.handleInputPassword} required style={{ marginTop: '3.5px' }} />

                                <select name="active" className="select" value={this.state.user.active} onChange={this.handleActive} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                                <select name="township_id" className="select" value={this.state.user.township_id} onChange={this.handleTownshipChange} required >
                                    <option>*** Select Township ***</option>
                                    {
                                        this.state.townships.map((township, index) => {
                                            return (
                                                <option key={index} value={township.id}>{township.name}</option>
                                            )
                                        })
                                    }
                                </select>

                                <Input type="date" placeholder="Select Date..." name="register_date" value={this.state.user.register_date} onChange={this.handleInputRegisterDate} required />
                                <Input type="number" placeholder="Enter Watch Count..." name="register_date" value={this.state.user.watch_count} onChange={this.handleInputWatchCount} required />

                                <Input type="file" name="image_url" placeholder="Choose Image" onChange={this.onImageChange} />
                            </div>
                            <Button caption={this.props.isEdit ? "Update" : "Create"} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.user)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserCreate
