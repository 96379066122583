import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
class AnswerListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            teams: [],
            allTeams: [],
            home_team_players: [],
            away_team_players: [],
            allPlayers: [],
            leagueId: '',
            teamName: '',
            home_team_id: '',
            away_team_id: '',
            question: '',
            quiz_answer: {},
            quizInfo: {},
            playerRowCount: 50,
            user_quiz_answers: {},
            rowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            requiredError: ''
        }
    }
    componentDidMount = async () => {
        let quizId = this.props.match.params.id
        let quizDetailId = this.props.match.params.quiz_detail_id

        await axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/detail`, { id: quizDetailId }).then(response => {
            let value = response.data.data
            if (quizDetailId == value.id) {
                this.setState({
                    quizInfo: value,
                    leagueId: value?.SpecialQuiz?.league_id,
                    home_team_id: (value?.quiz_type !== 4) && value?.SpecialQuiz?.Match?.home_team_id,
                    away_team_id: (value?.quiz_type !== 4) && value?.SpecialQuiz?.Match?.away_team_id,
                }, () => {
                    let teams = [];
                    let id = this.state.quizInfo?.SpecialQuiz?.match_id
                    axios.post(`${CONSTANTS.BASE_URL}matches/detail`, { id: id }).then((res) => {
                        let data = res.data.data
                        teams.push(data?.HomeTeam)
                        teams.push(data?.AwayTeam)
                        this.setState({
                            teams: teams
                        })
                    })
                    // let allTeams = [];
                    // axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?league_id=${this.state.leagueId}&row_count=${30}&page_at=${this.state.initialPage}`).then((response) => {
                    //     let data = response.data.data
                    //     data.map((team) => {

                    //         allTeams.push(team)
                    //     })
                    //     this.setState({
                    //         allTeams: allTeams
                    //     })
                    // })
                    // this.props.searchWithLeagueAndTeamName(this.state.quizInfo?.SpecialQuiz?.league_id,this.state.teamName,this.state.teamRowCount,this.state.initialPage)
                    // this.getAllTeams();
                })
            }
        })

        Object.values(this.props.quiz_details_lists).map(quiz_detail => {
            quiz_detail.id == this.props.match.params.quiz_detail_id && this.setState({
                question: quiz_detail.question
            })

        })
        if (this.state.quizInfo?.submit_type === 4) {
            if (this.state.quizInfo?.quiz_type === 1 || this.state.quizInfo?.quiz_type === 3) {

                let promise = [
                    axios.post(`${CONSTANTS.BASE_URL}players/list?team_id=${this.state.home_team_id}&page_at=${this.state.initialPage}&row_count=${this.state.playerRowCount}&sort_by=name&order_by=ASC`),
                    axios.post(`${CONSTANTS.BASE_URL}players/list?team_id=${this.state.away_team_id}&page_at=${this.state.initialPage}&row_count=${this.state.playerRowCount}&sort_by=name&order_by=ASC`),
                    // axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?league_id=${this.state.quizInfo?.SpecialQuiz?.league_id}&teamName=""&row_count=${this.state.teamRowCount}&page_at=${this.state.initialPage}`)
                ];
                Promise.all(promise).then((response) => {
                    this.setState({
                        home_team_players: response[0].data.data,
                        away_team_players: response[1].data.data,
                        allPlayers: [...response[0].data.data, ...response[1].data.data],
                        // allTeams: response[2].data.data
                    })
                })
            }
            if (this.state.quizInfo?.quiz_type === 2) {
                axios.post(`${CONSTANTS.BASE_URL}odds_rates/list?special_quiz_detail_id=${this.state.quizInfo.id}&page_at=${this.state.initialPage}&row_count=${this.state.playerRowCount}`).then((response) => {
                    let data = response.data.data;
                    let players = [];
                    let teams = [];
                    data.map((player) => {
                        players.push(player?.Player)
                        teams.push(player?.Team)

                    })
                    this.setState({
                        allPlayers: players,
                        allTeams: teams
                    })
                })
            }
        }

        this.getCorrectAnswer();
        this.props.getQuizDetailsLists(quizId, this.state.initialPage, this.state.rowCount)
        this.props.getUserQuizAnswers(quizDetailId, this.state.initialPage, this.state.rowCount);

    }

    // getAllTeams = () => {
    //     this.props.searchWithLeagueAndTeamName(this.state.leagueId, this.state.teamName, this.state.teamRowCount, this.state.initialPage)
    // }
    loadMoreTeams = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        }, () => {
            this.props.searchWithLeagueAndTeamName(this.state.leagueId, this.state.teamName, this.state.teamRowCount, this.state.initialPage)
        })
    }
    getPlayers = (id) => {
        let player = this.state.allPlayers?.find(player => player.id == id)
        return player;
    }
    getTeam = (id) => {
        let team = this.state.quizInfo?.quiz_type == 2 ? this.state.allTeams?.find(team => team.id == id) : this.state.teams?.find(team => team.id == id)
        return team;
    }
    getCorrectAnswer = () => {
        let quizId = this.props.match.params.id
        let quizDetailId = this.props.match.params.quiz_detail_id
        this.props.getQuizAnswers(quizId, quizDetailId, this.state.initialPage, this.state.rowCount)
    }
    handleEdit = (quiz_answer) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            quiz_answer: {
                ...prevState.quiz_answer,
                quiz_answer
            }
        }))
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let quiz_answer = {
            special_quiz_detail_id: data.special_quiz_detail_id,
            // answer: this.state.quizInfo?.quiz_type === 3 ? JSON.stringify(data.answer) : data.answer,
            answer: data.answer,
            active: data.active,
            quiz_type: data.quiz_type,
            updated_by: operator.id,
        }
        if (data.match_id) {
            quiz_answer = { ...quiz_answer, match_id: data.match_id }
        }
        if (data.win_case) {
            quiz_answer = { ...quiz_answer, win_case: data.win_case }
        }
        if (this.state.isEdit) {
            quiz_answer = {
                ...quiz_answer,
                id: data.id,
            }
            if (quiz_answer.special_quiz_detail_id === '' || quiz_answer.answer === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                // this.props.updateQuizAnswer(quiz_answer)
                this.props.updateQuizAnswer(quiz_answer).then(() => {
                    this.getCorrectAnswer();
                })

                this.hideModal();
            }
        } else {
            quiz_answer = { ...quiz_answer, created_by: operator.id }
            if (quiz_answer.special_quiz_detail_id === '' || quiz_answer.answer === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                // this.props.addQuizAnswer(quiz_answer)
                this.props.addQuizAnswer(quiz_answer).then(() => {
                    this.getCorrectAnswer();
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }

    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
}

export default AnswerListController