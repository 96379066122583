
import styled from "styled-components";

const MainContainer = styled.div`
    width:90%;
    height:100%;
    // background-color:red;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
const Header = styled.div`
    display:flex;
    // justify-content:space-between;
    width:100%;
    height:10%;
    // background-color:green;
    h4{
        font-size:30px;
        font-family: "Roboto", sans-serif;
        width:100%;
  background: -webkit-linear-gradient(#202020, #FAD230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
      }
      
     
`

const Body = styled.div`
    width:100%;
    height:80%;
    padding-top:20px;

    // background-color:blue;
`
const Footer = styled.div`
    width:100%;
    height:10%;
    // background-color:silver;
`

const InputBox = styled.div`
    display: flex;
    width: 100%;
    input{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .icon{
        width: 45px;
        align-items:center;
        padding: 12px 10px 23px 12px;
        max-height:42px;
        font-size: 14px;
        margin-top: 12px;
        border: 1px solid #fad230;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #ffffff;
        color: #202020;
        cursor: pointer;
        text-align: center;
    }
`
export {
    MainContainer, Header, Body, InputBox, Footer
}