const VersionCheckType = {
  GET_VERSION_CHECKS: "GET_VERSION_CHECKS",
  ADD_VERSION_CHECK: "ADD_VERSION_CHECK",
  UPDATE_VERSION_CHECK: "UPDATE_VERSION_CHECK",
  DELETE_VERSION_CHECK: "DELETE_VERSION_CHECK",
  VERSION_CHECK_ERROR: "VERSION_CHECK_ERROR",
  VERSION_CHECK_SUCCESS: "VERSION_CHECK_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  SEARCH_ITEM: "SEARCH_ITEM",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default VersionCheckType