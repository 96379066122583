const SpecialQuizType = {
    GET_SPECIAL_QUIZZES: "GET_SPECIAL_QUIZZES",
    ADD_SPECIAL_QUIZ: "ADD_SPECIAL_QUIZ",
    UPDATE_SPECIAL_QUIZ: "UPDATE_SPECIAL_QUIZ",
    DELETE_SPECIAL_QUIZ: "DELETE_SPECIAL_QUIZ",
    SPECIAL_QUIZ_ERROR: "SPECIAL_QUIZ_ERROR",
    SPECIAL_QUIZ_SUCCESS: "SPECIAL_QUIZ_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_LEAGUE_ID: 'SEARCH_WITH_LEAGUE_ID',
    SEARCH_WITH_QUIZ_TYPE: 'SEARCH_WITH_QUIZ_TYPE',
    SEARCH_WITH_DATE: "SEARCH_WITH_DATE",
    SEARCH_WITH_ISCURRENT_DATE: "SEARCH_WITH_ISCURRENT_DATE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default SpecialQuizType