import React, { Component } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import { RouteWithLayout } from './components/routers';
import { MainLayout, LoginLayout, LogoutLayout } from './layouts';

import UserListing from './pages/user/listing/UserListing';
import OperatorListing from './pages/operator/listing/OperatorListing';
import CountryListing from './pages/country/listing/CountryListing';
import CityListing from './pages/city/listing/CityListing';
import TownshipListing from './pages/township/listing/TownshipListing';
import TeamListing from './pages/team/listing/TeamListing'
import StadiumListing from './pages/stadium/listing/StadiumListing'
import MatchListing from './pages/match/listing/MatchListing'
import AccessToMatchList from './pages/access_to_match/listing/AccessToMatchList'
import { MatchAlertList } from './pages/match_alert/listing/MatchAlertList.jsx'
import PaymentTypeList from './pages/payment_type/listing/PaymentTypeList'
import RoleListing from './pages/role/listing/RoleListing';
import PermissionList from './pages/permission/listing/PermissionList'
import RolePermissionList from './pages/role_permission/listing/RolePermissionList'
import WalletListing from './pages/wallet/listing/WalletListing'
import LiveStreamingList from './pages/live_streaming/listing/LiveStreamingList'
import TransactionsList from './pages/transactions/listing/TransactionsList'
import NotificationList from './pages/notifications/NotificationList.jsx'

import { LogInPage, NotFoundPage } from './pages';

import ChangePassword from './pages/change_password/ChangePassword';
import RegisterLayout from './layouts/register/RegisterLayout';
import LeagueList from './pages/league/listing/LeagueList';
import HighlightList from './pages/highlight/listing/HighlightList';
import TeamLeagueListing from './pages/team_league/listing/TeamLeagueListing';
import OneSignal from 'react-onesignal'
import CONSTANTS from './redux/constants';

import FavouriteTeamLevelRuleList from './pages/favorite_team_level_rule/list/FavouriteTeamLevelRuleList';
import UserTeamActivityList from './pages/user_team_activity/list/UserTeamActivityList';
import UserFavouriteTeamList from './pages/user_favourite_team/list/UserFavouriteTeamList';
import GlobalLevelRuleList from './pages/global_level_rule/list/GlobalLevelRuleList';
import UserDetail from './pages/user/detail/UserDetail';
import ProtectedRoute from './components/routers/ProtectedRoute';
import LiveScorelist from './pages/livescore/list/LiveScorelist';
import ScoreDetail from './pages/livescore/score_detail/ScoreDetail';
import ActivityList from './pages/activity_log/list/ActivityList';
import QuestionList from './pages/question/listing/QuestionList';
import AnswerList from './pages/special_quiz_answers/list/AnswerList';
import LeaderBoard from './pages/leaderboard/list/LeaderBoard';
import SpecialQuizList from './pages/special_quiz/list/SpecialQuizList.jsx';
import HomePage from './pages/home/HomePage';
import PlayerList from './pages/players/list/PlayerList';
import OddsList from './pages/odds/list/OddsList';
import FutureDetailsList from './pages/future_details/list/FutureDetailsList';
import SponsorList from './pages/sponsor/list/SponsorList';
import SpecialQuizDetailList from './pages/special_quiz_detail/special_quiz_detail_list/SpecialQuizDetailList';
import QuizDetailFutureList from './pages/special_quiz_detail/future/list/QuizDetailFutureList';
import CoinPolicyList from './pages/coin_policy/list/CoinPolicyList';
import PrizeList from './pages/price/list/PrizeList';
import PrizeHistoryList from './pages/prize_history/listing/PrizeHistoryList';
import AnnouncementList from './pages/announcement/list/AnnouncementList';
import SiteConfigsList from './pages/site_configs/list/SiteConfigsList';
import UserFavNationalTeamList from './pages/user_favourite_national_team/list/UserFavNationalTeamList';
import UnauthorizedPage from './pages/error/UnauthorizedPage';
import SubProtectedRoutes from './components/routers/SubProtectedRoutes';
import VersionCheckList from './pages/version_check/list/VersionCheckList';


class App extends Component {

  componentDidMount() {
    OneSignal.init({
      appId: `${CONSTANTS.ONESIGNAL_APP_ID}`,
    });

    // const operator = JSON.parse(localStorage.getItem('operator'))
    // if (operator) {
    //   const externalUserId = operator.id;
    //   OneSignal.setExternalUserId(externalUserId)
    //   console.log("external id =>", externalUserId)
    // }
  }

  render() {

    return (
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          <Route path="/" element={<Navigate replace={true} to="/home" />} />

          <Route path="register" element={<MainLayout><RegisterLayout /></MainLayout>} />
          <Route path="/home" element={<MainLayout><HomePage /></MainLayout>} />

          <Route path="/" element={<SubProtectedRoutes routeName="Operator" />}>
            <Route path="operator" element={<MainLayout><OperatorListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Role" />}>
            <Route path="role" element={<MainLayout><RoleListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Permission" />}>
            <Route path="permission" element={<MainLayout><PermissionList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Role Permission" />}>
            <Route path="role_permission" element={<MainLayout><RolePermissionList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Country" />}>
            <Route path="country" element={<MainLayout><CountryListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="City" />}>
            <Route path="city" element={<MainLayout><CityListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Township" />}>
            <Route path="township" element={<MainLayout><TownshipListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="League" />}>
            <Route path="league" element={<MainLayout><LeagueList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Team" />}>
            <Route path="team" element={<MainLayout><TeamListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Player" />}>
            <Route path="player" element={<MainLayout><PlayerList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Stadium" />}>
            <Route path="stadium" element={<MainLayout><StadiumListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Team League" />}>
            <Route path="team_league" element={<MainLayout><TeamLeagueListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Match" />}>
            <Route path="match" element={<MainLayout><MatchListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Access To Match" />}>
            <Route path="access_to_match" element={<MainLayout><AccessToMatchList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Match Alert" />}>
            <Route path="match_alert" element={<MainLayout><MatchAlertList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Payment Type" />}>
            <Route path="payment_type" element={<MainLayout><PaymentTypeList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Live Streaming" />}>
            <Route path="live_streaming" element={<MainLayout><LiveStreamingList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Wallet" />}>
            <Route path="wallet" element={<MainLayout><WalletListing /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="User" />}>
            <Route path="user" element={<MainLayout><UserListing /></MainLayout>} />
            <Route path="user_detail/:id" element={<MainLayout><UserDetail /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Transaction" />}>
            <Route path="transactions" element={<MainLayout><TransactionsList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Highlight" />}>
            <Route path="highlight" element={<MainLayout><HighlightList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Favourite Team Level Rule" />}>
            <Route path="favourite_team_level_rules" element={<MainLayout><FavouriteTeamLevelRuleList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Global Level Rule" />}>
            <Route path="global_level_rules" element={<MainLayout><GlobalLevelRuleList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="User Team Activity" />}>
            <Route path="user_team_activities" element={<MainLayout><UserTeamActivityList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="User Favourite Team" />}>
            <Route path="user_favourite_teams" element={<MainLayout><UserFavouriteTeamList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="User Favourite National Team" />}>
            <Route path="user_favourite_national_teams" element={<MainLayout><UserFavNationalTeamList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="LiveScore" />}>
            <Route path="livescore" element={<MainLayout><LiveScorelist /></MainLayout>} />
            <Route path="score_detail/:matchId" element={<MainLayout><ScoreDetail /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Leaderboard" />}>
            <Route path="leaderboard" element={<MainLayout><LeaderBoard /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Quizzes" />}>
            <Route path="quizzes" element={<MainLayout><SpecialQuizList /></MainLayout>} />
            <Route path="quizzes/:id" element={<MainLayout><SpecialQuizDetailList /></MainLayout>} />
            <Route path="quizzes/:quiz/:id" element={<MainLayout><QuizDetailFutureList /></MainLayout>} />
            <Route path="quiz_detail/:id/answers/:quiz_detail_id" element={<MainLayout><AnswerList /></MainLayout>} />
            <Route path="odds/:id" element={<MainLayout><OddsList /></MainLayout>} />
            <Route path="quiz_detail/:id/:detail/:future_detail_id" element={<MainLayout><FutureDetailsList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Question" />}>
            <Route path="questions" element={<MainLayout><QuestionList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Sponsor" />}>
            <Route path="sponsor" element={<MainLayout><SponsorList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Coin Policy" />}>
            <Route path="coin_policy" element={<MainLayout><CoinPolicyList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Notification" />}>
            <Route path="notifications" element={<MainLayout><NotificationList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Prize" />}>
            <Route path="prizes" element={<MainLayout><PrizeList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Prize History" />}>
            <Route path="prize_histories" element={<MainLayout><PrizeHistoryList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Activity Logs" />}>
            <Route path="activity" element={<MainLayout><ActivityList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Announcement" />}>
            <Route path="announcement" element={<MainLayout><AnnouncementList /></MainLayout>} />
          </Route>

          <Route path="/" element={<SubProtectedRoutes routeName="Site Config" />}>
            <Route path="site_configs" element={<MainLayout><SiteConfigsList /></MainLayout>} />
          </Route>
          <Route path="/" element={<SubProtectedRoutes routeName="Version Check" />}>
            <Route path="version_check" element={<MainLayout><VersionCheckList /></MainLayout>} />
          </Route>
          <Route path="change_password" element={<MainLayout><ChangePassword /></MainLayout>} />
        </Route>
        <Route path="login" element={<LoginLayout />} />
        <Route path="logout" element={<LogoutLayout />} />
        <Route path="*" element={<NotFoundPage />} />



      </Routes>
    )
  }
}


export {
  App
}

