import React from 'react';
import { LeagueCreate } from './../create/LeagueCreate';
import LeagueListController from './LeagueListController';
import LeagueAction from './../../../redux/action/league_action';
import CountryAction from './../../../redux/action/country_action';
import { MainContainer, Header, Body, Button, connect, Pagination, Modal, PageLoader, Search, Alert } from '../../../components/ImportsFile'
class LeagueList extends LeagueListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'League' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'League' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'League' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Leagues</h4>
                    <form className="search search-league" autoComplete="off">
                        <div className="input-group">
                            <input type="text" className="input-field" placeholder="Search.." name="search" value={this.state.text} onChange={this.handleInputText} />
                            {/**
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        */}
                            <Search
                                items={this.props.countries}
                                searchItem={(countryId) => this.handleSelectCountry(countryId)}
                                searching={this.props.searchCountry}
                                currentPage={this.props.currentPage}
                                text={'Search with Country'}
                                search={this.state.countryId ? true : false}
                                LoadMore={this.loadMore}
                                getItems={this.props.getAllCountries}
                                rowCount={this.props.countryRowCount}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>

                    </form>

                    {createPermission && <Button icon="" btnFun={this.showModal} caption="Add League" className="btn-primary add-button" />}

                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Logo</th>
                                <th>Country</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.leagues.map((league, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <img src={league.logo_url} alt="choosed images" />
                                            </td>
                                            <td>{league.Country?.name}</td>
                                            <td>{league.name}</td>
                                            <td>{league.description || "-"}</td>
                                            <td>{league.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteLeague(league.id)} editItem={() => this.handleEdit(league)} updatePermission={updatePermission} deletePermission={deletePermission} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage}
                        paginate={(pageNumber) => {
                            (!this.props.text && !this.props.countryId) && this.props.getLeagues(pageNumber, this.props.row_count);
                            (this.props.text || this.props.countryId) && this.props.searchItem(this.props.text, this.props.countryId, pageNumber)
                        }} />

                    {
                        this.state.show &&
                        <LeagueCreate
                            isEdit={this.state.isEdit}
                            countries={this.props.countries}
                            loadMore={this.loadMore}
                            league={this.state.league}
                            createOrUpdate={this.createOrUpdate}
                            hideModal={this.hideModal}
                            searchCountry={this.props.searchCountry}
                            countryText={this.propscountryText}
                            currentPage={this.props.currentPage}
                            getAllCountries={this.props.getAllCountries}
                            countryRowCount={this.props.countryRowCount}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        leagues: state.league.leagues,
        error: state.league.error,
        success: state.league.success,
        currentPage: state.league.currentPage,
        totalCount: state.league.totalCount,
        loading: state.league.loading,
        totalCountPerPage: state.league.totalCountPerPage,
        text: state.league.text,
        countryId: state.league.countryId,
        row_count: state.league.row_count,
        countries: state.country.countries,
        countryText: state.country.text,
        countryRowCount: state.country.row_count
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getLeagues(pageNumber, row_count)),
        createLeague: (league) => dispatch(LeagueAction.createLeague(league)),
        updateLeague: (league) => dispatch(LeagueAction.updateLeague(league)),
        deleteLeague: (id) => dispatch(LeagueAction.deleteLeague(id)),
        getAllCountries: (pageNumber, row_count) => dispatch(CountryAction.getAllCountries(pageNumber, row_count)),
        searchItem: (text, countryId, pageNumber) => dispatch(LeagueAction.searchItem(text, countryId, pageNumber)),
        searchCountry: (text, pageNumber) => dispatch(CountryAction.searchCountry(text, pageNumber))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeagueList)
