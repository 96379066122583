import React from 'react'
import Modal from 'react-modal'
import FutureDetailsCreateController from './FutureDetailsCreateController';
import { Search, styled, Button, Pagination } from '../../../components/ImportsFile'
import fallBackIcon from '../../../images/icons/player.png'
class FutureDetailsCreate extends FutureDetailsCreateController {
  render() {

    return (
      <Modal className="createSelectModal future_modal"
        ariaHideApp={false}
        isOpen={this.props.show}
        onRequestClose={this.props.hideModal}
        shouldCloseOnOverlayClick={true}
        style={
          {
            overlay: {
              background: 'transparent'
            }
          }
        }
      >
        <h4>Choose {this.props.Caption}</h4>
        <p className="close" onClick={this.props.hideModal} style={{ fontSize: '30px' }}>x</p>

        <div style={{ paddingTop: '25px', width: '100%' }}>
          <form onSubmit={this.handleSearch} className="search search_select_team">
            {
              // for player
              this.props.type === 1 &&
              <div className="input-group">
                <Search
                  futureDetailSearch={true}
                  searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                  stopSearching={this.props.searchWithLeagueAndTeamName}
                  style={{ width: '50%' }}
                  text={"Team"}
                  search={true}
                  teams={this.props.teams}
                  leagueId={this.props.leagueId}
                  searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                  currentPage={this.props.teamCurrentPage}
                  getItems={() => this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.props.teamRowCount, this.props.teamCurrentPage)}
                  rowCount={this.props.teamRowCount}
                  LoadMore={this.props.loadMoreTeams}
                />
                <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                <button type="submit"><i className="fa fa-search"></i></button>
              </div>
            }
            {
              // for team
              this.props.type === 2 &&
              <div className="input-group">
                <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                <button type="submit"><i className="fa fa-search"></i></button>
              </div>
            }
            {
              // for manager
              this.props.type === 3 &&
              <div className="input-group">
                <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                <button type="submit"><i className="fa fa-search"></i></button>
              </div>
            }
          </form>
        </div>

        <ItemsCard className="item-lists choose_item_card">
          {

            // for player
            (this.props.type === 1 && this.props.players) && this.props.players.map((player, index) => {
              return (
                !this.props.prevData.includes(player.id) &&
                <Items key={index} >
                  <div className="img">
                    <img src={player.image_url} alt={player.name} style={{ width: "30px" }} onError={(e) => (e.currentTarget.src = fallBackIcon)} />
                  </div>
                  <div className="name">
                    <span> {player.name}</span>
                  </div>
                  <div className="team">
                    <span>{player?.Team?.name}</span>
                  </div>
                  <div className="selectBtn">
                    <Button caption='Select' className="btn-success" btnFun={() => {
                      this.props.handleSelect(player.id, player.name)
                    }} />
                  </div>
                </Items>
              )

            })
          }

          {
            // for team
            (this.props.type === 2 && this.props.teams) && this.props.teams.map((team, index) => {
              return (
                !this.props.prevData.includes(team.team_id) &&
                <Items key={index} >
                  <div className="img">
                    <img src={team?.Team?.logo_url} alt={team.name} style={{ width: "30px" }} />
                  </div>
                  <div className="team">
                    <span>{team?.Team?.name}</span>
                  </div>
                  <div className="selectBtn">
                    <Button caption='Select' className="btn-success" btnFun={() => {
                      this.props.handleSelect(team?.Team?.id, team?.Team?.name)
                    }} />
                  </div>
                </Items>
              )
            })
          }

          {
            // for manager
            (this.props.type === 3 && this.props.players) && this.props.players.map((player, index) => {
              return (
                !this.props.prevData.includes(player.id) &&
                <Items key={index} >
                  <div className="img">
                    <img src={player.image_url} alt={player.name} style={{ width: "30px" }} />
                  </div>
                  <div className="name">
                    <span> {player.name}</span>
                  </div>
                  <div className="team">
                    <span>{player?.Team?.name}</span>
                  </div>
                  <div className="selectBtn">
                    <Button caption='Select' className="btn-success" btnFun={() => {
                      this.props.handleSelect(player.id, player.name)
                    }} />
                  </div>
                </Items>
              )
            })
          }
        </ItemsCard>

        {
          // for player
          this.props.type === 1 &&
          <Pagination hideShowingTotalCount={true} activePage={this.props.playerCurrentPage} totalItemsCount={this.props.playerTotalCount} totalCountPerPage={this.props.playerTotalCountPerPage}
            paginate={(pageNumber) => {
              this.props.text ? this.props.searchPlayer(this.props.text, '', '', '', this.props.playerRowCount, pageNumber)
                : this.props.searchPlayer(this.state.text, this.state.position, '', this.state.teamId, this.state.playerRowCounts, pageNumber)
              // this.props.getPlayers(this.state.teamId, pageNumber, this.props.playerRowCount)
              // : this.props.getPlayers(this.state.teamId, pageNumber, this.props.playerRowCount)
              // this.props.searchPlayer(this.state.text, 'defenders', this.props.leagueIdFromPlayer, this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
            }} />
        }
        {
          // for team
          // (this.props.type === 2 && !this.props.teamName) &&
          this.props.type == 2 &&
          // this.props.teamTotalCountPerPage > 10 &&
          <Pagination
            hideShowingTotalCount={true}
            activePage={this.props.teamCurrentPage}
            totalItemsCount={this.props.teamTotalCount}
            totalCountPerPage={this.props.teamTotalCountPerPage}
            paginate={(pageNumber) => {
              this.props.teamName ? this.props.searchWithLeagueAndTeamName(this.props.leagueId, this.props.teamName, this.state.teamRowCount, pageNumber) :
                this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.state.teamRowCount, pageNumber)
              // (!this.props.teamName) && this.props.getTeams(pageNumber, this.props.teamRowCount);
            }}
          />
        }

        {
          // for player
          this.props.type === 3 &&
          <Pagination hideShowingTotalCount={true} activePage={this.props.playerCurrentPage} totalItemsCount={this.props.playerTotalCount} totalCountPerPage={this.props.playerTotalCountPerPage} paginate={(pageNumber) => {
            this.props.text ? this.props.searchPlayer(this.props.text, '', '', '', this.props.playerRowCount, pageNumber) : this.props.searchPlayer('', 'manager', this.props.leagueIdFromPlayer, '', this.props.playerRowCount, pageNumber)

            // this.props.text ? this.props.searchPlayer(this.props.text, '', '', this.props.playerRowCount, pageNumber) : this.props.searchPlayer('', '', 'manager', this.props.playerRowCount, pageNumber)
          }} />
        }
      </Modal>


    )
  }
}

export default FutureDetailsCreate


const ItemsCard = styled.div`
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:row;
    width: 100%;
    background-color: #eeeded;
`
const Items = styled.div`
  display:flex;
  width:100%;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:10px 20px 10px 20px;

  .selectBtn{
    text-align:left;
    min-width:10%;
  }
  .img{
    text-align:left;
    min-width:15%;
  }
  .name, .team{
    text-align:left;
    min-width:35%;
  }
`