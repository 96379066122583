
import CONSTANTS from './../constants';
import axios from 'axios'
import SpecialQuizDetailsType from './../type/special_quiz_detail_type';
import OddsRateType from './../type/odds_reate_type';

export const Error = (data) => {
  return {
    type: SpecialQuizDetailsType.SPECIAL_QUIZ_DETAILS_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: SpecialQuizDetailsType.SPECIAL_QUIZ_DETAILS_SUCCESS, payload: data
  }
}
const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: SpecialQuizDetailsType.LOADING, payload: status });
  }
}
const toggleEdit = (item) => {
  return (dispatch) => {
    return dispatch({ type: SpecialQuizDetailsType.TOGGLE_EDIT, payload: item });
  }
}
const getFutureDetails = (specialQuizId, questionId, type, pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/details?special_quiz_id=${specialQuizId}&question_id=${questionId}&type=${type}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      let data = response.data.data.map(item => {
        item.isEdit = false;
        return item
      })
      // for (let i = 0; i < data.length; i++) {

      //   if (data[i]?.OddsRates?.length > 0) {
      //     newData.push(data[i])
      //   }
      // }
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.GET_FUTURE_DETAILS,
          // payload: newData
          payload: data
        })
        dispatch({
          type: SpecialQuizDetailsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: SpecialQuizDetailsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: SpecialQuizDetailsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: SpecialQuizDetailsType.ROW_COUNT, payload: row_count
        })
        dispatch({
          type: SpecialQuizDetailsType.SEARCH_WITH_SPECIAL_QUIZ_ID, payload: specialQuizId
        })
        dispatch({
          type: SpecialQuizDetailsType.SEARCH_WITH_TYPE, payload: type
        })
        dispatch({
          type: SpecialQuizDetailsType.SEARCH_WITH_QUESTION_ID, payload: questionId
        })
      } else {
        let message = "Failed"
        dispatch({
          type: SpecialQuizDetailsType.ERROR,
          payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SpecialQuizDetailsType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const getSpecialQuizDetailsLists = (specialQuizId, pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?special_quiz_id=${specialQuizId}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.GET_SPECIAL_QUIZ_DETAILS_LISTS,
          payload: response.data.data
        })
        dispatch({
          type: SpecialQuizDetailsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: SpecialQuizDetailsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: SpecialQuizDetailsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: SpecialQuizDetailsType.ROW_COUNT, payload: row_count
        })
        dispatch({
          type: SpecialQuizDetailsType.SEARCH_WITH_SPECIAL_QUIZ_ID, payload: specialQuizId
        })
      } else {
        let message = "Failed"
        dispatch({
          type: SpecialQuizDetailsType.ERROR,
          payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SpecialQuizDetailsType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const createSpecialQuizDetails = (special_quiz_detail) => {

  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/create`, special_quiz_detail).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.ADD_SPECIAL_QUIZ_DETAILS,
          payload: response.data.data
        })
        // dispatch(getFutureDetails(response.data.data.special_quiz_id, response.data.data.question_id, response.data.data.type))
        // dispatch({
        //   type: SpecialQuizDetailsType.GET_FUTURE_DETAILS,
        //   payload: response.data.data
        // })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }



}
const createSpecialQuizDetailsWithOddsRate = (special_quiz_detail) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/createWithOddsRate`, special_quiz_detail).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.ADD_SPECIAL_QUIZ_DETAILS,
          payload: response.data.data.quizDetail
        })
        dispatch({
          type: OddsRateType.ADD_ODDS_RATE,
          payload: response.data.data.oddsRate
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
        dispatch(loading(false))
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }



}
const updateSpecialQuizDetails = (special_quiz_details) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}special_quiz_details/update`, special_quiz_details).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.UPDATE_SPECIAL_QUIZ_DETAILS,
          payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const deleteSpecialQuizDetails = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'));

  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}special_quiz_details/delete`, { data: { id: id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SpecialQuizDetailsType.DELETE_SPECIAL_QUIZ_DETAILS,
          payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "Failed"
        dispatch({
          type: SpecialQuizDetailsType.ERROR,
          payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SpecialQuizDetailsType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

// const searchSpecialQuizDetails = (quizId, pageNumber) => {
//   return (dispatch) => {
//     return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?quiz_id=${quizId}&page_at=${pageNumber}`).then(response => {
//       if (response.data.status === "Success") {
//         dispatch({
//           type: SpecialQuizDetailsType.GET_QUIZ_DETAILS_LISTS, payload: response.data.data
//         })
//         dispatch({
//           type: SpecialQuizDetailsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
//         })
//         dispatch({
//           type: SpecialQuizDetailsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
//         })
//         dispatch({
//           type: SpecialQuizDetailsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
//         })
//         dispatch({
//           type: SpecialQuizDetailsType.SEARCH_WITH_QUIZ_ID,
//           payload: quizId
//         })
//       }
//     })
//   }
// }

const SpecialQuizDetailsAction = {
  getSpecialQuizDetailsLists,
  createSpecialQuizDetails,
  updateSpecialQuizDetails,
  deleteSpecialQuizDetails,
  // searchSpecialQuizDetails,
  createSpecialQuizDetailsWithOddsRate,
  getFutureDetails,
  loading,
  toggleEdit
}
export default SpecialQuizDetailsAction