const HighlightType = {
    GET_HIGHLIGHT: "GET_HIGHLIGHT",
    ADD_HIGHLIGHT: "ADD_HIGHLIGHT",
    HIGHLIGHT_DETAIL: "HIGHLIGHT_DETAIL",
    UPDATE_HIGHLIGHT: "UPDATE_HIGHLIGHT",
    DELETE_HIGHLIGHT: "DELETE_HIGHLIGHT",
    HIGHLIGHT_ERROR: "HIGHLIGHT_ERROR",
    HIGHLIGHT_SUCCESS: "HIGHLIGHT_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_DATA: "SEARCH_DATA",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default HighlightType;