
import AnnouncementType from './../type/announcement_type';

const initialState = {
  announcements: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  totalPage: 1,
  text: null,
  row_count: 1,
  loading: false,
}
const announcementReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case AnnouncementType.GET_ANNOUNCEMENTS:
      return {
        ...state,
        loading: false,
        announcements: actions.payload,
      }
    case AnnouncementType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case AnnouncementType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case AnnouncementType.TOTAL_PAGE:
      return {
        ...state,
        totalPage: actions.payload
      }
    case AnnouncementType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case AnnouncementType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case AnnouncementType.ADD_ANNOUNCEMENT:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        announcements: [
          actions.payload,
          ...state.announcements,
        ],

      }
    case AnnouncementType.UPDATE_ANNOUNCEMENT:
      const updateAnnouncement = state.announcements.filter(announcement => announcement.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        prizes: [
          actions.payload,
          ...updateAnnouncement,
        ]
      }
    case AnnouncementType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case AnnouncementType.DELETE_ANNOUNCEMENT:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        announcements: state.announcements.filter(announcement => announcement.id !== actions.payload)
      }
    case AnnouncementType.SEARCH_ITEM:
      return {
        ...state,
        text: actions.payload
      }
    case AnnouncementType.ANNOUNCEMENT_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case AnnouncementType.ANNOUNCEMENT_SUCCESS:
      return { ...state, success: actions.payload, loading: false }
    default: return state
  }
}
export default announcementReducer