import CONSTANTS from './../constants';
import axios from 'axios'
import WalletType from './../type/wallet_type';

export const WalletError = (data) => {
    return {
        type: WalletType.WALLET_ERROR, payload: data
    }
}
export const WalletSuccess = (data) => {
    return {
        type: WalletType.WALLET_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: WalletType.LOADING, payload: status })
    }
}
const getWallets = (sortBy,pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user/wallets/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=${sortBy}&order_by=DESC`).then((response) => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: WalletType.GET_WALLETS,
                    payload: response.data.data
                })
                dispatch({
                    type: WalletType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: WalletType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: WalletType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: WalletType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: WalletType.SORT_BY, payload: sortBy
                })
            }
            else {
                let message = "Fail"
                dispatch({
                    type: WalletType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: WalletType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(WalletError(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(WalletError(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateWallet = (wallet) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}user/wallets/update`, wallet).then((response) => {
            if (response.data.status === 'Success') {

                // localStorage.setItem('notification', 'new action')

                dispatch({
                    type: WalletType.UPDATE_WALLET,
                    payload: response.data.data
                })
                // dispatch({
                //     type: WalletType.NOTIFICATION,
                //     payload: true,
                // })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(WalletSuccess(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(WalletSuccess(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                const errorPayload = {};
                errorPayload['message'] = response.data.message;

                dispatch(WalletError(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(WalletError(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(WalletError(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(WalletError(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user/wallets/list?user_id=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: WalletType.GET_WALLETS, payload: response.data.data
                })
                dispatch({
                    type: WalletType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: WalletType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: WalletType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: WalletType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}




const WalletAction = {
    getWallets,
    updateWallet,
    searchItem,
}
export default WalletAction