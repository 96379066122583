import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class VersionCheckListController extends Component {
  constructor(props) {
    super(props)


    this.state = {
      show: false,
      searchTerm: '',
      text: '',
      isEdit: false,
      initialPage: 1,
      version_check: {},
      requiredError: '',
      rowCount: CONSTANTS.ROW_COUNT,
    }
  }

  componentDidMount = () => {
    this.props.getVersionChecks(this.state.initialPage, this.state.rowCount);
  }
  stopSearching = () => {
    this.setState({
      text: ''
    }, () => {
      this.props.getVersionChecks(this.state.initialPage, this.state.rowCount);
    })
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchItem(this.state.text, this.state.initialPage)
  }
  handleEdit = async (version_check) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      version_check: {
        ...prevState.version_check,
        version_check
      }
    }))
  }

  hideModal = () => {
    this.setState({
      show: false,
      requiredError: ''
    })
  }

  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }

  createOrUpdate = (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    let version_check = {
      version_name: data.version_name,
      version_code: parseInt(data.version_code),
      force_update: data.force_update,
      update_logs: data.update_logs,
      active: data.active,
      updated_by: operator.id,
    }
    if (data.playstore_link) {
      version_check = { ...version_check, playstore_link: data.playstore_link }
    }
    if (data.appstore_link) {
      version_check = { ...version_check, appstore_link: data.appstore_link }
    }
    if (data.manual_download_link) {
      version_check = { ...version_check, manual_download_link: data.manual_download_link }
    }

    if (this.state.isEdit) {
      version_check = { ...version_check, id: data.id }
      if (version_check.version_name === '' || version_check.version_code === '' || version_check.force_update === '' || version_check.update_logs === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }

      else {
        this.props.updateVersionCheck(version_check).then(() => {
          this.props.getVersionChecks(this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }

    } else {
      version_check = { ...version_check, created_by: operator.id, updated_by: operator.id }
      if (version_check.version_name === '' || version_check.version_code === '' || version_check.force_update === '' || version_check.update_logs === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createVersionCheck(version_check).then(() => {
          this.props.getVersionChecks(this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }
}

export default VersionCheckListController
