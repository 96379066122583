
import PrizeHistoryType from './../type/prize_history_type';
const initialState = {
  prize_histories: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  text: null,
  totalCountPerPage: null,
  row_count: 1,
  notification: false,
  loading: false,
  userId: null,
  prizeId: null,
  startDate: null,
  endDate: null,
}
const prizeHistoryReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case PrizeHistoryType.GET_PRIZE_HISTORIES:
      return {
        ...state,
        loading: false,
        prize_histories: actions.payload
      }
    case PrizeHistoryType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case PrizeHistoryType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case PrizeHistoryType.SEARCH_ITEM:
      return {
        ...state,
        text: actions.payload
      }
    case PrizeHistoryType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case PrizeHistoryType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case PrizeHistoryType.NOTIFICATION:
      return {
        ...state,
        notification: actions.payload
      }
    case PrizeHistoryType.SEARCH_WITH_USER_ID:
      return {
        ...state,
        userID: actions.payload
      }
    case PrizeHistoryType.SEARCH_WITH_PRIZE_ID:
      return {
        ...state,
        prizeId: actions.payload
      }
    case PrizeHistoryType.SEARCH_WITH_START_DATE:
      return {
        ...state,
        startDate: actions.payload
      }
    case PrizeHistoryType.SEARCH_WITH_END_DATE:
      return {
        ...state,
        endDate: actions.payload
      }
    case PrizeHistoryType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case PrizeHistoryType.PRIZE_HISTORY_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case PrizeHistoryType.PRIZE_HISTORY_SUCCESS:
      return { ...state, success: actions.payload, loading: false }
    default: return state
  }
}
export default prizeHistoryReducer