import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../../redux/constants';
class ScoreTableController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      teams: [],
      tables: {},
      // tables: [],
      loading: true,
      text: '',
      showAll: true,
      showHomeTeam: false,
      showAwayTeam: false,
      leagueLogo: 'https://images.fotmob.com/image_resources/logo/teamlogo/'
    }
  }
  componentDidMount = () => {
    this.setState({ loading: true })
    const matchId = this.props.match.params.matchId

    axios.post(`${CONSTANTS.BASE_URL}livescores/matchDetail/${matchId}`).then((response) => {
      this.setState({
        teams: response.data?.header?.teams,

      })
      axios.post(`${CONSTANTS.BASE_URL}livescores/teamDetail/${this.state.teams[0].id || this.state.teams[1].id}`).then((response) => {
        this.setState({
          tables: response.data.table[0]?.data?.tables ? response.data.table[0]?.data?.tables : response.data.table[0]?.data,
          loading: false,
        })
        // this.setState({
        //   tables: response.data.table[0]?.tables ? response.data.table[0]?.tables : response.data.table[0],
        //   loading: false,
        // })
      })
    })
  }
  getAllTeams = () => {

    this.setState({
      showHomeTeam: false,
      showAll: true,
      showAwayTeam: false
    })
  }
  getHomeTeam = () => {
    this.setState({
      showHomeTeam: true,
      showAll: false,
      showAwayTeam: false
    })
  }
  getAwayTeam = () => {
    this.setState({
      showHomeTeam: false,
      showAll: false,
      showAwayTeam: true
    })

  }
  stopSearching = () => {
    this.getAllTeams();
    this.setState({
      text: ''
    })
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
  }
}

export default ScoreTableController