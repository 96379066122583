import React from 'react'
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import { MatchCreateController } from './MatchCreateController';
import SelectModal from './../../../components/selectModal/SelectModal';
import moment from 'moment'
import AutoSelectingPages from './../../auto_select/AutoSelectingPages';

class MatchCreate extends MatchCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? "Edit Match" : "Add Match"}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off">
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div style={{ columnCount: '2' }}>

                                <Input type="date" name="date" placeholder="Enter Date..." value={this.state.match.date} min={moment(this.state.today).format("yyyy-MM-DD")} onChange={this.handleDateChange} required />

                                <Input type="time" name="time" placeholder="Enter Time..." value={moment(this.state.match.time, ["h:mm A"]).format("HH:mm")} onChange={this.handleTimeChange} required />


                                <SelectModal
                                    name="league_id"
                                    routeChangeName="league"
                                    items={this.props.leagues}
                                    value={this.state.match.league_id}
                                    rowCount={this.props.teamLeagueRowCount}
                                    currentPage={this.props.leagueCurrentPage}
                                    getItems={this.props.getTeamLeagues}
                                    handleProcess={(league_id) => this.leagueProcess(league_id)}
                                    itemName={this.props.isEdit ? this.props.match.match.League.name : "*** Choose League ***"}
                                    LoadMore={this.props.loadMore1}
                                    searchLeague={this.props.searchLeague}
                                />


                                <SelectModal
                                    teamsForMatchCreate={this.props.teams}
                                    // teamsForMatchCreate={this.props.teams.filter((team) => team.id !== this.state.match.away_team_id)}
                                    // searchWithLeagueAndTeamName={this.props.searchWithLeague}
                                    searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                                    routeChangeName="team"
                                    currentPage={this.props.teamCurrentPage}
                                    handleProcess={(home_team_id) => this.homeTeamIdProcess(home_team_id)}
                                    itemName={(this.props.isEdit && this.props.match.match?.HomeTeam?.name) ? this.props.match.match?.HomeTeam?.name : "*** Choose Home Team ***"}
                                    LoadMore={this.loadMore}
                                    getItems={this.getTeams}
                                    league_id={this.state.match.league_id}
                                    isEdit={this.props.isEdit}
                                    //  teamsForMatchCreate={this.props.teams}
                                    stopSearching={this.props.searchWithLeagueAndTeamName}
                                    rowCount={this.props.teamLeagueRowCount}
                                    disabled={this.state.match.league_id ? '' : "disabled"}
                                />


                                <SelectModal
                                    teamsForMatchCreate={this.props.teams}
                                    // teamsForMatchCreate={this.props.teams.filter((team) => team.id !== this.state.match.home_team_id)}
                                    searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                                    currentPage={this.props.teamCurrentPage}
                                    handleProcess={(away_team_id) => this.awayTeamIdProcess(away_team_id)}
                                    LoadMore={this.loadMore}
                                    getItems={this.getTeams}
                                    league_id={this.state.match.league_id}
                                    routeChangeName="team"
                                    //  teamsForMatchCreate={this.props.teams}
                                    itemName={this.props.isEdit ? this.props.match.match.AwayTeam.name : "*** Choose Away Team ***"}
                                    stopSearching={this.props.searchWithLeagueAndTeamName}
                                    rowCount={this.props.teamLeagueRowCount}
                                    disabled={this.state.match.league_id ? '' : "disabled"}
                                />

                                <AutoSelectingPages
                                    items={this.props.stadiums}
                                    searching={this.props.searchStadium}
                                    currentPage={this.props.stadiumCurrentPage}
                                    getItems={this.props.getAllStadiums}
                                    rowCount={this.props.stadiumRowCount}
                                    routeChangeName="stadium"
                                    selectedId={this.state.match.stadium_id}
                                    selectedName={this.state.stadium_name}
                                    handleProcess={(stadium_id) => this.StadiumProcess(stadium_id)}
                                    itemName={this.props.isEdit ? (this.props.match?.match?.stadium_id === null ? " *** Choose Stadium ***" : this.props.match?.match?.Stadium?.name) : "*** Choose Stadium ***"}
                                    LoadMore={this.props.loadMore3}
                                />

                                <select name="is_playing" className="select" value={this.state.match.is_playing} onChange={this.handlePlayItem} required >
                                    <option value="0">Not Playing</option>
                                    <option value="1">Playing</option>
                                    <option value="2">Finished</option>
                                </select>

                                <select name="active" className="select" value={this.state.match.active} onChange={this.handleActive} required  >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                                <Input type="text" name="remark" placeholder="Enter Remark...(Optional)" value={this.state.match.remark || ''} onChange={this.handleInputRemark} required />

                                <Input type="time" name="time" placeholder="Enter End Time..."
                                    // value={moment(this.state.match.match_end_time).format("h:mm A")}
                                    // value={this.state.match.match_end_time}
                                    value={moment(this.state.match.match_end_time, ["h:mm A"]).format("HH:mm")}
                                    onChange={this.handleEndTimeChange} required />

                            </div>
                            <br />
                            <Button caption={this.props.isEdit ? "Update" : "Create"} type="button" id="submit-btn" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.match)} />


                        </form>
                    </div>
                </div>

            </div>
        );
    }
}

export default MatchCreate