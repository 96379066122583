const PaymentTypeType = {
    GET_PAYMENT_TYPES: "GET_PAYMENT_TYPES",
    ADD_PAYMENT_TYPE: "ADD_PAYMENT_TYPE",
    UPDATE_PAYMENT_TYPE: "UPDATE_PAYMENT_TYPE",
    DELETE_PAYMENT_TYPE: "DELETE_PAYMENT_TYPE",
    PAYMENT_TYPE_ERROR: "PAYMENT_TYPE_ERROR",
    PAYMENT_TYPE_SUCCESS: "PAYMENT_TYPE_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default PaymentTypeType