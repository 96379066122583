import CountryType from './../type/country_type';
import axios from "axios"
import CONSTANTS from './../constants';

export const Error = (data) => {
    return {
        type: CountryType.COUNTRY_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: CountryType.COUNTRY_SUCCESS, payload: data
    }
}

const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: CountryType.LOADING, payload: status })
    }
}
const getCountries = (pageNumber, row_count) => {
    return (dispatch) => {

        return axios.post(`${CONSTANTS.BASE_URL}countries/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: CountryType.GET_COUNTRIES, payload: response.data.data
                })
                dispatch({
                    type: CountryType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: CountryType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: CountryType.TOTAL_PAGE, payload: response.data.pagination.total_page
                })
                dispatch({
                    type: CountryType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: CountryType.ROW_COUNT,
                    payload: row_count
                })
            } else {
                let message = "custom error message"
                dispatch({
                    type: CountryType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CountryType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllCountries = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}countries/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: CountryType.GET_COUNTRIES, payload: response.data.data
                })
                dispatch({
                    type: CountryType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: CountryType.ROW_COUNT,
                    payload: row_count
                })
            } else {
                let message = "custom error message"
                dispatch({
                    type: CountryType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CountryType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const addCountry = (country) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}countries/create`, country).then(response => {
            if (response.data.status === 'Success' || response.data.message === 'Success') {
                dispatch({
                    type: CountryType.ADD_COUNTRY,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }

}
const updateCountry = (country) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}countries/update`, country).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: CountryType.UPDATE_COUNTRY, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}
const deleteCountry = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}countries/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: CountryType.DELETE_COUNTRY, payload: id,
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "custom error message"
                dispatch({
                    type: CountryType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CountryType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}countries/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: CountryType.GET_COUNTRIES, payload: response.data.data
                })
                dispatch({
                    type: CountryType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: CountryType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: CountryType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: CountryType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}
const searchCountry = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}countries/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: CountryType.GET_COUNTRIES, payload: response.data.data
                })
            }
            return dispatch({
                type: CountryType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}

const CountryAction = {
    getCountries,
    getAllCountries,
    addCountry,
    updateCountry,
    deleteCountry,
    searchItem,
    searchCountry,
    loading,
}

export default CountryAction;