import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
class LiveStreamingListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      livestream: {},
      rowCount: CONSTANTS.ROW_COUNT,
      matchRowCount: CONSTANTS.ROW_COUNT,
      text: '',
      matchId: '',
      requiredError: ''
    }
  }

  componentDidMount = () => {
    this.loadMore();
    this.props.getLiveStreams(this.state.initialPage, this.state.rowCount)
  }
  stopSearching = () => {
    this.props.getLiveStreams(this.state.initialPage, this.state.rowCount);
    this.setState({
      text: '',
      matchId: ''
    })
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value,
      matchId: ''
    })
  }
  handleSelectMatch = (matchId) => {
    this.setState({
      matchId
    }, () => {
      this.props.searchItem(this.state.text, this.state.matchId, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchItem(this.state.text, this.state.matchId, this.state.initialPage)
  }
  loadMore = () => {
    this.setState({
      matchRowCount: this.state.matchRowCount + 6
    })
    this.props.getAllMatches(this.state.initialPage, this.state.matchRowCount);
  }
  handleEdit = (livestream) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      livestream: {
        ...prevState.livestream,
        livestream
      }
    }))
  }
  deleteLiveStream = (livestream) => {

    let match_id = livestream.match_id;
    axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?url=&match_id=${match_id}&page_at=${this.state.initialPage}`).then((response) => {
      let data = response.data.data
      data.map((live_stream) => {
        this.props.deleteLiveStream(live_stream.id)
      })
    })


  }
  createOrUpdate = async (data) => {
    let livestream = {
      match_id: data.match_id,
      live_stream_url: data.live_stream_url,
      resolution: data.resolution,
      channel_no: Number(data.channel_no),
      active: data.active
    }
    if (livestream.match_id === '') {
      this.setState({
        requiredError: "Match is required"
      })
    }
    else if (livestream.live_stream_url === '') {
      this.setState({
        requiredError: "Livestream URL is required"
      })

    }
    // else if (livestream.channel_no === '') {
    //   this.setState({
    //     requiredError: "Channel number is required"
    //   })
    // }

    if (data.newURLs.length > 0) {
      this.setState({
        requiredError: ""
      })
      for (let i = 0; i < data.newURLs.length; i++) {
        let newLivestream = {
          match_id: data.match_id,
          live_stream_url: data.newURLs[i].live_stream_url,
          // check here
          channel_no: i + 1,
          // channel_no: Number(data.channel_no),
          resolution: data.resolution,
          active: data.active
        }
        if (data.newURLs[i]?.headers?.length > 0) {
          let newHeaders = {};
          data.newURLs[i]?.headers && data.newURLs[i]?.headers.forEach((header => {
            newHeaders[header.headerKey] = header.headerValue
          }
          ))
          newLivestream = { ...newLivestream, headers: JSON.stringify(newHeaders) }

        }
        if (newLivestream.match_id === '' || newLivestream.live_stream_url === '') {
          this.setState({
            requiredError: "All fields are required"
          })
        }
        else {
          this.props.createLiveStream(newLivestream).then(() => {
            this.props.getLiveStreams(this.state.initialPage, this.state.rowCount);
          })
          this.setState({
            requiredError: ''
          })
          this.hideModal();
        }
      }

    }
    // }
  }

  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
  hideModal = () => {
    this.setState({
      show: false
    })
    this.props.getLiveStreams(this.state.initialPage, this.state.rowCount);
  }

}
export {
  LiveStreamingListController
}