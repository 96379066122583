import React, { Component } from 'react'
import { Input } from '../../../components/inputs/Input'
import { Button } from '../../../components/buttons/Button'
class NewForm extends Component {
  constructor(props) {
    super(props)

    let headers = [];
    if (this.props.isEdit) {

      let data = (this.props.showForm && this.props.newLiveStream?.headers?.length > 0) && JSON.parse(props.newLiveStream.headers)
      let key = this.props.showForm && Object.keys(data)
      let value = this.props.showForm && Object.values(data)
      for (let i = 0; i < key.length; i++) {
        headers.push({ headerKey: key[i], headerValue: value[i], icon: 'fa fa-trash' });
      }
    }

    this.state = {
      addHeader: false,
      headerKeyIndex: 0,
      newLiveStream: this.props.editNewForm === false ? {
        // new_live_stream_id: Math.round(Math.random()),
        live_stream_url: '',
        channel_no: '',
        headers: []
      } : {
        ...props.newLiveStream,
        headers: this.props.isEdit ? headers : props.newLiveStream.headers,
      }
    }

  }
  handleInputLiveStreamURL = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newLiveStream: {
        ...prevState.newLiveStream,
        live_stream_url: e.target.value,
      }
    }))
  }

  handleInputChange = (index, event) => {
    const values = [...this.state.newLiveStream.headers]
    if (event.target.name === "headerKey") {
      values[index].headerKey = event.target.value;
    } else {
      values[index].headerValue = event.target.value;
    }
    this.setState((prevState) => ({
      ...prevState,
      newLiveStream: {
        ...prevState.newLiveStream,
        headers: values
      }
    }))
  };

  handleAddFields = (e, index) => {

    e.preventDefault();
    let header;
    let initialHeaderKey = ["Origin", "Referer"]
    const values = [...this.state.newLiveStream.headers];
    let newHeader = {};
    let headerKeyIdx = (this.props.isEdit && this.state.newLiveStream?.headers?.length > 0) ? (this.state.newLiveStream?.headers?.length) : ((this.props.editNewForm && this.state.newLiveStream?.headers?.length > 0) ? (this.state.newLiveStream?.headers?.length) : this.state.headerKeyIndex)

    newHeader = {
      headerKey: initialHeaderKey[headerKeyIdx],
      headerValue: this.state.newLiveStream.headers.headerValue,
      icon: 'fa fa-trash'
    }
    if (values.length === 0) {
      values.push(newHeader)
      this.setState((prevState) => ({
        ...prevState,
        newLiveStream: {
          ...prevState.newLiveStream,
          headers: values
        },
        headerKeyIndex: this.state.headerKeyIndex + 1,
      }))
    } else {
      if (values[index].headerKey !== undefined && values[index].headerValue !== undefined && values[index].headerKey !== "" && values[index].headerValue !== "") {
        header = this.state.newLiveStream.headers[index];
        header.icon = "fa fa-trash"
        values[index] = header
        values.push(newHeader)
        this.setState((prevState) => ({
          ...prevState,
          newLiveStream: {
            ...prevState.newLiveStream,
            headers: values
          },
          headerKeyIndex: this.state.headerKeyIndex + 1,
        }))
      }
    }

  };
  handleRemoveFields = (e, index) => {
    e.preventDefault();
    const newValue = [...this.state.newLiveStream.headers]
    newValue.splice(index, 1);
    this.setState((prevState) => ({
      ...prevState,
      newLiveStream: {
        ...prevState.newLiveStream,
        headers: newValue,
      }
    }))
  };
  addHeader = () => {
    this.setState({
      addHeader: true,
    })
  }
  render() {
    return (
      <div className="modal-wrapper add-new-url" >

        <div style={{ width: '70%', margin: 'auto' }}>
          <div className="modalHeader" style={{ backgroundColor: 'gray', maxHeight: '10px', paddingTop: '10px' }}>
            <span></span>
            <span className="close-modal-btn" onClick={() => this.props.toggleForm(false)}>x</span>
          </div>
          <div className="modalContent">
            <div className="modalBody">
              <div style={{ display: 'flex' }}>
                <Input type="text" name="live_stream_url" placeholder="Enter LiveStreaming URL..." value={this.state.newLiveStream?.live_stream_url} onChange={this.handleInputLiveStreamURL} required />
                <button type="button" id="addHeader" className="addHeader" caption="Add Header" onClick={(e) => this.handleAddFields(e, 0)} style={{ marginLeft: '20px' }}>Add Header</button>
              </div>


              {
                // this.props.isEdit &&
                this.state.newLiveStream?.headers?.map((data, index) => {
                  return (
                    <div key={index} className="header_highlight">

                      <Input type="text" name="headerKey" id="headerKey" placeholder="Header Key" value={data.headerKey || ""} onChange={event => this.handleInputChange(index, event)} className="header_highlight_key" />

                      <Input type="text" name="headerValue" id="headerValue" placeholder="Header Value" value={data.headerValue || ""} onChange={event => this.handleInputChange(index, event)} className="header_highlight_value" />
                      <button className="header_highlight_btn"
                        style={{
                          backgroundColor: data.icon === 'fa fa-trash' ? '#ffffff' : '#202020',

                        }}
                        onClick={(e) => {
                          data.icon === 'fa fa-trash' ?
                            this.handleRemoveFields(e, index) :
                            this.handleAddFields(e, index)
                        }
                        } >
                        <i className={data.icon} style={{ color: data.icon === 'fa fa-trash' ? '#ff0000' : '#fad230' }}></i>
                      </button>
                    </div>
                  )

                })
              }

              <br />

              {
                this.props.isEdit ?
                  <Button
                    type="button"
                    id="submit-btn"
                    className="btn-secondary"
                    caption={this.props.editNewForm ? "Update" : "Add New URL"}
                    // caption="Update"
                    btnFun={() => !this.props.editNewForm ? this.props.addNewLivestream(this.state.newLiveStream) : this.props.updateLiveStream(this.state.newLiveStream)}
                  />
                  :
                  <Button
                    type="button"
                    id="submit-btn"
                    className="btn-secondary"
                    caption="Save"
                    btnFun={() => this.props.editNewForm ? this.props.updateNewFields(this.state.newLiveStream) : this.props.saveNewFields(this.state.newLiveStream)}
                  />
              }
            </div>
          </div >
        </div>
      </div >
    )
  }
}

export default NewForm