import { Component } from 'react';
class OperatorCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            operator: props.isEdit === false ? {
                full_name: '',
                user_name: '',
                email: '',
                phone_no: '',
                password: '',
                role_id: '',
                active: 1,
            } : props.operator.operator
        }
    }
    fullnameProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                full_name: e.target.value
            }
        }))
    }
    usernameProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                user_name: e.target.value
            }
        }))
    }
    emailProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                email: e.target.value
            }
        }))
    }
    passwordProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                password: e.target.value
            }
        }))
    }
    phoneProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                phone_no: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                active: e.target.value
            }
        }))
    }
    handleSelectRole = (role_id) => {
        this.setState((prevState) => ({
            ...prevState,
            operator: {
                ...prevState.operator,
                role_id: role_id
            }
        }))
    }
}

export {
    OperatorCreateController
}