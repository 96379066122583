
import { Component } from 'react';
import { axios, moment, CONSTANTS, Swal } from '../../../components/ImportsFile'

class MatchListingController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            match: {},
            time: '',
            leagueId: '',
            teamId: '',
            startDate: '',
            endDate: '',
            date: '',
            rowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            leagueRowCount: CONSTANTS.ROW_COUNT,
            stadiumRowCount: CONSTANTS.ROW_COUNT,
            requiredError: '',
            includesData: [],
        }
    }

    componentDidMount = () => {
        this.loadMore2();
        this.loadMore1();
        this.loadMore3();
        this.props.getMatches(this.state.initialPage, this.state.rowCount);
    }

    handleStartDateChange = (e) => {
        this.setState({
            startDate: e.target.value,
        })
    }
    handleEndDateChange = (e) => {
        this.setState({
            endDate: e.target.value,
            // teamId: '',
        })
    }
    handleInputTime = (e) => {
        this.setState({
            time: e.target.value,
            // teamId: ''
        })
    }
    handleSelectLeagueId = (leagueId) => {
        this.setState({
            leagueId,
            // teamId: ''
        }, () => {
            this.props.searchItem(this.state.time, this.state.leagueId, this.state.teamId, this.state.startDate, this.state.endDate, this.state.initialPage)
        })
    }
    handleSelectTeamId = (teamId) => {
        this.setState({
            teamId,
            // startDate: '',
            // endDate: '',
            // leagueId: '',
            // time: ''
        }, () => {
            this.props.searchItem(this.state.time, this.state.leagueId, this.state.teamId, this.state.startDate, this.state.endDate, this.state.initialPage)
        })
    }
    stopSearching = () => {
        this.props.getMatches(this.state.initialPage, this.state.rowCount);
        this.setState({
            teamId: '',
            startDate: '',
            endDate: '',
            leagueId: '',
            time: ''
        })

    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.time, this.state.leagueId, this.state.teamId, this.state.startDate, this.state.endDate, this.state.initialPage)
    }
    loadMore2 = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount);
    }

    loadMore1 = () => {
        this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
        this.setState({
            leagueRowCount: this.state.leagueRowCount + 6
        })
    }
    loadMore3 = () => {
        this.setState({
            stadiumRowCount: this.state.stadiumRowCount + 6
        })
        this.props.getAllStadiums(this.state.initialPage, this.state.stadiumRowCount);
    }
    handleEdit = (match) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            match: {
                ...prevState.match,
                match
            }
        }))
    }

    handleDelete = (id) => {
        let data = []
        let promise = [
            axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?match_id=${id}`),
            axios.post(`${CONSTANTS.BASE_URL}highlights/list?match_id=${id}`),
            axios.post(`${CONSTANTS.BASE_URL}special_quizzes/list?match_id=${id}`),
            axios.post(`${CONSTANTS.BASE_URL}sponsors/list?match_id=${id}`),
        ];
        Promise.all(promise).then((response) => {
            data = [
                ...response[0]?.data?.data,
                ...response[1]?.data?.data,
                ...response[2]?.data?.data,
                // ...response[3]?.data?.data
            ]
            this.setState({
                includesData: data
            }, () => {
                if (this.state.includesData.length > 0) {
                    Swal.fire({
                        title: "Can't delete this match!",
                        icon: 'warning',
                        // Can't delete this match cause other functions are using it!
                    })
                }
                else {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.props.deleteMatch(id)
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                        }
                    })
                }
            })

        })
    }
    createOrUpdate = async (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let match;

        let date = moment(data.date).format("YYYY-MM-DD")
        let time = moment(data.match_end_time, 'hh:mm A').format('HH:mm')

        let timestamp = new Date(
            date.split("-")[0],
            parseInt(date.split("-")[1]) - 1,
            date.split("-")[2],
            time.split(":")[0],
            time.split(":")[1]
        ).getTime();

        match = {

            home_team_id: data.home_team_id,
            away_team_id: data.away_team_id,
            league_id: data.league_id,
            // stadium_id: data.stadium_id,
            date: data.date,
            match_end_time: timestamp,
            // match_end_time: data.match_end_time,
            // time: data.time,
            // time: moment(data.time, "h:mm A").format("HH:mm"),
            is_playing: data.is_playing,
            active: data.active,
            updated_by: operator.id
        }
        if (data.stadium_id) {
            match = { ...match, stadium_id: data.stadium_id }
        }
        let today = new Date();
        let currentDate = moment(today).format("yyyy, MM, DD")
        let inputDate = moment(match.date).format("yyyy, MM, DD")

        const compareDate = (inputDate, currentDate) => {
            if (inputDate == currentDate) {
                return 0;
            }
            else if (inputDate > currentDate) {
                return true;
            } else {
                return false;
            }
        }
        let isAfterDate = compareDate(inputDate, currentDate)
        let currentTime = moment(today, "h:mm A").format("HH:mm")
        let inputTime = moment(data.time, "h:mm A").format("HH:mm")
        const compareTime = (str1, str2) => {
            if (str1 === str2) {
                return true;
            }
            const time1 = str1.split(':');
            const time2 = str2.split(':');
            if ((time1[0]) > (time2[0])) {
                return true;
            } else if ((time1[0]) == (time2[0]) && (time1[1]) > (time2[1])) {
                return true;
            } else {
                return false;
            }
        }
        let isAfterTime = compareTime(JSON.stringify(inputTime), JSON.stringify(currentTime));

        if (data.remark) {
            match = { ...match, remark: data.remark }
        }
        if (this.state.isEdit) {
            match = { ...match, time: moment(data.time, "h:mm A").format("HH:mm"), id: data.id }
            if (match.home_team_id !== match.away_team_id) {
                if (match.home_team_id === '' || match.away_team_id === '' || match.league_id === '' || match.date === '' || match.time === '' || match.is_playing === '') {
                    this.setState({
                        requiredError: "All fields are required"
                    })
                }
                else if (match.home_team_id === match.away_team_id) {
                    this.setState({
                        requiredError: "Teams should not be the same"
                    })
                }

                // else if (isAfterDate === false) {
                //     this.setState({
                //         requiredError: "Match can't create for previous date "
                //     })
                // }
                // else if (isAfterDate === 0 && isAfterTime === false) {
                //     this.setState({
                //         requiredError: "Match can't create for previous date time"
                //     })
                // }
                else {
                    this.props.updateMatch(match).then(() => {
                        this.props.getMatches(this.props.currentPage, this.state.rowCount)
                    })
                    this.hideModal();
                }
            }

            else {
                this.setState({
                    requiredError: "Teams should not be the same"
                })
            }
        } else {
            match = { ...match, time: data.time, created_by: operator.id, updated_by: operator.id }
            // match = { ...match, created_by: operator.id, updated_by: operator.id }

            if (match.home_team_id === '' || match.away_team_id === '' || match.league_id === '' || match.date === '' || match.time === '' || match.is_playing === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (match.home_team_id === match.away_team_id) {
                this.setState({
                    requiredError: "Teams should not be the same"
                })
            }
            else if (isAfterDate === false) {
                this.setState({
                    requiredError: "Match can't create for previous date "
                })
            }
            else if (isAfterDate === 0 && isAfterTime === false) {
                this.setState({
                    requiredError: "Match can't create for previous date time"
                })
            }
            else {
                this.props.createMatch(match).then(() => {
                    this.props.getMatches(this.state.initialPage, this.state.rowCount)
                })
                this.hideModal();
                this.setState({
                    requiredError: ''
                })
            }
        }
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,
        })
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }

}

export {
    MatchListingController
}