import CONSTANTS from './../constants';
import axios from 'axios'
import PrizeHistoryType from './../type/prize_history_type';
import moment from 'moment'
export const Error = (data) => {
  return {
    type: PrizeHistoryType.PRIZE_HISTORY_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: PrizeHistoryType.PRIZE_HISTORY_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: PrizeHistoryType.LOADING, payload: status })
  }
}
const getPrizeHistories = (pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}prize_histories/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === 'Success') {
        dispatch({
          type: PrizeHistoryType.GET_PRIZE_HISTORIES,
          payload: response.data.data
        })
        dispatch({
          type: PrizeHistoryType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PrizeHistoryType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PrizeHistoryType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: PrizeHistoryType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: PrizeHistoryType.ERROR,
          payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PrizeHistoryType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const searchPrizeHistory = (prizeId, userId, startDate, endDate, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}prize_histories/list?prize_id=${prizeId}&user_id=${userId}&startDate=${startDate}&endDate=${endDate}&page_at=${pageNumber}`).then((response) => {
      if (response.data.status === 'Success') {
        dispatch({
          type: PrizeHistoryType.GET_PRIZE_HISTORIES,
          payload: response.data.data
        })
        dispatch({
          type: PrizeHistoryType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PrizeHistoryType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PrizeHistoryType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: PrizeHistoryType.SEARCH_WITH_PRIZE_ID,
          payload: prizeId
        })
        dispatch({
          type: PrizeHistoryType.SEARCH_WITH_USER_ID,
          payload: userId
        })
        dispatch({
          type: PrizeHistoryType.SEARCH_WITH_START_DATE,
          payload: startDate
        })
        dispatch({
          type: PrizeHistoryType.SEARCH_WITH_END_DATE,
          payload: endDate
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: PrizeHistoryType.ERROR,
          payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PrizeHistoryType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const exportAllPrizeHistory = () => {
  return (dispatch) => {
    let fileName = "prize_history_" + moment(new Date()).format("DD/MM/YY_hh:mm:ss A") + ".xlsx";
    return axios.get(`${CONSTANTS.BASE_URL}prize_histories/exportAllPrizeHistory`, {
      responseType: 'blob',
    }).then((response) => {

      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }
    })
  }
}
const exportPrizeHistoryByPrize = () => {
  return (dispatch) => {
    let fileName = "prize_history_by_prize_" + moment(new Date()).format("DD/MM/YY_hh:mm:ss A") + ".xlsx";
    return axios.get(`${CONSTANTS.BASE_URL}prize_histories/exportPrizeHistoryByPrize`, {
      responseType: 'blob',
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }
    })
  }
}
const exportPrizeHistoryByUser = () => {
  return (dispatch) => {
    let fileName = "prize_history_by_user_" + moment(new Date()).format("DD/MM/YY_hh:mm:ss A") + ".xlsx";
    return axios.get(`${CONSTANTS.BASE_URL}prize_histories/exportPrizeHistoryByUser`, {
      responseType: 'blob',
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }
    })
  }
}
const exportPrizeHistory = (prizeId, userId, startDate, endDate) => {
  console.log("endDate =>", endDate);
  return (dispatch) => {
    let fileName = "prize_history_by_custom_" + moment(new Date()).format("DD/MM/YY_hh:mm:ss A") + ".xlsx";
    return axios.get(`${CONSTANTS.BASE_URL}prize_histories/exportPrizeHistory?prize_id=${prizeId}&user_id=${userId}&startDate=${startDate}&endDate=${endDate}`, {
      responseType: 'blob',
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }
    })
  }
}

const PrizeHistoryAction = {
  getPrizeHistories,
  searchPrizeHistory,
  exportAllPrizeHistory,
  exportPrizeHistoryByPrize,
  exportPrizeHistoryByUser,
  exportPrizeHistory
}
export default PrizeHistoryAction