import React from 'react'
import { MainContainer, Header, Body, Sidebar, Main } from './LiveScoreListElement'
import { Link } from 'react-router-dom';
import LiveScoreListController from './LiveScoreListController';
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import fallBackLeague from '../../../images/icons/fallBackLeague.png'
import fallBackTeam from '../../../images/icons/team.png'
import Moment from 'react-moment';
class LiveScorelist extends LiveScoreListController {
    render() {
        // let leagueLogo = 'https://images.fotmob.com/image_resources/logo/teamlogo/'

        return (
            <MainContainer>
                <Header>
                    <h4>Live Scores</h4>

                    <form onSubmit={(e) => e.preventDefault()} className="score_date">
                        <p>Date: &nbsp;{
                            this.state.date ? <Moment format="ddd D MMM YYYY">{this.state.date}</Moment> : new Date().toDateString()
                        }
                        </p>
                        <input type="date" name="date" onChange={this.dateChange} className="livescore_date" />

                    </form>


                </Header>
                {
                    this.state.loading ? <PageLoader /> :
                        <Body>
                            <Sidebar>
                                <div className="search-form">
                                    <form className="search search-score" onSubmit={this.handleSearch} style={{ marginBottom: '20px' }} >
                                        <div className="input-group">
                                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="text" onChange={this.handleInputText} autoComplete="off" />
                                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                            <button type="submit"><i className="fa fa-search"></i></button>
                                        </div>
                                    </form>
                                </div>
                                <div className="items">
                                    {
                                        this.state.leagues.filter((val) => {
                                            if (this.state.text === '') {
                                                return val;
                                            }
                                            else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                                                return val
                                            }
                                        }).map((league) => {
                                            return <p key={league.id} onClick={() => this.getScore(league.id)}>
                                                <img src={`${this.state.logoUrl}${league.ccode.toLowerCase()}.png`} alt="" onError={(e) => (e.currentTarget.src = fallBackLeague)} />
                                                {league.name.length > 35 ? league.name.substring(0, 35) + '...' : league.name}
                                            </p>
                                        })
                                    }
                                </div>
                            </Sidebar>
                            <Main>
                                <div className="match_item_list">

                                    {
                                        <div className="items">
                                            <h5>{this.state.league}</h5>
                                            {this.state.matches.map((match) => {
                                                return (
                                                    <Link to={`/score_detail/${match.id}`} key={match.id}>
                                                        <div className="item" >
                                                            <div className="full-time">
                                                                <p style={{ backgroundColor: match.status.finished === false && 'green' }}>{match.status.finished ? match.status.reason.short : "NS"}</p>
                                                            </div>

                                                            <div className="left">
                                                                <p>{match.home.name.length > 20 ? match.home.name.substring(0, 20) + '...' : match.home.name}
                                                                    <img src={`${this.state.logoUrl}${match?.home.id}_small.png`} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                                                </p>
                                                            </div>
                                                            <div className="center">

                                                                {
                                                                    match.status.finished !== false && <p> {match.home.score} - {match.away.score}
                                                                    </p>
                                                                }

                                                                {
                                                                    match.status.started === false && <small>{match.status.startTimeStr}</small>
                                                                }

                                                            </div>
                                                            <div className="right">
                                                                <p> <img src={`${this.state.logoUrl}${match?.away.id}_small.png`} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /> {match.away.name.length > 20 ? match.away.name.substring(0, 20) + '...' : match.away.name}</p>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    }

                                </div>
                            </Main>
                        </Body>
                }

            </MainContainer>
        )
    }
}

export default LiveScorelist
