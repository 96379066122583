
import CONSTANTS from '../constants';
import axios from 'axios'
import SpecialQuizType from './../type/special_quiz_type';

export const Error = (data) => {
    return {
        type: SpecialQuizType.SPECIAL_QUIZ_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: SpecialQuizType.SPECIAL_QUIZ_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: SpecialQuizType.LOADING, payload: status });
    }
}
const getSpecialQuizzes = (date, leagueId, quizType, isCurrent, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}special_quizzes/list?date=${date}&leagueId=${leagueId}&quiz_type=${quizType}&is_current=${isCurrent}&page_at=${pageNumber}&row_count=${row_count}&sort_by=created_at&order_by=desc`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: SpecialQuizType.GET_SPECIAL_QUIZZES,
                    payload: response.data.data
                })
                dispatch({
                    type: SpecialQuizType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: SpecialQuizType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: SpecialQuizType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: SpecialQuizType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: SpecialQuizType.SEARCH_WITH_DATE, payload: date
                })
                dispatch({
                    type: SpecialQuizType.SEARCH_WITH_LEAGUE_ID, payload: leagueId
                })
                dispatch({
                    type: SpecialQuizType.SEARCH_WITH_QUIZ_TYPE, payload: quizType
                })
                dispatch({
                    type: SpecialQuizType.SEARCH_WITH_ISCURRENT_DATE, payload: isCurrent
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: SpecialQuizType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: SpecialQuizType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createSpecialQuiz = (quiz) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}special_quizzes/create`, quiz).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: SpecialQuizType.ADD_SPECIAL_QUIZ,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateSpecialQuiz = (quiz) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}special_quizzes/update`, quiz).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: SpecialQuizType.UPDATE_SPECIAL_QUIZ,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const deleteSpecialQuiz = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));

    return (dispatch) => {

        return axios.delete(`${CONSTANTS.BASE_URL}special_quizzes/delete`, {
            data: { id: id, deleted_by: operator.id }
        }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: SpecialQuizType.DELETE_SPECIAL_QUIZ,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: SpecialQuizType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: SpecialQuizType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchSpecialQuiz = (date, leagueIdFromFotmob, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}special_quizzes/list?dateForQuiz=${date}&leagueId=${leagueIdFromFotmob}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: SpecialQuizType.GET_SPECIAL_QUIZZES, payload: response.data.data
                })
                dispatch({
                    type: SpecialQuizType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: SpecialQuizType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: SpecialQuizType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: SpecialQuizType.SEARCH_WITH_LEAGUE_ID_FROM_FOTMOB,
                    payload: leagueIdFromFotmob
                })
                dispatch({
                    type: SpecialQuizType.GET_WITH_DATE, payload: date
                })
            }

        })
    }
}

const SpecialQuizAction = {
    getSpecialQuizzes,
    createSpecialQuiz,
    updateSpecialQuiz,
    deleteSpecialQuiz,
    searchSpecialQuiz,
    loading
}
export default SpecialQuizAction