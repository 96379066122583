
import { Component } from 'react';
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';
import moment from 'moment'
class MatchCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            today: new Date(),
            stadium_name: '',
            teamName: '',
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            teamLeagueRowCount: CONSTANTS.ROW_COUNT,
            teams: [],
            leagues: props.team_leagues,
            match: props.isEdit === false ? {
                home_team_id: '',
                away_team_id: '',
                league_id: '',
                stadium_id: '',
                match_end_time: '',
                date: '',
                time: '',
                remark: '',
                is_playing: 0,
                active: 1,

            } : {
                ...props.match.match,
                match_end_time: moment(this.props.match.match.match_end_time).format("h:mm A")
            }
        }


    }

    leagueProcess = (league_id) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                league_id: league_id,
            },
        }), () => {
            this.loadMore();
        })


    }
    // getTeams = () => {
    //     axios.post(`${CONSTANTS.BASE_URL}team-leagues/list?league_id=${this.state.match.league_id}&page_at=${this.state.initialPage}&row_count=${1000}`).then((response) => {
    //         let teamList = response.data && response.data.data.map((team => team.Team))
    //         this.setState({
    //             teams: teamList
    //         })
    //     })
    // }
    homeTeamIdProcess = (home_team_id) => {
        axios.post(`${CONSTANTS.BASE_URL}teams/detail`, { id: home_team_id }).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                match: {
                    ...prevState.match,
                    stadium_id: response.data?.data?.Stadium?.id,
                },
                stadium_name: response.data?.data?.Stadium?.name,
            }))
        })
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                home_team_id: home_team_id,
            },
            // teams: this.state.teams.filter(team => team.id !== home_team_id)
        }))
        this.loadMore();
    }
    awayTeamIdProcess = (away_team_id) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                away_team_id: away_team_id
            },
            // teams: this.state.teams.filter(team => team.id !== away_team_id)
        }))
    }
    handleDateChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                date: e.target.value
            }
        }))
    }
    handleTimeChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                time: e.target.value
            }
        }), () => {
            let timestamp = new Date(
                this.state.match.date.split("-")[0],
                parseInt(this.state.match.date.split("-")[1]) - 1,
                this.state.match.date.split("-")[2],
                this.state.match.time.split(":")[0],
                this.state.match.time.split(":")[1]
            ).getTime();

            let twoHour = 2 * 60 * 60 * 1000
            let addTime = timestamp + twoHour
            let endTimestamp = new Date(addTime).getTime();
            let endTime = moment(endTimestamp).format("h:mm A")

            this.setState((prevState) => ({
                ...prevState,
                match: {
                    ...prevState.match,
                    match_end_time: endTime
                }
            }))
        })
    }
    handleEndTimeChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                match_end_time: e.target.value
            }
        }))
    }
    handleInputRemark = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                remark: e.target.value
            }
        }))
    }
    handlePlayItem = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                is_playing: e.target.value
            }
        }))
    }
    StadiumProcess = (stadium_id) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                stadium_id: stadium_id
            }
        }))
    }
    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            match: {
                ...prevState.match,
                active: e.target.value
            }
        }))
    }
    loadMore = () => {
        this.setState({
            teamLeagueRowCount: this.state.teamLeagueRowCount + 6
        })
        // this.props.searchWithLeague(this.state.match.league_id, this.state.teamName, this.state.teamLeagueRowCount, this.state.initialPage)
        this.props.searchWithLeagueAndTeamName(this.state.match.league_id, this.state.teamName, this.state.teamLeagueRowCount, this.state.initialPage)
    }
}

export {
    MatchCreateController
}
