import { Component } from 'react'
import CONSTANTS from './../../redux/constants';
// const socket = new WebSocket("wss://notification.zotefamily.com")

export class NotificationListController extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            checked: true,
            inititalPage: 1,
            rowCount: CONSTANTS.ROW_COUNT
        }
        this.handleSwitch = this.handleSwitch.bind(this)
    }


    handleSwitch(checked) {
        this.setState({ checked })
    }
    componentDidMount = () => {
        this.props.getTransactions(this.state.inititalPage, this.state.rowCount)
    }
}

export default NotificationListController
