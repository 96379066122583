import { Component } from 'react'
import CONSTANTS from './../../../../redux/constants';

class QuizDetailFutureCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {

      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      questionType: 1,
      future_quiz_details: this.props.isEdit === false ? {
        question_id: '',
        type: '',
        active: 1
      } : props.future_quiz_details.future_quiz_details
    }

  }
  componentDidMount = () => {
    // this.loadMore();
    this.props.getQuestions(this.props.quizType, this.state.initialPage, this.state.rowCount)
  }


  handleSelectQuestion = (questionId) => {
    this.setState((prevState) => ({
      ...prevState,
      future_quiz_details: {
        ...prevState.future_quiz_details,
        question_id: questionId,
      }
    }))
  }

  handleSelectType = (typeId) => {
    this.setState((prevState) => ({
      ...prevState,
      future_quiz_details: {
        ...prevState.future_quiz_details,
        type: typeId
      }
    }))
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      future_quiz_details: {
        ...prevState.future_quiz_details,
        active: e.target.value
      }
    }))
  }


}

export default QuizDetailFutureCreateController
