const UserTeamActivityType = {
    GET_USER_TEAM_ACTIVITIES: "GET_USER_TEAM_ACTIVITIES",
    ADD_USER_TEAM_ACTIVITY: "ADD_USER_TEAM_ACTIVITY",
    UPDATE_USER_TEAM_ACTIVITY: "UPDATE_USER_TEAM_ACTIVITY",
    DELETE_USER_TEAM_ACTIVITY: "DELETE_USER_TEAM_ACTIVITY",
    USER_TEAM_ACTIVITY_ERROR: "USER_TEAM_ACTIVITY_ERROR",
    USER_TEAM_ACTIVITY_SUCCESS: "USER_TEAM_ACTIVITY_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    SEARCH_WITH_TEAM:"SEARCH_WITH_TEAM",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default UserTeamActivityType;