import React from 'react'
import AnnouncementCreateController from './AnnouncementCreateController';
import { Button, Input, FontAwesomeIcon, faCloudUploadAlt } from '../../../components/ImportsFile'
class AnnouncementCreate extends AnnouncementCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Announcement' : 'Add Announcement'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ columnCount: '2' }}>
                <Input type="text" placeholder="Enter Announcement Title" name="title" value={this.state.announcement.title} onChange={this.AnnouncementTitle} />

                <Input type="text" placeholder="Enter Announcement Description" name="descrption" value={this.state.announcement.description} onChange={this.AnnouncementDescription} />

                <select name="go_to_screen" className="select" value={this.state.announcement.go_to_screen} onChange={this.GoToScreen} required >
                  <option value="0">Home</option>
                  <option value="1">Live Score</option>
                  <option value="2">Quiz</option>
                  <option value="3">Special Quiz</option>
                  <option value="4">My Quiz</option>
                  <option value="5">Profile</option>
                  <option value="6">Leaderboard</option>
                </select>

                <Input type="date" placeholder="Enter StartDate (optional)" name="start_date" value={this.state.announcement.start_date} onChange={this.StartDate} />

                <Input type="date" placeholder="Enter End Date (optional)" name="end_date" value={this.state.announcement.end_date} onChange={this.EndDate} />

                <Input type="text" placeholder="Enter Button Text" name="button_text" value={this.state.announcement.button_text} onChange={this.ButtonText} />

                <Input type="number" placeholder="Enter Destination" name="destination" value={this.state.announcement.destination} onChange={this.Destination} style={{ marginTop: (this.state.announcement.image_url && !this.state.announcement.button_icon) && '12px' }} />


                <Input type="text" placeholder="Enter Destination Team Id (optional)" name="destination_team_id" value={this.state.announcement.destination_team_id || ''} onChange={this.DestinationTeamId} />


                <select name="active" className="select" value={this.state.announcement.active} onChange={this.handleSelect} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

                <div className="image-upload">
                  <label htmlFor="image_url">
                    <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#fad230" /> Choose Image For Announcement &nbsp;&nbsp;&nbsp;
                  </label>
                  <input type="file" id="image_url" name="image_url" onChange={this.ImageURL} style={{ display: 'none' }} />
                  {
                    (typeof this.state.announcement.image_url === "string" && this.props.isEdit) && <img className="uploadedImage" onClick={this.zoomToggle} src={this.state.announcement.image_url} alt="" style={{ width: '110px' }} />
                  }
                  {
                    (typeof this.state.announcement.image_url !== "string") && <img className="uploadedImage" onClick={this.zoomToggle} src={URL.createObjectURL(this.state.announcement.image_url)} alt="" style={{ width: '110px', maxHeight: '110px' }} />
                  }
                </div>

                <div className="image-upload">
                  <label htmlFor="button_icon">
                    <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#fad230" /> Choose Button Icon
                  </label>
                  <input type="file" id="button_icon" name="button_icon" onChange={this.ButtonIcon} style={{ display: 'none' }} />
                  {
                    (typeof this.state.announcement.button_icon === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.announcement.button_icon} alt="" style={{ width: '20px' }} />
                  }
                  {
                    (typeof this.state.announcement.button_icon !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.announcement.button_icon)} alt="" style={{ width: '20px' }} />
                  }
                </div>


                {
                  this.state.zoom &&
                  <div className="zoomModal">
                    <img className="zoomImg"
                      src={typeof this.state.announcement.image_url == "string" ? this.state.announcement.image_url : URL.createObjectURL(this.state.announcement.image_url)}
                      alt="" onClick={this.zoomToggle} />
                  </div>
                }
              </div>
              <br />
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.announcement)} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default AnnouncementCreate
