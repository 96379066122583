import { Component } from 'react';
import Swal from 'sweetalert2';
import CONSTANTS from './../../../redux/constants';
class UserListingController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            loading: false,
            text: '',
            requiredError: '',
            user: {},
            active: 1,
            rowCount: CONSTANTS.ROW_COUNT,
        }
    }
    componentDidMount = () => {
        this.props.getTownships(this.state.initialPage, this.state.rowCount)
        // this.props.getUsers(this.state.initialPage, this.state.rowCount)
        this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
    }
    stopSearching = () => {
        this.setState({
            text: '',
            active: 1,
        }, () => {
            this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleActive = (e) => {
        this.setState({
            active: e.target.value
        }, () => {
            this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault()
        this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
    }
    // change active to inactive for deleted user
    changeStatus = (user) => {
        let active = user.active === 0 ? 1 : 0;

        let data = {
            id: user.id,
            active: active
        }
        Swal.fire({
            title: 'Are you sure?',
            // text: "You want to change status.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.updateUser(data).then(() => {
                    this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
                })
            }
        })

        // let active = user.active === 0 ? 1 : 0;

        // let data = {
        //     id: user.id,
        //     active: active
        // }
        // this.props.updateUser(data).then(() => {
        //     this.props.searchItem(this.state.text, this.state.active, this.state.initialPage)
        // })
    }

    handleEdit = async (user) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            user: {
                ...prevState.user,
                user
            }
        }))
    }
    // UserDetail = (id) => {
    //     <Redirect to="/user_detail" />
    // }
    createOrUpdate = (data) => {
        const operator = JSON.parse(localStorage.getItem('operator'))
        let user;
        if (this.state.isEdit) {
            user = {
                id: data.id,
                township_id: data.township_id,
                full_name: data.full_name,
                user_name: data.user_name,
                email: data.email,
                phone_no: data.phone_no,
                password: data.password,
                address: data.address,
                image_url: data.image_url,
                register_date: data.register_date,
                watch_count: data.watch_count,
                active: data.active,
                updated_by: operator.id
            }
            let formData = new FormData();
            formData.append("id", user.id);
            formData.append("township_id", user.township_id);
            formData.append("full_name", user.full_name);
            formData.append("user_name", user.user_name);
            formData.append("email", user.email);
            formData.append("phone_no", user.phone_no);
            formData.append("password", user.password);
            formData.append("address", user.address);
            formData.append("image_url", user.image_url);
            formData.append("register_date", user.register_date);
            formData.append("watch_count", user.watch_count);
            formData.append("active", user.active);
            if (user.user_name === '' || user.phone_no === '' || user.password === '' || user.register_date === '' || user.township_id) {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {

                this.props.updateUser(formData)
                this.hideModal();
            }

        }
        else {
            user = { ...data, created_by: operator.id, updated_by: operator.id }
            let formData = new FormData();
            formData.append("township_id", user.township_id);
            formData.append("full_name", user.full_name);
            formData.append("user_name", user.user_name);
            formData.append("email", user.email);
            formData.append("phone_no", user.phone_no);
            formData.append("password", user.password);
            formData.append("address", user.address);
            formData.append("image_url", user.image_url);
            formData.append("register_date", user.register_date);
            formData.append("watch_count", user.watch_count);
            formData.append("active", user.active);
            if (user.user_name === '' || user.phone_no === '' || user.password === '' || user.register_date === '' || user.township_id) {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createUser(formData)
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
}
export {
    UserListingController
}