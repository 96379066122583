import { Component } from 'react';
class UserCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            townships: [],
            user: props.isEdit === false ? {
                township_id: 1,
                full_name: '',
                user_name: '',
                email: '',
                phone_no: '',
                address: '',
                password: '',
                register_date: '',
                watch_count: '',
                image_url: '',
                active: 1,
            } : props.user.user
        }
    }
    componentDidMount() {
        this.getTownships()
    }

    handleTownshipChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                township_id: e.target.value
            }
        }))
    }
    handleInputFullname = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                full_name: e.target.value
            }
        }))
    }
    handleInputuser_name = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                user_name: e.target.value
            }
        }))
    }
    handleInputEmail = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                email: e.target.value
            }
        }))
    }
    handleInputPhone = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                phone_no: e.target.value
            }
        }))
    }
    handleInputAddress = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                address: e.target.value
            }
        }))
    }
    handleInputPassword = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                password: e.target.value
            }
        }))
    }
    handleInputRegisterDate = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                register_date: e.target.value
            }
        }))
    }
    handleInputWatchCount = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                watch_count: e.target.value
            }
        }))
    }
    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                active: e.target.value
            }
        }))
    }

    onImageChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                image_url: e.target.files[0]
            }
        }))
    }
}
export {
    UserCreateController
}