import { Component } from 'react'
import { data } from './../../../redux/moduleList'
class PermissionCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectOption: "",
            permission: props.isEdit === false ? {
                name: '',
                description: '',
                active: 1,
                data: [],
            } : {
                ...props.permission.permission,
                data: props.permission.permission?.PermissionDetails
            }
        }
    }
    
    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                name: e.target.value,
            }
        }))
    }
    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                description: e.target.value,
            }
        }))
    }
    selectAll = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: data
            },
            selectOption: "selectAll"

        }))
    }
    unselectAll = (e) => {

        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: []
            },
            selectOption: "unselectAll",
        }))
    }
    listChackbox = (e) => {

        let data = e.target.value;
        let newArr = data.split(",")
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: e.target.checked ? [
                    ...this.state.permission?.data,
                    {
                        feature: newArr[0],
                        functions: newArr[1]
                    }
                ] : Object.values(this.state.permission?.data).filter(obj => { return (!(obj.feature == newArr[0] && obj.functions === "list")) })
            },
            selectOption: ''
        }))
    }

    createChackbox = (e) => {
       
        let data = e.target.value;
        let newArr = data.split(",")
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: e.target.checked ? [
                    ...this.state.permission?.data,
                    {
                        feature: newArr[0],
                        functions: newArr[1]
                    }
                ] : Object.values(this.state.permission?.data).filter(obj => { return (!(obj.feature == newArr[0] && obj.functions === "create")) })
            },
            selectOption: ''
        }))
    }
    updateChackbox = (e) => {
        let data = e.target.value;
        let newArr = data.split(",")
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: e.target.checked ? [
                    ...this.state.permission?.data,
                    {
                        feature: newArr[0],
                        functions: newArr[1]
                    }
                ] : Object.values(this.state.permission?.data).filter(obj => { return (!(obj.feature == newArr[0] && obj.functions === "update")) })
            },
            selectOption: ''
        }))
    }
    deleteChackbox = (e) => {
        let data = e.target.value;
        let newArr = data.split(",")
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                data: e.target.checked ? [
                    ...this.state.permission?.data,
                    {
                        feature: newArr[0],
                        functions: newArr[1]
                    }
                ] : Object.values(this.state.permission?.data).filter(obj => { return (!(obj.feature == newArr[0] && obj.functions === "delete")) })
            },
            selectOption: ''
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            permission: {
                ...prevState.permission,
                active: e.target.value
            }
        }))
    }

}
export {
    PermissionCreateController
}
