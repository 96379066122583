
import PrizeType from './../type/prize_type';

const initialState = {
  prizes: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  totalPage: 1,
  text: null,
  leagueId: null,
  expired_time: null,
  row_count: 1,
  loading: false,
}
const prizeReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case PrizeType.GET_PRIZES:
      return {
        ...state,
        loading: false,
        prizes: actions.payload,
      }
    case PrizeType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case PrizeType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case PrizeType.TOTAL_PAGE:
      return {
        ...state,
        totalPage: actions.payload
      }
    case PrizeType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case PrizeType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case PrizeType.ADD_PRIZE:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        // prizes: state.prizes.push(actions.payload)
        prizes: [
          actions.payload,
          ...state.prizes,
        ],

      }
    case PrizeType.UPDATE_PRIZE:
      const updatePrize = state.prizes.filter(prize => prize.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        prizes: [
          actions.payload,
          ...updatePrize,
        ]
      }
    case PrizeType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case PrizeType.DELETE_PRIZE:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        prizes: state.prizes.filter(prize => prize.id !== actions.payload)
      }
    case PrizeType.SEARCH_ITEM:
      return {
        ...state,
        text: actions.payload
      }
    case PrizeType.SEARCH_WITH_LEAGUE_ID:
      return {
        ...state,
        leagueId: actions.payload
      }
    case PrizeType.SEARCH_WITH_EXPIRED_TIME:
      return {
        ...state,
        expired_time: actions.payload
      }
    case PrizeType.PRIZE_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case PrizeType.PRIZE_SUCCESS:
      return { ...state, success: actions.payload, loading: false }
    default: return state
  }
}
export default prizeReducer