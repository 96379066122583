import React, { Component } from 'react';
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

class AutoSelectingPages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            searchTerm: '',
            selectedValue: props.itemName,
            initialPage: 1,
        }
        this.handleSelect = this.handleSelect.bind(this)
    }
    handleSelect = (id, name) => {
        this.props.handleProcess(id);
        this.setState(state => ({
            ...state,
            selectedValue: name,
            show: false,
        }))
    }
    handleInputSearch = (e) => {
        this.setState({
            searchTerm: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searching && this.props.searching(this.state.searchTerm, this.props.currentPage)

    }
    stopSearching = () => {
        this.props.getItems && this.props.getItems(this.state.initialPage, this.props.rowCount)

        this.setState({
            searchTerm: ''
        })
    }
    ShowModal = () => {
        this.setState({
            show: true
        })
    }
    render() {
        return (
            <div>

                <div className="selectbox" name="selectedValue" onChange={this.handleSelect} onClick={this.ShowModal}>
                    {
                        (this.state.selectedValue && !this.props.selectedId) && this.state.selectedValue
                    }
                    {
                        (this.props.selectedId && !this.state.selectedValue) && (this.props.selectedName)
                    }
                    {
                        (this.props.selectedId && this.state.selectedValue) && (this.props.selectedName || this.state.selectedValue)
                    }
                    <span>  <FontAwesomeIcon icon={faAngleDown} /> </span>
                </div>
                {
                    (this.state.show && !this.props.disabled) && <Modal className="createSelectModal"
                        ariaHideApp={false}
                        isOpen={this.state.show}
                        onRequestClose={() => this.setState({ show: false })}
                        shouldCloseOnOverlayClick={true}
                        style={
                            {
                                overlay: {
                                    background: 'transparent'
                                }
                            }
                        }
                    >
                        <p className="close" onClick={() => this.setState({ show: false })}>x</p>


                        <div className="item-lists" >
                            <div style={{ width: "100%" }}>
                                <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                    <div className="input-group">
                                        <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                                        <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                        <button type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </form>
                                <hr className="selectModalLineBreak" />
                            </div>

                            {
                                this.props.items.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.name)
                                        }} >{item.name}</p>
                                    )
                                })
                            }

                            {
                                this.props.items.length > 5 && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

export default AutoSelectingPages
