import axios from 'axios'
import CONSTANTS from './../constants';
import TeamType from './../type/team_type';

export const Error = (data) => {
    return {
        type: TeamType.TEAM_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: TeamType.TEAM_SUCCESS, payload: data
    }
}
const loadingTeam = (status) => {
    return (dispatch) => {
        return dispatch({ type: TeamType.LOADING, payload: status });
    }
}
const getTeams = (pageNumber, row_count) => {

    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}teams/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.GET_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: TeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: TeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: TeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: TeamType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: TeamType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TeamType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllTeams = (pageNumber, row_count) => {

    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}teams/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.GET_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: TeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: TeamType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: TeamType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TeamType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createTeam = (team) => {
    return (dispatch) => {
        dispatch(loadingTeam(true))
        return axios.post(`${CONSTANTS.BASE_URL}teams/create`, team).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.ADD_TEAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const updateTeam = (team) => {
    return (dispatch) => {
        dispatch(loadingTeam(true))
        return axios.put(`${CONSTANTS.BASE_URL}teams/update`, team).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.UPDATE_TEAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteTeam = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}teams/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.DELETE_TEAM, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: TeamType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TeamType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchTeam = (text, countryId, pageNumber) => {
    return (dispatch) => {

        return axios.post(`${CONSTANTS.BASE_URL}teams/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.GET_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: TeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: TeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: TeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: TeamType.SEARCH_WITH_COUNTRYID,
                    payload: countryId
                })
                dispatch({
                    type: TeamType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}

const searchItem = (text, countryId, pageNumber) => {
    return (dispatch) => {

        return axios.post(`${CONSTANTS.BASE_URL}teams/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TeamType.GET_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: TeamType.SEARCH_WITH_COUNTRYID,
                    payload: countryId
                })
                dispatch({
                    type: TeamType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}


const TeamAction = {
    getTeams,
    getAllTeams,
    createTeam,
    deleteTeam,
    updateTeam,
    loadingTeam,
    searchTeam,
    searchItem
}
export default TeamAction