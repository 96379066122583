const AnnouncementType = {
  GET_ANNOUNCEMENTS: "GET_ANNOUNCEMENTS",
  ADD_ANNOUNCEMENT: "ADD_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
  ANNOUNCEMENT_ERROR: "ANNOUNCEMENT_ERROR",
  ANNOUNCEMENT_SUCCESS: "ANNOUNCEMENT_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  TOTAL_PAGE: "TOTAL_PAGE",
  GET_PER_PAGE: "GET_PER_PAGE",
  SEARCH_ITEM: "SEARCH_ITEM",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}

export default AnnouncementType;