import React from 'react'
import { CountryCreateController } from './CountryCreateController'

import { Button, Input, FontAwesomeIcon, faCloudUploadAlt } from '../../../components/ImportsFile'

export class CountryCreate extends CountryCreateController {

    render(props) {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Country' : 'Add Country'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">

                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <div>
                                <Input type="text" placeholder="Enter Country Name" name="name" value={this.state.country.name} onChange={this.handleInputChange} required />

                                <Input type="text" placeholder="Enter Country Dial Code (Optional)" name="country_code" value={this.state.country.country_code || ''} onChange={this.handleInputCountyCode} />

                                <select name="active" className="select" value={this.state.country.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                            </div>

                            <div className="image-upload">
                                <label htmlFor="file">
                                    <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#fad230" /> Choose Image
                                </label>
                                <input type="file" id="file" name="flag" onChange={this.onChangeImage} style={{ display: 'none' }} />
                                {
                                    (typeof this.state.country.flag === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.country.flag} alt="" style={{ width: '110px', height: "70px" }} />
                                }
                                {
                                    (typeof this.state.country.flag !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.country.flag)} alt="" style={{ width: '110px', height: "70px" }} />
                                }
                            </div>
                            <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.country)} />

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default CountryCreate
