import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class UserFavouriteTeamListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            user_favourite_team: {},
            userRowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            userId: '',
            teamId: '',
            requiredError: '',
            rowCount: CONSTANTS.ROW_COUNT,

        }
    }

    componentDidMount = () => {
        this.loadMore1();
        this.loadMore2();
        this.props.getUserFavouriteTeams(this.state.initialPage, this.state.rowCount);
    }

    stopSearching = () => {
        this.setState({
            userId: '',
            teamId: ''
        })
        this.props.getUserFavouriteTeams(this.state.initialPage, this.state.rowCount)
    }
    handleSelectUserId = (userId) => {
        this.setState({
            userId,
            teamId: ''
        })
    }
    handleSelectTeamId = (teamId) => {
        this.setState({
            teamId,
            userId: ''
        }, () => {
            this.props.searchWithUserOrTeam(this.state.userId, this.state.teamId, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault()
        this.props.searchWithUserOrTeam(this.state.userId, this.state.teamId, this.state.initialPage)
    }
    loadMore1 = () => {
        this.setState({
            userRowCount: this.state.userRowCount + 6
        })
        this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
    }
    loadMore2 = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount);
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    handleEdit = async (user_favourite_team) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            user_favourite_team: {
                ...prevState.user_favourite_team,
                user_favourite_team
            }
        }))
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    createOrUpdate = (data) => {
        let user_favourite_team = {
            user_id: data.user_id,
            team_id: data.team_id,
            active: 1,
        }

        if (this.state.isEdit) {
            user_favourite_team = { ...user_favourite_team, id: data.id }
            if (user_favourite_team.user_id === '' || user_favourite_team.team_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateUserFavouriteTeam(user_favourite_team);
                this.hideModal();
            }
        }
        else {
            user_favourite_team = { ...user_favourite_team }
            if (user_favourite_team.user_id === '' || user_favourite_team.team_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createUserFavouriteTeam(user_favourite_team)
                this.hideModal();
            }
        }

    }

}

export default UserFavouriteTeamListController