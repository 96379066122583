import { MatchListingController } from './MatchListingController'
import MatchCreate from '../create/MatchCreate'
import MatchAction from './../../../redux/action/match_action';
import TeamAction from './../../../redux/action/team_action';
import StadiumAction from './../../../redux/action/stadium_action';
import Moment from 'react-moment';
import LeagueAction from './../../../redux/action/league_action';
import TeamLeagueAction from './../../../redux/action/team_league_action';
import { Alert, PageLoader, Search, Modal, Pagination, Button, connect, MainContainer, Header, Body } from '../../../components/ImportsFile'
class MatchListing extends MatchListingController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Match' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Match' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Match' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <form className="search match-search-form" autoComplete="off" >
                    <div className="input-group p-0">
                        <input type="text" className="input-field" placeholder="HH:MM" name="search" value={this.state.time} onChange={this.handleInputTime} disabled />

                        {/**
                        <span className="input-group-text p-0 m-0" onClick={() => this.stopSearching()}>&times;</span>
                    */}

                        <input type="time" placeholder="Search.." name="search" value={this.state.time} onChange={this.handleInputTime} />

                        <Search
                            items={this.props.leagues}
                            searchItem={(leagueId) => this.handleSelectLeagueId(leagueId)}
                            text={'Search with League'}
                            search={this.state.leagueId ? true : false}
                            LoadMore={this.loadMore1}
                            getItems={this.props.getAllLeagues}
                            rowCount={this.props.leagueRowCount}
                            searchLeague={this.props.searchLeague}
                            currentPage={this.props.leagueCurrentPage}
                            stopSearching={() => this.props.getMatches(this.state.initialPage, this.state.rowCount)}
                        />

                        <Search
                            items={this.props.teamlists}
                            searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                            text={'Search with Team'}
                            search={this.state.teamId ? true : false}
                            LoadMore={this.loadMore2}
                            getItems={this.props.getAllTeams}
                            rowCount={this.props.teamRowCount}
                            searchTeam={this.props.searchTeam}
                            currentPage={this.props.teamCurrentPage}
                            stopSearching={() => this.props.getMatches(this.state.initialPage, this.state.rowCount)}
                        />

                        <input type="date" name="startDate" value={this.state.startDate} onChange={this.handleStartDateChange} />
                        <input type="date" name="endDate" value={this.state.endDate} placeholder="Search.." onChange={this.handleEndDateChange} />
                        <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                        <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                    </div>
                </form>

                <br />
                <Header style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4>Matches</h4>
                    {
                        createPermission &&
                        <Button caption="Add Match" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>League</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Home Team</th>
                                <th>Away Team</th>
                                <th>Stadium</th>
                                <th>Playing</th>
                                {/**
                                <th>Active</th>
                            */}
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.matches.map((match, index) => {
                                    return (
                                        <tr key={index}>

                                            <td>
                                                <img src={match?.League?.logo_url} alt="choosed images" />
                                            </td>
                                            <td><Moment format="ddd,D MMM ,YYYY">{match.date}</Moment></td>
                                            <td>
                                                {match.time}
                                            </td>
                                            <td>{match.HomeTeam?.name}</td>
                                            <td>{match.AwayTeam?.name}</td>
                                            <td>{match.Stadium?.name.substring(0, 20)}</td>
                                            <td>
                                                {
                                                    match.is_playing === 0 && <div style={{ fontSize: "12px", margin: "0px 10px", background: "#F5C330", padding: "2px 5px", color: "#ffffff", fontWeight: "bold", borderRadius: "15px" }}>Not Playing</div>}
                                                {

                                                    match.is_playing === 1 && <div style={{ fontSize: "12px", margin: "0px 10px", background: '#77E87A', padding: "2px 5px", color: "#ffffff", fontWeight: "bold", borderRadius: "15px", }}>Playing</div>
                                                }
                                                {
                                                    match.is_playing === 2 && <div style={{
                                                        fontSize: "12px", margin: "0px 10px",
                                                        background: "#795548", padding: "2px 5px", color: "#ffffff", fontWeight: "bold", borderRadius: "15px"
                                                    }} >Finished</div>
                                                }
                                            </td>
                                            {/**
                                            <td>{match.active === 1 ? "Active" : "Inactive"}</td>
                                        */}
                                            <td>
                                                <Modal deleteItem={() => this.handleDelete(match.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(match)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.time && !this.props.teamId && !this.props.leagueId && !this.props.startDate && !this.props.endDate) && this.props.getMatches(pageNumber, this.props.row_count);
                        (this.props.time || this.props.teamId || this.props.leagueId || this.props.startDate || this.props.endDate) && this.props.searchItem(this.state.time, this.state.leagueId, this.state.teamId, this.state.startDate, this.state.endDate, pageNumber);
                    }} />
                    {
                        this.state.show &&
                        <MatchCreate
                            isEdit={this.state.isEdit}
                            match={this.state.match}
                            hideModal={this.hideModal}
                            createOrUpdate={this.createOrUpdate}
                            teams={this.props.teams}
                            getAllTeams={this.props.getAllTeams}
                            teamRowCount={this.props.teamRowCount}
                            team_leagues={this.props.team_leagues}
                            leagues={this.props.leagues}
                            stadiums={this.props.stadiums}
                            getAllLeagues={this.props.getAllLeagues}
                            leagueRowCount={this.props.leagueRowCount}
                            teamLeagueRowCount={this.props.teamLeagueRowCount}
                            getTeamLeagues={this.props.getTeamLeagues}
                            loadMore1={this.loadMore1}
                            loadMore2={this.loadMore2}
                            loadMore3={this.loadMore3}
                            searchTeam={this.props.searchTeam}
                            searchLeague={this.props.searchLeague}
                            teamCurrentPage={this.props.teamCurrentPage}
                            leagueCurrentPage={this.props.leagueCurrentPage}
                            // searchWithLeague={this.props.searchWithLeague}
                            searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                            requiredError={this.state.requiredError}
                            getAllStadiums={this.props.getAllStadiums}
                            stadiumCurrentPage={this.props.stadiumCurrentPage}
                            searchStadium={this.props.searchStadium}
                            stadiumRowCount={this.props.stadiumRowCount}
                            stadiumText={this.props.stadiumText}

                        />
                    }

                </Body>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        matches: state.match.matches,
        totalCountPerPage: state.match.totalCountPerPage,
        currentPage: state.match.currentPage,
        row_count: state.match.row_count,
        totalCount: state.match.totalCount,
        teamlists: state.team.teams,
        stadiums: state.stadium.stadiums,
        leagues: state.league.leagues,
        error: state.match.error,
        success: state.match.success,
        time: state.match.time,
        teamId: state.match.teamId,
        leagueId: state.match.leagueId,
        startDate: state.match.startDate,
        endDate: state.match.endDate,
        loading: state.match.loading,
        teams: state.team_league.team_leagues,
        teamLeagueRowCount: state.team_league.row_count,
        teamRowCount: state.team.row_count,
        leagueRowCount: state.league.row_count,
        leagueText: state.league.text,
        leagueCurrentPage: state.league.currentPage,
        teamText: state.team.text,
        teamCurrentPage: state.team.currentPage,
        teamTotalCount: state.team.totalCount,
        stadiumText: state.stadium.text,
        stadiumRowCount: state.stadium.row_count,
        stadiumCurrentPage: state.stadium.currentPage,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMatches: (pageNumber, row_count) => dispatch(MatchAction.getMatches(pageNumber, row_count)),
        createMatch: (match) => dispatch(MatchAction.createMatch(match)),
        updateMatch: (match) => dispatch(MatchAction.updateMatch(match)),
        deleteMatch: (id) => dispatch(MatchAction.deleteMatch(id)),
        getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
        getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
        getAllStadiums: (pageNumber, row_count) => dispatch(StadiumAction.getAllStadiums(pageNumber, row_count)),
        searchItem: (text, leagueId, teamId, startDate, endDate, pageNumber) => dispatch(MatchAction.searchItem(text, leagueId, teamId, startDate, endDate, pageNumber)),
        searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
        searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchItem(text, stadiumId, countryId, pageNumber)),
        searchStadium: (text, pageNumber) => dispatch(StadiumAction.searchItem(text, pageNumber)),

        getTeamLeagues: (pageNumber, row_count) => dispatch(TeamLeagueAction.getTeamLeagues(pageNumber, row_count)),
        searchWithLeagueAndTeamName: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamLeagueAction.searchWithLeagueAndTeamName(leagueId, teamName, row_count, pageNumber)),
        // searchWithLeague: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamLeagueAction.searchWithLeague(leagueId, teamName, row_count, pageNumber))


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchListing)