import React, { Component } from 'react'
import { Navigate, Outlet } from "react-router-dom";
class ProtectedRoute extends Component {
    constructor(props) {
        super(props)
        const auth = localStorage.getItem('operator')

        let loggedIn = true;
        if (!auth) {
            loggedIn = false
        }
        this.state = {
            loggedIn,
        }
    }
    render() {
        return (
            this.state.loggedIn ? <Outlet /> : <Navigate to="/login" replace />
        )
    }
}
export default ProtectedRoute
