import React from 'react'
import QuizDetailsAction from './../../../../redux/action/quiz_details_action';
import SpecialQuizAction from '../../../../redux/action/special_quiz_action.js'
import QuestionAction from '../../../../redux/action/question_action';
import TeamAction from '../../../../redux/action/team_action';

import QuizDetailFutureCreate from './../create/QuizDetailFutureCreate';
import SpecialQuizDetailsAction from './../../../../redux/action/special_quiz_detail_action';
import QuizDetailFutureListController from './QuizDetailFutureListController';

import { Back, Alert, Link, withRouter, connect, DeleteModal, Pagination, PageLoader, Button, MainContainer, Header, Body } from '../../../../components/ImportsFile'
class QuizDetailFutureList extends QuizDetailFutureListController {
  render() {
    let deletePermission = false;
    let createPermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Quizzes' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    const questionIDs = [];
    for (let i = 0; i < this.props.special_quiz_details.length; i++) {
      let newId = this.props.special_quiz_details[i].question_id
      if (!questionIDs.includes(newId)) {
        questionIDs.push(newId)
      }
    }
    return (
      <MainContainer>
        <Header>
          <h4>
            <Link to="/quizzes" style={{ textDecoration: 'none' }}>
              <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
            </Link>
            Quiz Details Lists
          </h4>

          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Quiz Details" className="btn-primary add-button" />
        }
        </Header>
        <Body>
          {
            // this.props.quizInfo.match_name &&
            <div className="quiz_info">

              <p> <b>League: </b> {this.state.quizInfo && this.state.quizInfo?.League?.name}</p>
              <p> <b>Type:  </b>Future</p>
              <p> <b>Country: </b> {this.state.quizInfo ? this.state.quizInfo?.Country?.name : "-"}</p>
            </div>
          }

          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Type</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>


              {

                // this.props.special_quiz_details.filter((data, i) => this.props.special_quiz_details.findIndex(d => d.question_id === data.question_id) === i).map((item, index) => {
                this.props.special_quiz_details.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          item?.Question?.question
                        }
                      </td>
                      <td>
                        {
                          item.type === 1 && "Player"
                        }
                        {
                          item.type === 2 && "Team"
                        }
                        {
                          item.type === 3 && "Manager"
                        }

                      </td>
                      <td>{item.active === 1 ? "Active" : "Inactive"}</td>
                      <td>
                        <Link to={`/quiz_detail/${item.special_quiz_id}/answers/${item.id}`} className="quiz_detail">
                          <Button caption="Answers" className="btn-success"
                          />
                        </Link>

                        <Link to={`/quiz_detail/${item.special_quiz_id}/future_details/${item.id}`} className="quiz_detail">
                          <Button caption='Details' className="btn-primary" icon="fa fa-info-circle"
                          />
                        </Link>
                        <DeleteModal deleteItem={() => this.props.deleteSpecialQuizDetails(item.id)}
                          deletePermission={deletePermission}
                        // editItem={() => { this.handleEdit(item) }}
                        />
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>



          <Pagination activePage={this.props.currentPage}
            totalItemsCount={questionIDs.length}
            // totalItemsCount={this.props.totalCount}
            // totalCountPerPage={this.props.totalCountPerPage}
            totalCountPerPage={questionIDs.length}
            paginate={(pageNumber) => {
              // (!this.props.matchId) && this.props.getQuizzes(pageNumber, this.props.rowCount);
              this.props.getSpecialQuizDetailsLists(this.props.quizId, pageNumber, this.props.row_count)
            }} />


          {
            this.state.show &&
            <QuizDetailFutureCreate
              quizInfo={this.props.quizInfo}
              questions={this.props.questions}
              isEdit={this.state.isEdit}
              special_quiz_details={this.props.special_quiz_details}
              getQuestions={this.props.getQuestions}
              future_quiz_details={this.state.future_quiz_details}
              hideModal={this.hideModal}
              createOrUpdate={this.createOrUpdate}
              quizType={this.state.quizType}
              requiredError={this.state.requiredError}
            />
          }

        </Body>
      </MainContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    special_quiz_details: state.special_quiz_detail.special_quiz_details,
    matchRowCount: state.match.row_count,
    questions: state.question.questions,
    questionRowCount: state.question.row_count,
    questionTotalCount: state.question.totalCount,
    // teams: state.team.teams,
    // teamCurrentPage: state.team.currentPage,
    // teamRowCount: state.team.teamRowCount,
    // quiz_details_list: state.quiz_details.quiz_details_lists,
    currentPage: state.special_quiz_detail.currentPage,
    totalCount: state.special_quiz_detail.totalCount,
    error: state.special_quiz_detail.error,
    success: state.special_quiz_detail.success,
    matchId: state.special_quiz_detail.matchId,
    totalCountPerPage: state.special_quiz_detail.totalCountPerPage,
    rowCount: state.special_quiz_detail.row_count,
    loading: state.special_quiz_detail.loading,
    type: state.question.type,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialQuizDetailsLists: (quizId, pageNumber, row_count) => dispatch(SpecialQuizDetailsAction.getSpecialQuizDetailsLists(quizId, pageNumber, row_count)),
    createSpecialQuizDetails: (quiz_details) => dispatch(SpecialQuizDetailsAction.createSpecialQuizDetails(quiz_details)),
    updateSpeicalQuizDetails: (quiz_details) => dispatch(SpecialQuizDetailsAction.updateSpecialQuizDetails(quiz_details)),
    deleteSpecialQuizDetails: (id) => dispatch(SpecialQuizDetailsAction.deleteSpecialQuizDetails(id)),
    getSpecialQuizzes: (pageNumber, row_count) => dispatch(SpecialQuizAction.getSpecialQuizzes(pageNumber, row_count)),
    searchQuizDetails: (quizId, pageNumber) => dispatch(SpecialQuizDetailsAction.searchQuizDetails(quizId, pageNumber)),
    getQuestions: (type, pageNumber, row_count) => dispatch(QuestionAction.getQuestions(type, pageNumber, row_count)),
    // getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
    // searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, countryId, pageNumber))


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuizDetailFutureList))
