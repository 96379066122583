import React from 'react'
import { Alert, PageLoader, Modal, Input, Pagination, connect, MainContainer, Header, Body, Button } from '../../../components/ImportsFile'
import CoinPolicyListController from './CoinPolicyListController';
import CoinPolicyCreate from './../create/CoinPolicyCreate';
import CoinPolicyAction from '../../../redux/action/coin_policy_action.js'
class CoinPolicyList extends CoinPolicyListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Coin Policy' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Coin Policy' && permission.functions === 'update') {
        return updatePermission = true;
      }

    })
    return (

      <MainContainer>
        <Header>
          <h4>Coin Policy</h4>
          {/**
          <form className="search" onSubmit={this.handleSearch} autoComplete="off">
          <div className="input-group">
          <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
          <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
          <button type="submit"><i className="fa fa-search"></i></button>
          </div>
          </form>
        */}

          {
            !this.props.coin_policies?.length > 0 ?
              <Button caption="Add Coin Policy" className="btn-primary add-button" btnFun={this.showModal} /> :
              (
                createPermission &&
                <Button caption={this.state.fotmob_migration_id ? 'Update Migration Id' : "Add Migration Id"} className="btn-primary add-button" btnFun={this.showFotmobIdModal} />
              )
          }

        </Header>
        <Body>

          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.state.showFotmobIdModal && <div className="modal-wrapper">
              <div className="modalHeader">
                <h3>{this.state.fotmob_migration_id ? 'Edit Migration Id' : 'Add Migration Id'}</h3>
                <span className="close-modal-btn" onClick={this.hideModal}>x</span>
              </div>
              <div className="modalContent">
                <div className="modalBody">
                  <form autoComplete="off" style={{ width: '60%', margin: 'auto' }}>
                    {
                      this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                    }
                    <Input type="text" name="fotmob_migration_id" placeholder="Enter Fotmob Migration Id..." value={this.state.fotmob_migration_id} onChange={this.handleInputFotmobId} required />
                    <br />
                    <Button type="button" id="submit-btn" caption={this.state.fotmob_migration_id ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.updateMigrationId(this.state.fotmob_migration_id)} />
                  </form>
                </div>
              </div>
            </div>
          }

          {
            this.props.loading && <PageLoader />
          }

          <table>
            <thead>
              <tr>
                <th>Coin</th>
                <th>Equivalent Amount</th>
                <th>Quick Bet 1</th>
                <th>Quick Bet 2</th>
                <th>Quick Bet 3</th>
                <th>Quick Bet 4</th>
                <th>Active</th>
                {
                  updatePermission && <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.coin_policies.map((coin_policy, index) => {
                  return (
                    <tr key={index}>
                      <td>{coin_policy.coin}</td>
                      <td>{coin_policy.equivalent_amount}</td>
                      <td>{coin_policy.quick_bet_1}</td>
                      <td>{coin_policy.quick_bet_2}</td>
                      <td>{coin_policy.quick_bet_3}</td>
                      <td>{coin_policy.quick_bet_4}</td>
                      <td>{coin_policy.active === 1 ? "Active" : "Inactive"}</td>
                      <td>
                        <Modal editItem={() => this.handleEdit(coin_policy)} updatePermission={updatePermission} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { this.props.getCoinPolicies(pageNumber, this.props.row_count) }} />

          {
            this.state.show && <CoinPolicyCreate coin_policy={this.state.coin_policy} hideModal={this.hideModal} createOrUpdate={this.createOrUpdate} isEdit={this.state.isEdit} requiredError={this.state.requiredError} />
          }

        </Body>

      </MainContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    coin_policies: state.coin_policy.coin_policies,
    error: state.coin_policy.error,
    success: state.coin_policy.success,
    currentPage: state.coin_policy.currentPage,
    totalCount: state.coin_policy.totalCount,
    totalCountPerPage: state.coin_policy.totalCountPerPage,
    row_count: state.coin_policy.row_count,
    loading: state.coin_policy.loading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCoinPolicies: (pageNumber, row_count) => dispatch(CoinPolicyAction.getCoinPolicies(pageNumber, row_count)),
    updateCoinPolicy: (coin_policy) => dispatch(CoinPolicyAction.updateCoinPolicy(coin_policy)),
    createCoinPolicy: (coin_policy) => dispatch(CoinPolicyAction.createCoinPolicy(coin_policy)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoinPolicyList)

