import React from 'react'
import { Input, Button, SelectModal, Switch } from '../../../components/ImportsFile'
import SpecialQuizDetailCreateController from './SpecialQuizDetailCreateController';
class SpecialQuizDetailCreate extends SpecialQuizDetailCreateController {
  render() {

    const styles = {
      match_name: { color: "#ffffff", alignItems: 'center', fontSize: '15px', fontWeight: 'normal' },
      form: { columnCount: '2', width: '100%', margin: 'auto' }
    };
    // const submit_type = [
    //   { id: 1, name: 'Numbering Scroll' },
    //   { id: 2, name: 'Radio Button' },
    //   // { id: 3, name: 'Check Box' },
    //   { id: 4, name: 'Click Select' },
    // ]

    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Quiz Details' : 'Add Quiz Details'}</h3>
          <span style={styles.match_name} >
            {
              this.props.quizInfo.type === 1 &&
              <div>
                Match : &nbsp;
                <small>
                  {
                    this.props.quizInfo?.Match?.HomeTeam?.name + " Vs " + this.props.quizInfo?.Match?.AwayTeam?.name
                  }
                </small>
              </div>
            }
          </span>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            {
              this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
            }
            <form autoComplete="off" style={styles}>
              <div style={styles.form}>
                <select name="active" className="select" value={this.state.quiz_details.type} onChange={this.handleSelectType} required>
                  {
                    (this.props.quizInfo.quiz_type === 1 || this.props.quizInfo.quiz_type === 3) &&
                    <option value="4">Match</option>
                  }
                  <option value="1">Player</option>
                  <option value="2">Team</option>
                  <option value="3">Manager</option>
                </select>

                <SelectModal
                  questions={this.props.questions}
                  // noQuestion={this.props.questions.length < 1 ? "noQuestion" : ""}
                  handleSelectQuestion={(questionId) => this.handleSelectQuestion(questionId)}
                  itemName={
                    this.props.isEdit ?
                      this.props.quiz_details?.quiz_details?.Question?.question
                      :
                      "*** Choose Question ***"}
                />

                <SelectModal
                  items={this.state.submit_type}
                  submit_type={
                    (this.state.quiz_details.submit_type === 2 && "Radio Button") ||
                    (this.state.quiz_details.submit_type === 4 && "Click Select")
                  }
                  name="submit_type"
                  // routeChangeName="match_quiz"
                  handleProcess={(typeId) => this.handleSelectSubmitType(typeId)}
                  itemName={
                    this.props.isEdit ?
                      (
                        this.props.quiz_details.quiz_details.submit_type == 1 && "Numbering Scorll" ||
                        this.props.quiz_details.quiz_details.submit_type == 2 && "Radio Button" ||
                        // this.props.quiz_details.quiz_details.submit_type == 3 && "Check Box"
                        this.props.quiz_details.quiz_details.submit_type == 4 && "Select Scroll"
                      )
                      :
                      "*** Choose Submit Type ***"}
                />



                <Input type="text" placeholder="Enter Answer Count" name="answer_count" value={this.state.quiz_details.answer_count} onChange={this.AnswerCount} required disabled={this.state.quiz_details.submit_type === 1 && true} />

                <Input type="text" placeholder="Enter Redeem Points" name="redeem_point" value={this.state.quiz_details.redeem_point} onChange={this.RedeemPoints} />



                <div className="switch">
                  <span style={{ color: !this.state.checked ? '#fad230' : '#777777' }}> Manual &nbsp;</span>
                  <Switch onChange={this.handleSwitch} className="switch-toggle" checked={this.state.checked}
                    offColor="#202020"
                    onColor="#fad230"
                    offHandleColor="#fad230"
                    onHandleColor="#202020"
                    checkedIcon={
                      <div style={{ display: 'none' }}> Multiply </div>
                    } uncheckedIcon={
                      <div style={{ display: 'none' }}>Manual </div>
                    }
                  />
                  <span style={{ color: this.state.checked ? '#fad230' : '#777777' }}> &nbsp; Multiply</span>
                </div>
                {
                  this.state.checked &&
                  <Input type="text" placeholder="Enter Multiply Number" name="multiplay_no" value={this.state.quiz_details.multiply_no || ''} onChange={this.MultiplyNo} />
                }

                <Input type="text" placeholder="Enter Win Reward" name="win_reward" value={this.state.quiz_details.win_reward} onChange={this.WinReward} disabled={(this.props.isEdit && this.state.checked) && true} />

                <Input type="text" placeholder="Enter Remark" name="remark" value={this.state.quiz_details.remark || ''} onChange={this.Remark} />



                <select name="active" className="select" value={this.state.quiz_details.active} onChange={this.handleSelect} required>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

                {
                  !this.state.checked &&
                  <>
                    <br />
                    <br />
                    <br />
                  </>
                }
              </div>
              <br />
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => {
                this.props.createOrUpdate(this.state.quiz_details)
              }
              } />

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SpecialQuizDetailCreate