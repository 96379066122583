import { Component } from 'react'

class GlobalLevelRuleCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            global_level_rules: [],
            global_level_rule: props.isEdit === false ? {
                top_fan_level_number: '',
                from_min_count: '',
                to_max_count: '',
                active: 1
            } :
                props.global_level_rule.global_level_rule
        }
    }


    TopFanLevelNumber = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            global_level_rule: {
                ...prevState.global_level_rule,
                top_fan_level_number: e.target.value
            }
        }))
    }
    FromMinCount = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            global_level_rule: {
                ...prevState.global_level_rule,
                from_min_count: e.target.value
            }
        }))
    }
    ToMaxCount = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            global_level_rule: {
                ...prevState.global_level_rule,
                to_max_count: e.target.value
            }
        }))
    }

    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            global_level_rule: {
                ...prevState.global_level_rule,
                active: e.target.value
            }
        }))
    }
}

export default GlobalLevelRuleCreateController