import axios from "axios"
import CONSTANTS from './../constants';
import AnnouncementType from './../type/announcement_type';

export const Error = (data) => {
  return {
    type: AnnouncementType.ANNOUNCEMENT_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: AnnouncementType.ANNOUNCEMENT_SUCCESS, payload: data
  }
}

const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: AnnouncementType.LOADING, payload: status })
  }
}
const getAnnouncements = (pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}announcements/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: AnnouncementType.GET_ANNOUNCEMENTS, payload: response.data.data
        })
        dispatch({
          type: AnnouncementType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: AnnouncementType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: AnnouncementType.TOTAL_PAGE, payload: response.data.pagination.total_page
        })
        dispatch({
          type: AnnouncementType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: AnnouncementType.ROW_COUNT,
          payload: row_count
        })
      } else {
        let message = "custom error message"
        dispatch({
          type: AnnouncementType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: AnnouncementType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}


const createAnnouncement = (announcement) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}announcements/create`, announcement).then(response => {
      if (response.data.status === 'Success' || response.data.message === 'Success') {
        dispatch({
          type: AnnouncementType.ADD_ANNOUNCEMENT,
          payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }

}
const updateAnnouncement = (announcement) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}announcements/update`, announcement).then(response => {
      if (response.data.status === "Success") {

        dispatch({
          type: AnnouncementType.UPDATE_ANNOUNCEMENT, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)

    })
  }
}
const deleteAnnouncement = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'));
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}announcements/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: AnnouncementType.DELETE_ANNOUNCEMENT, payload: id,
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "custom error message"
        dispatch({
          type: AnnouncementType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: AnnouncementType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const searchAnnouncement = (text, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}announcements/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: AnnouncementType.GET_ANNOUNCEMENTS, payload: response.data.data
        })
        dispatch({
          type: AnnouncementType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: AnnouncementType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: AnnouncementType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
      }
      return dispatch({
        type: AnnouncementType.SEARCH_ITEM,
        payload: text
      })
    })
  }
}


const AnnouncementAction = {
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  searchAnnouncement,
  loading,
}

export default AnnouncementAction;