import React from 'react'
import { Alert, connect, PageLoader, Pagination, Button, Search, MainContainer, Header, Body } from '../../../components/ImportsFile'
import UserFavNationalTeamListController from './UserFavNationalTeamListController';
import UserFavouriteNationalTeamAction from '../../../redux/action/user_favourite_national_team_action.js'
import TeamAction from './../../../redux/action/team_action';
import UserAction from './../../../redux/action/user_action';

class UserFavNationalTeamList extends UserFavNationalTeamListController {
  render() {
    return (
      <MainContainer>
        <Header>
          <h4>User Fav National Teams</h4>
          <form className="search user-fav-team" >
            <div className="input-group">
              <Search
                items={this.props.users}
                searchItem={(userId) => this.handleSelectUserId(userId)}
                text={"Search with User"}
                searching={this.props.searchUser}
                getItems={this.props.getAllUsers}
                rowCount={this.state.rowCount}
                search={this.state.userId ? true : false}
                currentPage={this.props.currentUserPage}
                stopSearching={this.stopSearching}
                LoadMore={this.loadMore1}

              />
              <Search
                items={this.props.teams}
                searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                searchTeam={this.props.searchTeam}
                rowCount={this.state.rowCount}
                getItems={this.props.getAllTeams}
                currentPage={this.props.currentTeamPage}
                text={'Search with Team'}
                search={this.state.teamId ? true : false}
                stopSearching={this.stopSearching}
                LoadMore={this.loadMore2}
              />
              <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
              <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
            </div>
          </form>

        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Team</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>

              {
                this.props.user_favourite_national_teams?.map((favTeam, index) => {
                  return (
                    <tr key={index}>
                      <td>{favTeam.User?.user_name}</td>
                      <td>{favTeam.Team?.name}</td>
                      <td>{favTeam.active === 1 ? "Active" : "Inactive"}</td>
                      <td></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
            (!this.props.userId && !this.props.teamId) && this.props.getUserFavouriteTeams(pageNumber, this.props.row_count);
            (this.props.userId || this.props.teamId) && this.props.searchWithUserOrTeam(this.state.userId, this.state.teamId, pageNumber);

          }} />

        </Body>
      </MainContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user_favourite_national_teams: state.user_favourite_national_team.user_favourite_national_teams,
    totalCount: state.user_favourite_national_team.totalCount,
    totalCountPerPage: state.user_favourite_national_team.totalCountPerPage,
    userTotalCount: state.user.totalCount,
    teamTotalCount: state.team.totalCount,
    currentPage: state.user_favourite_national_team.currentPage,
    currentUserPage: state.user.currentPage,
    currentTeamPage: state.team.currentPage,
    error: state.user_favourite_national_team.error,
    success: state.user_favourite_national_team.success,
    row_count: state.user_favourite_national_team.row_count,
    loading: state.user_favourite_national_team.loading,
    teamId: state.user_favourite_national_team.teamId,
    userId: state.user_favourite_national_team.userId,
    userText: state.user.text,
    teamText: state.team.text,
    users: state.user.users,
    active: state.user.active,
    teams: state.team.teams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFavouriteNationalTeams: (pageNumber, row_count) => dispatch(UserFavouriteNationalTeamAction.getUserFavouriteNationalTeams(pageNumber, row_count)),
    createUserFavouriteNationalTeam: (user_favourite_national_team) => dispatch(UserFavouriteNationalTeamAction.createUserFavouriteNationalTeam(user_favourite_national_team)),
    updateUserFavouriteNationalTeam: (user_favourite_national_team) => dispatch(UserFavouriteNationalTeamAction.updateUserFavouriteNationalTeam(user_favourite_national_team)),
    // deleteUserFavouriteTeam: (id) => dispatch(UserFavouriteNationalTeamAction.deleteUserFavouriteTeam(id)),

    searchWithUserOrTeam: (userId, teamId, pageNumber) => dispatch(UserFavouriteNationalTeamAction.searchWithUserOrTeam(userId, teamId, pageNumber)),
    searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchItem(text, stadiumId, countryId, pageNumber)),
    getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
    searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),
    getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserFavNationalTeamList)
