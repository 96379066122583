import { Component } from 'react'
import Swal from 'sweetalert2';
class ChangePasswordController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            isChanged: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
        }
    }
    toggleOldPassword = () => {
        this.setState({
            showOldPassword: !this.state.showOldPassword
        })
    }
    toggleNewPassword = () => {
        this.setState({
            showNewPassword: !this.state.showNewPassword
        })
    }
    toggleConfirmPassword = () => {
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword
        })
    }
    handleChangeEmail = (e) => {
        this.setState((prevState) => ({
            ...prevState.email,
            email: e.target.value
        }))
    }
    handleChangeOldPassword = (e) => {
        this.setState((prevState) => ({
            ...prevState.old_password,
            old_password: e.target.value
        }))
    }
    handleChangeNewPassword = (e) => {
        this.setState((prevState) => ({
            ...prevState.new_password,
            new_password: e.target.value
        }))

    }
    handleChangeConPassword = (e) => {
        this.setState((prevState) => ({
            ...prevState.confirm_password,
            confirm_password: e.target.value
        }))

    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let loggedInOperator = JSON.parse(localStorage.getItem('operator'))
        if (!this.state.email || !this.state.old_password || !this.state.new_password || !this.state.confirm_password) {
            Swal.fire({
                icon: 'error',
                text: 'All fields are requried.',
            })
        }
        else if (this.state.email !== loggedInOperator.email) {
            Swal.fire({
                icon: 'error',
                text: 'Wrong Email Addresss!!',
            })
        }
        else if (this.state.new_password !== this.state.confirm_password) {
            Swal.fire({
                icon: 'error',
                text: 'Password Does Not Match!!',
            })
        }
        else {

            let operator;
            operator = {
                email: this.state.email,
                new_password: this.state.new_password,
                confirm_password: this.state.confirm_password,
                old_password: this.state.old_password
            }

            this.props.changeOperatorPassword(operator).then(() => {

                !this.props.error ? Swal.fire({
                    icon: 'success',
                    text: `Password Changed Successfully! Please Login Again!!`,
                }).then(() => {
                    // setTimeout(() => {
                        this.setState({
                            isChanged: true,
                            email: '',
                            old_password: '',
                            new_password: '',
                            confirm_password: '',
                        })
                    // }, 1000)
                }) : Swal.fire({
                    icon: 'error',
                    text: `${this.props.error.message}`,
                })

            });
        }

    }
}

export default ChangePasswordController