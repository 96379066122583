
import UserFavouriteTeamType from './../type/user_favourite_team_type';

const initialState = {
    user_favourite_teams: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    userId: null,
    teamId: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}

const userFavouriteTeamReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case UserFavouriteTeamType.GET_USER_FAVOURITE_TEAMS:
            return {
                ...state,
                loading: false,
                user_favourite_teams: actions.payload
            }
        case UserFavouriteTeamType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case UserFavouriteTeamType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case UserFavouriteTeamType.SEARCH_WITH_USER:
            return {
                ...state,
                userId: actions.payload
            }
        case UserFavouriteTeamType.SEARCH_WITH_TEAM:
            return {
                ...state,
                teamId: actions.payload
            }
        case UserFavouriteTeamType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case UserFavouriteTeamType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case UserFavouriteTeamType.ADD_USER_FAVOURITE_TEAM:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                user_favourite_teams: [
                    actions.payload,
                    ...state.user_favourite_teams,
                ]
            }
        case UserFavouriteTeamType.UPDATE_USER_FAVOURITE_TEAM:
            const updateUserFavouriteTeams = state.user_favourite_teams.filter(favTeam => favTeam.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                user_favourite_teams: [
                    actions.payload,
                    ...updateUserFavouriteTeams,
                ]
            }
        case UserFavouriteTeamType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case UserFavouriteTeamType.DELETE_USER_FAVOURITE_TEAM:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                user_favourite_teams: state.user_favourite_teams.filter(favTeam => favTeam.id !== actions.payload)
            }
        case UserFavouriteTeamType.USER_FAVOURITE_TEAM_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case UserFavouriteTeamType.USER_FAVOURITE_TEAM_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}

export default userFavouriteTeamReducer;