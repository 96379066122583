
import styled from "styled-components";


export const MainContainer = styled.div.attrs(props => ({
    
}))`
&:hover .icon{
  color:#6DAEC2;
  cursor:pointer;
}

`;
export const BtnContainer = styled.button`
background-color:transparent;
border:0px;

`;
export const Itab = styled.i`
background-color:transparent;
border:0px;
font-size:20px;
color:#565656;
`;

export function TouchIcon({
    ...props
  }) {
    return (
      <MainContainer><BtnContainer className="btnIcon" >
        <Itab className={props.icon + " icon"} />
      </BtnContainer></MainContainer>
    );
  }