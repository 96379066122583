const TransactionType = {
    GET_TRANSACTIONS: "GET_TRANSACTIONS",
    GET_TRANSACTION_DETAIL: "GET_TRANSACTION_DETAIL",
    AMOUNTIN_TRANSACTION: "AMOUNTIN_TRANSACTION",
    AMOUNTOUT_TRANSACTION: "AMOUNTOUT_TRANSACTION",
    ADD_POINTS_TRANSACTION: "ADD_POINTS_TRANSACTION",
    REMOVE_POINTS_TRANSACTION: "REMOVE_POINTS_TRANSACTION",
    UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
    DELETE_TRANSACTION: "DELETE_TRANSACTION",
    TRANSACTION_ERROR: "TRANSACTION_ERROR",
    TRANSACTION_SUCCESS: "TRANSACTION_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_WITH_USER: "SEARCH_WITH_USER",
    SEARCH_WITH_PAYTYPE:'SEARCH_WITH_PAYTYPE',
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default TransactionType