
import SpecialQuizType from './../type/special_quiz_type';
const initialState = {
    special_quizzes: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
    leagueId: null,
    quizType: null,
    date: null,
    isCurrent: null,
}
const specialQuizReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SpecialQuizType.GET_SPECIAL_QUIZZES:
            return {
                ...state,
                loading: false,
                special_quizzes: actions.payload
            }
        case SpecialQuizType.SEARCH_WITH_DATE:
            return {
                ...state,
                loading: false,
                date: actions.payload
            }
        case SpecialQuizType.SEARCH_WITH_LEAGUE_ID:
            return {
                ...state,
                loading: false,
                leagueId: actions.payload
            }
        case SpecialQuizType.SEARCH_WITH_QUIZ_TYPE:
            return {
                ...state,
                loading: false,
                quizType: actions.payload
            }
        case SpecialQuizType.SEARCH_WITH_ISCURRENT_DATE:
            return {
                ...state,
                loading: false,
                isCurrent: actions.payload
            }
        case SpecialQuizType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case SpecialQuizType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case SpecialQuizType.SEARCH_WITH_LEAGUE_ID_FROM_FOTMOB:
            return {
                ...state,
                leagueId: actions.payload
            }
        case SpecialQuizType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case SpecialQuizType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case SpecialQuizType.ADD_SPECIAL_QUIZ:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                special_quizzes: [
                    actions.payload,
                    ...state.special_quizzes,
                ]
            }
        case SpecialQuizType.UPDATE_SPECIAL_QUIZ:
            const updateQuizzes = state.special_quizzes.filter(quiz => quiz.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                special_quizzes: [
                    actions.payload,
                    ...updateQuizzes,
                ]
            }
        case SpecialQuizType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }

        case SpecialQuizType.DELETE_SPECIAL_QUIZ:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                special_quizzes: state.special_quizzes.filter(quiz => quiz.id !== actions.payload),
            }
        case SpecialQuizType.SPECIAL_QUIZ_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case SpecialQuizType.SPECIAL_QUIZ_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default specialQuizReducer