import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class GlobalLevelRuleListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            global_level_rule: {},
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            requiredError: '',

        }
    }

    componentDidMount = () => {
        this.props.getGlobalLevelRules(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.props.getGlobalLevelRules(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
        })
    }
    handleInputText = e => {
        this.setState({
            text: e.target.value,
        })

    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }

    handleEdit = async (global_level_rule) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            global_level_rule: {
                ...prevState.global_level_rule,
                global_level_rule
            }
        }))
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,

        })
    }
    createOrUpdate = (data) => {

        let global_level_rule = {
            top_fan_level_number: Number(data.top_fan_level_number),
            from_min_count: Number(data.from_min_count),
            to_max_count: Number(data.to_max_count),
            active: data.active,
        }

        if (this.state.isEdit) {
            global_level_rule = { ...global_level_rule, id: data.id }
            if (global_level_rule.top_fan_level_number === '' || global_level_rule.from_min_count === '' || global_level_rule.to_max_count === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }

            else if (global_level_rule.from_min_count < 0 || global_level_rule.to_max_count < 0) {
                this.setState({
                    requiredError: "Count must be greater than 0!"
                })
            }
            else if (global_level_rule.from_min_count > global_level_rule.to_max_count) {
                this.setState({
                    requiredError: `Maximun count must be greater than minimun count!`
                })
            }
            else {
                this.props.updateGlobalLevelRule(global_level_rule).then(() => {
                    this.props.getGlobalLevelRules(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        } else {
            global_level_rule = { ...global_level_rule }
            if (global_level_rule.top_fan_level_number === '' || global_level_rule.from_min_count === '' || global_level_rule.to_max_count === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (global_level_rule.from_min_count < 0 || global_level_rule.to_max_count < 0) {
                this.setState({
                    requiredError: "Count must be greater than 0!"
                })
            }
            else if ((this.props.global_level_rules.length > 0 && Number(this.props.global_level_rules[0].top_fan_level_number)) >= global_level_rule.top_fan_level_number) {
                this.setState({
                    requiredError: `Top fan level number must be greater than ${Number(this.props.global_level_rules[0].top_fan_level_number)}`
                })
            }
            else if (this.props.global_level_rules.length > 0 && Number(this.props.global_level_rules[0].to_max_count) >= global_level_rule.from_min_count) {
                this.setState({
                    requiredError: `Minimun Count must be greater than ${Number(this.props.global_level_rules[0].to_max_count)}`
                })
            }
            else if (global_level_rule.from_min_count >= global_level_rule.to_max_count) {
                this.setState({
                    requiredError: "Maximun count must be greater than min count!"
                })
            }
            else {
                this.props.createGlobalLevelRule(global_level_rule).then(() => {
                    this.props.getGlobalLevelRules(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
}

export default GlobalLevelRuleListController