import React from 'react'
import HighlightListController from './HighlightListController';
import HighlightCreate from './../create/HighlightCreate';
import HighlightAction from './../../../redux/action/highlight_action.js';
import MatchAction from './../../../redux/action/match_action.js';
import LeagueAction from './../../../redux/action/league_action.js';

import { YouTube, Custom, Alert, PageLoader, Search, Modal, Pagination, Button, connect, MainContainer, Header, Body } from '../../../components/ImportsFile'
class HighlightList extends HighlightListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Highlight' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Highlight' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Highlight' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer >
                <Header>
                    <h4>Highlight</h4>
                    <form className="search search-highlight">
                        <div className="input-group">
                            <Search
                                name={this.state.matchId}
                                matchForHighlight={this.state.matchForHighlight}
                                // items={this.props.matches}
                                searchItem={(matchId) => this.handleSelectMatch(matchId)}
                                currentPage={this.props.currentPage}
                                text={'Search with Match'}
                                search={this.state.matchId ? true : false}
                                LoadMore={this.loadMoreMatch}
                                getItems={this.props.getAllMatches}
                                rowCount={this.state.matchRowCount}
                                stopSearching={() => this.props.getHighlight(this.state.initialPage, this.state.rowCount)}
                            />

                            <Search
                                items={this.props.leagues}
                                searchItem={(leagueId) => this.handleSelectLeague(leagueId)}
                                searchLeague={this.props.searchLeague}
                                currentPage={this.props.currentPage}
                                text={'Search with League'}
                                search={this.state.leagueId ? true : false}
                                LoadMore={this.loadMoreLeague}
                                getItems={this.props.getAllLeagues}
                                rowCount={this.state.leagueRowCount}
                                stopSearching={() => this.props.getHighlight(this.state.initialPage, this.state.rowCount)}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Highlight" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>League</th>
                                <th>Match</th>
                                {/**
                                <th>Highlight URL</th>
                            */}
                                <th>Home Team Goal</th>
                                <th>Away Team Goal</th>
                                <th>Type</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Action</th>
                                }

                            </tr>
                        </thead>

                        <tbody>
                            {
                                this.props.highlights.map((highlight, index) => {
                                    return (
                                        highlight?.Match &&
                                        <tr key={index}>
                                            <td>
                                                <img src={highlight?.League?.logo_url} alt="choosed images" />
                                            </td>
                                            <td>{highlight.Match?.HomeTeam?.name} Vs {highlight.Match?.AwayTeam?.name}</td>
                                            {/**
                                            <td>
                                            {
                                                (highlight.highlight_url && highlight.highlight_url.length > 20) ? highlight.highlight_url.substring(0, 20) + '...' : highlight.highlight_url
                                            }
                                            </td>
                                        */}
                                            <td>{highlight.home_team_goal}</td>
                                            <td>{highlight.away_team_goal}</td>
                                            <td>{
                                                highlight.type === 1 ? <img src={Custom} alt='' style={{ width: '30px' }} /> :
                                                    <img src={YouTube} alt="" style={{ width: '30px' }} />
                                            }</td>

                                            <td>
                                                <Modal deleteItem={() => this.props.deleteHighlight(highlight.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(highlight)} />

                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.matchId && !this.props.leagueId) && this.props.getHighlight(pageNumber, this.props.row_count);
                        (this.props.matchId || this.props.leagueId) && this.props.searchItem(this.state.matchId, this.state.leagueId, pageNumber);
                    }} />
                    {
                        this.state.show &&
                        <HighlightCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            createOrUpdate={this.createOrUpdate}
                            highlight={this.state.highlight}
                            matches={this.state.matches}
                            leagues={this.props.leagues}
                            getAllLeagues={this.props.getAllLeagues}
                            leagueRowCount={this.props.leagueRowCount}
                            requiredError={this.state.requiredError}
                            newHeader={this.state.newHeader}
                        />
                    }

                </Body>

            </MainContainer >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        totalCount: state.highlight.totalCount,
        totalCountPerPage: state.highlight.totalCountPerPage,
        currentPage: state.highlight.currentPage,
        highlights: state.highlight.highlights,
        matches: state.match.matches,
        leagues: state.league.leagues,
        error: state.highlight.error,
        success: state.highlight.success,
        matchId: state.highlight.matchId,
        leagueId: state.highlight.leagueId,
        row_count: state.highlight.row_count,
        loading: state.highlight.loading,
        leagueText: state.league.text,
        leagueRowCount: state.league.row_count,
        matchRowCount: state.match.row_count,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHighlight: (pageNumber, row_count) => dispatch(HighlightAction.getHighlight(pageNumber, row_count)),
        createHighlight: (highlight) => dispatch(HighlightAction.createHighlight(highlight)),
        updateHighlight: (highlight) => dispatch(HighlightAction.updateHighlight(highlight)),
        deleteHighlight: (id) => dispatch(HighlightAction.deleteHighlight(id)),
        getAllMatches: (pageNumber, row_count) => dispatch(MatchAction.getAllMatches(pageNumber, row_count)),
        getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
        searchItem: (matchId, leagueId, pageNumber) => dispatch(HighlightAction.searchItem(matchId, leagueId, pageNumber)),
        searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HighlightList)