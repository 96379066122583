
import ActivityLogsType from './../type/activity_logs_type';
const initialState = {
    activity_logs: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    action: '',
    type: '',
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
    startDate: null,
    endDate: null,
}
const activityLogsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case ActivityLogsType.GET_ALL_ACTIVITIES:
            return {
                ...state,
                loading: false,
                activity_logs: actions.payload
            }
        case ActivityLogsType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case ActivityLogsType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case ActivityLogsType.SEARCH_WITH_ACTION:
            return {
                ...state,
                action: actions.payload
            }
        case ActivityLogsType.SEARCH_BY_TYPE:
            return {
                ...state,
                type: actions.payload
            }
        case ActivityLogsType.SEARCH_BY_START_DATE:
            return {
                ...state,
                startDate: actions.payload
            }
        case ActivityLogsType.SEARCH_BY_END_DATE:
            return {
                ...state,
                endDate: actions.payload
            }
        case ActivityLogsType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case ActivityLogsType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }

        case ActivityLogsType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case ActivityLogsType.ACTIVITY_LOGS_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case ActivityLogsType.ACTIVITY_LOGS_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default activityLogsReducer