import CoinPolicyType from './../type/coin_policy_type';
const initialState = {
  coin_policies: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
}

const coinPolicyReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case CoinPolicyType.GET_COIN_POLICIES:
      return {
        ...state,
        loading: false,
        coin_policies: actions.payload
      }
    case CoinPolicyType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case CoinPolicyType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case CoinPolicyType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case CoinPolicyType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case CoinPolicyType.ADD_COIN_POLICY:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        coin_policies: [
          actions.payload,
          ...state.coin_policies,
        ]
      }
    case CoinPolicyType.UPDATE_COIN_POLICY:
      const updateCoinPolicy = state.coin_policies.filter(item => item.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        coin_policies: [
          actions.payload,
          ...updateCoinPolicy,
        ]
      }
    case CoinPolicyType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case CoinPolicyType.COIN_POLICY_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case CoinPolicyType.COIN_POLICY_SUCCESS:
      return { ...state, success: actions.payload, loading: false }

    default: return state
  }
}

export default coinPolicyReducer;