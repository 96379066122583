import { Component } from 'react';
class WalletCreateController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: props.isEdit === false ? {
                user_id: '',
                // previous_amount: '',
                // current_amount: '',
                total_points: '',
                active: 1
            } : props.wallet.wallet
        }
    }
    UserSelect = (user_id) => {
        this.setState((prevState) => ({
            ...prevState,
            wallet: {
                ...prevState.wallet,
                user_id: user_id
            }
        }))
    }
    // InputPreviousAmount = (e) => {
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         wallet: {
    //             ...prevState.wallet,
    //             pervious_amount: e.target.value
    //         }
    //     }))
    // }
    // InputCurrentAmount = (e) => {
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         wallet: {
    //             ...prevState.wallet,
    //             current_amount: e.target.value
    //         }
    //     }))
    // }
    handleChangeTotalPoints = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            wallet: {
                ...prevState.wallet,
                total_points: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            wallet: {
                ...prevState.wallet,
                active: e.target.value
            }
        }))
    }
}

export {
    WalletCreateController
}