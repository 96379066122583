import React, { Component } from 'react'
import { Pie } from 'react-chartjs-2'
class PieChart extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }
  render() {
    let data = {
      labels: this.props.teamName,
      datasets: [
        {
          data: this.props.favCount,
          backgroundColor: [
            'red',
            '#137ced',
            '#ffc107',
            'rgb(87, 185, 96)',
            '#3A3365',
            'gray',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],

    }
    return <Pie data={data} />

  }
}

export default PieChart