import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';
class TeamListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            checked: false,
            teams: this.props.teams,
            rowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            countryRowCount: CONSTANTS.ROW_COUNT,
            stadiumRowCount: CONSTANTS.ROW_COUNT,
            leagueRowCount: CONSTANTS.ROW_COUNT,
            text: '',
            countryId: '',
            leagueId: null,
            requiredError: '',
            selectedLeague: [],
            searchWithLeague: false,
        }
        this.handleSwitch = this.handleSwitch.bind(this)
    }
    componentDidMount = () => {
        this.loadMore();
        this.loadMoreLeague();
        this.loadMoreCountry();

        this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
        this.props.searchWithLeagueAndTeamName(this.state.leagueId, '', this.state.rowCount, this.state.initialPage);
        this.props.getTeams(this.state.initialPage, this.state.rowCount);

    }
    stopSearching = () => {
        this.props.getTeams(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
            countryId: '',
            leagueId: null,
            searchWithLeague: false,
        })

    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value,
            // countryId: '',
            leagueId: '',
        })
    }
    handleSelectCountry = (countryId) => {
        this.setState({
            countryId,
            leagueId: '',
            // text: '',
            searchWithLeague: false
        }, () => {
            this.props.searchTeam(this.state.text, this.state.countryId, this.state.initialPage)
        })
    }
    handleSelectLeague = (leagueId) => {
        this.setState({
            leagueId,
            countryId: '',
            text: '',
            searchWithLeague: true
        }, () => {
            this.props.searchWithLeagueAndTeamName(this.state.leagueId, '', this.state.rowCount, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        (this.state.leagueId && this.state.searchWithLeague) ?
            this.props.searchWithLeagueAndTeamName(this.state.leagueId, '', this.state.rowCount, this.state.initialPage) :
            this.props.searchTeam(this.state.text, this.state.countryId, this.state.initialPage)
    }
    loadMore = () => {
        this.setState({
            stadiumRowCount: this.state.stadiumRowCount + 6
        })
        this.props.getAllStadiums(this.state.initialPage, this.state.stadiumRowCount);
    }
    loadMoreLeague = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllLeagues(this.state.initialPage, this.state.teamRowCount)
    }
    loadMoreCountry = () => {
        this.setState({
            countryRowCount: this.state.countryRowCount + 6
        })
        this.props.getAllCountries(this.state.initialPage, this.state.countryRowCount)
    }
    handleSwitch(checked) {
        this.setState({ checked })
    }

    handleEdit = async (team) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                show: true,
                isEdit: true,
                team: {
                    ...prevState.team,
                    team,
                },
                selectedLeague: team.leagues
            }
        })
    }

    selectChange = (leagues) => {
        let leaguesIdArray = leagues.map(ele => ele.value)
        this.setState(prevState => ({
            ...prevState,
            selectedLeague: leaguesIdArray
        }))
    }
    createOrUpdate = async (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let team = {
            logo_url: data.logo_url,
            league_id: this.state.selectedLeague,
            name: data.name,
            code: data.code,
            // stadium_id: data.stadium_id,
            country_id: data.country_id,
            active: data.active,
            updated_by: operator.id
        }
        if (data.stadium_id) {
            team = { ...team, stadium_id: data.stadium_id }
        }
        if (data.id_from_fotmob !== "" || data.id_from_fotmob !== null) {
            team = { ...team, id_from_fotmob: data.id_from_fotmob }
        }
        if (this.state.isEdit) {
            team = { ...team, id: data.id }
            let formData = new FormData();
            formData.append("id", team.id)
            formData.append("active", team.active)
            formData.append("code", team.code)
            formData.append("name", team.name)
            // formData.append("stadium_id", parseInt(team.stadium_id))
            // formData.append("id_from_fotmob", parseInt(team.id_from_fotmob))
            formData.append("country_id", parseInt(team.country_id))
            formData.append("league_id", JSON.stringify(team.league_id))
            formData.append("updated_by", parseInt(operator.id));
            if (data.id_from_fotmob !== '') {
                formData.append("id_from_fotmob", parseInt(team.id_from_fotmob))
            }

            if (data.stadium_id) {
                formData.append("stadium_id", parseInt(team.stadium_id))
            }

            if (typeof team.logo_url !== "string") {
                formData.append("logo_url", team.logo_url)
            }
            if (team.name === '' || team.code === '' || team.league_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (team.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else if (team.code.length > 3) {
                this.setState({
                    requiredError: "Team code character length must be less than 3"
                })
            }
            else {
                this.props.updateTeam(formData).then(() => {
                    this.props.getTeams(this.props.currentPage, this.state.rowCount)
                })
                this.hideModal();
            }
        }
        else {
            team = { ...team, created_by: operator.id, updated_by: operator.id }

            let formData = new FormData();
            formData.append("active", team.active)
            formData.append("code", team.code)
            formData.append("name", team.name)
            formData.append("logo_url", team.logo_url)
            // formData.append("stadium_id", parseInt(team.stadium_id))
            formData.append("country_id", parseInt(team.country_id))
            formData.append("league_id", JSON.stringify(this.state.selectedLeague))
            formData.append("created_by", parseInt(operator.id))
            formData.append("updated_by", parseInt(operator.id))
            if (data.id_from_fotmob !== '') {
                formData.append("id_from_fotmob", parseInt(team.id_from_fotmob))
            }
            if (data.stadium_id) {
                formData.append("stadium_id", parseInt(team.stadium_id))
            }
            if (team.name === '' || team.code === '' || team.league_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (team.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else if (team.code.length > 3) {
                this.setState({
                    requiredError: "Team code character length must be less than 3"
                })
            }
            else {
                this.props.createTeam(formData).then(() => {
                    this.props.getTeams(this.state.initialPage, this.state.rowCount)
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
}
export {
    TeamListController
}