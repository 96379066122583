import React, { Component } from 'react'
import { axios, CONSTANTS, Swal } from '../../../components/ImportsFile'

class PrizeListController extends Component {
  constructor(props) {
    super(props)


    this.state = {
      show: false,
      searchTerm: '',
      leagueId: '',
      expired_time: '',
      text: '',
      isEdit: false,
      initialPage: 1,
      prize: {},
      requiredError: '',
      rowCount: CONSTANTS.ROW_COUNT,
      leagueRowCount: CONSTANTS.ROW_COUNT,
    }
  }

  componentDidMount = () => {
    this.loadMoreLeague();
    this.props.getPrizes(this.state.initialPage, this.state.rowCount);
  }
  stopSearching = () => {
    this.setState({
      leagueId: '',
      text: '',
      expired_time: ''
    })
    this.props.getPrizes(this.state.initialPage, this.state.rowCount);
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value,
      leaugeId: '',
      expired_time: ''
    }, () => {
      this.props.searchItem(this.state.text, this.state.leagueId, this.state.expired_time, this.state.initialPage)
    })
  }
  handleSelectLeagueId = (leagueId) => {
    this.setState({
      leagueId: leagueId,
      text: '',
      expired_time: ''
    }, () => {
      this.props.searchItem(this.state.text, this.state.leagueId, this.state.expired_time, this.state.initialPage)
    })
  }
  ExpiredTime = (e) => {
    this.setState({
      expired_time: e.target.value,
      text: '',
      leagueId: ''
    }, () => {
      this.props.searchItem(this.state.text, this.state.leagueId, this.state.expired_time, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchItem(this.state.text, this.state.leagueId, this.state.expired_time, this.state.initialPage)
  }
  handleEdit = async (prize) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      prize: {
        ...prevState.prize,
        prize
      }
    }))
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }

  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }

  loadMoreLeague = () => {
    this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
    this.setState({
      leagueRowCount: this.state.leagueRowCount + 6
    })
  }

  deletePrize = (id) => {
    let data = [];

    axios.post(`${CONSTANTS.BASE_URL}prize_histories/list?prize_id=${id}`).then((response) => {
      console.log("response", response)
      data = [...response?.data?.data]

      if (data.length > 0) {
        Swal.fire({
          title: "Can't delete this prize!",
          icon: 'warning',
        })
      }
      else {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.deletePrize(id)
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
        })
      }
    })
  }
  createOrUpdate = (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    let prize = {
      exchangeable_points: data.exchangeable_points,
      limit_count: data.limit_count,
      name: data.name,
      type: data.type,
      active: data.active,
      updated_by: operator.id,
    }

    if (data.image) {
      prize = { ...prize, image: data.image }
    }
    if (data.description) {
      prize = { ...prize, description: data.description }
    }
    if (data.league_id) {
      prize = { ...prize, league_id: data.league_id }
    }
    if (data.expired_time) {
      prize = { ...prize, expired_time: data.expired_time }
      // prize = { ...prize, expired_time: new Date(data.expired_time).getTime() }
    }
    if (this.state.isEdit) {
      prize = { ...prize, id: data.id }
      let formData = new FormData();
      formData.append("id", prize.id)
      formData.append("name", prize.name)
      formData.append("exchangeable_points", prize.exchangeable_points)
      formData.append("limit_count", prize.limit_count)
      formData.append("type", prize.type)
      formData.append("active", prize.active)
      formData.append("updated_by", parseInt(operator.id))
      // formData.append("image", prize.image)

      if (data.description) {
        formData.append("description", prize.description)
      }
      if (data.league_id) {
        formData.append("league_id", prize.league_id)
      }
      if (data.expired_time) {
        formData.append("expired_time", prize.expired_time)
      }
      if (typeof prize.image !== "string" && prize.image) {
        formData.append("image", prize.image)
      }
      if (prize.name === '' || prize.exchangeable_points === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (prize.image === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.updatePrize(formData).then(() => {
          this.props.getPrizes(this.props.currentPage, this.state.rowCount);
        })
        this.hideModal();
      }

    } else {
      prize = { ...prize, created_by: operator.id, updated_by: operator.id }
      let formData = new FormData();
      formData.append("exchangeable_points", prize.exchangeable_points)
      formData.append("limit_count", prize.limit_count)
      formData.append("name", prize.name)
      formData.append("active", prize.active)
      formData.append("type", prize.type)
      formData.append("created_by", parseInt(operator.id))
      formData.append("updated_by", parseInt(operator.id))
      if (data.image) {
        formData.append("image", prize.image)
      }
      if (data.description) {
        formData.append("description", prize.description)
      }
      if (data.league_id) {
        formData.append("league_id", prize.league_id)
      }
      if (data.expired_time) {
        formData.append("expired_time", prize.expired_time)
      }
      if (prize.name === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (prize.image === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.createPrize(formData).then(() => {
          this.props.getPrizes(this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }
}

export default PrizeListController