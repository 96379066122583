import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';

class OddsListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      searchTerm: '',
      text: '',
      isEdit: false,
      initialPage: 1,
      requiredError: '',
      type_for_matches: [],
      rowCount: CONSTANTS.ROW_COUNT,
      odds_rate: {},
      quizInfo: {},
      specialQuizDetailId: '',
    }
  }
  componentDidMount = async () => {
    let id = this.props.match.params.id
    await axios.post(`${CONSTANTS.BASE_URL}special_quizzes/detail`, { id: id }).then(response => {
      let value = response.data.data
      if (id == value.id) {
        this.setState({
          quizInfo: value,
        })
      }
    })
    let specialQuizDetailId = this.state.quizInfo?.SpecialQuizDetails && this.state.quizInfo?.SpecialQuizDetails[0]?.id
    this.setState({
      specialQuizDetailId: specialQuizDetailId
    })

    this.props.getOddsRates(specialQuizDetailId, this.state.initialPage, this.state.rowCount).then(() => {
      const typeForMatch = [];
      this.props.odds_rates.map((odd) => {
        typeForMatch.push(odd.type_for_match)
      })
      this.setState({
        type_for_matches: typeForMatch
      })
    });

  }
  stopSearching = () => {
    this.props.getOddsRates('', this.state.initialPage, this.state.rowCount);
    // this.setState({
    //   text: ''
    // })
  }
  // handleInputText = (e) => {
  //   this.setState({
  //     text: e.target.value
  //   })
  // }
  // handleSearch = (e) => {
  //   e.preventDefault();
  //   this.props.searchItem(this.state.text, this.state.initialPage)
  // }
  handleEdit = (odds_rate) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      odds_rate: {
        ...prevState.odds_rate,
        odds_rate
      }
    }))
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }

  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }

  createOrUpdate = (data) => {

    let operator = JSON.parse(localStorage.getItem('operator'));
    let odds_rate = {
      special_quiz_detail_id: this.state.quizInfo?.SpecialQuizDetails[0]?.id,
      odds_american: data.odds_american,
      odds_fractional: data.odds_fractional,
      odds_decimal: data.odds_decimal,
      main_odds_type: data.main_odds_type,
      type_for_match: data.type_for_match,
      active: data.active,
      updated_by: operator.id,
    }

    if (this.state.isEdit) {
      odds_rate = { ...odds_rate, id: data.id }

      if (odds_rate.type_for_match === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        // this.props.updateOddsRate(odds_rate);
        this.props.updateOddsRate(odds_rate).then(() => {
          this.props.getOddsRates(this.state.specialQuizDetailId, this.state.initialPage, this.state.rowCount)
        })
        this.hideModal();
      }

    } else {
      odds_rate = { ...odds_rate, created_by: operator.id, updated_by: operator.id }

      if (this.state.type_for_matches.includes(odds_rate.type_for_match)) {
        this.setState({
          requiredError: "This type is already exist"
        })
      }
      else if (odds_rate.type_for_match === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        // this.props.createOddsRate(odds_rate);
        this.props.createOddsRate(odds_rate).then(() => {
          this.props.getOddsRates(this.state.specialQuizDetailId, this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }
}

export default OddsListController
