const SpecialQuizDetailsType = {
  GET_SPECIAL_QUIZ_DETAILS_LISTS: "GET_SPECIAL_QUIZ_DETAILS_LISTS",
  ADD_SPECIAL_QUIZ_DETAILS: "ADD_SPECIAL_QUIZ_DETAILS",
  UPDATE_SPECIAL_QUIZ_DETAILS: "UPDATE_SPECIAL_QUIZ_DETAILS",
  DELETE_SPECIAL_QUIZ_DETAILS: "DELETE_SPECIAL_QUIZ_DETAILS",
  SPECIAL_QUIZ_DETAILS_ERROR: "SPECIAL_QUIZ_DETAILS_ERROR",
  SPECIAL_QUIZ_DETAILS_SUCCESS: "SPECIAL_QUIZ_DETAILS_SUCCESS",
  SEARCH_WITH_SPECIAL_QUIZ_ID: "SEARCH_WITH_SPECIAL_QUIZ_ID",
  SEARCH_WITH_TYPE: "SEARCH_WITH_TYPE",
  SEARCH_WITH_QUESTION_ID: "SEARCH_WITH_QUESTION_ID",
  GET_FUTURE_DETAILS: "GET_FUTURE_DETAILS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  SEARCH_WITH_MATCH: 'SEARCH_WITH_MATCH',
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  TOGGLE_EDIT:"TOGGLE_EDIT",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default SpecialQuizDetailsType