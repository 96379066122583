import React, { Component } from 'react'

class UserTeamActivityCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user_team_activity: props.isEdit === false ? {
                user_favourite_team_id: '',
                for_team_watch_count: '',
                for_team_quizzes_count: '',
                total_count: '',
                active: 1,
            } : props.user_team_activity.user_team_activity
        }
    }

    ForTeamWatchCount = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user_team_activity: {
                ...prevState.user_team_activity,
                for_team_watch_count: e.target.value
            }
        }))
    }
    ForTeamQuizCount = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user_team_activity: {
                ...prevState.user_team_activity,
                for_team_quizzes_count: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            user_team_activity: {
                ...prevState.user_team_activity,
                active: e.target.value
            }
        }))
    }

    UserFavoruiteTeamId = (user_favourite_team_id) => [
        this.setState((prevState) => ({
            ...prevState,
            user_team_activity: {
                ...prevState.user_team_activity,
                user_favourite_team_id: user_favourite_team_id
            }
        }))
    ]

}

export default UserTeamActivityCreateController 