
import QuizAnswerType from './../type/quiz_answer_type';
const initialState = {
    quiz_answers: [],
    error: null,
    success: null,
    currentPage: 1,
    quizDetailId: '',
    quizId:'',
    userId:'',
    totalCount: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const quizAnswerReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case QuizAnswerType.GET_QUIZ_ANSWERS:
            return {
                ...state,
                loading: false,
                quiz_answers: actions.payload
            }
        case QuizAnswerType.ADD_QUIZ_ANSWER:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                quiz_answers: [
                    actions.payload,
                    ...state.quiz_answers,
                ],

            }
        case QuizAnswerType.UPDATE_QUIZ_ANSWER:
            const updateQuizAnswer = state.quiz_answers.filter(answer => answer.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                quiz_answers: [
                    actions.payload,
                    ...updateQuizAnswer,
                ]
            }
        case QuizAnswerType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case QuizAnswerType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case QuizAnswerType.SERCH_WITH_QUIZ_DETAIL_ID:
            return {
                ...state,
                quizDetailId: actions.payload
            }
        case QuizAnswerType.SERCH_WITH_QUIZ_ID:
            return {
                ...state,
                quizId: actions.payload
            }
        case QuizAnswerType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case QuizAnswerType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }

        case QuizAnswerType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }

        case QuizAnswerType.QUIZ_ANSWER_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case QuizAnswerType.QUIZ_ANSWER_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default quizAnswerReducer