import React, { Component } from 'react';
import Moment from 'react-moment';
import '../../components/selectModal/SelectModal.css'
import Modal from 'react-modal'
import { Button, moment, Pagination, styled, FontAwesomeIcon, faArrowAltCircleRight, faCaretDown } from '../../components/ImportsFile'
import Search from './../../components/search/Search';
class SelectModalForAnswer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showHomeTeamPlayer: true,
      showAwayTeamPlayer: false,
      searchTerm: '',
      initialPage: 1,
      teamId: '',
      selectedValue: props.itemName,
      // routeChange: props.routeChangeName
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount = () => {
    this.props.getPlayers && this.props.getPlayers(this.state.teamId, this.state.initialPage, this.props.playerRowCount)
    this.props.searchWithLeague && this.props.searchWithLeague(this.props.leagueId, '', this.props.rowCount, this.state.initialPage)
  }
  showHomeTeamPlayer = () => {
    this.setState({
      showHomeTeamPlayer: true,
      showAwayTeamPlayer: false
    })
  }
  showAwayTeamPlayer = () => {
    this.setState({
      showHomeTeamPlayer: false,
      showAwayTeamPlayer: true
    })
  }
  handleSelect = (id, name) => {

    this.props.handleProcess && this.props.handleProcess(id);
    this.props.handleProcessName && this.props.handleProcessName(name);

    this.setState(state => ({
      ...state,
      selectedValue: name,
      show: false,
    }))

  }
  handleInputSearch = (e) => {
    this.setState({
      searchTerm: e.target.value
    })
  }
  handleSelectTeamId = (teamId) => {
    this.setState({
      teamId
    }, () => {
      this.props.searchPlayer(this.state.searchTerm, '', this.props.leagueId, this.state.teamId, this.props.playerRowCounts, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searching && this.props.searching(this.state.searchTerm, this.props.currentPage)

  }
  handleSearchPlayer = (e) => {
    e.preventDefault();
    this.props.searchPlayer(this.state.searchTerm, '', this.props.leagueId, this.state.teamId, this.props.playerRowCounts, this.state.initialPage)
  }
  handleSearchTeam = (e) => {
    e.preventDefault();
    this.props.searching(this.props.leagueId, this.state.searchTerm, this.props.leagueId, this.state.teamId, this.props.playerRowCounts, this.state.initialPage)
  }
  stopSearching = () => {
    this.props.getItems && this.props.getItems(this.state.initialPage, this.props.rowCount)
    this.props.searchWithLeagueAndTeamName && this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.props.rowCount, this.state.initialPage)

    this.setState({
      searchTerm: ''
    })
  }
  ShowModal = () => {
    this.setState({
      show: !this.props.disabled && true
    })

  }

  render() {

    return (
      <>

        {((this.props.answerForPlayers || this.props.allPlayers) && !this.props.answerForTeams) &&
          <div style={{ background: 'red' }}>
            <div className="selectbox" name="selectedValue" onChange={this.handleSelect}
              //  onClick={!this.props.disabled && this.ShowModal}
              onClick={this.ShowModal}
            >
              {this.state.selectedValue}
              <span>  <FontAwesomeIcon icon={faCaretDown} /> </span>
            </div>
            {
              this.state.show && <Modal className="createSelectModal createSelectModalforAns"
                ariaHideApp={false}
                isOpen={this.state.show}
                onRequestClose={() => this.setState({ show: false })}
                shouldCloseOnOverlayClick={true}
                style={
                  {
                    overlay: {
                      background: 'transparent'
                    }
                  }
                }
              >
                <p className="close" onClick={() => this.setState({ show: false })}>X</p>

                <div style={{ width: '100%' }}>
                  <form onSubmit={this.handleSearchPlayer} className="search search_select_team">


                    {
                      this.props.quizType === 2 &&
                      <div className="input-group">
                        <Search
                          searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                          stopSearching={() => this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.props.teamRowCount, this.props.teamCurrentPage)}
                          style={{ width: '50%' }}
                          text={"Team"}
                          search={true}
                          teams={this.props.teams}
                          leagueId={this.props.leagueId}
                          searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                          currentPage={this.props.teamCurrentPage}
                          getItems={() => this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.props.teamRowCount, this.props.teamCurrentPage)}
                          rowCount={this.props.teamRowCount}
                          LoadMore={this.props.loadMoreTeams}
                        />
                        <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                        <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        <button type="submit"><i className="fa fa-search"></i></button>
                      </div>
                    }


                  </form>
                </div>

                <ItemsCard className="item-lists choose_item_card">
                  {

                    // for player
                    (this.props.allPlayers) && this.props.allPlayers.map((player, index) => {
                      return (
                        <Items key={index} >
                          <div className="img">
                            <img src={player.image_url} alt={player.name} style={{ width: "30px" }} />
                          </div>
                          <div className="name">
                            <span> {player.name}</span>
                          </div>
                          <div className="team">
                            <span>{player?.Team?.name}</span>
                          </div>
                          <div className="selectBtn">
                            <Button caption='Select' className="btn-success" btnFun={() => {
                              this.handleSelect(player.id, player.name)
                            }} />

                          </div>
                        </Items>
                      )

                    })
                  }
                  {

                    // for player
                    (this.props.answerForPlayers) && this.props.answerForPlayers.map((player, index) => {
                      return (
                        <Items key={index} >
                          <div className="img">
                            <img src={player.image_url} alt={player.name} style={{ width: "30px" }} />
                          </div>
                          <div className="name">
                            <span> {player.name}</span>
                          </div>
                          <div className="team">
                            <span>{player?.Team?.name}</span>
                          </div>
                          <div className="selectBtn">
                            <Button caption='Select' className="btn-success" btnFun={() => {
                              this.handleSelect(player.id, player.name)
                            }} />

                          </div>
                        </Items>
                      )

                    })
                  }

                </ItemsCard>
                <Pagination hideShowingTotalCount={true} activePage={this.props.playerCurrentPage} totalItemsCount={this.props.playerTotalCount} totalCountPerPage={this.props.playerTotalCountPerPage} paginate={(pageNumber) => {
                  this.props.text ? this.props.searchPlayer(this.props.text, '', '', '', this.props.playerRowCount, pageNumber) : this.props.getPlayers(this.state.teamId, pageNumber, this.props.playerRowCount)
                }} />
              </Modal>
            }
          </div>
        }

        {(this.props.answerForTeams && !this.props.answerForPlayers) &&
          <div>
            <div className="selectbox" name="selectedValue" onChange={this.handleSelect}
              //  onClick={!this.props.disabled && this.ShowModal}
              onClick={this.ShowModal}
            >
              {this.state.selectedValue}
              <span>  <FontAwesomeIcon icon={faCaretDown} /> </span>
            </div>
            {
              this.state.show && <Modal className="createSelectModal createSelectModalforAns"
                ariaHideApp={false}
                isOpen={this.state.show}
                onRequestClose={() => this.setState({ show: false })}
                shouldCloseOnOverlayClick={true}
                style={
                  {
                    overlay: {
                      background: 'transparent'
                    }
                  }
                }
              >
                <p className="close" onClick={() => this.setState({ show: false })}>x</p>

                {/**
                <div style={{ width: '100%' }}>
                <form onSubmit={this.handleSearchTeam} className="search search_select_team">
                
                <div className="input-group">
                <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                <button type="submit"><i className="fa fa-search"></i></button>
                </div>
                </form>
                </div>
              */}

                <div className="item-lists">
                  {
                    this.props.answerForTeams && this.props.answerForTeams.map((team, index) => {
                      return (
                        <p key={index} onClick={() => this.handleSelect(team.id, team?.name)}>{team?.name}</p>
                      )
                    })
                  }

                </div>
                <Pagination hideShowingTotalCount={true} activePage={this.props.playerCurrentPage} totalItemsCount={this.props.playerTotalCount} totalCountPerPage={this.props.playerTotalCountPerPage} paginate={(pageNumber) => {
                  this.props.text ? this.props.searchPlayer(this.props.text, '', '', '', this.props.playerRowCount, pageNumber) : this.props.getPlayers(this.state.teamId, pageNumber, this.props.playerRowCount)
                }} />


                {
                  (this.props.teams && this.props.teams.length > 5) &&
                  <span style={{ background: '#fad230', width: '100%', padding: '10px' }} onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                }

              </Modal>
            }
          </div>}


        {
          (!this.props.answerForPlayers && !this.props.answerForTeams) &&
          <div>
            <div className="selectbox" name="selectedValue" onChange={this.handleSelect}
              //  onClick={!this.props.disabled && this.ShowModal}
              onClick={this.ShowModal}
            >
              {this.state.selectedValue}
              <span>  <FontAwesomeIcon icon={faCaretDown} /> </span>
            </div>
            {
              this.state.show && <Modal className="createSelectModal"
                ariaHideApp={false}
                isOpen={this.state.show}
                onRequestClose={() => this.setState({ show: false })}
                shouldCloseOnOverlayClick={true}
                style={
                  {
                    overlay: {
                      background: 'transparent'
                    }
                  }
                }
              >
                <p className="close" onClick={() => this.setState({ show: false })}>x</p>

                <div className="item-lists" >
                  {
                    (this.props.allPlayers && this.props.allPlayers.length > 0) && this.props.allPlayers.map((item, index) => {
                      return (
                        <p key={index} onClick={() => {
                          this.handleSelect(item.id, item.name)
                        }} >{item.name}</p>
                      )
                    })

                  }
                  {
                    (this.props.home_team_players || this.props.away_team_players) &&
                    <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-around', width: '100%' }}>
                      <Button caption={"Home Team Players"} className="btn-primary" btnFun={() => this.showHomeTeamPlayer()} />

                      <Button caption={"Away Team Players"} className="btn-secondary" btnFun={() => this.showAwayTeamPlayer()} />

                      {
                        this.props.question_type == 2 &&
                        <Button caption={"No Scorer"} className="btn-info" btnFun={() => this.handleSelect(0, "No Scorer")} />
                      }
                      {
                        this.props.question_type == 3 &&
                        <Button caption={"No Yellow Card"} className="btn-info" btnFun={() => this.handleSelect(0, "No Yellow Card")} />
                      }
                      {
                        this.props.question_type == 4 &&
                        <Button caption={"No Red Card"} className="btn-info" btnFun={() => this.handleSelect(0, "No Red Card")} />
                      }
                    </div>
                  }
                  {
                    (this.props.home_team_players && this.props.home_team_players.length > 0 && this.state.showHomeTeamPlayer) && this.props.home_team_players.map((item, index) => {
                      return (
                        <p key={index} onClick={() => {
                          this.handleSelect(item.id, item.name)
                        }} >{item.name}</p>
                      )
                    })

                  }
                  {
                    (this.props.away_team_players && this.props.away_team_players.length > 0 && this.state.showAwayTeamPlayer) && this.props.away_team_players.map((item, index) => {
                      return (
                        <p key={index} onClick={() => {
                          this.handleSelect(item.id, item.name)
                        }} >{item.name}</p>
                      )
                    })

                  }
                  {
                    // (this.props.items && this.props.items.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                  }


                </div>
              </Modal>
            }
          </div>}

      </>
    )
  }
}

export default SelectModalForAnswer
const ItemsCard = styled.div`
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:row;
    width: 100%;
    background-color: #eeeded;
`
const Items = styled.div`
  display:flex;
  width:100%;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:10px 20px 10px 20px;

  .selectBtn{
    text-align:left;
    min-width:10%;
  }
  .img{
    text-align:left;
    min-width:15%;
  }
  .name, .team{
    text-align:left;
    min-width:35%;
  }
`