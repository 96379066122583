import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'

class HighlightListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      highlight: {},
      matchForHighlight: {},
      rowCount: CONSTANTS.ROW_COUNT,
      leagueRowCount: CONSTANTS.ROW_COUNT,
      matchRowCount: CONSTANTS.ROW_COUNT,
      leagueId: '',
      matchId: '',
      requiredError: '',
    }
  }
  componentDidMount = () => {
    this.loadMore();
    this.loadMoreLeague();
    this.loadMoreMatch();
    axios.post(`${CONSTANTS.BASE_URL}highlights/list?page_at=${this.state.initialPage}&row_count=${this.state.rowCount}`).then(response => {
      this.setState({
        matchForHighlight: response.data.data
      })
    })
    this.props.getHighlight(this.state.initialPage, this.state.rowCount);
  }
  stopSearching = () => {
    this.props.getHighlight(this.state.initialPage, this.state.rowCount);
    this.setState({
      matchId: '',
      leagueId: '',
    })
  }
  handleSelectLeague = (leagueId) => {
    this.setState({
      leagueId,
      matchId: '',
      text: ''
    }, () => {
      this.props.searchItem(this.state.matchId, this.state.leagueId, this.state.initialPage)
    })
  }
  handleSelectMatch = (matchId) => {
    this.setState({
      matchId,
      leagueId: '',
      text: ''
    }, () => {
      this.props.searchItem(this.state.matchId, this.state.leagueId, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchItem(this.state.matchId, this.state.leagueId, this.state.initialPage)
  }
  loadMore = () => {
    this.setState({
      rowCount: this.state.rowCount + 6
    })
    this.props.getAllMatches(this.state.initialPage, this.state.rowCount);
  }
  loadMoreLeague = () => {
    this.setState({
      leagueRowCount: this.state.leagueRowCount + 6
    })
    this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
  }
  loadMoreMatch = () => {
    this.setState({
      matchRowCount: this.state.matchRowCount + 6
    })
    this.props.getAllMatches(this.state.initialPage, this.state.matchRowCount);
  }

  createOrUpdate = async (data) => {
    let headers = {};

    let highlight = {
      match_id: data.match_id,
      league_id: data.league_id,
      // headers: JSON.stringify(headers),
      highlight_url: data.highlight_url,
      home_team_goal: data.home_team_goal,
      away_team_goal: data.away_team_goal,
      type: data.type,
      active: data.active
    }
    if (this.state.isEdit) {
      data.headers.forEach((header => {
        headers[header.headerKey] = header.headerValue
      }
      ))
      highlight = {
        ...highlight, id: data.id,
        headers: JSON.stringify(headers)
      }
      if (highlight.match_id === '' || highlight.league_id === '' || highlight.highlight_url === '' || highlight.home_team_goal === '' || highlight.away_team_goal === '' || highlight.type === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateHighlight(highlight).then(() => {
          this.props.getHighlight(this.props.currentPage, this.state.rowCount);
        })
        this.hideModal();
      }
    }
    else {
      data.headers.forEach((header => {
        headers[header.headerKey] = header.headerValue
      }
      ))

      highlight = { ...highlight, headers: JSON.stringify(headers) }

      if (highlight.match_id === '' || highlight.league_id === '' || highlight.highlight_url === '' || highlight.home_team_goal === '' || highlight.away_team_goal === '' || highlight.type === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createHighlight(highlight).then(() => {
          this.props.getHighlight(this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal()
      }
    }


  }
  handleEdit = (highlight) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        show: true,
        isEdit: true,
        highlight: {
          ...prevState.highlight,
          highlight,
        }
      }
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
  hideModal = () => {
    this.setState({
      show: false
    })
  }

}

export default HighlightListController