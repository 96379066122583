
import TransactionType from './../type/transaction_type';
const initialState = {
    transactions: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    userId: '',
    payTypeId: '',
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const transactionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case TransactionType.GET_TRANSACTIONS:
            return {
                ...state,
                loading: false,
                transactions: actions.payload
            }
        case TransactionType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case TransactionType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case TransactionType.SEARCH_WITH_USER:
            return {
                ...state,
                userId: actions.payload
            }
        case TransactionType.SEARCH_WITH_PAYTYPE:
            return {
                ...state,
                payTypeId: actions.payload
            }
        case TransactionType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case TransactionType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case TransactionType.AMOUNTOUT_TRANSACTION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                transactions: [
                    actions.payload,
                    ...state.transactions,
                ]
            }
        case TransactionType.AMOUNTIN_TRANSACTION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                transactions: [
                    actions.payload,
                    ...state.transactions,
                ]
            }
        case TransactionType.REMOVE_POINTS_TRANSACTION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                transactions: [
                    actions.payload,
                    ...state.transactions,
                ]
            }
        case TransactionType.ADD_POINTS_TRANSACTION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                transactions: [
                    actions.payload,
                    ...state.transactions,
                ]
            }
        case TransactionType.UPDATE_TRANSACTION:
            const updateTransactions = state.transactions.filter(transaction => transaction.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                transactions: [
                    actions.payload,
                    ...updateTransactions,
                ]
            }
        case TransactionType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case TransactionType.DELETE_TRANSACTION:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                transactions: state.transactions.filter(transaction => transaction.id !== actions.payload)
            }
        case TransactionType.TRANSACTION_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case TransactionType.TRANSACTION_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state;
    }
}
export default transactionReducer