import { Component } from 'react';

class StadiumCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stadium: props.isEdit === false ? {
                name: '',
                total_seats: '',
                active: 1
            } : props.stadium.stadium
        }
    }
    processActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            stadium: {
                ...prevState.stadium,
                active: e.target.value
            }
        }))
    }
    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            stadium: {
                ...prevState.stadium,
                name: e.target.value
            }
        }))
    }
    handleInputSeat = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            stadium: {
                ...prevState.stadium,
                total_seats: e.target.value
            }
        }))
    }
}

export {
    StadiumCreateController
}