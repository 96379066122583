import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class TransactionsListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: true,
            show: false,
            isEdit: false,
            initialPage: 1,
            transaction: {},
            id: '',
            userRowCount: CONSTANTS.ROW_COUNT,
            payTypeRowCount: CONSTANTS.ROW_COUNT,
            rowCount: CONSTANTS.ROW_COUNT,
            userId: '',
            payment_type_id: '',
            requiredError: ''
        }
        this.handleSwitch = this.handleSwitch.bind(this)
    }
    componentDidMount = () => {
        this.loadMore1();
        this.loadMore2();
        this.props.getTransactions(this.state.initialPage, this.state.rowCount);
        // this.props.walletSuccess && localStorage.setItem('noti', 'noti')
    }
    stopSearching = () => {
        this.setState({
            userId: '',
            payment_type_id: ''
        })
        this.props.getTransactions(this.state.initialPage, this.state.rowCount)
    }
    handleSelectUser = (userId) => {
        this.setState({
            userId,
            // payment_type_id: ''
        }, () => {
            this.props.searchItem(this.state.userId, this.state.payment_type_id, this.state.initialPage)
        })
    }

    handleSelectPayType = (payment_type_id) => {
        this.setState({
            payment_type_id,
            // userId: ''
        }, () => {
            this.props.searchItem(this.state.userId, this.state.payment_type_id, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.userId, this.state.payment_type_id, this.state.initialPage)
    }
    loadMore1 = () => {
        this.setState({
            userRowCount: this.state.userRowCount + 6
        })
        this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
    }
    loadMore2 = () => {
        this.setState({
            payTypeRowCount: this.state.payTypeRowCount + 6
        })
        this.props.getAllPaymentTypes(this.state.initialPage, this.state.payTypeRowCount);
    }

    handleSwitch(checked) {
        this.setState({ checked })
    }
    handleEdit = (transaction) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            transaction: {
                ...prevState.transaction,
                transaction
            }
        }))
    }
    createOrUpdate = async (data) => {
        const operator = JSON.parse(localStorage.getItem('operator'))
        let transaction;
        transaction = {
            payment_type_id: data.payment_type_id,
            user_id: data.user_id,
            active: data.active,
        }
        if (data.description) {
            transaction = { ...transaction, description: data.description }
        }
        if (data.add_points) {
            transaction = { ...transaction, add_points: data.add_points }
        }
        if (data.remove_points) {
            transaction = { ...transaction, remove_points: data.remove_points }
        }
        if (data.amount_in) {
            transaction = { ...transaction, amount_in: data.amount_in }
        }
        if (data.amount_out) {
            transaction = { ...transaction, amount_out: data.amount_out }
        }
        if (data.payment_screenshot) {
            transaction = { ...transaction, payment_screenshot: data.payment_screenshot }
        }
        if (this.state.isEdit) {

            transaction = { ...transaction, id: data.id, updated_by: operator.id }

            let formData = new FormData();
            formData.append("id", transaction.id)
            formData.append("payment_type_id", transaction.payment_type_id)
            formData.append("user_id", parseInt(transaction.user_id))
            formData.append("active", parseInt(transaction.active))
            formData.append("updated_by", parseInt(operator.id))
            if (data.description) {
                formData.append("description", transaction.description)
            }
            if (data.amount_in) {
                formData.append("amount_in", parseInt(transaction.amount_in))
            }
            if (data.amount_out) {
                formData.append("amount_out", parseInt(transaction.amount_out))
            }
            if (data.add_points) {
                formData.append("add_points", parseInt(transaction.add_points))
            }
            if (data.remove_points) {
                formData.append("remove_points", parseInt(transaction.remove_points))
            }
            if (typeof data.payment_screenshot !== "string") {
                formData.append("payment_screenshot", transaction.payment_screenshot)
            }
            // if (data.payment_screenshot) {
            //     formData.append("payment_screenshot", transaction.payment_screenshot)
            // }

            if (transaction.payment_type_id === '' || transaction.user_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateTransaction(formData).then(() => {
                    this.props.getTransactions(this.props.currentPage, this.state.rowCount);
                })
                this.hideModal();
            }

            // if (!this.state.checked) {
            //     transaction = { ...transaction, created_by: operator.id }
            //     // this.props.amountInTransaction(transaction)
            //     this.props.addPointsTransaction(transaction)
            //     let wallet = {
            //         id: data.user_id,
            //         // amount: transaction.amount_in,
            //         total_points: transaction.add_points,
            //         is_remove_point: this.state.checked
            //     }
            //     this.props.updateWallet(wallet)
            // }
            // else {
            //     // this.props.amountOutTransaction(transaction)
            //     this.props.removePointsTransaction(transaction)
            //     let wallet = {
            //         id: data.user_id,
            //         total_points: transaction.remove_points,
            //         // amount: transaction.amount_out,
            //         is_remove_point: this.state.checked
            //     }
            //     this.props.updateWallet(wallet)
            // }

        }
        else {

            if (this.state.checked) {

                transaction = { ...transaction, amount_in: data.amount_in, add_points: data.add_points, created_by: operator.id, updated_by: operator.id }
                let formData = new FormData();
                formData.append("amount_in", transaction.amount_in)
                formData.append("add_points", transaction.add_points)
                formData.append("payment_type_id", transaction.payment_type_id)
                formData.append("user_id", parseInt(transaction.user_id))
                formData.append("active", parseInt(transaction.active))
                formData.append("created_by", parseInt(operator.id))
                formData.append("updated_by", parseInt(operator.id))
                if (data.description) {
                    formData.append("description", transaction.description)
                }
                if (data.payment_screenshot) {
                    formData.append("payment_screenshot", transaction.payment_screenshot)
                }
                if (transaction.payment_type_id === '' || transaction.user_id === '' || transaction.cmount === '' || transaction.add_points === '') {
                    this.setState({
                        requiredError: "All fields are required"
                    })
                }
                else {
                    this.props.addPointsTransaction(formData).then(() => {
                        this.props.getTransactions(this.state.initialPage, this.state.rowCount);
                    })
                    this.setState({
                        requiredError: ''
                    })
                    this.hideModal();
                }
            }
            else {
                transaction = { ...transaction, amount_out: data.amount_out, remove_points: data.remove_points, created_by: operator.id, updated_by: operator.id }
                let formData = new FormData();
                formData.append("amount_out", transaction.amount_out)
                formData.append("remove_points", transaction.remove_points)
                formData.append("payment_type_id", transaction.payment_type_id)
                formData.append("user_id", parseInt(transaction.user_id))
                formData.append("active", parseInt(transaction.active))
                formData.append("created_by", parseInt(operator.id))
                formData.append("updated_by", parseInt(operator.id))
                if (data.description) {
                    formData.append("description", transaction.description)
                }
                if (data.payment_screenshot) {
                    formData.append("payment_screenshot", transaction.payment_screenshot)
                }
                if (transaction.payment_type_id === '' || transaction.user_id === '' || transaction.amount_out === '' || transaction.remove_points === '') {
                    this.setState({
                        requiredError: "All fields are required"
                    })
                }
                // this.props.amountOutTransaction(transaction)
                if (transaction.payment_type_id === '' || transaction.user_id === '' || transaction.amount_out === '' || transaction.remove_points === '') {
                    this.setState({
                        requiredError: "All fields are required"
                    })
                }
                else {
                    this.props.removePointsTransaction(formData).then(() => {
                        this.props.getTransactions(this.state.initialPage, this.state.rowCount);
                    })
                    this.setState({
                        requiredError: ''
                    })
                    this.hideModal();
                }
            }

        }
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }

    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
}
export {
    TransactionsListController
}




