import axios from 'axios'
import CONSTANTS from './../constants';
import UserTeamActivityType from './../type/user_team_activity_type';

export const Error = (data) => {
    return {
        type: UserTeamActivityType.USER_TEAM_ACTIVITY_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: UserTeamActivityType.USER_TEAM_ACTIVITY_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: UserTeamActivityType.LOADING, paylaod: status })
    }
}
const getUserTeamActivity = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user-team-activities/list?page_at=${pageNumber}&row_count=${row_count}&order_by=DESC&sort_by=total_count`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserTeamActivityType.GET_USER_TEAM_ACTIVITIES, payload: response.data.data
                });
                dispatch({
                    type: UserTeamActivityType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                });
                dispatch({
                    type: UserTeamActivityType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                });
                dispatch({
                    type: UserTeamActivityType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                });
                dispatch({
                    type: UserTeamActivityType.ROW_COUNT, payload: row_count
                });
            }
            else {
                let message = "Failed"
                dispatch({
                    type: UserTeamActivityType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserTeamActivityType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createUserTeamActivity = (user_team_activity) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}user-team-activities/create`, user_team_activity).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserTeamActivityType.ADD_USER_TEAM_ACTIVITY, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateUserTeamActivity = (user_team_activity) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}user-team-activities/update`, user_team_activity).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserTeamActivityType.UPDATE_USER_TEAM_ACTIVITY, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}

const deleteUserTeamActivity = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}user-team-activities/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: UserTeamActivityType.DELETE_USER_TEAM_ACTIVITY, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: UserTeamActivityType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserTeamActivityType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (teamId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user-team-activities/list?team_id=${teamId}&page_at=${pageNumber}`).then(response => {
            dispatch({
                type: UserTeamActivityType.GET_USER_TEAM_ACTIVITIES, payload: response.data.data
            })
            dispatch({
                type: UserTeamActivityType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
            });
            dispatch({
                type: UserTeamActivityType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
            });
            dispatch({
                type: UserTeamActivityType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
            });
            dispatch({
                type: UserTeamActivityType.SEARCH_WITH_TEAM,
                payload: teamId
            });
            // dispatch({
            //     type: UserTeamActivityType.SEARCH_ITEM,
            //     payload: text
            // });

        })
    }
}
const UserTeamActivityAction = {
    getUserTeamActivity,
    createUserTeamActivity,
    updateUserTeamActivity,
    deleteUserTeamActivity,
    searchItem,
}
export default UserTeamActivityAction