import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class FavouriteTeamLevelRuleListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            favourite_team_level_rule: {},
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            requiredError: '',

        }
    }

    componentDidMount = () => {
        this.props.getFavouriteTeamLevelRules(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.props.getFavouriteTeamLevelRules(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
        })
    }
    handleInputText = e => {
        this.setState({
            text: e.target.value,
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }

    handleEdit = async (favourite_team_level_rule) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            favourite_team_level_rule: {
                ...prevState.favourite_team_level_rule,
                favourite_team_level_rule
            }
        }))
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,

        })
    }
    createOrUpdate = (data) => {
        let favourite_team_level_rule = {
            id: data.id,
            level_name: data.level_name,
            from_min_count: Number(data.from_min_count),
            to_max_count: Number(data.to_max_count),
            reward_points: data.reward_points || 0,
            level_number: Number(data.level_number),
            discountable_points: data.discountable_points || 0,
            active: data.active,
        }
        if (this.state.isEdit) {

            favourite_team_level_rule = { ...favourite_team_level_rule, }
            if (favourite_team_level_rule.level_name === '' || favourite_team_level_rule.from_min_count === '' || favourite_team_level_rule.to_max_count === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (favourite_team_level_rule.from_min_count < 0 || favourite_team_level_rule.to_max_count < 0) {
                this.setState({
                    requiredError: "Count must be greater than 0!"
                })
            }
            else if (favourite_team_level_rule.from_min_count > favourite_team_level_rule.to_max_count) {
                this.setState({
                    requiredError: "Maximun count must be greater than min count!"
                })
            }
            else {
                this.props.updateFavouriteTeamLevelRule(favourite_team_level_rule).then(() => {
                    this.props.getFavouriteTeamLevelRules(this.state.initialPage, this.state.rowCount);

                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        } else {
            favourite_team_level_rule = { ...favourite_team_level_rule }
            if (favourite_team_level_rule.level_name === '' || favourite_team_level_rule.from_min_count === '' || favourite_team_level_rule.to_max_count === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (favourite_team_level_rule.from_min_count < 0 || favourite_team_level_rule.to_max_count < 0) {
                this.setState({
                    requiredError: "Count must be greater than 0!"
                })
            }
            else if ((this.props.favourite_team_level_rules.length > 0 && Number(this.props.favourite_team_level_rules[0].level_number)) >= favourite_team_level_rule.level_number) {
                this.setState({
                    requiredError: `Level number must be greater than ${Number(this.props.favourite_team_level_rules[0].level_number)}`
                })
            }
            else if (this.props.favourite_team_level_rules.length > 0 && Number(this.props.favourite_team_level_rules[0].to_max_count) >= favourite_team_level_rule.from_min_count) {
                this.setState({
                    requiredError: `Minimun Count must be greater than ${Number(this.props.favourite_team_level_rules[0].to_max_count)}`
                })
            }

            else if (favourite_team_level_rule.from_min_count > favourite_team_level_rule.to_max_count) {
                this.setState({
                    requiredError: "Maximun count must be greater than minimun count!"
                })
            }
            else {
                this.props.createFavouriteTeamLevelRule(favourite_team_level_rule).then(() => {
                    this.props.getFavouriteTeamLevelRules(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
}
export {
    FavouriteTeamLevelRuleListController
} 