import React, { Component } from 'react'
import { Button } from './../buttons/Button';
import Swal from 'sweetalert2'
import './SwalStyle.css'
class Modal extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
                this.props.deleteItem();
            }
        })

    }
    render() {
        return (
            <div className="actions">
                <Button caption="Delete" className="btn-danger" icon="fas fa-trash" btnFun={this.handleDelete} />
            </div>
        )
    }
}

export default Modal
