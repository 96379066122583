import { TeamListController } from './TeamListController'
import TeamCreate from '../create/TeamCreate'
import TeamAction from './../../../redux/action/team_action';
import StadiumAction from './../../../redux/action/stadium_action';
import TeamleagueAction from './../../../redux/action/team_league_action.js';
import LeagueAction from './../../../redux/action/league_action';
import CountryAction from './../../../redux/action/country_action';
import { Alert, PageLoader, Search, Modal, Pagination, Button, connect, MainContainer, Header, Body } from '../../../components/ImportsFile'
class TeamListing extends TeamListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Team' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Team' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Team' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Teams</h4>
                    <form className="search search-team" autoComplete="off">
                        <div className="input-group">
                            <input type="text" className="input-field" placeholder="Search.." name="search" value={this.state.text} onChange={this.handleInputText} />
                            {/**
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        */}
                            <Search
                                text={"Search with League"}
                                items={this.props.leagues}
                                searchItem={(leagueId) => this.handleSelectLeague(leagueId)}
                                currentPage={this.props.currentPage}
                                searchLeague={this.props.searchLeague}
                                // searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                                getItems={this.props.getAllLeagues}
                                rowCount={this.props.leagueRowCount}
                                // LoadMore={this.loadMoreCountry}
                                search={this.state.leagueId ? true : false}
                                stopSearching={() => this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount)}
                            />
                            <Search
                                text={'Search with Country'}
                                search={this.state.countryId ? true : false}
                                items={this.props.countries}
                                searchItem={(countryId) => this.handleSelectCountry(countryId)}
                                currentPage={this.props.currentPage}
                                searching={this.props.searchCountry}
                                getItems={this.props.getAllCountries}
                                rowCount={this.props.countryRowCount}
                                LoadMore={this.loadMoreCountry}
                                stopSearching={() => this.props.getTeams(this.state.initialPage, this.state.rowCount)}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>

                    </form>
                    {
                        createPermission &&
                        <Button icon="" btnFun={this.showModal} caption="Add Team" className="btn-primary add-button" />
                    }

                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Logo</th>
                                <th>Name</th>
                                <th>Stadium</th>
                                <th>League</th>
                                <th>Country</th>
                                {/**
                                <th>Active</th>
                            */}
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                !this.state.leagueId ? this.props.teams.map((team, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <img src={team.logo_url} alt="choosed images" />
                                            </td>
                                            <td>{team.name}</td>

                                            <td>{team.Stadium?.name}</td>

                                            <td>
                                                {
                                                    (team?.leagues && team?.leagues.length === 0) && "-"
                                                }
                                                {
                                                    (team?.leagues && team?.leagues.length === 1) &&
                                                    <img src={team?.leagues[0]?.logo_url} alt="choosed images" />
                                                }
                                                {
                                                    (team?.leagues && team?.leagues.length === 2) &&
                                                    <>
                                                        <img src={team?.leagues[0]?.logo_url} alt="choosed images" style={{ marginRight: "7px" }} />
                                                        <img src={team?.leagues[1]?.logo_url} alt="choosed images" />
                                                    </>
                                                }
                                            </td>

                                            <td><img src={team.Country?.flag} alt="choosed images" /></td>
                                            {/**
                                            <td>{team.active === 1 ? "Active" : "Inactive"}</td>
                                        */}
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteTeam(team.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(team)} />
                                            </td>
                                        </tr>
                                    )
                                })
                                    :

                                    this.props.team_leagues.map((team, index) => {
                                        return (
                                            team?.Team &&
                                            <tr key={index}>
                                                <td>
                                                    <img src={team?.Team?.logo_url} alt="choosed images" />
                                                </td>
                                                <td>{team?.Team?.name}</td>

                                                <td>{team?.Team?.Stadium?.name}</td>

                                                <td>
                                                    {
                                                        (team?.League && team?.League?.name.length === 0) && "-"
                                                    }
                                                    {
                                                        (team?.Team?.leagues && team?.Team?.leagues.length === 1) &&
                                                        <img src={team?.Team?.leagues[0]?.logo_url} alt="choosed images" />
                                                    }
                                                    {
                                                        (team?.Team?.leagues && team?.Team?.leagues.length === 2) &&
                                                        <>
                                                            <img src={team?.Team?.leagues[0]?.logo_url} alt="choosed images" style={{ marginRight: "7px" }} />
                                                            <img src={team?.Team?.leagues[1]?.logo_url} alt="choosed images" />
                                                        </>
                                                    }
                                                </td>

                                                <td><img src={team?.Team?.Country?.flag} alt="choosed images" /></td>
                                                {/**
                                                <td>{team.active === 1 ? "Active" : "Inactive"}</td>
                                            */}
                                                <td>
                                                    <Modal deleteItem={() => this.props.deleteTeam(team?.Team?.id)} editItem={() => this.handleEdit(team?.Team)}
                                                        deletePermission={deletePermission} updatePermission={updatePermission} />
                                                </td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </table>

                    {
                        (this.state.searchWithLeague) ?
                            <Pagination
                                activePage={this.props.teamLeagueCurrentPage}
                                totalItemsCount={this.props.teamLeagueTotalCount}
                                totalCountPerPage={this.props.teamLeagueTotalCountPerPage} paginate={(pageNumber) => {
                                    this.props.searchWithLeagueAndTeamName(this.props.leagueId, this.props.teamName, this.props.teamLeagueRowCount, pageNumber)
                                }}
                            />
                            :
                            <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                                (!this.props.text && !this.props.countryId) && this.props.getTeams(pageNumber, this.props.row_count);

                                (this.props.text || this.props.countryId) && this.props.searchTeam(this.state.text, this.state.countryId, pageNumber)

                            }}
                            />
                    }

                    {
                        this.state.show &&
                        <TeamCreate
                            isEdit={this.state.isEdit}
                            team={this.state.team}
                            leagues={this.props.leagues}
                            createOrUpdate={this.createOrUpdate}
                            hideModal={this.hideModal}
                            stadiums={this.props.stadiums}
                            loadMore={this.loadMore}
                            loadMoreLeague={this.loadMoreLeague}
                            selectChange={this.selectChange}
                            selectedLeague={this.state.selectedLeague}
                            searchItem={this.props.searchItem}
                            currentPage={this.props.currentPage}
                            stadiumText={this.props.stadiumText}
                            getAllStadiums={this.props.getAllStadiums}
                            stadiumRowCount={this.props.stadiumRowCount}
                            loadMoreCountry={this.loadMoreCountry}
                            countries={this.props.countries}
                            getAllCountries={this.props.getAllCountries}
                            countryText={this.props.countryText}
                            countryRowCount={this.props.countryRowCount}
                            searchCountry={this.props.searchCountry}
                            requiredError={this.state.requiredError}
                            handleSwitch={this.handleSwitch}
                            checked={this.state.checked}
                            searchLeague={this.props.searchLeague}
                            leagueCurrentPage={this.props.leagueCurrentPage}
                            getAllLeagues={this.props.getAllLeagues}
                            leagueRowCount={this.state.leagueRowCount}
                            textLeague={this.props.textLeague}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        teams: state.team.teams,
        countries: state.country.countries,
        stadiums: state.stadium.stadiums,
        error: state.team.error,
        success: state.team.success,
        currentPage: state.team.currentPage,
        totalCount: state.team.totalCount,
        totalCountPerPage: state.team.totalCountPerPage,
        loading: state.team.loading,
        text: state.team.text,
        stadiumId: state.team.stadiumId,
        countryId: state.team.countryId,
        row_count: state.team.row_count,
        leagues: state.league.leagues,
        countryText: state.country.text,
        countryRowCount: state.country.row_count,
        leagueCurrentPage: state.league.currentPage,
        textLeague: state.league.text,
        leagueRowCount: state.league.row_count,
        team_leagues: state.team_league.team_leagues,
        teamLeagueRowCount: state.team_league.row_count,
        teamLeagueCurrentPage: state.team_league.currentPage,
        teamLeagueTotalCount: state.team_league.totalCount,
        teamLeagueTotalCountPerPage: state.team_league.totalCountPerPage,
        leagueId: state.team_league.leagueId,
        teamName: state.team_league.teamName,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTeams: (pageNumber, row_count) => dispatch(TeamAction.getTeams(pageNumber, row_count)),
        createTeam: (team) => dispatch(TeamAction.createTeam(team)),
        updateTeam: (team) => dispatch(TeamAction.updateTeam(team)),
        deleteTeam: (id) => dispatch(TeamAction.deleteTeam(id)),
        getAllStadiums: (pageNumber, row_count) => dispatch(StadiumAction.getAllStadiums(pageNumber, row_count)),
        getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
        getAllCountries: (pageNumber, row_count) => dispatch(CountryAction.getAllCountries(pageNumber, row_count)),
        uploadTeamLogo: (team) => dispatch(TeamAction.uploadTeamLogo(team)),
        searchTeam: (text, countryId, pageNumber) => dispatch(TeamAction.searchTeam(text, countryId, pageNumber)),
        searchItem: (text, pageNumber) => dispatch(StadiumAction.searchItem(text, pageNumber)),
        searchCountry: (text, pageNumber) => dispatch(CountryAction.searchCountry(text, pageNumber)),
        searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
        searchWithLeagueAndTeamName: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamleagueAction.searchWithLeagueAndTeamName(leagueId, teamName, row_count, pageNumber))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamListing)