import React from 'react'
import LeaderBoardController from './LeaderBoardController';
import LeaderBoardImg from '../../../images/leaderboard.svg'
import { MainContainer, Header, Body } from './LeaderBoardElement';
import FavTeamList from './../fav_team/FavTeamList';
import TopFanList from './../top_fan/TopFanList';
import TheQuizzer from './../quizzer/TheQuizzer';

class LeaderBoard extends LeaderBoardController {
    render() {
        return (
            <MainContainer>
                <Header>
                    <div className="leaderBoard">
                        <img src={LeaderBoardImg} alt="" />
                        <span>Leaderboard</span>
                    </div>

                    <div className="nav">
                        <div className="favTeam" >
                            <button onClick={this.showFavTeamList} style={{ backgroundColor: this.state.showFavTeamList && '#fad230' }}>Favourite Team</button>
                        </div>
                        <div className="topFan" >
                            <button onClick={this.showTopFanList} style={{ backgroundColor: this.state.showTopFanList && '#fad230' }}>Top Fan</button>
                        </div>
                        <div className="quizzer" >
                            <button onClick={this.showQuizzer} style={{ backgroundColor: this.state.showQuizzer && '#fad230' }}>The Quizzers</button>
                        </div>
                    </div>

                </Header>
                <Body>

                    {
                        this.state.showFavTeamList && <FavTeamList />
                    }

                    {
                        this.state.showTopFanList && <TopFanList />
                    }
                    {
                        this.state.showQuizzer && <TheQuizzer />
                    }

                </Body>
            </MainContainer>
        )
    }
}

export default LeaderBoard
