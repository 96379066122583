import React from "react";
import FutureQuizAnswerList from "./../future_quiz_answer/list/FutureQuizAnswerList";
import AnswerListController from "./AnswerListController";
import UserQuizAnswerAction from "./../../../redux/action/user_quiz_answer.action";
import QuizAnswerAction from "./../../../redux/action/quiz_answer_action.js";
import AnswerCreate from "./../create/AnswerCreate";
import QuizDetailsAction from "./../../../redux/action/quiz_details_action";
import TeamLeagueAction from './../../../redux/action/team_league_action.js'
import { Back, Wrong, EditModal, Link, Alert, Button, Correct, connect, PageLoader, MainContainer, Header, Body, withRouter } from '../../../components/ImportsFile'

class AnswerList extends AnswerListController {
  render() {


    let quizId = this.props.match.params.id;
    let correct_answers;
    if (this.props.quiz_answers && this.props.quiz_answers.length > 0) {
      correct_answers = this.props.quiz_answers[0]?.answer?.split(",");
    }
    return (
      (this.state.quizInfo?.quiz_type === 4) ?
        <FutureQuizAnswerList quizInfo={this.state.quizInfo} />
        :
        <MainContainer>
          <Header>
            <h4>
              {
                this.state.quizInfo?.quiz_type === 3 ?
                  <Link to={`/odds/${quizId}`} style={{ textDecoration: "none" }}>
                    <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
                  </Link>
                  :
                  <Link to={`/quizzes/${quizId}`} style={{ textDecoration: "none" }}>
                    <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
                  </Link>
              }
              Quiz Answers
            </h4>
            {
              this.props.quiz_answers.length === 0 &&
              (
                <Button className="btn-primary add-button" caption="Add Correct Answer" btnFun={this.showModal} />
              )
            }
          </Header>
          <Body>
            <Alert
              errorMessage={this.props.error && this.props.error.message}
              successMessage={this.props.success && this.props.success.message}
            />
            {
              this.props.loading && <PageLoader />
            }
            {
              this.props.quiz_answers.length > 0 &&
              <div className="correct_answer">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>Correct Answer</h4>
                  <EditModal editItem={() => this.handleEdit(this.props.quiz_answers && this.props.quiz_answers[0])} />
                </div>

                {
                  this.state.quizInfo?.quiz_type !== 3 &&
                  <div className="correct_answer_card">
                    <div className="correct_answer_key">
                      <strong>Question </strong>
                    </div>
                    <div className="correct_answer_value">
                      <small>
                        <b>: &nbsp;</b>
                        {this.state.quizInfo?.Question?.question}
                      </small>
                    </div>
                  </div>
                }
                {
                  (this.props.quiz_answers.length !== 0 && this.props.quiz_answers) &&
                  <div className="correct_answer_card">
                    <div className="correct_answer_key">
                      <strong>Answer </strong>
                    </div>
                    <div className="correct_answer_value">
                      <small>
                        <b>: &nbsp;</b>
                        {
                          (this.props.quiz_answers.length !== 0 && this.props.quiz_answers && this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question != 'ဗိုလ်စွဲမည့်အသင်း/ချန်ပီယံအသင်း') && this.props.quiz_answers[0]?.win_case
                        }


                        {
                          (this.props.quiz_answers.length !== 0 && this.props.quiz_answers && this.state.quizInfo?.quiz_type === 3) && this.props.quiz_answers[0]?.win_case
                        }

                        {

                          (this.props.quiz_answers.length !== 0 && this.props.quiz_answers && this.state.quizInfo?.question_id === 19 && this.props.quiz_answers[0]?.win_case) && this.props.quiz_answers[0]?.win_case
                        }
                        {
                          (this.props.quiz_answers.length !== 0 && this.props.quiz_answers &&
                            (this.state.quizInfo?.Question?.question == 'ဗိုလ်စွဲမည့်အသင်း/ချန်ပီယံအသင်း' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' || this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ' ||
                              this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ'
                            ) && this.props.quiz_answers[0]?.win_case) && this.props.quiz_answers[0]?.win_case
                        }
                        {
                          (this.props.quiz_answers && (this.state.quizInfo?.Question?.question == 'ဗိုလ်စွဲမည့်အသင်း/ချန်ပီယံအသင်း' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' && this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ' &&
                            this.state.quizInfo?.Question?.question != 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ')
                          ) && correct_answers
                        }
                        {
                          (this.props.quiz_answers && (this.state.quizInfo?.Question?.question == 'ဂိုးပေါင်း ဘယ်လောက်လဲ')
                          ) && correct_answers
                        }

                      </small>
                    </div>
                  </div>
                }
              </div>

            }

            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Username</th>
                  <th>Answer</th>
                  {
                    this.state.quizInfo?.quiz_type === 3 &&
                    <th>Bet Amt</th>
                  }

                </tr>
              </thead>
              <tbody>
                {this.props.user_quiz_answers.map((answer, index) => {
                  let player;
                  let team = {};
                  if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.quiz_type != 2) {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး ဂိုးသမား (လက်ဗ်ယာရှင်ဆု)") {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question == "ပြိုင်ပွဲတလျှောက် အကောင်းဆုံး ကစားသမား") {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question == "ပြိုင်ပွဲတလျှောက် ဂိုးသွင်းအများဆုံး ကစားသမား") {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question == "ပြိုင်ပွဲတလျှောက် ဂိုးဖန်တီးမှုအများဆုံး ကစားသမား") {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.Question?.question == "ပြိုင်ပွဲတလျှောက် အကောင်းဆုံး လူငယ်ကစားသမား") {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.quiz_type != 2 && this.state.quizInfo?.SpecialQuizDetail?.type == 1) {
                    player = this.getPlayers(answer.answer)
                  }
                  else if (this.props.quiz_answers[0]?.SpecialQuizDetail?.quiz_type == 2) {
                    if (this.props.quiz_answers[0]?.SpecialQuizDetail?.type == 1) {
                      player = this.getPlayers(answer.answer)
                    }
                    else if (this.props.quiz_answers[0]?.SpecialQuizDetail?.type == 2) {
                      team = this.getTeam(answer.answer)
                    }
                  }
                  else if (this.state.quizInfo?.quiz_type == 1) {
                    team = this.getTeam(answer.answer)
                  }
                  let user_answers =
                    answer?.answer?.length > 0 && answer?.answer?.split(",");

                  return (
                    <tr key={index}>
                      <td>
                        {correct_answers &&
                          correct_answers.length > 0 &&
                          user_answers.map((ans, i) => {
                            return (
                              <div key={i}>
                                {correct_answers.includes(ans) ? (
                                  <img
                                    src={Correct}
                                    alt=""
                                    style={{ width: "25px" }}
                                  />
                                ) : (
                                  <img
                                    src={Wrong}
                                    alt=""
                                    style={{ width: "25px" }}
                                  />
                                )}
                              </div>
                            );
                          })}

                        {!this.props.quiz_answers.length > 0 && "-"}

                      </td>
                      <td>{answer.User?.user_name}</td>
                      <td style={{ paddingTop: this.state.quizInfo?.quiz_type !== 3 && "10px" }}>
                        {
                          this.state.quizInfo?.quiz_type === 3 ?
                            <>
                              {answer.answer == 1 && "Home"}
                              {answer.answer == 2 && "Draw"}
                              {answer.answer == 3 && "Away"}
                            </>
                            :
                            <>
                              {
                                (
                                  this.state.quizInfo?.submit_type !== 4 &&
                                  (this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်း နိင်မှာလဲ' &&

                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' &&
                                    this.state.quizInfo?.Question?.question !== 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ')

                                ) && answer.answer
                              }
                              {
                                // this.state.quizInfo?.Question?.question != 'ဂိုးပေါင်း ဘယ်လောက်လဲ' && answer.answer
                              }
                              {
                                this.state.quizInfo?.submit_type === 4 && player?.name
                              }
                              {
                                (this.state.quizInfo?.submit_type === 4 && this.state.quizInfo?.quiz_type == 2) && team?.Team?.name
                              }

                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်း နိင်မှာလဲ' && answer.answer == 0) && 'သရေ'
                              }

                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' && answer.answer == 0) && 'သွင်းဂိုးမရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' && answer.answer == 0) && 'အဝါကဒ်မရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' && answer.answer == 0) && 'အနီကဒ်မရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' && answer.answer == 0) && 'ဖရီးကစ်မရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' && answer.answer == 0) && 'ကော်နာမရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' && answer.answer == 0) && 'ပယ်နတီမရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (this.state.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ' && answer.answer == 0) && 'လူစားလဲတာမရှိ'
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 1) &&
                                (answer.answer != 0) && team?.name
                              }
                              {

                                (this.state.quizInfo?.quiz_type === 2) &&
                                (answer.answer != 0) && team?.name
                              }

                            </>
                        }
                      </td>
                      {
                        this.state.quizInfo?.quiz_type === 3 &&
                        <td style={{ paddingTop: "10px" }}>{answer.bet_amt}</td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {
              this.state.show && (
                <AnswerCreate
                  question={this.state.question}
                  quizInfo={this.state.quizInfo}
                  quiz_detail_id={this.props.match.params.quiz_detail_id}
                  home_team_players={this.state.home_team_players}
                  away_team_players={this.state.away_team_players}
                  allPlayers={this.state.allPlayers}
                  allTeams={this.state.allTeams}
                  teams={this.props.teams}
                  loadMoreTeams={this.loadMoreTeams}
                  searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                  show={this.state.show}
                  hideModal={this.hideModal}
                  isEdit={this.state.isEdit}
                  quiz_answer={this.state.quiz_answer}
                  createOrUpdate={this.createOrUpdate}
                  loadMore={this.loadMore}
                  quiz_answers={this.props.quiz_answers}
                  currentPage={this.props.currentPage}
                  countryText={this.props.countryText}
                  rowCount={this.state.rowCount}
                  requiredError={this.state.requiredError}
                />
              )}

            {/**
                    
            <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.userId ? this.props.getUserQuizAnswers(pageNumber, this.props.row_count) : this.props.searchItem(this.props.userId, pageNumber) }} />
                    
          */}
          </Body>
        </MainContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_quiz_answers: state.user_quiz_answer.user_quiz_answers,
    quiz_details_lists: state.quiz_details.quiz_details_lists,
    quiz_answers: state.quiz_answer.quiz_answers,
    currentPage: state.user_quiz_answer.currentPage,
    totalCount: state.user_quiz_answer.totalCount,
    error: state.quiz_answer.error,
    success: state.quiz_answer.success,
    totalCountPerPage: state.user_quiz_answer.totalCountPerPage,
    row_count: state.user_quiz_answer.row_count,
    loading: state.quiz_answer.loading,
    quizDetailId: state.user_quiz_answer.quizDetailId,
    userId: state.user_quiz_answer.userId,
    quizDetailIdFromQuizAnswer: state.quiz_answer.quizDetailId,

    teams: state.team_league.team_leagues,
    leagueId: state.team_league.leagueId,
    teamName: state.team_league.teamName,
    teamRowCount: state.team_league.row_count
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserQuizAnswers: (quizDetailId, pageNumber, row_count) =>
      dispatch(
        UserQuizAnswerAction.getUserQuizAnswers(
          quizDetailId,
          pageNumber,
          row_count
        )
      ),
    getQuizAnswers: (quizId, quizDetailId, pageNumber, row_count) =>
      dispatch(
        QuizAnswerAction.getQuizAnswers(
          quizId,
          quizDetailId,
          pageNumber,
          row_count
        )
      ),
    addQuizAnswer: (quiz_answer) =>
      dispatch(QuizAnswerAction.addQuizAnswer(quiz_answer)),

    getQuizDetailsLists: (quizId, pageNumber, row_count) =>
      dispatch(
        QuizDetailsAction.getQuizDetailsLists(quizId, pageNumber, row_count)
      ),

    updateQuizAnswer: (answer) => dispatch(QuizAnswerAction.updateQuizAnswer(answer)),
    searchWithLeagueAndTeamName: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamLeagueAction.searchWithLeagueAndTeamName(leagueId, teamName, row_count, pageNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnswerList));
