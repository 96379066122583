import React from 'react'
import { Input } from '../../../components/inputs/index'
import { Button } from '../../../components/buttons/index'
import { PermissionCreateController } from './PermissionCreateController'
import { ModuleList } from './../../../redux/moduleList';
class PermissionCreate extends PermissionCreateController {
    render() {

        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Permission' : 'Add Permission'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <Input type="text" name="name" placeholder="Enter Permission Name..." value={this.state.permission.name} onChange={this.handleInputName} required style={{ marginRight: '10px' }} />

                                <select name="active" className="select" value={this.state.permission.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <Input type="text" name="description" placeholder="Enter Permission Description...(Optional)" value={this.state.permission.description ? this.state.permission.description : ''} onChange={this.handleInputDescription} required />

                            <br />
                            <div className="module">
                                <div className="module_list">
                                    <input type="radio" name="selectAll"
                                        value="selectAll"
                                        onChange={this.selectAll}
                                        checked={this.state.selectOption === "selectAll" && true}
                                    /> Select All &nbsp;
                                    <input type="radio" name="unselectAll"
                                        value="unselectAll"
                                        onChange={this.unselectAll}
                                        checked={this.state.selectOption === "unselectAll" && true}
                                    /> Unselect All &nbsp;
                                </div>
                                {
                                    ModuleList.map((item, index) => {
                                        let checkList = false;
                                        let checkCreate = false;
                                        let checkUpdate = false;
                                        let checkDelete = false;
                                        this.state.permission?.data?.length > 0 && this.state.permission?.data?.map((data) => {
                                            if (data.feature === item.name && data.functions === "list") {
                                                return checkList = true;
                                            }
                                            else if (data.feature === item.name && data.functions === "create") {
                                                return checkCreate = true;
                                            }
                                            else if (data.feature === item.name && data.functions === "update") {
                                                return checkUpdate = true;
                                            }
                                            else if (data.feature === item.name && data.functions === "delete") {
                                                return checkDelete = true;
                                            }
                                        })
                                        return (
                                            <div className="module_list" key={index}>
                                                <span>
                                                    {item.name}
                                                </span>
                                                <div className="checkbox">
                                                    {
                                                        item.list && <div className="module_item">

                                                            <input type="checkbox" name="List" value={[item.name, "list"]} onChange={this.listChackbox}
                                                                checked={((checkList)) && true}
                                                            /> {item.list}
                                                        </div>
                                                    }
                                                    {
                                                        item.create && <div className="module_item">
                                                            <input type="checkbox" name="Create" value={[item.name, "create"]} onChange={this.createChackbox}
                                                                checked={(checkCreate) && true}
                                                            /> {item.create}
                                                        </div>
                                                    }
                                                    {
                                                        item.update && <div className="module_item">
                                                            <input type="checkbox" name="Update" value={[item.name, "update"]} onChange={this.updateChackbox} checked={(checkUpdate) && true} /> {item.update}
                                                        </div>
                                                    }
                                                    {
                                                        item.delete && <div className="module_item">
                                                            <input type="checkbox" name="Delete" value={[item.name, "delete"]} onChange={this.deleteChackbox} checked={(checkDelete) && true} /> {item.delete}
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <br />
                            <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.permission)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export {
    PermissionCreate
}
