import React from 'react'
import CityCreate from '../create/CityCreate'
import { CityListController } from './CityListController'
import CityAction from './../../../redux/action/city_action';
import CountryAction from './../../../redux/action/country_action'
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, Search, connect } from '../../../components/ImportsFile'
class CityListing extends CityListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'City' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'City' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'City' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Cities</h4>
                    <form className="search search-city" autoComplete="off" >
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            {/**
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        */}

                            <Search
                                items={this.props.countries}
                                searchItem={(selected) => this.handleSelectId(selected)}
                                searching={this.props.searchCountry}
                                getItems={this.props.getAllCountries}
                                rowCount={this.props.countryRowCount}
                                currentPage={this.props.countryCurrentPage}
                                text={'Search with Country'}
                                search={this.state.selected ? true : false}
                                stopSearching={() => this.stopSearching()}
                                LoadMore={this.loadMore}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>

                    </form>
                    {
                        createPermission &&
                        <Button caption="Add City" className="btn-primary add-button" btnFun={() => this.setState({ show: true })} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>City</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.cities.map((city, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{city.Country?.name}</td>
                                            <td>{city.name}</td>
                                            <td>{city.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteCity(city.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(city)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.text && !this.props.countryId) && this.props.getCities(pageNumber, this.props.row_count);
                        (this.props.text || this.props.countryId) && this.props.searchItem(this.state.text, this.state.countryId, pageNumber);
                    }} />

                    {
                        this.state.show &&
                        <CityCreate
                            show={this.state.show}
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            city={this.state.city}
                            createOrUpdate={this.createOrUpdate}
                            loadMore={this.loadMore}
                            countries={this.props.countries}
                            searchCountry={this.props.searchCountry}
                            currentPage={this.props.currentPage}
                            countryText={this.props.countryText}
                            getAllCountries={this.props.getAllCountries}
                            rowCount={this.state.rowCount}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        countries: state.country.countries,
        countryRowCount: state.country.row_count,
        countryCurrentPage: state.country.currentPage,
        cities: state.city.cities,
        totalCount: state.city.totalCount,
        totalCountPerPage: state.city.totalCountPerPage,
        error: state.city.error,
        success: state.city.success,
        text: state.city.text,
        countryId: state.city.countryId,
        row_count: state.city.row_count,
        loading: state.city.loading,
        currentPage: state.city.currentPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCities: (pageNumber, row_count) => dispatch(CityAction.getCities(pageNumber, row_count)),
        createCity: (city) => dispatch(CityAction.createCity(city)),
        updateCity: (city) => dispatch(CityAction.updateCity(city)),
        deleteCity: (data) => dispatch(CityAction.deleteCity(data)),
        getAllCountries: (pageNumber, row_count) => dispatch(CountryAction.getAllCountries(pageNumber, row_count)),
        searchItem: (text, countryId, pageNumber) => dispatch(CityAction.searchItem(text, countryId, pageNumber)),
        searchCountry: (text, pageNumber) => dispatch(CountryAction.searchCountry(text, pageNumber))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityListing)
