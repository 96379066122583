import React, { Component } from 'react'
import fallBackTeam from '../../../../images/icons/team.png'
class MainStats extends Component {
    render() {
        return (
            <div className="main_stats" >
                <div className="main">
                    <div className="left">
                        <img src={this.props.teams[0]?.imageUrl} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        <p>{this.props.teams[0]?.name}</p>
                    </div>
                    <div className="center">
                        <h4>{this.props.teams[0]?.score} - {this.props.teams[1]?.score}
                        </h4>
                        <p className="muted">
                            {
                                this.props.status?.reason ? this.props.status?.reason?.long : this.props.status?.startTimeStr
                            }</p>

                    </div>
                    <div className="right">
                        <img src={this.props.teams[1]?.imageUrl} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        <p>{this.props.teams[1]?.name}</p>
                    </div>
                </div>
                <div className='scorers'>
                    {
                        this.props.events?.homeTeamGoals &&
                        <div className="home_scorer">

                            {

                                Object.values(this.props.events?.homeTeamGoals).map((goal, index1) => {
                                    return goal.map((data, index2) => {
                                        return (
                                            <span key={index2} className="scorer">
                                                <p>
                                                    {data?.nameStr && data?.firstName + " " + data?.lastName}
                                                </p>
                                                <p className="timeStr">{data?.timeStr}'</p>
                                            </span>
                                        )
                                    })
                                }
                                )
                            }
                        </div>
                    }

                    <div className="center">
                        <div className="icon" style={{ display: !this.props.events && 'none' }}> &#9917; </div>
                    </div>
                    {
                        this.props.events?.awayTeamGoals &&
                        <div className="away_scorer">
                            {
                                Object.values(this.props.events?.awayTeamGoals).map((goal, index1) => {
                                    return goal.map((data, index2) => {
                                        return (
                                            <span key={index2} className="scorer">
                                                <p>
                                                    {data?.nameStr && data?.firstName + " " + data?.lastName}
                                                </p>
                                                <p className="timeStr">{data?.timeStr}'</p>
                                            </span>
                                        )
                                    })
                                }
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default MainStats