import React from 'react'
import { Link, Button, Input, SelectModal } from '../../../components/ImportsFile'
import SpecialQuizCreateController from './SpecialQuizCreateController';
import moment from 'moment'

class SpecialQuizCreate extends SpecialQuizCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Quiz' : 'Add Quiz'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">


                        <form autoComplete="off">
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }

                            <div style={{ columnCount: '2', paddingTop: '10px' }}>

                                <select name="quiz_type" className="select" value={this.state.special_quiz.quiz_type} onChange={this.handleSelectQuizType} required>
                                    <option value="1">Match</option>
                                    <option value="2">Season</option>
                                    <option value="3">Special Match</option>
                                    <option value="4">Future</option>
                                </select>

                                <SelectModal
                                    routeChangeName="league"
                                    items={this.props.leagues}
                                    LoadMore={this.props.loadMoreLeagues}
                                    searchLeague={this.props.searchLeague}
                                    currentPage={this.props.leagueCurrentPage}
                                    getItems={this.props.getAllLeagues}
                                    rowCount={this.props.leagueRowCount}
                                    handleProcess={(league_id) => this.handleSelectLeague(league_id)
                                    }
                                    itemName={this.props.isEdit ? this.props.special_quiz.special_quiz?.League?.name : "*** Choose League ***"}
                                />

                                {
                                    (this.state.special_quiz.quiz_type == 1 || this.state.special_quiz.quiz_type == 3) &&
                                    <SelectModal
                                        items={this.state.matches}
                                        routeChangeName="match"
                                        itemName={
                                            this.props.isEdit ?
                                                (
                                                    this.props.special_quiz.special_quiz.Match.HomeTeam.name + " Vs " + this.props.special_quiz.special_quiz.Match.AwayTeam.name
                                                )
                                                :
                                                "*** Choose Match ***"}
                                        handleProcess={(match_id) => this.handleSelectMatch(match_id)}
                                        LoadMore={this.state.loadMore}
                                    />
                                }

                                <Input type="date" placeholder="Select Deadline" name="deadline" value={this.state.special_quiz.deadline_date} onChange={this.DeadlineDate} />

                                <Input type="time" placeholder="Enter Deadline" name="deadline" value={this.state.special_quiz.deadline_time} onChange={this.DeadlineTime} />

                                <select name="active" className="select" value={this.state.special_quiz.active} onChange={this.handleSelect} required>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: this.state.special_quiz?.Match ? 'space-between' : 'end', alignItems: 'center', fontSize: '12px', color: '#ffffff' }}>
                                {

                                    this.state.special_quiz?.Match && <p>Match Date & time : {this.props.isEdit ? (this.state.special_quiz?.Match?.date + " " + this.state.special_quiz?.Match?.time) : this.state.match_time}</p>

                                }

                                <div style={{ display: 'flex' }}>
                                    {
                                        this.props.isEdit &&

                                        <Link to={`/quiz_detail/${this.props.special_quiz.special_quiz.id}`} className="quiz_detail" style={{ float: 'left', marginRight: '20px' }}>
                                            <Button caption="Detail" className="btn-primary" icon="fa fa-info-circle"
                                            />
                                        </Link>
                                    }
                                    <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => {
                                        this.props.createOrUpdate(this.state.special_quiz)
                                    }
                                    } />
                                </div>
                            </div>



                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default SpecialQuizCreate