import React from 'react'
import { WalletCreateController } from './WalletCreateController'
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import '../../modal/modal.css'
import SelectModal from './../../../components/selectModal/SelectModal';

class WalletCreate extends WalletCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Wallet' : 'Add Wallet'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }

                            <div>

                                {/**
                            <SelectModal items={this.props.users} handleProcess={(user_id) => this.UserSelect(user_id)} itemName={this.props.isEdit ? this.props.wallet.wallet.User.user_name : "*** Choose User ***"} LoadMore={this.props.loadMore} style={{ display:"none" }} />
                            
                        */}

                                <Input type="text" name="total_points" value={this.props.wallet.wallet.User.user_name} disabled />

                                <Input type="text" placeholder="Enter Total Coins" name="total_points" value={this.state.wallet.total_points} onChange={this.handleChangeTotalPoints} required />

                                <select name="active" className="select" value={this.state.wallet.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />

                            <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.wallet)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    WalletCreate
}