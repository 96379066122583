import { Component } from 'react'
import moment from 'moment'
class PrizeCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prize: props.isEdit === false ? {
        exchangeable_points: '',
        name: '',
        description: '',
        image: '',
        limit_count: '',
        active: 1,
        type: 1,
        expired_time: ''
      } : {
        ...props.prize.prize,
        expired_time: moment(props.prize.prize.expired_time).format('YYYY-MM-DD')
      }
    }
  }

  ExchangeablePoints = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        exchangeable_points: e.target.value
      }
    }))
  }
  LimitCount = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        limit_count: e.target.value
      }
    }))
  }
  handleInputChange = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        name: e.target.value
      }
    }))
  }
  handleInputDescription = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        description: e.target.value
      }
    }))
  }

  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        active: e.target.value
      }
    }))
  }
  leagueProcess = (league_id) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        league_id: league_id
      }
    }))
  }
  ExpiredTime = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        expired_time: e.target.value
      }
    }), () => {
      console.log(this.state.prize.expired_time);
    })
  }
  handleSelectType = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      prize: {
        ...prevState.prize,
        type: e.target.value
      }
    }))
  }
  onChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        prize: {
          ...prevState.prize,
          image: e.target.files[0]
        }
      }))
    }
  }
}

export default PrizeCreateController