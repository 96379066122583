import CONSTANTS from './../constants';
import axios from 'axios'
import CoinPolicyType from './../type/coin_policy_type';

export const Error = (data) => {
  return {
    type: CoinPolicyType.COIN_POLICY_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: CoinPolicyType.COIN_POLICY_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: CoinPolicyType.LOADING, payload: status })
  }
}
const getCoinPolicies = (pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}coin_policies/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: CoinPolicyType.GET_COIN_POLICIES, payload: response.data.data
        })
        dispatch({
          type: CoinPolicyType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: CoinPolicyType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: CoinPolicyType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: CoinPolicyType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: CoinPolicyType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: CoinPolicyType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createCoinPolicy = (coin_policy) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.post(`${CONSTANTS.BASE_URL}coin_policies/create`, coin_policy).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: CoinPolicyType.ADD_COIN_POLICY, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))

        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const updateCoinPolicy = (coin_policy) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.put(`${CONSTANTS.BASE_URL}coin_policies/update`, coin_policy).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: CoinPolicyType.UPDATE_COIN_POLICY, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}



const CoinPolicyAction = {
  getCoinPolicies,
  createCoinPolicy,
  updateCoinPolicy,
}
export default CoinPolicyAction