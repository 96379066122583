import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';

class TownshipListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialPage: 1,
            township: {},
            rowCount: CONSTANTS.ROW_COUNT,
            cityRowCount: CONSTANTS.ROW_COUNT,
            text: '',
            selected: '',
            requiredError: ''
        }
    }
    componentDidMount = () => {
        this.loadMore();
        this.props.getTownships(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.props.getTownships(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
            selected: ''
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value,
            // selected: ''
        })
    }
    handleSelectCityId = (selected) => {
        this.setState({ selected })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.selected, this.state.initialPage)
    }
    loadMore = () => {
        this.props.getAllCities(this.state.initialPage, this.state.cityRowCount);
        this.setState({
            cityRowCount: this.state.cityRowCount + 6
        })
    }
    handleEdit = async (township) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            township: {
                ...prevState.township,
                township
            }
        }))
    }
    createOrUpdate = async (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let township;
        if (this.state.isEdit) {
            township = {
                id: data.id,
                name: data.name,
                city_id: data.city_id,
                active: 1,
                updated_by: operator.id
            }
            if (township.name === '' || township.city_id === '') {
                this.setState({
                    requiredError: "All fields are requried"
                })
            }
            else {
                this.props.updateTownship(township).then(() => {
                    this.props.getTownships(this.props.currentPage, this.state.rowCount);
                })
                this.hideModal();
            }
        } else {
            township = { ...data, created_by: operator.id, updated_by: operator.id }
            if (township.name === '' || township.city_id === '') {
                this.setState({
                    requiredError: "All fields are requried"
                })
            }
            else {
                this.props.createTownship(township).then(() => {
                    this.setState({
                        requiredError: ''
                    })
                    this.props.getTownships(this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }
        }

    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,
        })
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }

}

export {
    TownshipListController
}