import React from 'react'
import { Input } from '../../../components/inputs/index'
import { SelectBox } from '../../../components/selectbox/index'
import { Button } from '../../../components/buttons/index'

import { MatchAlertCreateController } from './MatchAlertCreateController'
import '../../modal/modal.css'
class MatchAlertCreate extends MatchAlertCreateController {


    render() {
        
        const items = [{ name: "Active", value: "Active" }, { name: "Inactive", value: "Inactive" }];

        return (
            <div className="modal-wrapper" style={{
                display: this.props.show ? "block" : "none"
            }}>
                <div className="modal-header">
                    <h3>{this.props.isEdit ? 'Edit Alert' : 'Add Alert'}</h3>
                    <span className="close-modal-btn" onClick={this.props.handleClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="modal-body">
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            
                            <Input type="text" name="match" placeholder="Enter Match Name..." value={this.state.match} onChange={this.handleInputChange} required/>

                            <Input type="text" name="user" placeholder="Enter User Name..." value={this.state.user} onChange={this.handleInputChange} required/>

                            <SelectBox items={items} name="active" value={this.state.active} onChange={this.handleSelect} required /><br />

                            <Button type="submit" caption={this.props.isEdit ? 'Update' : 'Create'} className="btn-info"  btnFun={(this.state.match && this.state.user) ? this.props.handleClose : null}/>
                        </form>
                    </div>
                </div>
            </div>

        )
    }
}
export {
    MatchAlertCreate
} 
