import React from 'react'
import { Input } from '../../../components/inputs/index'
import { Button } from '../../../components/buttons/index'

import { CityCreateController } from './CityCreateController'
import '../../modal/modal.css'
import SelectModal from './../../../components/selectModal/SelectModal';
class CreateCity extends CityCreateController {

    render(props) {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit City' : 'Add City'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <div>
                                <Input type="text" name="name" placeholder="Enter City Name..." value={this.state.city.name} onChange={this.handleInputChange} required />

                                <SelectModal
                                    routeChangeName="country"
                                    items={this.props.countries}
                                    LoadMore={this.props.loadMore}
                                    searching={this.props.searchCountry}
                                    currentPage={this.props.currentPage}
                                    getItems={this.props.getAllCountries}
                                    rowCount={this.props.rowCount}
                                    handleProcess={(country_id) => this.CountryProcess(country_id)
                                    }
                                    itemName={this.props.isEdit ? this.props.city.city.Country.name : "*** Choose Country ***"}
                                />

                                <select name="active" className="select" value={this.state.city.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                            </div>
                            <br />

                            <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.city)} />
                        </form>
                    </div>
                </div>
            </div>

        )
    }
}
export default CreateCity