import React, { Component } from 'react'
import { MainContainer, Header, Body, Search, Alert, PageLoader, Pagination, connect } from '../../../components/ImportsFile'
import PrizeHistoryAction from '../../../redux/action/prize_history_action.js'
import PrizeHistoryListController from './PrizeHistoryListController';
import UserAction from './../../../redux/action/user_action';
import PrizeAction from './../../../redux/action/prize_action';
import Moment from 'react-moment';
class PrizeHistoryList extends PrizeHistoryListController {
  render() {
    return (
      <MainContainer>
        <Header>
          <h4>Prize History</h4>
          <form className="search search-prize-history">
            <div className="input-group">

              <Search
                items={this.props.prizes}
                searchItem={(prizeId) => this.handleSelectPrize(prizeId)}
                searching={this.props.searchPrize}
                currentPage={this.props.currentPage}
                text={'Search with Prize'}
                search={this.state.prizeId ? true : false}
                LoadMore={this.loadMorePrize}
                getItems={this.props.getAllPrizes}
                rowCount={this.state.prizeRowCount}
                stopSearching={() => this.stopSearching()}
              />
              <Search
                items={this.props.users}
                searchItem={(userId) => this.handleSelectUser(userId)}
                searching={this.props.searchUser}
                currentPage={this.props.currentPage}
                text={'Search with User'}
                search={this.state.userId ? true : false}
                LoadMore={this.loadMoreUser}
                getItems={this.props.getAllUsers}
                rowCount={this.state.userRowCount}
                stopSearching={() => this.stopSearching()}
              />
              <input type="date" name="startDate" value={this.state.startDate} max={this.state.endDate} onChange={this.handleStartDateChange} />
              <input type="date" name="endDate" min={this.state.startDate} value={this.state.endDate} placeholder="Search.." onChange={this.handleEndDateChange} />
              <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
              <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
            </div>
          </form>
        </Header>
        <Body>
          {
            (this.state.prizeId || this.state.userId || this.state.startDate || this.state.endDate) ?
              <button className="downloadBtn" onClick={() => this.props.exportPrizeHistory(this.state.prizeId, this.state.userId, this.state.startDate ? this.state.startDate + " 00:00:01" : this.state.startDate, this.state.endDate ? this.state.endDate + " 23:59:59" : this.state.endDate)}><i className="fa fa-download"></i> Download </button>
              :
              <>
                <button className="downloadBtn" onClick={() => this.props.exportPrizeHistoryByUser()}><i className="fa fa-download"></i> Download By User</button>
                <button className="downloadBtn" onClick={() => this.props.exportPrizeHistoryByPrize()}><i className="fa fa-download"></i> Download By Prize</button>
                <button className="downloadBtn" onClick={() => this.props.exportAllPrizeHistory()}><i className="fa fa-download"></i> Download All</button>
              </>
          }

          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Claimed Date</th>
                <th>User Name</th>
                <th>Prize</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.prize_histories.map((history, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Moment format="ddd, D MMM ,YYYY h:mm:ss A">{history?.created_at}</Moment>
                      </td>
                      <td>{history?.User?.user_name}</td>
                      <td>{history?.Prize?.name}</td>
                      <td>
                        <img src={history?.Prize?.image} alt="choosed images" />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => (this.props.userId || this.props.prizeId) ? this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, this.state.startDate, this.state.endDate, pageNumber) : this.props.getPrizeHistories(pageNumber, this.state.rowCount)}
          />

        </Body>
      </MainContainer >
    )
  }
}
const mapStateToProps = (state) => {
  return {
    prize_histories: state.prize_history.prize_histories,
    error: state.prize_history.error,
    success: state.prize_history.success,
    currentPage: state.prize_history.currentPage,
    ucPage: state.user.currentPage,
    totalCount: state.prize_history.totalCount,
    totalCountPerPage: state.prize_history.totalCountPerPage,
    text: state.prize_history.text,
    row_count: state.prize_history.row_count,
    loading: state.prize_history.loading,
    userId: state.prize_history.userId,
    prizeId: state.prize_history.prizeId,
    startDate: state.prize_history.startDate,
    endDate: state.prize_history.endDate,
    prizes: state.prize.prizes,
    users: state.user.users,
    prizeName: state.prize.text,
    userName: state.user.text
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    getPrizeHistories: (pageNumber, row_count) => dispatch(PrizeHistoryAction.getPrizeHistories(pageNumber, row_count)),
    searchPrizeHistory: (prizeId, userId, startDate, endDate, pageNumber) => dispatch(PrizeHistoryAction.searchPrizeHistory(prizeId, userId, startDate, endDate, pageNumber)),
    exportAllPrizeHistory: () => dispatch(PrizeHistoryAction.exportAllPrizeHistory()),
    exportPrizeHistoryByPrize: () => dispatch(PrizeHistoryAction.exportPrizeHistoryByPrize()),
    exportPrizeHistoryByUser: () => dispatch(PrizeHistoryAction.exportPrizeHistoryByUser()),
    exportPrizeHistory: (prizeId, userId, startDate, endDate) => dispatch(PrizeHistoryAction.exportPrizeHistory(prizeId, userId, startDate, endDate)),
    getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
    getAllPrizes: (pageNumber, row_count) => dispatch(PrizeAction.getAllPrizes(pageNumber, row_count)),
    searchPrize: (text, pageNumber) => dispatch(PrizeAction.searchPrize(text, pageNumber)),
    searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrizeHistoryList)

