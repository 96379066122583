import React from 'react'
import UserTeamActivityListController from './UserTeamActivityListController'
import UserTeamActivityCreate from './../create/UserTeamActivityCreate';
import UserTeamActivityAction from '../../../redux/action/user_team_activity_action'
import TeamAction from './../../../redux/action/team_action';
import UserFavouriteTeamAction from '../../../redux/action/user_favourite_team_action.js'
import { Alert, connect, PageLoader, Pagination, Button, Search, MainContainer, Header, Body } from '../../../components/ImportsFile'
class UserTeamActivityList extends UserTeamActivityListController {
    render() {
        return (
            <MainContainer>
                <Header>
                    <h4 style={{ fontSize: '24px' }}>User Team Activities</h4>
                    <form className="search user-team-activity" onSubmit={this.handleSearch}>
                        <div className="input-group">
                            {/**
                        <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                        <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                    */}
                            <Search
                                // user_fav_teams={this.props.user_favourite_teams}
                                // searchItem={(selected) => this.handleSelectId(selected)}
                                // searching={this.props.searchUserFavTeams}
                                // getItems={this.props.getUserFavouriteTeams}
                                // rowCount={this.props.userFavRowCount}
                                // currentPage={this.props.currentPage}
                                // text={'Search with User Favourite Team'}
                                // search={this.state.selected ? true : false}
                                // stopSearching={() => this.stopSearching()}
                                // LoadMore={this.UserFavTeam}

                                items={this.props.teams}
                                searchItem={(teamId) => this.handleSelectId(teamId)}
                                text={'Search with Team'}
                                search={this.state.teamId ? true : false}
                                currentPage={this.props.currentPage}
                                searchTeam={this.props.searchTeam}
                                getItems={this.props.getAllTeams}
                                rowCount={this.props.teamRowCount}
                                LoadMore={this.loadMore}
                                stopSearching={() => this.stopSearching()}

                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>

                    <Button caption="Add Team Activity" className="btn-primary add-button" btnFun={() => this.setState({ show: true })} style={{ display: 'none' }} />
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Team</th>
                                <th>Watch Count</th>
                                <th>Quiz Count</th>
                                <th>Total Count</th>
                                <th>Active</th>
                                {/**
                                <th>Actions</th>
                            */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.search ?
                                    this.props.user_team_activities?.map((activity, index) => {
                                        return (
                                            activity?.UserTeamActivity &&
                                            <tr key={index}>
                                                <td>{activity?.User?.user_name} </td>
                                                <td> {activity?.Team?.name}</td>
                                                <td>{activity?.UserTeamActivity?.for_team_watch_count}</td>
                                                <td>{activity?.UserTeamActivity?.for_team_quizzes_count}</td>
                                                <td>{activity?.UserTeamActivity?.total_count}</td>
                                                <td>
                                                    {activity.active === 1 ? "Active" : "Inactive"}
                                                </td>
                                                <td></td>
                                            </tr>
                                        )
                                    })

                                    :

                                    this.props.user_team_activities?.map((activity, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{activity.UserFavTeam?.User?.user_name} </td>
                                                <td> {activity.UserFavTeam?.Team?.name}</td>
                                                <td>{activity.for_team_watch_count}</td>
                                                <td>{activity.for_team_quizzes_count}</td>
                                                <td>{activity.total_count}</td>
                                                <td>
                                                    {activity.active === 1 ? "Active" : "Inactive"}
                                                </td>
                                                <td></td>

                                            </tr>

                                        )
                                    })
                            }

                        </tbody>

                    </table>
                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        !this.props.teamId && this.props.getUserTeamActivity(pageNumber, this.props.row_count);
                        this.props.teamId && this.props.searchItem(this.state.teamId, pageNumber);
                    }} />

                    {
                        this.state.show &&
                        <UserTeamActivityCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            show={this.state.show}
                            getUserFavouriteTeams={this.props.getUserFavouriteTeams}
                            user_team_activity={this.state.user_team_activity}
                            createOrUpdate={this.createOrUpdate}
                            user_favourite_teams={this.props.user_favourite_teams}
                            currentPage={this.props.currentPage}
                            rowCount={this.state.rowCount}
                            loadMore={this.loadMore}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user_favourite_teams: state.user_favourite_team.user_favourite_teams,
        user_team_activities: state.user_team_activity.user_team_activities,
        currentPage: state.user_team_activity.currentPage,
        totalCount: state.user_team_activity.totalCount,
        totalCountPerPage: state.user_team_activity.totalCountPerPage,
        countries: state.country.countries,
        error: state.user_team_activity.error,
        success: state.user_team_activity.success,
        text: state.user_team_activity.text,
        row_count: state.user_team_activity.row_count,
        loading: state.user_team_activity.loading,
        teams: state.team.teams,
        teamCurrentPage: state.team.currentPage,
        textTeam: state.team.text,
        teamRowCount: state.team.row_count,
        userFavTeamId: state.user_team_activity.userFavTeamId,
        userFavRowCount: state.user_favourite_team.row_count
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUserTeamActivity: (pageNumber, row_count) => dispatch(UserTeamActivityAction.getUserTeamActivity(pageNumber, row_count)),
        createUserTeamActivity: (user_team_activity) => dispatch(UserTeamActivityAction.createUserTeamActivity(user_team_activity)),
        updateUserTeamActivity: (user_team_activity) => dispatch(UserTeamActivityAction.updateUserTeamActivity(user_team_activity)),
        deleteUserTeamActivity: (data) => dispatch(UserTeamActivityAction.deleteUserTeamActivity(data)),
        searchItem: (userFavTeamId, pageNumber) => dispatch(UserTeamActivityAction.searchItem(userFavTeamId, pageNumber)),
        getUserFavouriteTeams: (pageNumber, row_count) => dispatch(UserFavouriteTeamAction.getUserFavouriteTeams(pageNumber, row_count)),
        searchUserFavTeams: (text, pageNumber) => dispatch(UserFavouriteTeamAction.searchItem(text, pageNumber)),
        getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
        searchTeam: (text, countryId, pageNumber) => dispatch(TeamAction.searchItem(text, countryId, pageNumber)),
        // getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTeamActivityList)