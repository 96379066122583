
import axios from 'axios'
import CONSTANTS from './../constants';
import LeaderboardType from './../type/leaderboard_type';

export const Error = (data) => {
    return {
        type: LeaderboardType.LEADERBOARD_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: LeaderboardType.LEADERBOARD_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: LeaderboardType.LOADING, payload: status })
    }
}
const getTopFanUsers = (pageNumber, row_count) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}leaderboard/top_fans?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: LeaderboardType.GET_TOP_FANS, payload: response.data.data
                })
                dispatch({
                    type: LeaderboardType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count,
                })
                dispatch({
                    type: LeaderboardType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: LeaderboardType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LeaderboardType.ROW_COUNT,
                    payload: row_count,
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: LeaderboardType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LeaderboardType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeaderboardType.GET_TOP_FANS, payload: response.data.data
                })
                dispatch({
                    type: LeaderboardType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count,
                })
                dispatch({
                    type: LeaderboardType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: LeaderboardType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })

            }
            return dispatch({
                type: LeaderboardType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}
const getFavTeamUsers = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}leaderboard/fav_team_users?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            // if (response.data.code === 200) {
            //     dispatch({
            //         type: LeaderboardType.GET_TOP_FANS, payload: response.data.data
            //     })
            //     dispatch({
            //         type: LeaderboardType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count,
            //     })
            //     dispatch({
            //         type: LeaderboardType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
            //     })
            //     dispatch({
            //         type: LeaderboardType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
            //     })
            //     dispatch({
            //         type: LeaderboardType.ROW_COUNT,
            //         payload: row_count,
            //     })
            // }
            // else {
            //     let message = "Failed"
            //     dispatch({
            //         type: LeaderboardType.ERROR, payload: message
            //     })
            //     setTimeout(() => {
            //         const message = null
            //         dispatch({
            //             type: LeaderboardType.ERROR,
            //             payload: message
            //         })
            //     }, CONSTANTS.ALERT_DISPLAY_TIME)
            // }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
        })
    }
}



const searchWithTeam = (text, teamId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}cities/list?keyword=${text}&country_id=${teamId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LeaderboardType.GET_CITIES,
                    payload: response.data.data
                })
                dispatch({
                    type: LeaderboardType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: LeaderboardType.GET_CURRENT_PAGE,
                    payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LeaderboardType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: LeaderboardType.SEARCH_WITH_COUNTRY,
                    payload: teamId
                })
                dispatch({
                    type: LeaderboardType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}
// const searchCity = (text, countryId, pageNumber) => {
//     return (dispatch) => {
//         return axios.post(`${CONSTANTS.BASE_URL}cities/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}`).then(response => {
//             if (response.data.status === "Success") {
//                 dispatch({
//                     type: LeaderboardType.GET_CITIES,
//                     payload: response.data.data
//                 })

//                 dispatch({
//                     type: LeaderboardType.SEARCH_WITH_COUNTRY,
//                     payload: countryId
//                 })
//                 dispatch({
//                     type: LeaderboardType.SEARCH_ITEM,
//                     payload: text
//                 })
//             }
//         })
//     }
// }

const LeaderboardAction = {
    searchWithTeam,
    getTopFanUsers,
    getFavTeamUsers,
    loading,
    searchItem
}

export default LeaderboardAction;