const FavouriteTeamLevelRuleType = {
    GET_FAVOURITE_TEAM_LEVEL_RULES: "GET_FAVOURITE_TEAM_LEVEL_RULES",
    ADD_FAVOURITE_TEAM_LEVEL_RULE: "ADD_FAVOURITE_TEAM_LEVEL_RULE",
    UPDATE_FAVOURITE_TEAM_LEVEL_RULE: "UPDATE_FAVOURITE_TEAM_LEVEL_RULE",
    DELETE_FAVOURITE_TEAM_LEVEL_RULE: "DELETE_FAVOURITE_TEAM_LEVEL_RULE",
    FAVOURITE_TEAM_LEVEL_RULE_ERROR: "FAVOURITE_TEAM_LEVEL_RULE_ERROR",
    FAVOURITE_TEAM_LEVEL_RULE_SUCCESS: "FAVOURITE_TEAM_LEVEL_RULE_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default FavouriteTeamLevelRuleType;