import React from 'react'
import { LiveStreamingCreateController } from './LiveStreamingCreateController'
import NewForm from './NewForm';

import { Modal, Button, Input, styled, SelectModal } from '../../../components/ImportsFile'
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import Alert from './../../../components/alert/Alert';

class LiveStreamingCreate extends LiveStreamingCreateController {
  render() {
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Live Streaming' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Live Streaming' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    let header;
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Live Streaming' : 'Add Live Streaming'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">

          <div className="modalBody livestreamCreate" style={{ width: '90%', margin: 'auto' }}>

            {
              this.props.isEdit &&
              <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
            }
            {
              (this.props.loading && this.props.isEdit) && <PageLoader />
            }
            <form autoComplete="off" style={{ width: '60%', margin: 'auto' }}>
              {
                this.props.requiredError &&
                <p className="requiredError">
                  {
                    this.state.livestream?.newURLs?.length > 0 ? '' : "*" + this.props.requiredError
                  }
                </p>
              }

              <SelectModal
                items={this.state.matches}
                noMatchForLivestream={this.state.matches?.length > 0 ? false : true}
                routeChangeName="match"
                itemName={this.props.isEdit ?
                  (
                    this.props.livestream.livestream.Match.HomeTeam.name + " Vs " + this.props.livestream.livestream.Match.AwayTeam.name
                  )
                  :
                  "*** Choose Match ***"}
                handleProcess={(match_id) => this.handleMatches(match_id)}
                LoadMore={this.props.loadMore}
              />

              <select name="active" className="select" value={this.state.livestream.active} onChange={this.handleSelect} required >
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </select>

              <br />
              <br />

              {
                !this.props.isEdit &&

                <Button type="button" id="submit-btn" className="btn-secondary add-url" caption={"Create"} btnFun={() => this.props.createOrUpdate(this.state.livestream)} />
              }
              <Button type="button" id="submit-btn" className="btn-gray" caption="Add URL" btnFun={() => this.toggleForm(true)} />

              {
                this.state.showForm &&
                <NewForm
                  showForm={this.state.showForm}
                  editNewForm={this.state.editNewForm}
                  newLiveStream={this.state.newLiveStream}
                  saveNewFields={this.saveNewFields}
                  updateNewFields={this.updateNewFields}
                  updateLiveStream={this.updateLiveStream}
                  addNewLivestream={this.addNewLivestream}
                  toggleForm={() => this.toggleForm()}
                  isEdit={this.props.isEdit}
                />
              }


            </form>

            <br />
            <br />

            {
              !this.state.livestream?.newURLs ? <PageLoader />
                :
                <div style={{ width: '90%', margin: 'auto' }}>

                  {
                    this.state.livestream?.newURLs?.length > 0 &&
                    this.state.livestream?.newURLs?.map((url, index) => {
                      if (this.props.isEdit && typeof url.headers == "string") {
                        const headers = [];
                        let newHeaders = JSON.parse(url.headers)
                        if (newHeaders) {
                          let key = Object.keys(newHeaders)
                          let value = Object.values(newHeaders)
                          for (let i = 0; i < key.length; i++) {
                            headers.push({ headerKey: key[i], headerValue: value[i], icon: 'fa fa-trash' });
                          }
                        }
                        header = headers;
                      } else {
                        header = url.headers
                      }
                      return (
                        <div key={index}>
                          <ListingContainer>
                            <ChannelContainer>
                              <p>
                                Ch {url.channel_no ? url.channel_no : index + 1}
                              </p>
                            </ChannelContainer>
                            <URLContainer>
                              <p style={{ padding: '0px', margin: '0px' }}>
                                <span> <b>URL : </b>  {url.live_stream_url}</span>
                              </p>
                              {
                                header?.map((header, i) => {
                                  return (
                                    <p key={i}>
                                      <span> <b>{header.headerKey} : </b></span> {header.headerValue}
                                    </p>
                                  )
                                })
                              }

                            </URLContainer>
                            <ActionContainer>
                              <Modal deleteItem={() => this.deleteItem(index, url)} editItem={() => this.handleEdit(index, url)} updatePermission={updatePermission} deletePermission={deletePermission} />
                            </ActionContainer>

                          </ListingContainer>
                          <hr style={{ color: '#ffffff', width: '100%', height: '2px', zIndex: '0' }} />
                        </div>
                      )
                    })

                  }
                </div>
            }


          </div>
        </div >
      </div >
    )
  }
}
export {
  LiveStreamingCreate
}
export const ListingContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  color:#ffffff;
  align-items:center;
  font-size:14px;
  padding:10px 0px;
`
export const ChannelContainer = styled.div`
  flex:1;
  align-items:center;
  p{
    min-width:50px;
    display:flex;
    flex-wrap:no-wrap;
    color:#fad230;
    font-weight:bold;
    padding:0px;
    margin:0px;
    word-wrap: normal; 
  }
`
export const URLContainer = styled.div`
  flex:10;
  flex-direction:column;
  padding:0px 8px 0px 8px;
  margin:0px 8px 0px 8px;
  align-items:center;
  p{
    max-width: 470px; 
    word-wrap: break-word; 
    padding:0px;
    margin:0px;     
  }
`
export const ActionContainer = styled.div`
  flex:2;
  align-items:center;
`
