import axios from 'axios'
import CONSTANTS from './../constants';
import PlayerType from './../type/player_type';

export const Error = (data) => {
  return {
    type: PlayerType.PLAYER_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: PlayerType.PLAYER_SUCCESS, payload: data
  }
}
const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: PlayerType.LOADING, payload: status });
  }
}
const getPlayers = (teamId, pageNumber, row_count) => {

  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}players/list?team_id=${teamId}&page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: PlayerType.GET_PLAYERS, payload: response.data.data
        })
        dispatch({
          type: PlayerType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PlayerType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PlayerType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: PlayerType.ROW_COUNT, payload: row_count
        })
        dispatch({
          type: PlayerType.SEARCH_WITH_TEAMID, payload: teamId
        })
        // dispatch({
        //   type: PlayerType.SEARCH_WITH_LEAGUEID, payload: leagueId
        // })
      } else {
        let message = "Failed"
        dispatch({
          type: PlayerType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PlayerType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createPlayer = (player) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}players/create`, player).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: PlayerType.ADD_PLAYER, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const migratePlayer = (migrate_player) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}migration/create-players`, migrate_player).then((response) => {
      if (response.data.message === "Migration is successful") {
        dispatch({
          type: PlayerType.ADD_PLAYER, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const updatePlayer = (player) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}players/update`, player).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: PlayerType.UPDATE_PLAYER, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)

      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const deletePlayer = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'))
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}players/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: PlayerType.DELETE_PLAYER, payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "Failed"
        dispatch({
          type: PlayerType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PlayerType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const searchPlayer = (text, position, leagueId, teamId, row_count, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}players/list?keyword=${text}&position=${position}&league_id=${leagueId}&team_id=${teamId}&row_count=${row_count}&page_at=${pageNumber}&sort_by=name&order_by=ASC`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: PlayerType.GET_PLAYERS,
          payload: response.data.data
        })
        dispatch({
          type: PlayerType.GET_TOTAL_COUNT,
          payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PlayerType.GET_CURRENT_PAGE,
          payload: response.data.pagination.page_at
        })
        dispatch({
          type: PlayerType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
        })
        dispatch({
          type: PlayerType.ROW_COUNT, payload: row_count
        })
        dispatch({
          type: PlayerType.SEARCH_WITH_POSITION,
          payload: position
        })
        dispatch({
          type: PlayerType.SEARCH_ITEM,
          payload: text
        })
        dispatch({
          type: PlayerType.SEARCH_WITH_TEAMID,
          payload: teamId
        })
        dispatch({
          type: PlayerType.SEARCH_WITH_LEAGUEID, payload: leagueId
        })
      }
    })
  }
}




const PlayerAction = {
  getPlayers,
  createPlayer,
  deletePlayer,
  updatePlayer,
  migratePlayer,
  loading,
  searchPlayer
}
export default PlayerAction