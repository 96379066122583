import  { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';

class ScoreDetailController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scoreDetail: {},
      scores: {},
      stats: true,
      lineup: false,
      table: false,
    }
  }

  componentDidMount = () => {
    const matchId = this.props.match.params.matchId
    axios.post(`${CONSTANTS.BASE_URL}livescores/matchDetail/${matchId}`).then((response) => {
      this.setState({
        scoreDetail: response.data
      })
    })


  }
  showStats = () => {
    this.setState({
      stats: true,
      lineup: false,
      table: false,
    })
  }
  showLineUp = () => {
    this.setState({
      stats: false,
      lineup: true,
      table: false,
    })
  }
  showTable = () => {
    this.setState({
      stats: false,
      lineup: false,
      table: true,
    })
  }
}

export default ScoreDetailController