import CONSTANTS from './../constants';
import axios from 'axios'
import UserType from './../type/user_type';

export const Error = (data) => {
    return {
        type: UserType.USER_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: UserType.USER_SUCCESS, payload: data
    }
}
const getUsers = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=user_name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.GET_USERS,
                    payload: response.data.data
                })
                dispatch({
                    type: UserType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: UserType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: UserType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Fail"
                dispatch({
                    type: UserType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllUsers = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=user_name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.GET_USERS,
                    payload: response.data.data
                })
                dispatch({
                    type: UserType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Fail"
                dispatch({
                    type: UserType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createUser = (user) => {
    return (dispatch) => {
        dispatch(loadingUser(true))
        return axios.post(`${CONSTANTS.BASE_URL}users/create`, user).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.ADD_USER,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateUser = (user) => {
    return (dispatch) => {
        return axios.put(`${CONSTANTS.BASE_URL}users/update`, user).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.UPDATE_USER,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteUser = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}users/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.DELETE_USER,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail"
                dispatch({
                    type: UserType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, active, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/list?keyword=${text}&active=${active}&page_at=${pageNumber}&sort_by=user_name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.GET_USERS, payload: response.data.data
                })
                dispatch({
                    type: UserType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: UserType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: UserType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserType.SEARCH_ITEM,
                    payload: text
                })
                dispatch({
                    type: UserType.SEARCH_WITH_ACTIVE,
                    payload: active
                })
                // dispatch({
                //     type: UserType.ROW_COUNT, payload: row_count
                // })
            }

        })
    }
}
const searchUser = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.GET_USERS, payload: response.data.data
                })
            }
            return dispatch({
                type: UserType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}


const loadingUser = (status) => {
    return (dispatch) => {
        return dispatch({ type: UserType.LOADING, payload: status });
    }
}

const getSingleUser = (id) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}users/detail`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserType.GET_USERS,
                    payload: response.data.data
                })

            } else {
                let message = "Fail"
                dispatch({
                    type: UserType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
        })
    }
}
const UserAction = {
    getUsers,
    getAllUsers,
    createUser,
    updateUser,
    deleteUser,
    loadingUser,
    searchItem,
    getSingleUser,
    searchUser,
}
export default UserAction
