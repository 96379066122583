import React from 'react'
import ScoreTableController from './ScoreTableController';
import { ScrollTable, Header } from '../ScoreDetailElement'
import SampleTeam from '../../../../images/icons/team.png'
import { withRouter } from '../../../../components/routers/withRouter'
import { PageLoader } from './../../../../components/pageLoader/PageLoader';
import fallBackTeam from '../../../../images/icons/team.png'
class ScoreTable extends ScoreTableController {
  render() {
    const teamUrl = `https://images.fotmob.com/image_resources/logo/teamlogo/`
    return (
      <ScrollTable>
        <Header>
          <div>
            <button className={this.state.showAll ? "active" : ''} onClick={this.getAllTeams}>All</button>
            <button className={this.state.showHomeTeam ? "active" : ''} onClick={this.getHomeTeam}>Home</button>
            <button className={this.state.showAwayTeam ? "active" : ''} onClick={this.getAwayTeam}>Away</button>
          </div>
          <form className="search search-score" onSubmit={this.handleSearch} >
            <div className="input-group">
              <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="text" onChange={this.handleInputText} autoComplete="off" />
              <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>
        </Header>
        {
          this.state.loading ?
            <PageLoader />
            :

            <>

              {
                (this.state.tables && this.state.tables.length > 1) ? this.state.tables.map((table, index1) => {
                  return (

                    <div key={index1}>
                      <h4 className="leagueName">{table.leagueName}</h4>
                      <table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Logo</th>
                            <th>Team</th>
                            <th>PI</th>
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>+/-</th>
                            <th>GD</th>
                            <th>Pts</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            this.state.showAll && table?.table?.all.filter((val) => {
                              if (this.state.text === '') {
                                return val;
                              }
                              else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                                return val
                              }
                            }).map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.idx}</td>
                                  <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                  <td>{data.name}</td>
                                  <td>{data.played}</td>
                                  <td>{data.wins}</td>
                                  <td>{data.draws}</td>
                                  <td>{data.losses}</td>
                                  <td>{data.scoresStr}</td>
                                  <td>{data.goalConDiff}</td>
                                  <td>{data.pts}</td>
                                </tr>
                              )
                            })
                          }
                          {
                            this.state.showHomeTeam && table?.table?.home.filter((val) => {
                              if (this.state.text === '') {
                                return val;
                              }
                              else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                                return val
                              }
                            }).map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.idx}</td>
                                  <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                  <td>{data.name}</td>
                                  <td>{data.played}</td>
                                  <td>{data.wins}</td>
                                  <td>{data.draws}</td>
                                  <td>{data.losses}</td>
                                  <td>{data.scoresStr}</td>
                                  <td>{data.goalConDiff}</td>
                                  <td>{data.pts}</td>
                                </tr>
                              )
                            })
                          }
                          {
                            this.state.showAwayTeam && table?.table?.away.filter((val) => {
                              if (this.state.text === '') {
                                return val;
                              }
                              else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                                return val
                              }
                            }).map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.idx}</td>
                                  <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                  <td>{data.name}</td>
                                  <td>{data.played}</td>
                                  <td>{data.wins}</td>
                                  <td>{data.draws}</td>
                                  <td>{data.losses}</td>
                                  <td>{data.scoresStr}</td>
                                  <td>{data.goalConDiff}</td>
                                  <td>{data.pts}</td>
                                </tr>
                              )
                            })
                          }


                        </tbody>

                      </table>
                      <br />
                    </div>
                  )
                })
                  :
                  <div>
                    <h4 className="leagueName">{this.state.tables && this.state.tables.leagueName}</h4>
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Logo</th>
                          <th>Team</th>
                          <th>PI</th>
                          <th>W</th>
                          <th>D</th>
                          <th>L</th>
                          <th>+/-</th>
                          <th>GD</th>
                          <th>Pts</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          (this.state.showAll && this.state.tables) && this.state.tables?.table?.all.filter((val) => {
                            if (this.state.text === '') {
                              return val;
                            }
                            else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                              return val
                            }
                          }).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.idx}</td>
                                <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                <td>{data.name}</td>
                                <td>{data.played}</td>
                                <td>{data.wins}</td>
                                <td>{data.draws}</td>
                                <td>{data.losses}</td>
                                <td>{data.scoresStr}</td>
                                <td>{data.goalConDiff}</td>
                                <td>{data.pts}</td>
                              </tr>
                            )
                          })
                        }
                        {
                          (this.state.showHomeTeam && this.state.tables) && this.state.tables?.table?.home.filter((val) => {
                            if (this.state.text === '') {
                              return val;
                            }
                            else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                              return val
                            }
                          }).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.idx}</td>
                                <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                <td>{data.name}</td>
                                <td>{data.played}</td>
                                <td>{data.wins}</td>
                                <td>{data.draws}</td>
                                <td>{data.losses}</td>
                                <td>{data.scoresStr}</td>
                                <td>{data.goalConDiff}</td>
                                <td>{data.pts}</td>
                              </tr>
                            )
                          })
                        }
                        {
                          (this.state.showAwayTeam && this.state.tables) && this.state.tables?.table?.away.filter((val) => {
                            if (this.state.text === '') {
                              return val;
                            }
                            else if ((val.name).toLowerCase().includes(this.state.text.toLowerCase())) {
                              return val
                            }
                          }).map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.idx}</td>
                                <td><img src={`${teamUrl}${data.id}.png` || SampleTeam} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} /></td>
                                <td>{data.name}</td>
                                <td>{data.played}</td>
                                <td>{data.wins}</td>
                                <td>{data.draws}</td>
                                <td>{data.losses}</td>
                                <td>{data.scoresStr}</td>
                                <td>{data.goalConDiff}</td>
                                <td>{data.pts}</td>
                              </tr>
                            )
                          })
                        }

                      </tbody>

                    </table>
                    <br />
                  </div>
              }
            </>
        }
        <br />
        <br />
        <br />
      </ScrollTable>
    )
  }
}

export default withRouter(ScoreTable)