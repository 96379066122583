
import PermissionType from './../type/permission_type';

const initialState = {
    permissions: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    loading:false,
}
const permissionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case PermissionType.GET_PERMISSIONS:
            return {
                ...state,
                loading:false,
                permissions: actions.payload
            }
        case PermissionType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case PermissionType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case PermissionType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case PermissionType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case PermissionType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case PermissionType.ADD_PERMISSION:
            return {
                ...state,
                loading:false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // permissions: state.permissions.concat(actions.payload)
                permissions: [
                    actions.payload,
                    ...state.permissions,
                ]
            }
        case PermissionType.UPDATE_PERMISSION:
            const updatePermissions = state.permissions.filter(permission => permission.id !== actions.payload.id)
            return {
                ...state,
                loading:false,
                permissions: [
                    actions.payload,
                    ...updatePermissions,
                ]
            }
        case PermissionType.DELETE_PERMISSION:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                permissions: state.permissions.filter(permission => permission.id !== actions.payload)
            }
        case PermissionType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case PermissionType.PERMISSION_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case PermissionType.PERMISSION_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default permissionReducer