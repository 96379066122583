import React from 'react'
import { TownshipCreateController } from './TownshipCreateController'
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import '../../modal/modal.css'
import SelectModal from './../../../components/selectModal/SelectModal';
class TownshipCreate extends TownshipCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Township' : 'Add Township'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                        {
                            this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                        }
                            <div>
                                <Input type="text" name="name" placeholder="Enter Township..." value={this.state.township.name} onChange={this.handleInputChange} required />

                                <SelectModal
                                    routeChangeName="city"
                                    items={this.props.cities}
                                    currentPage={this.props.currentPage}
                                    searchCity={this.props.searchCity}
                                    handleProcess={(city_id) => this.CityProcess(city_id)}
                                    getItems={this.props.getAllCities}
                                    rowCount={this.props.rowCount}
                                    LoadMore={this.props.loadMore}
                                    itemName={this.props.isEdit ? this.props.township.township.City.name : "*** Choose City ***"}
                                />

                                <select name="active" className="select" value={this.state.township.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />

                            <Button caption={this.props.isEdit ? "Update" : "Create"} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.township)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default TownshipCreate
