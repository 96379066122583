import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';
class StadiumListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            stadium: {},
            requiredError: '',
        }
    }
    componentDidMount = () => {
        this.props.getStadiums(this.state.initialPage, this.state.rowCount);
    }

    stopSearching = () => {
        this.props.getStadiums(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: ''
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }
    handleEdit = async (stadium) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            stadium: {
                ...prevState.stadium,
                stadium
            }
        }))
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let stadium;
        if (this.state.isEdit) {
            stadium = {
                id: data.id,
                name: data.name,
                total_seats: data.total_seats,
                active: data.active,
                updated_by: operator.id
            }
            if (stadium.name === '' || stadium.total_seats === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateStadium(stadium).then(()=>{
                    this.props.getStadiums(this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }
        } else {
            stadium = { ...data, created_by: operator.id, updated_by: operator.id }
            if (stadium.name === '' || stadium.total_seats === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createStadium(stadium).then(()=>{
                    this.props.getStadiums(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
}

export default StadiumListController