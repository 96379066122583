import React, { Component } from 'react';
class LogInPage extends Component {
    state = {

    }
    render() {
        return (
            <div>this is login page</div>
        );
    }
}



export {LogInPage};