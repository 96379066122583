import React from 'react'
import { RoleCreate } from '../create/RoleCreate'
import { RoleListController } from './RoleListController'
import RoleAction from './../../../redux/action/role_action';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, Search, connect } from '../../../components/ImportsFile'
class RoleListing extends RoleListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Role' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Role' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Role' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Roles</h4>
                    <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Role" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>

                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.roles.map((role) => {
                                    return (
                                        <tr key={role.id}>
                                            <td>{role.name}</td>
                                            <td>{role.description}</td>
                                            <td>{role.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteRole(role.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(role)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getRoles(pageNumber, this.props.row_count) : this.props.searchItem(this.props.text, pageNumber) }} />
                    {
                        this.state.show &&
                        <RoleCreate
                            isEdit={this.state.isEdit}
                            role={this.state.role}
                            hideModal={this.hideModal}
                            createOrUpdate={this.createOrUpdate}
                            requiredError={this.state.requiredError}
                        />
                    }

                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        roles: state.role.roles,
        error: state.role.error,
        success: state.role.success,
        currentPage: state.role.currentPage,
        totalCount: state.role.totalCount,
        totalCountPerPage: state.role.totalCountPerPage,
        text: state.role.text,
        row_count: state.role.row_count,
        loading: state.role.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: (pageNumber, row_count) => dispatch(RoleAction.getRoles(pageNumber, row_count)),
        createRole: (role) => dispatch(RoleAction.createRole(role)),
        updateRole: (role) => dispatch(RoleAction.updateRole(role)),
        deleteRole: (id) => dispatch(RoleAction.deleteRole(id)),
        searchItem: (text, pageNumber) => dispatch(RoleAction.searchItem(text, pageNumber))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleListing)