import { Component } from 'react'

class MatchAlertListController extends Component {
    constructor(props){
        super(props);
        // const token = localStorage.getItem('token')
        this.state = {
            currentIndex: -1,
            isEdit: false,
            show: false,
            list: this.returnList(),
        }
        this.returnList = this.returnList.bind(this)
        this.onAddOrEdit = this.onAddOrEdit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    returnList(){
        if(localStorage.getItem('match_alerts') === null){
            localStorage.setItem('match_alerts',JSON.stringify([]))
        }
        return JSON.parse(localStorage.getItem('match_alerts'))
    }

    onAddOrEdit = (data) =>{
        const list = this.returnList()
        if(this.state.currentIndex === -1){
            list.push(data)
        }
        else{
            list[this.state.currentIndex] = data
        }
        localStorage.setItem('match_alerts',JSON.stringify(list))
        this.setState({list, currentIndex: -1})

    }
    handleEdit =(index) =>{
        this.setState({
            currentIndex:index,
            show:true,
            isEdit: true
        })
    }
    handleDelete = (index) =>{
        const list = this.returnList()
        list.splice(index,1)
        localStorage.setItem('match_alerts',JSON.stringify(list))
        this.setState({list, currentIndex: -1})

    }
    handleClose = () =>{
        this.setState({
            show:false
        })
    }
    
}
export {
    MatchAlertListController
} 