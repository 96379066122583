import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class RolePermissionListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            role_permission: {},
            roleRowCount: CONSTANTS.ROW_COUNT,
            permissionRowCount: CONSTANTS.ROW_COUNT,
            roleId: '',
            permissionId: '',
            requiredError: '',
            rowCount: CONSTANTS.ROW_COUNT,

        }
    }

    componentDidMount = () => {
        this.loadMore1();
        this.loadMore2();
        this.props.getRolePermissions(this.state.initialPage, this.state.rowCount);
    }

    handleSelectRoleId = (roleId) => {
        this.setState({
            roleId,
            permissionId: ''
        })
    }
    handleSelectPermissionId = (permissionId) => {
        this.setState({
            permissionId,
            roleId: ''
        })
    }
    handleSearch = (e) => {
        e.preventDefault()
        this.props.searchWithRoleOrPermission(this.state.roleId, this.state.permissionId, this.state.initialPage)
    }
    loadMore1 = () => {
        this.setState({
            roleRowCount: this.state.roleRowCount + 6
        })
        this.props.getAllRoles(this.state.initialPage, this.state.roleRowCount);
    }
    loadMore2 = () => {
        this.setState({
            permissionRowCount: this.state.permissionRowCount + 6
        })
        this.props.getAllPermissions(this.state.initialPage, this.state.permissionRowCount);
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    handleEdit = async (role_permission) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            role_permission: {
                ...prevState.role_permission,
                role_permission
            }
        }))
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    stopSearching = () => {
        this.setState({
            roleId: '',
            permissionId: ''
        })
        this.props.getRolePermissions(this.state.initialPage, this.state.rowCount)
    }
    createOrUpdate = async (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let role_permission = {
            role_id: data.role_id,
            permission_id: data.permission_id,
            active: 1,
            updated_by: operator.id
        }
        if (data.description) {
            role_permission = { ...role_permission, description: data.description }
        }
        if (this.state.isEdit) {
            role_permission = { ...role_permission, id: data.id }
            if (role_permission.role_id === '' || role_permission.permission_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateRolePermission(role_permission).then(() => {
                    this.props.getRolePermissions(this.props.currentPage, this.state.rowCount)
                    this.hideModal();
                })
            }
        }
        else {
            role_permission = { ...role_permission, created_by: operator.id, updated_by: operator.id }
            if (role_permission.role_id === '' || role_permission.permission_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createRolePermission(role_permission).then(() => {
                    this.props.getRolePermissions(this.state.initialPage, this.state.rowCount)
                    this.setState({
                        requiredError: ''
                    })
                    this.hideModal();
                })
            }
        }

    }
}
export {
    RolePermissionListController
}
