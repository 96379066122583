const LeaderboardType = {
    GET_TOP_FANS: "GET_TOP_FANS",
    GET_FAV_TEAM_USERS: "GET_FAV_TEAM_USERS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM:'SEARCH_ITEM',
    SEARCH_WITH_TEAM: "SEARCH_WITH_TEAM",
    LOADING: "LOADING",
    LEADERBOARD_ERROR:'LEADERBOARD_ERROR',
    LEADERBOARD_SUCCESS:'LEADERBOARD_SUCCESS',
    ERROR: "ERROR"

}
export default LeaderboardType;