import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class RoleListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEdit: false,
            show: false,
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            role: {},
            requiredError: '',
            text: ''
        }
    }

    componentWillMount() {
        this.props.getRoles(this.state.initialPage, this.state.rowCount);
    }

    stopSearching = () => {
        this.props.getRoles(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    handleEdit = async (role) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            role: {
                ...prevState.role,
                role
            }
        }))
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let role = {
            name: data.name,
            active: data.active,
            updated_by: operator.id,
        }

        if (data.description) {
            role = { ...role, description: data.description }
        }
        if (this.state.isEdit) {
            role = { ...role, id: data.id }

            if (role.name === '') {
                this.setState({
                    requiredError: "Name field is required"
                })
            }
            else {
                this.props.updateRole(role).then(() => {
                    this.props.getRoles(this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }

        }
        else {
            role = { ...role, created_by: operator.id, updated_by: operator.id }
            if (role.name === '') {
                this.setState({
                    requiredError: "Name field is required"
                })
            }
            else {
                this.props.createRole(role).then(() => {
                    this.props.getRoles(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
    }
}
export {
    RoleListController
} 