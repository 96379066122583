import React, { Component } from 'react'
import fallBackPlayer from './../../../../images/icons/player1.png'
import fallBackTeam from '../../../../images/icons/team.png'
import PlayerDetail from './PlayerDetail';
class TopPlayer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            playerId: '',
            showPlayerDetailModal: false,
        }
    }
    showPlayerDetail = (playerId) => {
        this.setState((prevState) => ({
            ...prevState,
            showPlayerDetailModal: true,
            playerId: playerId
        }))
    }
    hidePlayerDetail = () => {
        this.setState({
            showPlayerDetailModal: false,
        })
    }


    render() {
        const teamLogo = 'https://images.fotmob.com/image_resources/logo/teamlogo/'
        const playerImageUrl = 'https://images.fotmob.com/image_resources/playerimages/'
        const homeTopPlayer = this.props.topPlayers.homeTopPlayers.map((homePlayer, index) => {
            return homePlayer
        })
        const awayTopPlayer = this.props.topPlayers.awayTopPlayers.map((awayPlayer, index) => {
            return awayPlayer
        })
        return (
            <div className="scoreBody" >
                <h5>Top Players</h5>
                <div className="top_players">
                    {
                        this.props.topPlayers && Object.values(homeTopPlayer).map((player, index) => {
                            return (

                                <div key={index}>
                                    <div className="top_player" onClick={() => this.showPlayerDetail(player.playerId)} >
                                        <span className="rating badge rounded-pill" style={{ backgroundColor: player.playerRatingRounded > 8 && 'green', color: player.playerRatingRounded > 8 && 'white' }}>{player.playerRatingRounded}</span>
                                        <img src={`${playerImageUrl}${player.playerId}.png`} alt="" width="80px" onError={(e) => (e.currentTarget.src = fallBackPlayer)} />
                                        <span className="team_logo rounded-pill badge">
                                            <img src={`${teamLogo}${player.teamId}.png`} alt="" width="25px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                        </span>
                                        <p>{player.name?.firstName} {player.name?.lastName}</p>
                                    </div>

                                </div>
                            )
                        })
                    }
                    {
                        this.props.topPlayers && Object.values(awayTopPlayer).map((player, index) => {
                            return (

                                <div key={index}>
                                    <div className="top_player" onClick={() => this.showPlayerDetail(player.playerId)} >
                                        <span className="rating badge rounded-pill" style={{ backgroundColor: player.playerRatingRounded > 8 && 'green', color: player.playerRatingRounded > 8 && 'white' }}>{player.playerRatingRounded}</span>
                                        <img src={`${playerImageUrl}${player.playerId}.png`} alt="" width="80px" onError={(e) => (e.currentTarget.src = fallBackPlayer)} />
                                        <span className="team_logo rounded-pill badge">
                                            <img src={`${teamLogo}${player.teamId}.png`} alt="" width="25px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                        </span>
                                        <p>{player.name?.firstName} {player.name?.lastName}</p>
                                    </div>

                                </div>


                            )
                        })
                    }
                    {
                        this.state.showPlayerDetailModal && <PlayerDetail lineups={this.props.lineups} playerId={this.state.playerId} hidePlayerDetail={this.hidePlayerDetail} />
                    }


                </div>

            </div>
        )
    }
}

export default TopPlayer


