import { Component } from 'react'

class FavouriteTeamLevelRuleCreateController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      favourite_team_level_rules: [],
      favourite_team_level_rule: props.isEdit === false ? {
        level_name: "",
        level_number: "",
        from_min_count: "",
        to_max_count: '',
        reward_points: '',
        discountable_points: '',
        active: 1
      } : props.favourite_team_level_rule.favourite_team_level_rule
    }
  }

  LevelName = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        level_name: e.target.value
      }
    }))
  }
  LevelNumber = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        level_number: e.target.value
      }
    }))
  }
  FromMinCount = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        from_min_count: e.target.value
      }
    }))
  }
  ToMaxCount = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        to_max_count: e.target.value
      }
    }))

  }
  RewardPoints = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        reward_points: e.target.value
      }
    }))
  }
  DiscountablePoint = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        discountable_points: e.target.value
      }
    }))
  }
  handleActive = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      favourite_team_level_rule: {
        ...prevState.favourite_team_level_rule,
        active: e.target.value
      }
    }))
  }

}

export { FavouriteTeamLevelRuleCreateController }
