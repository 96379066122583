import React from 'react'
import { Button } from './../../../components/buttons/Button';
import { Input } from './../../../components/inputs/Input'
import GlobalLevelRuleCreateController from './GlobalLevelRuleCreateController';
class GlobalLevelRuleCreate extends GlobalLevelRuleCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Global Level Rule' : 'Add Global Level Rule'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            <div>
                                {
                                    this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                                }

                                <Input type="text" name="top_fan_level_number" value={this.state.global_level_rule.top_fan_level_number} onChange={this.TopFanLevelNumber} placeholder={this.props.global_level_rules.length > 0 ? "Enter Level Number...(must be greater than " + Number(this.props.global_level_rules[0].top_fan_level_number) + ")" : "Enter Level Number..."} required
                                // disabled={this.props.isEdit && true} 
                                />

                                <Input type="number" name="from_min_count" value={this.state.global_level_rule.from_min_count} onChange={this.FromMinCount} placeholder={this.props.global_level_rules.length > 0 ? "Enter Min Count...(must be greater than " + Number(this.props.global_level_rules[0].to_max_count) + ")" : "Enter Min Count..."}
                                // disabled={this.props.isEdit && true}
                                />

                                <Input type="number" name="to_max_count" value={this.state.global_level_rule.to_max_count} onChange={this.ToMaxCount} placeholder="Enter Max Count..." />

                                <select name="active" className="select" value={this.state.global_level_rule.active} onChange={this.handleActive} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                                <br />
                                <br />
                                <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.global_level_rule)} />
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default GlobalLevelRuleCreate