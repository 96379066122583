
const OddsRateType = {
  GET_ODDS_RATES: "GET_ODDS_RATES",
  ADD_ODDS_RATE: "ADD_ODDS_RATE",
  UPDATE_ODDS_RATE: "UPDATE_ODDS_RATE",
  DELETE_ODDS_RATE: "DELETE_ODDS_RATE",
  ODDS_RATE_ERROR: "ODDS_RATE_ERROR",
  ODDS_RATE_SUCCESS: "ODDS_RATE_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOGGLE_EDIT: "TOGGLE_EDIT",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  SEARCH_WITH_SPECIAL_QUIZ_DETAIL_ID: "SEARCH_WITH_SPECIAL_QUIZ_DETAIL_ID",
  TOTAL_PAGE: "TOTAL_PAGE",
  GET_PER_PAGE: "GET_PER_PAGE",
  SEARCH_ITEM: "SEARCH_ITEM",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}

export default OddsRateType;