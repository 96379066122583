const CoinPolicyType = {
  GET_COIN_POLICIES: "GET_COIN_POLICIES",
  ADD_COIN_POLICY: "ADD_COIN_POLICY",
  UPDATE_COIN_POLICY: "UPDATE_COIN_POLICY",
  COIN_POLICY_ERROR: "COIN_POLICY_ERROR",
  COIN_POLICY_SUCCESS: "COIN_POLICY_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default CoinPolicyType