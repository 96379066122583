import React from 'react'
import { Button } from '../../../components/buttons/index'
import { Table } from '../../../components/tables/index'
import { MatchAlertCreate } from '../create/MatchAlertCreate'
import { MatchAlertListController } from './MatchAlertListController'

import { MainContainer, Header, Body } from '../../PagesElement'
export class MatchAlertList extends MatchAlertListController {

    render() {
       
        const header = ["Match", "User", "Active", "Edit", "Delete"];

        return (
            <MainContainer>
                <Header>
                    <h2>Match Alert Lists</h2>
                    <Button caption="Add Match Alert" className="btn-primary" btnFun={() => this.setState({ show: true })} />
                </Header>
                <Body>


                    <Table headers={header} rows={this.state.list} handleEdit={(index) => this.handleEdit(index)} handleDelete={this.handleDelete} />

                    <MatchAlertCreate show={this.state.show} handleClose={this.handleClose} isEdit={this.state.isEdit} onAddOrEdit={this.onAddOrEdit} currentIndex={this.state.currentIndex} list={this.state.list} />
                </Body>
            </MainContainer>

        )
    }
}
