
import CONSTANTS from './../constants';
import axios from 'axios'
import QuizAnswerType from './../type/quiz_answer_type';

export const Error = (data) => {
    return {
        type: QuizAnswerType.QUIZ_ANSWER_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: QuizAnswerType.QUIZ_ANSWER_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: QuizAnswerType.LOADING, payload: status });
    }
}
const getQuizAnswers = (quizId, quizDetailId, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}quiz_answer/list?special_quiz_id=${quizId}&special_quiz_detail_id=${quizDetailId}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            // return axios.post(`${CONSTANTS.BASE_URL}quiz_answer/list?special_quiz_id=${quizId}&special_quiz_detail_id=${quizDetailId}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizAnswerType.GET_QUIZ_ANSWERS,
                    payload: response.data.data
                })
                dispatch({
                    type: QuizAnswerType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: QuizAnswerType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: QuizAnswerType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: QuizAnswerType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: QuizAnswerType.SERCH_WITH_QUIZ_DETAIL_ID, payload: quizDetailId
                })
                dispatch({
                    type: QuizAnswerType.SERCH_WITH_QUIZ_ID, payload: quizId
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: QuizAnswerType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuizAnswerType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const addQuizAnswer = (answer) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}quiz_answer/create`, answer).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: QuizAnswerType.ADD_QUIZ_ANSWER,
                    payload: response.data.data
                })
                dispatch(loading(false))
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }

}

const updateQuizAnswer = (answer) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}quiz_answer/update`, answer).then((response) => {
            if (response.data.code === 200) {
                dispatch({
                    type: QuizAnswerType.UPDATE_QUIZ_ANSWER, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}
const QuizAnswerAction = {
    getQuizAnswers,
    addQuizAnswer,
    updateQuizAnswer,
    loading
}
export default QuizAnswerAction