import React, { Component } from 'react'
import moment from 'moment'
class SiteConfigsCreateController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      config: props.isEdit === false ? {
        registration_reward: '',
        start_date: '',
        end_date: '',
        duration_reward: '',
        active: 1,
      } : {
        ...props.config.config,
        start_date: props.config.config.start_date ? moment(props.config.config.start_date).format("yyyy-MM-DD") : '',
        end_date: props.config.config.end_date ? moment(props.config.config.end_date).format("yyyy-MM-DD") : ''
      }
    }
  }

  RegistrationReward = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        registration_reward: e.target.value
      }
    }))
  }
  StartDate = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        start_date: e.target.value
      }
    }))
  }
  EndDate = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        end_date: e.target.value
      }
    }))
  }
  DurationReward = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        duration_reward: e.target.value
      }
    }))
  }
  processActive = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        active: e.target.value
      }
    }))
  }

}

export default SiteConfigsCreateController
