import React from 'react';
import { Input } from '../../components/inputs/index';
import YellowGoat from './image/YellowGoat.png';
import './LoginLayout.css';
import { LoginLayoutController } from './LoginLayoutController';
import { Navigate } from 'react-router-dom';

class LoginLayout extends LoginLayoutController {

    render() {
        if (this.state.loggedIn) {
            return <Navigate to="/home" />
        }
        return (
            <div className="loginForm">
                <div className="card">
                    <div className="logo">
                        <img src={YellowGoat} alt="YellowGoat" />
                    </div>
                    <h4>G.O.A.T Live TV</h4>
                    <small>Version : 1.0.0</small>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                            <Input type="text" name="email" placeholder="Enter Your Email" value={this.state.email} onChange={this.handleChange} autoComplete="off" />
                            <div className="input-container">
                                <Input type={this.state.showPassword ? "text" : "password"} name="password" placeholder="Enter Your Password" value={this.state.password} onChange={this.handleChange} autoComplete="off" />
                                <i className={this.state.showPassword ? "fa fa-eye icon" : "fa fa-eye-slash icon"} onClick={this.togglePassword}></i>
                            </div>

                            <button caption="Login" type="submit" id="login-btn" >Login</button>
                        </form>
                    </div>
                </div>

            </div>
        );
    }
}

export default LoginLayout;




