import { CountryListController } from './CountryListController';
import CountryCreate from '../create/CountryCreate'
import CountryAction from './../../../redux/action/country_action';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, connect } from '../../../components/ImportsFile'
class CountryListing extends CountryListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Country' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Country' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Country' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Countries</h4>

                    <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>

                    {
                        createPermission &&
                        <Button icon="" btnFun={this.showModal} caption="Add Country" className="btn-primary add-button" />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Flag</th>
                                <th>Country</th>
                                <th>Code</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                this.props.countries.map((country, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <img src={country.flag} alt="choosed images" />
                                            </td>
                                            <td>{country.name}</td>
                                            <td>{country.country_code}</td>
                                            <td>{country.active === 1 ? 'Active' : 'Inactive'}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteCountry(country.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(country)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                    {/*
                        this.props.text && <RecordCount searchCount={this.props.totalCount} />
                        */}
                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getCountries(pageNumber, this.props.row_count) : this.props.searchItem(this.state.text, pageNumber) }} />

                    {
                        this.state.show && <CountryCreate hideModal={this.hideModal} country={this.state.country} isEdit={this.state.isEdit} createOrUpdate={this.createOrUpdate} requiredError={this.state.requiredError} />
                    }

                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countries: state.country.countries,
        error: state.country.error,
        success: state.country.success,
        currentPage: state.country.currentPage,
        totalCount: state.country.totalCount,
        perPage: state.country.perPage,
        text: state.country.text,
        row_count: state.country.row_count,
        loading: state.country.loading,
        totalCountPerPage: state.country.totalCountPerPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountries: (pageNumber, row_count) => dispatch(CountryAction.getCountries(pageNumber, row_count)),
        createCountry: (country) => dispatch(CountryAction.addCountry(country)),
        updateCountry: (country) => dispatch(CountryAction.updateCountry(country)),
        deleteCountry: (id) => dispatch(CountryAction.deleteCountry(id)),
        searchItem: (text, pageNumber) => dispatch(CountryAction.searchItem(text, pageNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryListing)
