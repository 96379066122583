import { Component } from 'react';
class TownshipCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            township: props.isEdit === false ? {
                name: "",
                city_id: "",
                active: 1
            } : props.township.township
        }
    }
    handleInputChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            township: {
                ...prevState.township,
                name: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            township: {
                ...prevState.township,
                active: e.target.value
            }
        }))
    }
    CityProcess = (city_id) => {
        this.setState((prevState) => ({
            ...prevState,
            township: {
                ...prevState.township,
                city_id: city_id
            }
        }))
    }
}
export {
    TownshipCreateController
}