
import AccessToMatchType from './../type/access_to_match_type';
const initialState = {
    access_to_matches: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    userId: '',
    matchId: '',
    totalCountPerPage: null,
    row_count: 1,
    loading:false,
}
const accessToMatchReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case AccessToMatchType.GET_ACCESSES:
            return {
                ...state,
                loading: false,
                access_to_matches: actions.payload
            }
        case AccessToMatchType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case AccessToMatchType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case AccessToMatchType.SEARCH_WITH_USER:
            return {
                ...state,
                userId: actions.payload,
            }
        case AccessToMatchType.SEARCH_WITH_MATCH:
            return {
                ...state,
                matchId: actions.payload
            }
        case AccessToMatchType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case AccessToMatchType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case AccessToMatchType.ADD_ACCESS:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                access_to_matches: [
                    actions.payload,
                    ...state.access_to_matches,
                ]
            }
        case AccessToMatchType.UPDATE_ACCESS:
            const updateAccesses = state.access_to_matches.filter(access_to_match => access_to_match.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                access_to_matches: [
                    actions.payload,
                    ...updateAccesses,
                ]
            }
        case AccessToMatchType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case AccessToMatchType.DELETE_ACCESS:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                access_to_matches: state.access_to_matches.filter(access_to_match => access_to_match.id !== actions.payload)
            }
        case AccessToMatchType.ACCESS_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case AccessToMatchType.ACCESS_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default accessToMatchReducer