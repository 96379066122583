
import styled from "styled-components";

const MainContainer = styled.div`
    width:90%;
    height:100%;
    // background-color:red;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    border-bottom:3px solid #fad230;
    .leaderBoard{
        flex:3;
        justify-content:space-between;
        align-item:center;

        img{
            margin-right:15px;
            margin-top:-5%;
        }
        span{
            
            font-size:26px;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight:bold;
            line-height:1px;
            margin-bottom:0px;
            margin-top:10%;   
    }
}
.nav{
    justify-content:end;
    flex-direction:row;
    cursor:pointer;
    .favTeam, .topFan, .quizzer{
        button {
            padding:10px 22px;
            border:1px solid #fad230;
            border-bottom:none;
            border-bottom-left-radius:none;
            border-bottom-right-radius:none;
            border-top-right-radius:5px;
            border-top-left-radius:5px;
            font-size:14px;
            font-family: "Roboto", sans-serif;
            font-weight:bold;
        }
    }
}
   
    .search{
        display:flex;
        padding-bottom:0px;
        margin-bottom:0px;
        width:65%;
        justify-content:center;
    }
    
     
`

const Body = styled.div`
    width:80%;
    height:80%;
    margin:auto;
    .leaderboard_pagination{
        margin-top:10px;
        .btn-group{
            float:right;
            button{
                padding:5px 24px;
                border:1px solid #fad230;
                border-radius:5px;
                font-size:14px;
                margin-left:10px;
                background:#202020;
                color:#fad230;
            }
            button:disabled{
                background:#2020205f;
                color:#20202055;
            }
        }
        small{
            font-size:14px;
        }
    }
    .leaderboardCard{
        margin-top:10px;
        padding:0px 10px 0px 10px;
        height: 58vh;
        overflow-y: scroll;
        ::-webkit-scrollbar{
           width:3px;
        }
        :hover::-webkit-scrollbar-thumb{
           background-image: linear-gradient(#202020, #fad230);
           border-radius: 3px;
        }
    }
    .lists{
        padding:10px;
        display:flex;
        margin:10px auto;
        align-items:center;
        background:#fad230;
        border:1px solid #202020;
        border-radius:10px;
        cursor:pointer;
        .profile{
            margin-left:20px;
            margin-right:10px;
            img{
                width:60px;
                height:60px;
                border-radius:50%;
            }
        }
        .info{
            margin-left:10px;
            flex:2;
            h6{
            }
            span{
                font-size:15px;
                img{
                    width:20px;
                    height:20px;
                    padding:0px;
                    border-radius:50%;
                    margin-right:7px;
                }
            }
        }
        .level{
            flex:2;
            text-align:left;
            font-size:15px;
            font-weight:bold;
            flex-direction:column;
            align-items:center;
            
            
            .count{
                // background:red;
                display:flex;
                flex-direction:row;
                margin-top:12px;
                padding-left:50px;
                p{
                    font-size:12px;
                }
            }
            p{
                margin:0px;
                font-weight:normal;
            }
            span{
                padding-right: 20px;
                           
                    
                small{
                    padding:7px 11px;
                    width:30px;
                    height:30px;
                    font-size:14px;
                    border-radius:50%;
                    background:#202020;
                    color:#fad230;
                   
                }
                
            }

        }
    }
    .lists:hover{
        background:transparent;
        border:1px solid #fad230;
     }
    .topFanLists{
        padding:10px;
        display:flex;
        margin:10px auto;
        align-items:center;
        background:transparent;
        border-radius:10px;
        border:1px solid #fad230;
        cursor:pointer;
        .profile{
            margin-left:20px;
            margin-right:10px;
            img{
                width:60px;
                height:60px;
                border-radius:50%;
            }
        }
        .info{
            margin-left:10px;
            flex:2;
            
            span{
                font-size:14px;
                img{
                    width:20px;
                    height:20px;
                    padding:0px;
                    border-radius:50%;
                    margin-right:7px;
                }
                 p{
                    margin-bottom:0px;
                }
            }
        }
        .level{
            flex:1;
            text-align:center;
            font-size:15px;
            font-weight:bold;
            align-items:center;
            width:30px;
            span{
                display:flex;
                justify-content:center;
                text-align:center;
                background:green;
                width:40px;
                height:40px;
                font-size:14px;
                // padding:auto;
                padding-top:10px;
                margin:auto;
                border-radius:50%;
                background:#202020;
                color:#fad230;
                
            }
        }
    }
        .topFanLists:hover{
            background:#fad230;
            border:1px solid #202020;
        }
`
const Footer = styled.div`
    width:100%;
    height:10%;
    // background-color:silver;
`
export {
    MainContainer, Header, Body, Footer
}