const SponsorType = {
  GET_SPONSORS: "GET_SPONSORS",
  ADD_SPONSOR: "ADD_SPONSOR",
  UPDATE_SPONSOR: "UPDATE_SPONSOR",
  DELETE_SPONSOR: "DELETE_SPONSOR",
  SPONSOR_ERROR: "SPONSOR_ERROR",
  SPONSOR_SUCCESS: "SPONSOR_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  ROW_COUNT: "ROW_COUNT",
  // SEARCH_ITEM: "SEARCH_ITEM",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default SponsorType