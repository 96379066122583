import React, { Component } from 'react'

class UnauthorizedPage extends Component {
  render() {
    return (
      <div className="unauthorized_page">
        <h1>Unauthorized..</h1>
      </div>
    )
  }
}

export default UnauthorizedPage
