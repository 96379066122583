import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
class PlayerListController extends Component {
  constructor(props) {
    super(props)


    this.state = {
      show: false,
      checked: true,
      isEdit: false,
      initialPage: 1,
      teamIds: [],
      player: {},
      migrate_player: {},
      teamId: '',
      leagueId: '',
      searchTerm: '',
      text: '',
      position: '',
      requiredError: '',
      rowCount: CONSTANTS.ROW_COUNT,
      leagueRowCount: CONSTANTS.ROW_COUNT,
      teamRowCount: CONSTANTS.ROW_COUNT,
      teamleagueRowCount: CONSTANTS.ROW_COUNT,
      countryRowCount: CONSTANTS.ROW_COUNT,
    }
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount = () => {
    this.props.getCoinPolicies(this.state.initialPage, this.state.rowCount)
    this.loadMoreTeam();
    this.loadMoreCountry();
    this.loadMoreLeague();
    this.getTeamIds();
    // this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount);
    this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.rowCount)
  }
  getTeamIds = () => {
    axios.post(`${CONSTANTS.BASE_URL}players/getTeamIds`).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        teamIds: response.data
      }))
    })
  }
  stopSearching = () => {
    this.setState({
      text: '',
      teamId: '',
      position: ''
    })
    this.props.getPlayers('', this.state.initialPage, this.state.rowCount);
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value,
      // position: '',
      // teamId: ''
    })
  }
  handleSelectTeam = (teamId) => {
    this.setState({
      teamId: teamId,
      // text: '',
      // position: ''
    }, () => {
      this.props.searchPlayer(this.state.text, this.state.position, this.state.leagueId, this.state.teamId, this.state.rowCount, this.state.initialPage)
    })
  }
  handleSwitch(checked) {
    this.setState({
      checked
    }, () => {
      this.props.searchPlayer(this.state.text, this.state.position, this.state.leagueId, this.state.teamId, this.state.rowCount, this.state.initialPage)
    })
  }
  handleSelectPosition = (position) => {
    let selectedPosition = "";
    if (position === 1) {
      selectedPosition = 'attackers';
    }
    else if (position === 2) {
      selectedPosition = "midfielders";
    }
    else if (position === 3) {
      selectedPosition = "defenders";
    }
    else if (position === 4) {
      selectedPosition = "goalkeepers";
    }
    else if (position === 5) {
      selectedPosition = "manager";
    }
    else if (position === 6) {
      selectedPosition = "";
    }
    this.setState({
      position: selectedPosition,
      // text: '',
      // teamId: ''
    }, () => {
      this.props.searchPlayer(this.state.text, this.state.position, this.state.leagueId, this.state.teamId, this.state.rowCount, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchPlayer(this.state.text, this.state.position, this.state.leagueId, this.state.teamId, this.state.rowCount, this.state.initialPage)
  }

  loadMoreTeam = () => {
    this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount);
    this.setState({
      teamRowCount: this.state.teamRowCount + 6
    })
  }

  loadMoreLeague = () => {
    this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
    this.setState({
      teamRowCount: this.state.teamRowCount + 6
    })
  }
  loadMoreCountry = () => {
    this.props.getCountries(this.state.initialPage, this.state.countryRowCount);
    this.setState({
      countryRowCount: this.state.countryRowCount + 6
    })
  }

  handleEdit = (player) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      checked: false,
      player: {
        ...prevState.player,
        player
      }
    }))
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }

  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }

  createOrUpdate = async (data) => {

    let operator = JSON.parse(localStorage.getItem('operator'));
    let player = {
      // image_url: data.image_url,
      name: data.name,
      position: data.position,
      country_id: data.country_id,
      team_id: data.team_id,
      type: data.type,
      active: data.active,
      updated_by: operator.id,
    }
    if (data.league_id) {
      player = { ...player, league_id: data.league_id }
    }

    if (this.state.isEdit) {
      player = { ...player, id: data.id, is_migrate: 0 }
      let formData = new FormData();
      formData.append("id", player.id)
      formData.append("name", player.name)
      formData.append("position", player.position)
      formData.append("type", player.type)
      formData.append("country_id", parseInt(player.country_id))
      formData.append("team_id", parseInt(player.team_id))
      // formData.append("is_migrate", player.is_migrate)
      formData.append("active", player.active)
      formData.append("updated_by", parseInt(operator.id))

      if (player.league_id) {
        formData.append("league_id", player.league_id)
      }
      // if (player.image_url) {
      //   formData.append("image_url", player.image_url)
      // }
      if (typeof data.image_url === "object") {
        formData.append("image_url", data.image_url)
        formData.append("is_migrate", player.is_migrate)
      }
      // if (player.league_id) {
      //   formData.append("league_id", player.league_id)
      // }
      if (player.name === '' || player.position === "" || player.type === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (player.image_url === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.updatePlayer(formData).then(() => {
          this.props.getPlayers(this.state.teamId, this.props.currentPage, this.state.rowCount)
        })
        this.hideModal();
      }

    } else {
      player = { ...player, created_by: operator.id, updated_by: operator.id }
      let formData = new FormData();
      formData.append("name", player.name)
      formData.append("position", player.position)
      formData.append("type", player.type)
      formData.append("image_url", player.image_url)
      formData.append("country_id", parseInt(player.country_id))
      formData.append("team_id", parseInt(player.team_id))
      formData.append("active", player.active)
      formData.append("created_by", parseInt(operator.id))
      formData.append("updated_by", parseInt(operator.id))
      if (player.league_id) {
        formData.append("league_id", player.league_id)
      }
      if (player.name === '' || player.position === "" || player.type === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (player.image_url === '') {
        this.setState({
          requiredError: "Please Choose Image"
        })
      }
      else {
        this.props.createPlayer(formData).then(() => {
          this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }

  migratePlayer = async (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    let updateData = {
      id: this.props.coin_policies[0]?.id,
      fotmob_migration_id: data.appId,
      updated_by: operator.id,
    }
    this.props.updateCoinPolicy(updateData)

    let migrate_player = {
      appId: data.appId,
      id: data.teamId,
      name: data.teamName,
    }

    if (migrate_player.appId === '' || migrate_player.id === "" || migrate_player.name === "") {
      this.setState({
        requiredError: "All fields are required"
      })
    }
    else if (this.state.teamIds.includes(data.localTeamId)) {
      this.setState({
        requiredError: "This team is already migrated",
      })
    }
    else {
      this.props.migratePlayer(migrate_player).then(() => {
        this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.rowCount)
        this.getTeamIds();
      })
      this.setState({
        requiredError: ''
      })
      this.hideModal();
    }
  }
}

export default PlayerListController