import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';

class LeagueListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            league: {},
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            countryId: '',
            requiredError: '',

        }
    }
    componentDidMount = () => {
        this.loadMore();
        this.props.getLeagues(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.setState({
            text: '',
            countryId: '',
        })
        this.props.getLeagues(this.state.initialPage, this.state.rowCount);
    }
    loadMore = () => {
        this.setState({
            rowCount: this.state.rowCount + 6
        })
        this.props.getAllCountries(this.state.initialPage, this.state.rowCount)
    }
    handleInputText = (e) => {
        this.setState({
            // countryId: '',
            text: e.target.value,
        })
    }
    handleSelectCountry = (countryId) => {
        this.setState({
            countryId: countryId,
            // text: ''
        }, () => {
            this.props.searchItem(this.state.text, this.state.countryId, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.countryId, this.state.initialPage)
    }

    handleEdit = async (league) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            league: {
                ...prevState.league,
                league
            }
        }))
    }

    createOrUpdate = async (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let league = {
            logo_url: data.logo_url,
            country_id: data.country_id,
            name: data.name,
            active: data.active,
            updated_by: operator.id
        }
        if (this.state.isEdit) {
            league = { ...league, id: data.id }

            let formData = new FormData();
            formData.append("id", league.id)
            formData.append("active", league.active)
            formData.append("name", league.name)
            formData.append("country_id", parseInt(league.country_id));
            formData.append("updated_by", parseInt(operator.id));
            if (data.description) {
                formData.append("description", data.description)
            }

            if (typeof league.logo_url !== "string") {
                formData.append("logo_url", league.logo_url)
            }
            if (league.name === '' || league.country_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (league.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.updateLeague(formData).then(() => {
                    this.props.getLeagues(this.props.currentPage, this.state.rowCount);
                })
                this.hideModal();
            }

        }
        else {
            league = { ...league, created_by: operator.id, updated_by: operator.id }
            let formData = new FormData();
            formData.append("active", league.active)
            formData.append("name", league.name)
            formData.append("logo_url", league.logo_url)
            formData.append("country_id", parseInt(league.country_id));
            formData.append("created_by", parseInt(operator.id))
            formData.append("updated_by", parseInt(operator.id))
            // console.log("formData :: ", formData.get("logo_url"))
            if (data.description) {
                formData.append("description", data.description)
            }

            if (league.name === '' || league.country_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (league.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.createLeague(formData).then(() => {
                    this.props.getLeagues(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
    }


    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
}

export default LeagueListController;
