
import UserQuizAnswerType from './../type/user_quiz_answer_type';
const initialState = {
    user_quiz_answers: [],
    error: null,
    success: null,
    currentPage: 1,
    quizDetailId:'',
    totalCount: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const userQuizAnswerReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case UserQuizAnswerType.GET_USER_ANSWERS:
            return {
                ...state,
                loading: false,
                user_quiz_answers: actions.payload
            }
        case UserQuizAnswerType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case UserQuizAnswerType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case UserQuizAnswerType.SERCH_WITH_QUIZ_DETAIL_ID:
            return {
                ...state,
                quizDetailId: actions.payload
            }
       
        case UserQuizAnswerType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case UserQuizAnswerType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
       
        case UserQuizAnswerType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
       
        case UserQuizAnswerType.USER_QUIZ_ANSWER_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case UserQuizAnswerType.USER_QUIZ_ANSWER_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default userQuizAnswerReducer