import React from 'react'
import { Button } from './../../../components/buttons/Button';
import { Input } from './../../../components/inputs/Input'
import SelectModal from './../../../components/selectModal/SelectModal';
import HighlightCreateController from './HighlightCreateController';
class HighlightCreate extends HighlightCreateController {
    render() {

        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Highlight' : 'Add Highlight'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off">
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div style={{ columnCount: '2' }}>
                                <SelectModal
                                    name="league_id"
                                    routeChangeName="league"
                                    items={this.props.leagues}
                                    rowCount={this.props.leagueRowCount}
                                    currentPage={this.props.leagueCurrentPage}
                                    handleProcess={(league_id) => this.leagueProcess(league_id)}
                                    itemName={this.props.isEdit ? this.props.highlight.highlight?.League.name : "*** Choose League ***"}
                                />
                                <SelectModal
                                    matchesForHighlight={this.state.matches}
                                    routeChangeName="match"
                                    itemName={
                                        this.props.isEdit ?
                                            (
                                                this.props.highlight.highlight.Match.HomeTeam.name + " Vs " + this.props.highlight.highlight.Match.AwayTeam.name
                                            )
                                            :
                                            "*** Choose Match ***"}
                                    noMatchForHighlight={this.state.matches.length === 0 && true}
                                    handleProcess={(match_id) => this.handleMatches(match_id)}
                                    LoadMore={this.state.loadMore}
                                />


                                <Input type="text" name="highlight_url" placeholder="Enter Highlight URL..." value={this.state.highlight.highlight_url} onChange={this.handleInputHighlightURL} required />


                                <Input type="number" name="home_team_goal" placeholder="Enter Home Team Goal..." value={this.state.highlight.home_team_goal} onChange={this.homeTeamGoal} required />


                                <Input type="number" name="away_team_goal" placeholder="Enter Away Team Goal..." value={this.state.highlight.away_team_goal} onChange={this.awayTeamGoal} required style={{ marginTop: ((this.props.isEdit && this.state.highlight.type == 2) || (!this.props.isEdit && this.state.highlight.type == 2)) && '12px' }} />


                                <select name="type" className="select" value={this.state.highlight.type} onChange={this.handleSelectType} required style={{ marginTop: this.state.highlight.headers.length >= 1 && '12px' }} >
                                    <option value="1">Custom</option>
                                    <option value="2">Youtube</option>
                                </select>

                                <select name="active" className="select" value={this.state.highlight.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>




                                {
                                    (this.state.highlight.headers.length === 0 && this.state.highlight.type == 1) ? (

                                        <div style={{ marginTop: "14px" }}>
                                            {
                                                this.state.addHeader &&
                                                <Button type="button" id="submit-btn" className="btn-secondary" caption="Add Header" btnFun={(e) => this.handleAddFields(e, 0)} />
                                            }
                                            <br />
                                        </div>

                                    ) :
                                        this.state.highlight.headers.map((data, index) => {
                                            return (
                                                <div key={index} className="header_highlight">

                                                    <Input type="text" name="headerKey" id="headerKey" placeholder="Header Key" value={data.headerKey || ""} onChange={event => this.handleInputChange(index, event)} className="header_highlight_key" />

                                                    <Input type="text" name="headerValue" id="headerValue" placeholder="Header Value" value={data.headerValue || ""} onChange={event => this.handleInputChange(index, event)} className="header_highlight_value" />
                                                    <button className="header_highlight_btn"
                                                        style={{
                                                            backgroundColor: data.icon === 'fa fa-trash' ? '#ffffff' : '#202020',

                                                        }}
                                                        onClick={(e) => {
                                                            data.icon === 'fa fa-trash' ?
                                                                this.handleRemoveFields(e, index) :
                                                                this.handleAddFields(e, index)
                                                        }
                                                        } >
                                                        <i className={data.icon} style={{ color: data.icon === 'fa fa-trash' ? '#ff0000' : '#fad230' }}></i>
                                                    </button>
                                                </div>
                                            )

                                        })
                                }

                                {
                                    (this.state.highlight.headers.length === 1 || this.state.highlight.headers.length === 2) &&
                                    <>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                }
                            </div>

                            <br />
                            <div style={{ display: 'flex', justifyContent: this.state.highlight.highlight_url ? 'space-between' : 'end', alignItems: 'center', fontSize: '12px' }}>
                                {
                                    this.state.highlight.highlight_url &&
                                    <a href={this.state.highlight.type === 2 && "https://www.youtube.com/watch?v=" + this.state.highlight.highlight_url}
                                        target='_blank'
                                        style={{ margin: '0px', color: '#fad230' }}>
                                        {
                                            this.state.highlight.type == 2 && "https://www.youtube.com/watch?v=" + this.state.highlight.highlight_url
                                        }
                                    </a>
                                }
                                <Button type="button" id="submit-btn" className="btn-secondary" caption={this.props.isEdit ? "Update" : "Create"} btnFun={() => this.props.createOrUpdate(this.state.highlight)} />
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        )
    }
}

export default HighlightCreate