import CONSTANTS from './../constants';
import axios from 'axios'
import StadiumType from './../type/stadium_type';

export const Error = (data) => {
    return {
        type: StadiumType.STADIUM_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: StadiumType.STADIUM_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: StadiumType.LOADING, payload: status })
    }
}
const getStadiums = (pageNumber, row_count) => {
    return (dispatch) => {

        return axios.post(`${CONSTANTS.BASE_URL}stadiums/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.GET_STADIUMS, payload: response.data.data
                })
                dispatch({
                    type: StadiumType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: StadiumType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: StadiumType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: StadiumType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Fail"
                dispatch({
                    type: StadiumType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: StadiumType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllStadiums = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}stadiums/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.GET_STADIUMS, payload: response.data.data
                })
                dispatch({
                    type: StadiumType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Fail"
                dispatch({
                    type: StadiumType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: StadiumType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createStadium = (stadium) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}stadiums/create`, stadium).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.ADD_STADIUM, payload: response.data.data
                })


                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))

                // success payload
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateStadium = (stadium) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}stadiums/update`, stadium).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.UPDATE_STADIUM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            // errorPayload['message'] = err.response.data.message;
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteStadium = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}stadiums/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.DELETE_STADIUM, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "Fail"
                dispatch({
                    type: StadiumType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: StadiumType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}stadiums/list?keyword=${text}&page_at=${pageNumber}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: StadiumType.GET_STADIUMS, payload: response.data.data
                })
                dispatch({
                    type: StadiumType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: StadiumType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: StadiumType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: StadiumType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}

const StadiumAction = {
    getStadiums,
    getAllStadiums,
    createStadium,
    updateStadium,
    deleteStadium,
    searchItem,
}
export default StadiumAction