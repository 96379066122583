import React from 'react'
import PrizeCreateController from './PrizeCreateController';
import { Button, Input, FontAwesomeIcon, faCloudUploadAlt, SelectModal } from '../../../components/ImportsFile'

class PrizeCreate extends PrizeCreateController {
  render(props) {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Prize' : 'Add Prize'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ columnCount: '2' }}>
                <Input type="number" placeholder="Enter Exchangeable Points" name="exchangeable_points" value={this.state.prize.exchangeable_points} onChange={this.ExchangeablePoints} required />

                <Input type="text" placeholder="Enter Prize Name" name="name" value={this.state.prize.name} onChange={this.handleInputChange} required />

                <Input type="text" placeholder="Enter Limit Count" name="limit_count" value={this.state.prize.limit_count} onChange={this.LimitCount} required />


                <Input type="text" placeholder="Enter Prize Description" name="description" value={this.state.prize.description || ''} onChange={this.handleInputDescription} required />

                <select name="type" className="select" value={this.state.prize.type} onChange={this.handleSelectType} required >
                  <option value="1">Main Coin</option>
                  <option value="2">Quiz Coin</option>
                </select>

                <SelectModal
                  name="league_id"
                  routeChangeName="league"
                  items={this.props.leagues}
                  value={this.state.prize.league_id}
                  rowCount={this.props.leagueRowCount}
                  currentPage={this.props.leagueCurrentPage}
                  getItems={this.props.getAllLeagues}
                  handleProcess={(league_id) => this.leagueProcess(league_id)}
                  itemName={this.props.isEdit ? this.props.prize.prize?.League?.name : "*** Choose League ***"}
                  LoadMore={this.props.loadMoreLeague}
                  searchLeague={this.props.searchLeague}
                />

                <Input type="date" placeholder="Enter Expired Time" name="expired_time" value={this.state.prize.expired_time} onChange={this.ExpiredTime} required />

                <select name="active" className="select" value={this.state.prize.active} onChange={this.handleSelect} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

              </div>

              <div className="image-upload">
                <label htmlFor="file">
                  <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#fad230" /> Choose Image
                </label>
                <input type="file" id="file" name="flag" onChange={this.onChangeImage} style={{ display: 'none' }} />
                {
                  (typeof this.state.prize.image === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.prize.image} alt="" style={{ width: '110px' }} />
                }
                {
                  (typeof this.state.prize.image !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.prize.image)} alt="" style={{ width: '110px' }} />
                }
              </div>
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.prize)} />

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PrizeCreate