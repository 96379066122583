
import CityType from './../type/city_type';
const initialState = {
    cities: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    text: '',
    countryId: '',
    loading:false,
}
const cityReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case CityType.GET_CITIES:
            return {
                ...state,
                loading: false,
                cities: actions.payload
            }
        case CityType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case CityType.SEARCH_WITH_COUNTRY:
            return {
                ...state,
                countryId: actions.payload
            }
        case CityType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case CityType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case CityType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case CityType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case CityType.ADD_CITY:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // cities: state.cities.concat(actions.payload),
                cities: [
                    actions.payload,
                    ...state.cities,
                ]

            }
        case CityType.UPDATE_CITY:
            const updateCity = state.cities.filter(city => city.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                cities: [
                    actions.payload,
                    ...updateCity,
                ]
            }
        case CityType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case CityType.DELETE_CITY:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                cities: state.cities.filter(city => city.id !== actions.payload)
            }
        case CityType.CITY_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case CityType.CITY_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default cityReducer