import { Component } from 'react'

class VersionCheckCreateController extends Component {
  constructor(props) {
    super(props)

    console.log("this.props", this.props)
    this.state = {
      version_check: props.isEdit === false ? {
        version_name: '',
        version_code: '',
        force_update: 0,
        playstore_link: '',
        appstore_link: '',
        manual_download_link: '',
        update_logs: '',
        active: 1
      } : { ...props.version_check.version_check }
    }
  }

  handleInputVersionName = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        version_name: e.target.value
      }
    }))
  }
  handleInputVersionCode = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        version_code: e.target.value
      }
    }))
  }
  handleSelectForceUpdate = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        force_update: e.target.value
      }
    }))
  }
  handleInputPlayStoreLink = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        playstore_link: e.target.value
      }
    }))
  }
  handleInputAppStoreLink = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        appstore_link: e.target.value
      }
    }))
  }
  handleInputManualDownloadLink = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        manual_download_link: e.target.value
      }
    }))
  }
  handleInputUpdateLogs = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        update_logs: e.target.value
      }
    }))
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      version_check: {
        ...prevState.version_check,
        active: e.target.value
      }
    }))
  }
}

export default VersionCheckCreateController
