import React, { Component } from 'react'

class SubStats extends Component {
    render() {

        return (
            <div>
                <div className="list">
                    <button className={this.props.showTeamData ? 'teamBtn active' : "teamBtn"} onClick={this.props.getTeamData}>Team</button>
                    <button className={this.props.showPlayerData ? 'playerBtn active' : "playerBtn"} onClick={this.props.getPlayerData}>Player</button>
                </div>
                {
                    this.props.showTeamData &&
                    <>
                        {

                            <div className="scoreBody">
                                <h6>{this.props.topStats && this.props.topStats?.stats?.stats[0]?.title}</h6>

                                {
                                    this.props.topStats && this.props.topStats.map((stats, index) => {
                                        return (
                                            <div className="score_compare" key={index}>
                                                <div className="item_lists">
                                                    <h6>{stats.title}</h6>
                                                    {
                                                        stats.stats.map((data, index) => {
                                                            return (
                                                                <div className="items" key={index}>
                                                                    {
                                                                        data.type === 'graph' ?
                                                                            <div className="bar" style={{ display: 'flex', width: '100%', flexWrap: 'wrap'}}>
                                                                                <p>{data.type === 'graph' && data.title}</p>
                                                                                <div className="home_bar" style={{ width: data.stats[0] + '%', paddingLeft: '10px', backgroundColor: data.stats[0] > data.stats[1] ? "#202020" : "#fad230", color: data.stats[0] > data.stats[1] ? "#fad230" : "#202020", }}> <span>{data.stats[0] + '%'}</span> </div>
                                                                                <div className="away_bar" style={{ width: data.stats[1] + '%', backgroundColor: data.stats[1] > data.stats[0] ? "#202020" : "#fad230", color: data.stats[1] > data.stats[0] ? "#fad230" : "#202020", }}> <span>{data.stats[1] + '%'}</span> </div>

                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="home_team_item">
                                                                                    <p>{data.stats[0]}</p>
                                                                                </div>

                                                                                <div className="item_label">
                                                                                    <p>{data.type === 'text' && data.title}</p>
                                                                                </div>

                                                                                <div className="away_team_item">
                                                                                    <p>{data.stats[1]}</p>
                                                                                </div>
                                                                            </>
                                                                    }


                                                                </div>

                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            // :
                            // <div>
                            //     <p style={{ textAlign: 'center', fontSize: '14px', paddingTop: '20px' }}>There is no data!</p>
                            // </div>
                        }
                    </>

                }
            </div>
        )
    }
}

export default SubStats