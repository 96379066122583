
import styled from "styled-components";

const MainContainer = styled.div`
    width:90%;
    height:100%;
    // background-color:red;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
    // height:10%;
    h4{
        // color:#FAD230;
        // color:#14A2B8;
        // width:300px;
        font-size:26px;
        // font-size:28px;
        font-family: "Roboto", sans-serif;
        // width:20%;
        background: -webkit-linear-gradient(#202020, #FAD230);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }
    .search{
        display:flex;
        padding-bottom:0px;
        margin-bottom:0px;
        width:65%;
        justify-content:center;
    }
    .add-button{
        float: right;       
        color:#FAD230;
        font-weight:500;
        &:hover{
            background:transparent;
        }
    }

     
`

const Body = styled.div`
    width:100%;
    height:80%;
    padding-top:20px;

    // background-color:blue;
`
const Footer = styled.div`
    width:100%;
    height:10%;
    // background-color:silver;
`
export {
    MainContainer, Header, Body, Footer
}