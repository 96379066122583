
import MatchType from './../type/match_type';
const initialState = {
    matches: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    time: '',
    startDate: '',
    endDate: '',
    teamId: '',
    leagueId: '',
    totalCountPerPage: null,
    row_count: 1,
    date: null,
    loading: false,
}
const matchReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case MatchType.GET_MATCHES:
            return {
                ...state,
                loading: false,
                matches: actions.payload
            }
        case MatchType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case MatchType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case MatchType.SEARCH_ITEM:
            return {
                ...state,
                time: actions.payload
            }
        case MatchType.SEARCH_WITH_DATE:
            return {
                ...state,
                date: actions.payload
            }
        case MatchType.SEARCH_DATA:
            return {
                ...state,
                teamId: actions.payload,
            }
        case MatchType.SEARCH_WITH_LEAGUE:
            return {
                ...state,
                leagueId: actions.payload
            }
        case MatchType.SEARCH_ITEM_S:
            return {
                ...state,
                startDate: actions.payload
            }
        case MatchType.SEARCH_ITEM_E:
            return {
                ...state,
                endDate: actions.payload
            }
        case MatchType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case MatchType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case MatchType.ADD_MATCH:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // matches: state.matches.concat(actions.payload)
                matches: [
                    actions.payload,
                    ...state.matches,
                ]
            }

        case MatchType.UPDATE_MATCH:
            const updateMatches = state.matches.filter(match => match.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                matches: [
                    actions.payload,
                    ...updateMatches,
                ]
            }
        case MatchType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case MatchType.DELETE_MATCH:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                matches: state.matches.filter(match => match.id !== actions.payload)
            }
        case MatchType.MATCH_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case MatchType.MATCH_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default matchReducer