import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';
class AccessToMatchListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            matchId: '',
            userId: '',
            access_to_match: {},
            userRowCount: CONSTANTS.ROW_COUNT,
            matchRowCount: CONSTANTS.ROW_COUNT,
            rowCount: CONSTANTS.ROW_COUNT,
            requiredError: ''
        }
    }
    componentDidMount = () => {
        this.loadMore1();
        this.loadMore2();
        this.props.getAccessToMatches(this.state.initialPage, this.state.rowCount);
    }
    handleSelectUser = (userId) => {
        this.setState({
            userId,
            matchId: ''
        }, () => {
            this.props.searchAccess(this.state.userId, this.state.matchId, this.state.initialPage)
        })
    }
    handleSelectMatch = (matchId) => {
        this.setState({
            matchId,
            userId: ''
        }, () => {
            this.props.searchAccess(this.state.userId, this.state.matchId, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchAccess(this.state.userId, this.state.matchId, this.state.initialPage)
    }
    stopSearching = () => {
        this.setState({
            userId: '',
            matchId: ''
        })
        this.props.getAccessToMatches(this.state.initialPage, this.state.rowCount)
    }
    loadMore1 = () => {
        this.setState({
            userRowCount: this.state.userRowCount + 6
        })
        this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
    }
    loadMore2 = () => {
        this.setState({
            matchRowCount: this.state.matchRowCount + 6
        })
        this.props.getAllMatches(this.state.initialPage, this.state.matchRowCount);
    }

    handleEdit = async (access_to_match) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            access_to_match: {
                ...prevState.access_to_match,
                access_to_match
            }
        }))
    }
    createOrUpdate = async (data) => {
        let access_to_match;
        if (this.state.isEdit) {
            access_to_match = {
                id: data.id,
                user_id: data.user_id,
                match_id: data.match_id,
                active: data.active,
                // updated_by: operator.id
            }
            if (access_to_match.match_id === '' || access_to_match.user_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateAccessToMatch(access_to_match).then(() => {
                    this.props.getAccessToMatches(this.props.currentPage, this.state.rowCount);
                })
                this.hideModal()
            }
        }
        else {
            access_to_match = { ...data }
            if (access_to_match.match_id === '' || access_to_match.user_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                await this.props.createAccessToMatch(access_to_match).then(() => {
                    this.props.getAccessToMatches(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal()
            }
        }
    }

    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }

}

export {
    AccessToMatchListController
}