import axios from 'axios'
import CONSTANTS from './../constants';
import OperatorType from './../type/operator_type';

export const Error = (data) => {
    return {
        type: OperatorType.OPERATOR_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: OperatorType.OPERATOR_SUCCESS, payload: data
    }
}
export const OperatorPagination = ({ data }) => {
    return {
        type: OperatorType.GET_PAGINATION, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: OperatorType.LOADING, payload: status })
    }
}
const getOperators = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}operators/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=user_name&order_by=ASC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: OperatorType.GET_OPERATORS, payload: response.data.data
                })
                dispatch({
                    type: OperatorType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: OperatorType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: OperatorType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: OperatorType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Failed to Retrieved"
                dispatch({
                    type: OperatorType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: OperatorType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }

        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchOperator = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}operators/list?keyword=${text}&page_at=${pageNumber}&sort_by=user_name&order_by=ASC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: OperatorType.GET_OPERATORS, payload: response.data.data
                })
                dispatch({
                    type: OperatorType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: OperatorType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: OperatorType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: OperatorType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}
const createOperator = (operator) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}operators/create`, operator).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: OperatorType.ADD_OPERATOR, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateOperator = (operator) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}operators/update`, operator).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: OperatorType.UPDATE_OPERATOR, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteOperator = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}operators/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: OperatorType.DELETE_OPERATOR, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "failed"
                dispatch({
                    type: OperatorType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: OperatorType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const changeOperatorPassword = (operator) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}auth/operator/change_password`, operator).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: OperatorType.UPDATE_OPERATOR, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else if (response.data.status === "Failed") {
                const errorPayload = {};
                errorPayload['message'] = response.data.data
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            dispatch({
                type: OperatorType.ERROR, payload: "Incorrect Old Password"
            })
            setTimeout(() => {
                const message = null
                dispatch({
                    type: OperatorType.ERROR,
                    payload: message
                })
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })

    }
}

const OperatorAction = {
    getOperators,
    createOperator,
    updateOperator,
    deleteOperator,
    searchOperator,
    changeOperatorPassword
}

export default OperatorAction