import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class CoinPolicyListController extends Component {
  constructor(props) {

    super(props)
    this.state = {
      show: false,
      showFotmobIdModal: false,
      isEdit: false,
      isEditMigrationId: false,
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      coin_policy: {},
      fotmob_migration_id: '',
      requiredError: '',
    }
  }
  componentDidMount = () => {
    this.props.getCoinPolicies(this.state.initialPage, this.state.rowCount).then(() => {
      this.setState({
        fotmob_migration_id: this.props.coin_policies[0]?.fotmob_migration_id
      })
    })
  }

  handleEdit = (coin_policy) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      coin_policy: {
        ...prevState.coin_policy,
        coin_policy
      }
    }))
  }
  handleInputFotmobId = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      fotmob_migration_id: e.target.value
    }))
  }
  updateMigrationId = (fotmob_migration_id) => {
    let id = this.props.coin_policies[0]?.id
    let operator = JSON.parse(localStorage.getItem('operator'))
    let coin_policy = {
      updated_by: operator.id,
      fotmob_migration_id: fotmob_migration_id,
      id: id
    }
    if (coin_policy.fotmob_migration_id === '') {
      this.setState({
        requiredError: "All fields are required"
      })
    }
    else {
      this.props.updateCoinPolicy(coin_policy).then(()=>{
        this.props.getCoinPolicies(this.state.initialPage, this.state.rowCount)
      })
      this.hideModal();
    }

  }
  createOrUpdate = (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    let coin_policy = {
      coin: data.coin,
      equivalent_amount: data.equivalent_amount,
      quick_bet_1: data.quick_bet_1,
      quick_bet_2: data.quick_bet_2,
      quick_bet_3: data.quick_bet_3,
      quick_bet_4: data.quick_bet_4,
      active: data.active,
      updated_by: operator.id
    }
   
    if (this.state.isEdit) {
      coin_policy = { ...coin_policy, id: data.id, }
      if (coin_policy.coin === '' || coin_policy.equivalent_amount === '' || coin_policy.quick_bet_1 === '' || coin_policy.quick_bet_2 === '' || coin_policy.quick_bet_3 === '' || coin_policy.quick_bet_4 === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateCoinPolicy(coin_policy).then(()=>{
          this.props.getCoinPolicies(this.state.initialPage, this.state.rowCount)
        })
        this.hideModal();
      }
    } else {
      coin_policy = { ...coin_policy, created_by: operator.id }
      if (coin_policy.coin === '' || coin_policy.equivalent_amount === '' || coin_policy.quick_bet_1 === '' || coin_policy.quick_bet_2 === '' || coin_policy.quick_bet_3 === '' || coin_policy.quick_bet_4 === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createCoinPolicy(coin_policy)
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }
  }
  hideModal = () => {
    this.setState({
      show: false,
      showFotmobIdModal: false,
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
  showFotmobIdModal = () => {
    this.setState({
      showFotmobIdModal: true,
      isEdit: false
    })
  }
}

export default CoinPolicyListController