import CONSTANTS from './../constants';
import axios from 'axios'
import SponsorType from './../type/sponsor_type';

export const Error = (data) => {
  return {
    type: SponsorType.SPONSOR_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: SponsorType.SPONSOR_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: SponsorType.LOADING, payload: status })
  }
}
const getSponsors = (pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}sponsors/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SponsorType.GET_SPONSORS, payload: response.data.data
        })
        dispatch({
          type: SponsorType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: SponsorType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: SponsorType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: SponsorType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: SponsorType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SponsorType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createSponsor = (sponsor) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}sponsors/create`, sponsor).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SponsorType.ADD_SPONSOR, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))

        // success payload
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const updateSponsor = (sponsor) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}sponsors/update`, sponsor).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SponsorType.UPDATE_SPONSOR, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      // errorPayload['message'] = err.response.data.message;
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const deleteSponsor = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'))
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}sponsors/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SponsorType.DELETE_SPONSOR, payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "Fail"
        dispatch({
          type: SponsorType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SponsorType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
// const searchItem = (text, pageNumber) => {
//   return (dispatch) => {
//     return axios.post(`${CONSTANTS.BASE_URL}stadiums/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
//       if (response.data.status === "Success") {
//         dispatch({
//           type: SponsorType.GET_STADIUMS, payload: response.data.data
//         })
//         dispatch({
//           type: SponsorType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
//         })
//         dispatch({
//           type: SponsorType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
//         })
//         dispatch({
//           type: SponsorType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
//         })
//       }
//       return dispatch({
//         type: SponsorType.SEARCH_ITEM,
//         payload: text
//       })
//     })
//   }
// }

const SponsorAction = {
  getSponsors,
  createSponsor,
  updateSponsor,
  deleteSponsor,
}
export default SponsorAction