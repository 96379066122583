const TownshipType = {
    GET_TOWNSHIPS: "GET_TOWNSHIPS",
    ADD_TOWNSHIP: "ADD_TOWNSHIP",
    UPDATE_TOWNSHIP: "UPDATE_TOWNSHIP",
    DELETE_TOWNSHIP: "DELETE_TOWNSHIP",
    TOWNSHIP_ERROR: "TOWNSHIP_ERROR",
    TOWNSHIP_SUCCESS: "TOWNSHIP_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_WITH_CITY: "SEARCH_WITH_CITY",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default TownshipType