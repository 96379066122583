
import CONSTANTS from './../constants';
import axios from 'axios'
import QuizDetailsType from './../type/quiz_details_type';

export const Error = (data) => {
    return {
        type: QuizDetailsType.QUIZ_DETAILS_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: QuizDetailsType.QUIZ_DETAILS_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: QuizDetailsType.LOADING, payload: status });
    }
}
const getQuizDetailsLists = (quizId, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?quiz_id=${quizId}&page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizDetailsType.GET_QUIZ_DETAILS_LISTS,
                    payload: response.data.data
                })
                dispatch({
                    type: QuizDetailsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: QuizDetailsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: QuizDetailsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: QuizDetailsType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: QuizDetailsType.SEARCH_WITH_QUIZ_ID, payload: quizId
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: QuizDetailsType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuizDetailsType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createQuizDetails = (quiz_details) => {

    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/create`, quiz_details).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizDetailsType.ADD_QUIZ_DETAILS,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }



}
const updateQuizDetails = (quiz_details) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}special_quiz_details/update`, quiz_details).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizDetailsType.UPDATE_QUIZ_DETAILS,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const deleteQuizDetails = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));

    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}special_quiz_details/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizDetailsType.DELETE_QUIZ_DETAILS,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: QuizDetailsType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: QuizDetailsType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchQuizDetails = (quizId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?quiz_id=${quizId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: QuizDetailsType.GET_QUIZ_DETAILS_LISTS, payload: response.data.data
                })
                dispatch({
                    type: QuizDetailsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: QuizDetailsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: QuizDetailsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: QuizDetailsType.SEARCH_WITH_QUIZ_ID,
                    payload: quizId
                })
            }
        })
    }
}

const QuizDetailsAction = {
    getQuizDetailsLists,
    createQuizDetails,
    updateQuizDetails,
    deleteQuizDetails,
    searchQuizDetails,
    loading
}
export default QuizDetailsAction