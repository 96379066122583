import React from 'react'
import { Input, Button } from '../../../components/ImportsFile'
import AnswerCreateController from './AnswerCreateController';
import SelectModalForAnswer from './../SelectModalForAnswer';
import Search from './../../../components/search/Search';

class AnswerCreate extends AnswerCreateController {
  render() {

    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Correct Answer' : 'Add Correct Answer'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ width: '50%', margin: 'auto' }}>

                {
                  this.props.quizInfo.quiz_type !== 3 &&
                  <Input type="text" name="question_id" placeholder="Enter Question..." value={this.props.quizInfo?.Question?.question} disabled />
                }

                {
                  (this.props.quizInfo.quiz_type === 2 && this.props.quizInfo.submit_type !== 4) &&
                  <Input type="text" name="answer" placeholder="Enter Correct Answer..." value={this.state.quiz_answer.answer} onChange={this.handleInputChange} required />
                }
                {
                  (this.props.quizInfo.quiz_type === 1 && this.props.quizInfo.submit_type === 1) &&
                  <Input type="text" name="answer" placeholder="Enter Correct Answer..." value={this.state.quiz_answer.answer} onChange={this.handleInputChange} required />
                }
                {
                  (this.props.quizInfo.quiz_type === 1 && this.props.quizInfo.submit_type === 2 && this.props.quizInfo?.Question?.question == 'ဂိုးပေါင်း ဘယ်လောက်လဲ') &&
                  <select name="answer" className="select" value={this.state.quiz_answer.answer} onChange={this.handleInputChange} required >
                    <option value="ဂိုးမရှိ">ဂိုးမရှိ</option>
                    <option value="၁ဂိုး">၁ဂိုး</option>
                    <option value="၂ဂိုး">၂ဂိုး</option>
                    <option value="၂ဂိုးအထက်">၂ဂိုးအထက်</option>
                  </select>
                }
                {
                  (this.props.quizInfo.type === 2 && this.props.quizInfo.submit_type === 4) &&
                  <SelectModalForAnswer
                    routeChangeName="team"
                    // answerForTeams={true}
                    // teams={this.props.teams}
                    answerForTeams={this.props.allTeams}
                    LoadMore={this.props.loadMoreTeams}
                    question_type={this.props.quizInfo?.Question?.question_type}
                    searching={this.props.searchWithLeagueAndTeamName}
                    currentPage={this.props.currentPage}
                    searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
                    leagueId={this.props.quizInfo?.SpecialQuiz?.league_id}
                    rowCount={this.state.teamRowCount}

                    handleProcess={(team_id) => this.TeamIdProcess(team_id)}
                    handleProcessName={(name) => this.TeamNameProcess(name)
                    }
                    itemName={this.props.isEdit ? this.props.quiz_answer.quiz_answer.win_case : "*** Choose Team ***"}
                  />
                }
                {
                  (this.props.quizInfo.quiz_type === 1 && this.props.quizInfo.submit_type === 2 && (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်း နိင်မှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' || this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ')) &&
                  <select name="answer" className="select" value={this.state.quiz_answer.answer} onChange={this.handleInputChangeAnswer} required >
                    <option value=""> *** Choose Team *** </option>
                    <option value={this.state.match?.HomeTeam?.id}>{this.state.match?.HomeTeam?.name}</option>
                    <option value={this.state.match?.AwayTeam?.id}>{this.state.match?.AwayTeam?.name}</option>
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်း နိင်မှာလဲ' &&
                      <option value={0}>သရေကျမယ်</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ' &&
                      <option value={0}>သွင်းဂိုးမရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ' &&
                      <option value={0}>အနီကဒ်မရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ' &&
                      <option value={0}>အဝါကဒ်မရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ' &&
                      <option value={0}>ဖရီးကစ်မရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ' &&
                      <option value={0}>ကော်နာမရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ' &&
                      <option value={0}>ပယ်နတီမရှိ</option>
                    }
                    {
                      this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ' &&
                      <option value={0}>လူစားလဲတာမရှိ</option>
                    }

                  </select>
                }


                {
                  (this.props.quizInfo?.quiz_type === 1 && this.props.quizInfo?.submit_type === 4) &&
                  <SelectModalForAnswer
                    routeChangeName="player"
                    home_team_players={this.props.home_team_players}
                    away_team_players={this.props.away_team_players}
                    // LoadMore={this.props.loadMore}
                    question_type={this.props.quizInfo?.Question?.question_type}
                    searching={this.props.searchPlayer}
                    currentPage={this.props.currentPage}
                    getItems={this.props.getAllPlayer}
                    rowCount={this.props.rowCount}
                    handleProcess={(player_id) => this.PlayerIdProcess(player_id)}
                    handleProcessName={(name) => this.PlayerNameProcess(name)
                    }
                    itemName={this.props.isEdit ? this.props.quiz_answer.quiz_answer.win_case : "*** Choose Player ***"}
                  />
                }

                {
                  // (this.props.quiz_answers[0]?.SpecialQuizDetail?.quiz_type == 2 && this.props.quiz_answers[0]?.SpecialQuizDetail?.type == 1) &&
                  (this.props.quizInfo?.quiz_type === 2 && this.props.quizInfo?.submit_type === 4 && this.props.quizInfo.type !== 2) &&
                  <SelectModalForAnswer
                    routeChangeName="player"
                    answerForPlayers={this.props.allPlayers}
                    // LoadMore={this.props.loadMore}
                    searching={this.props.searchPlayer}
                    currentPage={this.props.currentPage}
                    getItems={this.props.getAllPlayer}
                    rowCount={this.props.rowCount}
                    handleProcess={(player_id) => this.PlayerIdProcess(player_id)}
                    handleProcessName={(name) => this.PlayerNameProcess(name)
                    }
                    itemName={this.props.isEdit ? this.props.quiz_answer.quiz_answer.win_case : "*** Choose Player ***"}

                  />

                }

                {
                  this.props.quizInfo.quiz_type === 3 &&
                  <select name="answer" className="select" value={this.state.quiz_answer.answer} onChange={this.handleInputChange} required >
                    <option value="1">Home</option>
                    <option value="2">Draw</option>
                    <option value="3">Away</option>
                  </select>
                }

                <select name="active" className="select" value={this.state.quiz_answer.active} onChange={this.handleSelect} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
                <br />
                <br />

                <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.quiz_answer)} />
              </div>

            </form>
          </div>
        </div>
      </div>

    )
  }
}

export default AnswerCreate