import React from 'react'
import { MainContainer, Header, Body } from '../../PagesElement'
import { TeamLeagueListingControler } from './TeamLeagueListingControler';
import { connect } from 'react-redux';
import TeamLeagueAction from '../../../redux/action/team_league_action'
import Modal from './../../../components/modal/Modal';
import { Button } from './../../../components/buttons/Button';
import TeamLeagueCreate from './../create/TeamLeagueCreate';
import TeamAction from './../../../redux/action/team_action';
import LeagueAction from './../../../redux/action/league_action';
import Paginate from './../../../components/pagination/Pagination';
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import Search from './../../../components/search/Search';
import Alert from './../../../components/alert/Alert';
class TeamLeagueListing extends TeamLeagueListingControler {
  render() {
   
    return (
      <MainContainer>
        <Header>
          <h4>Team League</h4>
          <form className="search search_role_permission" onSubmit={this.handleSearch} >
            <Search
              items={this.props.teams}
              searchItem={(teamId) => this.handleSelectTeamId(teamId)}
              text={"team"}
              LoadMore={this.loadMore1}
              getItems={this.props.getAllTeams}
              rowCount={this.props.teamRowCount}
              searchTeam={this.props.searchTeam}
              currentPage={this.props.teamCurrentPage}
            />

            <Search
              items={this.props.leagues}
              searchItem={(leagueId) => this.handleSelectLeagueId(leagueId)}
              text={"league"}
              LoadMore={this.loadMore2}
              getItems={this.props.getAllLeagues}
              rowCount={this.props.leagueRowCount}
              searchLeague={this.props.searchLeague}
              currentPage={this.props.leagueCurrentPage}
              stopSearching={() => this.props.searchWithTeamOrLeague('', '', this.props.currentPage)}
            />

            <button type="submit"><i className="fa fa-search"></i></button>
          </form>
          <Button className="btn-primary add-button" caption="Add New" btnFun={this.showModal} />
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Team</th>
                <th>League</th>
                <th>Active</th>
                {/**
                <th>Actions</th>
              */}
              </tr>
            </thead>
            <tbody>
              {
                this.props.team_leagues.map((team_league, index) => {
                  return (
                    <tr key={index}>
                      <td>{team_league.Team.name}</td>
                      <td>{team_league.League.name}</td>
                      <td>{team_league.active === 1 ? "Active" : "Inactive"}</td>
                      {/**
                      <td>
                      <Modal deleteItem={() => this.props.deleteTeamLeague(team_league.id)} editItem={() => this.handleEdit(team_league)} />
                      </td>
                    */}
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
          <Paginate activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
            (!this.props.teamId && !this.props.leagueId) && this.props.getTeamLeagues(pageNumber, this.props.row_count);
            (this.props.teamId || this.props.leagueId) && this.props.searchWithTeamOrLeague(this.props.teamId, this.props.leagueId, pageNumber);

          }} />

          {
            this.state.show &&
            <TeamLeagueCreate
              hideModal={this.hideModal}
              isEdit={this.state.isEdit}
              team_league={this.state.team_league}
              teams={this.props.teams}
              leagues={this.props.leagues}
              loadMore1={this.loadMore1}
              loadMore2={this.loadMore2}
              createOrUpdate={this.createOrUpdate}
              searchTeam={this.props.searchTeam}
              searchLeague={this.props.searchLeague}
              teamRowCount={this.props.teamRowCount}
              leagueRowCount={this.props.leagueRowCount}
              teamCurrentPage={this.props.teamCurrentPage}
              leagueCurrentPage={this.props.leagueCurrentPage}
              getAllTeams={this.props.getAllTeams}
              getAllLeagues={this.props.getAllLeagues}
            />
          }
        </Body>
      </MainContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    team_leagues: state.team_league.team_leagues,
    teams: state.team.teams,
    leagues: state.league.leagues,
    totalCount: state.team_league.totalCount,
    totalCountPerPage: state.team_league.totalCountPerPage,
    currentPage: state.team_league.currentPage,
    error: state.team_league.error,
    success: state.team_league.success,
    teamId: state.team_league.teamId,
    leagueId: state.team_league.leagueId,
    row_count: state.team_league.row_count,
    loading: state.team_league.loading,
    teamRowCount: state.team.row_count,
    leagueRowCount: state.league.row_count,
    teamCurrentPage: state.team.currentPage,
    leagueCurrentPage: state.league.currentPage,
    // teamText:state.team.text,
    // leagueText:state.league.text,

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getTeamLeagues: (pageNumber, row_count) => dispatch(TeamLeagueAction.getTeamLeagues(pageNumber, row_count)),
    getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
    getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getLeagues(pageNumber, row_count)),

    createTeamLeague: (team_league) => dispatch(TeamLeagueAction.createTeamLeague(team_league)),
    updateTeamLeague: (team_league) => dispatch(TeamLeagueAction.updateTeamLeague(team_league)),
    deleteTeamLeague: (id) => dispatch(TeamLeagueAction.deleteTeamLeague(id)),

    searchTeam: (text, stadiumId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, pageNumber)),
    searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchItem(text, countryId, pageNumber)),

    searchWithTeamOrLeague: (teamId, leagueId, pageNumber) => dispatch(TeamLeagueAction.searchWithTeamOrLeague(teamId, leagueId, pageNumber)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamLeagueListing)