import React from 'react'
import { RolePermissionListController } from './RolePermissionListController'
import { RolePermissionCreate } from '../create/RolePermissionCreate'
import RolePermissionAction from './../../../redux/action/role_permission_action';
import RoleAction from './../../../redux/action/role_action';
import PermissionAction from './../../../redux/action/permission_action';

import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, Search, connect } from '../../../components/ImportsFile'
class RolePermissionList extends RolePermissionListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Role Permission' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Role Permission' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Role Permission' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Role-Permissions</h4>
                    <form className="search search_role_permission" >
                        <div className="input-group">

                            <Search
                                items={this.props.roles}
                                searchItem={(roleId) => this.handleSelectRoleId(roleId)}
                                text={'Search with Role'}
                                search={this.state.roleId ? true : false}
                                searching={this.props.searchRole}
                                getItems={this.props.getAllRoles}
                                rowCount={this.state.rowCount}
                                currentPage={this.props.currentRolePage}
                                stopSearching={() => this.stopSearching()}
                                LoadMore={this.loadMore1}
                            />

                            <Search
                                items={this.props.permissions}
                                searchItem={(permissionId) => this.handleSelectPermissionId(permissionId)}
                                searching={this.props.searchPermission}
                                rowCount={this.state.rowCount}
                                getItems={this.props.getAllPermissions}
                                currentPage={this.props.currentPermissionPage}
                                text={'Search with Permission'}
                                search={this.state.permissionId ? true : false}
                                stopSearching={() => this.stopSearching()}
                                LoadMore={this.loadMore2}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>


                    </form>
                    {
                        createPermission &&
                        <Button className="btn-primary add-button" caption="Add New" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Permission</th>
                                <th>Description</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.role_permissions.map((role_permission, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{role_permission.Role?.name}</td>
                                            <td>{role_permission.Permission?.name}</td>
                                            <td>{role_permission.description}</td>
                                            <td>{role_permission.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteRolePermission(role_permission.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(role_permission)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.roleId && !this.props.permissionId) && this.props.getRolePermissions(pageNumber, this.props.row_count);
                        (this.props.roleId || this.props.permissionId) && this.props.searchWithRoleOrPermission(this.props.roleId, this.props.permissionId, pageNumber);

                    }} />
                    {
                        this.state.show &&
                        <RolePermissionCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            role_permission={this.state.role_permission}
                            createOrUpdate={this.createOrUpdate}
                            roles={this.props.roles}
                            permissions={this.props.permissions}
                            loadMore1={this.loadMore1}
                            loadMore2={this.loadMore2}
                            searchRole={this.props.searchRole}
                            searchPermission={this.props.searchPermission}
                            roleText={this.props.roleText}
                            permissionText={this.props.permissionText}
                            currentPage={this.props.currentPage}
                            getAllRoles={this.props.getAllRoles}
                            getAllPermissions={this.props.getAllPermissions}
                            roleRowCount={this.state.roleRowCount}
                            permissionRowCount={this.state.permissionRowCount}
                            requiredError={this.state.requiredError}

                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        role_permissions: state.role_permission.role_permissions,
        roles: state.role.roles,
        permissions: state.permission.permissions,
        totalCount: state.role_permission.totalCount,
        totalCountPerPage: state.role_permission.totalCountPerPage,
        roleTotalCount: state.role.totalCount,
        permissionTotalCount: state.permission.totalCount,
        currentPage: state.role_permission.currentPage,
        currentRolePage: state.role.currentPage,
        currentPermissionPage: state.permission.currentPage,
        error: state.role_permission.error,
        success: state.role_permission.success,
        roleId: state.role_permission.roleId,
        permissionId: state.role_permission.permissionId,
        row_count: state.role_permission.row_count,
        loading: state.role_permission.loading,
        roleText: state.role.text,
        permissionText: state.permission.text,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRolePermissions: (pageNumber, row_count) => dispatch(RolePermissionAction.getRolePermissions(pageNumber, row_count)),
        createRolePermission: (role_permission) => dispatch(RolePermissionAction.createRolePermission(role_permission)),
        updateRolePermission: (role_permission) => dispatch(RolePermissionAction.updateRolePermission(role_permission)),
        deleteRolePermission: (id) => dispatch(RolePermissionAction.deleteRolePermission(id)),
        getAllRoles: (pageNumber, row_count) => dispatch(RoleAction.getAllRoles(pageNumber, row_count)),
        getAllPermissions: (pageNumber, row_count) => dispatch(PermissionAction.getAllPermissions(pageNumber, row_count)),
        searchWithRoleOrPermission: (roleId, permissionId, pageNumber) => dispatch(RolePermissionAction.searchWithRoleOrPermission(roleId, permissionId, pageNumber)),
        searchRole: (text, pageNumber) => dispatch(RoleAction.searchRole(text, pageNumber)),
        searchPermission: (text, pageNumber) => dispatch(PermissionAction.searchPermission(text, pageNumber))


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RolePermissionList)