import { Component } from 'react'
import CONSTANTS from './../../../../redux/constants';
import axios from 'axios'
class FutureQuizAnswerListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      isEdit: false,
      rowCount: CONSTANTS.ROW_COUNT,
      teamRowCount: CONSTANTS.ROW_COUNT,
      playerRowCount: CONSTANTS.ROW_COUNT,
      initialPage: 1,
      teamId: '',
      specialQuizId: '',
      specialQuizDetailId: '',
      quiz_answer: {},
      leagueId: '',
      questionId: '',


    }
  }
  componentDidMount = () => {

    this.props.quizInfo?.type === 1 && this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCount)
    this.props.quizInfo?.type === 2 && this.props.searchWithLeagueAndTeamName(this.props.quizInfo?.SpecialQuiz?.league_id, '', this.state.teamRowCount, this.state.initialPage)

    this.props.quizInfo?.type === 3 && this.props.searchPlayer('', 'manager', this.props.quizInfo?.SpecialQuiz?.league_id, '', this.state.playerRowCount, this.state.initialPage)

    this.setState({
      specialQuizId: this.props.quizInfo?.special_quiz_id,
      specialQuizDetailId: this.props.quizInfo?.id,
      leagueId: this.props.quizInfo?.SpecialQuiz?.league_id,
      questionId: this.props.quizInfo?.question_id
    }, () => {
      this.props.getQuizAnswers(this.state.leagueId, this.state.questionId, this.state.initialPage, this.state.rowCount)
    })
    this.loadMorePlayers();
    this.loadMoreTeams();
    this.props.getUserQuizAnswers(this.state.specialQuizId, this.state.initialPage, this.state.rowCount)

  }
  loadMorePlayers = () => {
    this.setState({
      playerRowCount: this.state.playerRowCount + 6
    })
    this.props.quizInfo.type === 1 && this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCount)
    this.props.quizInfo.type === 3 && this.props.searchPlayer('', 'manager', this.props.quizInfo?.SpecialQuiz?.league_id, '', this.state.playerRowCount, this.state.initialPage)
  }
  loadMoreTeams = () => {
    this.setState({
      teamRowCount: this.state.teamRowCount + 6
    })
    this.props.searchWithLeagueAndTeamName(this.props.quizInfo?.SpecialQuiz?.league_id, '', this.state.teamRowCount, this.state.initialPage)
  }
  handleEdit = (quiz_answer) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      quiz_answer: {
        ...prevState.quiz_answer,
        quiz_answer
      }
    }))
  }
  createOrUpdate = async (data) => {

    let operator = JSON.parse(localStorage.getItem('operator'));
    let quiz_answer;
    quiz_answer = {
      special_quiz_detail_id: data.special_quiz_detail_id,
      answer: JSON.stringify(data.answer),
      quiz_type: data.quiz_type,
      active: data.active,
      updated_by: operator.id
    }
    if (data.match_id) {
      quiz_answer = { ...quiz_answer, match_id: data.match_id }
    }
    if (data.win_case) {
      quiz_answer = { ...quiz_answer, win_case: data.win_case }
    }
    if (this.state.isEdit) {

      quiz_answer = { ...quiz_answer, id: data.id }
      if (quiz_answer.answer === '' || quiz_answer.quiz_type === '' || quiz_answer.special_quiz_detail_id === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateQuizAnswer(quiz_answer).then(() => {
          this.props.getQuizAnswers(this.state.leagueId, this.state.questionId, this.props.currentPage, this.state.rowCount)
        })
        this.hideModal();

      }


    } else {
      quiz_answer = { ...quiz_answer, created_by: operator.id }
      if (quiz_answer.answer === '' || quiz_answer.quiz_type === '' || quiz_answer.special_quiz_detail_id === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        await this.props.addQuizAnswer(quiz_answer).then(() => {
          this.props.getQuizAnswers(this.state.leagueId, this.state.questionId, this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }
  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default FutureQuizAnswerListController
