import React from 'react'
import { Button } from '../../../components/buttons/index'
import { FavouriteTeamLevelRuleListController } from './FavouriteTeamLevelRuleListController'
import { MainContainer, Header, Body } from '../../PagesElement'
import { connect } from 'react-redux';
import Pagination from './../../../components/pagination/Pagination';
import Modal from './../../../components/modal/Modal';
import '../../../components/search/Search.css'
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import FavouriteTeamLevelRuleCreate from './../create/FavouriteTeamLevelRuleCreate';
import FavouriteTeamLevelRuleAction from './../../../redux/action/favourite_team_level_rule_action';
import Alert from './../../../components/alert/Alert';
class FavouriteTeamLevelRuleList extends FavouriteTeamLevelRuleListController {


    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Favourite Team Level Rule' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Favourite Team Level Rule' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Favourite Team Level Rule' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Fav Team Level Rules</h4>
                    <form className="search" onSubmit={this.handleSearch} autoComplete="off">
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search with Level Name or Level No" name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button icon="" caption="Add Rule" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Level Name</th>
                                <th>Level Number</th>
                                <th>Min Count</th>
                                <th>Max Count</th>
                                <th>Reward Points</th>
                                <th>Discountable Points</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.favourite_team_level_rules.map((rule, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{rule.level_name}</td>
                                            <td>{rule.level_number}</td>
                                            <td>{rule.from_min_count}</td>
                                            <td>{rule.to_max_count}</td>
                                            <td>{rule.reward_points}</td>
                                            <td>{rule.discountable_points}</td>
                                            <td>{rule.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteFavouriteTeamLevelRule(rule.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(rule)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>

                    <Pagination
                        activePage={this.props.currentPage}
                        totalItemsCount={this.props.totalCount}
                        totalCountPerPage={this.props.totalCountPerPage}
                        paginate={(pageNumber) => { !this.props.text ? this.props.getFavouriteTeamLevelRules(pageNumber, this.props.row_count) : this.props.searchItem(this.props.text, pageNumber) }} />
                    {
                        this.state.show &&
                        <FavouriteTeamLevelRuleCreate
                            show={this.state.show}
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            favourite_team_level_rule={this.state.favourite_team_level_rule}
                            createOrUpdate={this.createOrUpdate}
                            favourite_team_level_rules={this.props.favourite_team_level_rules}
                            currentPage={this.props.currentPage}
                            rowCount={this.state.rowCount}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        favourite_team_level_rules: state.favourite_team_level_rule.favourite_team_level_rules,
        currentPage: state.favourite_team_level_rule.currentPage,
        totalCount: state.favourite_team_level_rule.totalCount,
        totalCountPerPage: state.favourite_team_level_rule.totalCountPerPage,
        error: state.favourite_team_level_rule.error,
        success: state.favourite_team_level_rule.success,
        text: state.favourite_team_level_rule.text,
        row_count: state.favourite_team_level_rule.row_count,
        loading: state.favourite_team_level_rule.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFavouriteTeamLevelRules: (pageNumber, row_count) => dispatch(FavouriteTeamLevelRuleAction.getFavouriteTeamLevelRules(pageNumber, row_count)),
        createFavouriteTeamLevelRule: (favouriteTeamLevelRule) => dispatch(FavouriteTeamLevelRuleAction.createFavouriteTeamLevelRule(favouriteTeamLevelRule)),
        updateFavouriteTeamLevelRule: (favouriteTeamLevelRule) => dispatch(FavouriteTeamLevelRuleAction.updateFavouriteTeamLevelRule(favouriteTeamLevelRule)),
        deleteFavouriteTeamLevelRule: (data) => dispatch(FavouriteTeamLevelRuleAction.deleteFavouriteTeamLevelRule(data)),
        searchItem: (text, pageNumber) => dispatch(FavouriteTeamLevelRuleAction.searchItem(text, pageNumber))

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FavouriteTeamLevelRuleList)
