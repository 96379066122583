import { Component } from 'react'

class CoinPolicyCreateController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coin_policy: props.isEdit === false ? {
        coin: '',
        equivalent_amount: '',
        quick_bet_1: '',
        quick_bet_2: '',
        quick_bet_3: '',
        quick_bet_4: '',
        active: 1,
      } : props.coin_policy.coin_policy
    }
  }
  handleInputCoin = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        coin: e.target.value
      }
    }))
  }
  handleInputEquivalentAmt = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        equivalent_amount: e.target.value
      }
    }))
  }
  QuickBet1 = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        quick_bet_1: e.target.value
      }
    }))
  }
  QuickBet2 = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        quick_bet_2: e.target.value
      }
    }))
  }
  QuickBet3 = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        quick_bet_3: e.target.value
      }
    }))
  }
  QuickBet4 = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        quick_bet_4: e.target.value
      }
    }))
  }
  processActive = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      coin_policy: {
        ...prevState.coin_policy,
        active: e.target.value
      }
    }))
  }
  
}

export default CoinPolicyCreateController