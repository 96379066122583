const UserQuizAnswerType = {
    GET_USER_ANSWERS: "GET_USER_ANSWERS",
    USER_QUIZ_ANSWER_ERROR: "USER_QUIZ_ANSWER_ERROR",
    USER_QUIZ_ANSWER_SUCCESS: "USER_QUIZ_ANSWER_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    SERCH_WITH_QUIZ_DETAIL_ID:"SERCH_WITH_QUIZ_DETAIL_ID",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default UserQuizAnswerType