
import UserFavouriteNationalTeamType from './../type/user_favourite_national_team_type';

const initialState = {
  user_favourite_national_teams: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  userId: null,
  teamId: null,
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
}

const userFavouriteNationalTeamReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case UserFavouriteNationalTeamType.GET_USER_FAVOURITE_NATIONAL_TEAMS:
      return {
        ...state,
        loading: false,
        user_favourite_national_teams: actions.payload
      }
    case UserFavouriteNationalTeamType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case UserFavouriteNationalTeamType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case UserFavouriteNationalTeamType.SEARCH_WITH_USER:
      return {
        ...state,
        userId: actions.payload
      }
    case UserFavouriteNationalTeamType.SEARCH_WITH_TEAM:
      return {
        ...state,
        teamId: actions.payload
      }
    case UserFavouriteNationalTeamType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case UserFavouriteNationalTeamType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case UserFavouriteNationalTeamType.ADD_USER_FAVOURITE_TEAM:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        user_favourite_national_teams: [
          actions.payload,
          ...state.user_favourite_national_teams,
        ]
      }
    case UserFavouriteNationalTeamType.UPDATE_USER_FAVOURITE_TEAM:
      const updateUserFavouriteNationalTeams = state.user_favourite_national_teams.filter(favTeam => favTeam.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        user_favourite_national_teams: [
          actions.payload,
          ...updateUserFavouriteNationalTeams,
        ]
      }
    case UserFavouriteNationalTeamType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case UserFavouriteNationalTeamType.DELETE_USER_FAVOURITE_NATIONAL_TEAM:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        user_favourite_national_teams: state.user_favourite_national_teams.filter(favTeam => favTeam.id !== actions.payload)
      }
    case UserFavouriteNationalTeamType.USER_FAVOURITE_NATIONAL_TEAM_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case UserFavouriteNationalTeamType.USER_FAVOURITE_NATIONAL_TEAM_SUCCESS:
      return { ...state, success: actions.payload, loading: false }

    default: return state
  }
}

export default userFavouriteNationalTeamReducer;