
import axios from 'axios'
import CityType from '../type/city_type';
import CONSTANTS from './../constants';

export const Error = (data) => {
    return {
        type: CityType.CITY_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: CityType.CITY_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: CityType.LOADING, payload: status })
    }
}
const getCities = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}cities/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.code === 200) {
                dispatch({
                    type: CityType.GET_CITIES, payload: response.data.data
                })
                dispatch({
                    type: CityType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count,
                })
                dispatch({
                    type: CityType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: CityType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: CityType.ROW_COUNT,
                    payload: row_count,
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: CityType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CityType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
        })
    }
}
const getAllCities = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}cities/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.code === 200) {
                dispatch({
                    type: CityType.GET_CITIES, payload: response.data.data
                })
                dispatch({
                    type: CityType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: CityType.ROW_COUNT,
                    payload: row_count,
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: CityType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CityType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const createCity = (city) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}cities/create`, city).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: CityType.ADD_CITY, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateCity = (city) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}cities/update`, city).then((response) => {
            if (response.data.code === 200) {
                dispatch({
                    type: CityType.UPDATE_CITY, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}
const deleteCity = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}cities/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: CityType.DELETE_CITY, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed to delete"
                dispatch({
                    type: CityType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: CityType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, countryId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}cities/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: CityType.GET_CITIES,
                    payload: response.data.data
                })
                dispatch({
                    type: CityType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: CityType.GET_CURRENT_PAGE,
                    payload: response.data.pagination.page_at
                })
                dispatch({
                    type: CityType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: CityType.SEARCH_WITH_COUNTRY,
                    payload: countryId
                })
                dispatch({
                    type: CityType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}
const searchCity = (text, countryId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}cities/list?keyword=${text}&country_id=${countryId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: CityType.GET_CITIES,
                    payload: response.data.data
                })

                dispatch({
                    type: CityType.SEARCH_WITH_COUNTRY,
                    payload: countryId
                })
                dispatch({
                    type: CityType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}

const CityAction = {
    searchItem,
    getCities,
    getAllCities,
    createCity,
    updateCity,
    deleteCity,
    loading,
    searchCity
}

export default CityAction;