const GlobalLevelRuleType = {
    GET_GLOBAL_LEVEL_RULES: "GET_GLOBAL_LEVEL_RULES",
    ADD_GLOBAL_LEVEL_RULE: "ADD_GLOBAL_LEVEL_RULE",
    UPDATE_GLOBAL_LEVEL_RULE: "UPDATE_GLOBAL_LEVEL_RULE",
    DELETE_GLOBAL_LEVEL_RULE: "DELETE_GLOBAL_LEVEL_RULE",
    GLOBAL_LEVEL_RULE_ERROR: "GLOBAL_LEVEL_RULE_ERROR",
    GLOBAL_LEVEL_RULE_SUCCESS: "GLOBAL_LEVEL_RULE_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default GlobalLevelRuleType;