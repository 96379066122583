import styled from "styled-components";
const MainContainer = styled.div`
    width:95%;
    height:100%;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
    button{
        width:100px;
        margin-top:0px;
        margin-right:10px;
        // margin-bottom:20px;
        font-size:14px;
        border:1px solid #202020;
        padding:6px 0px;
    }
    button.active{
        background-color:#202020;
        color:#fad230;
    }
    button:hover{
        background-color:#202020;
        color:#fad230;
    }
    button.active:hover{
        background-color:#fad230;
        color:#202020;
    }
`
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
    margin-bottom:20px;
   
     
`

const ScoreNav = styled.div`
    width:100%;
    align-items:center;
    background:transparent;
    color:#202020;
    border-radius:5px;
    ul{
        display:flex;
        justify-content:space-around;
        flex-direction:row;
        list-style-type:none;
        align-items:center;
        padding:0px;
        margin:0px;
        li{
            padding:10px 20px;
            width:100%;
            font-size:15px;
            cursor:pointer;
            border: 1px solid #202020;
            // border-right:2px solid #fad230;
            align-self:center;
            text-align:center;
        }
        li.active{
            background:#202020;
            color:#fad230;
        }
        li:first-child{
            border-top-left-radius:5px;
            border-bottom-left-radius:5px;
        }
        li:last-child{
            border-top-right-radius:5px;
            border-bottom-right-radius:5px;
        }
        // li:last-child{
        //     border-right:none !important;
        // }
        p{
            margin:0px;
        }
    }
`
const MatchContainer = styled.div`
    width:100%;
    background:#F1F1F1;
    padding:10px;
    margin:10px 0px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    .main_stats{
        width:80%;
        .main{
                padding:0px 30px;
                display:flex;
                width:100%;
                justify-content:space-around;
                align-items:center;
                text-align:center;
                margin-top:20px;
                // margin-bottom:20px;
                .left,.right{
                    min-width:150px;
                }
                img{
                    width:100px;
                    height:100px;
                    padding:10px;
                }
                p{
                    font-size:18px;
                    margin:0px;
                    padding:8px 0px;

                }
                p.muted{
                    color:#6C757D;
                    font-size:14px;
                }
                span{
                    font-size:14px;
                }
                .center{
                width:40%;
                    
                }
            }
        .scorers{
                display:flex;
                flex-direction:row;
                align-items:center;
                width:70%;
                justify-content:space-around;
                margin:0 auto;
                .home_scorer{
                    display:flex;
                    flex-direction:column;
                    width:300px;
                   
                    .scorer{
                        display:flex;
                        // jsutify-content:space-between;
                        
                    justify-content:end;
                    text-align:right;
                    margin-right:0px;
                    padding-right:0px;
                        p{
                            display:inline;
                        min-width:100px;
                        font-size:14px;
                        padding:0px;
                        margin:0px;
                        }
                        p.timeStr{
                            font-size:12px;
                        }
                    }
                }
                .icon{
                    padding:10px 20px;
                    justify-content:center;
                    text-align:center;
                    width:80px;
                    // position:fixed;
                    
                }
                .away_scorer{
                    display:flex;
                    flex-direction:column;
                    width:300px;
                    .scorer{
                        display:flex;
                        justify-content:space-between;                        
                    text-align:left;
                    margin-right:0px;
                    padding-right:0px;
                        p{
                            display:inline;
                        min-width:100px;
                        font-size:14px;
                        padding:0px;
                        margin:0px;
                        }
                        p.timeStr{
                            font-size:12px;
                            text-align:right;
                        }
                    }
                }
            }
   
        
    }
`
const ScoreSubNav = styled.div`
    width:40%;
    // display:flex;
    margin:auto;
    align-items:center;
    justify-content:center;
    background:transparent;
    color:#202020;
    border-radius:5px;
    padding-top:20px;
    ul{
        display:flex;
        justify-content:space-around;
        flex-direction:row;
        list-style-type:none;
        align-items:center;
        padding:0px;
        margin:0px;
        li{
            padding:6px 20px;
            width:100%;
            font-size:15px;
            cursor:pointer;
            border: 1px solid #202020;
            // border-right:2px solid #fad230;
            align-self:center;
            text-align:center;
        }
        li.active{
            background:#202020;
            color:#fad230;
        }
        li:first-child{
            border-top-left-radius:5px;
            border-bottom-left-radius:5px;
        }
        li:last-child{
            border-top-right-radius:5px;
            border-bottom-right-radius:5px;
        }
        // li:last-child{
        //     border-right:none !important;
        // }
        p{
            margin:0px;
        }
    }
`
const ScoreBody = styled.div`
    padding:20px 0px;
    width:100%;
    .list{
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:row;
    }
    .scoreBody{
        padding-top:20px;
        text-align:center;
        margin-bottom:50px;
        h5{
            font-size:26px;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h6{
            font-size:20px;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .score_compare{
        .bar{
            font-size:14px;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            // margin:0px auto;
            height:30px;
            width:80%;
            align-items:center;
            margin-bottom:45px;
            .home_bar{
                display:flex;
                justify-content:center;
                height:30px;
                align-items:center;
                // background:#fad230;
                // color:#202020;
                background:#202020;
                color:#fad230;
                border-right:4px solid #EFEFEF;
                border-top-left-radius:5px;
                border-bottom-left-radius:5px;
            }
            .away_bar{
                display:flex;
                justify-content:center;
                height:30px;
                align-items:center;
                background:#202020;
                color:#fad230;
                border-top-right-radius:5px;
                border-bottom-right-radius:5px;
            }
            p{
                width:100%;
                padding:10px;
            }
        }
       }
       .item_lists{

           padding:12px 0px;
           width:70%;
           justify-content:center;
           margin:0 auto;
           font-size:15px;
           .items{
                display:flex;
                justify-content:space-between; 
                p{
                    margin:0px;
                    padding-top:10px;
                }
           }
       }

       .top_players{
           display:flex;
           width:80%;
           margin:15px auto;
           padding-top:20px;
           flex-direction:row;
           justify-content:space-between;
           .top_player{
               position:relative;
               cursor:pointer;
               .rating{
                   position:absolute;
                   top:10%;
                   left:85%;
                   background:#fad230;
                   transform: translate(-50%,-50%)!important; 
               }
               .team_logo{
                   position:absolute;
                   top:45%;
                   left:80%;
                   background:blue;
                   transform: translate(-50%,-50%)!important; 
               }
               p{
                   padding:20px 0px;
                   font-size:14px;
               }
               .badge {
                    display: inline-block;
                    padding: 5px 7px;
                    font-size:10px;
                    font-weight: 700;
                    line-height: 1;
                    color: #202020;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 0.25rem;
                }
                .team_logo.badge{
                    padding:0px!important;
                }
               img{
                   border-radius:50%;
               }
           }
        }
    }
    .h2h{
        .h2h_header{
            display:flex;
            flex-direction:row;
            width:70%;
            padding:10px;
            flex-direction:row;
            justify-content:space-between;   
            margin:auto; 
            align-items:center; 
            .info{
                display:flex;
                flex-direction:column;
                text-align:center;
                p{
                    padding:5px 24px;
                    border-radius:10px;
                    // border:1px solid #202020;
                    margin-bottom:0px;
                }
                .draw{
                   border:1px solid #fff;
                   background:#777;
                   color:#fad230;
               }
            }
        }
        
        h5{
            text-align:center;
            font-size:26px;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom:10px;

        }
        .h2h_lists{
            display:flex;
            width:100%;
            flex-direction:column;
            justify-content:space-between;
           .h2h_item{
               display:flex;
               align-items:center;
               font-size:16px;
               border-bottom:1px solid #F1F1F1;
               cursor:pointer;
               img{
                   width:50px;
                   height:50px;
                   border-radius:50%;
                   padding:10px;
               }
               .home,.away{
                   flex:2;
               }
               .league{
                   flex:3;
               }
               .home{
                   text-align:end;
               }
               .away{
                   text-align:start;
               }
               .score,.date{
                   flex:2;
                    text-align:center;
               }
           }
           .h2h_item:hover{
               background:#f1f1f1;
           }
        }
    }
`

const ScrollTable = styled.div`
    width:100%;
    ailgn-items:center;
    text-align:center;
    padding:20px 0px;
    .leagueName{
        text-align:left;
            font-size:24px;
            margin-top:20px;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom:10px;
    }
    .loader{
        text-align:center;
       
        width:100%;

    }
     table {
           tbody{
               td:last-child{
                  margin-top:19px;
               }
           }
       }
`

export {
    MainContainer, ScoreNav, MatchContainer, ScoreSubNav, ScoreBody, ScrollTable, Header
}