import { Component } from 'react'

class MatchAlertCreateController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.returnStateObject()
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.returnStateObject = this.returnStateObject.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
    }
    returnStateObject() {
        if (this.props.currentIndex === -1) {
            return {
                match: '',
                user: '',
                active: '',
            }
        }
        else {
            return (
                this.props.list[this.props.currentIndex]
            )
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentIndex !== this.props.currentIndex || prevProps.list.length !== this.props.list.length) {
            this.setState({ ...this.returnStateObject() })
        }
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSelect = (e) => {
        this.setState({
            active: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onAddOrEdit(this.state);
        this.setState(this.state)

    }
}
export {
    MatchAlertCreateController
}