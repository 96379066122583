import StadiumCreate from '../create/StadiumCreate'
import StadiumAction from './../../../redux/action/stadium_action';
import { Alert, PageLoader, Modal, Pagination, connect, MainContainer, Header, Body, Button } from '../../../components/ImportsFile'
import StadiumListController from './StadiumListController';
class StadiumListing extends StadiumListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Stadium' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Stadium' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Stadium' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (

            <MainContainer>
                <Header>
                    <h4>Stadiums</h4>
                    <form className="search" onSubmit={this.handleSearch} autoComplete="off">
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Stadium" className="btn-primary add-button" btnFun={this.showModal} />
                    }

                </Header>
                <Body>

                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

                    {
                        this.props.loading && <PageLoader />
                    }

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Total Seats</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.stadiums.map((stadium, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{stadium.name}</td>
                                            <td>{stadium.total_seats && stadium.total_seats.toLocaleString()}</td>
                                            <td>{stadium.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteStadium(stadium.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(stadium)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getStadiums(pageNumber, this.props.row_count) : this.props.searchItem(this.state.text, pageNumber) }} />

                    {
                        this.state.show && <StadiumCreate stadium={this.state.stadium} hideModal={this.hideModal} createOrUpdate={this.createOrUpdate} isEdit={this.state.isEdit} requiredError={this.state.requiredError} />
                    }

                </Body>

            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        stadiums: state.stadium.stadiums,
        error: state.stadium.error,
        success: state.stadium.success,
        currentPage: state.stadium.currentPage,
        totalCount: state.stadium.totalCount,
        totalCountPerPage: state.stadium.totalCountPerPage,
        text: state.stadium.text,
        row_count: state.stadium.row_count,
        loading: state.stadium.loading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getStadiums: (pageNumber, row_count) => dispatch(StadiumAction.getStadiums(pageNumber, row_count)),
        createStadium: (stadium) => dispatch(StadiumAction.createStadium(stadium)),
        updateStadium: (stadium) => dispatch(StadiumAction.updateStadium(stadium)),
        deleteStadium: (id) => dispatch(StadiumAction.deleteStadium(id)),
        searchItem: (text, pageNumber) => dispatch(StadiumAction.searchItem(text, pageNumber))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StadiumListing)