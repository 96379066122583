const TeamLeagueType = {
    GET_TEAM_LEAGUES: "GET_TEAM_LEAGUES",
    ADD_TEAM_LEAGUE: "ADD_TEAM_LEAGUE",
    UPDATE_TEAM_LEAGUE: "UPDATE_TEAM_LEAGUE",
    DELETE_TEAM_LEAGUE: "DELETE_TEAM_LEAGUE",
    TEAM_LEAGUE_ERROR: "TEAM_LEAGUE_ERROR",
    TEAM_LEAGUE_SUCCESS: "TEAM_LEAGUE_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_TEAM: "SEARCH_WITH_TEAM",
    SEARCH_WITH_LEAGUE: "SEARCH_WITH_LEAGUE",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default TeamLeagueType