import React from 'react'
import SponsorCreateController from './SponsorCreateController';
import { SelectModal, Link, Button, Input, Switch, faCloudUploadAlt, FontAwesomeIcon } from '../../../components/ImportsFile'


const textSwitch = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  paddingRight: 2,
  color: "#FFFFFFA0",
  fontSize: 12,
  fontWeight: "bold",

}
class SponsorCreate extends SponsorCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Sponsor' : 'Add Sponsor'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <form autoComplete="off">
              {
                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
              }

              <div style={{ columnCount: '2', paddingTop: '15px' }}>
                <SelectModal
                  routeChangeName="league"
                  items={this.props.leagues}
                  LoadMore={this.props.loadMoreLeague}
                  searchLeague={this.props.searchLeague}
                  currentPage={this.props.leagueCurrentPage}
                  getItems={this.props.getAllLeagues}
                  rowCount={this.props.leagueRowCount}
                  handleProcess={(league_id) => this.LeagueProcess(league_id)}
                  itemName={(this.props.isEdit && this.props.sponsor.sponsor?.League) ? this.props.sponsor.sponsor?.League?.name : "*** Choose League ***"} />

                <SelectModal
                  items={this.props.matches}
                  routeChangeName="match"
                  itemName={
                    (this.props.isEdit && this.props.sponsor?.sponsor?.Match) ?
                      this.props.sponsor?.sponsor?.Match.HomeTeam.name + " Vs " + this.props.sponsor?.sponsor?.Match.AwayTeam.name
                      :
                      "*** Choose Match ***"
                  }
                  handleProcess={(match_id) => this.MatchProcess(match_id)}
                  LoadMore={this.props.loadMoreMatch}
                />
                <SelectModal
                  questions={this.props.questions}
                  currentPage={this.props.questionCurrentPage}
                  handleProcess={(questionId) => this.questionIdProcess(questionId)}
                  itemName={(this.props.isEdit && this.props.sponsor.sponsor?.Question) ? this.props.sponsor.sponsor?.Question?.question : "*** Choose Future Question ***"}
                  getItems={this.props.getQuestions}
                  rowCount={this.props.questionRowCount}
                />
                <Input type="text" placeholder="Enter Sponsor Name" name="sponsor_name" value={this.state.sponsor.sponsor_name} onChange={this.handleInputSponsorName} />
                <Input type="text" placeholder="Enter Sponsor Website URL" name="sponsor_website_url" value={this.state.sponsor.sponsor_website_url} onChange={this.handleInputSponsorWebsite} />

                <select name="active" className="select" value={this.state.sponsor.active} onChange={this.handleSelect} required>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

              </div>
              <div style={{ width: '50%', minHeight: '100px' }}>
                <div style={{ display: 'flex', marginTop: "10px", alignItems: "center", justifyContent: 'left' }}>

                  <Switch onChange={this.props.handleSwitch} checked={this.props.checked}
                    offColor="#00ff00"
                    onColor="#02ba02"
                    offHandleColor="#eaeaea"
                    onHandleColor="#eaeaea"
                    checkedIcon={
                      <div style={textSwitch}> </div>
                    } uncheckedIcon={
                      <div style={textSwitch}> </div>
                    }
                  />
                  <span style={{ color: "#FAD230", fontSize: '16px', paddingLeft: '10px' }}>
                    {this.props.checked ? "Choose Image" : "Enter Image URL"}
                  </span>
                </div>
                {
                  this.props.checked ?
                    <div className="image-upload">
                      <label htmlFor="file">
                        <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#FAD230" />
                        Choose Image
                      </label>
                      <input type="file" id="file" name="sponsor_image_url" onChange={this.onChangeImage} style={{ display: 'none' }} />
                      {
                        (typeof this.state.sponsor.sponsor_image_url === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.sponsor.sponsor_image_url} alt="" onClick={this.zoomToggle} style={{ width: '100px' }} />
                      }
                      {
                        (typeof this.state.sponsor.sponsor_image_url !== "string") &&
                        <img className="uploadedImage" src={URL.createObjectURL(this.state.sponsor.sponsor_image_url)} alt="" onClick={this.zoomToggle} style={{ width: '100px' }} />
                      }

                      <br />
                    </div>
                    :
                    <>
                      <Input type="text" placeholder="Select Sponsor Image Url" name="sponsor_image_url" value={this.state.sponsor.sponsor_image_url} onChange={this.handleInputSponsorImage} />
                      <br />
                      <img className="uploadedImage" src={this.state.sponsor.sponsor_image_url} alt="" style={{ width: '120px' }} />
                    </>
                }
                {
                  this.state.zoom &&
                  <div className="zoomModal">
                    <img className="zoomImg"
                      src={typeof this.state.sponsor.sponsor_image_url == "string" ? this.state.sponsor.sponsor_image_url : URL.createObjectURL(this.state.sponsor.sponsor_image_url)}
                      alt="" onClick={this.zoomToggle} />
                  </div>
                }
              </div>
              <br />

              <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.sponsor)} />

            </form>
          </div>
        </div>
      </div>
    )

  }
}

export default SponsorCreate