import { Component } from 'react'

class TeamLeagueCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            team_league: props.isEdit === false ? {
                team_id: "",
                league_id: '',
                active: 1
            } : props.team_league.team_league
        }
    }
    teamIdProcess = (team_id) => {
        this.setState((prevState) => ({
            ...prevState,
            team_league: {
                ...prevState.team_league,
                team_id: team_id
            }
        }))
    }
    leagueIdProcess = (league_id) => {
        this.setState((prevState) => ({
            ...prevState,
            team_league: {
                ...prevState.team_league,
                league_id: league_id
            }
        }))
    }

    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            team_league: {
                ...prevState.team_league,
                active: e.target.value
            }
        }))
    }
}
export default TeamLeagueCreateController
