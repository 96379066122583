import React from 'react'
import { Input, Button } from '../../../components/ImportsFile';
import CoinPolicyCreateController from './CoinPolicyCreateController';

class CoinPolicyCreate extends CoinPolicyCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Coin Policy' : 'Add Coin Policy'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <form autoComplete="off">
              {
                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
              }
              <div style={{ columnCount: '2' }}>
                <Input type="number" name="coin" placeholder="Enter Coin..." value={this.state.coin_policy.coin} onChange={this.handleInputCoin} required />

                <Input type="number" name="equivalent_amount" placeholder="Enter Equivalent Amount..." value={this.state.coin_policy.equivalent_amount} onChange={this.handleInputEquivalentAmt} required />

                <Input type="number" name="quick_bet_1" placeholder="Enter Quick Bet 1..." value={this.state.coin_policy.quick_bet_1} onChange={this.QuickBet1} required />

                <Input type="number" name="quick_bet_2" placeholder="Enter Quick Bet 2..." value={this.state.coin_policy.quick_bet_2} onChange={this.QuickBet2} required />

                <Input type="number" name="quick_bet_3" placeholder="Enter Quick Bet 3..." value={this.state.coin_policy.quick_bet_3} onChange={this.QuickBet3} required />

                <Input type="number" name="quick_bet_4" placeholder="Enter Quick Bet 4..." value={this.state.coin_policy.quick_bet_4} onChange={this.QuickBet4} required />

                <select name="active" className="select" value={this.state.coin_policy.active} onChange={this.processActive} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

                <br />
                <br />
              </div>
              <Button type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.coin_policy)} />
              <br />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default CoinPolicyCreate