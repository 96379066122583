import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class PrizeHistoryListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      userRowCount: CONSTANTS.ROW_COUNT,
      prizeRowCount: CONSTANTS.ROW_COUNT,
      userId: '',
      prizeId: '',
      startDate: '',
      endDate: ''
    }
  }
  componentDidMount() {
    this.loadMoreUser();
    this.loadMorePrize();
    this.props.getPrizeHistories(this.state.initialPage, this.state.rowCount);
  }
  handleSelectPrize = (prizeId) => {
    this.setState({
      prizeId
    }, () => {
      this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, this.state.startDate, this.state.endDate, this.state.initialPage)
    })
  }
  handleSelectUser = (userId) => {
    this.setState({
      userId
    }, () => {
      this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, this.state.startDate, this.state.endDate, this.state.initialPage)
    })
  }
  handleStartDateChange = (e) => {
    this.setState({
      startDate: e.target.value
    }, () => {
      let start_date = this.state.startDate + " 00:00:01";
      // let end_date = this.state.endDate && this.state.endDate
      this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, start_date, this.state.endDate, this.state.initialPage)
    })
  }
  handleEndDateChange = (e) => {
    this.setState({
      endDate: e.target.value
    }, () => {
      let start_date = this.state.startDate && this.state.startDate;
      let end_date = this.state.endDate + " 23:59:59";
      this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, this.state.startDate, end_date, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    let start_date = this.state.startDate ? this.state.startDate + " 00:00:01" : this.state.startDate;
    let end_date = this.state.endDate ? this.state.endDate + " 23:59:59" : this.state.endDate;
    this.props.searchPrizeHistory(this.state.prizeId, this.state.userId, start_date, end_date, this.state.initialPage)
  }
  loadMoreUser = () => {
    this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
    this.setState({
      userRowCount: this.state.userRowCount + 6
    })
  }
  loadMorePrize = () => {
    this.props.getAllPrizes(this.state.initialPage, this.state.prizeRowCount);
    this.setState({
      prizeRowCount: this.state.prizeRowCount + 6
    })
  }

  stopSearching = () => {
    this.setState({
      userId: '',
      prizeId: '',
      startDate: '',
      endDate: ''
    })
    this.props.getPrizeHistories(this.state.initialPage, this.state.rowCount)
  }

  // exportAllPrizeHistory = async () => {
  //   const url = `${CONSTANTS.BASE_URL}prize_histories/exportAllPrizeHistory`
  //   let fileName = "prize_history";
  //   let no = Math.floor(Math.random() * 100) + 1;
  //   fileName = `${fileName}${no}.xlsx`;
  //   const response = await axios.get(url, {
  //     responseType: 'blob',
  //   });
  //   if (response) {
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.download = fileName
  //     document.body.appendChild(link)
  //     link.click()
  //     window.URL.revokeObjectURL(url)
  //   }
  // }
  // exportByPrize = async () => {
  //   const url = `${CONSTANTS.BASE_URL}prize_histories/exportPrizeHistoryByPrize`
  //   let fileName = "prize_history";
  //   let no = Math.floor(Math.random() * 100) + 1;
  //   fileName = `${fileName}${no}.xlsx`;
  //   const response = await axios.get(url, {
  //     responseType: 'blob',
  //   });
  //   if (response) {
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.download = fileName
  //     document.body.appendChild(link)
  //     link.click()
  //     window.URL.revokeObjectURL(url)
  //   }
  // }
  // exportByUser = async () => {
  //   const url = `${CONSTANTS.BASE_URL}prize_histories/exportPrizeHistoryByUser`
  //   let fileName = "prize_history";
  //   let no = Math.floor(Math.random() * 100) + 1;
  //   fileName = `${fileName}${no}.xlsx`;
  //   const response = await axios.get(url, {
  //     responseType: 'blob',
  //   });
  //   if (response) {
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.download = fileName
  //     document.body.appendChild(link)
  //     link.click()
  //     window.URL.revokeObjectURL(url)
  //   }
  // }
}

export default PrizeHistoryListController