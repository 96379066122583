import React, { Component } from 'react';
import Modal from 'react-modal'
import './SelectModal.css'
import Moment from 'react-moment';

import { Correct, Button, Link, moment, FontAwesomeIcon, faArrowAltCircleRight, faCaretDown } from '../ImportsFile'
class SelectModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            searchTerm: '',
            initialPage: 1,
            selectedValue: props.itemName,
            // routeChange: props.routeChangeName
        }
        this.handleSelect = this.handleSelect.bind(this)
    }

    handleSelectForPlayerMigrate = (id, team_id, name) => {

        this.props.handleIdProcess(id);
        this.props.localTeamIdProcess(team_id);
        this.props.handleNameProcess(name);


        this.setState(state => ({
            ...state,
            selectedValue: name,
            show: false,
        }))

    }
    handleSelect = (id, name) => {
        if (this.props.handleSelectQuestion) {
            this.props.handleSelectQuestion(id)
        }
        else if (this.props.handleIdProcess && this.props.handleNameProcess) {
            this.props.handleIdProcess(id);
            this.props.handleNameProcess(name);
        }
        else if (this.props.handleSelectAnswerId && this.props.handleSelectAnswerName) {
            this.props.handleSelectAnswerId(id);
            this.props.handleSelectAnswerName(name);
        }
        else {
            this.props.handleProcess(id);
        }
        this.setState(state => ({
            ...state,
            selectedValue: name,
            show: false,
        }))

    }
    handleInputSearch = (e) => {
        this.setState({
            searchTerm: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searching && this.props.searching(this.state.searchTerm, this.props.currentPage)
        this.props.searchCity && this.props.searchCity(this.state.searchTerm, '', this.props.currentPage)
        this.props.searchTeam && this.props.searchTeam(this.state.searchTerm, '', '', this.props.currentPage)
        this.props.searchWithLeagueAndTeamName && this.props.searchWithLeagueAndTeamName(this.props.league_id, this.state.searchTerm, this.props.rowCount, this.props.currentPage)
        this.props.searchWithLeague && this.props.searchWithLeague(this.props.league_id, this.state.searchTerm, this.props.rowCount, this.props.currentPage)
        this.props.searchLeague && this.props.searchLeague(this.state.searchTerm, '', this.props.currentPage)
        this.props.type == 1 && this.props.searchPlayer(this.state.searchTerm, '', '', '', this.props.rowCount, this.props.currentPage)
        this.props.type == 3 && this.props.searchPlayer(this.state.searchTerm, '', '', '', this.props.rowCount, this.props.currentPage)
    }
    stopSearching = () => {
        this.props.getItems && this.props.getItems(this.state.initialPage, this.props.rowCount)
        this.props.getPlayers && this.props.getPlayers('', this.state.initialPage, this.props.rowCount)
        this.props.stopSearching && this.props.stopSearching(this.props.league_id, '', this.props.rowCount, this.props.currentPage)
        this.props.type === 1 && this.props.stopPlayerSearching('', '', '', this.props.rowCount, this.props.currentPage)
        this.props.type === 3 && this.props.stopPlayerSearching('', 'manager', '', this.props.rowCount, this.props.currentPage)
        this.setState({
            searchTerm: ''
        })
    }
    ShowModal = () => {
        this.setState({
            show: !this.props.disabled && true
        })

    }
    render() {

        return (
            <div>
                <div className="selectbox" name="selectedValue" onChange={this.handleSelect}
                    //  onClick={!this.props.disabled && this.ShowModal}
                    onClick={this.ShowModal}
                >
                    {
                        // this.state.selectedValue
                        this.props.submit_type ? this.props.submit_type : this.state.selectedValue
                    }
                    <span>  <FontAwesomeIcon icon={faCaretDown} /> </span>
                </div>
                {
                    this.state.show && <Modal className="createSelectModal"
                        ariaHideApp={false}
                        isOpen={this.state.show}
                        onRequestClose={() => this.setState({ show: false })}
                        shouldCloseOnOverlayClick={true}
                        style={
                            {
                                overlay: {
                                    background: 'transparent'
                                }
                            }
                        }
                    >
                        <p className="close" onClick={() => this.setState({ show: false })}>x</p>

                        <div className="item-lists" >

                            {
                                this.props.teamsForMatchCreate &&

                                <div style={{ width: "100%" }}>
                                    <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                        <div className="input-group">
                                            <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                            <button type="submit"><i className="fa fa-search"></i></button>
                                        </div>
                                    </form>
                                    <hr className="selectModalLineBreak" />
                                </div>

                            }
                            {
                                (this.props.teamsForMatchCreate && this.props.teamsForMatchCreate.length > 0) && this.props.teamsForMatchCreate.map((item, index) => {
                                    return (
                                        item?.Team &&
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.team_id, item?.Team?.name)
                                        }} >{item?.Team?.name}</p>
                                    )
                                })
                            }
                            {
                                (this.props.teams || this.props.team_leagues) &&
                                <div style={{ width: "100%" }}>
                                    <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                        <div className="input-group">
                                            <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                            <button type="submit"><i className="fa fa-search"></i></button>
                                        </div>
                                    </form>
                                    <hr className="selectModalLineBreak" />
                                </div>
                            }
                            {
                                (this.props.items || this.props.teamsForPlayers) &&
                                <>
                                    {
                                        (!this.props.noTeam && !this.props.noMatchForLivestream) &&
                                        <div style={{ width: "100%" }}>
                                            <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                                <div className="input-group">
                                                    <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} autoComplete="off" />
                                                    <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                                    <button type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </form>
                                            <hr className="selectModalLineBreak" />
                                        </div>
                                    }
                                </>
                            }
                            {
                                this.props.user_favourite_teams && <>
                                    {
                                        this.props.user_favourite_teams.length > 0 &&
                                        <div style={{ width: "100%" }}>
                                            <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                                <div className="input-group">
                                                    <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} />
                                                    <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                                    <button type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </form>
                                            <hr className="selectModalLineBreak" />
                                        </div>
                                    }
                                </>
                            }
                            {
                                this.props.quizzes && <>
                                    {
                                        this.props.quizzes.length > 0 &&
                                        <div style={{ width: "100%" }}>
                                            <form onSubmit={this.handleSearch} className="SelectSearchModal">
                                                <div className="input-group">
                                                    <input type="text" className="SelectSearchModalInput" name="name" placeholder="Search" onChange={this.handleInputSearch} value={this.state.searchTerm} />
                                                    <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                                    <button type="submit"><i className="fa fa-search"></i></button>
                                                </div>
                                            </form>
                                            <hr className="selectModalLineBreak" />
                                        </div>
                                    }
                                </>
                            }

                            {
                                this.props.user_favourite_teams && this.props.user_favourite_teams.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.User?.user_name + " Vs " + item.Team?.name)
                                        }} >
                                            <span>                                                    {item.User?.user_name} &#8608; {item.Team?.name}
                                            </span>
                                        </p>
                                    )
                                })
                            }
                            {
                                (this.props.items && this.props.items.length > 0) && this.props.items.map((item, index) => {
                                    return (
                                        item.HomeTeam ?
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.id, item.HomeTeam.name + " Vs " + item.AwayTeam.name)
                                            }} style={{ display: "flex", flexDirection: "column" }} >
                                                <strong>
                                                    <span>
                                                        {item.HomeTeam.name} <i>Vs</i> {item.AwayTeam.name}
                                                    </span>
                                                    <span className="league">
                                                        <b>Leagues: </b>
                                                        {item.League?.name}
                                                    </span>
                                                </strong>

                                                <small>
                                                    <span>
                                                        <b>Date: </b>
                                                        <Moment format="ddd, D MMM , YYYY">{item.date}</Moment>
                                                    </span>
                                                    <span>
                                                        <b>Time: </b> {item.time}
                                                    </span>
                                                </small>
                                            </p>
                                            :
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.id, item.name || item.user_name)
                                            }} >{item.name || item.user_name}</p>
                                    )
                                })

                            }
                            {
                                this.props.noMatchForLivestream && <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                    There is no match.
                                </span>
                            }
                            {
                                (this.props.matchesForHighlight && this.props.matchesForHighlight.length > 0) && this.props.matchesForHighlight.map((item, index) => {
                                    return (
                                        item.HomeTeam &&
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.HomeTeam.name + " Vs " + item.AwayTeam.name)
                                        }} >
                                            <strong>
                                                <span>
                                                    {item.HomeTeam.name} <i>Vs</i> {item.AwayTeam.name}
                                                </span>
                                                <span className="league">
                                                    <b>Leagues: </b>
                                                    {item.League?.name}
                                                </span>
                                            </strong>

                                            <small>
                                                <span>
                                                    <b>Date: </b>
                                                    <Moment format="ddd, D MMM , YYYY">{item.date}</Moment>
                                                </span>
                                                <span>
                                                    <b>Time: </b> {item.time}
                                                </span>
                                            </small>
                                        </p>

                                    )
                                })

                            }

                            {
                                // for matches in quiz
                                this.props.matches && this.props.matches.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item?.home?.longName + " Vs " + item?.away?.longName)
                                        }} >
                                            <strong>
                                                <span>
                                                    {item?.home?.longName.length > 18 ? item?.home?.longName.substring(0, 18) + '...' : item?.home?.longName} <i>Vs</i> {item?.away?.longName.length > 18 ? item?.away?.longName.substring(0, 18) + '...' : item?.away?.longName}
                                                </span>

                                                <span className="date">
                                                    <b>Date: </b>
                                                    <Moment format="ddd, D MMM , YYYY">{item?.time.substring(0, 10)}</Moment>
                                                </span>
                                            </strong>

                                            <small>
                                                <span className="league">
                                                    <b>Leagues: </b>
                                                    {this.props.leagueName}
                                                    {/**
                                                    {this.props.leagueName.substring(0,20)}
                                                 */}
                                                </span>
                                                <span>
                                                    <b>Time:  </b>
                                                    {moment(item?.time.substring(1, 16)).format('h:mm A')}
                                                </span>
                                            </small>
                                        </p>
                                    )
                                })
                            }
                            {
                                // for quiz details
                                this.props.quizzes && this.props.quizzes.map((item, index) => {
                                    return (
                                        <div key={index} onClick={() => {
                                            this.handleSelect(item.id, item?.league_name + " & " + item?.match_name)
                                        }} >
                                            <span className="search_with_quiz">
                                                <span>
                                                    <b>League&nbsp;:</b> <span>{item.league_name}</span>
                                                </span>
                                                <span>
                                                    <b>Match&nbsp; &nbsp;:</b> <span>{item.match_name || "-"}</span>
                                                </span>
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            {
                                (this.props.teams && this.props.teams.length > 0) && this.props.teams
                                    .map((item, index) => {
                                        return (
                                            item.HomeTeam ?
                                                <p key={index} onClick={() => {
                                                    this.handleSelect(item.id, item.HomeTeam.name + " Vs " + item.AwayTeam.name)
                                                }} >
                                                    <strong>
                                                        <span>
                                                            {item.HomeTeam.name} <i>Vs</i> {item.AwayTeam.name}
                                                        </span>
                                                        <span className="league">
                                                            <b>Leagues: </b>
                                                            {item.League?.name}
                                                        </span>
                                                    </strong>

                                                    <small>
                                                        <span>
                                                            <b>Date: </b>
                                                            <Moment format="ddd, D MMM , YYYY">{item.date}</Moment>
                                                        </span>
                                                        <span>
                                                            <b>Time: </b> {item.time}
                                                        </span>
                                                    </small>
                                                </p>

                                                :
                                                <p key={index} onClick={() => {
                                                    this.handleSelect(item.id, item.name || item.user_name)
                                                }} >{item.name || item.user_name}</p>

                                        )
                                    })
                            }
                            {
                                // for team from team_league 
                                (this.props.team_leagues && this.props.team_leagues.length > 0) && this.props.team_leagues.map((item, index) => {
                                    return (
                                        item?.Team &&
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item?.Team?.id, item?.Team?.name)
                                        }} >{item?.Team?.name}</p>
                                    )
                                })
                            }
                            {
                                // for questions 
                                (this.props.questions && this.props.questions.length > 0) && this.props.questions.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.question)
                                        }} >{item.question}</p>
                                    )
                                })
                            }

                            {
                                // teams for player
                                (this.props.teamsForPlayers && this.props.teamsForPlayers.length > 0) && this.props.teamsForPlayers.map((item, index) => {

                                    return (
                                        item?.Team &&
                                        <p key={index} onClick={() => {
                                            this.handleSelectForPlayerMigrate(item?.Team?.id_from_fotmob, item?.team_id, item?.Team?.name)
                                        }} >
                                            {item?.Team?.name}
                                            <span>
                                                {
                                                    this.props.teamIds.includes(item?.team_id) &&
                                                    <img
                                                        src={Correct}
                                                        alt=""
                                                        style={{ width: "25px" }}
                                                    />
                                                }
                                            </span>
                                        </p>
                                    )
                                })

                            }
                            {
                                this.props.noTeam && <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                    There is no team to migrate.
                                </span>
                            }
                            {
                                this.props.noMatchForHighlight && <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                    There is no match.
                                </span>
                            }
                            {
                                this.props.questions &&
                                <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>

                                    <Link to="/questions" style={{ textDecoration: "none", textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button caption="Create New Question" className="btn-primary add-button" />
                                    </Link>
                                </span>
                            }
                            {
                                // types for odds
                                (this.props.type_for_odds && this.props.type_for_odds.length > 0) && this.props.type_for_odds.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.name)
                                        }} >{item.name}</p>
                                    )
                                })
                            }
                            {
                                this.props.noQuestion &&
                                <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                    <span>
                                        There is no data!
                                    </span>
                                    <Link to="/questions" style={{ textDecoration: "none", textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button caption="Create New Question" className="btn-primary add-button" />
                                    </Link>
                                </span>
                            }

                            {
                                (this.props.teams && this.props.teams.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.team_leagues && this.props.team_leagues.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.items && this.props.items.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.matchesForHighlight && this.props.matchesForHighlight.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.teamsForPlayers && this.props.teamsForPlayers.length > 5) &&
                                <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.user_favourite_teams && this.props.user_favourite_teams.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }
                            {
                                (this.props.teamsForMatchCreate && this.props.teamsForMatchCreate.length > 5) && <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                            }


                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

export default SelectModal
