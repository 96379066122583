import React, { Component } from 'react'
import { Button } from './../buttons/Button';
import Swal from 'sweetalert2'
import './SwalStyle.css'
class Modal extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
                this.props.deleteItem();
            }
            // else {
            //     Swal.fire(
            //         'Cancelled',
            //         'Your imaginary file is safe :)',
            //         'error'
            //     )
            // }
        })

    }
    handleEdit = () => {
        // Swal.fire({
        //     title: 'Are you sure?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes, edit it!',
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         this.props.editItem()
        //     }
        // })
        this.props.editItem()
    }
    render() {
        return (
            <div className="actions">
                {
                    this.props.updatePermission &&
                    <Button caption="Edit" className="btn-info" icon="fas fa-edit" btnFun={this.handleEdit} style={{ marginRight: '10px' }} />
                }
                {
                    this.props.deletePermission && 
                    <Button caption="Delete" className="btn-danger" icon="fas fa-trash" btnFun={this.handleDelete} />
                }
            </div>
        )
    }
}

export default Modal
