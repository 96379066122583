const WalletType = {
    GET_WALLETS: "GET_WALLETS",
    ADD_WALLET: "ADD_WALLET",
    UPDATE_WALLET: "UPDATE_WALLET",
    DELETE_WALLET: "DELETE_WALLET",
    WALLET_ERROR: "WALLET_ERROR",
    WALLET_SUCCESS: "WALLET_SUCCESS",
    SORT_BY: "SORT_BY",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_ITEM: "SEARCH_ITEM",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    NOTIFICATION: "NOTIFICATION",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default WalletType