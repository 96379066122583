
import WalletType from './../type/wallet_type';
const initialState = {
    wallets: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    notification: false,
    loading: false,
    sort_by: null
}
const walletReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case WalletType.GET_WALLETS:
            return {
                ...state,
                loading: false,
                wallets: actions.payload
            }
        case WalletType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case WalletType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case WalletType.SORT_BY:
            return {
                ...state,
                sort_by: actions.payload
            }
        case WalletType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case WalletType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case WalletType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case WalletType.NOTIFICATION:
            return {
                ...state,
                notification: actions.payload
            }
        case WalletType.UPDATE_WALLET:
            const updateWallets = state.wallets.filter(wallet => wallet.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                wallets: [
                    actions.payload,
                    ...updateWallets,
                ]
            }
        case WalletType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case WalletType.WALLET_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case WalletType.WALLET_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default walletReducer