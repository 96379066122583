
import CountryType from './../type/country_type';
const initialState = {
    countries: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    totalCountPerPage: null,
    totalPage: 1,
    text: null,
    row_count: 1,
    loading: false,
}
const countryReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case CountryType.GET_COUNTRIES:
            return {
                ...state,
                loading: false,
                countries: actions.payload,
            }
        case CountryType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case CountryType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case CountryType.TOTAL_PAGE:
            return {
                ...state,
                totalPage: actions.payload
            }
        case CountryType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case CountryType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case CountryType.ADD_COUNTRY:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // countries: state.countries.push(actions.payload)
                countries: [
                    actions.payload,
                    ...state.countries,
                ],

            }
        case CountryType.UPDATE_COUNTRY:
            const updateCountry = state.countries.filter(country => country.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                countries: [
                    actions.payload,
                    ...updateCountry,
                ]
            }
        case CountryType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case CountryType.DELETE_COUNTRY:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                countries: state.countries.filter(country => country.id !== actions.payload)
            }
        case CountryType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case CountryType.COUNTRY_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case CountryType.COUNTRY_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default countryReducer