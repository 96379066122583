import { combineReducers } from 'redux'
import countryReducer from './country_reducer';
import cityReducer from './city_reducer';
import townshipReducer from './township_reducer'
import operatorReducer from './operator_reducer';
import roleReducer from './role_reducer';
import permissionReducer from './permission_reducer';
import paymentTypeReducer from './payment_type_reducer';
import rolePermissionReducer from './role_permission_reducer';
import teamReducer from './team_reducer';
import stadiumReducer from './stadium_reducer';
import matchReducer from './match_reducer';
import userReducer from './user_reducer';
import accessToMatchReducer from './access_to_match_reducer';
import walletReducer from './wallet_reducer';
import transactionReducer from './transaction_reducer';
import liveStreamReducer from './live_streaming_reducer';
import leagueReducer from './league_reducer';
import teamLeagueReducer from './team_league_reducer';
import notificationReducer from './notification_reducer';
import highlightReducer from './highlight_reducer';
import favouriteTeamLevelRuleReducer from './favourite_team_level_rule_reducer';
import userTeamActivityReducer from './user_team_activity_reducer';
import userFavouriteTeamReducer from './user_favourite_team_reducer';
import globalLevelRuleReducer from './global_level_rule_reducer';
import quizDetailsReducer from './quiz_details_reducer'
import activityLogsReducer from './activity_logs_reducer';
import userQuizAnswerReducer from './user_quiz_answer_reducer';
import quizAnswerReducer from './quiz_answer_reducer';
import specialQuizReducer from './special_quiz_reducer';
import questionReducer from './question_reducer';
import leaderboardReducer from './leaderboard.reducer';
import playerReducer from './player_reducer';
import sponsorReducer from './sponsor_reducer';
import oddsRateReducer from './odds_rate_reducer';
import specialQuizDetailsReducer from './special_quiz_details_reducer';
import coinPolicyReducer from './coin_policy_reducer';
import prizeReducer from './prize_reducer';
import prizeHistoryReducer from './prize_history_reducer';
import announcementReducer from './announcement_reducer';
import siteConfigsReducer from './site_configs_reducer';
import userFavouriteNationalTeamReducer from './user_favourite_national_team_reducer';
import versionCheckReducer from './version_check_reducer'


const rootReducer = combineReducers({
    user: userReducer,
    country: countryReducer,
    city: cityReducer,
    township: townshipReducer,
    operator: operatorReducer,
    role: roleReducer,
    permission: permissionReducer,
    role_permission: rolePermissionReducer,
    payment_type: paymentTypeReducer,
    league: leagueReducer,
    team: teamReducer,
    team_league: teamLeagueReducer,
    stadium: stadiumReducer,
    match: matchReducer,
    access_to_match: accessToMatchReducer,
    wallet: walletReducer,
    transaction: transactionReducer,
    livestream: liveStreamReducer,
    highlight: highlightReducer,
    favourite_team_level_rule: favouriteTeamLevelRuleReducer,
    global_level_rule: globalLevelRuleReducer,
    user_team_activity: userTeamActivityReducer,
    user_favourite_team: userFavouriteTeamReducer,
    special_quiz: specialQuizReducer,
    special_quiz_detail: specialQuizDetailsReducer,
    quiz_details: quizDetailsReducer,
    question: questionReducer,
    notification: notificationReducer,
    activity_log: activityLogsReducer,
    user_quiz_answer: userQuizAnswerReducer,
    quiz_answer: quizAnswerReducer,
    leaderboard: leaderboardReducer,
    player: playerReducer,
    sponsor: sponsorReducer,
    odds_rate: oddsRateReducer,
    coin_policy: coinPolicyReducer,
    prize: prizeReducer,
    prize_history: prizeHistoryReducer,
    announcement: announcementReducer,
    site_config: siteConfigsReducer,
    user_favourite_national_team: userFavouriteNationalTeamReducer,
    version_check: versionCheckReducer,

})

export default rootReducer;
