const NotificationType = {
    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    LOADING: "LOADING",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
}
export default NotificationType