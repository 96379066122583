import StadiumType from './../type/stadium_type';
const initialState = {
    stadiums: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}

const stadiumReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case StadiumType.GET_STADIUMS:
            return {
                ...state,
                loading: false,
                stadiums: actions.payload
            }
        case StadiumType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case StadiumType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case StadiumType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case StadiumType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case StadiumType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case StadiumType.ADD_STADIUM:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                stadiums: [
                    actions.payload,
                    ...state.stadiums,
                ]
            }
        case StadiumType.UPDATE_STADIUM:
            const updateStadiums = state.stadiums.filter(stadium => stadium.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                stadiums: [
                    actions.payload,
                    ...updateStadiums,
                ]
            }
        case StadiumType.DELETE_STADIUM:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                stadiums: state.stadiums.filter(stadium => stadium.id !== actions.payload)
            }
        case StadiumType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case StadiumType.STADIUM_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case StadiumType.STADIUM_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}

export default stadiumReducer;