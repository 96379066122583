import CONSTANTS from './../constants';
import axios from 'axios'
import UserFavouriteTeamType from './../type/user_favourite_team_type';

export const Error = (data) => {
    return {
        type: UserFavouriteTeamType.USER_FAVOURITE_TEAM_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: UserFavouriteTeamType.USER_FAVOURITE_TEAM_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: UserFavouriteTeamType.LOADING, payload: status })
    }
}
const getUserFavouriteTeams = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user-fav-team/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserFavouriteTeamType.GET_USER_FAVOURITE_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: UserFavouriteTeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: UserFavouriteTeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserFavouriteTeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: UserFavouriteTeamType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: UserFavouriteTeamType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserFavouriteTeamType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createUserFavouriteTeam = (user_favourite_team) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user-fav-team/create`, user_favourite_team).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserFavouriteTeamType.ADD_USER_FAVOURITE_TEAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateUserFavouriteTeam = (user_favourite_team) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}user-fav-team/update`, user_favourite_team).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserFavouriteTeamType.UPDATE_USER_FAVOURITE_TEAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteUserFavouriteTeam = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}user-fav-team/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserFavouriteTeamType.DELETE_USER_FAVOURITE_TEAM, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail to delete"
                dispatch({
                    type: UserFavouriteTeamType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserFavouriteTeamType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchWithUserOrTeam = (userId, teamId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user-fav-team/list?user_id=${userId}&team_id=${teamId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserFavouriteTeamType.GET_USER_FAVOURITE_TEAMS, payload: response.data.data
                })
                dispatch({
                    type: UserFavouriteTeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: UserFavouriteTeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: UserFavouriteTeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserFavouriteTeamType.SEARCH_WITH_TEAM,
                    payload: teamId
                })
                dispatch({
                    type: UserFavouriteTeamType.SEARCH_WITH_USER,
                    payload: userId
                })
            }
        })
    }
}

const UserFavouriteTeamAction = {
    getUserFavouriteTeams,
    createUserFavouriteTeam,
    updateUserFavouriteTeam,
    deleteUserFavouriteTeam,
    searchWithUserOrTeam,
}
export default UserFavouriteTeamAction