import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';
class UserDetailController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            showUserInfo: true,
            showUserLevel: false,
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        axios.post(`${CONSTANTS.BASE_URL}users/detail`, { id: id }).then(response => {
            this.setState({
                user: response.data.data
            })
        })

    }
    showUserInfo = () => {
        this.setState({
            showUserInfo: true,
            showUserLevel: false,
        })
    }
    showUserLevel = () => {
        this.setState({
            showUserInfo: false,
            showUserLevel: true,
        })
    }

}

export default UserDetailController