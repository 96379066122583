import React from 'react'
import VersionCheckCreateController from './VersionCheckCreateController';
import { Button, Input, Textarea } from '../../../components/ImportsFile'

class VersionCheckCreate extends VersionCheckCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Version Check' : 'Add Version Check'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ columnCount: '2' }}>
                <Input type="text" placeholder="Enter Version Name" name="version_name" value={this.state.version_check.version_name} onChange={this.handleInputVersionName} required />

                <Input type="number" placeholder="Enter Version Code" name="version_code" value={this.state.version_check.version_code} onChange={this.handleInputVersionCode} />

                <select name="force_update" className="select" value={this.state.version_check.force_update} onChange={this.handleSelectForceUpdate} required >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>

                <Input type="text" placeholder="Enter Play Store Link" name="playstore_link" value={this.state.version_check.playstore_link} onChange={this.handleInputPlayStoreLink} required />

                <Input type="text" placeholder="Enter App Store Link" name="appstore_link" value={this.state.version_check.appstore_link} onChange={this.handleInputAppStoreLink} required />

                <Input type="text" placeholder="Enter Manual Download Link" name="manual_download_link" value={this.state.version_check.manual_download_link} onChange={this.handleInputManualDownloadLink} required />

                <select name="active" className="select" value={this.state.version_check.active} onChange={this.handleSelect} required >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

                <Textarea type="text" placeholder="Enter Update Logs" name="udpate_logs" value={this.state.version_check.update_logs} onChange={this.handleInputUpdateLogs} required />

              </div>
              <br />
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.version_check)} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default VersionCheckCreate
