import { AccessToMatchCreate } from '../create/AccessToMatchCreate'
import { AccessToMatchListController } from './AccessToMatchListController'
import AccessToMatchAction from './../../../redux/action/access_to_match_action';
import UserAction from './../../../redux/action/user_action';
import MatchAction from './../../../redux/action/match_action';
import TeamAction from './../../../redux/action/team_action';
import { Alert, PageLoader, Search, Modal, Pagination, Button, connect, MainContainer, Header, Body } from '../../../components/ImportsFile'
class AccessToMatchList extends AccessToMatchListController {
    render() {
        return (
            <MainContainer>
                <Header>
                    <h4>Access To Match</h4>
                    <form className="search search-access-to-match">
                        <div className="input-group">

                            <Search

                                items={this.props.users}
                                searchItem={(userId) => this.handleSelectUser(userId)}
                                text={'Search with User'}
                                search={this.state.userId ? true : false}
                                searching={this.props.searchUser}
                                currentPage={this.props.currentPage}
                                LoadMore={this.loadMore1}
                                getItems={this.props.getAllUsers}
                                rowCount={this.state.userRowCount}
                                stopSearching={this.stopSearching}
                            />

                            <Search
                                items={this.props.matches}
                                searchItem={(matchId) => this.handleSelectMatch(matchId)}
                                text={'Search with Match'}
                                search={this.state.matchId ? true : false}
                                searchMatch={this.props.searchMatch}
                                currentPage={this.props.currentPage}
                                LoadMore={this.loadMore2}
                                getItems={this.props.getAllMatches}
                                rowCount={this.state.matchRowCount}
                                stopSearching={this.stopSearching}
                            />

                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>

                    <Button icon="" btnFun={this.showModal} caption="Add Access" className="btn-primary add-button" />
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Match</th>
                                <th>Username</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.access_to_matches.map((access_to_match, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{access_to_match.Match?.HomeTeam?.name} Vs {access_to_match.Match?.AwayTeam?.name}</td>
                                            <td>{access_to_match.User?.user_name}</td>
                                            <td>{access_to_match.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteAccessToMatch(access_to_match.id)} editItem={() => this.handleEdit(access_to_match)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
                        (!this.props.userId && !this.props.matchId) && this.props.getAccessToMatches(pageNumber, this.props.row_count);
                        (this.props.userId || this.props.matchId) && this.props.searchAccess(this.state.userId, this.state.matchId, pageNumber)
                    }} />

                    {
                        this.state.show &&
                        <AccessToMatchCreate
                            access_to_match={this.state.access_to_match}
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            createOrUpdate={this.createOrUpdate}
                            searchUser={this.props.searchUser}
                            searchMatch={this.props.searchMatch}
                            currentPage={this.props.currentPage}
                            userText={this.props.userText}
                            matchTime={this.props.matchTime}
                            userRowCount={this.props.userRowCount}
                            matchRowCount={this.props.matchRowCount}
                            getAllUsers={this.props.getAllUsers}
                            getAllMatches={this.props.getAllMatches}
                            users={this.props.users}
                            matches={this.props.matches}
                            loadMore1={this.loadMore1}
                            loadMore2={this.loadMore2}
                            requiredError={this.state.requiredError}
                        />
                    }

                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        access_to_matches: state.access_to_match.access_to_matches,
        currentPage: state.access_to_match.currentPage,
        totalCount: state.access_to_match.totalCount,
        users: state.user.users,
        matches: state.match.matches,
        error: state.access_to_match.error,
        success: state.access_to_match.success,
        userId: state.access_to_match.userId,
        matchId: state.access_to_match.matchId,
        totalCountPerPage: state.access_to_match.totalCountPerPage,
        row_count: state.access_to_match.row_count,
        loading: state.access_to_match.loading,
        userText: state.user.text,
        matchTime: state.match.time,
        userRowCount: state.user.row_count,
        matchRowCount: state.match.row_count
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAccessToMatches: (pageNumber, row_count) => dispatch(AccessToMatchAction.getAccessToMatches(pageNumber, row_count)),
        createAccessToMatch: (access_to_match) => dispatch(AccessToMatchAction.createAccessToMatch(access_to_match)),
        updateAccessToMatch: (access_to_match) => dispatch(AccessToMatchAction.updateAccessToMatch(access_to_match)),
        deleteAccessToMatch: (id) => dispatch(AccessToMatchAction.deleteAccessToMatch(id)),
        getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
        getAllMatches: (pageNumber, row_count) => dispatch(MatchAction.getAllMatches(pageNumber, row_count)),
        searchAccess: (userId, matchId, pageNumber) => dispatch(AccessToMatchAction.searchAccess(userId, matchId, pageNumber)),
        searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),
        searchMatch: (text, stadiumId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, pageNumber)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccessToMatchList)