import styled from "styled-components";

export const Container = styled.div`
    display: flex;    
    flex-direction: column;
    width:100%;
    color:#14A2B8;
    & input::placeholder{
        font-size: 16px;
    }
    & input:disabled {
    background-color: #fff;
    color: #202020;
    border:1px solid #fad230;
}

`;

export const Caption = styled.label`
    padding:6px;
    color:#202020;
    

`
export const Element = styled.textarea.attrs(props => ({
  type: props.type,
}))`
    color:#202020;
    border:1px solid #FAD230;
    font-size:14px;
    min-height:150px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius: 5px;
    padding-left:10px;
    padding-right:10px;
    :disabled{
      background-color:white;
      color:#202020;
    }    
    :focus{
      outline:none;
    }    
    width:100%;
  }
    
    
    
`;

export function Textarea({
  ...props
}) {
  return (
    <Container {...props}>

      <Caption {...props}>{props.label}</Caption>
      <Element {...props} />

    </Container>
  );
}