import React from 'react'
import FavTeamListController from './FavTeamListController';
import TeamAction from '../../../redux/action/team_action.js';

import { Pagination, Search, UserImg, PageLoader, fallBackTeam, connect } from '../../../components/ImportsFile'
class FavTeamList extends FavTeamListController {
  render() {
    return (

      this.state.loading ? <PageLoader /> :
        <div>
          <form className="search search-leaderboard" onSubmit={this.handleSearch} autoComplete="off" >
            <div className="input-group">
              <Search
                items={this.props.teams}
                searchItem={(teamId) => this.handleSelectTeamId(teamId)}
                searchTeam={this.props.searchTeam}
                rowCount={this.state.teamRowCount}
                getItems={this.props.getAllTeams}
                currentPage={this.props.currentTeamPage}
                text={'Search with Team'}
                search={this.state.teamId ? true : false}
                stopSearching={() => this.stopSearchingWithTeam()}
                LoadMore={this.loadMore}
              />
              <span className="clear_filter" onClick={() => this.stopSearchingWithTeam()} >&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>

          <div className="leaderboardCard">
            {
              (!this.state.searching && this.state.usersForFavTeamList) && this.state.usersForFavTeamList.map((item, index) => {
                return (

                  <div className="lists" key={index}>
                    <div style={{ fontSize: '14px' }}>
                      {this.state.initialPageForFavTeam + index + 1 + "."}
                    </div>

                    <div className="profile">
                      <img src={item?.User?.image_url} alt="" onError={(e) => (e.currentTarget.src = UserImg)} />
                    </div>
                    <div className="info">
                      <h6>{item.User?.user_name}</h6>

                      <span>
                        <img src={item?.Team?.logo_url} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        {item?.Team?.name}
                      </span>
                    </div>
                    <div className="level">
                      <div className="fav_level">
                        <span><small>{item?.User?.FavouriteTeamLevelRule?.level_number}</small></span>
                        {item?.User?.FavouriteTeamLevelRule?.level_name}
                      </div>


                      <div className="count">
                        <p>
                          WC: <span>{item?.UserTeamActivity?.for_team_watch_count}</span>
                        </p>
                        <p>
                          QC: <span>{item?.UserTeamActivity?.for_team_quizzes_count}</span>
                        </p>
                        <p>
                          TC: <span>{item?.UserTeamActivity?.total_count}</span>
                        </p>
                      </div>
                    </div>

                  </div>
                )
              })
            }
            {

              (this.state.searching && this.state.usersForFavTeam) && this.state.usersForFavTeam.map((item, index) => {
                return (

                  <div className="lists" key={index}>
                    <div style={{ fontSize: '14px' }}>
                      {this.state.initialPageForFavTeam + index + 1 + "."}
                    </div>
                    <div className="profile">
                      <img src={item?.User?.image_url} alt="" onError={(e) => (e.currentTarget.src = UserImg)} />
                    </div>
                    <div className="info">
                      <h6>{item?.User?.user_name}</h6>

                      <span>
                        <img src={item?.Team?.logo_url} alt="" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        {item?.Team?.name}
                      </span>
                    </div>
                    <div className="level">
                      <div className="fav_level">
                        <span><small>{item?.User?.FavouriteTeamLevelRule?.level_number}</small></span>
                        {item?.User?.FavouriteTeamLevelRule?.level_name}
                      </div>
                      <div className="count">
                        <p>
                          WC: <span>{item?.UserTeamActivity?.for_team_watch_count}</span>
                        </p>
                        <p>
                          QC: <span>{item?.UserTeamActivity?.for_team_quizzes_count}</span>
                        </p>
                        <p>
                          TC: <span>{item?.UserTeamActivity?.total_count}</span>
                        </p>
                      </div>


                    </div>

                  </div>
                )
              })


            }



          </div>

          {
            !this.state.loading && <p style={{ display: 'flex', justifyContent: 'space-between', padding: '15px 15px', fontSize: '14px' }}>
              <span>
                <b>WC : </b> <small>Watch Count</small>
              </span>
              <span>
                <b>QC : </b> <small>Quiz Count</small>
              </span>
              <span>
                <b>TC : </b> <small>Total Count</small>
              </span>
            </p>
          }

        </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    teams: state.team.teams,
    totalCount: state.user.totalCount,
    totalCountPerPage: state.user.totalCountPerPage,
    userTotalCount: state.user.totalCount,
    teamTotalCount: state.team.totalCount,
    currentPage: state.user.currentPage,
    currentUserPage: state.user.currentPage,
    currentTeamPage: state.team.currentPage,
    error: state.user.error,
    success: state.user.success,
    row_count: state.user.row_count,
    loading: state.user.loading,
    teamId: state.user_favourite_team.teamId,
    userId: state.user_favourite_team.userId,
    userText: state.user.text,
    teamText: state.team.text,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, countryId, pageNumber)),
    getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FavTeamList)