import CONSTANTS from '../constants';
import axios from 'axios'
import TransactionType from '../type/transaction_type';
import WalletType from './../type/wallet_type';


export const Error = (data) => {
    return {
        type: TransactionType.TRANSACTION_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: TransactionType.TRANSACTION_SUCCESS, payload: data
    }
}
export const TransactionLoading = (status) => {
    return (dispatch) => {
        return dispatch({ type: TransactionType.LOADING, payload: status })
    }
}
export const WalletError = (data) => {
    return {
        type: WalletType.WALLET_ERROR, payload: data
    }
}
export const WalletSuccess = (data) => {
    return {
        type: WalletType.WALLET_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: WalletType.LOADING, payload: status })
    }
}
const updateWallet = (wallet) => {
    return (dispatch) => {
        // dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}user/wallets/update`, wallet).then((response) => {
            if (response.data.status === 'Success') {
                localStorage.setItem('notification', 'new action')
                dispatch({
                    type: WalletType.UPDATE_WALLET,
                    payload: response.data.data
                })
                dispatch({
                    type: WalletType.NOTIFICATION,
                    payload: true,
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(WalletSuccess(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(WalletSuccess(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                const errorPayload = {};
                errorPayload['message'] = "NOT ENOUGH POINTS!";
                dispatch(WalletError(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(WalletError(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getTransactions = (pageNumber, row_count) => {
    return (dispatch) => {
        dispatch(TransactionLoading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.GET_TRANSACTIONS,
                    payload: response.data.data
                })
                dispatch({
                    type: TransactionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: TransactionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: TransactionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: TransactionType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const amountInTransaction = (transaction) => {
    return (dispatch) => {
        dispatch(TransactionLoading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/amount-in`, transaction).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.AMOUNTIN_TRANSACTION,
                    payload: response.data.data
                })
                // const successPayload = {};
                // successPayload['message'] = response.data.message;
                // dispatch(Success(successPayload))

            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }

            const successPayload = {};
            successPayload['message'] = "Successfully Created";
            dispatch(Success(successPayload))
            setTimeout(() => {
                const successPayload = null
                dispatch(Success(successPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)


        }).catch((err) => {
            const errorPayload = {};
            errorPayload['message'] = err.response.message;
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const amountOutTransaction = (transaction) => {
    return (dispatch) => {
        // dispatch(TransactionLoading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/amount-out`, transaction).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.AMOUNTOUT_TRANSACTION,
                    payload: response.data.data
                })
                // const successPayload = {};
                // successPayload['message'] = response.data.message;
                // dispatch(Success(successPayload))
            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }

            const successPayload = {};
            successPayload['message'] = "Successfully Created";
            dispatch(Success(successPayload))
            setTimeout(() => {
                const successPayload = null
                dispatch(Success(successPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const addPointsTransaction = (transaction) => {
    return (dispatch) => {
        dispatch(TransactionLoading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/add-point`, transaction).then((response) => {
            if (response.statusText === 'Created') {
                dispatch({
                    type: TransactionType.ADD_POINTS_TRANSACTION,
                    payload: response.data
                })

            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            let wallet = {
                id: parseInt(response.data.user_id),
                total_points: response.data.add_points,
                is_remove_point: false
            }
            dispatch(updateWallet(wallet))

            const successPayload = {};
            successPayload['message'] = "Successfully Created";
            dispatch(Success(successPayload))
            setTimeout(() => {
                const successPayload = null
                dispatch(Success(successPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

            dispatch(TransactionLoading(false))


        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const removePointsTransaction = (transaction) => {
    return (dispatch) => {
        dispatch(TransactionLoading(true))

        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/remove-point`, transaction).then((response) => {

            if (response.statusText === 'Created') {
                dispatch({
                    type: TransactionType.REMOVE_POINTS_TRANSACTION,
                    payload: response.data
                })
            }
            else if (response.data.status === "Failed") {
                const errorPayload = {};
                errorPayload['message'] = response.data.message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }

            let wallet = {
                id: response.data.user_id,
                total_points: response.data.remove_points,
                is_remove_point: true
            }
            dispatch(updateWallet(wallet))

            const successPayload = {};
            successPayload['message'] = "Successfully Created";
            dispatch(Success(successPayload))
            setTimeout(() => {
                const successPayload = null
                dispatch(Success(successPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
            dispatch(TransactionLoading(false))


        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateTransaction = (transaction) => {
    return (dispatch) => {
        dispatch(TransactionLoading(true))

        return axios.put(`${CONSTANTS.BASE_URL}user/transactions/update`, transaction).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.UPDATE_TRANSACTION,
                    payload: response.data.data
                })
                // const successPayload = {};
                // successPayload['message'] = response.data.message;
                // dispatch(Success(successPayload))
                // setTimeout(() => {
                //     const successPayload = null
                //     dispatch(Success(successPayload))
                // }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            const successPayload = {};
            successPayload['message'] = "Successfully Updated";
            dispatch(Success(successPayload))
            setTimeout(() => {
                const successPayload = null
                dispatch(Success(successPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deleteTransaction = (id) => {
    const operator = JSON.parse(localStorage.getItem('operator'))
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}user/transactions/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.DELETE_TRANSACTION,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Fail"
                dispatch({
                    type: TransactionType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: TransactionType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (userId, payTypeId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user/transactions/list?user_id=${userId}&payment_type_id=${payTypeId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: TransactionType.GET_TRANSACTIONS, payload: response.data.data
                })
                dispatch({
                    type: TransactionType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: TransactionType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: TransactionType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: TransactionType.SEARCH_WITH_USER,
                    payload: userId
                })
                dispatch({
                    type: TransactionType.SEARCH_WITH_PAYTYPE,
                    payload: payTypeId
                })
            }
        })
    }
}
const TransactionAction = {
    getTransactions,
    amountInTransaction,
    amountOutTransaction,
    addPointsTransaction,
    removePointsTransaction,
    updateTransaction,
    deleteTransaction,
    searchItem,
}
export default TransactionAction