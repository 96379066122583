
import NotificationType from './../type/notification_type';
const initialState = {
    notifications: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const notificationReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case NotificationType.GET_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                notifications: actions.payload
            }
        case NotificationType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case NotificationType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case NotificationType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case NotificationType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }

        case NotificationType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case NotificationType.ERROR:
            return { ...state, error: actions.payload, loading: false }
        case NotificationType.SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default notificationReducer