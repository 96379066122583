import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class PaymentTypeListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            payment_type: {},
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            requiredError: ''
        }
    }
    componentDidMount = () => {
        this.props.getPaymentTypes(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.props.getPaymentTypes(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: ''
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.text, this.state.initialPage)
    }
    handleEdit = async (payment_type) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            payment_type: {
                ...prevState.payment_type,
                payment_type
            }
        }))
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false,

        })
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let payment_type = {
            name: data.name,
            logo_url: data.logo_url,
            active: data.active,
            updated_by: operator.id
        }
        if (this.state.isEdit) {
            payment_type = { ...payment_type, id: data.id }
            let formData = new FormData();
            formData.append("id", payment_type.id)
            formData.append("name", payment_type.name)
            formData.append("active", payment_type.active)
            formData.append("updated_by", parseInt(operator.id));
            if (data.description) {
                formData.append("description", data.description)
            }
            if (typeof payment_type.logo_url !== "string") {
                formData.append("logo_url", payment_type.logo_url)
            }
            if (payment_type.name === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (payment_type.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.updatePaymentType(formData).then(() => {
                    this.props.getPaymentTypes(this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }
        } else {
            payment_type = { ...payment_type, created_by: operator.id, updated_by: operator.id }
            let formData = new FormData();
            formData.append("name", payment_type.name)
            formData.append("active", payment_type.active)
            formData.append("logo_url", payment_type.logo_url)
            formData.append("created_by", parseInt(operator.id))
            formData.append("updated_by", parseInt(operator.id))
            if (data.description) {
                formData.append("description", data.description)
            }
            if (payment_type.name === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else if (payment_type.logo_url === '') {
                this.setState({
                    requiredError: "Please Choose Image"
                })
            }
            else {
                this.props.createPaymentType(formData).then(() => {
                    this.props.getPaymentTypes(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }
}
export {
    PaymentTypeListController
}