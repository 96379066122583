const AccessToMatchType = {
    GET_ACCESSES: "GET_ACCESSES",
    ADD_ACCESS: "ADD_ACCESS",
    UPDATE_ACCESS: "UPDATE_ACCESS",
    DELETE_ACCESS: "DELETE_ACCESS",
    ACCESS_ERROR: "ACCESS_ERROR",
    ACCESS_SUCCESS: "ACCESS_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_USER: 'SEARCH_WITH_USER',
    SEARCH_WITH_MATCH: 'SEARCH_WITH_MATCH',
    // SEARCH_ITEM: "SEARCH_ITEM",
    // SEARCH_DATA: "SEARCH_DATA",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default AccessToMatchType