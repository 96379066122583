import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom'
import { Input } from '../../components/inputs/index';
import { Button } from '../../components/buttons/index';
import './RegisterLayout.css';
import Logo from './image/logo.png'
import axios from 'axios'

class RegisterLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            con_password: '',
            registered: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        axios.post("http://localhost:8000/register", {
            email: this.state.email,
            password: this.state.password,
            con_password: this.state.con_password
        }).then(response => {
            console.log(response)
            
        }).catch(err => {
            console.log(err)
        })
        this.setState({
            registered: true,
            email: '',
            password: '',
            con_password: ''
        })
        e.preventDefault()

    }

    render() {
        if(this.state.registered === true){
            return <Navigate to="/"/>
        }
        return (
            <div className="loginForm">
                <div className="card">
                    <div className="logo">
                        <img src={Logo} alt="logo" style={{ width: '100px', height: '100px' }} />
                    </div>
                    <h4>G.O.A.T Live TV</h4>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                            <Input type="text" name="email" placeholder="Enter Your Email" value={this.state.email} onChange={this.handleInputChange} />
                            <Input type="text" name="password" placeholder="Enter Your Password" value={this.state.password} onChange={this.handleInputChange} />
                            <Input type="text" name="con_password" placeholder="Confirm Your password" value={this.state.con_password} onChange={this.handleInputChange} />
                            <Button caption="Register" type="submit" className="btn-info" />
                        </form>
                        <button id="login-btn">
                            <Link to="/login">Login</Link>
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

export default RegisterLayout;




