const LiveStreamingType = {
    GET_LIVESTREAMS: "GET_LIVESTREAMS",
    ADD_LIVESTREAM: "ADD_LIVESTREAM",
    UPDATE_LIVESTREAM: "UPDATE_LIVESTREAM",
    LIVESTREAM_DETAIL: "LIVESTREAM_DETAIL",
    DELETE_LIVESTREAM: "DELETE_LIVESTREAM",
    LIVESTREAM_ERROR: "LIVESTREAM_ERROR",
    LIVESTREAM_SUCCESS: "LIVESTREAM_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_DATA: "SEARCH_DATA",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default LiveStreamingType;