import { Component } from 'react'
import CONSTANTS from '../../../redux/constants';
class SponsorCreateController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsors: {},
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      loading: true,
      zoom: false,

      sponsor: props.isEdit === false ? {
        sponsor_name: '',
        sponsor_image_url: '',
        sponsor_website_url: "",
        league_id: '',
        match_id: '',
        questinoId: '',
        active: 1,
      } :
        props.sponsor.sponsor,

    }
  }

  handleInputSponsorName = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sponsor: {
        ...prevState.sponsor,
        sponsor_name: e.target.value
      }
    }))
  }
  handleInputSponsorWebsite = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sponsor: {
        ...prevState.sponsor,
        sponsor_website_url: e.target.value
      }
    }))
  }
  LeagueProcess = (league_id) => {
    this.setState((prevState) => ({
      sponsor: {
        ...prevState.sponsor,
        league_id: league_id
      }
    }))
  }
  MatchProcess = (match_id) => {
    this.setState((prevState) => ({
      sponsor: {
        ...prevState.sponsor,
        match_id: match_id
      }
    }))
  }
  questionIdProcess = (questionId) => {
    this.setState((prevState) => ({
      sponsor: {
        ...prevState.sponsor,
        questionId: questionId
      }
    }))
  }
  handleInputSponsorImage = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sponsor: {
        ...prevState.sponsor,
        sponsor_image_url: e.target.value
      }
    }))
  }
  onChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        sponsor: {
          ...prevState.sponsor,
          sponsor_image_url: e.target.files[0]
        }
      }))
    }
  }

  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sponsor: {
        ...prevState.sponsor,
        active: e.target.value
      }
    }))
  }
  zoomToggle = () => {
    this.setState({
      zoom: !this.state.zoom,
    })
  }
}

export default SponsorCreateController
