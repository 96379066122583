import React, { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class UserTeamActivityListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            teamId: '',
            search: false,
        }
    }

    componentDidMount = () => {
        this.loadMore();
        this.props.getUserTeamActivity(this.state.initialPage, this.state.rowCount)
    }
    loadMore = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount)
    }
    handleSelectId = (teamId) => {
        this.setState({
            teamId: teamId,
            search: true,
        }, () => {
            this.props.searchItem(this.state.teamId, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.setState({
            search: true,
        }, () => {
            this.props.searchItem(this.state.teamId, this.state.initialPage)
        })
    }

    handleEdit = async (user_team_activity) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            user_team_activity: {
                ...prevState.user_team_activity,
                user_team_activity
            }
        }))

    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    createOrUpdate = (data) => {
        let user_team_activity = {
            user_favourite_team_id: data.user_favourite_team_id,
            for_team_watch_count: data.for_team_watch_count,
            for_team_quizzes_count: data.for_team_quizzes_count,
            // total_count: `${data.for_team_watch_count + data.for_team_quizzes_count}`,
            total_count: Number(data.for_team_watch_count) + Number(data.for_team_quizzes_count),
            active: data.active,
        }
        if (this.state.isEdit) {
            user_team_activity = { ...user_team_activity, id: data.id }
            if (user_team_activity.user_favourite_team_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateUserTeamActivity(user_team_activity)
                this.hideModal();
            }
        } else {
            user_team_activity = { ...user_team_activity }
            if (user_team_activity.user_favourite_team_id === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createUserTeamActivity(user_team_activity);
                this.hideModal();
            }
        }

    }
    stopSearching = () => {
        this.setState({
            teamId: '',
            search: false,
        })
        this.props.getUserTeamActivity(this.state.initialPage, this.state.rowCount)
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    hideModal = () => {
        this.setState({
            show: false,
        })
    }

}

export default UserTeamActivityListController 