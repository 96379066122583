import React from 'react'
import { TeamCreateController } from './TeamCreateController'
import { Select, Switch, SelectModal, FontAwesomeIcon, faCloudUploadAlt, Input, Button } from '../../../components/ImportsFile'

const textSwitch = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingRight: 2,
    color: "#FFFFFFA0",
    fontSize: 12,
    fontWeight: "bold",

}
class TeamCreate extends TeamCreateController {
    render() {

        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Team' : 'Add Team'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <div style={{ display: 'flex', marginTop: "8px", alignItems: "center", justifyContent: 'center' }}>
                            <span style={{ color: "#FAD230", fontSize: '16px', paddingRight: '10px' }}>
                                Manual
                            </span>
                            <Switch onChange={this.props.handleSwitch} checked={this.props.checked}
                                offColor="#00ff00"
                                onColor="#02ba02"
                                offHandleColor="#eaeaea"
                                onHandleColor="#eaeaea"
                                checkedIcon={
                                    <div style={textSwitch}> </div>
                                } uncheckedIcon={
                                    <div style={textSwitch}> </div>
                                }
                            />
                            <span style={{ color: "#FAD230", fontSize: '16px', paddingLeft: '10px' }}>
                                Migrate from fotmob
                            </span>
                        </div>
                        {
                            this.props.checked ?
                                <form autoComplete="off" style={{ width: '70%', margin: 'auto', }}>
                                    {
                                        this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                                    }

                                    <div style={{ marginBottom: '10px' }}>
                                        <SelectModal
                                            routeChangeName="league"
                                            items={this.state.leagues}
                                            // LoadMore={this.props.loadMore}
                                            // searchLeague={this.props.searchLeague}
                                            // currentPage={this.props.leagueCurrentPage}
                                            // getItems={this.props.getAllLeagues}
                                            // rowCount={this.props.leagueRowCount}
                                            handleProcess={(league_id) => this.LeagueProcess(league_id)
                                            }
                                            itemName={this.props.isEdit ? this.props.player.player?.League?.name : "*** Choose League ***"}
                                        />

                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <Button caption="Migrate" id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.country)} />
                                </form>
                                :
                                <form autoComplete="off">
                                    {
                                        this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                                    }
                                    <div style={{ columnCount: 2, marginTop: '10px' }} >
                                        <Select
                                            maxMenuHeight={150}
                                            menuPlacement="auto"
                                            isClearable={true}
                                            closeMenuOnSelect={false}
                                            placeholder="*** Choose Leagues ***"
                                            onChange={this.selectChange}
                                            options={this.state.options}
                                            value={this.state.selectedValue || this.state.options.filter(obj => this.state.team.league_id.includes(obj.value))}
                                            isMulti
                                        />
                                        <Input type="text" placeholder="Enter Team Name" name="name" value={this.state.team.name} onChange={this.handleInputName} required />

                                        <Input type="text" name="code" placeholder="Enter Team Code.." value={this.state.team.code} onChange={this.handleInputCode} required />

                                        <Input type="text" name="id_from_fotmob" placeholder="Enter Team Id from fotmob.." value={this.state.team.id_from_fotmob} onChange={this.handleInputIdFromFotmob} required />


                                        <SelectModal
                                            routeChangeName="stadium"
                                            items={this.props.stadiums}
                                            searching={this.props.searchItem}
                                            LoadMore={this.props.loadMore}
                                            currentPage={this.props.currentPage}
                                            getItems={this.props.getAllStadiums}
                                            rowCount={this.props.stadiumRowCount}
                                            handleProcess={(stadium_id) => this.StadiumProcess(stadium_id)}
                                            itemName={(this.props.isEdit && this.props.team.team?.Stadium?.name) ? this.props.team.team?.Stadium?.name : "*** Choose Stadium ***"}
                                        />

                                        <SelectModal
                                            routeChangeName="country"
                                            items={this.props.countries}
                                            searching={this.props.searchCountry}
                                            LoadMore={this.props.loadMoreCountry}
                                            currentPage={this.props.currentPage}
                                            getItems={this.props.getAllCountries}
                                            rowCount={this.props.countryRowCount}
                                            handleProcess={(country_id) => this.CountryProcess(country_id)}
                                            itemName={this.props.isEdit ? this.props.team.team?.Country?.name : "*** Choose Country ***"}
                                        />




                                        <select name="active" className="select" value={this.state.team.active} onChange={this.processActive} required >
                                            <option value="0">Inactive</option>
                                            <option value="1">Active</option>
                                        </select>


                                        <div className="image-upload" >
                                            <label htmlFor="file">
                                                <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#FAD230" />
                                                Choose Image
                                            </label>
                                            <input type="file" id="file" name="logo_url" onChange={this.onChangeImage} style={{ display: 'none' }} />
                                            {
                                                (typeof this.state.team.logo_url === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.team.logo_url} alt="" style={{ width: '70px', height: "70px" }} />
                                            }
                                            {
                                                (typeof this.state.team.logo_url !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.team.logo_url)} alt="" style={{ width: '70px', height: "70px" }} />
                                            }

                                            <br />
                                        </div>

                                    </div>

                                    <br />
                                    <Button className="btn-secondary" type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} btnFun={() => this.props.createOrUpdate(this.state.team)} />

                                </form>
                        }
                    </div>

                </div>
            </div>
        )
    }
}

export default TeamCreate
