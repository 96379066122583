import CONSTANTS from './../constants';
import axios from 'axios'
import SiteConfigsType from './../type/site_configs_type';

export const Error = (data) => {
  return {
    type: SiteConfigsType.SITE_CONFIGS_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: SiteConfigsType.SITE_CONFIGS_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: SiteConfigsType.LOADING, payload: status })
  }
}
const getSiteConfigs = (pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}site-config/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SiteConfigsType.GET_SITE_CONFIGS, payload: response.data.data
        })
        dispatch({
          type: SiteConfigsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: SiteConfigsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: SiteConfigsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: SiteConfigsType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: SiteConfigsType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SiteConfigsType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createSiteConfigs = (config) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.post(`${CONSTANTS.BASE_URL}site-config/create`, config).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SiteConfigsType.ADD_SITE_CONFIGS, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))

        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const updateSiteConfigs = (config) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.put(`${CONSTANTS.BASE_URL}site-config/update`, config).then((response) => {
      console.log("update=>", response)
      if (response.data.status === "Success") {
        dispatch({
          type: SiteConfigsType.UPDATE_SITE_CONFIGS, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const deleteSiteConfig = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'))
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}site-config/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: SiteConfigsType.DELETE_SITE_CONFIGS, payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "Failed"
        dispatch({
          type: SiteConfigsType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: SiteConfigsType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const SiteConfigsAction = {
  getSiteConfigs,
  createSiteConfigs,
  updateSiteConfigs,
  deleteSiteConfig
}
export default SiteConfigsAction