const StadiumType = {
    GET_STADIUMS : "GET_STADIUMS",
    ADD_STADIUM : "ADD_STADIUM",
    UPDATE_STADIUM: "UPDATE_STADIUM",
    DELETE_STADIUM: "DELETE_STADIUM",
    STADIUM_ERROR: "STADIUM_ERROR",
    STADIUM_SUCCESS: "STADIUM_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default StadiumType