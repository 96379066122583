import CONSTANTS from './../constants';
import axios from 'axios'
import VersionCheckType from './../type/version_check_type';

export const Error = (data) => {
  return {
    type: VersionCheckType.VERSION_CHECK_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: VersionCheckType.VERSION_CHECK_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: VersionCheckType.LOADING, payload: status })
  }
}
const getVersionChecks = (pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}version_checks/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: VersionCheckType.GET_VERSION_CHECKS, payload: response.data.data
        })
        dispatch({
          type: VersionCheckType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: VersionCheckType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: VersionCheckType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: VersionCheckType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Fail"
        dispatch({
          type: VersionCheckType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: VersionCheckType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createVersionCheck = (config) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.post(`${CONSTANTS.BASE_URL}version_checks/create`, config).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: VersionCheckType.ADD_VERSION_CHECK, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))

        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const updateVersionCheck = (config) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.put(`${CONSTANTS.BASE_URL}version_checks/update`, config).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: VersionCheckType.UPDATE_VERSION_CHECK, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const deleteVersionCheck = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'))
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}version_checks/delete`, { data: { id: id, deleted_by: operator.id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: VersionCheckType.DELETE_VERSION_CHECK, payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "Failed"
        dispatch({
          type: VersionCheckType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: VersionCheckType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const searchItem = (text, pageNumber) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}version_checks/list?keyword=${text}&page_at=${pageNumber}&sort_by=version_name&order_by=ASC`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: VersionCheckType.GET_VERSION_CHECKS, payload: response.data.data
        })
        dispatch({
          type: VersionCheckType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: VersionCheckType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: VersionCheckType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: VersionCheckType.SEARCH_ITEM,
          payload: text
        })
      }
    })
  }
}

const VersionCheckAction = {
  getVersionChecks,
  createVersionCheck,
  updateVersionCheck,
  deleteVersionCheck,
  searchItem
}
export default VersionCheckAction