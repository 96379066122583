import { Component } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import CONSTANTS from '../../redux/constants';
import OneSignal from 'react-onesignal';
export class LoginLayoutController extends Component {

    constructor(props) {
        super(props)
        const auth = localStorage.getItem('operator')
        let loggedIn = true;
        if (auth === null) {
            loggedIn = false;
        }
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            loggedIn,
            permissions: []

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.togglePassword = this.togglePassword.bind(this)
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();

        await axios.post(`${CONSTANTS.BASE_URL}auth/operator/login`, {
            email: this.state.email,
            password: this.state.password,
        }).then(async (response) => {
            if (response.data.status === 'Success') {
                const operator = response.data.data;
                const externalUserId = operator.id;
                await axios.post(`${CONSTANTS.BASE_URL}role-permissions/list?role_id=${operator.role_id}`).then(async (res) => {
                    let permission_id = res.data?.data[0]?.permission_id
                    await axios.post(`${CONSTANTS.BASE_URL}permission_detail/list?permission_id=${permission_id}&row_count=${200}`).then((data) => {
                        let permission = data.data.data;
                        this.setState({
                            permissions: permission
                        })
                    })
                })

                OneSignal.setExternalUserId(externalUserId)
                localStorage.setItem('operator', JSON.stringify(operator))
                localStorage.setItem('permissions', JSON.stringify(this.state.permissions))
                this.setState({
                    loggedIn: true
                })
            }
            else if (!this.state.email || !this.state.password) {
                Swal.fire({
                    icon: 'error',
                    text: "All fields are required",
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.data,
                })
            }
        }).catch((err) => {
            console.log(err.data)
        })


    }
    togglePassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
}

export default LoginLayoutController
