import axios from 'axios'
import CONSTANTS from './../constants';
import HighlightType from './../type/highlight_type';


export const Error = (data) => {
    return {
        type: HighlightType.HIGHLIGHT_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: HighlightType.HIGHLIGHT_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: HighlightType.LOADING, payload: status })
    }
}
const getHighlight = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}highlights/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: HighlightType.GET_HIGHLIGHT, payload: response.data.data
                })
                dispatch({
                    type: HighlightType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: HighlightType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: HighlightType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: HighlightType.ROW_COUNT, payload: row_count
                })
            }
            else {
                const message = "Failed";
                dispatch({
                    type: HighlightType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: HighlightType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createHighlight = (highlight) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}highlights/create`, highlight).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: HighlightType.ADD_HIGHLIGHT, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const updateHighlight = (highlight) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}highlights/update`, highlight).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: HighlightType.UPDATE_HIGHLIGHT, payload: response.data.data
                })
                const successPayload = {};
                successPayload["message"] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
            else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const deleteHighlight = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}highlights/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: HighlightType.DELETE_HIGHLIGHT, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: HighlightType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: HighlightType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (matchId, leagueId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}highlights/list?match_id=${matchId}&league_id=${leagueId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: HighlightType.GET_HIGHLIGHT, payload: response.data.data
                })
                dispatch({
                    type: HighlightType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: HighlightType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: HighlightType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: HighlightType.SEARCH_DATA,
                    payload: leagueId
                })
                dispatch({
                    type: HighlightType.SEARCH_ITEM,
                    payload: matchId
                })
            }
        })
    }
}

const HighlightAction = {
    getHighlight,
    createHighlight,
    updateHighlight,
    deleteHighlight,
    searchItem,
}

export default HighlightAction;