import React, { Component } from 'react'


class Alert extends Component {

    render() {
        return (

            (this.props.errorMessage !== null || this.props.successMessage !== null) &&

            <div
                className={this.props.errorMessage ? 'alert alert-danger alert-dismissible fade show' : 'alert alert-success alert-dismissible fade show'} role="alert"
            >
                {this.props.errorMessage && this.props.errorMessage}
                {this.props.successMessage && this.props.successMessage}

                {
                    this.props.errorMessage &&
                    <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
                }

            </div>

        )
    }
}

export default Alert
