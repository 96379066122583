import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';
import moment from 'moment'
class LiveScoreListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            leagues: [],
            league: '',
            matches: [],
            date: new Date(),
            // date: new Date().toDateString(),
            text: '',
            loading: true,
            logoUrl: 'https://images.fotmob.com/image_resources/logo/teamlogo/'
        }
    }

    componentDidMount = () => {

        this.getAllLeagues();
    }
    dateChange = (e) => {
        this.setState({
            date: e.target.value
        }, () => {
            this.getAllLeagues();
        })
    }
    getAllLeagues = () => {

        let date = moment(this.state.date).format("yyyyMMDD")
        let allMatches = []
        this.setState({ loading: true })
        axios.post(`${CONSTANTS.BASE_URL}livescores/match_list/${date}`)
            .then(response => {
                let leagues = response.data.leagues
                for (let i = 0; i < leagues.length; i++) {
                    let matches = leagues[i]?.matches
                    for (let j = 0; j < matches.length; j++) {
                        allMatches.push(matches[j])
                    }
                    this.setState({
                        leagues: response.data.leagues,
                        matches: allMatches,
                        loading: false
                    })
                }
            }).catch(e => {
                console.log(e.message);
            })
    }
    getScore = (id) => {
        this.state.leagues.map((league) => {
            return (
                league.id === id && this.setState({
                    matches: league.matches,
                    league: league.name
                })

            )
        })

    }
    stopSearching = () => {
        // this.getAllLeagues();
        this.setState({
            text: ''
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
    }

}

export default LiveScoreListController