import React, { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class SiteConfigsListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      config: {},
      requiredError: '',
      checked: false,

    }
    this.handleSwitch = this.handleSwitch.bind(this)

  }
  componentDidMount = () => {
    this.props.getSiteConfigs(this.state.initialPage, this.state.rowCount)
  }
  handleSwitch(checked) {
    this.setState({ checked })
  }
  handleEdit = (config) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      config: {
        ...prevState.config,
        config
      }
    }))
  }

  createOrUpdate = (data) => {
    console.log("checked", this.state.checked);
    let operator = JSON.parse(localStorage.getItem('operator'))
    let config = {
      registration_reward: data.registration_reward,
      active: data.active,
      updated_by: operator.id,
    }
    if (this.state.checked === false) {
      config = {
        start_date: 0,
        end_date: 0,
        duration_reward: 0
      }
    }
    else {
      if (data.start_date || data.end_date || data.duration_reward) {
        config = { ...config, start_date: new Date(data.start_date).getTime(), end_date: new Date(data.end_date).getTime(), duration_reward: data.duration_reward }
      }
    }
    if (this.state.isEdit) {
      config = { ...config, id: data.id }
      if (config.registration_reward === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateSiteConfigs(config).then(()=>{
          this.props.getSiteConfigs(this.state.initialPage, this.state.rowCount)
        })
        this.hideModal();
      }
    }
    else {
      config = { ...config, created_by: operator.id }
      if (config.registration_reward === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createSiteConfigs(config).then(()=>{
          this.props.getSiteConfigs(this.state.initialPage, this.state.rowCount)
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }
  }
  hideModal = () => {
    this.setState({
      show: false,
      showFotmobIdModal: false,
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default SiteConfigsListController
