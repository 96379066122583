const PrizeHistoryType = {
  GET_PRIZE_HISTORIES: "GET_PRIZE_HISTORIES",
  PRIZE_HISTORY_ERROR: "PRIZE_HISTORY_ERROR",
  PRIZE_HISTORY_SUCCESS: "PRIZE_HISTORY_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  SEARCH_WITH_PRIZE_ID: "SEARCH_WITH_PRIZE_ID",
  SEARCH_WITH_USER_ID: "SEARCH_WITH_USER_ID",
  SEARCH_WITH_START_DATE: "SEARCH_WITH_START_DATE",
  SEARCH_WITH_END_DATE: "SEARCH_WITH_END_DATE",
  ROW_COUNT: "ROW_COUNT",
  NOTIFICATION: "NOTIFICATION",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default PrizeHistoryType