import React, { Component } from 'react'
import CONSTANTS from './../../../../redux/constants';
import axios from 'axios'
class QuizDetailFutureListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      question_type: '',
      future_quiz_details: {},
      questionIDs: [],

      teamRowCount: CONSTANTS.ROW_COUNT,
      rowCount: CONSTANTS.ROW_COUNT,
      questionRowCount: CONSTANTS.ROW_COUNT,
      requiredError: '',

    }
  }
  componentDidMount = async () => {
    let id = this.props.match.params.id
    this.setState({ quizId: id })

    await axios.post(`${CONSTANTS.BASE_URL}special_quizzes/detail`, { id: id }).then(response => {
      let data = response.data.data
      if (id == data.id) {
        this.setState({
          quizInfo: data,
          quizType: data.quiz_type
        })
      }
    })
    this.getQuestionsIDs();
    this.props.getQuestions(this.state.quizType, this.state.initialPage, this.state.questionRowCount)
    this.props.getSpecialQuizDetailsLists(id, this.state.initialPage, this.state.rowCount)

  }
  getQuestionsIDs = async () => {
    const questionId = [];
    await axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/list?special_quiz_id=${this.state.quizId}&page_at=${this.state.initialPage}&row_count=${this.state.rowCount}`).then(response => {
      let data = response.data.data
      data.map((quiz) => {
        questionId.push(quiz.question_id)
        this.setState({
          questionIDs: questionId
        })
      })
    })
  }
  loadMoreQuestion = () => {
    this.props.getQuestions(this.state.quizType, this.state.initialPage, this.state.questionRowCount)
  }

  handleEdit = (future_quiz_details) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      future_quiz_details: {
        ...prevState.future_quiz_details,
        future_quiz_details
      }
    }))
  }
  createOrUpdate = async (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    let future_quiz_details = {
      special_quiz_id: this.state.quizId,
      question_id: data.question_id,
      type: data.type,
      active: data.active,
      quiz_type: this.state.quizInfo?.quiz_type,
      // quiz_type: this.state.quizInfo?.quiz_type,
      answer_count: 0,
      updated_by: operator.id,
    }
    if (this.state.isEdit) {

      future_quiz_details = { ...future_quiz_details, id: data.id }
      if (future_quiz_details.question_id === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateSpeicalQuizDetails(future_quiz_details).then(() => {
          this.props.getSpecialQuizDetailsLists(this.state.quizId, this.props.currentPage, 1000);
        })
        this.hideModal()
      }
    }
    else {
      future_quiz_details = { ...future_quiz_details, created_by: operator.id, }
      if (future_quiz_details.question === "") {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else if (this.state.questionIDs.includes(future_quiz_details.question_id)) {
        this.setState({
          requiredError: "This question is already exist"
        })
      }
      else {
        this.props.createSpecialQuizDetails(future_quiz_details).then(() => {
          this.props.getSpecialQuizDetailsLists(this.state.quizId, this.state.initialPage, 1000);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal()
      }
    }
  }

  hideModal = () => {
    this.setState({
      show: false,
      requiredError: ''
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default QuizDetailFutureListController
