import axios from 'axios'
import CONSTANTS from './../constants';
import LiveStreamingType from './../type/live_streaming_type';


export const Error = (data) => {
    return {
        type: LiveStreamingType.LIVESTREAM_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: LiveStreamingType.LIVESTREAM_SUCCESS, payload: data
    }
}
export const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: LiveStreamingType.LOADING, payload: status })
    }
}
const getLiveStreams = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            if (response.status === 200) {
                // if (response.data.status === "Success") {
                dispatch({
                    type: LiveStreamingType.GET_LIVESTREAMS, payload: response.data.data
                })
                dispatch({
                    type: LiveStreamingType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: LiveStreamingType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LiveStreamingType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: LiveStreamingType.ROW_COUNT, payload: row_count
                })
            }
            else {
                const message = "Failed";
                dispatch({
                    type: LiveStreamingType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LiveStreamingType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createLiveStream = (livestream) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}live-streamings/create`, livestream).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LiveStreamingType.ADD_LIVESTREAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateLiveStream = (livestream) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}live-streamings/update`, livestream).then((response) => {
            if (response.data.code === 200) {
                dispatch({
                    type: LiveStreamingType.UPDATE_LIVESTREAM, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}
const deleteLiveStream = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}live-streamings/delete`, { data: { id: id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: LiveStreamingType.DELETE_LIVESTREAM, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed to delete"
                dispatch({
                    type: LiveStreamingType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: LiveStreamingType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, matchId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?url=${text}&match_id=${matchId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: LiveStreamingType.GET_LIVESTREAMS, payload: response.data.data
                })
                dispatch({
                    type: LiveStreamingType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: LiveStreamingType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: LiveStreamingType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: LiveStreamingType.SEARCH_DATA,
                    payload: matchId
                })
                dispatch({
                    type: LiveStreamingType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}

const LiveStreamingAction = {
    getLiveStreams,
    createLiveStream,
    updateLiveStream,
    deleteLiveStream,
    searchItem,
}

export default LiveStreamingAction;