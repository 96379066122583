const PrizeType = {
  GET_PRIZES: "GET_PRIZES",
  ADD_PRIZE: "ADD_PRIZE",
  UPDATE_PRIZE: "UPDATE_PRIZE",
  DELETE_PRIZE: "DELETE_PRIZE",
  PRIZE_ERROR: "PRIZE_ERROR",
  PRIZE_SUCCESS: "PRIZE_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  TOTAL_PAGE: "TOTAL_PAGE",
  GET_PER_PAGE: "GET_PER_PAGE",
  SEARCH_ITEM: "SEARCH_ITEM",
  SEARCH_WITH_LEAGUE_ID: "SEARCH_WITH_LEAGUE_ID",
  SEARCH_WITH_EXPIRED_TIME: "SEARCH_WITH_EXPIRED_TIME",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}

export default PrizeType;