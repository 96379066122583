
import FavouriteTeamLevelRuleType from './../type/favourite_team_level_rule_type';
const initialState = {
    favourite_team_level_rules: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    text: '',
    loading: false,
}
const favouriteTeamLevelRuleReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case FavouriteTeamLevelRuleType.GET_FAVOURITE_TEAM_LEVEL_RULES:
            return {
                ...state,
                loading: false,
                favourite_team_level_rules: actions.payload
            }
        case FavouriteTeamLevelRuleType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case FavouriteTeamLevelRuleType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case FavouriteTeamLevelRuleType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case FavouriteTeamLevelRuleType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case FavouriteTeamLevelRuleType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case FavouriteTeamLevelRuleType.ADD_FAVOURITE_TEAM_LEVEL_RULE:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                favourite_team_level_rules: [
                    actions.payload,
                    ...state.favourite_team_level_rules,
                ]

            }
        case FavouriteTeamLevelRuleType.UPDATE_FAVOURITE_TEAM_LEVEL_RULE:
            const updatefavouriteTeamLevelRule = state.favourite_team_level_rules.filter(favouriteTeamLevelRule => favouriteTeamLevelRule.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                favourite_team_level_rules: [
                    actions.payload,
                    ...updatefavouriteTeamLevelRule,
                ]
            }
        case FavouriteTeamLevelRuleType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case FavouriteTeamLevelRuleType.DELETE_FAVOURITE_TEAM_LEVEL_RULE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                favourite_team_level_rules: state.favourite_team_level_rules.filter(favouriteTeamLevelRule => favouriteTeamLevelRule.id !== actions.payload)
            }
        case FavouriteTeamLevelRuleType.FAVOURITE_TEAM_LEVEL_RULE_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case FavouriteTeamLevelRuleType.FAVOURITE_TEAM_LEVEL_RULE_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default favouriteTeamLevelRuleReducer