import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
class WalletListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            wallet: {},
            rowCount: CONSTANTS.ROW_COUNT,
            userRowCount: CONSTANTS.ROW_COUNT,
            sort_by: 'total_points',
            selected: '',
            requiredError: ''
        }

    }
    componentDidMount() {
        this.loadMore();
        this.props.getWallets(this.state.sort_by, this.state.initialPage, this.state.rowCount);
    }

    handleSelectUser = (selected) => {
        this.setState({
            selected
        }, () => {
            this.props.searchItem(this.state.selected, this.state.initialPage)
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchItem(this.state.selected, this.state.initialPage)
    }
    loadMore = () => {
        this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
        this.setState({
            userRowCount: this.state.userRowCount + 6
        })
    }
    handleEdit = (wallet) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            wallet: {
                ...prevState.wallet,
                wallet
            }
        }))
    }
    createOrUpdate = (data) => {
        if (this.state.isEdit) {
            let wallet = {
                // id: data.user_id,
                id: data.id,
                // userId: data.user_id,
                // amount: data.current_amount,
                total_points: data.total_points,
            }
            if (wallet.total_points === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateWallet(wallet).then(()=>{
                    this.props.getWallets(this.state.sort_by, this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }
        }


    }
    stopSearching = () => {
        this.setState({
            selected: ''
        })
        this.props.getWallets(this.state.sort_by, this.state.initialPage, this.state.rowCount)
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
}

export {
    WalletListController
}