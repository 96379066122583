import React from 'react'
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, connect } from '../../../components/ImportsFile'
import AnnouncementListController from './AnnouncementListController';
import AnnouncementCreate from './../create/AnnouncementCreate';
import AnnouncementAction from './../../../redux/action/announcement_action';
import Moment from 'react-moment';

class AnnouncementList extends AnnouncementListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Announcement' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Announcement' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Announcement' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>Announcements</h4>
          <form className="search" onSubmit={this.handleSearch} autoComplete="off">
            <div className="input-group">
              <input type="text" value={this.state.text} className="input-field" placeholder="Search..." name="seach" onChange={this.handleInputText} />
              <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </form>
          {
            createPermission &&
            <Button btnFun={this.showModal} caption="Add Announcement" className="btn-primary add-button" />
          }

        </Header>

        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Go To Screen</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Button Icon</th>
                <th>Button Text</th>
                <th>Active</th>
                {
                  (updatePermission || deletePermission) &&
                  <th>Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.announcements.map((announcement, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img src={announcement?.image_url} alt="choosed images" />
                      </td>
                      <td>
                        {announcement.title}
                      </td>
                      <td>
                        {announcement?.go_to_screen === 0 && 'Home'}
                        {announcement?.go_to_screen === 1 && 'Live Score'}
                        {announcement?.go_to_screen === 2 && 'Quiz'}
                        {announcement?.go_to_screen === 3 && 'Special Quiz'}
                        {announcement?.go_to_screen === 4 && 'My Quiz'}
                        {announcement?.go_to_screen === 5 && 'Profile'}
                        {announcement?.go_to_screen === 6 && 'Leaderboard'}
                      </td>
                      <td>
                        {
                          announcement.start_date ?
                            <Moment format="ddd,D MMM ,YYYY">{announcement.start_date}</Moment>
                            : '-'
                        }
                      </td>
                      <td>
                        {
                          announcement.end_date ?
                            <Moment format="ddd,D MMM ,YYYY">{announcement.end_date}</Moment>
                            : '-'
                        }
                      </td>
                      <td>
                        {
                          announcement?.button_icon ?
                            <img src={announcement?.button_icon} alt="choosed images" />
                            : '-'
                        }
                      </td>
                      <td>
                        {
                          announcement.button_text ? announcement.button_text : '-'
                        }
                      </td>
                      <td>{announcement.active === 1 ? "Active" : "Inactive"}</td>
                      <td>
                        <Modal deleteItem={() => this.props.deleteAnnouncement(announcement.id)} editItem={() => this.handleEdit(announcement)} deletePermission={deletePermission} updatePermission={updatePermission} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => {
              this.props.getAnnouncements(pageNumber, this.props.row_count)
            }}
          />

          {
            this.state.show &&
            <AnnouncementCreate
              hideModal={this.hideModal}
              announcement={this.state.announcement}
              isEdit={this.state.isEdit}
              createOrUpdate={this.createOrUpdate}
              requiredError={this.state.requiredError}
            />

          }

        </Body>
      </MainContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    announcements: state.announcement.announcements,
    error: state.announcement.error,
    success: state.announcement.success,
    currentPage: state.announcement.currentPage,
    totalCount: state.announcement.totalCount,
    totalCountPerPage: state.announcement.totalCountPerPage,
    text: state.announcement.text,
    row_count: state.announcement.row_count,
    loading: state.announcement.loading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getAnnouncements: (pageNumber, row_count) => dispatch(AnnouncementAction.getAnnouncements(pageNumber, row_count)),
    createAnnouncement: (announcement) => dispatch(AnnouncementAction.createAnnouncement(announcement)),
    updateAnnouncement: (announcement) => dispatch(AnnouncementAction.updateAnnouncement(announcement)),
    deleteAnnouncement: (id) => dispatch(AnnouncementAction.deleteAnnouncement(id)),
    searchAnnouncement: (text, pageNumber) => dispatch(AnnouncementAction.searchAnnouncement(text, pageNumber))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementList)
