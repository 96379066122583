const QuizDetailsType = {
    GET_QUIZ_DETAILS_LISTS: "GET_QUIZ_DETAILS_LISTS",
    ADD_QUIZ_DETAILS: "ADD_QUIZ_DETAILS",
    UPDATE_QUIZ_DETAILS: "UPDATE_QUIZ_DETAILS",
    DELETE_QUIZ_DETAILS: "DELETE_QUIZ_DETAILS",
    QUIZ_DETAILS_ERROR: "QUIZ_DETAILS_ERROR",
    QUIZ_DETAILS_SUCCESS: "QUIZ_DETAILS_SUCCESS",
    SEARCH_WITH_QUIZ_ID:"SEARCH_WITH_QUIZ_ID",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_MATCH: 'SEARCH_WITH_MATCH',
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default QuizDetailsType