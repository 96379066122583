import axios from 'axios'
import CONSTANTS from './../constants';
import PaymentTypeType from './../type/payment_type_type';

export const Error = (data) => {
    return {
        type: PaymentTypeType.PAYMENT_TYPE_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: PaymentTypeType.PAYMENT_TYPE_SUCCESS, payload: data
    }
}

const getPaymentTypes = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}payment-types/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.code === 200) {
                dispatch({
                    type: PaymentTypeType.GET_PAYMENT_TYPES, payload: response.data.data
                })
                dispatch({
                    type: PaymentTypeType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: PaymentTypeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: PaymentTypeType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: PaymentTypeType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: PaymentTypeType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PaymentTypeType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllPaymentTypes = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}payment-types/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
            if (response.data.code === 200) {
                dispatch({
                    type: PaymentTypeType.GET_PAYMENT_TYPES, payload: response.data.data
                })
                dispatch({
                    type: PaymentTypeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: PaymentTypeType.ROW_COUNT, payload: row_count
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: PaymentTypeType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PaymentTypeType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createPaymentType = (city) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}payment-types/create`, city).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PaymentTypeType.ADD_PAYMENT_TYPE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updatePaymentType = (city) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}payment-types/update`, city).then((response) => {
            if (response.data.code === 200) {
                dispatch({
                    type: PaymentTypeType.UPDATE_PAYMENT_TYPE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const deletePaymentType = (id) => {
    let operator = JSON.parse(localStorage.getItem('operator'));
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}payment-types/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: PaymentTypeType.DELETE_PAYMENT_TYPE, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed to delete"
                dispatch({
                    type: PaymentTypeType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: PaymentTypeType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}payment-types/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PaymentTypeType.GET_PAYMENT_TYPES, payload: response.data.data
                })
                dispatch({
                    type: PaymentTypeType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: PaymentTypeType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: PaymentTypeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
            }
            return dispatch({
                type: PaymentTypeType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}
const searchPayType = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}payment-types/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: PaymentTypeType.GET_PAYMENT_TYPES, payload: response.data.data
                })
            }
            return dispatch({
                type: PaymentTypeType.SEARCH_ITEM,
                payload: text
            })
        })
    }
}

const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: PaymentTypeType.LOADING, payload: status })
    }
}
const PaymentTypeAction = {
    getPaymentTypes,
    getAllPaymentTypes,
    createPaymentType,
    updatePaymentType,
    deletePaymentType,
    searchItem,
    searchPayType,
    loading,
}

export default PaymentTypeAction;