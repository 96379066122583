import React from 'react'
import TopFanListController from './TopFanListController';
import LeaderboardAction from '../../../redux/action/leaderboard_action';
import { UserImg, PageLoader, Pagination, connect } from '../../../components/ImportsFile'
class TopFanList extends TopFanListController {
  render() {
    return (

      this.props.loading ? <PageLoader /> : <div>
        <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
          <div className="input-group">
            <input type="text" className="input-field" placeholder="Search.." name="search" value={this.state.text} onChange={this.handleInputText} />
            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
            <button type="submit"><i className="fa fa-search"></i></button>
          </div>
        </form>
        <div className="leaderboardCard">

          <div>
            {
              this.props.topFanUsers && this.props.topFanUsers.map((item, index) => {
                return (
                  <div className="topFanLists" key={index}>
                    <div style={{ fontSize: '14px' }}>
                      {((Number(this.props.currentPage) - 1) * Number(this.state.rowCount)) + index + 1 + "."}
                    </div>
                    <div className="profile">
                      <img src={item?.image_url} alt="" onError={(e) => (e.currentTarget.src = UserImg)} />
                    </div>
                    <div className="info">
                      <h6>{item?.user_name}</h6>
                      <span>
                        <p>
                          <b>Watch Count : </b>{item.global_watch_count}
                        </p>
                      </span>
                    </div>
                    <div className="level">
                      <span>
                        {item?.global_level_id}
                      </span>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
        {
          !this.props.loading && <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => { !this.props.text ? this.props.getTopFanUsers(pageNumber, this.state.rowCount) : this.props.searchItem(this.props.text, pageNumber) }}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    topFanUsers: state.leaderboard.top_fans,
    totalCount: state.leaderboard.totalCount,
    totalCountPerPage: state.leaderboard.totalCountPerPage,
    currentPage: state.leaderboard.currentPage,
    error: state.leaderboard.error,
    success: state.leaderboard.success,
    row_count: state.leaderboard.row_count,
    loading: state.leaderboard.loading,
    text: state.user.text,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTopFanUsers: (pageNumber, row_count) => dispatch(LeaderboardAction.getTopFanUsers(pageNumber, row_count)),
    searchUser: (text, pageNumber) => dispatch(LeaderboardAction.searchItem(text, pageNumber)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopFanList)