import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';

class PlayerCreateController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leagues: [],
      teams: [],
      // teamIds: [],
      league_id: '',
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      teamleagueRowCount: CONSTANTS.ROW_COUNT,
      migrate_player: props.isEdit === false ? {
        appId: '',
        teamId: '',
        teamName: '',
        localTeamId: '',
      } : props.migrate_player.migrate_player,
      player: props.isEdit === false ? {
        name: '',
        position: '',
        image_url: '',
        team_id: '',
        country_id: '',
        league_id: '',
        type: 1,
        active: 1,
      } : props.player.player
    }
  }

  componentDidMount = () => {
    this.setState((prevState) => ({
      ...prevState,
      migrate_player: {
        ...prevState.migrate_player,
        appId: this.props.coin_policies[0]?.fotmob_migration_id
      }
    }))

    this.loadMoreTeamLeague();
    this.getLeagues();
    // this.loadMoreTeam();
  }


  CountryProcess = (country_id) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        country_id: country_id
      }
    }))
  }
  TeamProcess = (team_id) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        team_id: team_id
      }
    }))
  }
  handleInputChange = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        name: e.target.value
      }
    }))
  }
  handleInputPosition = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        position: e.target.value
      }
    }))
  }

  handleSelectType = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        type: e.target.value
      }
    }))


  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      player: {
        ...prevState.player,
        active: e.target.value
      }
    }))
  }
  onChangeImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        player: {
          ...prevState.player,
          image_url: e.target.files[0]
        }
      }))
    }
  }

  //for migration
  getLeagues = async () => {
    await axios.post(`${CONSTANTS.BASE_URL}migration/all-leagues`).then((response) => {
      this.setState({
        leagues: response.data.popular
      })
    })
  }

  LeagueProcess = (league_id) => {
    this.setState((prevState) => ({
      ...prevState,
      league_id: league_id,
      player: {
        ...prevState.player,
        league_id: league_id
      }
    }), () => {
      this.props.searchWithLeague(this.state.player.league_id, '', this.state.teamleagueRowCount, this.state.initialPage)
    }
    )
  }
  loadMoreTeamLeague = () => {
    this.setState({
      teamleagueRowCount: this.state.teamleagueRowCount + 6
    }, () => {
      this.props.searchWithLeague(this.state.player.league_id, '', this.state.teamleagueRowCount, this.state.initialPage)
    })
  }
  TeamIdProcess = (id) => {
    this.setState((prevState) => ({
      ...prevState,
      migrate_player: {
        ...prevState.migrate_player,
        teamId: id
      }
    }))
  }
  LocalTeamIdProcess = (team_id) => {
    this.setState((prevState) => ({
      ...prevState,
      migrate_player: {
        ...prevState.migrate_player,
        localTeamId: team_id
      }
    }))
  }

  TeamNameProcess = (teamName) => {
    this.setState((prevState) => ({
      ...prevState,
      migrate_player: {
        ...prevState.migrate_player,
        teamName: teamName
      }
    }), () => {

      this.props.getTeamIds();
    })
  }
  AppIdProcess = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      migrate_player: {
        ...prevState.migrate_player,
        appId: e.target.value
      }
    }))
  }
}

export default PlayerCreateController