
const UserFavouriteTeamType = {
    GET_USER_FAVOURITE_TEAMS: "GET_USER_FAVOURITE_TEAMS",
    ADD_USER_FAVOURITE_TEAM: "ADD_USER_FAVOURITE_TEAM",
    UPDATE_USER_FAVOURITE_TEAM: "UPDATE_USER_FAVOURITE_TEAM",
    DELETE_USER_FAVOURITE_TEAM: "DELETE_USER_FAVOURITE_TEAM",
    USER_FAVOURITE_TEAM_ERROR: "USER_FAVOURITE_TEAM_ERROR",
    USER_FAVOURITE_TEAM_SUCCESS: "USER_FAVOURITE_TEAM_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_WITH_USER: "SEARCH_WITH_USER",
    SEARCH_WITH_TEAM: "SEARCH_WITH_TEAM",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default UserFavouriteTeamType