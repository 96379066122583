import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from '../../../redux/constants';
import moment from 'moment'
class SpecialQuizCreateController extends Component {
    constructor(props) {
        super(props);

        let match;
        let deadline;
        let time;
        let match_name;

        let today = moment(new Date()).format("MM-DD-YYYY")

        if (this.props.isEdit) {
            let date = new Date(parseInt(this.props.special_quiz.special_quiz.deadline))
            deadline = moment(date).format('YYYY-MM-DD')
            time = moment(date, ["h:mm A"]).format("HH:mm")
            if (this.props.special_quiz.special_quiz.match_name) {

                match = this.props.special_quiz.special_quiz.match_name && JSON.parse(this.props.special_quiz.special_quiz.match_name)
                match_name = {
                    api_id: match.api_id && match.api_id,
                    awayTeamId: match.awayTeamId,
                    awayTeamName: match.awayTeamName,
                    homeTeamId: match.homeTeamId,
                    homeTeamName: match.homeTeamName,
                    matchStartTime: match.matchStartTime,
                    match_deadline: match.match_deadline,
                    timestamp: match.timestamp,
                }
            }

        }

        this.state = {
            leagues: {},
            initialPage: 1,
            leagueRowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            loading: true,
            match_time: '',
            matches: [],
            special_quiz: props.isEdit === false ? {
                league_id: '',
                country_id: '',
                match_id: '',
                deadline_date: '',
                deadline_time: '',
                deadline_str: '',
                quiz_type: 1,
                deadline: "",
                active: 1,

            } :
                //  props.special_quiz.special_quiz
                {
                    ...props.special_quiz.special_quiz,
                    match_name: match_name,
                    country_name: this.props.special_quiz.special_quiz.country_name ? this.props.special_quiz.quiz.country_name : "",
                    league_id_from_fotmob: this.props.special_quiz.special_quiz.league_id_from_fotmob,
                    // deadline: this.props.special_quiz.special_quiz.deadline,
                    deadline_date: this.props.special_quiz.special_quiz.deadline && deadline,
                    deadline_time: this.props.special_quiz.special_quiz.deadline && time,
                }

        }
    }

    handleSelectLeague = (id) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                league_id: id
            }
        }))
        if (id === 2 || id === 3 || id === 4) {
            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    country_id: 197
                }
            }))
        }
        else if (id === 1) {
            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    country_id: 186
                }
            }))
        }
        else if (id === 5) {
            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    country_id: 165
                }
            }))
        }
        else if (id === 6) {
            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    country_id: 83
                }
            }))
        }
        else if (id === 7) {
            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    country_id: 60
                }
            }))
        }
        axios.post(`${CONSTANTS.BASE_URL}matches/list`).then((response) => {
            let newMatch = [];
            let matches = response.data.data;

            matches.map(match => {
                if (id === match.league_id && match.is_playing === 0) {
                    newMatch.push(match)
                }
            })
            this.setState({
                matches: newMatch
            })
        })
    }
    handleSelectMatch = async (id) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                match_id: id
            }
        }),
            () => {
                this.getMatchTime(id)
            })

    }

    getMatchTime = async (id) => {
        await axios.post(`${CONSTANTS.BASE_URL}matches/detail`, { id: id }).then((response) => {
            let date = response.data.data.date;
            let time = moment(response.data.data.time, ["h:mm A"]).format("HH:mm");

            let timestamp = new Date(
                date.split("-")[0],
                parseInt(date.split("-")[1]) - 1,
                date.split("-")[2],
                time.split(":")[0],
                time.split(":")[1]
            ).getTime();

            let oneHour = 1 * 60 * 60 * 1000
            let subTime = timestamp - oneHour
            let match_deadline_date = moment(subTime).format("YYYY-MM-DD")
            let match_deadline_time = moment(subTime).format("HH:mm")


            let startDate = moment(timestamp).format("DD/MM")
            let endDate = moment(subTime).format("DD/MM")

            let deadline_str = startDate == endDate ? moment(subTime).format("h:mm A") : moment(subTime).format("h:mm A, DD/MM")


            this.setState((prevState) => ({
                ...prevState,
                special_quiz: {
                    ...prevState.special_quiz,
                    deadline_time: match_deadline_time,
                    deadline_date: match_deadline_date,
                    deadline: subTime,
                    deadline_str: deadline_str
                },
                match_time: moment(timestamp).format("YYYY-MM-DD h:mm A"),
            }))
        })
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                active: e.target.value
            }
        }))
    }
    handleSelectQuizType = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                quiz_type: e.target.value
            }
        }))
    }
    DeadlineDate = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                deadline_date: e.target.value
            }
        }), () => {
            let date = this.state.special_quiz.deadline_date
            let time = this.state.special_quiz.deadline_time ? this.state.special_quiz.deadline_time : "00:00"
            let matchDate = this.props.isEdit ? this.state.special_quiz?.Match?.date : moment(this.state.match_time).format("YYYY-MM-DD");
            let matchTime = this.props.isEdit ? this.state.special_quiz?.Match?.time : moment(this.state.match_time).format('HH:mm');
            this.getTime(date, time, matchDate, matchTime);
        })
    }
    DeadlineTime = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                deadline_time: e.target.value
            }
        }), () => {
            let date = this.state.special_quiz.deadline_date
            let time = this.state.special_quiz.deadline_time
            let matchDate = this.props.isEdit ? this.state.special_quiz?.Match?.date : moment(this.state.match_time).format("YYYY-MM-DD");
            let matchTime = this.props.isEdit ? this.state.special_quiz?.Match?.time : moment(this.state.match_time).format('HH:mm');
            this.getTime(date, time, matchDate, matchTime);
        })
    }

    getTime = (inputDate, inputTime, matchDate, matchTime) => {

        // match time
        let match_time = moment(matchTime, ["h:mm A"]).format("HH:mm");
        let matchDateInfo = new Date(
            matchDate.split("-")[0],
            parseInt(matchDate.split("-")[1]) - 1,
            matchDate.split("-")[2],
            match_time.split(":")[0],
            match_time.split(":")[1]
        ).getTime();



        // deadline
        let date = inputDate;
        let time = moment(inputTime, ["h:mm A"]).format("HH:mm");

        let timestamp = new Date(
            date.split("-")[0],
            parseInt(date.split("-")[1]) - 1,
            date.split("-")[2],
            time.split(":")[0],
            time.split(":")[1]
        ).getTime();


        let startDate = moment(matchDateInfo).format("DD/MM")
        let endDate = moment(timestamp).format("DD/MM")

        let deadline_str;
        if (this.state.special_quiz.quiz_type == 2) {
            deadline_str = moment(timestamp).format("DD/MM/YYYY")
        }
        else {
            deadline_str = startDate == endDate ? moment(timestamp).format("h:mm A") : moment(timestamp).format("h:mm A, DD/MM")
        }

        this.setState((prevState) => ({
            ...prevState,
            special_quiz: {
                ...prevState.special_quiz,
                deadline: timestamp,
                deadline_str: deadline_str
            },
            // match_time: moment(timestamp).format("YYYY-MM-DD h:mm A"),
        }))
    }
}

export default SpecialQuizCreateController