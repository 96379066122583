import React from 'react'
import { TransactionsCreateController } from './TransactionsCreateController'
import { Switch, SelectModal, FontAwesomeIcon, faCloudUploadAlt, Input, Button } from '../../../components/ImportsFile'

const styles = {
    textSwitch: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingRight: 2,
        color: "#FFFFFFA0",
        fontSize: 12,
        fontWeight: "bold",
        zIndex: 0,
    },
    Img: {
        maxWidth: '100px', cursor: 'pointer'
    }
}
class TransactionsCreate extends TransactionsCreateController {
    render() {

        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Transactions' : 'Add Transactions'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off">
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div style={{ columnCount: '2' }}>

                                <SelectModal
                                    routeChangeName="user"
                                    items={this.props.users}
                                    searching={this.props.searchUser}
                                    currentPage={this.props.currentPage}
                                    getItems={this.props.getAllUsers}
                                    rowCount={this.props.userRowCount}
                                    handleProcess={(user_id) => this.userIdChange(user_id)}
                                    itemName={this.props.isEdit ? this.props.transaction.transaction.User.user_name : "*** Choose User ***"}
                                    LoadMore={this.props.loadMore1}

                                />

                                <SelectModal
                                    items={this.props.payment_types}
                                    routeChangeName="payment_type"
                                    searching={this.props.searchPayType}
                                    currentPage={this.props.currentPage}
                                    handleProcess={(payment_type_id) => this.paymentTypeChange(payment_type_id)}
                                    itemName={this.props.isEdit ? this.props.transaction.transaction.PaymentType.name : "*** Choose Payment Type ***"}
                                    LoadMore={this.props.loadMore2}
                                    getItems={this.props.getAllPaymentTypes}
                                    rowCount={this.props.paymentTypeRowCount}
                                />

                                <select name="active" className="select" value={this.state.transaction.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>


                                <Input type="text" name="description" placeholder="Enter Description ...(Optional)" value={this.state.transaction.description || ''} onChange={this.handleInputDescription} />

                                {
                                    this.props.checked ? <Input type="text" name="amount_in" placeholder="Enter Amount..." value={this.state.transaction.amount_in} onChange={this.AmountInProcess} /> : <Input type="text" name="amount_out" placeholder="Enter Amount..." value={this.state.transaction.amount_out} onChange={this.AmountOutProcess} />
                                }

                                {
                                    this.props.checked ? <Input type="text" name="add_points" placeholder="Enter Points..." value={this.state.transaction.add_points} onChange={this.AddPointsProcess} /> : <Input type="text" name="remove_points" placeholder="Enter Points..." value={this.state.transaction.remove_points} onChange={this.RemovePointsProcess} />
                                }

                            </div>

                            <div style={{ columnCount: '2' }}>
                                <div className="image-upload" style={{ paddingTop: '0px', marginTop: '0px' }} >
                                    <label htmlFor="file">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#FAD230" />
                                        Choose Image
                                    </label>
                                    <input type="file" id="file" name="payment_screenshot" onChange={this.onChangeImage} style={{ display: 'none' }} />
                                    {
                                        (typeof this.state.transaction.payment_screenshot == "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.transaction.payment_screenshot} alt="" style={styles.Img} onClick={this.zoomToggle} />
                                    }
                                    {
                                        (typeof this.state.transaction.payment_screenshot !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.transaction.payment_screenshot)} alt="" style={styles.Img} onClick={this.zoomToggle} />
                                    }


                                </div>

                                <div style={{ paddingTop: '22px' }}>

                                    <Switch onChange={this.props.handleSwitch} checked={this.props.checked}
                                        offColor="#ff0000"
                                        onColor="#02ba02"
                                        offHandleColor="#eaeaea"
                                        onHandleColor="#eaeaea"
                                        checkedIcon={
                                            <div style={styles.textSwitch}>In</div>
                                        } uncheckedIcon={
                                            <div style={styles.textSwitch}>Out</div>
                                        }
                                    />
                                    <span style={{ color: "#FAD230", fontWeight: "bold" }}>
                                        {
                                            this.props.checked ? " Deposit" : " Withdraw"
                                        }
                                    </span>
                                </div>
                            </div>

                            {
                                this.state.zoom &&
                                <div className="zoomModal">
                                    <img className="zoomImg" src={typeof this.state.transaction.payment_screenshot == "string" ? this.state.transaction.payment_screenshot : URL.createObjectURL(this.state.transaction.payment_screenshot)} alt="" onClick={this.zoomToggle} />
                                </div>
                            }

                            <Button type="button" btnFun={this.handleSubmit} id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" />
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export {
    TransactionsCreate
}
