import React from 'react'
import PlayerListController from './PlayerListController';
import PlayerCreate from './../create/PlayerCreate';
import LeagueAction from '../../../redux/action/league_action.js'
import TeamAction from './../../../redux/action/team_action';
import PlayerAction from './../../../redux/action/player_action';
import TeamLeagueAction from './../../../redux/action/team_league_action.js'
import CountryAction from './../../../redux/action/country_action';
import CoinPolicyAction from './../../../redux/action/coin_policy_action.js';
import { Alert, PageLoader, Search, Modal, Pagination, Button, connect, MainContainer, Header, Body } from '../../../components/ImportsFile'
class PlayerList extends PlayerListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Player' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Player' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Player' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        let position = [
            { id: 1, type: "Attracker" },
            { id: 2, type: "Midfielder" },
            { id: 3, type: "Defender" },
            { id: 4, type: "Goalkeeper" },
            { id: 5, type: "Manager" },
            { id: 6, type: "All" }
        ]
        return (
            <MainContainer>
                <Header>
                    <h4>Players</h4>

                    <form className="search search-player" autoComplete="off" >
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search with Player Name.." name="search" onChange={this.handleInputText} />
                            {/**
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        */}
                            <Search
                                position={position}
                                searchItem={(position) => this.handleSelectPosition(position)}
                                text={'Search with Position'}
                                search={this.state.position ? true : false}
                                searchPlayer={this.props.searchPlayer}
                                getItems={this.props.getPlayers}
                                currentPage={this.props.currentPage}
                                rowCount={this.state.rowCount}
                                stopSearching={() => this.stopSearching()}
                            />
                            <Search
                                items={this.props.teams}
                                searchItem={(teamId) => this.handleSelectTeam(teamId)}
                                text={'Search with Team'}
                                search={this.state.teamId ? true : false}
                                currentPage={this.props.currentPage}
                                searchTeam={this.props.searchTeam}
                                getItems={this.props.getAllTeams}
                                rowCount={this.props.teamRowCount}
                                LoadMore={this.loadMoreTeam}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button icon="" btnFun={this.showModal} caption="Add Player" className="btn-primary add-button" />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>League</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Team</th>
                                <th>Country</th>
                                <th>Status</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.players.map((player, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{
                                                player?.League ? <img src={player?.League?.logo_url} alt="choosed images" /> : '-'
                                            }</td>
                                            <td>
                                                <img src={player.image_url} alt="choosed images"
                                                // onError={(e) => (e.currentTarget.src = fallBackPlayer)}
                                                />
                                            </td>
                                            <td>{player.name}</td>
                                            <td>

                                                {
                                                    player.position === 'attackers' && 'Attacker'
                                                }
                                                {

                                                    player.position === 'midfielders' && 'Midfielder'
                                                }
                                                {
                                                    player.position === 'defenders' && 'Defender'
                                                }
                                                {
                                                    player.position === 'goalkeepers' && 'Goalkeeper'
                                                }
                                                {
                                                    player.position === 'manager' && 'Manager'
                                                }
                                                {
                                                    // player.position.charAt(0).toUpperCase() + player.position.slice(1)
                                                }

                                            </td>
                                            <td>{player?.Team?.name}</td>
                                            <td>
                                                {
                                                    player?.Country ? player?.Country?.name : '-'
                                                }
                                            </td>
                                            <td>{player?.type === 1 ? 'Player' : 'Coach'}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deletePlayer(player.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(player)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>


                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={
                        (pageNumber) => {
                            (!this.props.teamId && !this.props.text && !this.props.position) ?
                                this.props.getPlayers(this.props.teamId, pageNumber, this.props.row_count)
                                : this.props.searchPlayer(this.state.text, this.state.position, this.state.leagueId, this.state.teamId, this.props.row_count, pageNumber)
                        }} />

                    {
                        this.state.show &&
                        <PlayerCreate
                            coin_policies={this.props.coin_policies}
                            hideModal={this.hideModal}
                            player={this.state.player}
                            isEdit={this.state.isEdit}
                            totalCount={this.props.totalCount}
                            createOrUpdate={this.createOrUpdate}
                            requiredError={this.state.requiredError}
                            handleSwitch={this.handleSwitch}
                            checked={this.state.checked}
                            leagues={this.props.leagues}
                            teams={this.props.teams}
                            teamList={this.props.teamList}
                            loadMoreTeam={this.loadMoreTeam}
                            loadMoreLeague={this.loadMoreLeague}
                            searchTeam={this.props.searchTeam}
                            searchWithLeague={this.props.searchWithLeague}
                            teamCurrentPage={this.props.teamCurrentPage}
                            getAllTeams={this.props.getAllTeams}
                            teamRowCount={this.state.teamRowCount}
                            text={this.props.text}
                            searchLeague={this.props.searchLeague}
                            leagueCurrentPage={this.props.leagueCurrentPage}
                            getAllLeagues={this.props.getAllLeagues}
                            getCountries={this.props.getCountries}
                            leagueRowCount={this.state.leagueRowCount}
                            textLeague={this.props.textLeague}
                            countries={this.props.countries}
                            countryRowCount={this.state.countryRowCount}
                            loadMoreCountry={this.loadMoreCountry}
                            searchCountry={this.props.searchCountry}
                            textCountry={this.props.textCountry}
                            countryCurrentPage={this.props.countryCurrentPage}
                            migratePlayer={this.migratePlayer}
                            migrate_player={this.state.migrate_player}
                            teamIds={this.state.teamIds}
                            getTeamIds={this.getTeamIds}

                        />
                    }

                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        leagues: state.league.leagues,
        coin_policies: state.coin_policy.coin_policies,
        countries: state.country.countries,
        teams: state.team.teams,
        teamCurrentPage: state.team.currentPage,
        textTeam: state.team.text,
        teamRowCount: state.team.row_count,
        leagueCurrentPage: state.league.currentPage,
        textLeague: state.league.text,
        players: state.player.players,
        error: state.player.error,
        success: state.player.success,
        currentPage: state.player.currentPage,
        totalCount: state.player.totalCount,
        perPage: state.player.perPage,
        row_count: state.player.row_count,
        loading: state.player.loading,
        totalCountPerPage: state.player.totalCountPerPage,
        textCountry: state.country.text,
        countryCurrentPage: state.country.currentPage,
        teamId: state.player.teamId,
        leagueId: state.player.leagueId,
        text: state.player.text,
        position: state.player.position,

        teamName: state.team_league.teamName,
        teamList: state.team_league.team_leagues,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
        getCountries: (pageNumber, row_count) => dispatch(CountryAction.getCountries(pageNumber, row_count)),
        searchCountry: (text, pageNumber) => dispatch(CountryAction.searchCountry(text, pageNumber)),
        getAllTeams: (pageNumber, row_count) => dispatch(TeamAction.getAllTeams(pageNumber, row_count)),
        searchTeam: (text, countryId, pageNumber) => dispatch(TeamAction.searchItem(text, countryId, pageNumber)),
        searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
        getPlayers: (teamId, pageNumber, row_count) => dispatch(PlayerAction.getPlayers(teamId, pageNumber, row_count)),
        searchWithLeague: (leagueId, row_count, teamName, pageNumber) => dispatch(TeamLeagueAction.searchWithLeague(leagueId, teamName, row_count, pageNumber)),
        searchPlayer: (text, position, leagueId, teamId, row_count, pageNumber) => dispatch(PlayerAction.searchPlayer(text, position, leagueId, teamId, row_count, pageNumber)),
        createPlayer: (player) => dispatch(PlayerAction.createPlayer(player)),
        migratePlayer: (migrate_player) => dispatch(PlayerAction.migratePlayer(migrate_player)),
        updatePlayer: (player) => dispatch(PlayerAction.updatePlayer(player)),
        deletePlayer: (id) => dispatch(PlayerAction.deletePlayer(id)),

        getCoinPolicies: (pageNumber, row_count) => dispatch(CoinPolicyAction.getCoinPolicies(pageNumber, row_count)),
        updateCoinPolicy: (coin_policy) => dispatch(CoinPolicyAction.updateCoinPolicy(coin_policy)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerList)