import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
class FutureDetailsCreateController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      show: false,
      searchTerm: '',
      initialPage: 1,
      selectedValue: props.itemName,
      teamId: '',
      playerRowCounts: CONSTANTS.ROW_COUNT,
      teamRowCount: CONSTANTS.ROW_COUNT,
      text: '',
      position: '',
    }
  }


  componentDidMount = () => {
    // this.props.type === 1 && this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCounts)
    if (this.props.type === 1) {

      if (this.props.question == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး နောက်တန်းကစားသမား") {
        this.setState({
          position: 'defenders'
        }, () => {
          this.props.searchPlayer(this.state.text, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        })
      }
      else if (this.props.question == "ပြိုင်ပွဲတစ်လျှောက်အကောင်းဆုံး ရှေ့တန်းကစားသမား") {
        this.setState({
          position: 'attackers'
        }, () => {
          this.props.searchPlayer(this.state.text, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        })
      }
      else if (this.props.question == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး အလယ်တန်းကစားသမား") {
        this.setState({
          position: 'midfielders'
        }, () => {
          this.props.searchPlayer(this.state.text, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        })
      }
      else if (this.props.question == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး ဂိုးသမား (လက်ဗ်ယာရှင်ဆု)") {
        this.setState({
          position: 'goalkeepers'
        }, () => {
          this.props.searchPlayer(this.state.text, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        })
      }
      else {
        this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCounts)
      }
    }
    else if (this.props.type == 2) {
      this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.props.teamRowCount, this.state.initialPage)
    }
    else if (this.props.type == 3) {
      this.props.searchPlayer('', 'manager', this.props.leagueIdFromPlayer, '', this.state.playerRowCounts, this.state.initialPage)
    }
  }
  handleInputSearch = (e) => {
    this.setState({
      searchTerm: e.target.value
    })
  }
  handleSelectTeamId = (teamId) => {
    this.setState({
      teamId: teamId
    }, () => {
      if (this.props.type == 1) {
        this.props.searchPlayer(this.state.searchTerm, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        // this.state.searchTerm ?
        // this.props.searchPlayer(this.state.searchTerm, this.state.position, this.props.leagueIdFromPlayer, this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        // :
        // this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCounts)
      }

    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    if (this.props.type === 1) {
      // this.state.searchTerm ?
      this.props.searchPlayer(this.state.searchTerm, this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
      // :
      // this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCounts)
    }
    else if (this.props.type === 2) {
      this.props.searchWithLeagueAndTeamName(this.props.leagueId, this.state.searchTerm, this.state.teamRowCount, this.state.initialPage)
      // this.props.searchTeam(this.state.searchTerm, '', '', this.state.initialPage)
    }
    else if (this.props.type === 3) {
      this.props.searchPlayer(this.state.searchTerm, 'manager', this.props.leagueIdFromPlayer, '', this.state.playerRowCounts, this.state.initialPage)
    }

  }

  stopSearching = () => {
    this.setState({
      searchTerm: ''
    })
    this.props.type === 1 && this.props.searchPlayer('', this.state.position, '', this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
    // this.props.type === 1 && this.props.searchPlayer('', this.state.position, this.props.leagueIdFromPlayer, this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
    // this.props.type === 1 && this.props.getPlayers(this.state.teamId, this.state.initialPage, this.state.playerRowCounts)
    this.props.type === 2 && this.props.searchWithLeagueAndTeamName(this.props.leagueId, '', this.state.teamRowCount, this.state.initialPage)
    // this.props.type === 2 && this.props.searchTeam('', '', '', this.state.initialPage)
    this.props.type === 3 && this.props.searchPlayer('', 'manager', this.props.leagueIdFromPlayer, '', this.state.playerRowCounts, this.state.initialPage)
  }
  ShowModal = () => {
    this.setState({
      show: !this.props.disabled && true
    })

  }
}

export default FutureDetailsCreateController
