/* 
====================== USAGE
input text
<Input type="text" color="red" label="Name in English" />

input radio
<Input type="radio" label="hello" />

======================
*/

import styled from "styled-components";

export const Container = styled.div`
    display:${props => props.type === "text" && "flex"};    
    flex-direction:${props => props.type === "text" || props.type === "file" ? "column" : "row"};
    width:${props => props.width ? props.width : "100%"};
    color:${props => props.color ? props.color : "#14A2B8"};
    & input::placeholder{
        font-size: 16px;
    }
    & input:disabled {
    background-color: #fff;
    color: #202020;
    border:1px solid #fad230;
}

`;

export const Caption = styled.label`
    padding:${props => props.type === "text" || props.type === "file" ? "6px" : "0px"};
    color:${props => props.color ? props.color : "#202020"};
    

`
export const Element = styled.input.attrs(props => ({
    type: props.type,
}))`
    color:${props => props.color ? props.color : "#202020"};
    // border:1px solid #14A2B8;
    border:1px solid #FAD230;
    font-size:14px;
    height:42px;
    // min-height:42px;
    
    ${props => props.type === "text" ?
        `
        width:100%;
        padding-top:5px;
        padding-bottom:5px;
        border-radius: 5px;
        padding-left:10px;
        padding-right:10px;
        :disabled{
            background-color:white;
            color:#202020;
        }
        
        `
        :
        `
        width:100%;
        margin-top:12px;
        padding-top:5px;
        padding-bottom:5px;
        padding-left:10px;
        border-radius: 5px; 
        ::-webkit-calendar-picker-indicator {
            cursor: pointer;
        }
        
       
        `
    }
    
    ${props => props.type === "file" &&
        `
        width:100%;
        padding-top:2px;
        padding-bottom:2px;
        border-radius: 5px;
        padding-left:10px;
        color:#FAD230;
        `
    }
    
    ${props => props.type === "date" &&
        `
        width:100%;
        padding-top:2px;
        padding-bottom:2px;
        border-radius: 5px;
        padding-left:10px;
        padding-right:10px;
        `
    }
    
    ${props => props.type === "time" &&
        `
        width:100%;
        padding-top:2px;
        padding-bottom:2px;
        border-radius: 5px;
        padding-left:10px;
        padding-right:10px;
        `
    }
   
    
    
    
    
`;

export function Input({
    ...props
}) {
    return (
        <Container {...props}>
            {props.type === "text" ?
                <Caption {...props}>{props.label}</Caption>
                :
                <></>
            }

            <Element {...props} />

            {props.type !== "text" ?
                <Caption {...props}>{props.label}</Caption>
                :
                <></>
            }

        </Container>
    );
}