import React, { Component } from 'react'
import './PageLoader.css'
export class PageLoader extends Component {

    render() {
        return (
            <div className="loader">
                <div id="loadingText1" className="loadingText">L</div>
                <div id="loadingText2" className="loadingText">o</div>
                <div id="loadingText3" className="loadingText">a</div>
                <div id="loadingText4" className="loadingText">d</div>
                <div id="loadingText5" className="loadingText">i</div>
                <div id="loadingText6" className="loadingText">n</div>
                <div id="loadingText7" className="loadingText">g</div>
                <div id="loadingText7" className="loadingText">.</div>
                <div id="loadingText7" className="loadingText">.</div>
                <div id="loadingText7" className="loadingText">.</div>
            </div>
        )
    }
}

export default PageLoader;
