const SiteConfigsType = {
  GET_SITE_CONFIGS: "GET_SITE_CONFIGS",
  ADD_SITE_CONFIGS: "ADD_SITE_CONFIGS",
  UPDATE_SITE_CONFIGS: "UPDATE_SITE_CONFIGS",
  DELETE_SITE_CONFIGS: "DELETE_SITE_CONFIGS",
  SITE_CONFIGS_ERROR: "SITE_CONFIGS_ERROR",
  SITE_CONFIGS_SUCCESS: "SITE_CONFIGS_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  ROW_COUNT: "ROW_COUNT",
  LOADING: "LOADING",
  ERROR: "ERROR"
}
export default SiteConfigsType