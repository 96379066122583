import React from 'react'
import { MainLayoutController } from './MainLayoutController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleRight, faAngleDown, faBell, faShapes, faGripHorizontal,
    faUser, faChartLine, faTrophy,
    faSignOutAlt, faLock, faUsers, faGlobe, faCity, faHome, faFutbol, faPlayCircle, faUserLock, faMoneyCheckAlt, faWallet, faUserCog, faExchangeAlt, faBroadcastTower, faBahai, faExclamation, faList, faHeart, faQuestion, faQuestionCircle, faCoins, fasShield, faAward, faBullhorn, faWrench, faFlag
} from '@fortawesome/free-solid-svg-icons';

import { Nav } from './MainLayoutElement';

import { ReactComponent as StadiumIcon } from '../../images/stadium.svg'
import { ReactComponent as LeaderBoard } from '../../images/icons/leaderboard.svg'
import { ReactComponent as PrizeHistoryIcon } from '../../images/icons/prize_history.svg'
import { ReactComponent as PlayerIcon } from '../../images/player.svg'
import { ReactComponent as ShieldIcon } from '../../images/shield.svg'

import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
const navMenuList = [
    {
        icon: <FontAwesomeIcon icon={faShapes} />,
        name: 'Dashboard ',
        route: '/home'
    },
    {
        icon: <FontAwesomeIcon icon={faFutbol} />,
        name: 'Football ',
        child: [
            {
                icon: <FontAwesomeIcon icon={faTrophy} />,
                name: 'League ',
                route: '/league',
            },
            {
                icon: <ShieldIcon className="stadiumIcon" width="15px" height="15px" />,
                name: 'Team ',
                route: '/team'
            },
            {
                icon: <PlayerIcon className="stadiumIcon" width="15px" height="20px" />,
                name: 'Player ',
                route: '/player'
            },
            {
                icon: <StadiumIcon className="stadiumIcon" width="15px" height="15px" />,
                name: 'Stadium ',
                route: '/stadium'
            },
            {
                icon: <FontAwesomeIcon icon={faFutbol} />,
                name: 'Match ',
                route: '/match'
            },
            {
                icon: <FontAwesomeIcon icon={faBahai} />,
                name: 'Highlight ',
                route: '/highlight'
            },
            {
                icon: <FontAwesomeIcon icon={faPlayCircle} />,
                name: 'Live Streaming ',
                route: '/live_streaming'
            },
            {
                icon: <FontAwesomeIcon icon={faFutbol} />,
                name: 'Live Score ',
                route: '/livescore',
            },
            {
                icon: <LeaderBoard className="stadiumIcon" width="15px" height="15px" />,
                name: 'Leaderboard ',
                route: '/leaderboard'
            },
            {
                icon: <PrizeHistoryIcon className="stadiumIcon" width="15px" height="15px" />,
                name: 'Prize History ',
                route: '/prize_histories',
            },
            {
                icon: <FontAwesomeIcon icon={faQuestion} />,
                name: 'Quizzes ',
                route: '/quizzes'
            }
        ]
    },
    {
        icon: <FontAwesomeIcon icon={faExclamation} />,
        name: 'Rules  ',
        child: [
            {
                icon: <FontAwesomeIcon icon={faExclamation} />,
                name: 'Global Level Rules ',
                route: '/global_level_rules'
            },
            {
                icon: <FontAwesomeIcon icon={faExclamation} />,
                name: 'Favourite Team Level Rules ',
                route: '/favourite_team_level_rules'
            }
        ]
    },
    {
        icon: <FontAwesomeIcon icon={faUser} />,
        name: "User ",
        child: [
            {
                icon: <FontAwesomeIcon icon={faUser} />,
                name: 'User ',
                route: '/user'
            },
            {
                icon: <FontAwesomeIcon icon={faHeart}
                />,
                name: 'User Favourite Team ',
                route: '/user_favourite_teams'
            },
            {
                icon: <FontAwesomeIcon icon={faHeart}
                />,
                name: 'User Favourite National Team ',
                route: '/user_favourite_national_teams'
            },
            {
                icon: <FontAwesomeIcon icon={faList}
                />,
                name: 'User Team Activities ',
                route: '/user_team_activities'
            },
            {
                icon: <FontAwesomeIcon icon={faWallet} />,
                name: 'Wallet ',
                route: '/wallet',
            },
            {
                icon: <FontAwesomeIcon icon={faExchangeAlt} />,
                name: 'Transaction ',
                route: '/transactions'
            }
        ]
    },
    {
        icon: <FontAwesomeIcon icon={faGripHorizontal} />,
        name: 'Setup  ',
        child: [
            {
                icon: <FontAwesomeIcon icon={faBroadcastTower} />,
                name: 'Operator ',
                route: '/operator',
            },
            {
                icon: <FontAwesomeIcon icon={faUserCog} />,
                name: 'Role ',
                route: '/role',
            },
            {
                icon: <FontAwesomeIcon icon={faLock} />,
                name: "Permission",
                route: '/permission'
            },
            {
                icon: <FontAwesomeIcon icon={faUserLock} />,
                name: 'Role Permission ',
                route: '/role_permission',
            },
            {
                icon: <FontAwesomeIcon icon={faGlobe} />,
                name: 'Country ',
                route: '/country',
            },
            {
                icon: <FontAwesomeIcon icon={faCity} />,
                name: 'City ',
                route: '/city'
            },
            {
                icon: <FontAwesomeIcon icon={faHome} />,
                name: 'Township ',
                route: '/township'
            },
            {
                icon: <FontAwesomeIcon icon={faMoneyCheckAlt} />,
                name: 'Payment Type ',
                route: '/payment_type'
            },
            {
                icon: <FontAwesomeIcon icon={faQuestionCircle} />,
                name: 'Questions ',
                route: '/questions'
            },
            {
                icon: <FontAwesomeIcon icon={faQuestionCircle} />,
                name: 'Sponsor ',
                route: '/sponsor',
            },
            {
                icon: <FontAwesomeIcon icon={faCoins} />,
                name: 'Coin Policy ',
                route: '/coin_policy',
            },
            {
                icon: <FontAwesomeIcon icon={faAward}
                />,
                name: 'Prizes ',
                route: '/prizes',
            },
            {
                icon: <FontAwesomeIcon icon={faBullhorn} />,
                name: 'Announcement ',
                route: '/announcement',
            },
            {
                icon: <FontAwesomeIcon icon={faWrench} />,
                name: 'Site Configs ',
                route: '/site_configs'
            },
            {
                icon: <FontAwesomeIcon icon={faFlag} />,
                name: 'Version Check ',
                route: '/version_check'
            },
        ]
    },
    {
        icon: <FontAwesomeIcon icon={faBell} />,
        name: ' Notification ',
        route: '/notifications',
    },
    {
        icon: <FontAwesomeIcon icon={faList} />,
        name: ' Activity Logs ',
        route: '/activity'
    },
    {
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        name: " Logout ",
        route: '/logout'
    },
    {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        name: ' Report ',
        route: '/'
    }
]
class NavList extends MainLayoutController {
    render() {
        return (
            <Nav className="nav-list">
                {
                    navMenuList.map((menu, index) => {
                        return <MenuItem menu={menu} key={index} isOpenSlider={this.state.isOpenSlider} showMenuItem={this.props.showMenuItem} />
                    })
                }
            </Nav>
        )
    }
}


export default NavList