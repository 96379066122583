import React from 'react'
import { Button } from './../../../components/buttons/Button';
import { Input } from './../../../components/inputs/Input'
import { FavouriteTeamLevelRuleCreateController } from './FavouriteTeamLevelRuleCreateController';
class FavouriteTeamLevelRuleCreate extends FavouriteTeamLevelRuleCreateController {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Favourite Team Level Rule' : 'Add Favourite Team Level Rule'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            <form autoComplete="off">
              {
                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
              }
              <div style={{ columnCount: '2' }}>
                <Input type="text" name="level_name" value={this.state.favourite_team_level_rule.level_name} onChange={this.LevelName} placeholder="Enter Level Name..." required />

                <Input type="number" name="level_number" value={this.state.favourite_team_level_rule.level_number} onChange={this.LevelNumber} placeholder={this.props.favourite_team_level_rules.length > 0 ? "Enter Level Number...(must be greater than " + Number(this.props.favourite_team_level_rules[0].level_number) + ")" : "Enter Level Number..."} required 
                // disabled={this.props.isEdit && true} 
                />

                <Input type="number" name="from_min_count" min="0" value={this.state.favourite_team_level_rule.from_min_count} onChange={this.FromMinCount} placeholder={this.props.favourite_team_level_rules.length > 0 ? "Enter Minimun Count...(must be greater than " + Number(this.props.favourite_team_level_rules[0].to_max_count) + ")" : "Enter Minimun Count..."} required 
                // disabled={this.props.isEdit && true} 
                />

                <Input type="number" name="to_max_count" min="0" value={this.state.favourite_team_level_rule.to_max_count} onChange={this.ToMaxCount} placeholder="Enter Max Count..." />

                <Input type="text" name="reward_points" value={this.state.favourite_team_level_rule.reward_points} onChange={this.RewardPoints} placeholder="Enter Reward Points..."/>

                <Input type="text" name="discountable_points" value={this.state.favourite_team_level_rule.discountable_points} onChange={this.DiscountablePoint} placeholder="Enter Discountable Points..." />

                <select name="active" className="select" value={this.state.favourite_team_level_rule.active} onChange={this.handleActive} required style={{ marginBottom: "10px" }} >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
            
                <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.favourite_team_level_rule)} />
              </div>

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default FavouriteTeamLevelRuleCreate