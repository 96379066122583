
import CONSTANTS from './../constants';
import axios from 'axios'
import ActivityLogsType from './../type/activity_logs_type';

export const Error = (data) => {
    return {
        type: ActivityLogsType.ACTIVITY_LOGS_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: ActivityLogsType.ACTIVITY_LOGS_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: ActivityLogsType.LOADING, payload: status });
    }
}
const getAllActivityLogs = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}activity-logs/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: ActivityLogsType.GET_ALL_ACTIVITIES,
                    payload: response.data.data
                })
                dispatch({
                    type: ActivityLogsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: ActivityLogsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: ActivityLogsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: ActivityLogsType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: ActivityLogsType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: ActivityLogsType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)

            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const searchActivityLogs = (type, action, startDate, endDate, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}activity-logs/list?type=${type}&action=${action}&start_date=${startDate}&end_date=${endDate}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: ActivityLogsType.GET_ALL_ACTIVITIES, payload: response.data.data
                })
                dispatch({
                    type: ActivityLogsType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: ActivityLogsType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: ActivityLogsType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: ActivityLogsType.SEARCH_WITH_ACTION,
                    payload: action
                })
                dispatch({
                    type: ActivityLogsType.SEARCH_BY_TYPE,
                    payload: type
                })
                dispatch({
                    type: ActivityLogsType.SEARCH_BY_START_DATE,
                    payload: startDate
                })
                dispatch({
                    type: ActivityLogsType.SEARCH_BY_END_DATE,
                    payload: endDate
                })
            }

        })
    }
}

const ActivityLogsAction = {
    getAllActivityLogs,
    searchActivityLogs,
    loading
}
export default ActivityLogsAction