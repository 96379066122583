import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../redux/constants';
import moment from 'moment'

class HomePageController extends Component {
    constructor(props) {
        super(props)
        document.title = "G.O.A.T";

        let newDate = new Date()
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let firstDateOfCurrentMonth = 1;
        const endOfMonth = moment(newDate).endOf('month').format('DD');
        let fistTime = "00:00";
        let endTime = "23:59";
        let firstDate = `${year}-${month < 10 ? `0${month}` : `${month}`}-${firstDateOfCurrentMonth}`
        let lastDate = `${year}-${month < 10 ? `0${month}` : `${month}`}-${endOfMonth}`
        let oneHour = 1 * 60 * 60 * 1000
        let oneWeek = oneHour * 24 * 6;
        let onDay = 1 * 60 * 60 * 24 * 1000;
        let totalWeeks = Math.floor(endOfMonth / 7)

        // for current month
        let SD = [];
        let ED = [];
        let BarLabels = [];
        for (let i = 0; i < totalWeeks; i++) {
            let timestamp;
            let firstWeekStartDateTS;
            let firstWeekEndDateTS;
            let firstWeekStartDate;
            let firstWeekEndDate;
            timestamp = new Date(
                firstDate.split("-")[0],
                parseInt(firstDate.split("-")[1]) - 1,
                firstDate.split("-")[2],
                fistTime.split(":")[0],
                fistTime.split(":")[1]
            ).getTime();
            firstWeekStartDateTS = timestamp + (oneWeek * i + (onDay * (i)));

            firstWeekStartDate = new Date(firstWeekStartDateTS).toISOString();
            if (i === 3) {
                firstWeekEndDateTS = new Date(
                    lastDate.split("-")[0],
                    parseInt(lastDate.split("-")[1]) - 1,
                    lastDate.split("-")[2],
                    endTime.split(":")[0],
                    endTime.split(":")[1]
                ).getTime();
            }
            else {
                let endD = firstWeekStartDateTS + oneWeek
                let WeekDate = moment(endD).format('yyyy-MM-DD');
                firstWeekEndDateTS = new Date(
                    WeekDate.split("-")[0],
                    parseInt(WeekDate.split("-")[1]) - 1,
                    WeekDate.split("-")[2],
                    endTime.split(":")[0],
                    endTime.split(":")[1]
                ).getTime();
            }
            firstWeekEndDate = new Date(firstWeekEndDateTS).toISOString();
            SD.push(firstWeekStartDate)
            ED.push(firstWeekEndDate)
            let label = moment(firstWeekStartDate).format('DD MMM') + " ~ " + moment(firstWeekEndDate).format('DD MMM')
            BarLabels.push(label)
        }


        //  Previous Month 
        let prevMonth = newDate.getMonth() + 1 - 1;
        let PMfirstDate = `${year}-${prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`}-${firstDateOfCurrentMonth}`
        let PMlastDate = `${year}-${prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`}-${endOfMonth}`

        let PMSD = [];
        let PMED = [];
        for (let i = 0; i < totalWeeks; i++) {
            let timestamp;
            let firstWeekStartDateTS;
            let firstWeekEndDateTS;
            let firstWeekStartDate;
            let firstWeekEndDate;
            timestamp = new Date(
                PMfirstDate.split("-")[0],
                parseInt(PMfirstDate.split("-")[1]) - 1,
                PMfirstDate.split("-")[2],
                fistTime.split(":")[0],
                fistTime.split(":")[1]
            ).getTime();
            firstWeekStartDateTS = timestamp + (oneWeek * i + (onDay * (i)));
            firstWeekStartDate = new Date(firstWeekStartDateTS).toISOString();
            if (i === 3) {
                firstWeekEndDateTS = new Date(
                    PMlastDate.split("-")[0],
                    parseInt(PMlastDate.split("-")[1]) - 1,
                    PMlastDate.split("-")[2],
                    endTime.split(":")[0],
                    endTime.split(":")[1]
                ).getTime();
            }
            else {
                let endD = firstWeekStartDateTS + oneWeek
                let WeekDate = moment(endD).format('yyyy-MM-DD');
                firstWeekEndDateTS = new Date(
                    WeekDate.split("-")[0],
                    parseInt(WeekDate.split("-")[1]) - 1,
                    WeekDate.split("-")[2],
                    endTime.split(":")[0],
                    endTime.split(":")[1]
                ).getTime();
            }
            firstWeekEndDate = new Date(firstWeekEndDateTS).toISOString();
            PMSD.push(firstWeekStartDate)
            PMED.push(firstWeekEndDate)
        }

        this.state = {
            rowCount: CONSTANTS.ROW_COUNT,
            intitalPage: 1,
            topFanUsers: {},
            loading: true,
            initialPage: 1,
            userCount: '',
            teamCount: '',
            teamName: '',
            favCount: '',
            quizCount: '',
            playerCount: '',
            watchCountForCurrentMonth: '',
            watchCountForPrevMonth: '',
            type: "LIVE_STREAM",
            action: "PUBLIC_WATCH",
            BarLabels: BarLabels,
            PMStartDate: PMSD,
            PMEndDate: PMED,
            startDate: SD,
            endDate: ED,
        }
    }
    componentDidMount = () => {
        this.getUserFavTeams()
        this.getAllUsers()
        this.getAllPlayers()
        this.getQuizCount();
        this.getWatchCount();
        this.getPMWatchCount();
        this.getTopFanUsers();
        this.getAllTeams()
    }

    getTopFanUsers = async () => {
        await axios.post(`${CONSTANTS.BASE_URL}leaderboard/top_fans?sort_by=global_watch_count&order_by=DESC`).then((response) => {
            this.setState({
                topFanUsers: response.data.data,
                loading: false,
            })
        })
    }
    getAllUsers = async () => {

        await axios.post(`${CONSTANTS.BASE_URL}users/list?sort_by=global_watch_count&order_by=ASC`).then((response) => {
            this.setState({
                userCount: response.data?.pagination?.total_records_count
            })
        })
    }
    getAllPlayers = async () => {
        await axios.post(`${CONSTANTS.BASE_URL}players/list`).then((response) => {
            this.setState({
                playerCount: response.data?.pagination?.total_records_count
            })
        })
    }
    getAllTeams = async () => {
        await axios.post(`${CONSTANTS.BASE_URL}teams/list`).then((response) => {
            this.setState({
                teamCount: response.data?.pagination?.total_records_count
            })
        })
    }

    getQuizCount = async () => {
        let QC = [];
        for (let i = 0; i < this.state.startDate.length; i++) {
            await axios.post(`${CONSTANTS.BASE_URL}activity-logs/list?type=QUIZ&action=PLAY&start_date=${this.state.startDate[i]}&end_date=${this.state.endDate[i]}&page_at=${this.state.intitalPage}`).then((response) => {
                let count = response.data?.pagination?.total_records_count;
                QC.push(count)
            })
        }
        this.setState({
            quizCount: QC,
        })

    }
    getWatchCount = async () => {
        let WC = [];
        for (let i = 0; i < this.state.startDate.length; i++) {
            await axios.post(`${CONSTANTS.BASE_URL}activity-logs/list?type=LIVE_STREAM&action=PUBLIC_WATCH&start_date=${this.state.startDate[i]}&end_date=${this.state.endDate[i]}&page_at=${this.state.intitalPage}`).then((response) => {
                let count = response.data?.pagination?.total_records_count;
                WC.push(count)
            })
        }
        this.setState({
            watchCountForCurrentMonth: WC,
        })
    }
    getPMWatchCount = async () => {
        let PMWC = [];
        for (let i = 0; i < this.state.startDate.length; i++) {
            await axios.post(`${CONSTANTS.BASE_URL}activity-logs/list?type=LIVE_STREAM&action=PUBLIC_WATCH&start_date=${this.state.PMStartDate[i]}&end_date=${this.state.PMEndDate[i]}&page_at=${this.state.intitalPage}`).then((response) => {
                let pcount = response.data?.pagination?.total_records_count;
                PMWC.push(pcount)
            })
        }

        this.setState({
            watchCountForPrevMonth: PMWC,
        })
    }

    getUserFavTeams = async () => {
        await axios.post(`${CONSTANTS.BASE_URL}user-fav-team/topFavTeams`).then((response) => {
            let teams = [];
            let counts = [];
            let others;
            let res = response.data
            for (let i = 0; i < res.favTeams.length; i++) {
                teams.push(res.favTeams[i]?.teamName)
                counts.push(res.favTeams[i]?.favCount)
            }
            let sum = counts.reduce((cnt, value) => {
                return cnt + value;
            });
            others = res.totalCount - sum;
            this.setState({
                teamName: [...teams, 'Others'],
                favCount: [...counts, others]
            })

        })


    }

}

export default HomePageController