import React from 'react'
import { Button } from '../../../components/buttons/Button';
import SelectModal from '../../../components/selectModal/SelectModal';
import { Input } from '../../../components/inputs/Input'
import OddsCreateController from './OddsCreateController';
class OddsCreate extends OddsCreateController {
  render() {
    const styles = {
      match_name: { color: "#ffffff", alignItems: 'center', fontSize: '15px', fontWeight: 'normal' },
      form: { columnCount: '2', width: '100%', margin: 'auto' }
    };
    const type_for_match = [
      { id: 1, name: 'Home' },
      { id: 2, name: 'Draw' },
      { id: 3, name: 'Away' },
    ]
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Odds' : 'Add Odds'}</h3>
          <span style={styles.match_name} >
            {
              // this.props.quizInfo.match_name &&
              // <div>
              //   Match : &nbsp;
              //   <small>
              //     {match?.homeTeamName + " Vs " + match?.awayTeamName}
              //   </small>
              // </div>
            }
          </span>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">
            <form autoComplete="off">
              {
                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
              }
              <div style={styles.form}>
                <Input type="text" placeholder="Enter American Odds" name="odds_american" value={this.state.odds_rate.odds_american} onChange={this.handleInputChangeAmericanOdds} required />
                <Input type="text" placeholder="Enter Odds Fractional" name="odds_fractional" value={this.state.odds_rate.odds_fractional} onChange={this.handleInputChangeFrantional} required />

                <Input type="text" placeholder="Enter Odds Decimal" name="odds_decimal" value={this.state.odds_rate.odds_decimal} onChange={this.handleInputChangeDecimal} required />

                <SelectModal
                  type_for_odds={type_for_match}
                  // routeChangeName="match_quiz"
                  handleProcess={(typeId) => this.handleSelectMatchType(typeId)}
                  itemName={
                    this.props.isEdit ?
                      (
                        this.props.odds_rate.odds_rate.type_for_match == 1 && "Home" ||
                        this.props.odds_rate.odds_rate.type_for_match == 2 && "Draw" ||
                        this.props.odds_rate.odds_rate.type_for_match == 3 && "Away"
                      )
                      :
                      "*** Choose Match Type ***"}
                  disabled={this.props.isEdit ? true : false}
                />

                <select name="main_odds_type" className="select" value={this.state.odds_rate.main_odds_type} onChange={this.handleSelectMainOddsType} required>
                  <option value="1">Fractional</option>
                  <option value="2">Decimal</option>
                </select>
                <select name="active" className="select" value={this.state.odds_rate.active} onChange={this.handleSelect} required>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>

                <br />

              </div>
              <br />
              <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => {
                this.props.createOrUpdate(this.state.odds_rate)
              }
              } />

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default OddsCreate
