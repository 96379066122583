
import QuizDetailsType from './../type/quiz_details_type';
const initialState = {
    quiz_details_lists: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    matchId: '',
    quizId: '',
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}
const matchQuizReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case QuizDetailsType.GET_QUIZ_DETAILS_LISTS:
            return {
                ...state,
                loading: false,
                quiz_details_lists: actions.payload
            }
        case QuizDetailsType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case QuizDetailsType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case QuizDetailsType.SEARCH_WITH_MATCH:
            return {
                ...state,
                matchId: actions.payload
            }
        case QuizDetailsType.SEARCH_WITH_QUIZ_ID:
            return {
                ...state,
                quizId: actions.payload
            }
        case QuizDetailsType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case QuizDetailsType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case QuizDetailsType.ADD_QUIZ_DETAILS:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                quiz_details_lists: [
                    actions.payload,
                    ...state.quiz_details_lists,
                ]
            }
        case QuizDetailsType.UPDATE_QUIZ_DETAILS:
            const updateMatchQuizes = state.quiz_details_lists.filter(quiz_details => quiz_details.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                quiz_details_lists: [
                    actions.payload,
                    ...updateMatchQuizes,
                ]
            }
        case QuizDetailsType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case QuizDetailsType.DELETE_QUIZ_DETAILS:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                quiz_details_lists: state.quiz_details_lists.filter(match_quiz => match_quiz.id !== actions.payload),
            }
        case QuizDetailsType.QUIZ_DETAILS_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case QuizDetailsType.QUIZ_DETAILS_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default matchQuizReducer