import React from 'react'
import { RolePermissionCreateController } from './RolePermissionCreateController'
import { Input } from '../../../components/inputs/Input'
import { Button } from '../../../components/buttons/Button'
import SelectModal from './../../../components/selectModal/SelectModal';
class RolePermissionCreate extends RolePermissionCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? "Edit Role Permission" : "Add Role Permission"}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <Input type="text" name="description" placeholder="Enter Description...(Optional)" value={this.state.role_permission.description ? this.state.role_permission.description : ''} onChange={this.handleInputDescription} />

                            <SelectModal
                                routeChangeName="role"
                                items={this.props.roles}
                                searching={this.props.searchRole}
                                currentPage={this.props.currentPage}
                                getItems={this.props.getAllRoles}
                                rowCount={this.props.roleRowCount}
                                handleProcess={(role_id) => this.roleIdProcess(role_id)}
                                LoadMore={this.props.loadMore1}
                                itemName={this.props.isEdit ? this.props.role_permission.role_permission?.Role?.name : "*** Choose Role ***"}
                            />

                            <SelectModal
                                routeChangeName="permission"
                                items={this.props.permissions}
                                searching={this.props.searchPermission}
                                getItems={this.props.getAllPermissions}
                                rowCount={this.props.permissionRowCount}
                                handleProcess={(permission_id) => this.permissionIdProcess(permission_id)}
                                currentPage={this.props.currentPage}
                                LoadMore={this.props.loadMore2}
                                itemName={this.props.isEdit ? this.props.role_permission.role_permission?.Permission?.name : "*** Choose Permission ***"}
                            />
                            <select name="active" className="select" value={this.state.role_permission.active} onChange={this.handleActive} required >
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </select>
                            <br /><br />

                            <Button type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.role_permission)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export {
    RolePermissionCreate
}
