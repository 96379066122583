const CountryType = {
    GET_COUNTRIES: "GET_COUNTRIES",
    ADD_COUNTRY: "ADD_COUNTRY",
    UPDATE_COUNTRY: "UPDATE_COUNTRY",
    DELETE_COUNTRY: "DELETE_COUNTRY",
    COUNTRY_ERROR: "COUNTRY_ERROR",
    COUNTRY_SUCCESS: "COUNTRY_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    TOTAL_PAGE: "TOTAL_PAGE",
    GET_PER_PAGE: "GET_PER_PAGE",
    SEARCH_ITEM: "SEARCH_ITEM",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}

export default CountryType;