const ActivityLogsType = {
    GET_ALL_ACTIVITIES: "GET_ALL_ACTIVITIES",
    ACTIVITY_LOGS_ERROR: "ACTIVITY_LOGS_ERROR",
    ACTIVITY_LOGS_SUCCESS: "ACTIVITY_LOGS_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_ACTION: 'SEARCH_WITH_ACTION',
    SEARCH_BY_TYPE: "SEARCH_BY_TYPE",
    SEARCH_BY_START_DATE: "SEARCH_BY_START_DATE",
    SEARCH_BY_END_DATE: "SEARCH_BY_END_DATE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default ActivityLogsType