import React from 'react'
import MatchAction from '../../../redux/action/match_action.js'
import QuizCreate from '../create/SpecialQuizCreate';
import SpecialQuizAction from '../../../redux/action/special_quiz_action';
import LeagueAction from '../../../redux/action/league_action.js';
import SpecialQuizListController from './SpecialQuizListController';
import CountryAction from './../../../redux/action/country_action';

import { MainContainer, Header, Body, Button, Pagination, Modal, connect, Search, PageLoader, Link, moment, Alert } from '../../../components/ImportsFile'
class SpecialQuizList extends SpecialQuizListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Quizzes' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>

        <Header>
          <h4>Quizzes</h4>
          <form className="search match-quiz-search" onSubmit={this.handleSearch}>
            <div className="input-group">
              <Search
                items={this.props.leagues}
                searchItem={(league_id) => this.handleSelectLeague(league_id)}
                text={'Search with League'}
                search={this.state.leagueId ? true : false}
                currentPage={this.props.leagueCurrentPage}
                LoadMore={this.loadMoreLeagues}
                getItems={this.props.getSpecialQuizzes}
                rowCount={this.state.leagueRowCount}
                stopSearching={() => this.props.getSpecialQuizzes(this.state.date, this.state.leagueId, this.state.quiz_type, this.state.isCurrent, this.state.initialPage, this.state.rowCount)}
              />

              <select name="quiz_type" className="dropdown_select" value={this.state.quiz_type} onChange={this.handleSelectQuizType} required>
                <option value="0">All</option>
                <option value="1">Match</option>
                <option value="2">Season</option>
                <option value="3">Special Match</option>
                <option value="4">Future</option>
              </select>

              <select name="" className="dropdown_select" value={this.state.isCurrent} onChange={this.handleSelect} required>
                <option value="true">Current</option>
                <option value="false">History</option>
              </select>
              <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>

          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Quiz" className="btn-primary add-button" />
          }
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Country</th>
                <th>League</th>
                <th>Match</th>
                <th>Deadline</th>
                <th>Type</th>
                {
                  (updatePermission || deletePermission ) && 
                  <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.special_quizzes && this.props.special_quizzes.map((special_quiz, index) => {
                  let date = special_quiz?.deadline && new Date(parseInt(special_quiz.deadline))
                  let deadline = moment(date).format('ddd,D MMM, YYYY, h:mm A')
                  let ansLength;
                  let detailAnsLength;
                  const detailAns = [];
                  for (let i = 0; i < special_quiz?.SpecialQuizDetails?.length; i++) {
                    ansLength = special_quiz?.SpecialQuizDetails?.length
                    for (let j = 0; j < special_quiz?.SpecialQuizDetails[i]?.QuizAnswers?.length; j++) {
                      detailAns.push(special_quiz?.SpecialQuizDetails[i]?.QuizAnswers)
                      detailAnsLength = detailAns.length
                    }
                  }

                  return (
                    <tr key={index} style={{
                      // background: special_quiz?.SpecialQuizDetails?.length > 0 ? "#77E87A" : "#ffffff"
                      background: ansLength > 0 ? (ansLength == detailAnsLength ? "#77E87A" : "#C9C9C9") : "#ffffff"
                    }}>
                      <td>
                        {
                          special_quiz?.Country?.flag &&
                          <img src={special_quiz?.Country?.flag} alt="choosed images" />
                        }
                      </td>
                      <td>
                        {
                          special_quiz?.Country?.flag &&
                          <img src={special_quiz?.League?.logo_url} alt="choosed images" />
                        }
                      </td>

                      <td>{
                        special_quiz?.Match ? special_quiz?.Match?.HomeTeam?.name + " Vs " + special_quiz?.Match?.AwayTeam?.name
                          : "-"
                      }</td>
                      <td>
                        {
                          deadline
                        }
                      </td>
                      <td>
                        {
                          special_quiz.quiz_type === 1 && "Match"
                        }
                        {
                          special_quiz.quiz_type === 2 && "Season"
                        }
                        {
                          special_quiz.quiz_type === 3 && "Special Match"
                        }
                        {
                          special_quiz.quiz_type === 4 && "Future"
                        }
                      </td>
                      <td>

                        {/**
                          <Button icon="" btnFun={() => this.tempCalculate(quiz.id)} caption="Calculate" className="btn-success" />
                        */}

                        {
                          special_quiz.quiz_type === 3 ?
                            <Link to={`/odds/${special_quiz.id}`} className="quiz_detail">
                              <Button caption='Odds' className="btn-primary" icon="fa fa-calculator"
                              />
                            </Link>
                            :
                            <Link to={special_quiz.quiz_type === 4 ? `/quizzes/future_quiz/${special_quiz.id}` : `/quizzes/${special_quiz.id}`} className="quiz_detail">
                              <Button caption='Details' className="btn-primary" icon="fa fa-info-circle"
                              />
                            </Link>
                        }

                        <Modal deleteItem={() => this.props.deleteSpecialQuiz(special_quiz.id)} deletePermission={deletePermission} updatePermission={updatePermission}
                          editItem={() => this.handleEdit(special_quiz)}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>


          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {

            this.props.getSpecialQuizzes(this.props.date, this.props.leagueId, this.props.quizType, this.props.isCurrent, pageNumber, this.props.row_count);
          }} />

          {
            this.state.show &&
            <QuizCreate
              special_quiz={this.state.special_quiz}
              hideModal={this.hideModal}
              isEdit={this.state.isEdit}
              createOrUpdate={this.createOrUpdate}
              currentPage={this.props.currentPage}

              getAllLeagues={this.props.getAllLeagues}
              getAllMatches={this.props.getAllMatches}
              getAllCountries={this.props.getAllCountries}

              loadMoreLeagues={this.loadMoreLeagues}
              loadMoreMatches={this.loadMoreMatches}
              loadMoreCountries={this.loadMoreCountries}

              leagues={this.props.leagues}
              leagueCurrentPage={this.props.leagueCurrentPage}

              matches={this.props.matches}
              matchCurrentPage={this.props.matchCurrentPage}

              countries={this.props.countries}
              countryCurrentPage={this.props.countryCurrentPage}
              searchCountry={this.props.searchCountry}
              countryText={this.props.countryText}

              requiredError={this.state.requiredError}

            />
          }

        </Body>
      </MainContainer >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    special_quizzes: state.special_quiz.special_quizzes,
    totalCount: state.special_quiz.totalCount,
    error: state.special_quiz.error,
    currentPage: state.special_quiz.currentPage,
    row_count: state.special_quiz.row_count,
    success: state.special_quiz.success,
    leagueId: state.special_quiz.leagueId,
    quizType: state.special_quiz.quizType,
    date: state.special_quiz.date,
    isCurrent: state.special_quiz.isCurrent,
    totalCountPerPage: state.special_quiz.totalCountPerPage,
    loading: state.special_quiz.loading,

    matchRowCount: state.match.row_count,
    matches: state.match.matches,
    matchCurrentPage: state.match.currentPage,

    leagues: state.league.leagues,
    leagueRowCount: state.league.row_count,
    leagueCurrentPage: state.league.currentPage,

    countryRowCount: state.country.row_count,
    countries: state.country.countries,
    countryCurrentPage: state.country.currentPage,
    countryText: state.country.text,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialQuizzes: (date, leagueId, quizType, isCurrent, pageNumber, row_count) => dispatch(SpecialQuizAction.getSpecialQuizzes(date, leagueId, quizType, isCurrent, pageNumber, row_count)),
    createSpecialQuiz: (special_quiz) => dispatch(SpecialQuizAction.createSpecialQuiz(special_quiz)),
    updateSpecialQuiz: (special_quiz) => dispatch(SpecialQuizAction.updateSpecialQuiz(special_quiz)),
    deleteSpecialQuiz: (id) => dispatch(SpecialQuizAction.deleteSpecialQuiz(id)),

    getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
    getAllMatches: (pageNumber, row_count) => dispatch(MatchAction.getAllMatches(pageNumber, row_count)),
    getAllCountries: (pageNumber, row_count) => dispatch(CountryAction.getAllCountries(pageNumber, row_count)),
    searchCountry: (text, pageNumber) => dispatch(CountryAction.searchCountry(text, pageNumber)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialQuizList)