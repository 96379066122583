import React from 'react'
import FutureDetailsListController from './FutureDetailsListController';
import FutureDetailsCreate from './../create/FutureDetailsCreate';
import SpecialQuizDetailsAction from './../../../redux/action/special_quiz_detail_action';
import { Back, Alert, Pagination, Link, DeleteModal, withRouter, connect, Modal, PageLoader, Button, MainContainer, Header, Body } from '../../../components/ImportsFile'
import PlayerAction from './../../../redux/action/player_action';
import OddsRateAction from './../../../redux/action/odds_rate_action';
import TeamAction from './../../../redux/action/team_action';
import TeamLeagueAction from '../../../redux/action/team_league_action.js'
class FutureDetailsList extends FutureDetailsListController {
  render() {
    let updatePermission = false;
    let deletePermission = false;
    let createPermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Quizzes' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    let Caption;
    if (this.state.special_quiz_data.type === 1) {
      Caption = "Player"
    }
    else if (this.state.special_quiz_data.type === 2) {
      Caption = "Team"
    }
    else if (this.state.special_quiz_data.type === 3) {
      Caption = "Manager"
    }
    else if (this.state.special_quiz_data.type === 4) {
      Caption = "Match"
    }
    else {
      Caption = ""
    }
    return (

      <MainContainer>
        <Header>

          <h4>
            {
              this.state.quiz_type === 4 && <Link to={`/quizzes/${this.state.quiz_type === 4 ? "future_quiz" : "season_quiz"}/${this.state.quiz_id}`} style={{ textDecoration: 'none' }}>
                <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
              </Link>
            }
            {
              this.state.quiz_type === 2 && <Link to={`/quizzes/${this.state.quiz_id}`} style={{ textDecoration: 'none' }}>
                <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
              </Link>
            }
            {
              this.state.quiz_type === 4 ? "Future Details Lists" : "Season Details"
            }

          </h4>

          {/** Add Player or Add Team or Add Manager */}
          {
            createPermission &&
            <Button icon="" btnFun={this.addNewRow} caption={`Add ${Caption}`} className="btn-primary add-button" />
          }
        </Header>
        <Body>
          {
            // this.state.quizInfo.match_name &&
            <div className="quiz_info">
              <p> <b>League: </b> {this.state.special_quiz_data.SpecialQuiz?.League?.name}</p>
              <p> <b>Question: </b> {this.state.special_quiz_data.Question?.question}
              </p>
              <p> <b>Country: </b> {this.state.special_quiz_data.SpecialQuiz?.Country?.name}</p>
            </div>
          }
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          {
            this.state.requiredError && <Alert errorMessage={this.state.requiredError} style={{ width: '100%' }} />
          }
          <div className="tableScroll">
            <table>
              <thead className="fixed">
                <tr>
                  <th>Image</th>
                  <th>{Caption} Name</th>
                  {
                    this.state.quiz_type === 4 && <th>American Odds</th>
                  }
                  {
                    this.state.quiz_type === 4 && <th>Fractional</th>
                  }
                  {
                    this.state.quiz_type === 4 && <th>Decimal</th>
                  }
                  {
                    this.state.quiz_type === 4 && <th>Main Odds</th>
                  }
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                {
                  this.state.showCreateRow &&
                  <tr className="new_row_form">
                    <td></td>
                    <td>
                      {
                        this.state.selectedValue ? <p>{this.state.selectedValue} <span className="close" onClick={this.cancelItem}><i className="fas fa-edit"></i></span></p> :
                          <button className="choose_item" onClick={this.showModal}>Choose {Caption}</button>
                      }
                    </td>
                    {
                      this.state.quiz_type === 4 &&
                      <td>
                        <input type="text" name="odds_american" value={this.state.newItem.odds_american} onChange={this.OddsAmerican} />
                      </td>
                    }
                    {
                      this.state.quiz_type === 4 &&
                      <td>
                        <input type="text" name="odds_fractional" value={this.state.newItem.odds_fractional} onChange={this.OddsFractional} />
                      </td>
                    }
                    {
                      this.state.quiz_type === 4 &&
                      <td>
                        <input type="text" name="odds_decimal" value={this.state.newItem.odds_decimal} onChange={this.OddsDecimal} />
                      </td>
                    }
                    {
                      this.state.quiz_type === 4 &&
                      <td>
                        <select name="main_odds_type" value={this.state.newItem.main_odds_type} required onChange={this.MainOddsType} >
                          <option value="1">Fractional</option>
                          <option value="2">Decimal</option>
                        </select>
                      </td>
                    }
                    <td>
                      <select name="active" className="" value={this.state.newItem.active} onChange={this.handleSelectActive} >
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </select>
                    </td>
                    <td>
                      <Button type="button" caption="Save" className="btn-success" icon="fas fa-download" btnFun={() => this.saveItem(this.state.newItem)} />

                    </td>
                  </tr>
                }
                {
                  this.props.odds_rates?.map((item, index) => {
                    return (
                      <tr className="new_row_form" key={index}>
                        <td>
                          {item?.Player && <img src={item?.Player?.image_url} alt="choosed images" />}
                          {item?.Team && <img src={item?.Team?.logo_url} alt="choosed images" />}
                          {item?.Manager && <img src={item?.Manager?.image_url} alt="choosed images" />}
                        </td>
                        <td>
                          {
                            item.isEdit ?
                              <>
                                <p>

                                  {
                                    !this.state.remove ?
                                      <>
                                        {item?.Player && item?.Player?.name}
                                        {item?.Team && item?.Team?.name}
                                        {item?.Manager && item?.Manager?.name}
                                      </>
                                      :
                                      this.state.selectedValue
                                  }
                                </p>
                                {
                                  !this.state.remove &&
                                  <span className="close" onClick={this.cancelItem}>
                                    <i className="fas fa-edit"></i>
                                  </span>
                                }
                                {
                                  this.state.selectedValue && <span className="close" onClick={this.cancelItem}>
                                    <i className="fas fa-edit"></i>
                                  </span>
                                }
                                {
                                  (!this.state.selectedValue && this.state.remove) &&
                                  <button className="choose_item" onClick={this.showModal}>Choose {Caption}</button>
                                }
                              </>
                              :
                              <>
                                {item?.Player && item?.Player?.name}
                                {item?.Team && item?.Team?.name}
                                {item?.Manager && item?.Manager?.name}
                              </>
                          }
                        </td>
                        {this.state.quiz_type === 4 &&
                          <td>
                            {
                              item.isEdit ?
                                <input type="text" name="odds_american" value={this.state.newItem.odds_american} onChange={this.OddsAmerican} />
                                :
                                item.odds_american

                            }
                          </td>
                        }
                        {this.state.quiz_type === 4 &&
                          <td>
                            {
                              item.isEdit ?
                                <input type="text" name="odds_fractional" value={this.state.newItem.odds_fractional} onChange={this.OddsFractional} />
                                :
                                item.odds_fractional

                            }
                          </td>
                        }
                        {
                          this.state.quiz_type === 4 &&
                          <td>
                            {
                              item.isEdit ?
                                <input type="text" name="odds_decimal" value={this.state.newItem.odds_decimal} onChange={this.OddsDecimal} />
                                :
                                item.odds_decimal

                            }
                          </td>
                        }
                        {
                          this.state.quiz_type === 4 &&
                          <td>
                            {
                              item.isEdit ?

                                <select name="main_odds_type" value={this.state.newItem.main_odds_type} required onChange={this.MainOddsType} >
                                  <option value="1">Fractional</option>
                                  <option value="2">Decimal</option>
                                </select>
                                :
                                <>
                                  {item.main_odds_type === 1 ? "Fractional" : "Decimal"}
                                </>
                            }
                          </td>
                        }
                        <td>
                          {
                            item.isEdit ?
                              <select name="active" className="" value={this.state.newItem.active} onChange={this.handleSelectActive} >
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                              </select>
                              :
                              item.active === 1 ? "Active" : "Inactive"
                          }
                        </td>
                        <td>
                          {
                            item.isEdit ?
                              <>
                                <Button type="button" caption="Save" className="btn-success" icon="fas fa-download" btnFun={() => this.saveItem(this.state.newItem)} />
                                <DeleteModal deleteItem={() => this.props.deleteOddsRate(item.id)} />
                              </>
                              :
                              <Modal editItem={() => this.handleEdit(item)} deleteItem={() => this.props.deleteOddsRate(item.id)} updatePermission={updatePermission} deletePermission={deletePermission} />

                          }
                        </td>


                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>


          {/**
          
          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
            (!this.props.matchId) && this.props.getQuizzes(pageNumber, this.props.rowCount);
            (this.props.matchId) && this.props.searchQuiz(this.props.userId, this.props.matchId, pageNumber)
          }} />
        */}


          {
            this.state.show &&
            <FutureDetailsCreate
              show={this.state.show}
              hideModal={this.hideModal}
              players={this.props.players}
              playerCurrentPage={this.props.playerCurrentPage}
              playerRowCount={this.props.playerRowCount}
              playerTotalCount={this.props.playerTotalCount}
              playerTotalCountPerPage={this.props.playerTotalCountPerPage}
              loadMorePlayers={this.loadMorePlayers}
              handleSelect={(id, name) => this.handleSelect(id, name)}

              getPlayers={(leagueId, teamId, currentPage, pageNumber) => this.props.getPlayers(leagueId, teamId, currentPage, pageNumber)}
              leagueIdFromPlayer={this.state.leagueId}
              teams={this.props.teams}
              // teams={this.state.teams}
              teamTotalCount={this.props.teamTotalCount}
              teamTotalCountPerPage={this.props.teamTotalCountPerPage}
              teamRowCount={this.props.teamRowCount}
              teamCurrentPage={this.props.teamCurrentPage}
              searchTeam={(text, stadiumId, countryId, pageNumber) => this.props.searchTeam(text, stadiumId, countryId, pageNumber)}
              teamText={this.props.teamText}
              searchWithLeagueAndTeamName={(leagueId, teamName, rowCount, pageNumber) => this.props.searchWithLeagueAndTeamName(leagueId, teamName, rowCount, pageNumber)}
              // searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
              teamName={this.props.teamName}
              loadMoreTeams={this.loadMoreTeams}
              getTeams={this.props.getTeams}
              leagueId={this.props.leagueId}

              searchPlayer={this.props.searchPlayer}
              Caption={Caption}
              type={this.state.special_quiz_data.type}

              prevData={this.state.prevData}
              question={this.state.special_quiz_data?.Question?.question}

            />
          }

          <br />
          <br />
          <br />
        </Body>
      </MainContainer>


    )
  }
}

const mapStateToProps = (state) => {
  return {
    odds_rates: state.odds_rate.odds_rates,
    future_details: state.odds_rate.future_details,
    loading: state.odds_rate.loading,
    currentPage: state.odds_rate.currentPage,
    totalCount: state.odds_rate.totalCount,
    totalCountPerPage: state.odds_rate.totalCountPerPage,
    error: state.odds_rate.error,
    success: state.odds_rate.success,
    specialQuizDetailId: state.odds_rate.specialQuizDetailId,


    players: state.player.players,
    playerCurrentPage: state.player.currentPage,
    playerRowCount: state.player.row_count,
    playerTotalCount: state.player.totalCount,
    playerTotalCountPerPage: state.player.totalCountPerPage,
    teamId: state.player.teamId,
    leagueIdFromPlayer: state.player.leagueId,
    leagueId: state.team_league.leagueId,

    teams: state.team_league.team_leagues,
    teamName: state.team_league.teamName,
    teamlist: state.team_league.team_leagues,
    teamRowCount: state.team_league.row_count,
    teamCurrentPage: state.team_league.currentPage,
    teamTotalCount: state.team_league.totalCount,
    teamTotalCountPerPage: state.team_league.totalCountPerPage,

    teamText: state.team.text,
    stadiumId: state.team.stadiumId,
    countryId: state.team.countryId,


  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialQuizDetailsLists: (quizId, pageNumber, row_count) => dispatch(SpecialQuizDetailsAction.getSpecialQuizDetailsLists(quizId, pageNumber, row_count)),
    getFutureDetails: (specialQuizId, questionId, type, pageNumber, row_count) => dispatch(SpecialQuizDetailsAction.getFutureDetails(specialQuizId, questionId, type, pageNumber, row_count)),
    createSpecialQuizDetails: (quiz_details) => dispatch(SpecialQuizDetailsAction.createSpecialQuizDetails(quiz_details)),
    // createSpecialQuizDetailsWithOddsRate: (quiz_details) => dispatch(SpecialQuizDetailsAction.createSpecialQuizDetailsWithOddsRate(quiz_details)),
    updateSpeicalQuizDetails: (quiz_details) => dispatch(SpecialQuizDetailsAction.updateSpecialQuizDetails(quiz_details)),
    deleteSpecialQuizDetails: (id) => dispatch(SpecialQuizDetailsAction.deleteSpecialQuizDetails(id)),
    getOddsRates: (specialQuizDetailId, pageNumber, row_count) => dispatch(OddsRateAction.getOddsRates(specialQuizDetailId, pageNumber, row_count)),
    createOddsRate: (odds_rate) => dispatch(OddsRateAction.addOddsRate(odds_rate)),
    updateOddsRate: (odds_rate) => dispatch(OddsRateAction.updateOddsRate(odds_rate)),
    deleteOddsRate: (id) => dispatch(OddsRateAction.deleteOddsRate(id)),
    getPlayers: (leagueId, teamId, pageNumber, row_count) => dispatch(PlayerAction.getPlayers(leagueId, teamId, pageNumber, row_count)),
    searchPlayer: (text, position, leagueId, teamId, row_count, pageNumber) => dispatch(PlayerAction.searchPlayer(text, position, leagueId, teamId, row_count, pageNumber)),
    getTeams: (pageNumber, row_count) => dispatch(TeamAction.getTeams(pageNumber, row_count)),
    searchTeam: (teamText, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchTeam(teamText, stadiumId, countryId, pageNumber)),
    searchWithLeagueAndTeamName: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamLeagueAction.searchWithLeagueAndTeamName(leagueId, teamName, row_count, pageNumber)),
    toggleEdit: (item) => dispatch(OddsRateAction.toggleEdit(item))



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FutureDetailsList))
