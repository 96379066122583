import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';
class OperatorListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEdit: false,
            show: false,
            initialPage: 1,
            operator: {},
            text: '',
            requiredError: '',
            rowCount: CONSTANTS.ROW_COUNT,
        }

    }
    componentDidMount = () => {
        this.loadMore();
        this.props.getOperators(this.state.initialPage, this.state.rowCount);
    }

    stopSearching = () => {
        this.props.getOperators(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
        })
    }
    loadMore = () => {
        this.props.getAllRoles(this.state.initialPage, this.state.rowCount);
        this.setState({
            rowCount: this.state.rowCount + 6
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searchOperator(this.state.text, this.state.initialPage)
    }
    handleEdit = (operator) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            operator: {
                ...prevState.operator,
                operator
            }
        }))
    }
    createOrUpdate = (data) => {
        let loggedInOperator = JSON.parse(localStorage.getItem('operator'))
        let operator;
        if (this.state.isEdit) {
            operator = {
                id: data.id,
                full_name: data.full_name,
                user_name: data.user_name,
                email: data.email,
                phone_no: data.phone_no,
                password: data.password,
                role_id: data.role_id,
                active: data.active,
                updated_by: loggedInOperator.id,
            }
            if (operator.full_name === '' || operator.user_name === '' || operator.email === '' || operator.role_id === '' || operator.phone_no === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.updateOperator(operator).then(() => {
                    this.props.getOperators(this.state.initialPage, this.state.rowCount);
                })
                this.hideModal();
            }
        }
        else {
            operator = { ...data, created_by: loggedInOperator.id, updated_by: loggedInOperator.id }
            if (operator.full_name === '' || operator.user_name === '' || operator.email === '' || operator.password === '' || operator.role_id === '' || operator.phone_no === '') {
                this.setState({
                    requiredError: "All fields are required"
                })
            }
            else {
                this.props.createOperator(operator).then(() => {
                    this.props.getOperators(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }

    }

    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }

}

export {
    OperatorListController
}