const CityType = {
    GET_CITIES: "GET_CITIES",
    ADD_CITY: "ADD_CITY",
    UPDATE_CITY: "UPDATE_CITY",
    DELETE_CITY: "DELETE_CITY",
    CITY_ERROR: "CITY_ERROR",
    CITY_SUCCESS: "CITY_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_WITH_COUNTRY:"SEARCH_WITH_COUNTRY",
    LOADING: "LOADING",
    ERROR: "ERROR"

}
export default CityType;