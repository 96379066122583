import React, { Component } from 'react'
import fallBackPlayer from './../../../../images/icons/player1.png'
import fallBackTeam from '../../../../images/icons/team.png'
class PlayerDetail extends Component {

    render() {
        let singlePlayer = {}
        const homePlayers = this.props.lineups !== false && this.props.lineups?.lineup[0]?.players
        const awayPlayers = this.props.lineups !== false && this.props.lineups?.lineup[1]?.players
        const teamLogo = 'https://images.fotmob.com/image_resources/logo/teamlogo/'

        for (let i = 0; i < homePlayers.length; i++) {
            Object.values(homePlayers[i]).map((player) => {
                if (this.props.playerId == player.id) {
                    return singlePlayer = player
                }
            })
        }
        for (let i = 0; i < awayPlayers.length; i++) {
            Object.values(awayPlayers[i]).map((player) => {
                if (this.props.playerId == player.id) {
                    return singlePlayer = player
                }
            })
        }
        return (
            <div className="player_detail_modal-wrapper" >

                {
                    singlePlayer.imageUrl ? <div className="detail_modal_header">
                        <img src={singlePlayer.imageUrl} alt="" width="70px" onError={(e) => (e.currentTarget.src = fallBackPlayer)} />
                        <span className="team_logo rounded-pill badge">
                            <img src={`${teamLogo}${singlePlayer?.isHomeTeam === true ? this.props.lineups !== false && this.props.lineups?.lineup[0]?.teamId : this.props.lineups !== false && this.props.lineups?.lineup[1]?.teamId}.png`} alt="" width="25px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        </span>
                        <h3>{singlePlayer.name?.firstName} {singlePlayer.name?.lastName}</h3>

                        <span className="close-modal-btn" onClick={this.props.hidePlayerDetail}>x</span>
                    </div>
                        :
                        <div className="detail_modal_header">
                            <p style={{ color: "#fad230", marginBottom: "0px" }}>There is no data!</p>
                            <span className="close-modal-btn" onClick={this.props.hidePlayerDetail}>x</span>
                        </div>

                }

                <div className="modalContent" >
                    <div className="playerDetailModalBody">
                        <ul className="mainDetail">
                            <li>
                                <h6>Position</h6>
                                <p>{singlePlayer.positionStringShort || "-"}</p>
                            </li>
                            <li>
                                <h6>Team</h6>
                                <p>{(singlePlayer?.isHomeTeam && this.props.lineups !== false) ? this.props.lineups?.lineup[0]?.teamName : this.props.lineups?.lineup[1]?.teamName}</p>
                            </li>
                            <li>
                                <h6>Age</h6>
                                <p>-</p>
                            </li>
                        </ul>

                        <div className="player_detail">

                            {

                                singlePlayer?.stats ? singlePlayer?.stats.map((stat, index) => {
                                    return (
                                        <div className="top_stats" key={index}>
                                            <h6>{stat.title}</h6>

                                            {
                                                Object.entries(stat.stats).map((statName, v) => {
                                                    return (
                                                        <ul key={v}>
                                                            <li>
                                                                <p>{statName[0]}</p>
                                                                <p>{statName[1]}</p>
                                                            </li>

                                                        </ul>
                                                    )
                                                })
                                            }


                                        </div>
                                    )
                                })
                                    :
                                    <p style={{ color: "#fad230", marginTop: "50px" }} >No Data!</p>
                            }

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default PlayerDetail