import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';


class LiveStreamingCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            editNewForm: false,
            newLiveStream: {},
            updateIndex: 1,
            matches: [],
            matchId: [],
            allLivestream: [],
            livestream: props.isEdit === false ? {
                newURLs: [],
                headers: [],
                match_id: '',
                live_stream_url: '',
                channel_no: '',
                resolution: '1080p',
                active: 1

            } :
                {
                    ...props.livestream.livestream,
                }
        }

    }
    componentDidMount = () => {
        let matchId = [];
        axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?row_count=${this.props.totalCount}&sort_by=created_at&order_by=ASC`).then((res) => {
            let data = res.data.data
            data.map((livestream) => {
                matchId.push(livestream.match_id)
            })
            this.setState({
                matchId: matchId
            }, () => {
                this.getMatch();
            })
        })

        if (this.props.isEdit) {
            this.getAllLiveStreams();
            this.getLiveStreams();
        }
    }
    getMatch = () => {
        axios.post(`${CONSTANTS.BASE_URL}matches/list`).then((response) => {
            let newMatch = [];
            let matches = response.data.data;
            matches.map(match => {
                // check for not starting
                if (match.is_playing !== 2 && !this.state.matchId.includes(match.id)) {
                    newMatch.push(match)
                }
            })
            this.setState({
                matches: newMatch
            })
        })
    }
    getLiveStreams = () => {
        axios.post(`${CONSTANTS.BASE_URL}live-streamings/list?match_id=${this.state.livestream.match_id}&sort_by=created_at&order_by=ASC`).then((res) => {
            this.setState((prevState) => ({
                ...prevState,
                livestream: {
                    ...prevState.livestream,
                    newURLs: res.data.data,
                }
            }))
        })
    }
    getAllLiveStreams = () => {
        axios.post(`${CONSTANTS.BASE_URL}live-streamings/findByMatchId?match_id=${this.state.livestream.match_id}&sort_by=created_at&order_by=ASC`).then((res) => {
            this.setState((prevState) => ({
                ...prevState,
                allLivestream: res.data
            }))
        })
    }
    handleMatches = (match_id) => {
        this.setState((prevState) => ({
            ...prevState,
            livestream: {
                ...prevState.livestream,
                match_id: match_id
            }
        }))
    }

    resolutionProcess = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            livestream: {
                ...prevState.livestream,
                resolution: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            livestream: {
                ...prevState.livestream,
                active: e.target.value
            }
        }))
    }

    toggleForm = (visibility) => {
        this.setState((prevState) => ({
            ...prevState,
            showForm: visibility,
            editNewForm: false,
        }))
    }

    // create => add new url
    saveNewFields = (data) => {
        const URLs = [...this.state.livestream.newURLs];
        URLs.push(data)
        this.setState((prevState) => ({
            ...prevState,
            livestream: {
                ...prevState.livestream,
                newURLs: URLs,
            }
        }))
        this.toggleForm(false)
    }

    // create => edit
    updateNewFields = (data) => {
        const URLs = this.state.livestream.newURLs.map((link, idx) => {
            if (this.state.updateIndex === idx) {
                return {
                    ...link, live_stream_url: data.live_stream_url,
                    // channel_no:data.channel_no,
                    headers: data.headers
                }
            }
            else {
                return link;
            }
        });
        this.setState((prevState) => ({
            ...prevState,
            livestream: {
                ...prevState.livestream,
                newURLs: URLs,
            }
        }))
        this.toggleForm(false)

    }

    handleEdit = (index, data) => {
        this.toggleForm(true);
        this.setState((prevState) => ({
            ...prevState,
            newLiveStream: data,
            editNewForm: true,
            updateIndex: index,
        }))
    }

    deleteItem = (index, data) => {
        if (this.props.isEdit) {
            this.props.deleteLiveStream(data.id).then(() => {
                this.getLiveStreams();
            })
        }
        else {
            this.state.livestream.newURLs.map(((data, idx) => {
                if (index === idx) {
                    this.state.livestream.newURLs.splice(index, 1);
                    const URLs = [...this.state.livestream.newURLs];
                    this.setState((prevState) => ({
                        ...prevState,
                        livestream: {
                            ...prevState.livestream,
                            newURLs: URLs,
                        }
                    }))
                }

            }))
        }
    };

    // edit => add new url
    addNewLivestream = (data) => {

        let headers = {};
        let livestream = {
            match_id: this.state.livestream.match_id,
            active: this.state.livestream.active,
            resolution: this.state.livestream.resolution,
            live_stream_url: data.live_stream_url,
            // channel_no: data.channel_no
            channel_no: this.state.allLivestream.length + 1
        }
        if (data.headers) {
            data.headers.forEach((header) => {
                headers[header.headerKey] = header.headerValue
            })
            livestream = {
                ...livestream,
                headers: JSON.stringify(headers)
            }
        }
        this.props.createLiveStream(livestream).then(() => {
            this.getLiveStreams();
            this.getAllLiveStreams();
        })
        this.toggleForm();
    }

    // edit => update  url
    updateLiveStream = (data) => {
        let headers = {};
        let livestream = {
            id: data.id,
            match_id: data.match_id,
            live_stream_url: data.live_stream_url,
            resolution: data.resolution,
            channel_no: this.state.allLivestream.length + 1,
            active: data.active
        }
        data.headers.forEach((header) => {
            headers[header.headerKey] = header.headerValue
        })

        livestream = {
            ...livestream,
            headers: JSON.stringify(headers)
        }
        if (livestream.live_stream_url === '') {
            this.setState({
                requiredError: "LiveStream URL is Required"
            })
        }
        else {

            const URLs = this.state.livestream.newURLs.map((link, idx) => {
                if (this.state.updateIndex === idx) {
                    this.props.updateLiveStream(livestream)
                    return {
                        ...link,
                        live_stream_url: livestream.live_stream_url,
                        // channel_no: livestream.channel_no, 
                        headers: livestream.headers
                    }
                }
                else {
                    return link;
                }
            });
            this.setState((prevState) => ({
                ...prevState,
                livestream: {
                    ...prevState.livestream,
                    newURLs: URLs,
                }
            }))
            this.toggleForm(false)
        }
    }
}
export {
    LiveStreamingCreateController
}