import { Component } from 'react';
class MainLayoutController extends Component {
    constructor(props) {
        super(props);
        const auth = localStorage.getItem('operator')
        let loggedIn = true;
        if (!auth) {
            loggedIn = false
        }
        this.state = {
            show: true,
            isOpenSlider: true,
            loggedInUserModalOpen: false,
            showSetupMenu: false,
            showRuleMenuItem: false,
            showUserMenuItem: false,
            showMenuItem: true,
            showFootballMenuItem: false,
            operator: {},
            loggedIn,
            showNotiDot: true,
            currentYear: '',


            isOpen: false,
        };
    }
    componentDidMount = async () => {
        const LoggedInOperator = JSON.parse(localStorage.getItem('operator'));
        this.setState({
            operator: LoggedInOperator
        })
        let currentYear = new Date().getFullYear();
        this.setState({
            currentYear: currentYear
        })
    }
    goToNotiPage = () => {
        this.setState({
            showNotiDot: false,
        })
        localStorage.removeItem('notification')
        return this.props.navigate('/notifications')

    }
    logoutFromModal = () => {
        return this.props.navigate('/logout')
    }
    loggedInUserModal = () => {
        this.setState({
            loggedInUserModalOpen: true
        })
    }
    changePassword = () => {
        this.setState({
            loggedInUserModalOpen: false
        })
        return this.props.navigate('/change_password')
    }

    // showSetupMenu = () => this.setState((currentState) => ({
    //     showSetupMenu: !currentState.showSetupMenu
    // }))
    // showUserMenuItem = () => this.setState((currentState) => ({
    //     showUserMenuItem: !currentState.showUserMenuItem
    // }))
    // showRuleMenuItem = () => this.setState((currentState) => ({
    //     showRuleMenuItem: !currentState.showRuleMenuItem
    // }))
    // showFootballMenuItem = () => this.setState((currentState) => ({
    //     showFootballMenuItem: !currentState.showFootballMenuItem,
    // }))
    onNavBarTroggleClick = () => {
        this.setState({
            isOpenSlider: !this.state.isOpenSlider,
            showMenuItem: !this.state.showMenuItem,
        });
    }
    // openChildMenu = () => {
    //     this.setState({
    //         isOpen: !this.state.isOpen
    //     })
    // }
}

export {
    MainLayoutController
}