import React from 'react'
import OddsListController from './OddsListController';
import OddsCreate from './../create/OddsCreate';
import OddsRateAction from './../../../redux/action/odds_rate_action';
import { Back, Alert, Link, withRouter, connect, Modal, Pagination, PageLoader, Button, MainContainer, Header, Body } from '../../../components/ImportsFile'
class OddsList extends OddsListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Quizzes' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Quizzes' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>
            <Link to="/quizzes" style={{ textDecoration: 'none' }}>
              <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
            </Link>
            Odds
          </h4>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <Link to={`/quiz_detail/${this.state.quizInfo && this.state.quizInfo?.id}/answers/${this.state.specialQuizDetailId && this.state.specialQuizDetailId}`} className="quiz_detail" style={{ paddingRight: "20px" }}>
              <Button caption="Answers" className="btn-primary" icon="fa fa-info-circle"
              />
            </Link>
          {

          createPermission &&  <Button icon="" btnFun={this.showModal} caption="Add Odds" className="btn-primary add-button" />
          } 
          </div>

        </Header>
        <Body>
          {
            this.state.quizInfo &&
            <div className="quiz_info">

              <p> <b>League: </b> {this.state.quizInfo?.League?.name}</p>
              {
                (this.state.quizInfo?.Match?.HomeTeam || this.state.quizInfo?.Match?.HomeTeam) &&
                <p> <b>Match: </b>
                  {
                    this.state.quizInfo?.Match?.HomeTeam?.name + " Vs " + this.state.quizInfo?.Match?.AwayTeam?.name
                  }
                </p>
              }
              <p> <b>Country: </b> {this.state.quizInfo?.Country?.name}</p>
            </div>
          }
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Match Type</th>
                <th>American Odds</th>
                <th>Fractional</th>
                <th>Decimal</th>
                <th>Main</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.odds_rates.map((odds, index) => {
                  return (

                    <tr key={index}>
                      <td>
                        {odds.type_for_match === 1 && "Home"}
                        {odds.type_for_match === 2 && "Draw"}
                        {odds.type_for_match === 3 && "Away"}
                      </td>
                      <td>{odds.odds_american}</td>
                      <td>{odds.odds_fractional}</td>
                      <td>{odds.odds_decimal}</td>
                      <td>{odds.main_odds_type === 1 ? "Fractional" : "Decimal"}</td>
                      <td>{odds.active === 1 ? "Active" : "Inactive"}</td>
                      <td>

                        <Modal deleteItem={() => this.props.deleteOddsRate(odds.id)} updatePermission={updatePermission} deletePermission={deletePermission}
                          editItem={() => this.handleEdit(odds)}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>

          </table>


          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => {
            (!this.props.matchId) && this.props.getQuizzes(pageNumber, this.props.rowCount);
            (this.props.matchId) && this.props.searchQuiz(this.props.userId, this.props.matchId, pageNumber)
          }} />

          {
            this.state.show &&
            <OddsCreate
              hideModal={this.hideModal}
              isEdit={this.state.isEdit}
              odds_rate={this.state.odds_rate}
              createOrUpdate={this.createOrUpdate}
              requiredError={this.state.requiredError}
            // odds_rates={this.props.odds_rates}
            //   quiz_details={this.state.quiz_details}
            //   quizzes={this.props.quizzes}
            //   quizInfo={this.state.quizInfo}
            //   createOrUpdate={this.createOrUpdate}
            //   searchQuiz={this.props.searchQuiz}
            //   currentPage={this.props.currentPage}
            //   matchRowCount={this.props.matchRowCount}
            //   getAllMatches={this.props.getAllMatches}
            //   matches={this.props.matches}
            //   loadMore={this.loadMore}
            //   requiredError={this.state.requiredError}
            //   questions={this.props.questions}
            //   questionRowCount={this.props.questionRowCount}
            //   questionTotalCount={this.props.questionTotalCount}
            //   teams={this.props.teams}
            //   getTeams={(pageNumber, row_count) => this.props.getAllTeams(pageNumber, row_count)}
            //   teamRowCount={this.state.teamRowCount}
            //   teamCurrentPage={this.props.teamCurrentPage}
            //   searchTeam={this.props.searchTeam}
            //   getQuestions={this.props.getQuestions}

            />
          }

        </Body>
      </MainContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    odds_rates: state.odds_rate.odds_rates,
    error: state.odds_rate.error,
    success: state.odds_rate.success,
    currentPage: state.odds_rate.currentPage,
    totalCount: state.odds_rate.totalCount,
    perPage: state.odds_rate.perPage,
    specialQuizDetailId: state.odds_rate.specialQuizDetailId,
    // text: state.odds_rate.text,
    row_count: state.odds_rate.row_count,
    loading: state.odds_rate.loading,
    totalCountPerPage: state.odds_rate.totalCountPerPage
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOddsRates: (specialQuizDetailId, pageNumber, row_count) => dispatch(OddsRateAction.getOddsRates(specialQuizDetailId, pageNumber, row_count)),
    createOddsRate: (odds_rate) => dispatch(OddsRateAction.addOddsRate(odds_rate)),
    updateOddsRate: (odds_rate) => dispatch(OddsRateAction.updateOddsRate(odds_rate)),
    deleteOddsRate: (id) => dispatch(OddsRateAction.deleteOddsRate(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OddsList))
