import React from 'react'
import { MainContainer, Header, Body, InputBox, Footer } from './ChangePasswordElement'
import { Input } from '../../components/inputs/Input'
import { Button } from '../../components/buttons/Button'
import ChangePasswordController from './ChangePasswordController';
import { connect } from 'react-redux';
import OperatorAction from './../../redux/action/operator_action';


class ChangePassword extends ChangePasswordController {
    render() {

        return (
            <MainContainer>
                <Header>
                    <h4>Change Password</h4>
                </Header>
                <Body>

                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                        <Input type="text" name="email" placeholder="Enter Your Email" value={this.state.email} onChange={this.handleChangeEmail} autoComplete="off" />

                        <InputBox>
                            <Input type={this.state.showOldPassword ? "text" : "password"} name="old_password" placeholder="Enter Your Old Password" value={this.state.old_password} onChange={this.handleChangeOldPassword} autoComplete="off" />
                            <i className={this.state.showOldPassword ? "fa fa-eye icon" : "fa fa-eye-slash icon"} onClick={this.toggleOldPassword}></i>
                        </InputBox>
                        <InputBox>
                            <Input type={this.state.showNewPassword ? "text" : "password"} name="new_password" placeholder="Enter New Password" value={this.state.new_password} onChange={this.handleChangeNewPassword} autoComplete="off" className="password_input" />
                            <i className={this.state.showNewPassword ? "fa fa-eye icon" : "fa fa-eye-slash icon"} onClick={this.toggleNewPassword}></i>
                        </InputBox>

                        <InputBox>
                            <Input type={this.state.showConfirmPassword ? "text" : "password"} name="confirm_password" placeholder="Confirm Your Password" value={this.state.confirm_password} onChange={this.handleChangeConPassword} autoComplete="off" className="password_input" />
                            <i className={this.state.showConfirmPassword ? "fa fa-eye icon" : "fa fa-eye-slash icon"} onClick={this.toggleConfirmPassword}></i>
                        </InputBox>


                        <br />

                        <Button caption="Change" id="change-password" type="submit" />
                    </form>
                </Body>
                <Footer></Footer>
            </MainContainer>
        )
    }
}


const mapStateToProps = state => {
    return {
        operators: state.operator.operators,
        error: state.operator.error,
        success: state.operator.success,
        loading: state.operator.loading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changeOperatorPassword: (operator) => dispatch(OperatorAction.changeOperatorPassword(operator)),
        updateOperator: (operator) => dispatch(OperatorAction.updateOperator(operator)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
