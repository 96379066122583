import React from 'react'
import { Input } from '../../../components/inputs/Input'
import { Button } from '../../../components/buttons/Button'
import { PaymentTypeCreateController } from './PaymentTypeCreateController'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
class PaymentTypeCreate extends PaymentTypeCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Payment Type' : 'Add Payment Type'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <div>
                                <Input type="text" name="name" placeholder="Enter Payment Type..." value={this.state.payment_type.name} onChange={this.handleInputName} required />

                                <Input type="text" name="description" placeholder="Enter Payment Type Description...(Optional)" value={this.state.payment_type.description || ''} onChange={this.handleInputDescription} />

                                <select name="active" className="select" value={this.state.payment_type.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                                <div className="image-upload">
                                    <label htmlFor="file">
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#FAD230" />
                                        Choose Image
                                    </label>
                                    <input type="file" id="file" name="logo_url" onChange={this.onChangeImage} style={{ display: 'none' }} />
                                    {
                                        (typeof this.state.payment_type.logo_url === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.payment_type.logo_url} alt="" style={{ width: '80px', height: "80px" }} />
                                    }
                                    {
                                        (typeof this.state.payment_type.logo_url !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.payment_type.logo_url)} alt="" style={{ width: '80px', height: "80px" }} />
                                    }

                                </div>

                            </div>
                            <br />
                            <Button caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" id="submit-btn" type="button" btnFun={() => this.props.createOrUpdate(this.state.payment_type)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export {
    PaymentTypeCreate
}
