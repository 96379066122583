import { Component } from 'react'
class RolePermissionCreateController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            role_permission: props.isEdit === false ? {
                role_id: "",
                permission_id: '',
                description: '',
                active: 1
            } : props.role_permission.role_permission
        }
    }
    roleIdProcess = (role_id) => {
        this.setState((prevState) => ({
            ...prevState,
            role_permission: {
                ...prevState.role_permission,
                role_id: role_id
            }
        }))
    }
    permissionIdProcess = (permission_id) => {
        this.setState((prevState) => ({
            ...prevState,
            role_permission: {
                ...prevState.role_permission,
                permission_id: permission_id
            }
        }))
    }
    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            role_permission: {
                ...prevState.role_permission,
                description: e.target.value
            }
        }))
    }
    handleActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            role_permission: {
                ...prevState.role_permission,
                active: e.target.value
            }
        }))
    }
}
export {
    RolePermissionCreateController
}