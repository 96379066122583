import CONSTANTS from './../constants';
import axios from 'axios'
import UserFavouriteNationalTeamType from './../type/user_favourite_national_team_type';

export const Error = (data) => {
  return {
    type: UserFavouriteNationalTeamType.USER_FAVOURITE_NATIONAL_TEAM_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: UserFavouriteNationalTeamType.USER_FAVOURITE_NATIONAL_TEAM_SUCCESS, payload: data
  }
}
export const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: UserFavouriteNationalTeamType.LOADING, payload: status })
  }
}
const getUserFavouriteNationalTeams = (pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}user-fav-national-team/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: UserFavouriteNationalTeamType.GET_USER_FAVOURITE_NATIONAL_TEAMS, payload: response.data.data
        })
        dispatch({
          type: UserFavouriteNationalTeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: UserFavouriteNationalTeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: UserFavouriteNationalTeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
        })
        dispatch({
          type: UserFavouriteNationalTeamType.ROW_COUNT, payload: row_count
        })
      }
      else {
        let message = "Failed"
        dispatch({
          type: UserFavouriteNationalTeamType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: UserFavouriteNationalTeamType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const createUserFavouriteNationalTeam = (user_favourite_national_team) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.post(`${CONSTANTS.BASE_URL}user-fav-national-team/create`, user_favourite_national_team).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: UserFavouriteNationalTeamType.ADD_USER_FAVOURITE_NATIONAL_TEAM, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const updateUserFavouriteNationalTeam = (user_favourite_national_team) => {
  return (dispatch) => {
    dispatch(loading(true))

    return axios.put(`${CONSTANTS.BASE_URL}user-fav-national-team/update`, user_favourite_national_team).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: UserFavouriteNationalTeamType.UPDATE_USER_FAVOURITE_NATIONAL_TEAM, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
      else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const deleteUserFavouriteNationalTeam = (id) => {
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}user-fav-national-team/delete`, { data: { id: id } }).then((response) => {
      if (response.data.status === "Success") {
        dispatch({
          type: UserFavouriteNationalTeamType.DELETE_USER_FAVOURITE_NATIONAL_TEAM, payload: id
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "Fail to delete"
        dispatch({
          type: UserFavouriteNationalTeamType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: UserFavouriteNationalTeamType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch((err) => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const searchWithUserOrTeam = (userId, teamId, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}user-fav-national-team/list?user_id=${userId}&national_team_id=${teamId}&page_at=${pageNumber}`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: UserFavouriteNationalTeamType.GET_USER_FAVOURITE_NATIONAL_TEAMS, payload: response.data.data
        })
        dispatch({
          type: UserFavouriteNationalTeamType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
        })
        dispatch({
          type: UserFavouriteNationalTeamType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: UserFavouriteNationalTeamType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: UserFavouriteNationalTeamType.SEARCH_WITH_TEAM,
          payload: teamId
        })
        dispatch({
          type: UserFavouriteNationalTeamType.SEARCH_WITH_USER,
          payload: userId
        })
      }
    })
  }
}

const UserFavouriteNationalTeamAction = {
  getUserFavouriteNationalTeams,
  createUserFavouriteNationalTeam,
  updateUserFavouriteNationalTeam,
  deleteUserFavouriteNationalTeam,
  searchWithUserOrTeam,
}
export default UserFavouriteNationalTeamAction