
import GlobalLevelRuleType from './../type/global_level_rule_type';
const initialState = {
    global_level_rules: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    totalCountPerPage: null,
    row_count: 1,
    text: '',
    loading: false,
}
const globalLevelRuleReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case GlobalLevelRuleType.GET_GLOBAL_LEVEL_RULES:
            return {
                ...state,
                loading: false,
                global_level_rules: actions.payload
            }
        case GlobalLevelRuleType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case GlobalLevelRuleType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case GlobalLevelRuleType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case GlobalLevelRuleType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case GlobalLevelRuleType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case GlobalLevelRuleType.ADD_GLOBAL_LEVEL_RULE:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                global_level_rules: [
                    actions.payload,
                    ...state.global_level_rules,
                ]

            }
        case GlobalLevelRuleType.UPDATE_GLOBAL_LEVEL_RULE:
            const updateGlobalRule = state.global_level_rules.filter(rule => rule.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                global_level_rules: [
                    actions.payload,
                    ...updateGlobalRule,
                ]
            }
        case GlobalLevelRuleType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case GlobalLevelRuleType.DELETE_GLOBAL_LEVEL_RULE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                global_level_rules: state.global_level_rules.filter(rule => rule.id !== actions.payload)
            }
        case GlobalLevelRuleType.GLOBAL_LEVEL_RULE_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case GlobalLevelRuleType.GLOBAL_LEVEL_RULE_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default globalLevelRuleReducer