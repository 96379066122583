
import UserType from './../type/user_type';
const initialState = {
    users: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    loading: false,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    active: 1,
}
const userReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case UserType.GET_USERS:
            return {
                ...state,
                loading: false,
                users: actions.payload
            }
        case UserType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case UserType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case UserType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case UserType.SEARCH_WITH_ACTIVE:
            return {
                ...state,
                active: actions.payload
            }
        case UserType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case UserType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case UserType.ADD_USER:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                users: [
                    actions.payload,
                    ...state.users,
                ]
            }
        case UserType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case UserType.UPDATE_USER:
            const updateUsers = state.users.filter(user => user.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                users: [
                    actions.payload,
                    ...updateUsers,
                ]
            }
        case UserType.DELETE_USER:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                users: state.users.filter(user => user.id !== actions.payload)
            }
        case UserType.USER_ERROR:
            return { ...state, loading: false, error: actions.payload }
        case UserType.USER_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}
export default userReducer