
import CONSTANTS from './../constants';
import axios from 'axios'
import UserQuizAnswerType from './../type/user_quiz_answer_type';

export const Error = (data) => {
    return {
        type: UserQuizAnswerType.USER_QUIZ_ANSWER_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: UserQuizAnswerType.USER_QUIZ_ANSWER_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: UserQuizAnswerType.LOADING, payload: status });
    }
}
const getUserQuizAnswers = (quizDetailId, pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}user-quiz-submit/list?special_quiz_detail_id=${quizDetailId}&page_at=${pageNumber}&row_count=${row_count}&sort_by=created_at&order_by=DESC`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: UserQuizAnswerType.GET_USER_ANSWERS,
                    payload: response.data.data
                })
                dispatch({
                    type: UserQuizAnswerType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: UserQuizAnswerType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: UserQuizAnswerType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: UserQuizAnswerType.ROW_COUNT, payload: row_count
                })
                dispatch({
                    type: UserQuizAnswerType.SERCH_WITH_QUIZ_DETAIL_ID, payload: quizDetailId
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: UserQuizAnswerType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: UserQuizAnswerType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}


const UserQuizAnswerAction = {
    getUserQuizAnswers,
    loading
}
export default UserQuizAnswerAction