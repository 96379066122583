import React from 'react'
import QuestionAction from './../../../redux/action/question_action';
import QuestionlistController from './QuestionlistController';
import QuestionCreate from './../create/QuestionCreate';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, Search, connect, withRouter } from '../../../components/ImportsFile'
class QuestionList extends QuestionlistController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Question' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Question' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Question' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        let question_types = [
            { id: 1, type: "Match" },
            { id: 2, type: "Season" },
            { id: 3, type: "Special Match" },
            { id: 4, type: "Future" },
        ]
        return (
            <MainContainer>
                <Header>
                    <h4>Questions</h4>
                    <form className="search match-quiz-search">
                        <div className="input-group">

                            <Search
                                questions={question_types}
                                searchItem={(quiz_type) => this.handleSelectType(quiz_type)}
                                text={'Search with Quiz Type'}
                                search={this.state.quiz_type ? true : false}
                                searchQuestion={this.props.getQuestions}
                                currentPage={this.props.currentPage}
                                LoadMore={this.loadMore2}
                                getAllQuestions={this.props.getQuestions}
                                rowCount={this.state.rowCount}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button type="submit" onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button icon="" btnFun={this.showModal} caption="Add Question" className="btn-primary add-button" />
                    }
                </Header>
                <Body>

                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Quiz Type</th>
                                <th>Question</th>
                                <th>Reward</th>
                                <th>Redeem Points</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.questions.map((question, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {
                                                    question.quiz_type === 1 && "Match"
                                                }
                                                {
                                                    question.quiz_type === 2 && "Season"
                                                }
                                                {
                                                    question.quiz_type === 3 && "Special Match"
                                                }
                                                {
                                                    question.quiz_type === 4 && "Future"
                                                }
                                            </td>
                                            <td>
                                                {
                                                    // question.question.length > 50 ? question.question.substring(0, 50) + "..." : question.question
                                                    question.question
                                                }
                                            </td>
                                            <td>{question.win_reward}</td>
                                            <td>{question.redeem_points}</td>
                                            <td>{question.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteQuestion(question.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => { this.handleEdit(question) }} />
                                            </td>
                                        </tr>
                                    )
                                })

                            }
                        </tbody>
                    </table>


                    <Pagination
                        activePage={this.props.currentPage}
                        totalItemsCount={this.props.totalCount}
                        totalCountPerPage={this.props.totalCountPerPage}
                        paginate={(pageNumber) => this.props.getQuestions(this.props.type, pageNumber, this.props.row_count)}
                    />

                    {
                        this.state.show &&
                        <QuestionCreate
                            question={this.state.question}
                            questions={this.props.questions}
                            type={this.props.type}
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            createOrUpdate={this.createOrUpdate}
                            currentPage={this.props.currentPage}
                            requiredError={this.state.requiredError}
                            questionRowCount={this.props.questionRowCount}
                            questionTotalCount={this.props.questionTotalCount}
                        />
                    }

                </Body>
            </MainContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        questions: state.question.questions,
        currentPage: state.question.currentPage,
        totalCount: state.question.totalCount,
        error: state.question.error,
        success: state.question.success,
        totalCountPerPage: state.question.totalCountPerPage,
        row_count: state.question.row_count,
        type: state.question.type,
        loading: state.question.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getQuestions: (type, pageNumber, row_count) => dispatch(QuestionAction.getQuestions(type, pageNumber, row_count)),
        createQuestion: (question) => dispatch(QuestionAction.createQuestion(question)),
        updateQuestion: (question) => dispatch(QuestionAction.updateQuestion(question)),
        deleteQuestion: (id) => dispatch(QuestionAction.deleteQuestion(id)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionList))