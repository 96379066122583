import React, { Component } from 'react'
import Goal from '../../../../images/icons/goaled.png'
import yellowCard from '../../../../images/icons/YCard.png'
import Substitution from '../../../../images/icons/substitution.png'
import OwnGoal from '../../../../images/icons/ownGoal.png'
import RedCard from '../../../../images/icons/redCard.png'
import YellowRedCard from '../../../../images/icons/yellowRedCard.png'
import Var from '../../../../images/icons/v.png'
class MatchFacts extends Component {
    render() {
        const events = this.props.matchFacts?.events?.events
        return (
            <div className="match_facts">
                {
                    events && Object.values(events).map((event, index) => {
                        return (

                            <div className="facts" key={index}>
                                <div className="left">
                                    {
                                        event?.isHome && <span>

                                            {
                                                event?.type === "VAR" &&
                                                <span>
                                                    <img src={Var} alt="" width="30px" />&nbsp;
                                                    {event?.VAR?.decision}
                                                </span>
                                            }
                                            {
                                                (event?.type === "Goal" && event?.ownGoal === null) &&
                                                <span>
                                                    {event?.nameStr} &nbsp;
                                                    ({event?.newScore[0]} - {event?.newScore[1]}) &nbsp;
                                                    <img src={Goal} alt="" width="20px" />
                                                        <span><p style={{ paddingRight: '27px', fontSize: '12px', color: '#777', marginBottom: '0px' }}>{event?.assistStr}</p></span>
                                                </span>
                                            }
                                            {
                                                (event?.type === "Goal" && event?.ownGoal === true) &&
                                                <span>
                                                    {event?.nameStr} &nbsp;
                                                    ({event?.newScore[0]} - {event?.newScore[1]}) &nbsp;
                                                    <img src={OwnGoal} alt="" width="25px" />
                                                        <span><p style={{ paddingRight: '27px', fontSize: '12px', color: '#777', marginBottom: '0px' }}>{event?.goalDescription}</p></span>

                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'Yellow') &&
                                                <span>
                                                    {event?.nameStr}&nbsp;
                                                    <img src={yellowCard} alt="" width="20px" />
                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'YellowRed') &&
                                                <span>
                                                    {event?.nameStr}&nbsp;
                                                    <img src={YellowRedCard} alt="" width="20px" />
                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'Red') &&
                                                <span>
                                                    {event?.nameStr}&nbsp;
                                                    <img src={RedCard} alt="" width="20px" />
                                                </span>
                                            }
                                            {
                                                event?.type === 'AddedTime' && <p>{event?.minutesAddedStr}</p>
                                            }
                                            {
                                                event?.type === 'Substitution' &&
                                                <span className="substitution">
                                                    <span className="name">
                                                        <p>{event?.swap[0]?.name}</p>
                                                        <p>{event?.swap[1]?.name}</p>
                                                    </span>

                                                    <img src={Substitution} alt="" />
                                                </span>
                                            }

                                        </span>
                                    }

                                </div>

                                <div className="center" >
                                    {
                                        (event?.type !== "AddedTime" && event?.type !== "Half") &&
                                        <p>
                                            {event?.time}
                                        </p>
                                    }
                                    {
                                        event?.type === "AddedTime" &&
                                        <p style={{
                                            width: '300px',
                                            justifyContent: 'start',
                                            backgroundColor: '#fff',
                                            padding: '0px',
                                            textAlgin: 'start',
                                            marginLeft: '-110px',
                                            float: 'left'
                                        }} >
                                            {event?.minutesAddedStr}
                                        </p>
                                    }
                                    {
                                        event?.type === "Half" &&
                                        <p style={{
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                            ailgnItems: 'center',
                                            marginBottom: '0px',
                                            padding: '8px 0px',
                                            textAlgin: 'center',
                                            backgroundColor: '#202020',
                                            color: '#fad230',
                                            margin: 'auto',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            border: '2px solid #fad230',
                                        }} >
                                            {event?.halfStrShort}
                                        </p>
                                    }
                                </div>
                                <div className="right">

                                    {
                                        event?.isHome === false &&
                                        <span>
                                            {
                                                event?.type === "VAR" &&
                                                <span>
                                                    <img src={Var} alt="" width="30px" />&nbsp;
                                                    {event?.VAR?.decision}
                                                </span>
                                            }
                                            {
                                                (event?.type === "Goal" && event?.ownGoal === null) &&
                                                <span>
                                                    <img src={Goal} alt="" width="20px" /> &nbsp;
                                                    ({event?.newScore[0]} - {event?.newScore[1]}) &nbsp;
                                                    {event?.nameStr} &nbsp;
                                                    <span><p style={{ paddingLeft: '30px', fontSize: '12px', color: '#777',marginBottom:'0px' }}>{event?.assistStr}</p></span>
                                                </span>
                                            }
                                            {
                                                (event?.type === "Goal" && event?.ownGoal === true) &&
                                                <span>
                                                    <img src={OwnGoal} alt="" width="25px" />&nbsp;
                                                    ({event?.newScore[0]} - {event?.newScore[1]}) &nbsp;
                                                    {event?.nameStr} &nbsp;
                                                            <span><p style={{ paddingLeft: '30px', fontSize: '12px', color: '#777', marginBottom: '0px' }}>{event?.goalDescription}</p></span>
                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'Yellow') &&
                                                <span>
                                                    <img src={yellowCard} alt="" width="20px" />&nbsp;
                                                    {event?.nameStr}
                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'YellowRed') &&
                                                <span>
                                                    <img src={YellowRedCard} alt="" width="30px" />&nbsp;
                                                    {event?.nameStr}
                                                </span>
                                            }
                                            {
                                                (event?.type === "Card" && event?.card === 'Red') &&
                                                <span>
                                                    <img src={RedCard} alt="" width="30px" height="30px" />&nbsp;
                                                    {event?.nameStr}
                                                </span>
                                            }
                                            {
                                                event?.type === 'Substitution' &&
                                                <span className="substitution">
                                                    <img src={Substitution} alt="" />
                                                    <span className="name">
                                                        <p>{event?.swap[0]?.name}</p>
                                                        <p>{event?.swap[1]?.name}</p>
                                                    </span>

                                                </span>
                                            }
                                        </span>
                                    }

                                </div>
                            </div>
                        )
                    })
                }
               <br/>
               <br/>
            
            </div>
        )
    }
}

export default MatchFacts