
import VersionCheckType from './../type/version_check_type';
const initialState = {
  version_checks: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
  text: '',
}

const versionCheckReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case VersionCheckType.GET_VERSION_CHECKS:
      return {
        ...state,
        loading: false,
        version_checks: actions.payload
      }
    case VersionCheckType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case VersionCheckType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case VersionCheckType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case VersionCheckType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case VersionCheckType.ADD_VERSION_CHECK:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        version_checks: [
          actions.payload,
          ...state.version_checks,
        ]
      }
    case VersionCheckType.UPDATE_VERSION_CHECK:
      const updateVersionCheck = state.version_checks.filter(item => item.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        version_checks: [
          actions.payload,
          ...updateVersionCheck,
        ]
      }
    case VersionCheckType.DELETE_VERSION_CHECK:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        version_checks: state.version_checks.filter(version_check => version_check.id !== actions.payload)
      }
    case VersionCheckType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case VersionCheckType.SEARCH_ITEM:
      return {
        ...state,
        text: actions.payload,
      }
    case VersionCheckType.VERSION_CHECK_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case VersionCheckType.VERSION_CHECK_SUCCESS:
      return { ...state, success: actions.payload, loading: false }

    default: return state
  }
}

export default versionCheckReducer;