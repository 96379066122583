import { Component } from 'react'
import CONSTANTS from '../../../redux/constants';
class TopFanListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialPage: 1,
      loading: true,
      text: '',
      rowCount: CONSTANTS.ROW_COUNT,
    }
  }
  componentDidMount = () => {
    this.props.getTopFanUsers(this.state.initialPage, this.state.rowCount)
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchUser(this.state.text, this.state.initialPage)
  }
  handleInputText = (e) => {
    this.setState({
      text: e.target.value
    })
  }
  stopSearching = () => {
    this.setState({
      text: ''
    })
    this.props.getTopFanUsers(this.state.initialPage, this.state.rowCount)

  }
}

export default TopFanListController