import { Component } from 'react'
import CONSTANTS from '../../../redux/constants';
import axios from 'axios'
class FavTeamListController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searching: false,
            initialPage: 1,
            usersForFavTeamList: [],
            usersForFavTeam: [],
            levelName: '',
            requiredError: '',
            loading: true,
            teamId: '',
            rowCount: CONSTANTS.ROW_COUNT,
            teamRowCount: CONSTANTS.ROW_COUNT,
            initialPageForTopFan: 0,
            initialPageForFavTeam: 0,
        }
    }

    componentDidMount = () => {
        this.loadMore();
        this.getFavTeamUsers()
    }
    getFavTeamUsers = async (teamId) => {
        await axios.post(`${CONSTANTS.BASE_URL}leaderboard/fav_team_users?page_at=${this.state.initialPage}&row_count=${this.state.rowCount}`, { team_id: teamId && teamId }).then((response) => {
            if (teamId) {
                this.setState({
                    usersForFavTeam: response.data,
                    loading: false,
                })
            }
            else {
                this.setState({
                    usersForFavTeamList: response.data,
                    loading: false,
                })
            }
        })

    }
    handleSearch = (e) => {
        e.preventDefault();

    }
    stopSearchingWithTeam = () => {
        this.setState({
            searching: false,
            teamId: ''
        })
        this.getFavTeamUsers();
    }

    handleSelectTeamId = (teamId) => {
        this.getFavTeamUsers(teamId)
        this.setState({
            teamId,
            searching: true,
        })
    }

    loadMore = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount);
    }
}

export default FavTeamListController