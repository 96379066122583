import React from 'react'
import SelectModal from './../../../components/selectModal/SelectModal';
import { Button } from './../../../components/buttons/Button';
import TeamLeagueCreateController from './TeamLeagueCreateController';
class TeamLeagueCreate extends TeamLeagueCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? "Edit Team-League" : "Add Team-League"}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>


                            <SelectModal
                                items={this.props.teams}
                                handleProcess={(team_id) => this.teamIdProcess(team_id)}
                                LoadMore={this.props.loadMore1}
                                itemName={this.props.isEdit ? this.props.team_league.team_league.Team.name : "*** Choose Team ***"}
                                routeChangeName="team"
                                searchTeam={this.props.searchTeam}
                                currentPage={this.props.teamCurrentPage}
                                getItems={this.props.getAllTeams}
                                rowCount={this.props.teamRowCount}

                            />

                            <SelectModal
                                items={this.props.leagues}
                                handleProcess={(league_id) => this.leagueIdProcess(league_id)}
                                LoadMore={this.props.loadMore2}
                                searchLeague={this.props.searchLeague}
                                currentPage={this.props.leagueCurrentPage}
                                getItems={this.props.getAllLeagues}
                                rowCount={this.props.leagueRowCount}
                                itemName={this.props.isEdit ? this.props.team_league.team_league.League.name : "*** Choose League ***"}
                            />

                            <select name="active" className="select" value={this.state.team_league.active} onChange={this.handleActive} required >
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </select>
                            <br /><br />

                            <Button type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.team_league)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamLeagueCreate