import axios from "axios"
import CONSTANTS from './../constants';
import PrizeType from './../type/prize_type';

export const Error = (data) => {
  return {
    type: PrizeType.PRIZE_ERROR, payload: data
  }
}
export const Success = (data) => {
  return {
    type: PrizeType.PRIZE_SUCCESS, payload: data
  }
}

const loading = (status) => {
  return (dispatch) => {
    return dispatch({ type: PrizeType.LOADING, payload: status })
  }
}
const getPrizes = (pageNumber, row_count) => {
  return (dispatch) => {

    return axios.post(`${CONSTANTS.BASE_URL}prizes/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=exchangeable_points&order_by=DESC`).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: PrizeType.GET_PRIZES, payload: response.data.data
        })
        dispatch({
          type: PrizeType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PrizeType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: PrizeType.TOTAL_PAGE, payload: response.data.pagination.total_page
        })
        dispatch({
          type: PrizeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PrizeType.ROW_COUNT,
          payload: row_count
        })
      } else {
        let message = "custom error message"
        dispatch({
          type: PrizeType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PrizeType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}
const getAllPrizes = (pageNumber, row_count) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}prizes/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=name&order_by=ASC`).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: PrizeType.GET_PRIZES, payload: response.data.data
        })
        dispatch({
          type: PrizeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PrizeType.ROW_COUNT,
          payload: row_count
        })
      } else {
        let message = "custom error message"
        dispatch({
          type: PrizeType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PrizeType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const createPrize = (prize) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.post(`${CONSTANTS.BASE_URL}prizes/create`, prize).then(response => {
      if (response.data.status === 'Success' || response.data.message === 'Success') {
        dispatch({
          type: PrizeType.ADD_PRIZE,
          payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }

}
const updatePrize = (prize) => {
  return (dispatch) => {
    dispatch(loading(true))
    return axios.put(`${CONSTANTS.BASE_URL}prizes/update`, prize).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: PrizeType.UPDATE_PRIZE, payload: response.data.data
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "All fields are required"
        const errorPayload = {};
        errorPayload['message'] = message;
        dispatch(Error(errorPayload))
        setTimeout(() => {
          const errorPayload = null
          dispatch(Error(errorPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)

    })
  }
}
const deletePrize = (id) => {
  let operator = JSON.parse(localStorage.getItem('operator'));
  return (dispatch) => {
    return axios.delete(`${CONSTANTS.BASE_URL}prizes/delete`, { data: { id: id, deleted_by: operator.id } }).then(response => {
      if (response.data.status === 'Success') {
        dispatch({
          type: PrizeType.DELETE_PRIZE, payload: id,
        })
        const successPayload = {};
        successPayload['message'] = response.data.message;
        dispatch(Success(successPayload))
        setTimeout(() => {
          const successPayload = null
          dispatch(Success(successPayload))
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      } else {
        let message = "custom error message"
        dispatch({
          type: PrizeType.ERROR, payload: message
        })
        setTimeout(() => {
          const message = null
          dispatch({
            type: PrizeType.ERROR,
            payload: message
          })
        }, CONSTANTS.ALERT_DISPLAY_TIME)
      }
    }).catch(err => {
      const errorPayload = {};
      if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
        errorPayload['message'] = err.message;
      } else {
        errorPayload['message'] = err?.response?.data?.message
      }
      dispatch(Error(errorPayload))
      setTimeout(() => {
        const errorPayload = null
        dispatch(Error(errorPayload))
      }, CONSTANTS.ALERT_DISPLAY_TIME)
    })
  }
}

const searchItem = (text, leagueId, expired_time, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}prizes/list?keyword=${text}&league_id=${leagueId}&expired_time=${expired_time}&page_at=${pageNumber}&sort_by=exchangeable_points&order_by=DESC`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: PrizeType.GET_PRIZES, payload: response.data.data
        })
        dispatch({
          type: PrizeType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
        })
        dispatch({
          type: PrizeType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
        })
        dispatch({
          type: PrizeType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
        })
        dispatch({
          type: PrizeType.SEARCH_ITEM,
          payload: text
        })
        dispatch({
          type: PrizeType.SEARCH_WITH_LEAGUE_ID,
          payload: leagueId
        })
        dispatch({
          type: PrizeType.SEARCH_WITH_EXPIRED_TIME,
          payload: expired_time
        })
      }
    })
  }
}
const searchPrize = (text, pageNumber) => {
  return (dispatch) => {
    return axios.post(`${CONSTANTS.BASE_URL}prizes/list?keyword=${text}&page_at=${pageNumber}`).then(response => {
      if (response.data.status === "Success") {
        dispatch({
          type: PrizeType.GET_PRIZES, payload: response.data.data
        })
      }
      return dispatch({
        type: PrizeType.SEARCH_ITEM,
        payload: text
      })
    })
  }
}

const PrizeAction = {
  getPrizes,
  getAllPrizes,
  createPrize,
  updatePrize,
  deletePrize,
  searchItem,
  searchPrize,
  loading,
}

export default PrizeAction;