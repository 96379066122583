import React, { Component } from 'react'
import Player from '../../../../images/icons/player.png'
class Bench extends Component {
    render() {
        const homeBench = this.props.lineups !== false && this.props.lineups?.bench?.benchArr[0]
        const awayBench = this.props.lineups !== false && this.props.lineups?.bench?.benchArr[1]
        const homeTeamCoach = this.props.lineups?.coaches?.coachesArr !== null && this.props.lineups?.coaches?.coachesArr[0][0]
        const awayTeamCoach = this.props.lineups?.coaches?.coachesArr !== null && this.props.lineups?.coaches?.coachesArr[1][0]
        return (

            this.props.lineups !== false ? <div className="benchContainer">
                {
                    this.props.lineups?.coaches?.coachesArr !== null &&
                    <div className="coach">

                        <div className="left">
                            <img src={homeTeamCoach?.imageUrl} alt="" onError={(e) => (e.currentTarget.src = Player)} />
                            <span>{homeTeamCoach?.name?.firstName + " " + homeTeamCoach?.name?.lastName}</span>
                        </div>
                        <div className="center">Coach</div>
                        <div className="right">
                            <span>{awayTeamCoach?.name?.firstName + " " + awayTeamCoach?.name?.lastName}</span>
                            <img src={awayTeamCoach?.imageUrl} alt="" onError={(e) => (e.currentTarget.src = Player)} />
                        </div>
                    </div>
                }
                <hr />
                <div className="bench">
                    <h3>Bench</h3>

                    <div className="bench_player">
                        <div className="left">
                            {
                                homeBench && Object.values(homeBench).map((bench, index) => {
                                    return (
                                        <div className="bench_list" key={index}>
                                            <div>
                                                <img src={bench?.imageUrl} alt="" style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} onError={(e) => (e.currentTarget.src = Player)} />
                                                <span className="bench_shirt">{bench?.shirt}</span>
                                                <span>{bench?.name?.firstName + " " + bench?.name?.lastName}</span>
                                                <span className="bench_rating" style={{ backgroundColor: bench?.rating?.num > 7.5 && 'green' }}>{bench?.rating && bench?.rating?.num}</span>
                                            </div>
                                            <small>
                                                {
                                                    bench?.timeSubbedOn &&
                                                    <span>

                                                        {bench?.timeSubbedOn + "'"}
                                                        <span className="bench_arrow">&#10145;</span>
                                                    </span>
                                                }
                                            </small>
                                        </div>

                                    )
                                })
                            }
                        </div>
                        <div className="right">
                            {
                                awayBench && Object.values(awayBench).map((bench, index) => {
                                    return (
                                        <div className="bench_list" key={index}>
                                            <div>
                                                <img src={bench?.imageUrl} alt="" style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} onError={(e) => (e.currentTarget.src = Player)} />
                                                <span className="bench_shirt">{bench?.shirt}</span>
                                                <span>{bench?.name?.firstName + " " + bench?.name?.lastName}</span>
                                                <span className="bench_rating" style={{ backgroundColor: bench?.rating?.num > 7.5 && 'green' }}>{bench?.rating && bench?.rating?.num}</span>
                                            </div>
                                            <small>
                                                {
                                                    bench?.timeSubbedOn &&
                                                    <span>

                                                        {bench?.timeSubbedOn + "'"}
                                                        <span className="bench_arrow">&#10145;</span>
                                                    </span>
                                                }
                                            </small>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
                :
                <div className="" style={{ height: '400px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                    <p>There is no data.</p>
                </div>



        )
    }
}

export default Bench