
import RoleType from './../type/role_type';
const initialState = {
    roles: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    row_count: 1,
    totalCountPerPage: null,
    loading: false,
}

const roleReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case RoleType.GET_ROLES:
            return {
                ...state,
                loading: false,
                roles: actions.payload
            }
        case RoleType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case RoleType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case RoleType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case RoleType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case RoleType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case RoleType.ADD_ROLE:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // roles: state.roles.concat(actions.payload)
                roles: [
                    actions.payload,
                    ...state.roles,
                ]
            }
        case RoleType.UPDATE_ROLE:
            const updateRoles = state.roles.filter(role => role.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                roles: [
                    actions.payload,
                    ...updateRoles,
                ]
            }
        case RoleType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case RoleType.DELETE_ROLE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                roles: state.roles.filter(role => role.id !== actions.payload)
            }
        case RoleType.ROLE_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case RoleType.ROLE_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default roleReducer