import React, { Component } from 'react'
import fallBackLeague from '../../../../images/icons/fallBackLeague.png'
import fallBackTeam from '../../../../images/icons/team.png'
class H2H extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        const leagueLogo = "https://images.fotmob.com/image_resources/logo/leaguelogo/"
        const teamLogo = "https://images.fotmob.com/image_resources/logo/teamlogo/"
        return (
            <div className="h2h">
                {
                    this.props.h2h?.summary &&
                    <h5>Head-to-Head</h5>
                }
                {
                    this.props.h2h?.summary ?
                        <div className="h2h_header">
                            <img src={this.props.teams[0]?.imageUrl} alt="" width="60px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />

                            <div className="info">
                                <p style={{ backgroundColor: (this.props.h2h?.summary[0] > this.props.h2h?.summary[2]) ? "green" : "red" }}>
                                    {this.props.h2h?.summary[0]}
                                </p>
                                <span>Wins</span>
                            </div>
                            <div className="info">
                                <p className="draw">
                                    {this.props.h2h?.summary[1]}
                                </p>
                                <span>Draws</span>
                            </div>
                            <div className="info">
                                <p style={{ backgroundColor: (this.props.h2h?.summary[0] > this.props.h2h?.summary[2]) ? "red" : "green" }}>
                                    {this.props.h2h?.summary[2]}
                                </p>
                                <span>Wins</span>
                            </div>

                            <img src={this.props.teams[1]?.imageUrl} alt="" width="60px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                        </div>
                        :
                        <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>There is no data!</span>
                }
                <div className="h2h_lists">
                    {
                        this.props.h2h && this.props.h2h?.matches.map((data, index) => {
                            return (
                                <div className="h2h_item" key={index}>
                                    <div className="league" >

                                        <img src={`${leagueLogo}${data.league?.id}.png`} alt="" style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} onError={(e) => (e.currentTarget.src = fallBackLeague)} />

                                        <span>{data.league.name.length > 25 ? data.league.name.substring(0, 25) + '...' : data.league.name}</span>
                                    </div>
                                    <div className="home">
                                        <span>{data.home.name.length > 15 ? data.home.name.substring(0, 15) + '...' : data.home.name}</span>
                                        <img src={`${teamLogo}${data.home.id}.png`} alt="" style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                    </div>
                                    <div className="score">
                                        {data.status.scoreStr}
                                    </div>
                                    <div className="away">
                                        <img src={`${teamLogo}${data.away.id}.png`} alt="" style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }} onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                        <span>{data.away.name.length > 15 ? data.away.name.substring(0, 15) + '...' : data.away.name}</span>
                                    </div>
                                    <div className="date">
                                        {data.time}
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
                <br />
                <br />
            </div>
        )
    }
}

export default H2H