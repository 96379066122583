import SiteConfigsType from './../type/site_configs_type';
const initialState = {
  site_configs: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
}

const siteConfigsReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SiteConfigsType.GET_SITE_CONFIGS:
      return {
        ...state,
        loading: false,
        site_configs: actions.payload
      }
    case SiteConfigsType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case SiteConfigsType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case SiteConfigsType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case SiteConfigsType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case SiteConfigsType.ADD_SITE_CONFIGS:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        site_configs: [
          actions.payload,
          ...state.site_configs,
        ]
      }
    case SiteConfigsType.UPDATE_SITE_CONFIGS:
      const updateSiteConfigs = state.site_configs.filter(item => item.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        site_configs: [
          actions.payload,
          ...updateSiteConfigs,
        ]
      }
    case SiteConfigsType.DELETE_SITE_CONFIGS:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        site_configs: state.site_configs.filter(config => config.id !== actions.payload)
      }
    case SiteConfigsType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case SiteConfigsType.SITE_CONFIGS_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case SiteConfigsType.SITE_CONFIGS_SUCCESS:
      return { ...state, success: actions.payload, loading: false }

    default: return state
  }
}

export default siteConfigsReducer;