import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap';
import { BrowserRouter } from "react-router-dom";

// import reportWebVitals from './reportWebVitals';
import store from './redux/store/store';

import { Provider } from 'react-redux';
// import { createBrowserHistory } from 'history'

// export const history = createBrowserHistory({ forceRefresh: true })


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
  //document.body
);

