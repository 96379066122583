import React from 'react';
import "./MainLayoutCss.css";
import Modal from 'react-modal'
import { MainLayoutController } from './MainLayoutController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import WalletAction from './../../redux/action/wallet_action';
import { faAlignJustify, faBell } from '@fortawesome/free-solid-svg-icons';
import {
    shadowbox,
    MainContainer, MainHeader, MainHeaderLeft, MainHeaderRight, MainBody, MainBodyLeft, MainHeaderEnd,
    MainBodyRight,
    MainFooter, MainFooterLeft, MainFooterRight
}
    from './MainLayoutElement';
import { withRouter } from './../../components/routers/withRouter'

import Logo from '../../images/YellowGoat.png'
import User from '../login/image/user.png';
import NavList from './NavList';
// =============================== VARIABLE ==================================



// =============================== END VARIABLE ==============================

class MainLayout extends MainLayoutController {


    render() {
        return (
            <MainContainer>
                <MainHeader>
                    <MainHeaderLeft isOpenNavBar={this.state.isOpenSlider}>
                        <div className="admin_dashboard">
                            {
                                this.state.showMenuItem === false ?
                                    <img src={Logo} alt="" width="27px" />
                                    : <h5>Admin Dashboard</h5>
                            }
                        </div>
                        <FontAwesomeIcon icon={faAlignJustify} color="#FAD230" onClick={this.onNavBarTroggleClick} style={{ cursor: 'pointer' }} />

                    </MainHeaderLeft>
                    <MainHeaderRight isOpenNavBar={this.state.isOpenSlider}>

                        <FontAwesomeIcon icon={faAlignJustify} id="rightToggle" color="#FAD230" style={{ cursor: 'pointer' }} onClick={this.onNavBarTroggleClick} />

                    </MainHeaderRight>
                    <MainHeaderEnd>

                        <div className="notification" onClick={this.goToNotiPage} >
                            <FontAwesomeIcon icon={faBell} id="" color="#FAD230" style={{ fontSize: '22px' }} />
                            {
                                ((this.props.notification && this.state.showNotiDot) || localStorage.getItem('notification')) && <div className="counter"></div>
                            }
                        </div>

                        <img src={User} alt="user_profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} onClick={this.loggedInUserModal} />

                        {/** LoggedInUserModal */}
                        <Modal className="loggedInUserModal" ariaHideApp={false}
                            isOpen={this.state.loggedInUserModalOpen}
                            onRequestClose={() => this.setState({ loggedInUserModalOpen: false })}
                            shouldCloseOnOverlayClick={true}
                            style={
                                {
                                    overlay: {
                                        background: 'transparent'
                                    }
                                }
                            }
                        >
                            <span onClick={() => this.setState({ loggedInUserModalOpen: false })}>x</span>
                            <img src={User} alt="Operator_Profile" />
                            {
                                this.state.loggedIn ? <h4>{this.state.operator.user_name}</h4> : <h4>Admin</h4>
                            }
                            <p style={{ textDecoration: 'none', margin: '0px', padding: '0px' }}>
                                {this.state.operator?.Role?.name && this.state.operator?.Role?.name}
                            </p>

                            <button className="logoutBtn" onClick={this.logoutFromModal}>Logout</button>
                            <p onClick={this.changePassword}>Change Password</p>

                        </Modal>
                        {/** LoggedInUserModal */}


                    </MainHeaderEnd>

                </MainHeader>

                <MainBody>

                    <MainBodyLeft isOpenNavBar={this.state.isOpenSlider} className="shadowBox" style={shadowbox}>
                        <NavList goToNotiPage={this.goToNotiPage} showMenuItem={this.state.showMenuItem} data={this.state.data} />
                    </MainBodyLeft>

                    <MainBodyRight isOpenNavBar={this.state.isOpenSlider}>
                        {this.props.children}
                    </MainBodyRight>

                </MainBody>

                <MainFooter>
                    <MainFooterLeft isOpenNavBar={this.state.isOpenSlider}></MainFooterLeft>
                    <MainFooterRight isOpenNavBar={this.state.isOpenSlider}>
                        <label>Copy Right &copy; {this.state.currentYear}</label>
                    </MainFooterRight>
                </MainFooter>
            </MainContainer>
        );
    }
}


const mapStateToProps = state => {
    return {
        notification: state.wallet.notification
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateWallet: (wallet) => dispatch(WalletAction.updateWallet(wallet)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainLayout))