import { Component } from 'react'
class QuestionCreateConroller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: props.isEdit === false ? {
                question: '',
                quiz_type: '',
                win_reward: '',
                question_type: '',
                future_type: '',
                active: 1,
                redeem_points:''
            } : props.question.question
        }
    }
    handleInput = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                question: e.target.value,
            }
        }))
    }
    handleInputType = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                quiz_type: e.target.value,
            }
        }))

    }
    handleInputFutureType = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                future_type: e.target.value,
            }
        }))

    }
    handleInputQuestionType = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                question_type: e.target.value,
            }
        }))
        
    }
    handleInputReward = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                win_reward: e.target.value,
            }
        }))
    }
    handleInputRedeemPoints = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                redeem_points: e.target.value,
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            question: {
                ...prevState.question,
                active: e.target.value
            }
        }))
    }
}

export default QuestionCreateConroller