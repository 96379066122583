
import SponsorType from './../type/sponsor_type';
const initialState = {
  sponsors: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
}
const sponsorReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SponsorType.GET_SPONSORS:
      return {
        ...state,
        loading: false,
        sponsors: actions.payload,
      }
    case SponsorType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.paylaod,
      }
    case SponsorType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case SponsorType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload,
      }
    case SponsorType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload,
      }
    case SponsorType.ADD_SPONSOR:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        sponsors: [
          actions.payload,
          ...state.sponsors,
        ]
      }
    case SponsorType.UPDATE_SPONSOR:
      const updateItem = state.sponsors.filter(item => item.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        sponsors: [
          actions.payload,
          ...updateItem,
        ]
      }
    case SponsorType.DELETE_SPONSOR:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        sponsors: state.sponsors.filter(sponsor => sponsor.id !== actions.payload)
      }
    case SponsorType.LOADING:
      return {
        ...state,
        loading: actions.payload,
      }
    case SponsorType.SPONSOR_ERROR:
      return {
        ...state,
        error: actions.payload,
        loading: false
      }
    case SponsorType.SPONSOR_SUCCESS:
      return {
        ...state,
        success: actions.payload,
        loading: false
      }
    default: return state
  }
}
export default sponsorReducer