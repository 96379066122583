import React from 'react'
import FutureQuizAnswerCreate from './../create/FutureQuizAnswerCreate';
import FutureQuizAnswerListController from './FutureQuizAnswerListController';
import PlayerAction from './../../../../redux/action/player_action';
import QuizAnswerAction from './../../../../redux/action/quiz_answer_action';
import UserQuizAnswerAction from './../../../../redux/action/user_quiz_answer.action.js';
import TeamLeagueAction from './../../../../redux/action/team_league_action.js';
import TeamAction from './../../../../redux/action/team_action';
import { Back, Link, Button, EditModal, Correct, Wrong, Alert, connect, PageLoader, MainContainer, Header, Body, withRouter } from '../../../../components/ImportsFile'
class FutureQuizAnswerList extends FutureQuizAnswerListController {
  render() {
    let correct_answers;
    let Caption;
    if (this.props.quizInfo?.type === 1) {
      Caption = "Player"
    }
    else if (this.props.quizInfo?.type === 2) {
      Caption = "Team"
    }
    else if (this.props.quizInfo?.type === 3) {
      Caption = "Manager"
    }
    else {
      Caption = ""
    }
    if (this.props.quiz_answers && this.props.quiz_answers.length > 0) {
      correct_answers = parseInt(this.props.quiz_answers[0]?.answer)
    }
    return (
      <MainContainer>
        <Header>
          <h4>
            <Link to={`/quizzes/future_quiz/${this.props.quizInfo?.special_quiz_id}`} style={{ textDecoration: 'none' }}>
              <img src={Back} alt="" width="25px" style={{ marginRight: "10px" }} />
            </Link>
            Quiz Answers</h4>
          {
            this.props.quiz_answers.length === 0 &&
            <Button className="btn-primary add-button" caption="Add Correct Answer" btnFun={this.showModal} />
          }

        </Header>
        <Body>

          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          {
            this.props.quiz_answers.length > 0 &&
            <div className="correct_answer">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Correct Answer</h4>
                <EditModal editItem={() => this.handleEdit(this.props.quiz_answers[0])} />
              </div>

              <div className="correct_answer_card">
                <div className="correct_answer_key">
                  <strong>Question </strong>
                </div>
                <div className="correct_answer_value">
                  <small> <b>:</b>
                    {
                      this.props.quizInfo && this.props.quizInfo?.Question?.question
                    }
                  </small>
                </div>
              </div>

              {
                (this.props.quiz_answers.length !== 0 && this.props.quiz_answers) &&
                <div className="correct_answer_card">
                  <div className="correct_answer_key">
                    <strong>Answer </strong>
                  </div>
                  <div className="correct_answer_value">
                    <small> <b>:</b>
                      {(this.props.quiz_answers.length !== 0 && this.props.quiz_answers) && this.props.quiz_answers[0]?.win_case}
                    </small>
                  </div>
                </div>
              }
            </div>
          }

          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>User Name</th>
                <th>{Caption} Name</th>
              </tr>
            </thead>
            <tbody>
              {this.props.user_quiz_answers.map((answer, index) => {
                let user_answers;
                if (answer.team_id !== null) {
                  user_answers = answer.team_id
                }
                else if (answer.player_id !== null) {
                  user_answers = answer.player_id
                }
                else if (answer.manager_id !== null) {
                  user_answers = answer.manager_id
                }

                return (
                  <tr key={index}>
                    <td>

                      {
                        !this.props.quiz_answers.length > 0 ? "-" :
                          <>
                            {
                              correct_answers == user_answers ?
                                <img
                                  src={Correct}
                                  key={index}
                                  alt=""
                                  style={{ width: "25px" }}
                                />
                                :
                                <img
                                  src={Wrong}
                                  key={index}
                                  alt=""
                                  style={{ width: "25px" }}
                                />

                            }
                          </>
                      }
                    </td>
                    <td>{answer.User?.user_name || "-"}</td>
                    <td style={{ paddingTop: "10px" }}>

                      {answer.player_id && answer?.PlayerId?.name}
                      {answer.team_id && answer?.Team?.name}
                      {answer.manager_id && answer?.ManagerId?.name}


                    </td>
                  </tr>
                );
              })}

            </tbody>
          </table>

          {
            this.state.show &&
            <FutureQuizAnswerCreate
              isEdit={this.state.isEdit}
              players={this.props.players}
              quizInfo={this.props.quizInfo}
              getPlayers={this.props.getPlayers}
              searchPlayer={this.props.searchPlayer}
              loadMorePlayers={this.loadMorePlayers}
              teams={this.props.teams}
              getTeams={this.props.getTeams}
              loadMoreTeams={this.loadMoreTeams}
              league_id={this.props.quizInfo?.SpecialQuiz?.league_id}
              teamRowCount={this.props.teamRowCount}
              teamCurrentPage={this.props.teamCurrentPage}
              searchTeam={this.props.searchTeam}
              hideModal={this.hideModal}
              createOrUpdate={this.createOrUpdate}
              quiz_answer={this.state.quiz_answer}

              playerCurrentPage={this.props.playerCurrentPage}
              playerRowCount={this.props.playerRowCount}
              playerTotalCount={this.props.playerTotalCount}
              playerTotalCountPerPage={this.props.playerTotalCountPerPage}


              teamTotalCount={this.props.teamTotalCount}
              teamTotalCountPerPage={this.props.teamTotalCountPerPage}
              searchWithLeagueAndTeamName={this.props.searchWithLeagueAndTeamName}
              leagueId={this.props.leagueId}
              teamName={this.props.teamName}

              teamText={this.props.teamText}
              Caption={Caption}

            />

          }

          {/**
              <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.userId ? this.props.getUserQuizAnswers(pageNumber, this.props.row_count) : this.props.searchItem(this.props.userId, pageNumber) }} />
           */}


        </Body>
      </MainContainer >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    quiz_answers: state.quiz_answer.quiz_answers,
    currentPage: state.quiz_answer.currentPage,
    rowCount: state.quiz_answer.row_count,
    totalCount: state.quiz_answer.totalCount,
    totalCountPerPage: state.quiz_answer.totalCountPerPage,
    loading: state.quiz_answer.loading,
    error: state.quiz_answer.error,
    success: state.quiz_answer.success,
    quizId: state.quiz_answer.quizId,
    quizDetailId: state.quiz_answer.quizDetailId,


    players: state.player.players,
    text: state.player.text,
    playerCurrentPage: state.player.currentPage,
    playerRowCount: state.player.row_count,
    playerTotalCount: state.player.totalCount,
    playerTotalCountPerPage: state.player.totalCountPerPage,
    teamId: state.player.teamId,


    teams: state.team_league.team_leagues,
    teamName: state.team_league.teamName,
    teamText: state.team.text,
    teamlist: state.team_league.team_leagues,
    teamRowCount: state.team_league.row_count,
    teamCurrentPage: state.team_league.currentPage,
    teamTotalCount: state.team_league.totalCount,
    teamTotalCountPerPage: state.team_league.totalCountPerPage,

    user_quiz_answers: state.user_quiz_answer.user_quiz_answers,
    UserAnsCrrentPage: state.user_quiz_answer.currentPage,
    UserAnsTotalCount: state.user_quiz_answer.totalCount,
    UserAnsError: state.quiz_answer.error,
    UserAnsSuccess: state.quiz_answer.success,
    UserAnsTotalCountPerPage: state.user_quiz_answer.totalCountPerPage,
    UserAnsRowCount: state.user_quiz_answer.row_count,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

    getQuizAnswers: (quizId, quizDetailId, pageNumber, row_count) => dispatch(QuizAnswerAction.getQuizAnswers(quizId, quizDetailId, pageNumber, row_count)),
    addQuizAnswer: (answer) => dispatch(QuizAnswerAction.addQuizAnswer(answer)),
    updateQuizAnswer: (answer) => dispatch(QuizAnswerAction.updateQuizAnswer(answer)),
    getUserQuizAnswers: (quizDetailId, pageNumber, row_count) => dispatch(UserQuizAnswerAction.getUserQuizAnswers(quizDetailId, pageNumber, row_count)),
    getPlayers: (teamId, pageNumber, row_count) => dispatch(PlayerAction.getPlayers(teamId, pageNumber, row_count)),
    searchPlayer: (text, position, leagueId, teamId, row_count, pageNumber) => dispatch(PlayerAction.searchPlayer(text, position, leagueId, teamId, row_count, pageNumber)),
    getTeams: (pageNumber, row_count) => dispatch(TeamAction.getTeams(pageNumber, row_count)),
    searchTeam: (text, stadiumId, countryId, pageNumber) => dispatch(TeamAction.searchTeam(text, stadiumId, countryId, pageNumber)),
    searchWithLeagueAndTeamName: (leagueId, teamName, row_count, pageNumber) => dispatch(TeamLeagueAction.searchWithLeagueAndTeamName(leagueId, teamName, row_count, pageNumber)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FutureQuizAnswerList))

