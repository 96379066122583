import React from 'react'
import PlayerCreateController from './PlayerCreateController';
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import '../../modal/modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch'
import SelectModal from './../../../components/selectModal/SelectModal';

const textSwitch = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  paddingRight: 2,
  color: "#FFFFFFA0",
  fontSize: 12,
  fontWeight: "bold",

}
class PlayerCreate extends PlayerCreateController {
  render() {
    // let teams = []
    // if (this.props.checked) {
    //   teams = this.props.teamList.filter(team => !this.state.teamIds.includes(team.team_id))
    // }
    return (
      <div className="modal-wrapper">
        <div className="modalHeader">
          <h3>{this.props.isEdit ? 'Edit Player' : 'Add Player'}</h3>
          <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
        </div>
        <div className="modalContent">
          <div className="modalBody">

            <div style={{ display: 'flex', marginTop: "8px", alignItems: "center", justifyContent: 'center' }}>
              <span style={{ color: "#FAD230", fontSize: '16px', paddingRight: '10px' }}>
                Manual
              </span>
              <Switch onChange={this.props.handleSwitch} checked={this.props.checked}
                offColor="#00ff00"
                onColor="#02ba02"
                offHandleColor="#eaeaea"
                onHandleColor="#eaeaea"
                checkedIcon={
                  <div style={textSwitch}> </div>
                } uncheckedIcon={
                  <div style={textSwitch}> </div>
                }
              />
              <span style={{ color: "#FAD230", fontSize: '16px', paddingLeft: '10px' }}>
                Migrate from fotmob
              </span>
            </div>
            {
              this.props.checked ?
                <form autoComplete="off" style={{ width: '80%', margin: 'auto', }}>
                  {
                    this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                  }

                  <div style={{ columnCount: '3', margin: 'auto', marginBottom: '20px' }}>

                    <Input type="text" placeholder="Enter App ID" name="appId" value={this.state.migrate_player.appId} onChange={this.AppIdProcess} required />

                    <SelectModal
                      routeChangeName="league"
                      items={this.props.leagues}
                      handleProcess={(league_id) => this.LeagueProcess(league_id)}
                      loadMore={this.props.loadMoreLeague}
                      searchLeague={this.props.searchLeague}
                      currentPage={this.props.leagueCurrentPage}
                      getItems={this.props.getAllLeagues}
                      rowCount={this.props.leagueRowCount}
                      itemName={(this.props.isEdit && this.props.migrate_player?.migrate_player?.League?.name) ? this.props.migrate_player?.migrate_player?.League?.name : "*** Choose League ***"}
                    />

                    <SelectModal
                      // teamIds={this.state.teamIds}
                      // teamsForPlayers={teams}
                      // noTeam={teams?.length === 0 && true}
                      // teamsForPlayers={this.props.teamList.filter(team => !this.state.teamIds.includes(team.team_id))}
                      teamsForPlayers={this.props.teamList}
                      routeChangeName="team"
                      LoadMore={this.loadMoreTeamLeague}
                      teamIds={this.props.teamIds}
                      league_id={this.state.league_id}
                      searchWithLeague={this.props.searchWithLeague}
                      currentPage={this.props.teamCurrentPage}
                      stopSearching={this.props.searchWithLeague}
                      rowCount={this.state.teamleagueRowCount}
                      handleIdProcess={(id) => this.TeamIdProcess(id)}
                      localTeamIdProcess={(team_id) => this.LocalTeamIdProcess(team_id)}
                      handleNameProcess={(name) => this.TeamNameProcess(name)}
                      itemName={(this.props.isEdit && this.props.migrate_player.migrate_player?.Team?.name) ? this.props.migrate_player.migrate_player?.Team?.name : "*** Choose Team ***"}
                    />
                  </div>
                  <Button caption="Migrate" id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.migratePlayer(this.state.migrate_player)} />


                </form>
                :
                <form autoComplete="off" >
                  {
                    this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                  }
                  <div style={{ columnCount: 2, marginTop: '10px' }}>
                    <Input type="text" placeholder="Enter Player Name" name="name" value={this.state.player.name} onChange={this.handleInputChange} required />

                    <select name="position" className="select" value={this.state.player.position} onChange={this.handleInputPosition} required >
                      <option value=""> *** Choose Position ***</option>
                      <option value="attackers">Attacker</option>
                      <option value="midfielders">Midfielder</option>
                      <option value="defenders">Defender</option>
                      <option value="goalkeepers">Goalkeeper</option>
                      <option value="manager">Manager</option>
                    </select>

                    <SelectModal
                      routeChangeName="league"
                      items={this.props.leagues}
                      handleProcess={(league_id) => this.LeagueProcess(league_id)}
                      loadMore={this.props.loadMoreLeague}
                      searchLeague={this.props.searchLeague}
                      currentPage={this.props.leagueCurrentPage}
                      getItems={this.props.getAllLeagues}
                      rowCount={this.props.leagueRowCount}
                      itemName={(this.props.isEdit && this.props.player?.player?.League?.name) ? this.props.player?.player?.League?.name : "*** Choose League ***"}
                    />

                    <SelectModal
                      routeChangeName="team"
                      items={this.props.teams}
                      LoadMore={this.props.loadMoreTeam}
                      searchTeam={this.props.searchTeam}
                      currentPage={this.props.teamCurrentPage}
                      getItems={this.props.getAllTeams}
                      rowCount={this.props.teamRowCount}
                      handleProcess={(team_id) => this.TeamProcess(team_id)
                      }
                      itemName={(this.props.isEdit || this.props.player.player?.Team?.name) ? this.props.player.player?.Team?.name : "*** Choose Team ***"}
                    />

                    <SelectModal
                      routeChangeName="country"
                      items={this.props.countries}
                      LoadMore={this.props.loadMoreCountry}
                      searching={this.props.searchCountry}
                      currentPage={this.props.countryCurrentPage}
                      getItems={this.props.getCountries}
                      rowCount={this.props.countryRowCount}
                      handleProcess={(country_id) => this.CountryProcess(country_id)
                      }
                      itemName={this.props.isEdit ? this.props.player.player?.Country?.name : "*** Choose Country ***"}
                    />

                    <select name="type" className="select" value={this.state.player.type} onChange={this.handleSelectType} required >
                      <option value="1">Player</option>
                      <option value="2">Coach</option>
                    </select>
                    <select name="active" className="select" value={this.state.player.active} onChange={this.handleSelect} required >
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                    </select>
                    <br />
                    <br />
                    <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.player)} />
                  </div>
                  <div className="image-upload">
                    <label htmlFor="file">
                      <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#fad230" /> Choose Image
                    </label>
                    <input type="file" id="file" name="image_url" onChange={this.onChangeImage} style={{ display: 'none' }} />
                    {
                      (typeof this.state.player.image_url === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.player.image_url} alt="" style={{ width: '110px', maxHeight: '100px' }} />
                    }
                    {
                      (typeof this.state.player.image_url !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.player.image_url)} alt="" style={{ width: '110px', maxHeight: '100px' }} />
                    }
                  </div>


                </form>
            }

          </div>
        </div>
      </div>
    )
  }
}

export default PlayerCreate