const LeagueType = {
    GET_LEAGUES: "GET_LEAGUES",
    ADD_LEAGUE: "ADD_LEAGUE",
    UPDATE_LEAGUE: "UPDATE_LEAGUE",
    DELETE_LEAGUE: "DELETE_LEAGUE",
    LEAGUE_ERROR: "LEAGUE_ERROR",
    LEAGUE_SUCCESS: "LEAGUE_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    LOADING: "LOADING",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_WITH_COUNTRY: "SEARCH_WITH_COUNTRY",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    ERROR: "ERROR",
}
export default LeagueType