import { Component } from 'react'

export class TeamLeagueListingControler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            initialPage: 1,
            rowCount: 10,
            teamRowCount: 10,
            leagueRowCount: 10,
            show: false,
            isEdit: false,
            team_league: {},
            teamId: '',
            leagueId: '',

        }
    }
    componentDidMount = () => {
        this.loadMore1();
        this.loadMore2();
        this.props.getTeamLeagues(this.state.initialPage, this.state.rowCount)
    }
    handleSelectTeamId = (teamId) => {
        this.setState({
            teamId: teamId,
            leageuId: ''
        })
    }
    handleSelectLeagueId = (leagueId) => {
        this.setState({
            leagueId,
            teamId: ''
        })
    }
    handleSearch = (e) => {
        e.preventDefault()
        this.props.searchWithTeamOrLeague(this.state.teamId, this.state.leagueId, this.state.initialPage)
    }
    handleEdit = async (team_league) => {
        this.setState((prevState) => ({
            ...prevState,
            isEdit: true,
            show: true,
            team_league: {
                ...prevState.team_league,
                team_league
            }
        }))
    }
    loadMore1 = () => {
        this.setState({
            teamRowCount: this.state.teamRowCount + 6
        })
        this.props.getAllTeams(this.state.initialPage, this.state.teamRowCount)
    }
    loadMore2 = () => {
        this.setState({
            leagueRowCount: this.state.leagueRowCount + 6
        })
        this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount)
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'))
        let team_league;
        if (this.state.isEdit) {
            team_league = {
                id: data.id,
                team_id: data.team_id,
                league_id: data.league_id,
                description: data.description,
                active: 1,
                updated_by: operator.id
            }
            this.props.updateTeamLeague(team_league);
        }
        else {
            team_league = { ...data, created_by: operator.id, updated_by: operator.id }
            this.props.createTeamLeague(team_league)
        }
        this.hideModal();
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
}

export default TeamLeagueListingControler