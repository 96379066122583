import React from 'react'
import { Link } from 'react-router-dom';
import { MainContainer, Header, Body } from './../../PagesElement'

import ReactLoading from 'react-loading';
import { withRouter } from './../../../components/routers/withRouter'

import UserDetailController from './UserDetailController';
import { Button } from './../../../components/buttons/Button';
import fallBackTeam from './../../../images/icons/team.png'
import moment from 'moment'
import styled from 'styled-components'
// Get ID from URL
class UserDetail extends UserDetailController {


    render() {
        const { Team, FavoriteRule, GlobalLevelRule, UserTeamActivity, NextLevelUp, UserNationalTeamActivity, NationalTeam } = this.state.user
        return (
            <MainContainer>

                <Header>
                    <h4>User Detail</h4>
                    <Link to="/user" className="back">
                        <Button caption="Go To User" className="btn-primary add-button" />
                    </Link>
                </Header>
                <Body>



                    <div className="user_detail_card">

                        <h5>{this.state.user.user_name}</h5>
                        {
                            !this.state.user.favourite_team_level_id
                                ?
                                <div className="full_page_loader">
                                    <ReactLoading type={'bubbles'} color={"#fad230"} height={'20%'} width={'20%'} />

                                </div>
                                :
                                <>
                                    <div className="nav">
                                        <div className="user_info" >
                                            <button onClick={this.showUserInfo} style={{ backgroundColor: this.state.showUserInfo && '#fad230' }}>User Info</button>
                                        </div>
                                        <div className="user_level_info" >
                                            <button onClick={this.showUserLevel} style={{ backgroundColor: this.state.showUserLevel && '#fad230' }}>Level Info</button>
                                        </div>
                                    </div>

                                    <UserDetailCard>
                                        {
                                            this.state.showUserInfo ?

                                                <UserInfoCard>
                                                    <ul className="info-list" style={{ listStyleType: "none" }}>
                                                        <li className="info-item">
                                                            <span style={{ display: "flex", justifyContent: 'space-between', marginBottom: "10px", alignItems: "center" }}>
                                                                <span>User Profile</span>
                                                                <span>
                                                                    {
                                                                        <img src={this.state.user?.image_url} alt="" width="60px" height="60px" onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                                                    }
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className="info_item">
                                                            <p>Username</p>

                                                            <p className="user_data">{
                                                                this.state.user?.user_name ? this.state.user?.user_name : "-"
                                                            }</p>
                                                        </li>

                                                        <li className="info_item">
                                                            <p>Phone Number</p>
                                                            <p className="user_data">
                                                                {this.state.user?.phone_no ? this.state.user?.phone_no : "-"}</p>
                                                        </li>

                                                        <li className="info_item">
                                                            <p>Email</p>
                                                            <p className="user_data">
                                                                {this.state.user?.email ? this.state.user?.email : "_"}
                                                            </p>
                                                        </li>
                                                        <li className="info_item">
                                                            <p>Gender</p>
                                                            <p className="user_data">
                                                                {this.state.user?.gender ? this.state.user?.gender : "_"}
                                                            </p>
                                                        </li>
                                                        <li className="info_item">
                                                            <p>Date of Birth</p>
                                                            <p className="user_data">
                                                                {this.state.user?.dob ? moment(this.state.user?.dob).format("DD/MM/yyyy") : "_"}

                                                            </p>
                                                        </li>
                                                        <li className="info_item">
                                                            <p>Address</p>
                                                            <p className="user_data">
                                                                {this.state.user?.address ? this.state.user?.address : "_"}
                                                            </p>
                                                        </li>



                                                    </ul>
                                                </UserInfoCard>

                                                :
                                                <LevelInfoCard>
                                                    <FavTeamCard>
                                                        <ul className="info-list" style={{ listStyleType: "none" }}>
                                                            <li className="info-item">
                                                                <span style={{ display: "flex", justifyContent: 'space-between', marginBottom: "10px", alignItems: "center" }}>
                                                                    <span>Favourite Team</span>
                                                                    <span>
                                                                        {
                                                                            !Team?.name ? <p>_</p> : <img src={Team?.logo_url} alt="" width="50px" height="50px" style={{ marginRight: '10px' }} onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                                                        }
                                                                        <span className="user_data">{Team?.name}</span>

                                                                    </span>
                                                                </span>
                                                            </li>
                                                            <li className="info_item">
                                                                <p>Current Level Name</p>
                                                                <p className="user_data">{
                                                                    FavoriteRule ? FavoriteRule.level_name : "-"
                                                                }</p>
                                                            </li>

                                                            <li className="info_item">
                                                                <p>Current Level Number</p>
                                                                <p className="user_data">{FavoriteRule ? FavoriteRule.level_number : "-"}</p>
                                                            </li>

                                                            <li className="info_item">
                                                                <p>Watch Count</p>
                                                                <p className="user_data">{Team?.name ? UserTeamActivity?.for_team_watch_count : "_"}</p>
                                                            </li>
                                                            <li className="info_item">
                                                                <p>Quiz Count</p>
                                                                <p className="user_data">{Team?.name ? UserTeamActivity?.for_team_quizzes_count : "_"}</p>
                                                            </li>
                                                            <li className="info_item">
                                                                <p>News Count</p>
                                                                <p className="user_data">{Team?.name ? "0" : "_"}</p>
                                                            </li>
                                                            <li className="info_item">
                                                                <p>Total Count</p>
                                                                <p className="user_data">{Team?.name ? UserTeamActivity?.total_count : "_"}</p>
                                                            </li>

                                                            <li className="info_item">
                                                                <p>Count for Next Level</p>
                                                                <p className="user_data">{
                                                                    NextLevelUp ? NextLevelUp.favourite + 1 : "-"
                                                                }</p>
                                                            </li>

                                                        </ul>
                                                    </FavTeamCard>
                                                    <RightCard>
                                                        <TopFanCard>
                                                            <ul className="info-list">
                                                                <li className="info_item">
                                                                    <p>Top Fan Level</p>
                                                                    <p className="user_data">{GlobalLevelRule ? GlobalLevelRule.top_fan_level_number : "-"}</p>
                                                                </li>
                                                                <li className="info_item">
                                                                    <p>Global Watch Count</p>
                                                                    <p className="user_data">{this.state.user.global_watch_count}</p>
                                                                </li>


                                                                <li className="info_item">
                                                                    <p>Count For Next Level</p>
                                                                    <p className="user_data">{
                                                                        // (this.state.globalLevelRule.to_max_count + 1) - this.state.user?.watch_count
                                                                        NextLevelUp ? NextLevelUp.global + 1 : "-"
                                                                    } </p>
                                                                </li>

                                                            </ul>
                                                        </TopFanCard>
                                                        <br />
                                                        <NationalFavTeamCard>
                                                            <ul className="info-list">
                                                                <li className="info-item">
                                                                    <span>
                                                                        <span>Favourite National Team</span>
                                                                        <span>
                                                                            {
                                                                                !NationalTeam?.name ? <p>_</p> : <img src={NationalTeam?.logo_url} alt="" width="50px" height="50px" style={{ marginRight: '10px' }} onError={(e) => (e.currentTarget.src = fallBackTeam)} />
                                                                            }
                                                                            <span className="user_data">{NationalTeam?.name}</span>

                                                                        </span>
                                                                    </span>
                                                                </li>


                                                                <li className="info_item">
                                                                    <p>Watch Count</p>
                                                                    <p className="user_data">{NationalTeam?.name ? UserNationalTeamActivity?.for_team_watch_count : "_"}</p>
                                                                </li>
                                                                <li className="info_item">
                                                                    <p>Quiz Count</p>
                                                                    <p className="user_data">{NationalTeam?.name ? UserNationalTeamActivity?.for_team_quizzes_count : "_"}</p>
                                                                </li>

                                                                <li className="info_item">
                                                                    <p>Total Count</p>
                                                                    <p className="user_data">{NationalTeam?.name ? UserNationalTeamActivity?.total_count : "_"}</p>
                                                                </li>
                                                            </ul>
                                                        </NationalFavTeamCard>
                                                    </RightCard>
                                                </LevelInfoCard>

                                        }
                                    </UserDetailCard>
                                </>

                        }
                    </div>
                </Body>
            </MainContainer >
        )
    }
}



export default withRouter(UserDetail)
export const UserDetailCard = styled.div`
// width: 100%;
    margin: auto;
    padding: 0px;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 400px;
 
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #202020;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}
   
`
export const UserInfoCard = styled.div`
    width:70%;
    margin:auto;
`
export const LevelInfoCard = styled.div`
    display:flex;
    width:100%;
    justify-content:spece-between;
    column-gap:20px;
`
export const FavTeamCard = styled.div`
flex:1;
border:1px solid #fad230;
padding:15px 15px 0px 15px;

`
export const RightCard = styled.div`
flex:1;

`
export const TopFanCard = styled.div`
border:1px solid #fad230;
padding:15px 15px 0px 15px;
ul{
    list-style-type:none;
    margin-top:10px;
}


`
export const NationalFavTeamCard = styled.div`
border:1px solid #fad230;
padding:15px 15px 0px 15px;
ul{
    list-style-type:none;
    margin-top:10px;
}
span{
display:flex;
justify-content:space-between;
margin-bottom:10px;
align-items:center;
}

`


