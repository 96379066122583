import OperatorCreate from '../operatorCreate/OperatorCreate'
import { OperatorListController } from './OperatorListController';
import OperatorAction from './../../../redux/action/operator_action';
import RoleAction from './../../../redux/action/role_action';
import { MainContainer, Header, Modal, Body, Button, PageLoader, Pagination, Alert, connect } from '../../../components/ImportsFile'
class OperatorListing extends OperatorListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Operator' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Operator' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Operator' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (

            <MainContainer>
                <Header>
                    <h4>Operators</h4>
                    <form className="search" autoComplete="off" onSubmit={this.handleSearch} >
                        <div className="input-group">
                            <input type="text" className="input-field" placeholder="Search.." name="search" value={this.state.text} onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>

                    </form>

                    {
                        createPermission &&
                        <Button icon="" caption="Add Operator" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }

                    <table>
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Full Name</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.operators.map((operator, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{operator.Role?.name}</td>
                                            <td>{operator.full_name}</td>
                                            <td>{operator.user_name}</td>
                                            <td>{operator.email}</td>
                                            <td>{operator.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteOperator(operator.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(operator)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    {/*
                        this.props.text && <RecordCount searchCount={this.props.totalCount} />
                        */}

                    <Pagination
                        activePage={this.props.currentPage}
                        totalItemsCount={this.props.totalCount}
                        totalCountPerPage={this.props.totalCountPerPage}
                        paginate={(pageNumber) => { !this.props.text ? this.props.getOperators(pageNumber, this.props.row_count) : this.props.searchOperator(this.props.text, pageNumber) }}
                    />

                    {
                        this.state.show &&
                        <OperatorCreate
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            operator={this.state.operator}
                            createOrUpdate={this.createOrUpdate}
                            roles={this.props.roles}
                            requiredError={this.state.requiredError}
                            searchRole={this.props.searchRole}
                            getAllRoles={this.props.getAllRoles}
                            roleRowCount={this.props.roleRowCount}
                            currentRolePage={this.props.currentRolePage}
                        />
                    }

                </Body>

            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        operators: state.operator.operators,
        error: state.operator.error,
        success: state.operator.success,
        currentPage: state.operator.currentPage,
        totalCount: state.operator.totalCount,
        text: state.operator.text,
        totalCountPerPage: state.operator.totalCountPerPage,
        row_count: state.operator.row_count,
        roles: state.role.roles,
        loading: state.operator.loading,
        roleRowCount: state.role.row_count,
        currentRolePage: state.role.currentPage,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getOperators: (pageNumber, row_count) => dispatch(OperatorAction.getOperators(pageNumber, row_count)),
        createOperator: (operator) => dispatch(OperatorAction.createOperator(operator)),
        updateOperator: (operator) => dispatch(OperatorAction.updateOperator(operator)),
        deleteOperator: (id) => dispatch(OperatorAction.deleteOperator(id)),
        searchOperator: (text, pageNumber) => dispatch(OperatorAction.searchOperator(text, pageNumber)),
        getAllRoles: (pageNumber, row_count) => dispatch(RoleAction.getAllRoles(pageNumber, row_count)),
        searchRole: (text, pageNumber) => dispatch(RoleAction.searchItem(text, pageNumber))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OperatorListing)
