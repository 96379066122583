import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'
import { Fraction } from 'fractional'
class FutureDetailsListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCreateRow: false,
      loading: false,
      success: false,
      message: '',
      failed: false,
      show: false,
      isEdit: false,
      remove: false,
      playerRowCounts: CONSTANTS.ROW_COUNT,
      teamRowCount: CONSTANTS.ROW_COUNT,
      rowCount: CONSTANTS.ROW_COUNT,
      oddsRateRowCount: CONSTANTS.ROW_COUNT,
      minRowCounts: 100,
      prevData: [],
      prevIDs: [],
      requiredError: '',
      initialPage: 1,
      selectedValue: '',
      special_quiz_detail_id: '',
      odd_rate_id: '',
      quiz_type: '',
      type: '',
      quiz_id: '',
      question_id: '',
      special_quiz_data: [],
      selectedId: '',
      team_id: [],
      player_id: [],
      manager_id: [],
      leagueId: '',
      teamId: '',
      text: '',
      newItem: {
        special_quiz_id: '',
        type: '',
        team_id: '',
        player_id: '',
        manager_id: '',
        match_id: '',
        special_quiz_detail_id: '',
        odds_american: '',
        odds_fractional: '',
        odds_decimal: '',
        main_odds_type: 1,
        type_for_match: '',
        active: 1,
      }
    }
  }

  componentDidMount = async () => {

    let id = this.props.match.params.id && this.props.match.params.id
    let future_detail_id = this.props.match.params.future_detail_id && this.props.match.params.future_detail_id

    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        special_quiz_detail_id: future_detail_id,
      },
      quiz_id: id,
    }))

    await axios.post(`${CONSTANTS.BASE_URL}special_quiz_details/detail`, { id: future_detail_id }).then(response => {
      this.setState({
        type: response.data.data.type,
        quiz_type: response.data.data.quiz_type,
        question_id: response.data.data.question_id,
        special_quiz_data: response.data.data,
        leagueId: response.data.data?.SpecialQuiz?.league_id
      }, () => {
        if (this.state.special_quiz_data?.Question?.question == "ပြိုင်ပွဲတစ်လျှောက် အကောင်းဆုံး နောက်တန်းကစားသမား") {
          this.props.searchPlayer(this.state.text, 'defenders', this.state.leagueId, this.state.teamId, this.state.playerRowCounts, this.state.initialPage)
        }
        else {
          this.props.getPlayers(this.props.teamId, this.state.initialPage, this.state.playerRowCounts)
        }
      })
      if (response.data.data.type == 3) {
        this.setState({
          leagueId: response.data.data?.SpecialQuiz?.league_id
        })
      }
    })
    this.state.quiz_type === 2 && this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        odds_american: '1',
        odds_fractional: '1/1',
        odds_decimal: '1.00',
        main_odds_type: '1',
      }
    }))

    this.loadMoreTeams();
    this.props.searchWithLeagueAndTeamName(this.state.special_quiz_data?.SpecialQuiz?.league_id, '', this.state.teamRowCount, this.state.initialPage)
    this.state.quiz_type === 2 ? this.props.getOddsRates(this.state.newItem.special_quiz_detail_id, this.state.initialPage, this.state.minRowCounts) : this.props.getOddsRates(this.state.newItem.special_quiz_detail_id, this.state.initialPage, this.state.rowCount)

  }
  loadMoreTeams = () => {
    let leagueId = this.state.special_quiz_data?.SpecialQuiz?.league_id
    this.props.searchWithLeagueAndTeamName(leagueId, '', this.state.teamRowCount, this.state.initialPage)
    this.setState({
      teamRowCount: this.state.teamRowCount + 6
    })
  }

  handleSelect = (id, name) => {
    if (this.state.isEdit) {
      this.setState({
        type: this.state.newItem?.SpecialQuizDetail?.type
      }, () => {
        this.getItemId(id, name)
      })
    }
    else {
      this.getItemId(id, name)
    }

    this.setState(state => ({
      ...state,
      selectedValue: name,
      selectedId: id,
      show: false,
    }))

    // this.setState({
    //   selectedValue: name,
    //   selectedId: id,
    //   show: false,
    // }, () => {
    // })

  }
  getItemId = (id, name) => {
    if (this.state.type === 1) {
      this.setState((prevState) => ({
        ...prevState,
        newItem: {
          ...prevState.newItem,
          player_id: id,
          type_for_match: 4,
        }
      }))
    }
    else if (this.state.type === 2) {
      this.setState((prevState) => ({
        ...prevState,
        newItem: {
          ...prevState.newItem,
          team_id: id,
          type_for_match: 5,
        }
      }))
    }
    else if (this.state.type === 3) {
      this.setState((prevState) => ({
        ...prevState,
        newItem: {
          ...prevState.newItem,
          manager_id: id,
          type_for_match: 6
        }
      }))
    }
    else if (this.state.type === 4) {
      this.setState((prevState) => ({
        ...prevState,
        newItem: {
          ...prevState.newItem,
          match_id: id,
        }
      }))
    }

  }

  addNewRow = () => {
    this.setState({
      showCreateRow: true
    })
  }
  OddsAmerican = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        odds_american: e.target.value
      }
    }), () => {
      let odds_american = Number(this.state.newItem.odds_american);
      let decimal;
      let fractional;

      let fra;
      if (odds_american > 0) {
        decimal = 1 + (odds_american / 100)
        fra = odds_american / 100
        fractional = fra.toFixed(2)
      }
      else {
        decimal = 1 - (100 / odds_american)
        fra = -(100 / odds_american)
        fractional = fra.toFixed(4)
      }

      if (fractional > 0) {
        let value = new Fraction(fractional);
        let fraction = `${value.numerator}/${value.denominator}`
        this.setState((prevState) => ({
          ...prevState,
          newItem: {
            ...prevState.newItem,
            odds_decimal: decimal.toFixed(2),
            odds_fractional: fraction,
          }
        }))
      }
      else {
        this.setState((prevState) => ({
          ...prevState,
          newItem: {
            ...prevState.newItem,
            odds_decimal: '',
            odds_fractional: '',
          }
        }))
      }

    })
  }
  OddsFractional = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        odds_fractional: e.target.value
      }
    })
      // , () => {
      //   let dec = this.state.newItem.odds_fractional.split('/')
      //   let decimal = parseInt(dec[0], 10) / parseInt(dec[1], 10);
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     newItem: {
      //       ...prevState.newItem,
      //       odds_decimal: decimal.toFixed(2)
      //     }
      //   }))
      // }
    )
  }
  OddsDecimal = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        odds_decimal: e.target.value
      }
    }))
  }
  MainOddsType = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        main_odds_type: e.target.value
      }
    }))
  }
  handleSelectActive = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        active: e.target.value
      }
    }))
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      // isEdit: false
    })
  }

  handleEdit = (newItem) => {
    this.props.toggleEdit(newItem)
    this.setState((prevState) => ({
      isEdit: true,
      ...prevState.newItem,
      newItem: newItem

    }))
  }
  cancelItem = () => {
    this.setState((prevState) => ({
      ...prevState,
      selectedValue: '',
      remove: true,
    }))
  }


  saveItem = async (data) => {

    const IDs = [];
    this.props.odds_rates?.map((item) => {
      if (item.team_id) {
        IDs.push(item.team_id)
      }
      else if (item.player_id) {
        IDs.push(item.player_id)
      }
      else if (item.manager_id) {
        IDs.push(item.manager_id)
      }

    })
    this.setState({
      prevIDs: IDs
    }, () => {
      let operator = JSON.parse(localStorage.getItem('operator'));
      // for odd type
      let newItem = {
        special_quiz_detail_id: this.state.newItem.special_quiz_detail_id,
        odds_american: data.odds_american,
        odds_fractional: data.odds_fractional,
        odds_decimal: data.odds_decimal,
        main_odds_type: parseInt(data.main_odds_type),
        type_for_match: data.type_for_match,
        // main_odds_type: this.state.isEdit ? parseInt(data?.newItem?.main_odds_type) : parseInt(data.main_odds_type),
        // active: this.state.isEdit ? data?.newItem?.active : data.active,
        active: data.active,
        updated_by: operator.id,
      }
      if (data.player_id) {
        newItem = { ...newItem, player_id: parseInt(data.player_id) }
      }
      else if (data.team_id) {
        newItem = { ...newItem, team_id: parseInt(data.team_id) }
      }
      else if (data.manager_id) {
        newItem = { ...newItem, manager_id: parseInt(data.manager_id) }
      }

      if (this.state.isEdit) {
        this.setState({
          loading: true,
        })
        newItem = { ...newItem, id: data.id }
        if (newItem?.odds_american === "") {
          this.setState({
            requiredError: "All fields are required"
          })
        } else {
          this.props.updateOddsRate(newItem).then(() => {
            this.props.getOddsRates(this.state.newItem.special_quiz_detail_id, this.props.currentPage, this.state.rowCount)
            this.setState({
              selectedValue: ''
            })
          })
          this.setState({
            showCreateRow: false
          })
        }
      }
      else {
        newItem = { ...newItem, created_by: operator.id }

        if (this.state.prevIDs.includes(newItem?.player_id) || this.state.prevIDs.includes(newItem?.manager_id) || this.state.prevIDs.includes(newItem?.team_id)) {
          this.setState({
            requiredError: "Already Exist"
          })
        }
        else if (newItem?.player_id === '' || newItem?.manager_id === '' || newItem?.team_id === '' || newItem?.odds_american === "") {
          this.setState({
            requiredError: "All fields are required"
          })
        }
        else {
          this.props.createOddsRate(newItem).then(() => {
            this.props.getOddsRates(this.state.newItem.special_quiz_detail_id, this.state.initialPage, this.state.rowCount)

            this.setState({
              selectedValue: ''
            })
            this.setState({
              requiredError: ""
            })
          })
          this.setState({
            showCreateRow: false,
          })
        }
      }
    })
  }
}

export default FutureDetailsListController
