
import { TownshipListController } from './TownshipListController'
import TownshipCreate from '../create/TownshipCreate'
import TownshipAction from './../../../redux/action/township_action';
import CityAction from './../../../redux/action/city_action';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, Search, connect } from '../../../components/ImportsFile'
class TownshipListing extends TownshipListController {

    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Township' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Township' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Township' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Townships</h4>
                    <form className="search search-township" autoComplete="off">
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            {/**
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                        */}
                            <Search
                                items={this.props.cities}
                                currentPage={this.props.currentPage}
                                searchCity={this.props.searchCity}
                                LoadMore={this.loadMore}
                                getItems={this.props.getAllCities}
                                rowCount={this.state.cityRowCount}
                                searchItem={(selected) => this.handleSelectCityId(selected)}
                                text={'Search with City'}
                                search={this.state.selected ? true : false}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>

                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Township" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>

                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>City</th>
                                <th>Township</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.townships.map((township, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{township.City?.name}</td>
                                            <td>{township.name}</td>
                                            <td>{township.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deleteTownship(township.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(township)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination
                        activePage={this.props.currentPage}
                        totalItemsCount={this.props.totalCount}
                        totalCountPerPage={this.props.totalCountPerPage}
                        paginate={(pageNumber) => {
                            (!this.props.text && !this.props.cityId) && this.props.getTownships(pageNumber, this.props.row_count);
                            (this.props.text || this.props.cityId) && this.props.searchItem(this.state.text, this.state.cityId, pageNumber);
                        }}
                    />
                    {
                        this.state.show &&
                        <TownshipCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            township={this.state.township}
                            createOrUpdate={this.createOrUpdate}
                            cities={this.props.cities}
                            loadMore={this.loadMore}
                            cityText={this.props.cityText}
                            currentPage={this.props.currentPage}
                            searchCity={this.props.searchCity}
                            getAllCities={this.props.getAllCities}
                            rowCount={this.state.rowCount}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        townships: state.township.townships,
        cities: state.city.cities,
        error: state.township.error,
        success: state.township.success,
        totalCount: state.township.totalCount,
        totalCountPerPage: state.township.totalCountPerPage,
        currentPage: state.township.currentPage,
        text: state.township.text,
        cityId: state.township.cityId,
        row_count: state.township.row_count,
        loading: state.township.loading,
        cityText: state.city.text,
        cityRowCount: state.city.row_count
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTownships: (pageNumber, row_count) => dispatch(TownshipAction.getTownships(pageNumber, row_count)),
        createTownship: (township) => dispatch(TownshipAction.createTownship(township)),
        updateTownship: (township) => dispatch(TownshipAction.updateTownship(township)),
        deleteTownship: (data) => dispatch(TownshipAction.deleteTownship(data)),
        getAllCities: (pageNumber, row_count) => dispatch(CityAction.getAllCities(pageNumber, row_count)),
        searchItem: (text, cityId, pageNumber) => dispatch(TownshipAction.searchItem(text, cityId, pageNumber)),
        searchCity: (text, countryId, pageNumber) => dispatch(CityAction.searchCity(text, countryId, pageNumber)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TownshipListing)