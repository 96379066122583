import React, { Component } from 'react';
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './Search.css'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            searchTerm: '',
            initialPage: 1,
            selectedValue: `${this.props.text}`,
        }

    }
    handleSelect = (id, name) => {
        this.props.searchItem(id)
        this.setState({
            show: false,
            selectedValue: name,
        })
    }
    handleInputSearch = (e) => {
        this.setState({
            searchTerm: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.searching && this.props.searching(this.state.searchTerm, this.props.currentPage)
        this.props.searchWithLeagueAndTeamName && this.props.searchWithLeagueAndTeamName(this.props.leagueId, this.state.searchTerm, '', this.props.currentPage)
        this.props.searchQuiz && this.props.searchQuiz(this.props.date, this.state.searchTerm, this.props.currentPage)
        this.props.searchActivityLogs && this.props.searchActivityLogs(this.state.searchTerm, '', '', '', this.props.currentPage)
        this.props.searchPlayer && this.props.searchPlayer('', this.state.searchTerm, '', this.props.currentPage)
        this.props.searchCity && this.props.searchCity(this.state.searchTerm, '', this.props.currentPage)
        this.props.searchLeague && this.props.searchLeague(this.state.searchTerm, '', this.props.currentPage)
        this.props.searchMatch && this.props.searchMatch(this.state.searchTerm, '', this.props.currentPage)
        this.props.searchTeam && this.props.searchTeam(this.state.searchTerm, '', '', this.props.currentPage)
        this.props.searchQuestion && this.props.searchQuestion(this.state.searchTerm, this.props.currentPage, this.props.rowCount)
    }
    stopSearching = () => {
        this.props.getItems(this.state.initialPage, this.props.rowCount)
        this.setState({
            show: true,
            searchTerm: '',
            selectedValue: `${this.props.text}`
        })
    }
    stopSearchingItem = () => {

        this.props.stopSearching()
        // this.props.stopSearching(this.state.initialPage, this.props.rowCount)

        if (this.props.text) {

            this.setState({
                show: false,
                selectedValue: `${this.props.text}`
            })
        }
    }
    ShowModal = () => {
        this.setState({
            show: true
        })
    }
    render() {
        let matchName;
        return (
            <div className="search_select">
                <div className="dropdown_search" onChange={this.handleSelect} onClick={this.ShowModal}>
                    {
                        // this.state.selectedValue
                        this.props.search ? this.state.selectedValue : this.props.text
                        // this.props.quizzes ? this.state.selectedValue : this.state.selectedValue
                        // this.state.selectedValue.substring(0, 27)
                    }
                    <span>
                        <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                </div>
                {
                    this.state.show && <Modal className={this.props.futureDetailSearch ? "futureDetailSearch searchSelectModal" : "searchSelectModal"}
                        ariaHideApp={false}
                        isOpen={this.state.show}
                        onRequestClose={() => this.setState({ show: false })}
                        shouldCloseOnOverlayClick={true}
                        style={
                            {
                                overlay: {
                                    background: 'transparent'
                                }
                            }
                        }
                    >
                        <p className="close" onClick={() => this.setState({ show: false })}>x</p>

                        <div className="item-lists" >
                            {
                                (!this.props.activity_types && !this.props.position && !this.props.activity_actions && !this.props.questions && !this.props.user_fav_teams && !this.props.international_leagues && this.props.text !== "Search with Match") &&
                                <form className="searchModal">
                                    <div className="input-group">
                                        <input type="text" className="input-field" placeholder={this.props.text} name="name" value={this.state.searchTerm} onChange={this.handleInputSearch} autoComplete="off" />
                                        <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                                        <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                                    </div>

                                </form>
                            }

                            <hr className="lineBreak" />
                            {
                                this.props.user_fav_teams && this.props.user_fav_teams.map((item, index) => {
                                    return (
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.User?.user_name + " Vs " + item.Team?.name)
                                        }} >
                                            <span>                                                    {item.User?.user_name} &#8608; {item.Team?.name}
                                            </span>
                                        </p>
                                    )
                                })
                            }

                            {
                                this.props.items && this.props.items.map((item, index) => {
                                    return (
                                        item.HomeTeam ?
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.id, item.HomeTeam.name + " Vs " + item.AwayTeam.name)
                                            }} >
                                                {item.HomeTeam.name} vs {item.AwayTeam.name}
                                            </p>

                                            :
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.id, item.name || item.user_name)
                                            }} >{item.name || item.user_name}</p>
                                    )
                                })
                            }
                            {
                                // match from highlihght search
                                this.props.matchForHighlight && this.props.matchForHighlight.map((item, index) => {
                                    return (
                                        item?.Match &&
                                        <p key={index} onClick={() => {
                                            this.handleSelect(item?.match_id, item?.Match?.HomeTeam.name + " Vs " + item?.Match?.AwayTeam.name)
                                        }} >
                                            {item?.Match?.HomeTeam?.name} vs {item?.Match?.AwayTeam?.name}
                                        </p>


                                    )
                                })
                            }
                            {
                                this.props.activity_types && this.props.activity_types.map((item, index) => {

                                    return (

                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.type)
                                        }} >{item.type}</p>

                                    )
                                })
                            }
                            {
                                this.props.position && this.props.position.map((item, index) => {

                                    return (

                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.type)
                                        }} >{item.type}</p>

                                    )
                                })
                            }
                            {
                                this.props.questions && this.props.questions.map((item, index) => {

                                    return (

                                        <p className="question" key={index} onClick={() => {
                                            this.handleSelect(item.id, item.type)
                                        }} >{item.type}</p>

                                    )
                                })
                            }
                            {
                                this.props.activity_actions && this.props.activity_actions.map((item, index) => {

                                    return (

                                        <p key={index} onClick={() => {
                                            this.handleSelect(item.id, item.action)
                                        }} >{item.action}</p>

                                    )
                                })
                            }
                            {
                                this.props.quizzes && this.props.quizzes.map((item, index) => {
                                    if (item?.match_name) {
                                        matchName = JSON.parse(item.match_name)
                                    }

                                    return (
                                        <div key={index}
                                            onClick={() =>
                                                this.handleSelect(item.id, item.league_name)
                                            }
                                        >
                                            <span className="search_with_quiz">
                                                <span>
                                                    <b>League&nbsp;:</b> <span>{item.league_name}</span>
                                                </span>
                                                <span>
                                                    <b>Match&nbsp; &nbsp;:</b> <small>{matchName.homeTeamName + " Vs " + matchName.awayTeamName || "-"}</small>
                                                </span>
                                            </span>

                                        </div>

                                    )
                                })
                            }

                            {
                                this.props.teams && this.props.teams.map((item, index) => {
                                    return (

                                        item?.Team ?
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.team_id, item?.Team?.name)
                                            }} >{item?.Team?.name}</p>
                                            :
                                            <p key={index} onClick={() => {
                                                this.handleSelect(item.id, item.name)
                                            }} >{item.name}</p>

                                    )
                                })
                            }

                            <div className={this.props.quizzes ? "loadMoreQuiz searchFooter" : "searchFooter"}>
                                {
                                    (this.props.activity_types || this.props.activity_actions || this.props.questions || this.props.user_fav_teams) &&
                                    <span className="stopSearching" onClick={this.stopSearchingItem}>Stop Searching </span>
                                }
                                {
                                    (this.props.items || this.props.matchForHighlight) &&
                                    <span className="stopSearching" onClick={this.stopSearchingItem}>Stop Searching </span>
                                }
                                {
                                    (this.props.teams) &&
                                    <span className="stopSearching" onClick={this.stopSearchingItem}>Stop Searching </span>
                                }
                                {
                                    this.props.LoadMore &&
                                    <>
                                        {
                                            (this.props.items && this.props.items.length > 5) &&
                                            <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                                        }
                                        {
                                            (this.props.teams && this.props.teams.length > 5) &&
                                            <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                                        }
                                        {
                                            (this.props.user_fav_teams && this.props.user_fav_teams.length > 5) &&
                                            <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                                        }
                                        {
                                            (this.props.quizzes && this.props.quizzes.length > 5) &&
                                            <span className="loadMore" onClick={() => this.props.LoadMore()}>Load More <FontAwesomeIcon icon={faArrowAltCircleRight} /> </span>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

export default Search
