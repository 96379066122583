import React, { Component } from 'react'
import moment from 'moment'
class AnnouncementCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
      announcement: props.isEdit === false ? {
        title: '',
        description: '',
        image_url: '',
        go_to_screen: 0,
        start_date: '',
        end_date: '',
        button_text: '',
        button_icon: '',
        destination: '',
        destination_team_id: '',
        active: 1,
      } : {
        ...props.announcement.announcement,
        start_date: moment(props.announcement.announcement.start_date).format("yyyy-MM-DD"),
        end_date: moment(props.announcement.announcement.end_date).format("yyyy-MM-DD")
      }
    }
  }
  AnnouncementTitle = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        title: e.target.value
      }
    }))
  }
  AnnouncementDescription = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        description: e.target.value
      }
    }))
  }
  GoToScreen = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        go_to_screen: e.target.value
      }
    }))
  }
  StartDate = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        start_date: e.target.value
      }
    }))
  }
  EndDate = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        end_date: e.target.value
      }
    }))
  }
  ButtonText = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        button_text: e.target.value
      }
    }))
  }
  Destination = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        destination: e.target.value
      }
    }))
  }
  DestinationTeamId = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        destination_team_id: e.target.value
      }
    }))
  }
  ButtonText = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        button_text: e.target.value
      }
    }))
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      announcement: {
        ...prevState.announcement,
        active: e.target.value
      }
    }))
  }
  ImageURL = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        announcement: {
          ...prevState.announcement,
          image_url: e.target.files[0]
        }
      }))
    }
  }
  ButtonIcon = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        announcement: {
          ...prevState.announcement,
          button_icon: e.target.files[0]
        }
      }))
    }
  }
  zoomToggle = () => {
    this.setState({
      zoom: !this.state.zoom,
    })
  }
}

export default AnnouncementCreateController
