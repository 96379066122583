import React from 'react'
import UserTeamActivityCreateController from './UserTeamActivityCreateController'
import { Button } from './../../../components/buttons/Button';
import { Input } from './../../../components/inputs/index'
import SelectModal from './../../../components/selectModal/SelectModal';
class UserTeamActivityCreate extends UserTeamActivityCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit User Team Activity' : 'Add User Team Activity'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }
                            <div>

                                <SelectModal
                                    routeChangeName="user_favourite_team"
                                    user_favourite_teams={this.props.user_favourite_teams}
                                    // searching={this.props.searchTeam}
                                    currentPage={this.props.currentPage}
                                    getItems={this.props.getUserFavouriteTeams}
                                    rowCount={this.props.rowCount}
                                    handleProcess={(user_favourite_team_id) => this.UserFavoruiteTeamId(user_favourite_team_id)
                                    }
                                    LoadMore={this.props.loadMore}
                                    itemName={this.props.isEdit ?
                                        this.props.user_team_activity.user_team_activity.UserFavTeam?.User?.user_name + " => " + this.props.user_team_activity.user_team_activity.UserFavTeam?.Team?.name :
                                        "*** Choose User Favourite Team ***"}
                                />

                                <Input type="number" name="for_team_watch_count" placeholder="Enter Watch Count..." value={this.state.user_team_activity.for_team_watch_count} onChange={this.ForTeamWatchCount} required />

                                <Input type="number" name="for_team_quizzes_count" placeholder="Enter Quiz Count..." value={this.state.user_team_activity.for_team_quizzes_count} onChange={this.ForTeamQuizCount} required />

                                <select name="active" className="select" value={this.state.user_team_activity.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                            </div>
                            <br />

                            <Button type="button" id="submit-btn" caption={this.props.isEdit ? 'Update' : "Create"} className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.user_team_activity)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserTeamActivityCreate