import { Component } from 'react';

export class LeagueCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            league: props.isEdit === false ? {
                name: '',
                description: '',
                country_id: '',
                logo_url: '',
                active: 1
            } : props.league.league
        }
        this.onChangeImage = this.onChangeImage.bind(this)

    }

    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            league: {
                ...prevState.league,
                name: e.target.value
            }
        }))
    }
    handleInputDescription = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            league: {
                ...prevState.league,
                description: e.target.value
            }
        }))
    }
    CountryIdProcess = (country_id) => {
        this.setState((prevState) => ({
            ...prevState,
            league: {
                ...prevState.league,
                country_id: country_id,
            }
        }))
    }

    processActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            league: {
                ...prevState.league,
                active: e.target.value
            }
        }))
    }
    onChangeImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState((prevState) => ({
                ...prevState,
                league: {
                    ...prevState.league,
                    logo_url: e.target.files[0]
                }
            }))
        }
    }



}

export default LeagueCreateController;
