import React from 'react'
import { MainContainer, Header, Body } from '../../PagesElement'
import { PaymentTypeCreate } from '../create/PaymentTypeCreate'
import { Button } from '../../../components/buttons/Button'
import { PaymentTypeListController } from './PaymentTypeListController'
import PaymentTypeAction from './../../../redux/action/payment_type_action';
import { connect } from 'react-redux';
import Pagination from './../../../components/pagination/Pagination';
import Modal from './../../../components/modal/Modal';
import { PageLoader } from './../../../components/pageLoader/PageLoader';
import Alert from './../../../components/alert/Alert';

class PaymentTypeList extends PaymentTypeListController {
    render() {
        let createPermission = false;
        let updatePermission = false;
        let deletePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Payment Type' && permission.functions === 'create') {
                return createPermission = true;
            }
            if (permission.feature === 'Payment Type' && permission.functions === 'update') {
                return updatePermission = true;
            }
            if (permission.feature === 'Payment Type' && permission.functions === 'delete') {
                return deletePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Payment Types</h4>
                    <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
                        <div className="input-group">
                            <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
                            <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    {
                        createPermission &&
                        <Button caption="Add Payment Type" className="btn-primary add-button" btnFun={this.showModal} />
                    }
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Logo</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                                {
                                    (updatePermission || deletePermission) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.payment_types.map((payment_type, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <img src={payment_type.logo_url} alt="choosed images" />
                                            </td>
                                            <td>{payment_type.name}</td>
                                            <td>{payment_type.description}</td>
                                            <td>{payment_type.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal deleteItem={() => this.props.deletePaymentType(payment_type.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(payment_type)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getPaymentTypes(pageNumber, this.props.row_count) : this.props.searchItem(this.props.text, pageNumber) }} />
                    {
                        this.state.show &&
                        <PaymentTypeCreate
                            hideModal={this.hideModal}
                            isEdit={this.state.isEdit}
                            payment_type={this.state.payment_type}
                            createOrUpdate={this.createOrUpdate}
                            requiredError={this.state.requiredError}
                        />
                    }
                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        payment_types: state.payment_type.payment_types,
        error: state.payment_type.error,
        success: state.payment_type.success,
        currentPage: state.payment_type.currentPage,
        totalCount: state.payment_type.totalCount,
        totalCountPerPage: state.payment_type.totalCountPerPage,
        text: state.payment_type.text,
        row_count: state.payment_type.row_count,
        loading: state.payment_type.loading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentTypes: (pageNumber, row_count) => dispatch(PaymentTypeAction.getPaymentTypes(pageNumber, row_count)),
        createPaymentType: (payment_type) => dispatch(PaymentTypeAction.createPaymentType(payment_type)),
        updatePaymentType: (payment_type) => dispatch(PaymentTypeAction.updatePaymentType(payment_type)),
        deletePaymentType: (id) => dispatch(PaymentTypeAction.deletePaymentType(id)),
        searchItem: (text, pageNumber) => dispatch(PaymentTypeAction.searchItem(text, pageNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypeList)
