const UserType = {
    GET_USERS: "GET_UERS",
    ADD_USER: "ADD_USER",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    USER_ERROR: "USER_ERROR",
    USER_SUCCESS: "USER_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    LOADING: "LOADING",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_WITH_ACTIVE: "SEARCH_WITH_ACTIVE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    ERROR: "ERROR"
}
export default UserType