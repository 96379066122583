
import RolePermissionType from './../type/role_permission_type';
const initialState = {
    role_permissions: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: 0,
    roleId: null,
    permissionId: null,
    totalCountPerPage: null,
    row_count: 1,
    loading: false,
}

const rolePermissionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case RolePermissionType.GET_ROLE_PERMISSIONS:
            return {
                ...state,
                loading: false,
                role_permissions: actions.payload
            }
        case RolePermissionType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case RolePermissionType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case RolePermissionType.SEARCH_WITH_ROLE:
            return {
                ...state,
                roleId: actions.payload
            }
        case RolePermissionType.SEARCH_WITH_PERMISSION:
            return {
                ...state,
                permissionId: actions.payload
            }
        case RolePermissionType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case RolePermissionType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case RolePermissionType.ADD_ROLE_PERMISSION:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                // role_permissions: state.role_permissions.concat(actions.payload)
                role_permissions: [
                    actions.payload,
                    ...state.role_permissions,
                ]
            }
        case RolePermissionType.UPDATE_ROLE_PERMISSION:
            const updateRolePermissions = state.role_permissions.filter(role_permission => role_permission.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                role_permissions: [
                    actions.payload,
                    ...updateRolePermissions,
                ]
            }
        case RolePermissionType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case RolePermissionType.DELETE_ROLE_PERMISSION:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                role_permissions: state.role_permissions.filter(role_permission => role_permission.id !== actions.payload)
            }
        case RolePermissionType.ROLE_PERMISSION_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case RolePermissionType.ROLE_PERMISSION_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}

export default rolePermissionReducer;