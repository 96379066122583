import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class ActivityListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isEdit: false,
      initialPage: 1,
      action: '',
      type: '',
      quiz: {},
      activity_logs: {},
      userRowCount: CONSTANTS.ROW_COUNT,
      typeRowCount: CONSTANTS.ROW_COUNT,
      rowCount: CONSTANTS.ROW_COUNT,
      requiredError: '',
      activity_types: [
        { id: 1, type: "OVERALL" },
        { id: 2, type: "LIVE_STREAM" },
        { id: 3, type: "HIGHLIGHT" },
        { id: 4, type: "LIVE_SCORE" },
        { id: 5, type: "QUIZ" },
        { id: 6, type: "SPECILA_QUIZ" },
        { id: 7, type: "ADS" },
      ],
      activity_actions: [
        { id: 1, action: "SURFING" },
        { id: 2, action: "REGISTER" },
        { id: 3, action: "LOGIN" },
        { id: 4, action: "PUBLIC_WATCH" },
        { id: 5, action: "GROUP_WATCH" },
        { id: 6, action: "SELF_WATCH" },
        { id: 7, action: "FAVOURITE_TEAM_WATCH" },
        { id: 8, action: "PLAY" },
      ]
    }
  }
  componentDidMount = () => {

    this.loadMore();
    this.props.getAllActivityLogs(this.state.initialPage, this.state.rowCount);
  }

  handleSelectType = (type) => {
    let selectedType = "";
    if (type === 1) {
      selectedType = 'OVERALL';
      this.setState({
        activity_actions: [
          { id: 1, action: "SURFING" },
          { id: 2, action: "REGISTER" },
          { id: 3, action: "LOGIN" },
        ]
      })
    }
    else if (type === 2) {
      selectedType = "LIVE_STREAM";
      this.setState({
        activity_actions: [
          { id: 4, action: "PUBLIC_WATCH" },
          { id: 5, action: "GROUP_WATCH" },
          { id: 6, action: "SELF_WATCH" },
        ]
      })
    }
    else if (type === 3) {
      selectedType = "HIGHTLIGHT";
      this.setState({
        activity_actions: [
          { id: 6, action: "SELF_WATCH" },
        ]
      })
    }
    else if (type === 4) {
      selectedType = "LIVE_SCORE";
      this.setState({
        activity_actions: [
          { id: 1, action: "SURFING" },
        ]
      })
    }
    else if (type === 5) {
      selectedType = "QUIZ";
      this.setState({
        activity_actions: [
          { id: 8, action: "PLAY" },
        ]
      })
    }
    else if (type === 6) {
      selectedType = "SPECIAL_QUIZ";
      this.setState({
        activity_actions: [
          { id: 8, action: "PLAY" },
        ]
      })
    }
    else if (type === 7) {
      selectedType = "ADS";
      this.setState({
        activity_actions: [
          { id: 8, action: "PLAY" },
        ]
      })
    }
    this.setState({
      type: selectedType,
      action: ''
    }, () => {
      this.props.searchActivityLogs(this.state.type, this.state.action, '', '', this.state.initialPage)
    })

  }
  handleSelectAction = (action) => {

    let selectedAction = "";
    if (action === 1) {
      selectedAction = 'SURFING';
    }
    else if (action === 2) {
      selectedAction = "REGISTER";
    }
    else if (action === 3) {
      selectedAction = "LOGIN";
    }
    else if (action === 4) {
      selectedAction = "PUBLIC_WATCH";
    }
    else if (action === 5) {
      selectedAction = "GROUP_WATCH";
    }
    else if (action === 6) {
      selectedAction = "SELF_WATCH";
    }
    else if (action === 7) {
      selectedAction = "FAVOURITE_TEAM_WATCH";
    }
    else if (action === 8) {
      selectedAction = "PLAY";
    }
    this.setState({
      // type: '',
      action: selectedAction,
    }, () => {
      this.props.searchActivityLogs(this.state.type, this.state.action, '', '', this.state.initialPage)
    })

  }
  stopSearching = () => {
    this.setState({
      action: '',
      type: ''
    })
    this.props.getAllActivityLogs(this.state.initialPage, this.state.rowCount)
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchActivityLogs(this.state.type, this.state.action, '', '', this.state.initialPage)
  }
  loadMore = () => {
    this.setState({
      userRowCount: this.state.userRowCount + 6
    })
    this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default ActivityListController