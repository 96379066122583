import React from 'react'
import { Button } from './../../../components/buttons/Button';
import { Input } from '../../../components/inputs/Input'
import QuestionCreateConroller from './QuestionCreateConroller';
class QuestionCreate extends QuestionCreateConroller {
    render() {

        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Question' : 'Add Question'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {
                                this.props.requiredError && <p className="requiredError">*{this.props.requiredError}</p>
                            }
                            <div>


                                <select name="quiz_type" className="select" value={this.state.question.quiz_type} onChange={this.handleInputType} required>
                                    <option>*** Select Quiz Type ***</option>
                                    <option value="1">Match</option>
                                    <option value="2">Season</option>
                                    <option value="3">Special Match</option>
                                    <option value="4">Future</option>
                                </select>

                                {
                                    this.state.question.quiz_type == 1 && <select name="question_type" className="select" value={this.state.question.question_type} onChange={this.handleInputQuestionType} required>
                                        <option>*** Select Question Type ***</option>
                                        <option value="1">Normal</option>
                                        <option value="2">Goal Scorer</option>
                                        <option value="3">Yellow Card</option>
                                        <option value="4">Red Card</option>
                                    </select>
                                }
                                {
                                    this.state.question.quiz_type == 4 && <select name="future_type" className="select" value={this.state.question.future_type} onChange={this.handleInputFutureType} required>
                                        <option>*** Select Future Type ***</option>
                                        <option value="1">Player</option>
                                        <option value="2">Team</option>
                                        <option value="3">Manager</option>
                                    </select>
                                }
                                <Input type="text" placeholder="Enter Question" name="question" value={this.state.question.question} onChange={this.handleInput} required />

                                <Input type="text" placeholder="Enter Reward Coin" name="win_reward" value={this.state.question.win_reward} onChange={this.handleInputReward} required />

                                <Input type="text" placeholder="Enter Redeem Points" name="redeem_points" value={this.state.question.redeem_points} onChange={this.handleInputRedeemPoints} required />

                                <select name="active" className="select" value={this.state.question.active} onChange={this.handleSelect} required>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>

                            </div>
                            <br />
                            <Button caption={this.props.isEdit ? 'Update' : 'Create'} type="button" id="submit-btn" className="btn-secondary" btnFun={() => {
                                this.props.createOrUpdate(this.state.question)
                            }
                            } />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionCreate