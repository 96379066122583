const QuestionType = {
    GET_QUESTIONS: "GET_QUESTIONS",
    QUESTION_TYPE:"QUESTION_TYPE",
    ADD_QUESTION: "ADD_QUESTION",
    UPDATE_QUESTION: "UPDATE_QUESTION",
    DELETE_QUESTION: "DELETE_QUESTION",
    QUESTION_ERROR: "QUESTION_ERROR",
    QUESTION_SUCCESS: "QUESTION_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_WITH_: 'SEARCH_WITH_',
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default QuestionType