import { Component } from 'react'
class CityCreateController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            city: props.isEdit === false ? {
                name: "",
                country_id: "",
                active: 1
            } : props.city.city
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.CountryProcess = this.CountryProcess.bind(this)
    }
    handleInputChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            city: {
                ...prevState.city,
                name: e.target.value
            }
        }))
    }
    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            city: {
                ...prevState.city,
                active: e.target.value
            }
        }))
    }
    CountryProcess = (country_id) => {
        this.setState((prevState) => ({
            ...prevState,
            city: {
                ...prevState.city,
                country_id: country_id
            }
        }))
    }
}
export {
    CityCreateController
}