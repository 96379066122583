import { Component } from 'react';
import CONSTANTS from './../../../redux/constants';
import axios from 'axios'

class TeamCreateController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            team: props.isEdit === false ? {
                name: '',
                code: '',
                logo_url: '',
                stadium_id: '',
                country_id: '',
                league_id: [],
                active: 1,
                id_from_fotmob: ''
            } : props.team.team,

            selectedValue: props.selectedLeague.map(team => ({ label: team.name, value: team.id })),
            options: this.props.leagues.map(League => {
                return { label: League.name, value: League.id }
            })

        }
    }

    componentDidMount = () => {
        this.getLeagues();

    }
    selectChange = (leagues) => {
        this.props.selectChange(leagues);
        this.setState(prevState => ({ ...prevState, selectedValue: leagues }))
    }


    onChange = (e) => {
        // let prevLeague = this.state.team.leagues.map(league => { return { label: league.name, value: league.id } })
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                // league_id: Array.isArray(e) ? e.map(x => x.value) : []
                league_id: e.map(league => league.value)
            }
        }))
    }

    handleInputName = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                name: e.target.value
            }
        }))
    }
    handleInputCode = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                code: e.target.value
            }
        }))
    }
    StadiumProcess = (stadium_id) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                stadium_id: stadium_id
            }
        }))
    }
    CountryProcess = (country_id) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                country_id: country_id
            }
        }))
    }

    LeagueProcess = (league_id) => {
        this.setState((prevState) => ({
            team: {
                ...prevState.team,
                league_id: league_id
            }
        }))
    }
    processActive = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                active: e.target.value
            }
        }))
    }
    handleInputIdFromFotmob = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            team: {
                ...prevState.team,
                id_from_fotmob: e.target.value
            }
        }))
    }
    onChangeImage = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            this.setState((prevState) => ({
                ...prevState,
                team: {
                    ...prevState.team,
                    logo_url: e.target.files[0]
                }
            }))
        }
    }

    //for migration
    getLeagues = async () => {
        await axios.post(`${CONSTANTS.BASE_URL}migration/all-leagues`).then((response) => {
            this.setState({
                leagues: response.data.popular
            })
        })
    }
}
export {
    TeamCreateController
}