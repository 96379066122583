
import LeagueType from './../type/league_type';

const initialState = {
    leagues: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    loading: false,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    countryId:null,
}
const leagueReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case LeagueType.GET_LEAGUES:
            return {
                ...state,
                loading:false,
                leagues: actions.payload,
            }
        case LeagueType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload,
            }
        case LeagueType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload,
            }
        case LeagueType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload,
            }
        case LeagueType.SEARCH_WITH_COUNTRY:
            return {
                ...state,
                countryId: actions.payload,
            }
        case LeagueType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case LeagueType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case LeagueType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case LeagueType.ADD_LEAGUE:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                leagues: [
                    actions.payload,
                    ...state.leagues,
                ]
            }
        case LeagueType.UPDATE_LEAGUE:
            const updateLeague = state.leagues.filter(league => league.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                leagues: [
                    actions.payload,
                    ...updateLeague,
                ]
            }
        case LeagueType.DELETE_LEAGUE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                leagues: state.leagues.filter(league => league.id !== actions.payload)
            }
        case LeagueType.LEAGUE_ERROR:
            return { ...state, loading: false, error: actions.payload }
        case LeagueType.LEAGUE_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default leagueReducer