import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import CONSTANTS from './../../redux/constants';
class Paginate extends Component {


    render() {

        let startItem = this.props.activePage > 1 ? ((this.props.activePage - 1) * 10) + 1 : 1;
        let endItem = this.props.activePage > 1 ? ((this.props.activePage - 1) * 10) + this.props.totalCountPerPage : this.props.totalCountPerPage;

        return (
            <div className="pagination-container">

                <div className="total_count">
                    {
                        !this.props?.hideShowingTotalCount &&
                        <>
                            {
                                this.props.totalItemsCount > 0 ?
                                    <p>
                                        Showing &nbsp;
                                        <span>
                                            {startItem}
                                        </span>
                                        &nbsp;~&nbsp;
                                        <span>
                                            {endItem}
                                        </span> of
                                        <span>&nbsp;{this.props.totalItemsCount}</span>
                                    </p>
                                    :
                                    <p>
                                        There is no items!
                                    </p>
                            }
                        </>

                    }
                </div>

                {
                    this.props.totalItemsCount > CONSTANTS.ROW_COUNT &&
                    <Pagination
                        activePage={this.props.activePage}
                        itemsCountPerPage={CONSTANTS.ROW_COUNT}
                        totalItemsCount={this.props.totalItemsCount}
                        onChange={(pageNumber) => this.props.paginate(pageNumber)}
                        nextPageText={"Next"}
                        pageRangeDisplayed={3}
                        prevPageText={"Prev"}
                        itemClass='item'
                        linkClass="link"
                    />
                }


            </div >
        )
    }
}

export default Paginate;

