import React, { Component } from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { PageLoader } from './../pageLoader/PageLoader';
class SubProtectedRoutes extends Component {
  constructor(props) {
    super(props)
    const routeNames = [];
    const permissions = localStorage.getItem('permissions')
    let permissionData = JSON.parse(permissions)
    permissionData?.map((data) => {
      if (data.functions === "list") {
        if (!routeNames.includes(data.feature)) {
          routeNames.push(data.feature)
        }
      }
    })

    this.state = {
      data: routeNames
    }
  }
  render() {
    return (
      this.state.data?.includes(this.props.routeName) ? <Outlet /> : <Navigate to="/unauthorized" replace />
    )
  }
}

export default SubProtectedRoutes
