import React from 'react'
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import { OperatorCreateController } from './OperatorCreateController';
import '../../modal/modal.css'
import SelectModal from './../../../components/selectModal/SelectModal';

class OperatorCreate extends OperatorCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Operator' : 'Add Operator'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form atuocomplete="off">
                            {
                                this.props.requiredError && <p className="requiredError">* {this.props.requiredError}
                                </p>
                            }
                            <div style={{ columnCount: '2' }}>
                                <Input type="text" name="full_name" placeholder="Enter Fullname..." onChange={this.fullnameProcess} value={this.state.operator.full_name} required />

                                <Input type="text" name="user_name" placeholder="Enter Username..." onChange={this.usernameProcess} value={this.state.operator.user_name} required />

                                <Input type="text" name="email" placeholder="Enter Email..." onChange={this.emailProcess} value={this.state.operator.email} required />

                                

                                <Input type="text" name="phone_no" placeholder="Enter Phone Number..."
                                    style={{ marginTop: this.props.isEdit && "15px" }}
                                    onChange={this.phoneProcess} value={this.state.operator.phone_no} required />

                                {
                                    !this.props.isEdit &&
                                    <Input type="text" name="password" placeholder="Enter Password..." onChange={this.passwordProcess} value={this.state.operator.password} required />
                                }


                                <SelectModal
                                    items={this.props.roles}
                                    routeChangeName="role"
                                    handleProcess={(role_id) => this.handleSelectRole(role_id)}
                                    loadMore={this.props.loadMore}
                                    itemName={this.props.isEdit ? this.props.operator.operator.Role.name : "*** Choose Role ***"}
                                    searching={this.props.searchRole}
                                    currentPage={this.props.currentRolePage}
                                    getItems={this.props.getAllRoles}
                                    rowCount={this.props.roleRowCount}
                                />


                                <select name="active" className="select" value={this.state.operator.active} onChange={this.handleSelect} required  style={{ marginBottom:'10px' }}>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                                
                                <Button caption={this.props.isEdit ? 'Update' : 'Create'} id="submit-btn" type="button" className="btn-secondary" btnFun={() => this.props.createOrUpdate(this.state.operator)}
                                />

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default OperatorCreate