import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';
import { Fraction } from 'fractional'
class OddsCreateController extends Component {
  constructor(props) {
    super(props);
    // country: props.isEdit === false ? {
    //   flag: '',
    //   name: '',
    //   country_code: '',
    //   active: 1,
    // } : props.country.country
    this.state = {
      // teams: props.teams,
      initialPage: 1,
      rowCount: CONSTANTS.ROW_COUNT,
      // questionType: this.props.quizInfo.match_id_from_fotmob === null ? 2 : 1,
      odds_rate: props.isEdit === false ? {
        active: 1,
        odds_fractional: '',
        odds_american: '',
        odds_decimal: '',
        main_odds_type: 1,
        type_for_match: '',
      } : props.odds_rate.odds_rate
    }
  }
  componentDidMount = () => {
    // this.loadMore();
    // this.props.getQuestions(this.state.questionType, this.state.initialPage, this.state.rowCount)
  }

  handleInputChangeAmericanOdds = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      odds_rate: {
        ...prevState.odds_rate,
        odds_american: e.target.value
      }
    }), () => {


      let odds_american = Number(this.state.odds_rate.odds_american);

      let decimal;
      let fractional;

      let fra;
      if (odds_american > 0) {
        decimal = 1 + (odds_american / 100)
        fra = odds_american / 100
        fractional = fra.toFixed(2)

      }
      else {
        decimal = 1 - (100 / odds_american)
        fra = -(100 / odds_american)
        fractional = fra.toFixed(4)
      }

      if (fractional > 0) {
        let value = new Fraction(fractional);
        let fraction = `${value.numerator}/${value.denominator}`
        this.setState((prevState) => ({
          ...prevState,
          odds_rate: {
            ...prevState.odds_rate,
            odds_decimal: decimal.toFixed(2),
            odds_fractional: fraction,
          }
        }))
      }
      else {
        this.setState((prevState) => ({
          ...prevState,
          odds_rate: {
            ...prevState.odds_rate,
            odds_decimal: '',
            odds_fractional: '',
          }
        }))
      }

    })
  }
  handleInputChangeFrantional = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      odds_rate: {
        ...prevState.odds_rate,
        odds_fractional: e.target.value
      }
    })
      // , () => {

      //   let dec = this.state.odds_rate.odds_fractional.split('/')
      //   let decimal = parseInt(dec[0], 10) / parseInt(dec[1], 10);
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     odds_rate: {
      //       ...prevState.odds_rate,
      //       odds_decimal: decimal.toFixed(2)
      //     }
      //   }))
      // }
    )

  }
  handleInputChangeDecimal = (e) => {
    if (Math.sign(this.state.odds_rate.odds_american) !== "NaN") {
      this.setState((prevState) => ({
        ...prevState,
        odds_rate: {
          ...prevState.odds_rate,
          odds_decimal: e.target.value
        }
      }))
    }
  }

  // handleSelectOddsType = (typeId) => {
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     odds: {
  //       ...prevState.odds,
  //       main_odds_type: typeId
  //     }
  //   }))
  // }
  handleSelectMatchType = (matchType) => {
    this.setState((prevState) => ({
      ...prevState,
      odds_rate: {
        ...prevState.odds_rate,
        type_for_match: matchType
      }
    }))
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      odds_rate: {
        ...prevState.odds_rate,
        active: e.target.value
      }
    }))
  }
  handleSelectMainOddsType = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      odds_rate: {
        ...prevState.odds_rate,
        main_odds_type: e.target.value
      }
    }))
  }

}

export default OddsCreateController
