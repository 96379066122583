const MatchType = {
    GET_MATCHES: "GET_MATCHES",
    ADD_MATCH: 'ADD_MATCH',
    UPDATE_MATCH: 'UPDATE_MATCH',
    DELETE_MATCH: 'DELETE_MATCH',
    MATCH_ERROR: "MATCH_ERROR",
    MATCH_SUCCESS: "MATCH_SUCCESS",
    // GET_PAGINATION: "GET_PAGINATION",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    SEARCH_ITEM: "SEARCH_ITEM",
    SEARCH_DATA: "SEARCH_DATA",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_WITH_LEAGUE: "SEARCH_WITH_LEAGUE",
    SEARCH_ITEM_S: "SEARCH_ITEM_S",
    SEARCH_ITEM_E: "SEARCH_ITEM_E",
    SEARCH_WITH_DATE: "SEARCH_WITH_DATE",
    LOADING: "LOADING",
    ERROR: 'ERROR'
}
export default MatchType