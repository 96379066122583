import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../../redux/constants';
class LineupController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLineUp: true,
      showBench: false,
      showMatchFacts: false,
      homeTeamPlayer: [],
      homeTeam: {},
      awayTeam: {},
      homeTeamLineup: {},
      awayTeamLineup: {},
      awayTeamPlayer: [],
      lineups: {},
      matchFacts: {}
    }
  }
  componentDidMount = () => {
    const matchId = this.props.match.params.matchId
    axios.post(`${CONSTANTS.BASE_URL}livescores/matchDetail/${matchId}`).then((response) => {
      this.setState({
        matchFacts: response.data?.content?.matchFacts && response.data?.content?.matchFacts,
        lineups: (response.data.content?.lineup !== false) && response.data.content?.lineup,
        homeTeam: (response.data.content?.lineup !== false) && response.data?.content?.lineup?.lineup[0]?.players,
        awayTeam: (response.data.content?.lineup !== false) && response.data?.content?.lineup?.lineup[1]?.players,
        homeTeamLineup: (response.data.content?.lineup !== false) && response.data?.content?.lineup?.lineup[0],
        awayTeamLineup: (response.data.content?.lineup !== false) && response.data?.content?.lineup?.lineup[1],
      })
      
      this.getHomeTeamLineup();
    })
  }
  getHomeTeamLineup = () => {
    Object.values(this.state.homeTeam).map((data, index) => {
      return Object.values(data).map((player, iD) => {
        return this.state.homeTeamPlayer.push(player)
      })
    })
  }
  getAwayTeamLineUp = () => {
    Object.values(this.state.awayTeam).map((data, index) => {
      return Object.values(data).map((player, iD) => {
        return this.state.awayTeamPlayer.push(player)
      })
    })
  }
  showLineUp = () => {
    this.setState({
      showLineUp: true,
      showBench: false,
      showMatchFacts: false,

    })
  }
  showBench = () => {
    this.setState({
      showLineUp: false,
      showBench: true,
      showMatchFacts: false,

    })
  }
  showMatchFacts = () => {
    this.setState({
      showLineUp: false,
      showBench: false,
      showMatchFacts: true,
    })
  }
}

export default LineupController