import React from 'react'
import VersionCheckListController from './VersionCheckListController';
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, connect } from '../../../components/ImportsFile'
import VersionCheckAction from '../../../redux/action/version_check_action'
import VersionCheckCreate from './../create/VersionCheckCreate';
import Moment from 'react-moment';

class VersionCheckList extends VersionCheckListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Version Check' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Version Check' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Version Check' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>Version Check</h4>

          <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
            <div className="input-group">
              <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
              <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>

          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Version Check" className="btn-primary add-button" />
          }
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Version Name</th>
                <th>Version Code</th>
                <th>Force Update</th>
                <th>Active</th>
                {
                  (updatePermission || deletePermission) &&
                  <th>Actions</th>
                }
              </tr>
            </thead>

            <tbody>
              {
                this.props.version_checks.map((version_check, index) => {
                  // console.log('version_check', version_check)
                  return (
                    <tr key={index}>
                      <td><Moment format="D MMM YYYY">{version_check.created_at}</Moment></td>
                      <td>{version_check.version_name}</td>
                      <td>{version_check.version_code}</td>
                      <td>{version_check.force_update === 1 ? 'Yes' : 'No'}</td>
                      <td>{version_check.active === 1 ? 'Active' : 'Inactive'}</td>
                      <td>
                        <Modal deleteItem={() => this.props.deleteVersionCheck(version_check.id)} deletePermission={deletePermission} updatePermission={updatePermission} editItem={() => this.handleEdit(version_check)} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>

          </table>

          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => { !this.props.text ? this.props.getCountries(pageNumber, this.props.row_count) : this.props.searchItem(this.state.text, pageNumber) }} />

          {
            this.state.show && <VersionCheckCreate hideModal={this.hideModal} version_check={this.state.version_check} isEdit={this.state.isEdit} createOrUpdate={this.createOrUpdate} requiredError={this.state.requiredError} />
          }

        </Body>
      </MainContainer>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    version_checks: state.version_check.version_checks,
    error: state.version_check.error,
    success: state.version_check.success,
    currentPage: state.version_check.currentPage,
    totalCount: state.version_check.totalCount,
    perPage: state.version_check.perPage,
    text: state.version_check.text,
    row_count: state.version_check.row_count,
    loading: state.version_check.loading,
    totalCountPerPage: state.version_check.totalCountPerPage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVersionChecks: (pageNumber, row_count) => dispatch(VersionCheckAction.getVersionChecks(pageNumber, row_count)),
    createVersionCheck: (version_check) => dispatch(VersionCheckAction.createVersionCheck(version_check)),
    updateVersionCheck: (version_check) => dispatch(VersionCheckAction.updateVersionCheck(version_check)),
    deleteVersionCheck: (id) => dispatch(VersionCheckAction.deleteVersionCheck(id)),
    searchItem: (text, pageNumber) => dispatch(VersionCheckAction.searchItem(text, pageNumber))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionCheckList)
