import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class TheQuizzerListController extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialPage: 1,
      loading: true,
      userId: '',
      rowCount: CONSTANTS.ROW_COUNT,
      userRowCount: CONSTANTS.ROW_COUNT,
      sort_by: 'quiz_points'
    }
  }
  componentDidMount = () => {
    this.loadMore();
    this.props.getWallets(this.state.sort_by, this.state.initialPage, this.state.rowCount);
  }
  handleSelectUser = (userId) => {
    this.setState({
      userId: userId
    }, () => {
      this.props.searchItem(this.state.userId, this.state.initialPage)
    })
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.searchItem(this.state.userId, this.state.initialPage)
  }
  loadMore = () => {
    this.props.getAllUsers(this.state.initialPage, this.state.userRowCount);
    this.setState({
      userRowCount: this.state.userRowCount + 6
    })
  }
  stopSearching = () => {
    this.setState({
      userId: ''
    }, () => {
      this.props.getAllUsers(this.state.initialPage, this.state.rowCount)
      this.props.getWallets(this.state.sort_by, this.state.initialPage, this.state.rowCount);
    })
  }
}

export default TheQuizzerListController
