
import CONSTANTS from './../constants';
import axios from 'axios'
import AccessToMatchType from './../type/access_to_match_type';

export const Error = (data) => {
    return {
        type: AccessToMatchType.ACCESS_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: AccessToMatchType.ACCESS_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: AccessToMatchType.LOADING, payload: status });
    }
}
const getAccessToMatches = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}access-to-matches/list?page_at=${pageNumber}&row_count=${row_count}`).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: AccessToMatchType.GET_ACCESSES,
                    payload: response.data.data
                })
                dispatch({
                    type: AccessToMatchType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: AccessToMatchType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: AccessToMatchType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: AccessToMatchType.ROW_COUNT, payload: row_count
                })
            } else {
                let message = "Failed"
                dispatch({
                    type: AccessToMatchType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: AccessToMatchType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
                
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const createAccessToMatch = (access_to_match) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.post(`${CONSTANTS.BASE_URL}access-to-matches/create`, access_to_match).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: AccessToMatchType.ADD_ACCESS,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateAccessToMatch = (access_to_match) => {
    return (dispatch) => {
        dispatch(loading(true))
        return axios.put(`${CONSTANTS.BASE_URL}access-to-matches/update`, access_to_match).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: AccessToMatchType.UPDATE_ACCESS,
                    payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const deleteAccessToMatch = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}access-to-matches/delete`, { data: { id: id } }).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: AccessToMatchType.DELETE_ACCESS,
                    payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed"
                dispatch({
                    type: AccessToMatchType.ERROR,
                    payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: AccessToMatchType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch((err) => {
            const errorPayload = {};
             if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchAccess = (userId, matchId, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}access-to-matches/list?match_id=${matchId}&user_id=${userId}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: AccessToMatchType.GET_ACCESSES, payload: response.data.data
                })
                dispatch({
                    type: AccessToMatchType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: AccessToMatchType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page
                })
                dispatch({
                    type: AccessToMatchType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: AccessToMatchType.SEARCH_WITH_USER,
                    payload: userId
                })
                dispatch({
                    type: AccessToMatchType.SEARCH_WITH_MATCH,
                    payload: matchId
                })
            }
            
        })
    }
}

const AccessToMatchAction = {
    getAccessToMatches,
    createAccessToMatch,
    updateAccessToMatch,
    deleteAccessToMatch,
    searchAccess,
    loading
}
export default AccessToMatchAction