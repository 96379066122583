import styled from "styled-components";
const MainContainer = styled.div`
    width:95%;
    height:100%;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
    h4{
        font-size:26px;
        font-family: "Roboto", sans-serif;
        background: -webkit-linear-gradient(#202020, #FAD230);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .score_date{
        display:flex;
        flex-direction:row;
        align-items:center;
        min-width:300px;
        margin-right:7%;
        justify-content:right;
        p{
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom:0px;
            padding-right:10px;
            font-weight:bold;
        }

    }
    .livescore_date{
        font-size:20px;
        width:32px;
        border:none;
        padding:5px;
        outline:none;
        
    }
    
    .livescore_date::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
    .livescore_date:active{
        border:none;
        outline:none;
        box-shadow:none;
    }
    .search{
        display:flex;
        padding-bottom:0px;
        margin-bottom:0px;
        width:65%;
        justify-content:center;
    }     
`

const Body = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:90%;
    padding-top:20px;
`
const Sidebar = styled.div`
    flex:2;
    // height:100%;
    // display:flex;
    justify-content:center;
    align-items:center;
    padding:0px 10px;
    flex-direction:column;
    .search-form{
        padding-right:5px;
    }
    .items{
        height: 60vh;
        padding-right:5px;
        overflow-y: scroll;
        ::-webkit-scrollbar{
            width:3px;
        }
        :hover::-webkit-scrollbar-thumb{
            background-image: linear-gradient(#202020, #fad230);
            border-radius: 3px;
        }
    }
    a{
        text-decoration:none;
        color:#202020;
    }
    h5{
        font-size:24px;
        float:left;
        font-family: "Roboto", sans-serif;
        background: -webkit-linear-gradient(#202020, #FAD230);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    p{
        width:100%;
        padding:5px 18px;
        font-size:14px;
        background:transparent;
        border:1px solid #202020;
        border-radius:5px;
        color:#202020;
        cursor:pointer;
        img{
            width:35px;
            padding-right:6px;
        }
    }
    p:hover{
        background:#202020;
        color:#fad230;
    }
    p:hover a{
        color:#fad230;
    }
    p.active{
        background:#202020;
    }
    p.active a{
        color:#fad230;
    }
`
const Main = styled.div`
    flex:4;
    height:100%;
    display:flex;
    margin:0px auto;
    padding:0px 20px;
    justify-content:center;
    align-items:center;
    .match_item_list{
        width:100%;
        padding:0px;
        justify-content:center;
        height: 74vh;
        margin-top:14%;
        
        .items{
            height: 70vh;
            overflow-y: scroll;
            ::-webkit-scrollbar{
                width:3px;
            }
            :hover::-webkit-scrollbar-thumb{
                background-image: linear-gradient(#202020, #fad230);
                border-radius: 3px;
            }

            
        }
        a{
            text-decoration:none;
            color:#202020;
        }
        h5{
            // margin-top:20px;
            // padding-top:20px;
            text-align:center;
            font-family: "Roboto", sans-serif;
            background: -webkit-linear-gradient(#202020, #FAD230);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
        }
        .item{
            width:90%;
            display:flex;
            justify-content:center;
            align-items:center;
            margin:10px auto;
            font-size:16px;
            padding:15px;
            border-radius:5px;
            background:#F1F1F1;
            cursor:pointer;
            
            p{
                margin:0px;
            }
        img{
            padding: 0px 7px;
            width:50px;
        }
        
        .left,.center,.right{
            display:flex;
            justify-content:space-between;
        }
        .full-time{
            flex:1;
            p{
                width:40px;
                height:40px;
                padding:13px 10px;
                align-item:center;
                display:flex;
                border-radius: 50%;
                font-size: 12px;
                font-family: "Roboto", sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-weight: bolder;
                line-height: 1;
                color: #202020;
                text-align: center;
                background: red;
                border:2px solid white;
                vertical-align: baseline;
            }

        }
        .left{
            flex:4;
            display:flex;
            justify-content:right;
            align-items:center;
            font-size:14px;
        }
        .center{
            flex:1;
            text-align:center;
            justify-content:center;
            flex-direction: column;
            small{
                font-size:12px;
            }
        }
        .right{
            flex:4;
            display:flex;
            justify-content:left;
            align-items:center;
            font-size:14px;
        }
        }
        
    }

`




export {
    MainContainer, Header, Body, Sidebar, Main
}