import React from 'react'
import { Alert, PageLoader, Modal, Pagination, connect, MainContainer, Header, Body, Button } from '../../../components/ImportsFile'
import SiteConfigsCreate from './../create/SiteConfigsCreate';
import SiteConfigsListController from './SiteConfigsListController';
import SiteConfigsAction from './../../../redux/action/site_configs_action'
import Moment from 'react-moment'
class SiteConfigsList extends SiteConfigsListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Site Config' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Site Config' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Site Config' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>Site Configs</h4>
          {
            !this.props.site_configs?.length > 0 &&
            createPermission && <Button caption="Add Site Configs"
              className="btn-primary add-button"
              btnFun={this.showModal} />
          }
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }

          <table>

            <thead>
              <tr>
                <th>Registration Reward</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Duration Reward</th>
                {
                  (updatePermission || deletePermission) &&
                  <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.site_configs?.map((config, index) => {
                  return (
                    <tr key={index}>
                      <td>{config.registration_reward ? config.registration_reward : '-'}</td>
                      <td>
                        {
                          config.start_date ? <Moment format="ddd,D MMM ,YYYY">{config.start_date}</Moment> : '-'
                        }
                      </td>
                      <td>
                        {
                          config.end_date ? <Moment format="ddd,D MMM ,YYYY">{config.end_date}</Moment> : '-'
                        }
                      </td>
                      <td>{config.duration_reward ? config.duration_reward : '-'}</td>
                      <td>
                        <Modal deleteItem={() => this.props.deleteSiteConfig(config.id)} editItem={() => this.handleEdit(config)} deletePermission={deletePermission} updatePermission={updatePermission} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => {
              this.props.getSiteConfigs(pageNumber, this.props.row_count)
            }} />

          {
            this.state.show && <SiteConfigsCreate
              config={this.state.config}
              hideModal={this.hideModal}
              createOrUpdate={this.createOrUpdate}
              isEdit={this.state.isEdit}
              requiredError={this.state.requiredError}
              checked={this.state.checked}
              handleSwitch={this.handleSwitch}
            />
          }
        </Body>


      </MainContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    site_configs: state.site_config.site_configs,
    error: state.site_config.error,
    success: state.site_config.success,
    currentPage: state.site_config.currentPage,
    totalCount: state.site_config.totalCount,
    totalCountPerPage: state.site_config.totalCountPerPage,
    row_count: state.site_config.row_count,
    loading: state.site_config.loading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSiteConfigs: (pageNumber, row_count) => dispatch(SiteConfigsAction.getSiteConfigs(pageNumber, row_count)),
    updateSiteConfigs: (config) => dispatch(SiteConfigsAction.updateSiteConfigs(config)),
    createSiteConfigs: (config) => dispatch(SiteConfigsAction.createSiteConfigs(config)),
    deleteSiteConfig: (id) => dispatch(SiteConfigsAction.deleteSiteConfig(id)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SiteConfigsList)
