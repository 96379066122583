const PlayerType = {
  GET_PLAYERS: "GET_PLAYERS",
  ADD_PLAYER: "ADD_PLAYER",
  UPDATE_PLAYER: "UPDATE_PLAYER",
  DELETE_PLAYER: "DELETE_PLAYER",
  PLAYER_ERROR: "PLAYER_ERROR",
  PLAYER_SUCCESS: "PLAYER_SUCCESS",
  GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  LOADING: "LOADING",
  SEARCH_ITEM: "SEARCH_ITEM",
  SEARCH_WITH_TEAMID: "SEARCH_WITH_TEAMID",
  SEARCH_WITH_LEAGUEID: "SEARCH_WITH_LEAGUEID",
  SEARCH_WITH_POSITION: "SEARCH_WITH_POSITION",
  TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
  ROW_COUNT: "ROW_COUNT",
  ERROR: "ERROR"
}
export default PlayerType