const CONSTANTS = {
    // testing
    // BASE_URL: "http://localhost:8080/1/",

    // development
    BASE_URL: "https://api.dev.livestreaming.zotefamily.com/1/",

    // production
    // BASE_URL: "https://api.livestreaming.zotefamily.com/1/",


    success: "success",
    failed: "failed",
    ROW_COUNT: 10,
    ALERT_DISPLAY_TIME: 3000,
    ONESIGNAL_APP_ID: "cbf67180-d8ab-4106-9c4c-ccc1273da7a7",

}

export default CONSTANTS;


