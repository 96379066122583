import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class SponsorListController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isEdit: false,
      checked: true,
      initialPage: 1,
      leagueId: '',
      isCurrent: "true",
      quiz_type: '',
      userId: '',
      date: '',
      sponsor: {},
      rowCount: CONSTANTS.ROW_COUNT,
      leagueRowCount: CONSTANTS.ROW_COUNT,
      matchRowCount: CONSTANTS.ROW_COUNT,
      questionRowCount: CONSTANTS.ROW_COUNT,
      requiredError: '',
    }
    this.handleSwitch = this.handleSwitch.bind(this)

  }
  componentDidMount = () => {
    this.loadMoreLeague();
    this.loadMoreMatch();
    this.props.getQuestions(4, this.state.initialPage, this.state.questionRowCount)
    this.props.getSponsors(this.state.initialPage, this.state.rowCount);
  }
  handleSwitch(checked) {
    this.setState({ checked })
  }
  handleSelect = (e) => {
    this.setState({
      isCurrent: e.target.value,
    })
    this.getDateRange();
  }
  // handleSearch = (e) => {
  //   e.preventDefault();

  //   this.props.getQuizzes(this.state.leagueId, this.state.date, this.state.isCurrent, this.state.initialPage, this.state.rowCount)
  // }
  loadMore = () => {
    this.setState({
      rowCount: this.state.rowCount + 6
    })
    this.props.getSponsors(this.state.initialPage, this.state.rowCount);
  }
  loadMoreLeague = () => {
    this.setState({
      leagueRowCount: this.state.leagueRowCount + 6
    })
    this.props.getAllLeagues(this.state.initialPage, this.state.leagueRowCount);
  }
  loadMoreMatch = () => {
    this.setState({
      matchRowCount: this.state.matchRowCount + 6
    })
    this.props.getAllMatches(this.state.initialPage, this.state.matchRowCount);
  }

  handleEdit = (sponsor) => {
    this.setState((prevState) => ({
      ...prevState,
      show: true,
      isEdit: true,
      sponsor: {
        ...prevState.sponsor,
        sponsor
      }
    }))
  }

  createOrUpdate = (data) => {
    let operator = JSON.parse(localStorage.getItem('operator'))
    let sponsor = {
      sponsor_name: data.sponsor_name,
      sponsor_image_url: data.sponsor_image_url,
      active: data.active,
      updated_by: operator.id
    }
    if (data.league_id) {
      sponsor = { ...sponsor, league_id: parseInt(data.league_id) }
    }
    if (data.match_id) {
      sponsor = { ...sponsor, match_id: parseInt(data.match_id) }
    }
    // if (data.question_id) {
    //   sponsor = { ...sponsor, question_id: parseInt(data.question_id) }
    // }
    if (data.sponsor_website_url) {
      sponsor = { ...sponsor, sponsor_website_url: data.sponsor_website_url }
    }
    // if (data.question_id) {
    //   sponsor = { ...sponsor, question_id: data.question_id }
    // }

    if (this.state.isEdit) {
      sponsor = { ...sponsor, id: data.id }
      let formData = new FormData();
      formData.append("id", sponsor.id)
      formData.append("active", sponsor.active)
      formData.append("sponsor_name", sponsor.sponsor_name)
      formData.append("updated_by", parseInt(operator.id));
      if (sponsor.league_id) {
        formData.append("league_id", parseInt(sponsor.league_id))
      }
      if (sponsor.match_id) {
        formData.append("match_id", parseInt(sponsor.match_id))
      }
      if (sponsor.sponsor_website_url) {
        formData.append("sponsor_website_url", sponsor.sponsor_website_url)
      }
      if (sponsor.question_id) {
        formData.append("question_id", parseInt(sponsor.question_id))
      }

      if (typeof sponsor.sponsor_image_url !== "string") {
        formData.append("sponsor_image_url", sponsor.sponsor_image_url)
      }
      if (sponsor.sponsor_name === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.updateSponsor(formData).then(() => {
          this.props.getSponsors(this.state.initialPage, this.state.rowCount);
        })
        this.hideModal();
      }
    }
    else {
      sponsor = { ...sponsor, created_by: operator.id, updated_by: operator.id }

      let formData = new FormData();
      formData.append("sponsor_image_url", sponsor.sponsor_image_url)
      formData.append("active", sponsor.active)
      formData.append("sponsor_name", sponsor.sponsor_name)
      formData.append("created_by", parseInt(operator.id))
      formData.append("updated_by", parseInt(operator.id))
      if (sponsor.league_id) {
        formData.append("league_id", parseInt(sponsor.league_id))
      }
      if (sponsor.match_id) {
        formData.append("match_id", parseInt(sponsor.match_id))
      }
      if (sponsor.sponsor_website_url) {
        formData.append("sponsor_website_url", sponsor.sponsor_website_url)
      }
      if (sponsor.question_id) {
        formData.append("question_id", parseInt(sponsor.question_id))
      }

      if (sponsor.sponsor_name === '') {
        this.setState({
          requiredError: "All fields are required"
        })
      }
      else {
        this.props.createSponsor(formData).then(() => {
          this.props.getSponsors(this.state.initialPage, this.state.rowCount);
        })
        this.setState({
          requiredError: ''
        })
        this.hideModal();
      }
    }

  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }
  showModal = () => {
    this.setState({
      show: true,
      isEdit: false
    })
  }
}

export default SponsorListController
