import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';

class HighlightCreateController extends Component {
    constructor(props) {
        super(props)

        let data = this.props.isEdit && JSON.parse(props.highlight.highlight.headers)
        let headers = [];
        let key = this.props.isEdit && Object.keys(data)
        let value = this.props.isEdit && Object.values(data)

        for (let i = 0; i < key.length; i++) {
            headers.push({ headerKey: key[i], headerValue: value[i], icon: 'fa fa-trash' });
        }

        this.state = {
            addHeader: true,
            league_name: '',
            newHeader: props.newHeader,
            matches: [],
            headerKeyIndex: 0,
            highlight: props.isEdit === false ? {
                headers: [],
                match_id: '',
                league_id: '',
                highlight_url: '',
                home_team_goal: '',
                away_team_goal: '',
                type: 2,
                active: 1
            } :
                {
                    ...props.highlight.highlight, headers: headers
                }
        }

    }

    componentDidMount = () => {

    }


    leagueProcess = (league_id) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                league_id: league_id
            }
        }))
        axios.post(`${CONSTANTS.BASE_URL}matches/list`).then((response) => {
            let newMatch = [];
            let matches = response.data.data;

            matches.map(match => {
                if (this.state.highlight.league_id === match.league_id && match.is_playing === 2) {
                    newMatch.push(match)
                }
            })
            this.setState({
                matches: newMatch
            })
        })
    }
    handleMatches = (match_id) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                match_id: match_id
            }
        }))
    }
    handleInputHighlightURL = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                highlight_url: e.target.value
            }
        }))
    }
    homeTeamGoal = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                home_team_goal: e.target.value
            }
        }))

    }
    awayTeamGoal = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                away_team_goal: e.target.value
            }
        }))

    }

    handleSelect = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                active: e.target.value
            }
        }))
    }
    handleSelectType = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                type: e.target.value
            }
        }))
        if (this.state.highlight.type === 1) {
            this.setState({
                addHeader: false
            })
        }
        else {
            this.setState({
                addHeader: true
            })
        }
    }

    handleInputChange = (index, event) => {
        const values = [...this.state.highlight.headers];
        if (event.target.name === "headerKey") {
            values[index].headerKey = event.target.value;
        } else {
            values[index].headerValue = event.target.value;
        }
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                headers: values
            }
        }))
    };

    handleAddFields = (e, index) => {
        e.preventDefault();
        let header;
        let initialHeaderKey = ["Origin", "Referer"]
        const values = [...this.state.highlight.headers];
        const newHeader = { headerKey: initialHeaderKey[this.state.headerKeyIndex], headerValue: this.state.highlight.headers.headerValue, icon: 'fa fa-plus' }
        // const newHeader = { headerKey: this.state.highlight.headers.headerKey, headerValue: this.state.highlight.headers.headerValue, icon: 'fa fa-plus' }

        if (values.length === 0) {
            values.push(newHeader)
            this.setState((prevState) => ({
                ...prevState,
                highlight: {
                    ...prevState.highlight,
                    headers: values
                },
                headerKeyIndex: this.state.headerKeyIndex + 1,
            }))

        } else {
            if (values[index].headerKey !== undefined && values[index].headerValue !== undefined && values[index].headerKey !== "" && values[index].headerValue !== "") {
                header = this.state.highlight.headers[index];
                header.icon = "fa fa-trash"
                values[index] = header
                values.push(newHeader)
                this.setState((prevState) => ({
                    ...prevState,
                    highlight: {
                        ...prevState.highlight,
                        headers: values
                    },
                    headerKeyIndex: this.state.headerKeyIndex + 1,
                }))
            }
        }
    };
    handleRemoveFields = (e, index) => {
        e.preventDefault();
        const newValue = [...this.state.highlight.headers]
        newValue.splice(index, 1);
        this.setState((prevState) => ({
            ...prevState,
            highlight: {
                ...prevState.highlight,
                headers: newValue,
            }
        }))
    };
}

export default HighlightCreateController