import { Component } from 'react'
import CONSTANTS from './../../../redux/constants';

class PermissionListController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            isEdit: false,
            initialPage: 1,
            rowCount: CONSTANTS.ROW_COUNT,
            text: '',
            requiredError: '',
            permission: {}
        }
    }
    componentDidMount() {
        this.props.getPermissions(this.state.initialPage, this.state.rowCount);
    }
    stopSearching = () => {
        this.props.getPermissions(this.state.initialPage, this.state.rowCount);
        this.setState({
            text: '',
        })
    }
    handleInputText = (e) => {
        this.setState({
            text: e.target.value
        })
    }
    handleSearch = (e) => {
        e.preventDefault()
        this.props.searchItem(this.state.text, this.state.initialPage)
    }
    handleEdit = async (permission) => {
        this.setState((prevState) => ({
            ...prevState,
            show: true,
            isEdit: true,
            permission: {
                ...prevState.permission,
                permission
            }
        }))
    }
    createOrUpdate = (data) => {
        let operator = JSON.parse(localStorage.getItem('operator'));
        let permission = {
            name: data.name,
            active: data.active,
            updated_by: operator.id,
            data: data.data
        }
        if (data.description) {
            permission = { ...permission, description: data.description }
        }
        if (this.state.isEdit) {
            permission = { ...permission, id: data.id }
            if (permission.name === '') {
                this.setState({
                    requiredError: "Name field is required"
                })
            }
            else {
                this.props.updatePermission(permission).then(() => {
                    this.props.getPermissions(this.state.initialPage, this.state.rowCount)
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
        else {
            permission = { ...permission, created_by: operator.id, updated_by: operator.id }
            if (permission.name === '') {
                this.setState({
                    requiredError: "Name field is required"
                })
            }
            else {
                this.props.createPermission(permission).then(() => {
                    this.props.getPermissions(this.state.initialPage, this.state.rowCount);
                })
                this.setState({
                    requiredError: ''
                })
                this.hideModal();
            }
        }
    }
    hideModal = () => {
        this.setState({
            show: false
        })
    }
    showModal = () => {
        this.setState({
            show: true,
            isEdit: false
        })
    }
}
export {
    PermissionListController
}