import { Button } from '../../../components/buttons';
import UserCreate from '../UserCreate/UserCreate'
import { UserListingController } from './UserListingController'
import UserAction from './../../../redux/action/user_action';
import Moment from 'react-moment';
import './UserListing.css'
import TownshipAction from './../../../redux/action/township_action';
import { Modal, Alert, BlackGoat, PageLoader, connect, Pagination, Link, MainContainer, Header, Body } from '../../../components/ImportsFile'
class UserListing extends UserListingController {
  render() {
    let updatePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'User' && permission.functions === 'update') {
        return updatePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>User Lists</h4>
          <form className="search" onSubmit={this.handleSearch} autoComplete="off" >
            <div className="input-group">
              <input type="text" className="input-field" placeholder="Search.." name="text" value={this.state.text} onChange={this.handleInputText} />
              <select name="active" className="dropdown_select" value={this.state.active} onChange={this.handleActive} required >
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </select>
              <span className="input-group-text" onClick={this.stopSearching}>&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }

          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Full Name</th>
                <th>Phone</th>
                <th>Register Date</th>
                <th>Global Level</th>
                <th>Favourite Team Level</th>
                <th>Active</th>
                {
                  // updatePermission &&
                  <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.users.map((user, index) => {
                  return (
                    <tr key={index} style={{ alignItems: 'center', justifyContent: 'center' }}>
                      <td>
                        <img src={user.image_url || BlackGoat} alt="choosed images" width="50px" height="50px" />
                      </td>
                      <td>{!user.full_name ? user.user_name : user.full_name}</td>
                      <td>{user.phone_no}</td>
                      <td>
                        <Moment format="ddd, D MMM , YYYY">{user.register_date}</Moment>
                      </td>
                      <td>{user.global_level_id}</td>
                      <td>{user.favourite_team_level_id}</td>
                      <td>
                        {
                          user.active === 0 ? "Inactive" : "Active"
                        }
                      </td>
                      <td>
                        <Link to={`/user_detail/${user.id}`} className="user_detail">
                          <Button caption="Detail" className="btn-primary" icon="fa fa-info-circle"
                          />
                        </Link>
                        {
                          updatePermission &&
                          <Button caption={user.active == 0 ? 'Active' : 'Inactive'} className={user.active == 1 ? "btn-danger" : 'btn-success'} onClick={() => this.changeStatus(user)}
                          />
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>

          </table>

          <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => {
              (!this.props.text && !this.props.active) ? this.props.getUsers(pageNumber, this.props.row_count)
                :
                this.props.searchItem(this.state.text, this.state.active, pageNumber)
            }} />

          {
            this.state.show && <UserCreate show={this.state.show} hideModal={this.hideModal} isEdit={this.state.isEdit} createOrUpdate={this.createOrUpdate} user={this.state.user} requiredError={this.state.requiredError} />
          }
        </Body>

      </MainContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    townships: state.township.townships,
    error: state.user.error,
    success: state.user.success,
    currentPage: state.user.currentPage,
    totalCount: state.user.totalCount,
    totalCountPerPage: state.user.totalCountPerPage,
    loading: state.user.loading,
    text: state.user.text,
    row_count: state.user.row_count,
    active: state.user.active
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (pageNumber, row_count) => dispatch(UserAction.getUsers(pageNumber, row_count)),
    getTownships: (pageNumber, row_count) => dispatch(TownshipAction.getTownships(pageNumber, row_count)),
    createUser: (user) => dispatch(UserAction.createUser(user)),
    updateUser: (user) => dispatch(UserAction.updateUser(user)),
    deleteUser: (id) => dispatch(UserAction.deleteUser(id)),
    searchItem: (text, active, pageNumber) => dispatch(UserAction.searchItem(text, active, pageNumber)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListing)