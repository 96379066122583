
import SpecialQuizDetailsType from './../type/special_quiz_detail_type';
const initialState = {
  special_quiz_details: [],
  future_details: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  specialQuizId: '',
  totalCountPerPage: null,
  row_count: 1,
  loading: false,
  questionId: '',
  type: '',
}
const specialQuizDetailsReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SpecialQuizDetailsType.GET_SPECIAL_QUIZ_DETAILS_LISTS:
      return {
        ...state,
        loading: false,
        special_quiz_details: actions.payload
      }
    case SpecialQuizDetailsType.GET_FUTURE_DETAILS:
      return {
        ...state,
        loading: false,
        future_details: actions.payload
      }
    case SpecialQuizDetailsType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload
      }
    case SpecialQuizDetailsType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }
    case SpecialQuizDetailsType.SEARCH_WITH_SPECIAL_QUIZ_ID:
      return {
        ...state,
        specialQuizId: actions.payload
      }
    case SpecialQuizDetailsType.SEARCH_WITH_QUESTION_ID:
      return {
        ...state,
        questionId: actions.payload
      }
    case SpecialQuizDetailsType.SEARCH_WITH_TYPE:
      return {
        ...state,
        type: actions.payload
      }
    case SpecialQuizDetailsType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case SpecialQuizDetailsType.TOGGLE_EDIT:
      let updatedArray = state.future_details.map(item => {
        if (item.id == actions.payload.id) {
          return {
            ...item,
            isEdit: !item.isEdit
          }
        }
        return item
      })
      return {
        ...state,
        future_details: [...updatedArray]
      }
    case SpecialQuizDetailsType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case SpecialQuizDetailsType.ADD_SPECIAL_QUIZ_DETAILS:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        special_quiz_details: [
          ...state.special_quiz_details,
          actions.payload,
        ]
      }
    case SpecialQuizDetailsType.UPDATE_SPECIAL_QUIZ_DETAILS:
      const updateSpecialQuizDetails = state.special_quiz_details.filter(quiz_details => quiz_details.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        special_quiz_details: [
          actions.payload,
          ...updateSpecialQuizDetails,
        ]
      }
    case SpecialQuizDetailsType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case SpecialQuizDetailsType.DELETE_SPECIAL_QUIZ_DETAILS:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        special_quiz_details: state.special_quiz_details.filter(detail => detail.id !== actions.payload),
      }
    case SpecialQuizDetailsType.SPECIAL_QUIZ_DETAILS_ERROR:
      return { ...state, error: actions.payload, loading: false }
    case SpecialQuizDetailsType.SPECIAL_QUIZ_DETAILS_SUCCESS:
      return { ...state, success: actions.payload, loading: false }
    default: return state
  }
}
export default specialQuizDetailsReducer