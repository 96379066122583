import styled from 'styled-components'

const MainContainer = styled.div`
    width:90%;
    height:100%;
    display:flex;
    margin:0px auto;
    padding:10px;
    flex-direction:column;
`
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
    height:10%;
    h4{
        font-size:28px;
        background: -webkit-linear-gradient(#202020, #FAD230);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .search_noti input[type="text"]{
        padding: 5px 10px;
        font-size: 17px;
        border: 1px solid grey;
        float: left;
        width: 250px;
        height: 38px;
        background: #f1f1f1;
    }
    .search_noti button {
        float: right;
        width: 80px;
        height: 38px;
        padding: 5px 10px;
        background: #202020;
        color: #fad230;
        font-size: 17px;
        border: 1px solid grey;
        border-left: none;
        cursor: pointer;
    }
    .switch{
        display:flex;
        align-items:center;
        justify-content:center;
        margin-top: -10px;
    }   
    .switch span{
        color:#202020;
        font-weight:bold;
        padding: 0 10px;
    }
     
`

const Body = styled.div`
    width:100%;
    height:80%;
    padding-top:20px;
`
const Card = styled.div`
    width:100%;
    min-height:100px;
    // height:auto;
    max-height:67vh;
    padding-top:20px;
    border-radius:10px;
    background-color:#e5e5e5;
     overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 2px;
        border-radius:50px;
    }
    ::-webkit-scrollbar-thumb {
        background-image: linear-gradient(#202020, #fad230);
        border-radius: 25px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-image: linear-gradient(#fad230, #202020);
    }
    .no-noti{
        display:flex;
        align-items:center;
        justify-content:center;
        text-align:center;
        font-weight:500;
        padding-top:10px;
    }
    .noti-list{
        .noti-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding:0px 10px;           
            font-size:18px;
            .profile{
                text-align:center;
                flex:1;
                .operator{
                    color: #202020;
                    font-weight:600;
                }
                .user{
                    color: #202020;
                    font-weight:600;
                }
                img{
                width:45px;
                height:45px;
                border-radius:50%;
            }
            }
            .transaction{
                flex:3;
                .date_time{
                    color:gray;
                    font-size:13px;
                }
                .in{
                    color:#009000;
                    font-weight:700;
                }
                .out{
                    color:#ff0000;
                    font-weight:700;
                }
            }
            .center{
                flex:1;
                img{
                    margin:0px 8px;
                    width:70px;
                }
            }
            .amount{
                flex:3;
            }
            .pay_type{
                flex:2;
                text-align:right;
                padding-right:20px;
                .pay-type{
                    color: #202020;
                }
                img{
                    margin:0px 8px;
                    width:45px;                    
                }
            }
        }
        hr{
            margin: 16px 0;
            border: 0;
            width: 100%;
            background-color: #575656;
        }
    }
    
`
export {
    MainContainer, Header, Body, Card
}