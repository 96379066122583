
const RolePermissionType = {
    GET_ROLE_PERMISSIONS : "GET_ROLE_PERMISSIONS",
    ADD_ROLE_PERMISSION : "ADD_ROLE_PERMISSION",
    UPDATE_ROLE_PERMISSION: "UPDATE_ROLE_PERMISSION",
    DELETE_ROLE_PERMISSION : "DELETE_ROLE_PERMISSION",
    ROLE_PERMISSION_ERROR: "ROLE_PERMISSION_ERROR",
    ROLE_PERMISSION_SUCCESS: "ROLE_PERMISSION_SUCCESS",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    ROW_COUNT: "ROW_COUNT",
    SEARCH_WITH_ROLE: "SEARCH_WITH_ROLE",
    SEARCH_WITH_PERMISSION: "SEARCH_WITH_PERMISSION",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default RolePermissionType