import React, { Component } from 'react'
import { MainContainer, Header, Body, Button, PageLoader, Pagination, Modal, Alert, connect, Search } from '../../../components/ImportsFile'
import PrizeListController from './PrizeListController';
import PrizeCreate from './../create/PrizeCreate';
import PrizeAction from './../../../redux/action/prize_action';
import LeagueAction from './../../../redux/action/league_action.js'
import moment from 'moment'
class PrizeList extends PrizeListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Prize' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Prize' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Prize' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>
        <Header>
          <h4>Prizes</h4>

          <form className="search search-prize" onSubmit={this.handleSearch} autoComplete="off" >
            <div className="input-group">
              <input type="text" value={this.state.text} className="input-field" placeholder="Search.." name="search" onChange={this.handleInputText} />
              <Search
                items={this.props.leagues}
                searchItem={(leagueId) => this.handleSelectLeagueId(leagueId)}
                text={'Search with League'}
                search={this.state.leagueId ? true : false}
                LoadMore={this.loadMore1}
                getItems={this.props.getAllLeagues}
                rowCount={this.props.leagueRowCount}
                searchLeague={this.props.searchLeague}
                currentPage={this.props.leagueCurrentPage}
                stopSearching={() => this.stopSearching()}
              />

              <input type="date" name="expired_time" value={this.state.expired_time} onChange={this.ExpiredTime} />

              <span className="input-group-text" onClick={() => this.stopSearching()}>&times;</span>
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </form>

          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Prize" className="btn-primary add-button" />
          }
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />

          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Exchangeable Points</th>
                {/**
                <th>Description</th>
                <th>Active</th>
              */}
                <th>Limit Count</th>
                <th>Type</th>
                <th>League</th>
                <th>Expired Time</th>
                {
                  (updatePermission || deletePermission) &&
                  <th>Actions</th>
                }
              </tr>
            </thead>

            <tbody>
              {
                this.props.prizes?.map((prize, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img src={prize.image} alt="choosed images" />
                      </td>
                      <td>{prize.name}</td>
                      <td>{prize.exchangeable_points}</td>
                      {/**
                      <td>{prize.description}</td>
                      <td>{prize.active === 1 ? 'Active' : 'Inactive'}</td>
                    */}
                      <td>{prize.limit_count}</td>
                      <td>{prize.type === 1 ? 'Main Coin' : 'Quiz Coin'}</td>
                      <td>
                        {
                          prize.league_id ? <img src={prize?.League?.logo_url} alt="League" /> : "-"
                        }
                      </td>
                      <td>{prize.expired_time ? moment(prize.expired_time).format("ddd,D MMM ,YYYY") : "-"}</td>
                      <td>
                        <Modal deleteItem={() => this.deletePrize(prize.id)} editItem={() => this.handleEdit(prize)} deletePermission={deletePermission} updatePermission={updatePermission} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>

          </table>

          <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => { (!this.props.text && !this.props.leagueId && !this.props.expired_time) ? this.props.getPrizes(pageNumber, this.props.row_count) : this.props.searchItem(this.state.text, this.state.leagueId, this.state.expired_time, pageNumber) }} />

          {
            this.state.show &&
            <PrizeCreate
              hideModal={this.hideModal}
              prize={this.state.prize}
              isEdit={this.state.isEdit}
              createOrUpdate={this.createOrUpdate}
              requiredError={this.state.requiredError}

              leagues={this.props.leagues}
              leagueRowCount={this.props.leagueRowCount}
              leagueCurrentPage={this.props.leagueCurrentPage}
              leagueText={this.props.leagueText}
              searchLeague={this.props.searchLeague}
              getAllLeagues={this.props.getAllLeagues}
              loadMoreLeague={this.loadMoreLeague}
            />
          }

        </Body>
      </MainContainer>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    prizes: state.prize.prizes,
    error: state.prize.error,
    success: state.prize.success,
    currentPage: state.prize.currentPage,
    totalCount: state.prize.totalCount,
    text: state.prize.text,
    leagueId: state.prize.leagueId,
    expired_time: state.prize.expired_time,
    row_count: state.prize.row_count,
    loading: state.prize.loading,
    totalCountPerPage: state.prize.totalCountPerPage,
    leagues: state.league.leagues,
    leagueRowCount: state.league.row_count,
    leagueText: state.league.text,
    leagueCurrentPage: state.league.currentPage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPrizes: (pageNumber, row_count) => dispatch(PrizeAction.getPrizes(pageNumber, row_count)),
    createPrize: (prize) => dispatch(PrizeAction.createPrize(prize)),
    updatePrize: (prize) => dispatch(PrizeAction.updatePrize(prize)),
    deletePrize: (id) => dispatch(PrizeAction.deletePrize(id)),
    searchItem: (text, leagueId, expired_time, pageNumber) => dispatch(PrizeAction.searchItem(text, leagueId, expired_time, pageNumber)),
    getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
    searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrizeList)