const QuizAnswerType = {
    GET_QUIZ_ANSWERS: "GET_QUIZ_ANSWERS",
    QUIZ_ANSWER_ERROR: "QUIZ_ANSWER_ERROR",
    QUIZ_ANSWER_SUCCESS: "QUIZ_ANSWER_SUCCESS",
    ADD_QUIZ_ANSWER: "ADD_QUIZ_ANSWER",
    UPDATE_QUIZ_ANSWER: "UPDATE_QUIZ_ANSWER",
    GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
    GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
    TOTAL_COUNT_PER_PAGE: "TOTAL_COUNT_PER_PAGE",
    SERCH_WITH_QUIZ_DETAIL_ID: "SERCH_WITH_QUIZ_DETAIL_ID",
    SERCH_WITH_QUIZ_ID:"SERCH_WITH_QUIZ_ID",
    SERCH_WITH_USER_ID: "SERCH_WITH_USER_ID",
    ROW_COUNT: "ROW_COUNT",
    LOADING: "LOADING",
    ERROR: "ERROR"
}
export default QuizAnswerType