import TownshipType from './../type/township_type';
const initialState = {
    townships: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    text: null,
    cityId: null,
    row_count: 1,
    totalCountPerPage: null,
    loading:false,
}

const townshipReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case TownshipType.GET_TOWNSHIPS:
            return {
                ...state,
                loading:false,
                townships: actions.payload
            }
        case TownshipType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case TownshipType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload
            }
        case TownshipType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload
            }
        case TownshipType.SEARCH_WITH_CITY:
            return {
                ...state,
                cityId: actions.payload
            }
        case TownshipType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case TownshipType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case TownshipType.ADD_TOWNSHIP:
            return {
                ...state,
                loading:false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                townships: [
                    actions.payload,
                    ...state.townships,
                ]
            }
        case TownshipType.UPDATE_TOWNSHIP:
            const updateTownship = state.townships.filter(township => township.id !== actions.payload.id)
            return {
                ...state,
                loading:false,
                townships: [
                    actions.payload,
                    ...updateTownship,
                ]
            }
        case TownshipType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case TownshipType.DELETE_TOWNSHIP:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                townships: state.townships.filter(township => township.id !== actions.payload)
            }
        case TownshipType.TOWNSHIP_ERROR:
            return { ...state, error: actions.payload, loading: false }
        case TownshipType.TOWNSHIP_SUCCESS:
            return { ...state, success: actions.payload, loading: false }
        default: return state
    }
}

export default townshipReducer