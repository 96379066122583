import React from 'react'
import '../../modal/modal.css'
import { Input } from '../../../components/inputs/Input'
import { Button } from '../../../components/buttons/index'
import { RoleCreateController } from './RoleCreateController'
class RoleCreate extends RoleCreateController {
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit Role' : 'Add Role'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }}>
                            {

                                this.props.requiredError && <p className="requiredError">* {this.props.requiredError}
                                </p>
                            }
                            <div>
                                <Input type="text" name="name" placeholder="Enter Name..." value={this.state.role.name} onChange={this.handleInputName} required />

                                <Input type="text" name="description" placeholder="Enter Description...(Optional)" value={this.state.role.description ? this.state.role.description : ''} onChange={this.handleInputDescription} />

                                <select name="active" className="select" value={this.state.role.active} onChange={this.handleSelect} required >
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                            <br />

                            <Button type="button" id="submit-btn" className="btn-secondary" caption={this.props.isEdit ? "Update" : "Create"} btnFun={() => this.props.createOrUpdate(this.state.role)} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export {
    RoleCreate
}
