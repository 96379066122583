
import PlayerType from './../type/player_type';
const initialState = {
  players: [],
  error: null,
  success: null,
  currentPage: 1,
  totalCount: null,
  loading: false,
  totalCountPerPage: null,
  row_count: 1,
  teamId: null,
  leagueId: null,
  text: null,
  position: null,
  countryId: null,
}
const playerReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case PlayerType.GET_PLAYERS:
      return {
        ...state,
        loading: false,
        players: actions.payload
      }
    case PlayerType.TOTAL_COUNT_PER_PAGE:
      return {
        ...state,
        totalCountPerPage: actions.payload,
      }
    case PlayerType.ROW_COUNT:
      return {
        ...state,
        row_count: actions.payload
      }

    case PlayerType.SEARCH_WITH_TEAMID:
      return {
        ...state,
        teamId: actions.payload,
      }
    case PlayerType.SEARCH_WITH_LEAGUEID:
      return {
        ...state,
        leagueId: actions.payload,
      }
    case PlayerType.SEARCH_ITEM:
      return {
        ...state,
        text: actions.payload,
      }
    case PlayerType.SEARCH_WITH_POSITION:
      return {
        ...state,
        position: actions.payload,
      }
    case PlayerType.GET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: actions.payload
      }
    case PlayerType.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: actions.payload
      }
    case PlayerType.LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case PlayerType.ADD_PLAYER:
      return {
        ...state,
        loading: false,
        totalCount: state.totalCount + 1,
        totalCountPerPage: state.totalCountPerPage + 1,
        players: [
          actions.payload,
          ...state.players,
        ]
      }

    case PlayerType.UPDATE_PLAYER:
      const updatePlayers = state.players.filter(player => player.id !== actions.payload.id)
      return {
        ...state,
        loading: false,
        players: [
          actions.payload,
          ...updatePlayers,
        ]
      }
    case PlayerType.DELETE_PLAYER:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        totalCountPerPage: state.totalCountPerPage - 1,
        players: state.players.filter(player => player.id !== actions.payload)
      }
    case PlayerType.PLAYER_ERROR:
      return { ...state, loading: false, error: actions.payload }
    case PlayerType.PLAYER_SUCCESS:
      return { ...state, success: actions.payload, loading: false }

    default: return state
  }
}
export default playerReducer