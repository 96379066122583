
import TeamType from './../type/team_type';
const initialState = {
    teams: [],
    error: null,
    success: null,
    currentPage: 1,
    totalCount: null,
    loading: false,
    text: null,
    totalCountPerPage: null,
    row_count: 1,
    stadiumId: null,
    countryId: null,
    leagueId: null,
}
const teamReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case TeamType.GET_TEAMS:
            return {
                ...state,
                loading: false,
                teams: actions.payload
            }
        case TeamType.TOTAL_COUNT_PER_PAGE:
            return {
                ...state,
                totalCountPerPage: actions.payload,
            }
        case TeamType.ROW_COUNT:
            return {
                ...state,
                row_count: actions.payload
            }
        case TeamType.SEARCH_ITEM:
            return {
                ...state,
                text: actions.payload,
            }
        case TeamType.SEARCH_WITH_STADIUMID:
            return {
                ...state,
                stadiumId: actions.payload,
            }
        case TeamType.SEARCH_WITH_COUNTRYID:
            return {
                ...state,
                countryId: actions.payload,
            }
        // case TeamType.SEARCH_WITH_LEAGUEID:
        //     return {
        //         ...state,
        //         leagueId: actions.payload,
        //     }
        case TeamType.GET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: actions.payload
            }
        case TeamType.GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: actions.payload
            }
        case TeamType.LOADING: {
            return {
                ...state,
                loading: actions.payload,
            };
        }
        case TeamType.ADD_TEAM:
            return {
                ...state,
                loading: false,
                totalCount: state.totalCount + 1,
                totalCountPerPage: state.totalCountPerPage + 1,
                teams: [
                    actions.payload,
                    ...state.teams,
                ]
            }

        case TeamType.UPDATE_TEAM:
            const updateTeams = state.teams.filter(team => team.id !== actions.payload.id)
            return {
                ...state,
                loading: false,
                teams: [
                    actions.payload,
                    ...updateTeams,
                ]
            }
        case TeamType.DELETE_TEAM:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                totalCountPerPage: state.totalCountPerPage - 1,
                teams: state.teams.filter(team => team.id !== actions.payload)
            }
        case TeamType.TEAM_ERROR:
            return { ...state, loading: false, error: actions.payload }
        case TeamType.TEAM_SUCCESS:
            return { ...state, success: actions.payload, loading: false }

        default: return state
    }
}
export default teamReducer