import React from 'react'
import { MainContainer, Header, Body, Button, Pagination, Modal, connect, Search, PageLoader, Link, moment, Alert } from '../../../components/ImportsFile'
import SponsorAction from './../../../redux/action/sponsor_action.js';
import LeagueAction from './../../../redux/action/league_action.js';
import MatchAction from './../../../redux/action/match_action.js';
import SponsorListController from './SponsorListController';
import SponsorCreate from './../create/SponsorCreate';
import QuestionAction from './../../../redux/action/question_action';
class SponsorList extends SponsorListController {
  render() {
    let createPermission = false;
    let updatePermission = false;
    let deletePermission = false;
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    permissions.map((permission) => {
      if (permission.feature === 'Sponsor' && permission.functions === 'create') {
        return createPermission = true;
      }
      if (permission.feature === 'Sponsor' && permission.functions === 'update') {
        return updatePermission = true;
      }
      if (permission.feature === 'Sponsor' && permission.functions === 'delete') {
        return deletePermission = true;
      }
    })
    return (
      <MainContainer>

        <Header>
          <h4>Sponsors</h4>

          {
            createPermission &&
            <Button icon="" btnFun={this.showModal} caption="Add Sponsor" className="btn-primary add-button" />
          }
        </Header>
        <Body>
          <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
          {
            this.props.loading && <PageLoader />
          }
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Website URL</th>
                <th>League</th>
                <th>Match</th>
                <th>Question</th>
                {
                  (updatePermission || deletePermission) &&
                  <th>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.sponsors && this.props.sponsors.map((sponsor, index) => {
                  return (
                    <tr key={index}>

                      <td>{sponsor.sponsor_name}</td>
                      <td>
                        {
                          // typeof sponsor.sponsor_image_url === 'string' ?
                          <img src={sponsor.sponsor_image_url} alt="sponsor" />
                          // :
                          // <img src={sponsor.sponsor_image_url} alt="sponsor" />
                        }
                      </td>
                      <td>{sponsor.sponsor_website_url}</td>
                      <td>{sponsor.League?.name}</td>
                      <td>
                        {
                          sponsor?.Match && sponsor?.Match?.HomeTeam?.name + " Vs " + sponsor?.Match?.AwayTeam?.name
                        }
                      </td>
                      <td>{sponsor?.Question?.question}</td>
                      <td>
                        <Modal deleteItem={() => this.props.deleteSponsor(sponsor.id)} deletePermission={deletePermission} updatePermission={updatePermission}
                          editItem={() => { this.handleEdit(sponsor) }
                          }
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>


          <Pagination
            activePage={this.props.currentPage}
            totalItemsCount={this.props.totalCount}
            totalCountPerPage={this.props.totalCountPerPage}
            paginate={(pageNumber) => {
              this.props.getSponsors(pageNumber, this.props.row_count);
            }}
          />

          {
            this.state.show &&
            <SponsorCreate
              sponsor={this.state.sponsor}
              hideModal={this.hideModal}
              isEdit={this.state.isEdit}
              handleSwitch={this.handleSwitch}
              checked={this.state.checked}
              createOrUpdate={this.createOrUpdate}

              leagues={this.props.leagues}
              loadMoreLeague={this.loadMoreLeague}
              searchLeague={this.props.searchLeague}
              leagueCurrentPage={this.props.leagueCurrentPage}
              getAllLeagues={this.props.getAllLeagues}
              leagueRowCount={this.props.leagueRowCount}

              matches={this.props.matches}
              loadMoreMatch={this.loadMoreMatch}
              matchCurrentPage={this.props.matchCurrentPage}
              getAllMatches={this.props.getAllMatches}
              matchRowCount={this.props.matchRowCount}

              questions={this.props.questions}
              questionRowCount={this.props.questionRowCount}
              questionTotalCount={this.props.questionTotalCount}
              getQuestions={this.props.getQuestions}
            />
          }
        </Body>
      </MainContainer >
    )
  }
}

const mapStateToProps = state => {
  return {
    sponsors: state.sponsor.sponsors,
    totalCount: state.sponsor.totalCount,
    error: state.sponsor.error,
    currentPage: state.sponsor.currentPage,
    row_count: state.sponsor.row_count,
    success: state.sponsor.success,
    totalCountPerPage: state.sponsor.totalCountPerPage,
    loading: state.sponsor.loading,
    leagues: state.league.leagues,
    leagueCurrentPage: state.league.currentPage,
    leagueRowCount: state.league.row_count,
    textLeague: state.league.text,
    countryId: state.league.countryId,
    matches: state.match.matches,
    matchCurrentPage: state.match.currentPage,
    matchRowCount: state.match.row_count,

    questions: state.question.questions,
    questionRowCount: state.question.row_count,
    questionTotalCount: state.question.totalCount,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSponsors: (pageNumber, row_count) => dispatch(SponsorAction.getSponsors(pageNumber, row_count)),
    createSponsor: (sponsor) => dispatch(SponsorAction.createSponsor(sponsor)),
    updateSponsor: (sponsor) => dispatch(SponsorAction.updateSponsor(sponsor)),
    deleteSponsor: (id) => dispatch(SponsorAction.deleteSponsor(id)),
    getAllLeagues: (pageNumber, row_count) => dispatch(LeagueAction.getAllLeagues(pageNumber, row_count)),
    searchLeague: (text, countryId, pageNumber) => dispatch(LeagueAction.searchLeague(text, countryId, pageNumber)),
    getAllMatches: (pageNumber, row_count) => dispatch(MatchAction.getAllMatches(pageNumber, row_count)),
    getQuestions: (type, pageNumber, row_count) => dispatch(QuestionAction.getQuestions(type, pageNumber, row_count)),
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorList)