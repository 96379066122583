import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import 'chart.js/auto';
class LineChart extends Component {
    render() {
        const labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
        const data = {
            labels: labels,
            datasets: [
                {
                    label: `Watching count for ${this.props.currentMonth}`,
                    data: this.props.watchCountForCurrentMonth,
                    fill: false,
                    borderColor: ['#FAD230'],
                    backgroundColor: ['#FAD230'],
                    pointBackgorundColor: ['#FAD230'],
                    pointBorderColor: ['#FAD230'],
                    tension: 0.1
                },
                {
                    label: `Watching count for ${this.props.previousMonth}`,
                    data: this.props.watchCountForPrevMonth,
                    fill: false,
                    borderColor: ['#202020'],
                    backgroundColor: ['#202020'],
                    pointBackgorundColor: ['#202020'],
                    pointBorderColor: ['#202020'],
                    tension: 0.1
                },
            ]
        };
        return <Line data={data} />

    }
}

export default LineChart

