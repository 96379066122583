import React, { Component } from 'react'
import { Button } from './../buttons/Button';
import Swal from 'sweetalert2'
import './SwalStyle.css'
class EditModal extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    handleEdit = () => {
        // Swal.fire({
        //     title: 'Are you sure?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes, edit it!',
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         this.props.editItem()
        //     }
        // })
        this.props.editItem()
    }
    render() {
        return (
            <Button caption="Edit" className="btn-info actions" icon="fas fa-edit" btnFun={this.handleEdit} />
        )
    }
}

export default EditModal
