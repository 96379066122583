import React from 'react'
import { WalletListController } from './WalletListController'
import { WalletCreate } from '../create/WalletCreate'
import WalletAction from './../../../redux/action/wallet_action';
import UserAction from './../../../redux/action/user_action';
import { Alert, connect, PageLoader, Modal, Pagination, Search, MainContainer, Header, Body } from '../../../components/ImportsFile'
class WalletListing extends WalletListController {
    render() {
        let updatePermission = false;
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        permissions.map((permission) => {
            if (permission.feature === 'Wallet' && permission.functions === 'update') {
                return updatePermission = true;
            }
        })
        return (
            <MainContainer>
                <Header>
                    <h4>Wallet</h4>
                    <form className="search search-wallet">
                        <div className="input-group">

                            <Search
                                items={this.props.users}
                                searchItem={(selected) => this.handleSelectUser(selected)}
                                searching={this.props.searchUser}
                                currentPage={this.props.currentPage}
                                text={'Search with User'}
                                search={this.state.selected ? true : false}
                                LoadMore={this.loadMore}
                                getItems={this.props.getAllUsers}
                                rowCount={this.props.userRowCount}
                                stopSearching={() => this.stopSearching()}
                            />
                            <span className="clear_filter" onClick={() => this.stopSearching()} >&times;</span>
                            <button onClick={this.handleSearch}><i className="fa fa-search"></i></button>
                        </div>
                    </form>
                    <p></p>
                </Header>
                <Body>
                    <Alert errorMessage={this.props.error && this.props.error.message} successMessage={this.props.success && this.props.success.message} />
                    {
                        this.props.loading && <PageLoader />
                    }
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                {/**
                                    <th>Previous Amount</th>
                                    <th>Current Amount</th>
                                */}
                                <th>Total Coins</th>
                                <th>Quiz Coins</th>
                                <th>Active</th>
                                {
                                    updatePermission &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.wallets.map((wallet, index) => {
                                    return (
                                        <tr key={index}>

                                            <td>{wallet.User?.user_name}</td>
                                            {/**
                                                <td>{wallet.previous_amount.toLocalString()}</td>
                                                <td>{wallet.current_amount.toLocalString()}</td>
                                            */}
                                            <td>{wallet.total_points.toLocaleString()}</td>
                                            <td>{wallet.quiz_points ? wallet.quiz_points.toLocaleString() : '-'}</td>
                                            <td>{wallet.active === 1 ? "Active" : "Inactive"}</td>
                                            <td>
                                                <Modal editItem={() => this.handleEdit(wallet)} updatePermission={updatePermission} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination activePage={this.props.currentPage} totalItemsCount={this.props.totalCount} totalCountPerPage={this.props.totalCountPerPage} paginate={(pageNumber) => this.props.getWallets(pageNumber, this.state.rowCount)} />

                    {
                        this.state.show &&
                        <WalletCreate
                            isEdit={this.state.isEdit}
                            hideModal={this.hideModal}
                            wallet={this.state.wallet}
                            searchUser={this.props.searchUser}
                            currentPage={this.props.currentPage}
                            createOrUpdate={this.createOrUpdate}
                            users={this.props.users}
                            // userRowCount={this.props.userRowCount}
                            // getAllUsers={this.props.getAllUsers}
                            loadMore={this.loadMore}
                            requiredError={this.state.requiredError}
                        />
                    }


                </Body>
            </MainContainer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        wallets: state.wallet.wallets,
        users: state.user.users,
        error: state.wallet.error,
        success: state.wallet.success,
        sort_by: state.wallet.success,
        currentPage: state.wallet.currentPage,
        ucPage: state.user.currentPage,
        totalCount: state.wallet.totalCount,
        totalCountPerPage: state.wallet.totalCountPerPage,
        text: state.wallet.text,
        row_count: state.wallet.row_count,
        loading: state.wallet.loading,
        userText: state.user.text,
        userRowCount: state.user.row_count,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        getWallets: (sortBy, pageNumber, row_count) => dispatch(WalletAction.getWallets(sortBy, pageNumber, row_count)),
        getAllUsers: (pageNumber, row_count) => dispatch(UserAction.getAllUsers(pageNumber, row_count)),
        searchItem: (text, pageNumber) => dispatch(WalletAction.searchItem(text, pageNumber)),
        updateWallet: (wallet) => dispatch(WalletAction.updateWallet(wallet)),
        searchUser: (text, pageNumber) => dispatch(UserAction.searchUser(text, pageNumber)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WalletListing)
