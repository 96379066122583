import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../../redux/constants';

class ScoreStatsController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stats: true,
            showTeamData: true,
            showPlayerData: false,
            showPlayerDetailModal: false,
            showSubLineUp: false,
            showSubStats: true,
            showH2H: false,
            loading: true,
            playerId: '',
            general: {},
            teams: [],
            status: {},
            events: {},
            content: {},
            seo: {},
            topStats: [],
            lineups: [],
            h2h: [],
            matchFacts: {},
        }
    }
    componentDidMount = () => {
        const matchId = this.props.match.params.matchId
        axios.post(`${CONSTANTS.BASE_URL}livescores/matchDetail/${matchId}`).then((response) => {
            this.setState({
                scoreDetail: response.data,
                general: response.data?.general,
                teams: response.data?.header?.teams,
                status: response.data?.header?.status,
                events: response.data?.header?.events,
                content: response.data?.conent,
                seo: response.data?.seo,
                topStats: response.data?.content?.stats !== null && response.data?.content?.stats?.stats,
                lineups: response.data?.content?.lineup,
                topPlayers: response.data?.content?.matchFacts?.topPlayers,
                h2h: response.data?.content?.h2h,
                matchFacts: response.data?.content?.matchFacts && response.data?.content?.matchFacts,
                loading: false,
            })
        })
    }

    hideModal = () => {
        this.setState({ showPlayerDetailModal: false })
    }
    getTeamData = () => {
        this.setState({ showTeamData: true, showPlayerData: false })
    }
    getPlayerData = () => {
        this.setState({ showPlayerData: true, showTeamData: false })
    }
    showPlayerDetail = (playerId) => {
        this.setState({ showPlayerDetailModal: true, playerId: playerId })
    }
    showSubLineUp = () => {
        this.setState({
            showSubLineUp: true,
            showSubStats: false,
            showH2H: false,
            showPlayerData: false,
            showTeamData: false,
        })
    }
    showSubStats = () => {
        this.setState({
            showH2H: false,
            showSubLineUp: false,
            showSubStats: true,
            showPlayerData: false,
            showTeamData: true,
            showMatchFact: false,
        })
    }
    showH2H = () => {
        this.setState({
            showH2H: true,
            showSubLineUp: false,
            showSubStats: false,
            showPlayerData: false,
            showTeamData: false,
            showMatchFact: false,
        })
    }
    showMatchFact = () => {
        this.setState({
            showMatchFact: true,
            showH2H: false,
            showSubLineUp: false,
            showSubStats: false,
            showPlayerData: false,
            showTeamData: false,
        })
    }
}

export default ScoreStatsController