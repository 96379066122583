
import axios from 'axios'
import CONSTANTS from './../constants';
import GlobalLevelRuleType from './../type/global_level_rule_type';

export const Error = (data) => {
    return {
        type: GlobalLevelRuleType.GLOBAL_LEVEL_RULE_ERROR, payload: data
    }
}
export const Success = (data) => {
    return {
        type: GlobalLevelRuleType.GLOBAL_LEVEL_RULE_SUCCESS, payload: data
    }
}
const loading = (status) => {
    return (dispatch) => {
        return dispatch({ type: GlobalLevelRuleType.LOADING, payload: status })
    }
}
const getGlobalLevelRules = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}global-level-rules/list?page_at=${pageNumber}&row_count=${row_count}&sort_by=top_fan_level_number&order_by=DESC`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: GlobalLevelRuleType.GET_GLOBAL_LEVEL_RULES, payload: response.data.data
                })
                dispatch({
                    type: GlobalLevelRuleType.GET_TOTAL_COUNT, payload: response.data.pagination.total_records_count,
                })
                dispatch({
                    type: GlobalLevelRuleType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: GlobalLevelRuleType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: GlobalLevelRuleType.ROW_COUNT,
                    payload: row_count,
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: GlobalLevelRuleType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: GlobalLevelRuleType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const getAllGlobalLevelRules = (pageNumber, row_count) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}global-level-rules/list?page_at=${pageNumber}&row_count=${row_count}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: GlobalLevelRuleType.GET_GLOBAL_LEVEL_RULES, payload: response.data.data
                })
                dispatch({
                    type: GlobalLevelRuleType.GET_CURRENT_PAGE, payload: response.data.pagination.page_at
                })
                dispatch({
                    type: GlobalLevelRuleType.ROW_COUNT,
                    payload: row_count,
                })
            }
            else {
                let message = "Failed"
                dispatch({
                    type: GlobalLevelRuleType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: GlobalLevelRuleType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}

const createGlobalLevelRule = (GlobalLevelRule) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.post(`${CONSTANTS.BASE_URL}global-level-rules/create`, GlobalLevelRule).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: GlobalLevelRuleType.ADD_GLOBAL_LEVEL_RULE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const updateGlobalLevelRule = (GlobalLevelRule) => {
    return (dispatch) => {
        dispatch(loading(true))

        return axios.put(`${CONSTANTS.BASE_URL}global-level-rules/update`, GlobalLevelRule).then((response) => {
            if (response.data.status === "Success") {
                dispatch({
                    type: GlobalLevelRuleType.UPDATE_GLOBAL_LEVEL_RULE, payload: response.data.data
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "All fields are required"
                const errorPayload = {};
                errorPayload['message'] = message;
                dispatch(Error(errorPayload))
                setTimeout(() => {
                    const errorPayload = null
                    dispatch(Error(errorPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)

        })
    }
}
const deleteGlobalLevelRule = (id) => {
    return (dispatch) => {
        return axios.delete(`${CONSTANTS.BASE_URL}global-level-rules/delete`, { data: { id: id } }).then(response => {
            if (response.data.status === 'Success') {
                dispatch({
                    type: GlobalLevelRuleType.DELETE_GLOBAL_LEVEL_RULE, payload: id
                })
                const successPayload = {};
                successPayload['message'] = response.data.message;
                dispatch(Success(successPayload))
                setTimeout(() => {
                    const successPayload = null
                    dispatch(Success(successPayload))
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            } else {
                let message = "Failed to delete"
                dispatch({
                    type: GlobalLevelRuleType.ERROR, payload: message
                })
                setTimeout(() => {
                    const message = null
                    dispatch({
                        type: GlobalLevelRuleType.ERROR,
                        payload: message
                    })
                }, CONSTANTS.ALERT_DISPLAY_TIME)
            }
        }).catch(err => {
            const errorPayload = {};
            if (err?.response?.data?.message === undefined || err?.response?.data?.message === null || !err?.response?.data?.message) {
                errorPayload['message'] = err.message;
            } else {
                errorPayload['message'] = err?.response?.data?.message
            }
            dispatch(Error(errorPayload))
            setTimeout(() => {
                const errorPayload = null
                dispatch(Error(errorPayload))
            }, CONSTANTS.ALERT_DISPLAY_TIME)
        })
    }
}
const searchItem = (text, pageNumber) => {
    return (dispatch) => {
        return axios.post(`${CONSTANTS.BASE_URL}global-level-rules/list?top_fan_level_number=${text}&page_at=${pageNumber}`).then(response => {
            if (response.data.status === "Success") {
                dispatch({
                    type: GlobalLevelRuleType.GET_GLOBAL_LEVEL_RULES,
                    payload: response.data.data
                })
                dispatch({
                    type: GlobalLevelRuleType.GET_TOTAL_COUNT,
                    payload: response.data.pagination.total_records_count
                })
                dispatch({
                    type: GlobalLevelRuleType.GET_CURRENT_PAGE,
                    payload: response.data.pagination.page_at
                })
                dispatch({
                    type: GlobalLevelRuleType.TOTAL_COUNT_PER_PAGE, payload: response.data.pagination.total_count_per_page,
                })
                dispatch({
                    type: GlobalLevelRuleType.SEARCH_ITEM,
                    payload: text
                })
            }
        })
    }
}

const GlobalLevelRuleAction = {
    searchItem,
    getGlobalLevelRules,
    getAllGlobalLevelRules,
    createGlobalLevelRule,
    updateGlobalLevelRule,
    deleteGlobalLevelRule,
    loading,
}

export default GlobalLevelRuleAction;