import React from 'react';
import { LeagueCreateController } from './LeagueCreateController';
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import '../../modal/modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import SelectModal from './../../../components/selectModal/SelectModal';
export class LeagueCreate extends LeagueCreateController {
    render(props) {
        return (
            <div className="modal-wrapper">
                <div className="modalHeader">
                    <h3>{this.props.isEdit ? 'Edit League' : 'Add League'}</h3>
                    <span className="close-modal-btn" onClick={this.props.hideModal}>x</span>
                </div>
                <div className="modalContent">
                    <div className="modalBody">
                        <form autoComplete="off" style={{ width: '50%', margin: 'auto' }} >
                            {
                                this.props.requiredError && <div className="requiredError">*{this.props.requiredError}</div>
                            }

                            <SelectModal
                                routeChangeName="country"
                                items={this.props.countries}
                                searching={this.props.searchCountry}
                                currentPage={this.props.currentPage}
                                LoadMore={this.props.loadMore}
                                getItems={this.props.getAllCountries}
                                rowCount={this.props.countryRowCount}
                                handleProcess={(country_id) => this.CountryIdProcess(country_id)}
                                itemName={this.props.isEdit ? this.props.league.league.Country.name : " *** Choose Country *** "}
                            />

                            <Input type="text" placeholder="Enter League Name" name="name" value={this.state.league.name} onChange={this.handleInputName} required />

                            <Input type="text" name="description" placeholder="Enter League Description ...(Optional)" value={this.state.league.description || ''} onChange={this.handleInputDescription} />

                            <select name="active" className="select" value={this.state.league.active} onChange={this.processActive} required >
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </select>

                            <div className="image-upload">
                                <label htmlFor="file">
                                    <FontAwesomeIcon icon={faCloudUploadAlt} className="icon" color="#FAD230" />
                                    Choose Image
                                </label>
                                <input type="file" id="file" name="logo_url" onChange={this.onChangeImage} style={{ display: 'none' }} />
                                {
                                    (typeof this.state.league.logo_url === "string" && this.props.isEdit) && <img className="uploadedImage" src={this.state.league.logo_url} alt="" style={{ width: '80px', height: "80px" }} />
                                }
                                {
                                    (typeof this.state.league.logo_url !== "string") && <img className="uploadedImage" src={URL.createObjectURL(this.state.league.logo_url)} alt="" style={{ width: '80px', height: "80px" }} />
                                }

                            </div>

                            <Button className="btn-secondary" type="button" id="submit-btn" caption={this.props.isEdit ? "Update" : "Create"} btnFun={() => this.props.createOrUpdate(this.state.league)} />

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeagueCreate;
