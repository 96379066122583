import { Component } from 'react'
import axios from 'axios'
import CONSTANTS from './../../../redux/constants';
class AnswerCreateController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_answers: [],
      allTeams: [],
      player_id: '',
      player_name: '',
      match: {},
      teamRowCount: CONSTANTS.ROW_COUNT,
      initialPage: 1,
      quiz_answer: props.isEdit === false ? {
        special_quiz_detail_id: '',
        answer: "",
        match_id: '',
        win_case: '',
        quiz_type: '',
        active: 1
      } : { ...props.quiz_answer.quiz_answer, answer: this.props.quiz_answer.quiz_answer.answer }

    }
  }
  componentDidMount = async () => {
    // this.getAllTeams();
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        special_quiz_detail_id: this.props.quizInfo && this.props.quizInfo?.id,
        quiz_type: this.props.quizInfo && this.props.quizInfo?.quiz_type,
        match_id: (this.props.quizInfo?.quiz_type === 1 || this.props.quizInfo?.quiz_type === 3) && this.props.quizInfo?.SpecialQuiz?.match_id,
      }
    }))
    if (this.props.quizInfo.quiz_type == 3) {
      this.setState((prevState) => ({
        ...prevState,
        quiz_answer: {
          ...prevState.quiz_answer,
          answer: this.props.quizInfo?.quiz_type === 3 && '1',
          win_case: this.props.quizInfo?.quiz_type === 3 && "Home",
        }
      }))
    }
    else if (this.props.quizInfo?.Question?.question == 'ဂိုးပေါင်း ဘယ်လောက်လဲ') {
      this.setState((prevState) => ({
        ...prevState,
        quiz_answer: {
          ...prevState.quiz_answer,
          answer: this.props.quizInfo?.quiz_type === 1 && 'ဂိုးမရှိ',
          win_case: this.props.quizInfo?.quiz_type === 1 && "ဂိုးမရှိ",
        }
      }))
    }
    if (this.props.quizInfo?.quiz_type === 1) {
      let id = this.props.quizInfo?.SpecialQuiz?.match_id
      axios.post(`${CONSTANTS.BASE_URL}matches/detail`, { id: id }).then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          match: res.data.data
        }))
      })
    }

  }
  
  PlayerIdProcess = (player_id) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        answer: JSON.stringify(player_id),

      }
    }))
  }
  TeamIdProcess = (team_id) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        answer: JSON.stringify(team_id),
      }
    }))
  }
  TeamNameProcess = (team_name) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        win_case: team_name
      },
    }))
  }
  PlayerNameProcess = (player_name) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        win_case: player_name,
      },
      // player_name:player_name
    }))
  }
  handleInputChange = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        answer: e.target.value
      }
    }), () => {
      // if()
      if (this.props.quizInfo.quiz_type === 3) {
        if (this.state.quiz_answer.answer == 1) {
          this.setState((prevState) => ({
            ...prevState,
            quiz_answer: {
              ...prevState.quiz_answer,
              win_case: "Home"
            }
          }))
        }
        else if (this.state.quiz_answer.answer == 2) {
          this.setState((prevState) => ({
            ...prevState,
            quiz_answer: {
              ...prevState.quiz_answer,
              win_case: "Draw"
            }
          }))
        }
        else if (this.state.quiz_answer.answer == 3) {
          this.setState((prevState) => ({
            ...prevState,
            quiz_answer: {
              ...prevState.quiz_answer,
              win_case: "Away"
            }
          }))
        }

      }
    })


  }
  handleInputChangeAnswer = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.quiz_answer,
        answer: e.target.value
      }
    }), () => {

      let teamName;
      if (this.state.quiz_answer.answer == this.state.match?.home_team_id) {
        teamName = this.state.match?.HomeTeam?.name
      }
      else if (this.state.quiz_answer.answer == this.state.match?.away_team_id) {
        teamName = this.state.match?.AwayTeam?.name
      }
      if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်း နိင်မှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'သရေကျမယ်' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဂိုးသွင်းမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'သွင်းဂိုးမရှိ' : teamName;
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'အနီကဒ်မရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အနီကဒ်ပြခံရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'အနီကဒ်မရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် အဝါကဒ်ပြခံရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'အဝါကဒ်မရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ဖရီးကစ်(freekick)ရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'ဖရီးကစ်မရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ကော်နာ(corner)ရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'ကော်နာမရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် ပယ်နတီ(penalty)ရမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'ပယ်နတီမရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      else if (this.props.quizInfo?.Question?.question == 'ဘယ်အသင်းအရင် လူစားလဲမှာလဲ') {
        let win_case = this.state.quiz_answer.answer == 0 ? 'လူစားလဲတာမရှိ' : teamName
        this.setState((prevState) => ({
          ...prevState,
          quiz_answer: {
            ...prevState.quiz_answer,
            win_case: win_case
          }
        }))
      }
      // if (this.state.quiz_answer.answer == this.state.match?.HomeTeam?.id) {
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     quiz_answer: {
      //       ...prevState.quiz_answer,
      //       win_case: this.state.match?.HomeTeam?.name
      //     }
      //   }))
      // }
      // else if (this.state.quiz_answer.answer == this.state.match?.AwayTeam?.id) {
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     quiz_answer: {
      //       ...prevState.quiz_answer,
      //       win_case: this.state.match?.AwayTeam?.name
      //     }
      //   }))
      // }
      // else if (this.state.quiz_answer.answer == 0) {
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     quiz_answer: {
      //       ...prevState.quiz_answer,
      //       win_case: 'Draw'
      //     }
      //   }))
      // }
    })
  }
  handleSelect = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      quiz_answer: {
        ...prevState.answer,
        active: e.target.value
      }
    }))
  }

}

export default AnswerCreateController